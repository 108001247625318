import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import CardType from '@admin/components/Moderation/types/CardType'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import SubjectType from '@admin/components/Moderation/types/SubjectType'
import { MODERATION_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { IStatusError, TStatus } from '@admin/types/commonTypes'
import { IModeratorWithTickets } from '@admin/types/Member'

import type { TAny } from '@yzzy/types'

export interface IModerationState {
  assignStatus: TStatus
  columns: TAny[]
  confirmedViolationsDataLength: number
  confirmedViolationsStatus: { content: TStatus; profile: TStatus }
  contentComplaintsDataLength: number
  contentComplaintsMyDataLength: number
  contentComplaintsStatus: TStatus
  currentGroup: 'All' | 'My'
  currentTab: ModerationTabs
  data: TAny[]
  declinedRequestsDataLength: number
  declinedRequestsStatus: { content: TStatus; profile: TStatus }
  entityCardInfoStatus: TStatus
  hiveDataLength: number
  hiveMyDataLength: number
  hiveStatus: TStatus
  moderators: IModeratorWithTickets[]
  moderatorsStatus: TStatus
  profileComplaintsDataLength: number
  profileComplaintsMyDataLength: number
  profileComplaintsStatus: TStatus
  status: TStatus
  unassignStatus: TStatus
}

const initialState: IModerationState = {
  assignStatus: 'idle',
  columns: [
    {
      columnId: 'moderationCards',
      columnName: 'ModerationCard',
    },
  ],
  confirmedViolationsDataLength: 0,
  confirmedViolationsStatus: { content: 'idle', profile: 'idle' },
  contentComplaintsDataLength: 0,
  contentComplaintsMyDataLength: 0,
  contentComplaintsStatus: 'idle',
  currentGroup: 'All',
  currentTab: ModerationTabs.HIVE,
  data: [],
  declinedRequestsDataLength: 0,
  declinedRequestsStatus: { content: 'idle', profile: 'idle' },
  entityCardInfoStatus: 'idle',
  hiveDataLength: 0,
  hiveMyDataLength: 0,
  hiveStatus: 'idle',
  moderators: [],
  moderatorsStatus: 'idle',
  profileComplaintsDataLength: 0,
  profileComplaintsMyDataLength: 0,
  profileComplaintsStatus: 'idle',
  status: 'idle',
  unassignStatus: 'idle',
}

export interface IMetrics {
  metricName: string
  score: number
}

export const fetchModerationData = createAsyncThunk<
  TAny,
  TAny,
  {
    rejectValue: IStatusError
  }
>('moderation/fetchCards', async (parameters: TAny, thunkAPI) => {
  if (parameters.type === ModerationTabs.RULES) {
    return { type: parameters.type }
  }

  const rootState: TRootState = thunkAPI.getState() as TRootState
  const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
  const sortingList =
    Object.keys(rootState.sorting.data).length > 0
      ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
      : [
          {
            columnId: 'complaint_task_created_date',
            sortingType: 'DESC',
          },
        ]

  let dataUrl
  let historyType
  let subjectType

  switch (parameters.type) {
    case ModerationTabs.CONFIRMED_VIOLATIONS: {
      dataUrl = 'moderation/screen/history'
      historyType = 'CONFIRMED'
      subjectType = parameters.subjectTypeConfirmed
      break
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints'
      break
    }
    case ModerationTabs.DECLINED_REQUESTS: {
      dataUrl = 'moderation/screen/history'
      historyType = 'DECLINED'
      subjectType = parameters.subjectTypeDeclined
      break
    }
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive'
      break
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints'
      break
    }
  }

  try {
    const response = await axios.post(
      MODERATION_API + dataUrl,
      {
        filters: filters,
        historyType: historyType,
        pageInfo: {
          page: 1,
          size: parameters.pageSize,
        },
        sortingList: sortingList,
        subjectType: subjectType,
      },
      { signal: thunkAPI.signal },
    )

    return { ...response.data, type: parameters.type }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }

  // const response = {
  //   moderationCards: mockHive,
  //   page: 1,
  //   allResultsCount: mockHive.length,
  // }
  // return {...response, type: ModerationTabs.HIVE};
})

export const fetchMyData = createAsyncThunk('moderaion/fetchMyCards', async (parameters: TAny, thunkAPI) => {
  let dataUrl

  switch (parameters.type) {
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints/my-tickets'
      break
    }
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive/my-tickets'
      break
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints/my-tickets'
      break
    }
  }
  try {
    const response = await axios.get(MODERATION_API + dataUrl, {
      signal: thunkAPI.signal,
    })

    return { ...response.data, type: parameters.type }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchAssignTickets = createAsyncThunk('moderation/fetchAssignCards', async (parameters: TAny) => {
  let dataUrl

  switch (parameters.type) {
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints/assign-tickets'
      break
    }
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive/assign-tickets'
      break
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints/assign-tickets'
      break
    }
  }

  const response = await axios.get(MODERATION_API + dataUrl)

  return { ...response.data, type: parameters.type }
})

export const fetchUnassignTickets = createAsyncThunk('moderation/unassignTickets', async (parameters: TAny, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${MODERATION_API}moderation/screen/unassign-tickets`, {
      moderatorIds: parameters.moderatorIds,
      ticketTypes: parameters.ticketTypes,
    })

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error) && rejectWithValue(error.response?.data)
  }
})

export const fetchModeratorWithTickets = createAsyncThunk('moderation/loadModerators', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${MODERATION_API}moderation/screen/unassign-tickets/moderator-list`)

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchDataLength = createAsyncThunk('moderation/fetchDataLength', async () => {
  const response = await axios.get(MODERATION_API + 'moderation/screen/counts')

  return response.data
})

export const fetchChangeStatus = createAsyncThunk('moderation/changeStatus', async (data: TAny) => {
  let url = ''
  let additionalData

  switch (data.cardType) {
    case CardType.CONTENT: {
      url = `${MODERATION_API}moderation/manual-violations/${data.entityId}/${data.status ? 'confirm' : 'decline'}`
      additionalData = {
        confirmedManualViolations: data.confirmedViolations,
        historical: data.historical,
      }
      break
    }
    case CardType.HIVE: {
      url = `${MODERATION_API}moderation/hive-violations/${data.entityId}/${data.status ? 'confirm' : 'decline'}`
      additionalData = data.manualMode
        ? {
            confirmedManualModeViolations: data.confirmedViolations,
            historical: data.historical,
          }
        : {
            historical: data.historical,
          }
      break
    }
    case CardType.PROFILE: {
      url = `${MODERATION_API}moderation/profile-violations/${data.entityId}/${data.status ? 'confirm' : 'decline'}`
      additionalData = {
        confirmedProfileViolations: data.confirmedViolations,
        historical: data.historical,
      }
      break
    }
  }

  await axios.post(url, {
    moderatorId: data.moderatorId,
    ...additionalData,
  })

  return { cardType: data.cardType, entityId: data.entityId, status: data.status }
})

export const getEntityCardInfo = createAsyncThunk('moderation/getEntityCardInfo', async (entityInfo: TAny) => {
  const response = await axios.get(MODERATION_API + `moderation/screen/${entityInfo.entityId}`)

  return response.data
})

export const moderationSlice = createSlice({
  name: 'moderation',
  extraReducers: (builder) => {
    builder
      .addCase(fetchModerationData.pending, (state, action: TAny) => {
        state.currentTab = action.meta.arg.type as ModerationTabs
        state.currentGroup = 'All'
        state.status = 'loading'
        switch (action.meta.arg.type) {
          case ModerationTabs.CONFIRMED_VIOLATIONS:
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.CONTENT) {
              state.confirmedViolationsStatus.content = 'loading'
            }
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.PROFILE) {
              state.confirmedViolationsStatus.profile = 'loading'
            }
            break
          case ModerationTabs.CONTENT_COMPLAINTS:
            state.contentComplaintsStatus = 'loading'
            break
          case ModerationTabs.DECLINED_REQUESTS:
            if (action.meta.arg.subjectTypeDeclined === SubjectType.CONTENT) {
              state.declinedRequestsStatus.content = 'loading'
            }
            if (action.meta.arg.subjectTypeDeclined === SubjectType.PROFILE) {
              state.declinedRequestsStatus.profile = 'loading'
            }
            break
          case ModerationTabs.HIVE:
            state.hiveStatus = 'loading'
            break
          case ModerationTabs.PROFILE_COMPLAINTS:
            state.profileComplaintsStatus = 'loading'
            break
        }
      })
      .addCase(fetchModerationData.fulfilled, (state, action) => {
        state.status = 'idle'
        if (action.payload.type === ModerationTabs.RULES) {
          return state
        }
        if (action.meta.arg.type === state.currentTab && state.currentGroup === 'All') {
          state.status = 'idle'
          state.data = action.payload.moderationCards
        }
        switch (action.payload.type) {
          case ModerationTabs.CONFIRMED_VIOLATIONS: {
            state.confirmedViolationsDataLength = action.payload.allResultsCount
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.CONTENT) {
              state.confirmedViolationsStatus.content = 'idle'
            }
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.PROFILE) {
              state.confirmedViolationsStatus.profile = 'idle'
            }
            break
          }
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsDataLength = action.payload.allResultsCount
            state.contentComplaintsStatus = 'idle'
            break
          }
          case ModerationTabs.DECLINED_REQUESTS: {
            state.declinedRequestsDataLength = action.payload.allResultsCount
            if (action.meta.arg.subjectTypeDeclined === SubjectType.CONTENT) {
              state.declinedRequestsStatus.content = 'idle'
            }
            if (action.meta.arg.subjectTypeDeclined === SubjectType.PROFILE) {
              state.declinedRequestsStatus.profile = 'idle'
            }
            break
          }
          case ModerationTabs.HIVE: {
            state.hiveDataLength = action.payload.allResultsCount
            state.hiveStatus = 'idle'
            break
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsDataLength = action.payload.allResultsCount
            state.profileComplaintsStatus = 'idle'
            break
          }
        }
      })
      .addCase(fetchModerationData.rejected, (state, action) => {
        state.status = 'failed'
        state.data = []
        switch (action.meta.arg.type) {
          case ModerationTabs.CONFIRMED_VIOLATIONS:
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.CONTENT) {
              state.confirmedViolationsStatus.content = 'failed'
            }
            if (action.meta.arg.subjectTypeConfirmed === SubjectType.PROFILE) {
              state.confirmedViolationsStatus.profile = 'failed'
            }
            break
          case ModerationTabs.CONTENT_COMPLAINTS:
            state.contentComplaintsStatus = 'failed'
            break
          case ModerationTabs.DECLINED_REQUESTS:
            if (action.meta.arg.subjectTypeDeclined === SubjectType.CONTENT) {
              state.declinedRequestsStatus.content = 'failed'
            }
            if (action.meta.arg.subjectTypeDeclined === SubjectType.PROFILE) {
              state.declinedRequestsStatus.profile = 'failed'
            }
            break
          case ModerationTabs.HIVE:
            state.hiveStatus = 'failed'
            break
          case ModerationTabs.PROFILE_COMPLAINTS:
            state.profileComplaintsStatus = 'failed'
            break
        }
      })
      .addCase(fetchMyData.pending, (state, action) => {
        state.currentGroup = 'My'
        state.status = 'loading'
        switch (action.meta.arg.type) {
          case ModerationTabs.CONTENT_COMPLAINTS:
            state.contentComplaintsStatus = 'loading'
            break
          case ModerationTabs.HIVE:
            state.hiveStatus = 'loading'
            break
          case ModerationTabs.PROFILE_COMPLAINTS:
            state.profileComplaintsStatus = 'loading'
            break
        }
      })
      .addCase(fetchMyData.fulfilled, (state, action) => {
        if (action.meta.arg.type === state.currentTab && state.currentGroup === 'My') {
          state.status = 'idle'
          state.data = action.payload.moderationCards
        }
        switch (action.payload.type) {
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsMyDataLength = action.payload.allResultsCount
            state.contentComplaintsStatus = 'idle'
            break
          }
          case ModerationTabs.HIVE: {
            state.hiveMyDataLength = action.payload.allResultsCount
            state.hiveStatus = 'idle'
            break
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsMyDataLength = action.payload.allResultsCount
            state.profileComplaintsStatus = 'idle'
            break
          }
        }
      })
      .addCase(fetchMyData.rejected, (state, action) => {
        state.status = 'failed'
        switch (action.meta.arg.type) {
          case ModerationTabs.CONTENT_COMPLAINTS:
            state.contentComplaintsStatus = 'failed'
            break
          case ModerationTabs.HIVE:
            state.hiveStatus = 'failed'
            break
          case ModerationTabs.PROFILE_COMPLAINTS:
            state.profileComplaintsStatus = 'failed'
            break
        }
      })
      .addCase(fetchAssignTickets.pending, (state) => {
        state.data = []
        state.assignStatus = 'loading'
      })
      .addCase(fetchAssignTickets.fulfilled, (state, action) => {
        state.assignStatus = 'idle'
        state.data = action.payload.moderationCards
        switch (action.payload.type) {
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsMyDataLength = action.payload.allResultsCount
            break
          }
          case ModerationTabs.HIVE: {
            state.hiveMyDataLength = action.payload.allResultsCount
            break
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsMyDataLength = action.payload.allResultsCount
            break
          }
        }
      })
      .addCase(fetchAssignTickets.rejected, (state) => {
        state.assignStatus = 'failed'
      })
      .addCase(fetchDataLength.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDataLength.fulfilled, (state, action) => {
        state.status = 'idle'
        state.hiveDataLength = action.payload.hive
        state.contentComplaintsDataLength = action.payload.content
        state.profileComplaintsDataLength = action.payload.profile
      })
      .addCase(fetchDataLength.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchChangeStatus.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (card) => card.profileInfo?.profileId !== action.payload.entityId && card.entityInfo?.entityId !== action.payload.entityId,
        )
        switch (action.payload.cardType) {
          case CardType.CONTENT: {
            state.contentComplaintsMyDataLength = state.contentComplaintsMyDataLength - 1
            break
          }
          case CardType.HIVE: {
            state.hiveMyDataLength = state.hiveMyDataLength - 1
            break
          }
          case CardType.PROFILE: {
            state.profileComplaintsMyDataLength = state.profileComplaintsMyDataLength - 1
            break
          }
        }
      })
      .addCase(getEntityCardInfo.pending, (state) => {
        state.entityCardInfoStatus = 'loading'
      })
      .addCase(getEntityCardInfo.fulfilled, (state) => {
        state.entityCardInfoStatus = 'idle'
      })
      .addCase(getEntityCardInfo.rejected, (state) => {
        state.entityCardInfoStatus = 'failed'
      })
      .addCase(fetchUnassignTickets.pending, (state) => {
        state.unassignStatus = 'loading'
      })
      .addCase(fetchUnassignTickets.fulfilled, (state, action) => {
        state.unassignStatus = 'idle'

        const channel = new BroadcastChannel('moderation_channel')

        channel.postMessage({
          moderatorIds: action.meta.arg.moderatorIds,
          ticketTypes: action.meta.arg.ticketTypes,
          type: 'TICKETS_UNASSIGNED',
        })
        channel.close()
      })
      .addCase(fetchUnassignTickets.rejected, (state) => {
        state.unassignStatus = 'failed'
      })
      .addCase(fetchModeratorWithTickets.pending, (state) => {
        state.moderatorsStatus = 'loading'
      })
      .addCase(fetchModeratorWithTickets.fulfilled, (state, action) => {
        state.moderatorsStatus = 'idle'
        state.moderators = action.payload.moderators
      })
      .addCase(fetchModeratorWithTickets.rejected, (state) => {
        state.moderatorsStatus = 'failed'
        state.moderators = []
      })
  },
  initialState,
  reducers: {
    clearContentModerationData: (state) => {
      state.data = []
      state.status = 'loading'
    },
    setModerationStatusLoading: (state, action) => {
      state.status = 'loading'
      switch (action.payload) {
        case ModerationTabs.CONFIRMED_VIOLATIONS:
          state.confirmedViolationsStatus.content = 'loading'
          break
        case ModerationTabs.CONTENT_COMPLAINTS:
          state.contentComplaintsStatus = 'loading'
          break
        case ModerationTabs.DECLINED_REQUESTS:
          state.declinedRequestsStatus.content = 'loading'
          break
        case ModerationTabs.HIVE:
          state.hiveStatus = 'loading'
          break
        case ModerationTabs.PROFILE_COMPLAINTS:
          state.profileComplaintsStatus = 'loading'
          break
      }
    },
  },
})

export default moderationSlice.reducer

export const { clearContentModerationData, setModerationStatusLoading } = moderationSlice.actions

export const selectModerationStatus = (state: TRootState): TStatus => state.moderation.status
export const selectAssignStatus = (state: TRootState): TStatus => state.moderation.assignStatus
export const selectUnassignStatus = (state: TRootState): TStatus => state.moderation.unassignStatus
export const selectHiveStatus = (state: TRootState): TStatus => state.moderation.hiveStatus
export const selectContentComplaintsStatus = (state: TRootState): TStatus => state.moderation.contentComplaintsStatus
export const selectProfileComplaintsStatus = (state: TRootState): TStatus => state.moderation.profileComplaintsStatus
export const selectConfirmedViolationsStatus = (state: TRootState): { content: TStatus; profile: TStatus } =>
  state.moderation.confirmedViolationsStatus
export const selectDeclinedRequestsStatus = (
  state: TRootState,
): {
  content: TStatus
  profile: TStatus
} => state.moderation.declinedRequestsStatus
export const selectModerationData = (state: TRootState): TAny[] => state.moderation.data
export const selectHiveLength = (state: TRootState): number => state.moderation.hiveDataLength
export const selectContentComplaintsLength = (state: TRootState): number => state.moderation.contentComplaintsDataLength
export const selectProfileComplaintsLength = (state: TRootState): number => state.moderation.profileComplaintsDataLength
export const selectConfirmedViolationsLength = (state: TRootState): number => state.moderation.confirmedViolationsDataLength
export const selectHiveMyDataLength = (state: TRootState): number => state.moderation.hiveMyDataLength
export const selectProfileComplaintsMyDataLength = (state: TRootState): number => state.moderation.profileComplaintsMyDataLength
export const selectContentComplaintsMyDataLength = (state: TRootState): number => state.moderation.contentComplaintsMyDataLength
export const selectEntityCardInfoStatus = (state: TRootState): TStatus => state.moderation.entityCardInfoStatus
export const selectDeclinedRequestsLength = (state: TRootState): number => state.moderation.declinedRequestsDataLength
export const selectColumn = (state: TRootState): TAny[] => state.moderation.columns
export const selectModerators = (state: TRootState): IModeratorWithTickets[] => state.moderation.moderators
export const selectModeratorsStatus = (state: TRootState): TStatus => state.moderation.moderatorsStatus
