import colors from '@admin/theme/constants/colors'
import { TStatus } from '@admin/types/commonTypes'

import { EntityType, EventFormat, EventOnlineFormatType, ITaxCode, ITaxRule } from '../Settings/interface'

/**
 * Enumerates the possible solutions for sales tax moderation card.
 *
 * @enum {string}
 * @readonly
 * @property {string} AUTO_APPROVED - The auto approved solution.
 * @property {string} APPROVED - The approved solution.
 * @property {string} MODIFIED - The modified solution.
 * @property {string} NEEDS_REVIEW - The needs review solution.
 * @property {string} ASSIGN_TO_SUPERVISOR - The assign to supervisor solution.
 */
export enum TaxModerationSolution {
  APPROVED = 'APPROVED',
  ASSIGN_TO_SUPERVISOR = 'ASSIGN_TO_SUPERVISOR',
  AUTO_APPROVED = 'AUTO_APPROVED',
  MODIFIED = 'MODIFIED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  RESOLVED = 'RESOLVED',
}

/**
 * Enumerates the possible status values for sales tax moderation card.
 *
 * @enum {string}
 * @readonly
 * @property {string} RESOLVED - The resolved status.
 * @property {string} CALCULATED - The calculated status.
 * @property {string} NEEDS_REVIEW - The needs review status.
 * @property {string} ASSIGN_TO_SUPERVISOR - The assign to supervisor status.
 */
export enum TaxModerationStatus {
  ASSIGN_TO_SUPERVISOR = 'ASSIGN_TO_SUPERVISOR',
  CALCULATED = 'CALCULATED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  RESOLVED = 'RESOLVED',
}

/* ------------------------------ Sales Tax Moderation ------------------------------ */
/**
 * Represents a card for sales tax moderation.
 *
 * @interface
 * @property {string} id - The unique identifier of the sales tax moderation card.
 * @property {string} entityName - The name of the entity associated with the sales tax moderation card.
 * @property {string} entityDescription - The description of the entity associated with the sales tax moderation card.
 * @property {EntityType} entityType - The type of entity to which the sales tax moderation card applies.
 * @property {EventFormat} [format] - The format of the sales tax moderation card.
 * @property {EventOnlineFormatType} [eventOnlineFormatType] - The online format type.
 * @property {Pick<ITaxCode, 'code' | 'category' | 'description'>} salesTaxCode - Information about the associated sales tax code.
 * @property {Date} createdAt - The date when the sales tax moderation card was created.
 * @property {TaxModerationSolution} solution - The solution status of the sales tax moderation card.
 */
export interface ITaxModerationCard {
  createdAt: Date
  entityDescription: string
  entityName: string
  entityType: EntityType
  eventCategory?: string
  eventOnlineFormatType?: EventOnlineFormatType
  format?: EventFormat
  id: string
  passions?: string[]
  salesTaxCode: Pick<ITaxCode, 'category' | 'code' | 'description' | 'id'>
  salesTaxRule: ITaxRule
  solution: TaxModerationSolution
  status?: TaxModerationStatus
}

/**
 * Represents solution object for sales tax moderation card.
 *
 * @interface
 * @property {TaxModerationSolution} solution - Solution value.
 * @property {string} title - Solution title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxModerationCardSolution {
  color: colors
  solution: TaxModerationSolution
  title: string
}

/**
 * Represents status object for sales tax moderation card.
 *
 * @interface
 * @property {TaxModerationSolution} solution - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxModerationCardStatus {
  color: colors
  status: TaxModerationStatus
  title: string
}

/**
 * Represents a search result item for sales tax moderation.
 *
 * @interface
 * @property {string} sameTitle - The title associated with the search item.
 * @property {string[]} ids - An array of unique identifiers for entities with the same title.
 */
export interface ITaxModerationSearchItem {
  ids: string[]
  sameTitle: string
}

/**
 * Represents the state of sales tax moderation in the store.
 *
 * @interface
 * @property {ITaxModerationCard[]} taxModerationCards - An array of sales tax moderation cards.
 * @property {string[]} allIds - An array of unique identifiers for all sales tax moderation cards.
 * @property {ITaxModerationSearchItem[]} taxModerationSearch - An array of search results for sales tax moderation.
 * @property {string[]} taxModerationSearchAll - An array of unique identifiers for all sales tax moderation search items.
 * @property {TStatus} taxModerationSearchStatus - The status of pending search results.
 * @property {ITaxModerationCard | null} taxModerationCardDetails - Details of a specific sales tax moderation card.
 * @property {number} taxModerationCardsCount - Total quantity of sales tax moderation cards.
 * @property {TStatus} status - The status value for the state data.
 * @property {string} searchValue - The current value of the TaxModerationSearch.
 */
export interface ITaxModerationState {
  allIds: string[]
  searchValue: string
  status: TStatus
  taxModerationCardDetails: ITaxModerationCard | null
  taxModerationCards: ITaxModerationCard[]
  taxModerationCardsCount: number
  taxModerationSearch: ITaxModerationSearchItem[]
  taxModerationSearchAll: string[]
  taxModerationSearchStatus: TStatus
}
