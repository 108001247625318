import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Typography from '@admin/components/shared/Typography/Typography'
import { ModalAction } from '@admin/types/commonTypes'

interface IConfirmCancelModalProps {
  action: ModalAction
  actionName: string
  closeModal: () => void
  onClose: () => void
  open: boolean
}

export const ConfirmCancelModal = ({ action, actionName, closeModal, onClose, open }: IConfirmCancelModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">{`Cancel ${action === ModalAction.ADD ? 'selecting another' : 'editing the'} ${actionName}?`}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Changes won’t be applied </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Don’t cancel
        </Button>
        <Button
          onClick={() => {
            closeModal()
            onClose()
          }}
          color="primary"
          variant="contained"
        >
          Cancel {action === ModalAction.ADD ? 'adding' : 'editing'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
