import './index.scss'

import React from 'react'

import Button from '@admin/components/shared/Button/Button'

interface IProps {
  action: 'add' | 'edit'
  closeModal(): void
  onClose(): void
}

const RulesModalCancel = ({ action, closeModal, onClose }: IProps) => {
  return (
    <div className="rulesModal">
      <div className="rulesModal-header">
        {action === 'add' && `Cancel adding a new rule?`}
        {action === 'edit' && `Cancel editing the rule?`}
      </div>
      <div className="rulesModal-content">
        <span className="rulesModal-content-value_delete">Changes won’t be applied</span>
      </div>
      <div className="rulesModal-footer">
        <div className="rulesModal-footer-buttons">
          <Button onClick={closeModal}>Don’t cancel</Button>
          <Button
            onClick={() => {
              closeModal()
              onClose()
            }}
            color="primary"
            variant="contained"
          >
            Cancel {action === 'add' ? 'adding' : 'editing'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RulesModalCancel
