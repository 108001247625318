import './index.scss'

import { useEffect, useMemo, useRef, useState } from 'react'

import ArrowDownIcon from '@admin/assets/img/ArrowDownIcon'
import ArrowUpIcon from '@admin/assets/img/ArrowUpIcon'
import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Divider from '@admin/components/shared/Divider/Divider'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import Menu from '@admin/components/shared/Menu/Menu'
import Radio from '@admin/components/shared/Radio/Radio'
import RadioGroup from '@admin/components/shared/RadioGroup/RadioGroup'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { selectSortingColumns, selectSortingData, selectSortingStatus, setSortingData } from '@admin/store/sortingSlice'
import colors from '@admin/theme/constants/colors'
import { ISortingItem } from '@admin/types/commonTypes'

/**
 * Props for the SortingChip component
 *
 * @interface
 * @property {boolean} [disabled] - If true, the sorting chip is disabled.
 * @property {ISortingItem} [defaultSortingModel] - Default sorting model to be applied when the component mounts.
 * @property {() => void} onSortingModelApply - Callback to execute when sorting model is applied.
 * @property {'ASC' | 'DESC'} [preferableOrder] - The preferable sorting order, defaulting to 'ASC' or 'DESC'.
 */
interface ISortingChipProps {
  defaultSortingModel?: ISortingItem
  disabled?: boolean
  onSortingModelApply: () => void
  preferableOrder?: 'ASC' | 'DESC'
}

const SortingChip = ({ defaultSortingModel, disabled, onSortingModelApply, preferableOrder }: ISortingChipProps) => {
  const dispatch = useAppDispatch()

  const sortingColumns = useAppSelector(selectSortingColumns)
  const sortingData = useAppSelector(selectSortingData)
  const sortingStatus = useAppSelector(selectSortingStatus)
  const isLoading = sortingStatus === 'loading'

  const chipReference = useRef<HTMLDivElement | null>(null)

  const [searchValue, setSearchValue] = useState('')
  const [sortingModel, setSortingModel] = useState<ISortingItem>({
    title: (defaultSortingModel?.title || sortingData[0]?.title) ?? '',
    columnId: (defaultSortingModel?.columnId || sortingData[0]?.columnId) ?? '',
    sortingType: (defaultSortingModel?.sortingType || sortingData[0]?.sortingType) ?? 'ASC',
  })
  const [temporarySortingModel, setTemporarySortingModel] = useState<ISortingItem>(sortingModel)
  const [sortingMenuAnchorElement, setSortingMenuAnchorElement] = useState<HTMLElement | null>(null)
  const sortingMenuOpen = Boolean(sortingMenuAnchorElement)

  const isShowSearch = sortingColumns.length > 20

  const filteredColumns = useMemo(() => {
    const lowerSearch = searchValue.toLowerCase()

    return sortingColumns.filter((column) => column.title.toLowerCase().includes(lowerSearch))
  }, [sortingColumns, searchValue])

  const handleSortingMenuClose = () => {
    setSortingMenuAnchorElement(null)
    setTemporarySortingModel(sortingModel)
  }

  const handleApplySorting = () => {
    setSortingModel(temporarySortingModel)
    dispatch(setSortingData([temporarySortingModel]))
    handleSortingMenuClose()
    onSortingModelApply()
  }

  useEffect(() => {
    if (sortingData.length) {
      const initialModel = sortingData[0]

      setSortingModel(initialModel)
      setTemporarySortingModel(initialModel)
    }
  }, [sortingData])

  const getSortingTypeLabel = (columnId: null | string, ascending: boolean) => {
    if (!columnId) return <Typography variant="body1">{ascending ? 'A-Z' : 'Z-A'}</Typography>

    const lowerColumnId = columnId.toLowerCase()
    const dateKeywords = ['date', 'created']
    const priceKeywords = ['price', 'amount', 'total', 'fee', 'count']

    let label = ascending ? 'A-Z' : 'Z-A'

    if (dateKeywords.some((keyword) => lowerColumnId.includes(keyword))) {
      label = ascending ? 'Oldest first' : 'Newest first'
    } else if (priceKeywords.some((keyword) => lowerColumnId.includes(keyword))) {
      label = ascending ? 'Lowest first' : 'Highest first'
    }

    return <Typography variant="body1">{label}</Typography>
  }

  const handleChipInteraction = () => {
    if (chipReference.current) {
      setSortingMenuAnchorElement(chipReference.current)
    }
  }

  return (
    <div ref={chipReference}>
      <Chip
        label={temporarySortingModel.title}
        icon={
          // TODO: fixed !important
          <Box
            sx={{
              display: 'flex',
              fontSize: '0 !important',
              marginLeft: '0 !important',
              marginRight: '0 !important',
            }}
          >
            {temporarySortingModel.sortingType === 'DESC' ? (
              <ArrowDownIcon sx={{ color: colors.primary, fontSize: 16, paddingLeft: 0.5 }} />
            ) : (
              <ArrowUpIcon sx={{ color: colors.primary, fontSize: 16, paddingLeft: 0.5 }} />
            )}
            <Typography color={colors.textSecondary} p={'0 4px'} variant="chip">
              Sort by
            </Typography>
            <Typography color={colors.primary} variant="chip">
              |
            </Typography>
          </Box>
        }
        sx={{
          '& .MuiChip-label': { padding: '0 6px 0 4px' },
          '& .MuiChip-deleteIcon:hover': { color: colors.primary },
        }}
        className="sorting-chip"
        deleteIcon={sortingMenuOpen ? <ChevronUpIcon sx={{ fontSize: 18 }} /> : <ChevronDownIcon sx={{ fontSize: 18 }} />}
        disabled={disabled}
        onClick={handleChipInteraction}
        onDelete={handleChipInteraction}
        size="small"
        variant="outlined"
      />
      <Menu
        id="sorting-menu"
        anchorEl={sortingMenuAnchorElement}
        onClose={handleSortingMenuClose}
        open={sortingMenuOpen}
        PaperProps={{ sx: { mt: '4px' } }}
      >
        {isShowSearch && (
          <>
            <TextField
              error={filteredColumns.length === 0 && searchValue !== ''}
              helperText={filteredColumns.length === 0 && searchValue !== '' && 'No matches here'}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search"
              size="small"
              sx={{ p: '0 8px 8px 8px' }}
              value={searchValue}
            />
            <Divider orientation="horizontal" flexItem />
          </>
        )}
        <RadioGroup row={false} sx={{ flexWrap: 'nowrap', maxHeight: '258px', overflowY: 'auto', p: '8px 12px' }}>
          <Typography color="text.secondary" variant="body2">
            Column
          </Typography>
          {isLoading && <CircularProgress size={24} sx={{ m: '8px auto' }} />}
          {filteredColumns.map((item) => {
            const parts = item.title.split(new RegExp(`(${searchValue})`, 'gi'))

            return (
              <FormControlLabel
                key={item.columnId}
                label={
                  <Typography variant="body2">
                    {parts.map((part: string, index: number) => (
                      <span key={index} style={{ fontWeight: part.toLowerCase() === searchValue.toLowerCase() ? 700 : 400 }}>
                        {part}
                      </span>
                    ))}
                  </Typography>
                }
                onClick={() =>
                  setTemporarySortingModel((previousState) => {
                    const newSortingType =
                      sortingModel?.columnId === item.columnId
                        ? sortingModel?.sortingType
                        : defaultSortingModel?.columnId === item.columnId
                          ? defaultSortingModel.sortingType
                          : preferableOrder || 'ASC'

                    return {
                      ...previousState,
                      title: item.title,
                      columnId: item.columnId,
                      sortingType: newSortingType,
                    }
                  })
                }
                control={<Radio key={item.columnId} checked={temporarySortingModel.columnId === item.columnId} size="small" />}
                sx={{ ml: 'unset' }}
                value={item.columnId}
              />
            )
          })}
        </RadioGroup>
        <Divider orientation="horizontal" flexItem />
        <RadioGroup sx={{ p: '8px 12px' }}>
          <Typography color="text.secondary" variant="body2">
            Direction
          </Typography>
          <FormControlLabel
            label={getSortingTypeLabel(temporarySortingModel.columnId, true)}
            control={<Radio checked={temporarySortingModel.sortingType === 'ASC'} size="small" />}
            onClick={() => setTemporarySortingModel((previousState) => ({ ...previousState, sortingType: 'ASC' }))}
            sx={{ ml: 'unset' }}
            value="ASC"
          />
          <FormControlLabel
            label={getSortingTypeLabel(temporarySortingModel.columnId, false)}
            control={<Radio checked={temporarySortingModel.sortingType === 'DESC'} size="small" />}
            onClick={() => setTemporarySortingModel((previousState) => ({ ...previousState, sortingType: 'DESC' }))}
            sx={{ ml: 'unset' }}
            value="DESC"
          />
        </RadioGroup>
        <Divider orientation="horizontal" flexItem />
        <Box p={'12px 12px 4px 12px'}>
          <Button onClick={handleApplySorting} size="small" sx={{ width: 1 }} variant="contained">
            Apply
          </Button>
        </Box>
      </Menu>
    </div>
  )
}

export default SortingChip
