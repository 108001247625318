import './index.scss'

import { MenuItem as MenuItemMUI } from '@mui/material'
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem'

const MenuItem = (props: MenuItemProps) => {
  return <MenuItemMUI {...props} />
}

export default MenuItem
