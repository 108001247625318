import { useCallback, useState } from 'react'

import { GridOverlay } from '@mui/x-data-grid'

import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'

interface IProps {
  clearFilters?: () => void
  fetchingError?: boolean
  isFilters?: boolean
  refreshData?: () => void
}

const NoMoreContent = ({ clearFilters, fetchingError, isFilters, refreshData }: IProps) => {
  const dispatch = useAppDispatch()
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false)
  const cancelClearFilters = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setClearFiltersConfirmModal(false)
  }, [])

  const handleClearFilters = useCallback(() => {
    setClearFiltersConfirmModal(false)

    clearFilters?.()
  }, [dispatch, clearFilters])

  return (
    <>
      <GridOverlay>
        {fetchingError && (
          <Stack alignItems="center">
            <Typography color="text.secondary" variant="body2">
              There are some troubles with data receiving. Repeat request in a while.
            </Typography>
            <Typography color="text.secondary" fontSize="small" variant="body2">
              WARNING! In case of page reload you could lose your requested data
            </Typography>
            {refreshData && (
              <Button onClick={refreshData} variant="text">
                Repeat request
              </Button>
            )}
          </Stack>
        )}
        {!isFilters && !fetchingError && (
          <Stack alignItems="center">
            <Typography color={colors.outlinedBorder} variant="h6">
              No content to display
            </Typography>
          </Stack>
        )}
        {isFilters && !fetchingError && (
          <Stack alignItems="center" zIndex={100}>
            <Typography color="text.secondary" variant="body2">
              No data to display. Try to remove any of filters or change their value
            </Typography>
            <Button onClick={() => setClearFiltersConfirmModal(true)} variant="text">
              Clear filters
            </Button>
          </Stack>
        )}
      </GridOverlay>
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={cancelClearFilters} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={handleClearFilters} variant="contained">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default NoMoreContent
