import './index.scss'

import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { alpha, Avatar, Menu, MenuItem, SelectChangeEvent, Stack, Tab } from '@mui/material'
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useDocumentTitle } from '@uidotdev/usehooks'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import isEqual from 'lodash/isEqual'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import ChangeIcon from '@admin/assets/img/ChangeIcon'
import ColumnsIcon from '@admin/assets/img/ColumnsIcon'
import CopyIcon from '@admin/assets/img/CopyIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import FilterIcon from '@admin/assets/img/FilterIcon'
import RefreshIcon from '@admin/assets/img/RefreshIcon'
import SortIcon from '@admin/assets/img/SortIcon'
import SystemIcon from '@admin/assets/img/SystemIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import { CloseColumnsDrawerConfirmModal } from '@admin/components/ColumnsDrawer/CloseColumnsDrawerConfirmModal'
import ColumnsDrawer from '@admin/components/ColumnsDrawer/ColumnsDrawer'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import Alert from '@admin/components/shared/Alert/Alert'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Input from '@admin/components/shared/Input/Input'
import LinkMUI from '@admin/components/shared/Link/Link'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Select from '@admin/components/shared/Select/Select'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import SortingDrawer from '@admin/components/SortingDrawer/SortingDrawer'
import NoUsersData from '@admin/components/Users/NoUsersData'
import { RenderCommentBannedCell } from '@admin/components/Users/RenderCommentBannedCell'
import { RenderCommentDeletedCell } from '@admin/components/Users/RenderCommentDeletedCell'
import { RenderDeadlineCell } from '@admin/components/Users/RenderDeadlineCell'
import { UsersTabs } from '@admin/components/Users/types/UsersTabs'
import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { clearColumns, fetchColumns, fetchColumnsPresets, syncColumnsData } from '@admin/store/columnsSlice'
import {
  clearFilters,
  fetchFilterPresets,
  fetchFilters,
  reverseFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { clearSortingData, fetchSortingColumns, fetchSortingPresets, setSortingData } from '@admin/store/sortingSlice'
import { fetchUpdateComment, selectUpdateCommentStatus } from '@admin/store/userProfileSlice'
import {
  clearData,
  fetchChangeStatus,
  fetchUsers,
  selectActiveUsers,
  selectActiveUsersCount,
  selectActualUsersStatus,
  selectBannedUsers,
  selectBannedUsersCount,
  selectBannedUsersStatus,
  selectDeletedUsers,
  selectDeletedUsersCount,
  selectDeletedUsersStatus,
  selectUsersRefreshStatus,
  setActualUsersStatus,
  setBannedUsersStatus,
  setDeletedUsersStatus,
  setUsersRefreshStatus,
} from '@admin/store/usersSlice'
import colors from '@admin/theme/constants/colors'
import { getAuraColor } from '@admin/utils//getAuraColor'
import { pluralize } from '@admin/utils//pluralize'

import type { TAny } from '@yzzy/types'

function Users() {
  const dispatch = useAppDispatch()
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const { tabId = UsersTabs.ACTIVE } = useParams<{ tabId: UsersTabs }>()
  const apiReference = useGridApiRef()

  dayjs.extend(duration)
  dayjs.extend(relativeTime)

  const columns = useAppSelector((state) => state.columns.data)
  const currentColumns = useAppSelector((state) => state.columns.currentData)
  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const activeUsers = useAppSelector(selectActiveUsers)
  const bannedUsers = useAppSelector(selectBannedUsers)
  const deletedUsers = useAppSelector(selectDeletedUsers)
  const actualUsersStatus = useAppSelector(selectActualUsersStatus)
  const bannedUsersStatus = useAppSelector(selectBannedUsersStatus)
  const deletedUsersStatus = useAppSelector(selectDeletedUsersStatus)
  const refreshStatus = useAppSelector(selectUsersRefreshStatus)
  const activeUsersCount = useAppSelector(selectActiveUsersCount)
  const bannedUsersCount = useAppSelector(selectBannedUsersCount)
  const deletedUsersCount = useAppSelector(selectDeletedUsersCount)
  const updateCommentStatus = useAppSelector(selectUpdateCommentStatus)

  const [changeStatusModal, setChangeStatusModal] = useState(false)
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [columnsDrawer, setColumnsDrawer] = useState<boolean>(false)
  const [sortingDrawer, setSortingDrawer] = useState<boolean>(false)
  const [changeStatusItem, setChangeStatusItem] = useState<TAny>(null)
  const [term, setTerm] = useState(0)
  const [initialUsersCount, setInitialUsersCount] = useState<number>(0)
  const [selectionModel] = useState<GridRowSelectionModel>([])
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const open = Boolean(anchorElement)

  useDocumentTitle(`YZZY users - ${startCase(tabId === 'active' ? 'Actual users' : tabId + ' users')}`)

  const isCorrectPage = useMemo(() => tabId && Object.values(UsersTabs).includes(tabId), [tabId])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        fetchUsersGrid(0, newPaginationModel.pageSize)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      fetchUsersGrid(newPaginationModel.page, newPaginationModel.pageSize)

      return newPaginationModel
    })
  }

  const [currentUser, setCurrentUser] = useState<TAny>(null)
  const [comment, setComment] = useState<string>('')
  const [editCommentModal, setEditCommentModal] = useState<boolean>(false)
  const [cancelEditCommentModal, setCancelEditCommentModal] = useState<boolean>(false)
  const [deleteCommentModal, setDeleteCommentModal] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [commentSaveError, setCommentSaveError] = useState<boolean>(false)
  const [canCommentBeDeleted, setCanCommentBeDeleted] = useState<boolean>(false)
  const [fetchingError, setFetchingError] = useState<boolean>(false)

  const openCommentModal = useCallback((data: TAny) => {
    setCurrentUser(data.userInfo.user_id)
    setComment(data.comment)
    setCanCommentBeDeleted(data.comment !== '')
    setDirty(false)
    setCommentSaveError(false)
    setEditCommentModal(true)
  }, [])

  const setCommentValue = useCallback((event: TAny) => {
    setDirty(true)
    setComment(event.target.value)
  }, [])

  const closeEditCommentModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setEditCommentModal(false)
  }, [])

  const closeDeleteCommentModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setDeleteCommentModal(false)
  }, [])

  const saveEditComment = useCallback(async () => {
    setCommentSaveError(false)
    const result = await dispatch(fetchUpdateComment({ comment: comment, profileId: currentUser! }))

    if (result.meta.requestStatus === 'fulfilled') {
      setEditCommentModal(false)
      enqueueSnackbar(`Comment has been saved`, { variant: 'success' })
      await fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
    } else if (result.meta.requestStatus === 'rejected') {
      setCommentSaveError(true)
    }
  }, [comment, currentUser])

  const copyComment = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation()

    if (!value) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      })

      return
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      })

      return navigator.clipboard.writeText(value)
    }
  }

  const deleteComment = useCallback(async () => {
    const result = await dispatch(fetchUpdateComment({ comment: '', profileId: currentUser! }))

    if (result.meta.requestStatus === 'fulfilled') {
      setEditCommentModal(false)
      setComment('')
      setDeleteCommentModal(false)
      enqueueSnackbar(`Comment has been deleted`, { variant: 'success' })
      await fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
    } else {
      setDeleteCommentModal(false)
      setEditCommentModal(false)
      enqueueSnackbar(`Deleting error, try again later`, { variant: 'error' })
    }
  }, [comment, currentUser])

  const cancelEditingComment = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setCancelEditCommentModal(false)
    setEditCommentModal(false)
    setComment('')
    setCurrentUser(null)
  }, [])

  const customCellRender: TAny = {
    occupation_id: (props: GridRenderCellParams) => <span>{props.value ? props.value : '—'}</span>,
    birth_date: (props: GridRenderCellParams) => (
      <div>
        {dayjs(props.value).format('MM/DD/YYYY')}
        <span className="age"> ({dayjs().diff(dayjs(props.value), 'year')} y.o.)</span>
      </div>
    ),
    commentBanned: (props: GridRenderCellParams) => {
      if (props.value === '' || props.value === undefined) {
        return <>—</>
      }

      return (
        <div className="deletedUsers--commentContainer">
          <Tooltip title={props.value} placement="top-end" followCursor>
            <Typography className="deletedUsers--comment" color="text.secondary" variant="caption">
              {props.value}
            </Typography>
          </Tooltip>
          {props.row?.deactivatedByInfo?.memberId ? (
            <Tooltip
              title="Edit comment"
              className="deletedUsers--commentIcon"
              onClick={() => openCommentModal(props.row)}
              placement="top-end"
              followCursor
            >
              <IconButton size="small" sx={{ p: '2px' }}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Copy" className="deletedUsers--commentIcon" placement="top-end" followCursor>
              <IconButton onClick={(event) => copyComment(event, props.value ?? '')} size="small" sx={{ p: '2px' }}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )
    },
    commentDeleted: (props: GridRenderCellParams) => {
      let commentElement
      const emptyComment = props.value === '' || props.value === undefined || props.value === null

      if (emptyComment) {
        commentElement = (
          <Typography color={colors.outlinedBorder} variant="caption">
            No comment
          </Typography>
        )
      } else {
        commentElement = (
          <Tooltip title={props.value} placement="top-end" followCursor>
            <Typography className="deletedUsers--comment" color="text.secondary" variant="caption">
              {props.value}
            </Typography>
          </Tooltip>
        )
      }

      return (
        <div className="deletedUsers--commentContainer">
          {commentElement}
          <Tooltip
            title={emptyComment ? 'Add comment' : 'Edit comment'}
            className="deletedUsers--commentIcon"
            onClick={() => openCommentModal(props.row)}
            placement="top-end"
            followCursor
          >
            <IconButton size="small" sx={{ p: '2px' }}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )
    },
    deletedBy: (props: GridRenderCellParams) => {
      if (props.row?.deactivatedByInfo?.memberId) {
        return (
          <AvatarItem
            avatarWidth={32}
            avatarHeight={32}
            primaryText={props.value?.memberFirstName + ' ' + props.value?.memberLastName}
            src={props.value?.memberAvatarUrl}
            subText={props.value?.memberRole}
          />
        )
      } else {
        return (
          <Stack alignItems="center" direction="row" p="4px 6px 6px 6px" spacing={1}>
            <Avatar sx={{ width: 32, bgcolor: alpha(colors.primary, 0.08), height: 32 }}>
              <SystemIcon sx={{ color: colors.textSecondary }} />
            </Avatar>
            <Typography color="text.secondary" variant="body2">
              Automatically
            </Typography>
          </Stack>
        )
      }
    },
    email: (props: GridRenderCellParams) => {
      return (
        <div className="deletedUsers--emailContainer">
          <Tooltip title={props.value} placement="top-end" followCursor>
            <div className="deletedUsers--email">{props.value || '—'}</div>
          </Tooltip>
        </div>
      )
    },
    followers: (props: GridRenderCellParams) => {
      return <span>{props.row?.followers_count}</span>
    },
    initiatedAt: (props: GridRenderCellParams) => {
      const date = dayjs(props.value)

      return (
        <div>
          <Typography color="text.primary" variant="body2">
            {date.format('MM/DD/YYYY')}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {date.format('hh:mm A')}
          </Typography>
        </div>
      )
    },
    latestActivityDate: (props: GridRenderCellParams) => <div>{props.value?.toLocaleDateString('en-US')}</div>,
    level_aura: (props: GridRenderCellParams) => (
      <Chip
        label={startCase(props.value?.toLowerCase())}
        sx={{
          '&:before': {
            background: `linear-gradient(to right, ${getAuraColor(props.value).gradientBackgroundColors.join(', ')})`,
          },
          background: getAuraColor(props.value).chipBackground,
          color: getAuraColor(props.value).color,
        }}
        className="level-aura-chip"
        size="small"
      />
    ),
    location: (props: GridRenderCellParams) => <span>{props.value ? props.value : '—'}</span>,
    passions: (props: GridRenderCellParams) => {
      const displayedPassions = props.value ? props.value.slice(0, 4) : []
      const hiddenPassions = props.value ? props.value.slice(4) : []

      return displayedPassions.length ? (
        <div className="user-passions">
          {displayedPassions.map((item: string) => (
            <Chip key={item} label={item} className="user-passions__chip" size="small" />
          ))}
          {hiddenPassions.length > 0 && (
            <Tooltip title={hiddenPassions.join(', ')} placement="top-end" followCursor>
              <Chip label="..." className="user-passions__chip user-passions__chip user-passions__chip--pointer" size="small" />
            </Tooltip>
          )}
        </div>
      ) : (
        <>—</>
      )
    },
    registered_at: (props: GridRenderCellParams) => (props.value ? <div>{dayjs(props.value).format('MM/DD/YYYY, hh:mm A')}</div> : <>—</>),
    status: (props: GridRenderCellParams) => {
      return (
        <Select
          sx={{
            '&::before, &::after': { border: 'none!important' },
            '.MuiInput-input:focus': { background: 'none' },
            color: statusOptions.find((item: TAny) => item.value === props.value)?.color,
          }}
          onChange={(event: SelectChangeEvent<unknown>) => callChangeStatusModal([props.row], event.target.value)}
          options={statusOptions}
          value={props.value}
          variant="standard"
        />
      )
    },
    subscriptions: (props: GridRenderCellParams) => {
      return <span>{props.row?.subscriptions_count}</span>
    },
    userInfo: (props: GridRenderCellParams) => {
      return (
        <LinkMUI
          className="usersTable--usernameLink"
          component={Link}
          onClick={(event: TAny) => event.stopPropagation()}
          to={`/ui/users/user/${props.value?.user_id}`}
          underline="none"
        >
          <AvatarItem
            levelAura={props.value?.level_aura}
            primaryText={`@${props.value?.user_name}`}
            src={props.value?.avatar_url}
            subText={props.value?.user_id}
          />
        </LinkMUI>
      )
    },
  }

  const cols: GridColDef[] = useMemo(() => {
    // const res = columns.map((item, index) => ({
    //   field: item.columnName,
    //   headerName: item.title,
    //   minWidth: 200,
    //   renderCell: customCellRender[item.columnName] ?? null
    // }));
    return columns.map((item) => {
      const object: TAny = {
        minWidth: 200,
        display: 'flex',
        field: item.columnName,
        flex: 0.7,
        headerName: item.title,
        sortable: false,
      }

      if (item.columnName === 'username') {
        object.field = 'userInfo'
        object.minWidth = 350
        object.renderCell = customCellRender['userInfo']
        object.headerClassName = 'username-header'
      }

      if (item.columnName === 'followers_count') {
        object.field = 'followers'
        object.minWidth = 100
        object.type = 'number'
        object.renderCell = customCellRender['followers']
      }
      if (item.columnName === 'subscriptions_count') {
        object.field = 'subscriptions'
        object.minWidth = 100
        object.type = 'number'
        object.renderCell = customCellRender['subscriptions']
      }
      if (customCellRender[item.columnName]) {
        object.renderCell = customCellRender[item.columnName]
      }

      return object
    })
  }, [columns])

  const bannedUserCols: GridColDef[] = useMemo(() => {
    return [
      {
        minWidth: 350,
        field: 'userInfo',
        flex: 0.7,
        headerClassName: 'username-header username-header--no-aura',
        headerName: 'Username',
        renderCell: customCellRender['userInfo'],
        sortable: false,
      },
      {
        minWidth: 216,
        editable: false,
        field: 'profileEmail',
        flex: 0.5,
        headerName: 'Email',
        renderCell: customCellRender['email'],
        sortable: false,
      },
      {
        minWidth: 112,
        field: 'initiatedAt',
        flex: 0.2,
        headerName: 'Banned',
        renderCell: customCellRender['initiatedAt'],
        sortable: false,
      },
      {
        minWidth: 306,
        field: 'deactivatedByInfo',
        flex: 0.7,
        headerClassName: 'banned-header',
        headerName: 'Banned by',
        renderCell: customCellRender['deletedBy'],
        sortable: false,
      },
      {
        minWidth: 400,
        field: 'comment',
        flex: 0.7,
        headerName: 'Comment',
        renderCell: (parameters: GridRenderCellParams) => <RenderCommentBannedCell openCommentModal={openCommentModal} props={parameters} />,
        sortable: false,
      },
    ]
  }, [])

  const deletedUserCols: GridColDef[] = useMemo(() => {
    return [
      {
        minWidth: 350,
        field: 'userInfo',
        flex: 0.7,
        headerClassName: 'username-header username-header--no-aura',
        headerName: 'Username',
        renderCell: customCellRender['userInfo'],
        sortable: false,
      },
      {
        minWidth: 216,
        editable: false,
        field: 'profileEmail',
        flex: 0.5,
        headerName: 'Email',
        renderCell: customCellRender['email'],
        sortable: false,
      },
      {
        minWidth: 112,
        field: 'initiatedAt',
        flex: 0.2,
        headerName: 'Initiated',
        renderCell: customCellRender['initiatedAt'],
        sortable: false,
      },
      {
        minWidth: 306,
        field: 'deactivatedByInfo',
        flex: 0.7,
        headerClassName: 'banned-header',
        headerName: 'Deleted by',
        renderCell: customCellRender['deletedBy'],
        sortable: false,
      },
      {
        minWidth: 112,
        field: 'deadlineAt',
        flex: 0.2,
        headerName: 'Deadline',
        renderCell: (parameters: GridRenderCellParams) => <RenderDeadlineCell props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 400,
        field: 'comment',
        flex: 0.7,
        headerName: 'Comment',
        renderCell: (parameters: GridRenderCellParams) => <RenderCommentDeletedCell openCommentModal={openCommentModal} props={parameters} />,
        sortable: false,
      },
    ]
  }, [])

  const usersCount: number = useMemo(() => {
    if (tabId === UsersTabs.ACTIVE) {
      return activeUsersCount || 0
    }
    if (tabId === UsersTabs.BANNED) {
      return bannedUsersCount || 0
    }
    if (tabId === UsersTabs.DELETED) {
      return deletedUsersCount || 0
    }

    return 0
  }, [activeUsersCount, bannedUsersCount, deletedUsersCount, tabId])

  const rows: [] | TAny[] = useMemo(() => {
    if (tabId === UsersTabs.ACTIVE) {
      return activeUsers ? activeUsers : []
    }
    if (tabId === UsersTabs.BANNED) {
      return bannedUsers ? bannedUsers : []
    }
    if (tabId === UsersTabs.DELETED) {
      return deletedUsers ? deletedUsers : []
    }

    return []
  }, [activeUsers, bannedUsers, deletedUsers, tabId])

  const handleTabChange = async (event: ChangeEvent<{}>, value?: string) => {
    navigate(`/ui/users/${value}`)
  }

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/users/${UsersTabs.ACTIVE}`)

      return
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'))
    }
  }, [])

  const tableScrollTop = useCallback(() => {
    apiReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const fetchData = useCallback(async () => {
    Promise.all([
      await dispatch(fetchColumns('users')),
      dispatch(fetchFilters('users')),
      dispatch(fetchFilterPresets('users')),
      dispatch(fetchColumnsPresets('users')),
      dispatch(fetchSortingPresets('users')),
      dispatch(fetchSortingColumns('users')),
    ]).then((result) => {
      result.forEach((value: TAny) => {
        if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
          showBoundary(new Error(value.error?.message))
        }
      })
    })
  }, [])

  const fetchUsersInitial = useCallback(
    async (page: number, pageSize: number, type?: string) => {
      try {
        await dispatch(
          fetchUsers({
            page,
            pageSize,
            type: type || tabId,
          }),
        )
          .unwrap()
          .then((originalPromiseResult: TAny) => {
            setInitialUsersCount(originalPromiseResult.allResultsCount)
          })
      } catch (error) {
        showBoundary(error)
      }
    },
    [tabId],
  )

  const fetchUsersGrid = useCallback(
    async (page: number, pageSize: number, type?: string) => {
      dispatch(fetchUsers({ page, pageSize, type: type || tabId })).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
    },
    [dispatch, tabId],
  )

  const initialFetchData = useCallback(async () => {
    switch (tabId) {
      case UsersTabs.ACTIVE:
        dispatch(setActualUsersStatus('loading'))
        break
      case UsersTabs.BANNED:
        dispatch(setBannedUsersStatus('loading'))
        break
      case UsersTabs.DELETED:
        dispatch(setDeletedUsersStatus('loading'))
        break
    }

    setPaginationModel({ page: 0, pageSize: 25 })
    dispatch(clearData())
    dispatch(clearFilters())

    await fetchData()

    if (tabId !== 'active') {
      dispatch(
        setSortingData([
          {
            id: 'initiated_at',
            title: 'Initiated',
            columnId: 'initiated_at',
            sortingType: 'DESC',
          },
        ]),
      )
    } else {
      dispatch(clearSortingData())
    }

    await fetchUsersInitial(paginationModel.page, paginationModel.pageSize)
  }, [paginationModel, tabId])

  const refreshData = useCallback(async () => {
    try {
      dispatch(setUsersRefreshStatus('loading'))
      const result = await dispatch(
        fetchUsers({
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
          type: tabId,
        }),
      )

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Data has been refreshed', { variant: 'success' as VariantType })
        setFetchingError(false)
      }
      if (result.meta.requestStatus === 'rejected') {
        setFetchingError(true)
        enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
      }
    } catch {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
      setFetchingError(true)
    }
  }, [dispatch, paginationModel])

  const repeatRequestUsers = useCallback(async () => {
    fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
  }, [dispatch, paginationModel])

  useEffect(() => {
    dispatch(clearFilters())
    dispatch(clearColumns())
    initialFetchData()
  }, [dispatch, tabId])

  const changeTerm = useCallback((value: TAny) => {
    setTerm(value)
  }, [])

  const callChangeStatusModal = useCallback((data: TAny, value: TAny) => {
    setChangeStatusModal(true)
    setChangeStatusItem({ data: data, value: value })
  }, [])

  const handleChangeStatusModalClose = useCallback(() => {
    setChangeStatusModal(false)
    setChangeStatusItem(null)
  }, [])

  const handleChangeStatus = useCallback(async (data: TAny) => {
    await dispatch(fetchChangeStatus(data))
    handleChangeStatusModalClose()
  }, [])

  const handleMultipleChangeStatus = useCallback(
    (value: number) => {
      handleClose()
      const data = rows.filter((item: TAny) => selectionModel.includes(item.id))

      callChangeStatusModal(data, value)
    },
    [selectionModel],
  )

  const statusOptions = [
    { color: '#4CA478', text: 'Active', value: 0 },
    { color: '#B42929', text: 'Restricted', value: 1 },
    { color: '#70778F', text: 'Banned', value: 2 },
  ]

  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [columnsDrawerCloseConfirmModal, setColumnsDrawerCloseConfirmModal] = useState<boolean>(false)

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const onColumnsSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(columns, currentColumns) || apply === true) {
        setColumnsDrawerCloseConfirmModal(false)
        setColumnsDrawer(false)
        dispatch(syncColumnsData())
      } else {
        setColumnsDrawerCloseConfirmModal(true)
      }
    },
    [columns, currentColumns],
  )

  const cancelCloseColumnsSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setColumnsDrawerCloseConfirmModal(false)
  }, [])

  const clearFiltersFunction = useCallback(() => {
    dispatch(clearFilters())
    fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
  }, [])

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((value) => value !== null).length
  }, [filtersData])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength)

  return (
    <div className="users-container">
      <Typography color="text.primary" variant="h6">
        YZZY users&nbsp;
        <Typography color="text.secondary" component="span" display="inline" variant="h6">
          {usersCount === 0
            ? ''
            : filtersLength !== 0
              ? usersCount && `(${pluralize(usersCount, 'result')} out of ${pluralize(initialUsersCount, 'user')})`
              : `(${usersCount})`}
        </Typography>
      </Typography>
      <TabContext value={tabId}>
        <TabList className="users-tabs" onChange={handleTabChange}>
          <Tab label="Actual users" value="active" />
          <Tab label="Banned users" value="banned" />
          <Tab label="Deleted users" value="deleted" />
        </TabList>
        <TabPanel value="active">
          <div className="users-table-container">
            <div className="users-table-buttons">
              <Button
                className="users-table-button"
                disabled={isDisabledFilterButton}
                onClick={() => setFilterDrawer(true)}
                size="small"
                startIcon={<FilterIcon />}
                variant="outlined"
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                className="users-table-button"
                onClick={() => setSortingDrawer(true)}
                size="small"
                startIcon={<SortIcon />}
                variant="outlined"
                disabled
              >
                Sort
              </Button>
              <Button
                className="users-table-button"
                disabled={actualUsersStatus === 'loading'}
                onClick={() => setColumnsDrawer(true)}
                size="small"
                startIcon={<ColumnsIcon />}
                variant="outlined"
              >
                Columns
              </Button>
              <LoadingButton
                className="users-table-button"
                disabled={refreshStatus === 'loading'}
                loading={refreshStatus === 'loading'}
                loadingPosition="start"
                onClick={() => refreshData()}
                size="small"
                startIcon={<RefreshIcon />}
              >
                Refresh data
              </LoadingButton>
              {selectionModel.length > 0 && (
                <Button className="users-table-button" onClick={handleClick} startIcon={<ChangeIcon />}>
                  Change status
                </Button>
              )}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                anchorEl={anchorElement}
                onClose={handleClose}
                open={open}
              >
                <MenuItem onClick={() => handleMultipleChangeStatus(0)}>Active</MenuItem>
                <MenuItem onClick={() => handleMultipleChangeStatus(1)}>Restricted</MenuItem>
                <MenuItem onClick={() => handleMultipleChangeStatus(2)}>Banned</MenuItem>
              </Menu>
            </div>
            {filtersLength !== 0 && (
              <ActiveFilterList
                refreshData={() => {
                  setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
                  fetchUsersGrid(paginationModel.page, paginationModel.pageSize).then(tableScrollTop)
                }}
              />
            )}
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter={isEmptyData && !fetchingError}
                  hideFooterSelectedRowCount
                  slots={{
                    footer: CustomFooter,
                    noRowsOverlay: () =>
                      NoUsersData({
                        clearFilters: () => clearFiltersFunction(),
                        fetchingError: fetchingError,
                        isFiltersApplied: !!filtersLength,
                        refreshData: () => repeatRequestUsers(),
                        renderNoDataComponent: () => (
                          <Typography color="text.secondary" variant="body2">
                            Not a single user has registered in the YZZY app
                          </Typography>
                        ),
                      }),
                  }}
                  sx={{
                    '.MuiDataGrid-cell': {
                      borderBottom: 'none',
                      margin: '0',
                      padding: '0',
                    },
                    '.MuiDataGrid-row': {
                      padding: '10px 0',
                    },
                  }}
                  apiRef={apiReference}
                  // rowHeight={72}
                  columnHeaderHeight={64}
                  columns={cols}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.userInfo.user_id}
                  // checkboxSelection
                  loading={actualUsersStatus === 'loading'}
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[10, 25, 50, 100]}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  // onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                  //   setSelectionModel(newSelectionModel);
                  // }}
                  // rowSelectionModel={selectionModel}
                  rowCount={activeUsersCount}
                  rows={rows}
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableRowSelectionOnClick
                  pagination
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value="banned">
          <div className="users-table-container bannedUsers--container">
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter={isEmptyData && !fetchingError}
                  hideFooterSelectedRowCount
                  slots={{
                    footer: CustomFooter,
                    noRowsOverlay: () =>
                      NoUsersData({
                        clearFilters: () => clearFiltersFunction(),
                        fetchingError: fetchingError,
                        isFiltersApplied: !!filtersLength,
                        refreshData: () => repeatRequestUsers(),
                        renderNoDataComponent: () => (
                          <Typography color="text.secondary" variant="body2">
                            There are no banned users
                          </Typography>
                        ),
                      }),
                  }}
                  sx={{
                    '.MuiDataGrid-cell': {
                      borderBottom: 'none',
                      margin: '0',
                      padding: '0',
                    },
                  }}
                  // rowHeight={72}
                  columnHeaderHeight={64}
                  columns={bannedUserCols}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.userInfo.user_id}
                  // checkboxSelection
                  loading={bannedUsersStatus === 'loading'}
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[10, 25, 50, 100]}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  rowCount={bannedUsersCount}
                  rows={rows}
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableRowSelectionOnClick
                  // onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                  //   setSelectionModel(newSelectionModel);
                  // }}
                  // rowSelectionModel={selectionModel}
                  pagination
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value="deleted">
          <div className="users-table-container deletedUsers--container">
            <Box sx={{ flex: 1, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter={isEmptyData && !fetchingError}
                  hideFooterSelectedRowCount
                  slots={{
                    footer: CustomFooter,
                    noRowsOverlay: () =>
                      NoUsersData({
                        clearFilters: () => clearFiltersFunction(),
                        fetchingError: fetchingError,
                        isFiltersApplied: !!filtersLength,
                        refreshData: () => repeatRequestUsers(),
                        renderNoDataComponent: () => (
                          <Typography color="text.secondary" variant="body2">
                            There are no deleted users
                          </Typography>
                        ),
                      }),
                  }}
                  sx={{
                    '.MuiDataGrid-cell': {
                      borderBottom: 'none',
                      margin: '0',
                      padding: '0',
                    },
                  }}
                  columnHeaderHeight={64}
                  columns={deletedUserCols}
                  getRowHeight={() => 'auto'}
                  // rowHeight={72}
                  getRowId={(row) => row.userInfo.user_id}
                  // checkboxSelection
                  loading={deletedUsersStatus === 'loading'}
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[10, 25, 50, 100]}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  // onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                  //   setSelectionModel(newSelectionModel);
                  // }}
                  // rowSelectionModel={selectionModel}
                  rowCount={deletedUsersCount}
                  rows={rows}
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableRowSelectionOnClick
                  pagination
                />
              </Box>
            </Box>
          </div>
        </TabPanel>

        <Modal onClose={handleChangeStatusModalClose} open={changeStatusModal}>
          <>
            <div className="changeStatusModal-header">
              You are going to change {changeStatusItem?.data.length > 1 ? changeStatusItem?.data.length : ``} user's status to
              <div>{statusOptions.find((item) => item.value === changeStatusItem?.value)?.text}:</div>
            </div>
            <div style={{ flex: 1, marginBottom: 40, maxHeight: 175, overflowY: 'auto' }}>
              {changeStatusItem?.data.map((item: TAny) => (
                <AvatarItem
                  levelAura={'DEFAULT'}
                  primaryText={item.baseInfo?.userId}
                  src={item.baseInfo?.avatarUrl}
                  subText={item.baseInfo?.nickname}
                />
              ))}
            </div>
            {changeStatusItem?.value === 2 && (
              <div>
                <div className="changeStatusModal-header">Details</div>
                <div>
                  <Select
                    options={[
                      { text: 'Violence', value: 0 },
                      { text: 'Nudity', value: 1 },
                      { text: 'Inappropriate content', value: 2 },
                    ]}
                    placeholder="Violation category"
                    sx={{ width: 250, marginBottom: '16px' }}
                  />
                </div>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <Select
                    options={[
                      { text: 'period', value: 0 },
                      { text: 'permanently', value: 1 },
                    ]}
                    onChange={(event: SelectChangeEvent<unknown>) => changeTerm(event.target.value)}
                    placeholder="Term"
                    sx={{ width: 150, marginRight: '8px' }}
                    value={term}
                  />
                  {term === 0 && 'of'}
                  {term === 0 && <Input placeholder="Days" sx={{ width: 60, marginLeft: '8px' }} type="number" />}
                </div>
              </div>
            )}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
              <Button onClick={() => handleChangeStatusModalClose()} style={{ marginRight: '16px' }}>
                Cancel
              </Button>
              <Button onClick={() => handleChangeStatus(changeStatusItem)} variant="contained">
                Confirm
              </Button>
            </div>
          </>
        </Modal>

        {editCommentModal && (
          <Modal customstyle={{ width: 444 }} onClose={closeEditCommentModal} open={editCommentModal}>
            <div className="defaultModal--content editCommentModal--container">
              <div className="defaultModal--header">Comment to {tabId} user</div>
              <div className="defaultModal--text">
                <TextField
                  label={'Comment' + (tabId === UsersTabs.DELETED ? ' (optional)' : '')}
                  className="editCommentModal--commentField"
                  inputProps={{ maxLength: 640 }}
                  maxRows={8}
                  minRows={8}
                  onChange={setCommentValue}
                  placeholder="No comment"
                  value={comment}
                  multiline
                />
              </div>
              {commentSaveError && (
                <Alert severity="error" sx={{ marginTop: '24px' }}>
                  Saving error, try again later
                </Alert>
              )}
              <div className="editCommentModal--actions">
                <div>
                  {tabId === UsersTabs.DELETED && (
                    <Button color="error" disabled={!canCommentBeDeleted} onClick={() => setDeleteCommentModal(true)}>
                      Delete comment
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setCancelEditCommentModal(true)
                      } else {
                        closeEditCommentModal()
                      }
                    }}
                    style={{ marginRight: '16px' }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={comment === '' || !dirty}
                    loading={updateCommentStatus === 'loading'}
                    onClick={() => saveEditComment()}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Modal>
        )}
        <Dialog fullWidth maxWidth="xs" open={cancelEditCommentModal}>
          <DialogTitle variant="h6">Cancel {!canCommentBeDeleted ? 'adding' : 'editing'} comment?</DialogTitle>
          <DialogContent>
            <DialogContentText color="text.primary" variant="body1">
              Changes won't be applied
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelEditCommentModal(false)} variant="text">
              Don't cancel
            </Button>
            <Button onClick={cancelEditingComment} type="submit" variant="contained">
              Cancel {!canCommentBeDeleted ? 'adding' : 'editing'}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth maxWidth="xs" open={deleteCommentModal}>
          <DialogTitle variant="h6">Delete comment?</DialogTitle>
          <DialogContent>
            <DialogContentText color="text.primary" variant="body1">
              You are going to delete comment to user <br /> #{currentUser}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteCommentModal} variant="text">
              Cancel
            </Button>
            <LoadingButton
              color="error"
              loading={updateCommentStatus === 'loading'}
              onClick={() => deleteComment()}
              type="submit"
              variant="contained"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <CloseFilterDrawerConfirmModal
          closeModal={() => cancelCloseFilterSidebar()}
          onClose={() => onFilterSidebarClose(true)}
          open={filterDrawerCloseConfirmModal}
        />
        <CloseColumnsDrawerConfirmModal
          closeModal={() => cancelCloseColumnsSidebar()}
          onClose={() => onColumnsSidebarClose(true)}
          open={columnsDrawerCloseConfirmModal}
        />
      </TabContext>
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          onApply={() => {
            onFilterSidebarClose(true)
            setPaginationModel((previousState) => ({ page: 0, pageSize: previousState.pageSize }))
            fetchUsersGrid(0, paginationModel.pageSize).then(tableScrollTop)
          }}
          onClose={onFilterSidebarClose}
          type="users"
        />
      </Drawer>
      <Drawer anchor="right" onClose={() => onColumnsSidebarClose()} open={columnsDrawer}>
        <ColumnsDrawer
          apply={() => {
            onColumnsSidebarClose(true)
            fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
          }}
          close={() => onColumnsSidebarClose()}
          type="users"
        />
      </Drawer>
      <Drawer anchor="right" onClose={() => setSortingDrawer(false)} open={sortingDrawer}>
        <SortingDrawer
          onApply={() => {
            setSortingDrawer(false)
            fetchUsersGrid(paginationModel.page, paginationModel.pageSize)
          }}
          onClose={() => setSortingDrawer(false)}
          type="users"
        />
      </Drawer>
    </div>
  )
}

export default Users
