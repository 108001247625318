import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import TextField from '@admin/components/shared/TextField/TextField'
import { useAppDispatch } from '@admin/store/hooks'
import { fetchSalesTaxCodes } from '@admin/store/slices/Settings/dictionariesSlice'
import { ITaxCode } from '@admin/store/slices/Settings/interface'
import { fetchAddTaxCode, fetchTaxCodes } from '@admin/store/slices/Settings/taxesSlice'
import { ModalAction } from '@admin/types/commonTypes'

import { SalesTaxGroup } from '../constants'
import { ConfirmCancelModal } from './ConfirmCancelModal'
import getValidationSchema from './utils/getValidationSchema'

interface ITaxCodeModalProps {
  onClose: () => void
  open: boolean
  page: number
  pageSize: number
}

export const TaxCodeModal = ({ onClose, open, page, pageSize }: ITaxCodeModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(ModalAction.ADD, SalesTaxGroup.CODES), [])

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      category: '',
      code: '',
      description: '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  const onSubmit = async (data: Pick<ITaxCode, 'category' | 'code' | 'description'>) => {
    trigger()

    const formattedData = {
      category: data.category.trim(),
      code: data.code.trim(),
      description: data.description.trim(),
    }

    try {
      const result = await dispatch(fetchAddTaxCode(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose()
        enqueueSnackbar('Tax code has been added', {
          variant: 'success' as VariantType,
        })
        await dispatch(fetchTaxCodes({ page, pageSize }))
        dispatch(fetchSalesTaxCodes())
      } else if (result.payload.meta) {
        const { title, meta } = result.payload
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITaxCode, 'id' | 'salesTaxCodeId' | 'status'>>

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: meta[key] }))
        } else {
          enqueueSnackbar(`An error occurred while adding the tax code: ${title}`, {
            variant: 'error' as VariantType,
          })
        }
      } else {
        enqueueSnackbar('An error occurred while adding the tax code', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred while adding the tax code', {
        variant: 'error' as VariantType,
      })
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new sales tax code</DialogTitle>
          <DialogContent>
            <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} sx={{ paddingTop: 2 }} container>
              <SimpleGrid pt={2} xs={12} item>
                <TextField
                  {...register('category')}
                  fullWidth
                  label="Category"
                  name="category"
                  error={!!errors.category}
                  helperText={errors.category && (errors.category?.message as string)}
                  onBlur={() => trigger('category')}
                  size="small"
                  type="text"
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('code')}
                  fullWidth
                  label="Tax code"
                  name="code"
                  error={!!errors.code}
                  helperText={errors.code && (errors.code?.message as string)}
                  onBlur={() => trigger('code')}
                  size="small"
                  type="text"
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('description')}
                  fullWidth
                  label="Description"
                  name="description"
                  error={!!errors.description}
                  helperText={errors.description && (errors.description?.message as string)}
                  maxRows={4}
                  onBlur={() => trigger('description')}
                  size="small"
                  type="text"
                  multiline
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (isDirty) {
                  setIsOpenConfirmCancelModal(true)
                } else {
                  handleClose()
                }
              }}
              color="primary"
              variant="text"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              // disabled={!isValid || !isDirty}
              variant="contained"
            >
              Add tax code
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        action={ModalAction.ADD}
        actionName="tax code"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        onClose={handleClose}
        open={isOpenConfirmCancelModal}
      />
    </>
  )
}
