import { createTheme, Shadows, Theme, ThemeOptions } from '@mui/material'

import { PALETTE } from './palette'

const components = {
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: PALETTE['common']['actionSelected'],
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: PALETTE['common']['buttonDisable'],
        padding: '4px',
      },
    },
  },
}

const shadows = [
  ...createTheme({}).shadows.map((shadow, index) =>
    index === 24
      ? '0px 9px 46px 8px rgba(16, 24, 40, 0.12), 0px 24px 38px 3px rgba(16, 24, 40, 0.14), 0px 11px 15px -7px rgba(16, 24, 40, 0.20)'
      : shadow,
  ),
] as Shadows

const typography = {
  errorSubtitle: {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '-1.5px',
    lineHeight: 'normal',
  },
  subtitle1: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '175%',
  },
  subtitle2: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '157%',
  },
  alertTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '150%',
  },
  avatarInitials: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.14px',
    lineHeight: '100%',
  },
  badgeLabel: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.14px',
    lineHeight: '166.667%',
  },
  badgeStatuses: {
    fontFamily: 'Noto Sans Display, monospace',
    fontSize: '8px',
    fontWeight: '600',
    letterSpacing: '0.3px',
    lineHeight: '140%',
  },
  body1: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '150%',
  },
  body2: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.17px',
    lineHeight: '143%',
  },
  body2Bold: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0.5px',
    lineHeight: '143%',
  },
  bodyLarge: {
    fontFamily: 'Noto Sans Display, monospace',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.3px',
    lineHeight: '100%',
  },
  bottomNavigationActiveLabel: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.4px',
    lineHeight: '23.24px',
  },
  buttonLarge: {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.46px',
    lineHeight: '173.333%',
    textTransform: 'uppercase',
  },
  buttonMedium: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.4px',
    lineHeight: '171.429%',
    textTransform: 'uppercase',
  },
  buttonSmall: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.46px',
    lineHeight: '169.231%',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.4px',
    lineHeight: '166%',
  },
  chip: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.16px',
    lineHeight: '138.462%',
  },
  errorTitle: {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '-1.5px',
    lineHeight: 'normal',
  },
  h1: {
    fontSize: '96px',
    fontStyle: 'normal',
    fontWeight: '300',
    letterSpacing: '-1.5px',
    lineHeight: '116.7%',
  },
  h2: {
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: '300',
    letterSpacing: '-0.5px',
    lineHeight: '120%',
  },
  h3: {
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '116.7%',
  },
  h4: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.25px',
    lineHeight: '123.5%',
  },
  h5: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '133.4%',
  },
  h6: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '160%',
  },
  helperText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.4px',
    lineHeight: '166.667%',
  },
  inputLabel: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '100%',
  },
  inputText: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '150%',
  },
  listSubheader: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '342.857%',
  },
  menuItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.15px',
    lineHeight: '150%',
  },
  menuItemDense: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.17px',
    lineHeight: '171.429%',
  },
  overline: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '1px',
    lineHeight: '266%',
    textTransform: 'uppercase' as const,
  },
  tableHeader: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.17px',
    lineHeight: '171.429%',
  },
  tooltip: {
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%',
  },
}

const themeOptions: ThemeOptions = {
  components,
  palette: PALETTE,
  shadows,
  typography,
}

export const theme: Theme = createTheme(themeOptions)
