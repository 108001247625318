import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'
import { SnackbarKey } from 'notistack'

import { AUTH_MEMBERS_API, MEMBERS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { OtherMessages, TErrorMessages } from '@admin/store/types/ErrorMessages'
import { IPermission, LocalStorageKeys, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

export interface IAuthState {
  cooldownTimer: Date | undefined
  errorStatusText: '' | TErrorMessages
  isAuth: string | undefined
  isWarningRedirect: boolean
  memberId: string
  permissions: [] | IPermission[]
  permissionsStatus: TStatus
  refreshToken: string
  snackbarKeys: [] | SnackbarKey[]
  status: TStatus
  token: string
  triesResendLeft: number | undefined
}

const initialState: IAuthState = {
  cooldownTimer: undefined,
  errorStatusText: '',
  isAuth: localStorage.getItem(LocalStorageKeys.IS_AUTH) ?? undefined,
  isWarningRedirect: false,
  memberId: localStorage.getItem(LocalStorageKeys.MEMBER_ID) ?? '',
  permissions: [],
  permissionsStatus: 'loading',
  refreshToken: localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) ?? '',
  snackbarKeys: [],
  status: 'idle',
  token: localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) ?? '',
  triesResendLeft: undefined,
}

interface IResponseError {
  meta: {
    unlockDate: Date
  }
  title: string
}

export const fetchSetUpPassword = createAsyncThunk('auth/setUpPassword', async ({ code, password }: { code: string; password: string }) => {
  await axios
    .post(AUTH_MEMBERS_API + 'members/password-setup', {
      code,
      password,
    })
    .catch((error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw Error(error.response.data.title)
      } else {
        throw Error('An unexpected error occurred')
      }
    })
})

export const fetchSignIn = createAsyncThunk('auth/signIn', async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
  try {
    const response = await axios.post(AUTH_MEMBERS_API + 'members/sign-in', {
      email,
      password,
    })

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error) && rejectWithValue(error.response?.data)
  }
})

export const fetchVerifyOtp = createAsyncThunk('auth/verifyOtp', async ({ email, otp }: { email: string; otp: string }) => {
  const response = await axios
    .post(AUTH_MEMBERS_API + 'members/verify-otp', {
      email,
      password: otp,
    })
    .catch((error: unknown) => {
      if (axios.isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 403)) {
        throw Error(error.response.data.techInfo.title)
      } else {
        throw Error('An unexpected error occurred')
      }
    })

  return response.data
})

export const fetchSignOut = createAsyncThunk('auth/signOut', async (_, thunkAPI) => {
  const rootState: TRootState = (await thunkAPI.getState()) as TRootState

  const result = await axios.post(MEMBERS_API + 'members/sign-out', {
    refreshToken: rootState.auth.refreshToken,
  })

  if (result.status === 401) {
    return thunkAPI.rejectWithValue(401)
  }
})

export const fetchValidateOtp = createAsyncThunk('auth/validateOtp', async ({ code }: { code: string }) => {
  const response = await axios.post(AUTH_MEMBERS_API + 'members/exists-otp', {}, { params: { code: code } }).catch((error: unknown) => {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      throw Error('Unvalidated otp')
    } else {
      throw Error('An unexpected error occurred')
    }
  })

  return response.data
})

export const fetchPermissions = createAsyncThunk('auth/permissions', async () => {
  try {
    const response = await axios.get(MEMBERS_API + `permissions/my`)

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetUpPassword.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchSetUpPassword.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.errorStatusText = ''
      })
      .addCase(fetchSetUpPassword.rejected, (state, { error }) => {
        state.status = 'failed'
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchSignIn.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchSignIn.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.errorStatusText = ''
        state.triesResendLeft = payload.triesResendLeft
        state.cooldownTimer = undefined
      })
      .addCase(fetchSignIn.rejected, (state, { error, payload }) => {
        state.status = 'failed'
        state.triesResendLeft = undefined
        // debugger;
        if (payload) {
          const { title, meta } = payload as IResponseError

          state.errorStatusText = (title ? title : '') as TErrorMessages
          if (meta) {
            state.cooldownTimer = meta.unlockDate
          }
        } else {
          state.errorStatusText = (error.message ?? '') as TErrorMessages
        }
      })
      .addCase(fetchVerifyOtp.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchVerifyOtp.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.errorStatusText = ''
        state.token = payload.accessToken
        state.refreshToken = payload.refreshToken
        state.memberId = payload.memberId
        state.isAuth = 'true'
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, payload.accessToken)
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, payload.refreshToken)
        localStorage.setItem(LocalStorageKeys.MEMBER_ID, payload.memberId)
        localStorage.setItem(LocalStorageKeys.IS_AUTH, 'true')
      })
      .addCase(fetchVerifyOtp.rejected, (state, { error }) => {
        state.status = 'failed'
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchSignOut.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchSignOut.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.errorStatusText = ''
      })
      .addCase(fetchSignOut.rejected, (state, { error }) => {
        state.status = 'failed'
      })
      .addCase(fetchValidateOtp.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchValidateOtp.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.errorStatusText = ''
      })
      .addCase(fetchValidateOtp.rejected, (state, { error }) => {
        state.status = 'failed'
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.permissionsStatus = 'loading'
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
        state.permissions = payload.permissions
        state.permissionsStatus = 'idle'
      })
      .addCase(fetchPermissions.rejected, (state, { payload }) => {
        state.permissionsStatus = 'failed'
      })
  },
  initialState,
  reducers: {
    checkoutErrorMessage: (state, { payload }) => {
      if (payload.showInfoLoginMessage) {
        state.errorStatusText = OtherMessages.SHOULD_ENTER_CREDENTIALS_AGAIN

        return
      }
      state.errorStatusText = ''
    },
    logout: (state, action: PayloadAction<TAny>) => {
      if (action) {
        state.errorStatusText = action.payload.message as TErrorMessages
      }

      state.token = ''
      state.isAuth = undefined
      state.permissions = []
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN)
      localStorage.removeItem(LocalStorageKeys.IS_AUTH)
      localStorage.removeItem(LocalStorageKeys.MEMBER_ID)
      localStorage.removeItem(LocalStorageKeys.MEMBER)
      localStorage.removeItem(LocalStorageKeys.AVATAR)
      localStorage.removeItem(LocalStorageKeys.AVATAR_PREVIEW)
      localStorage.removeItem(LocalStorageKeys.SPEED)
      localStorage.removeItem(LocalStorageKeys.VOLUME)
      localStorage.removeItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP)
    },
    refresh: (state, { payload }) => {
      state.token = payload.accessToken
      state.memberId = payload.memberId
    },
    setIsWarningRedirect: (state, { payload }) => {
      state.isWarningRedirect = payload
    },
    updateSnackbarKeys: (state, { payload }) => {
      state.snackbarKeys = payload
    },
  },
})

export const selectAuthStatus = (state: TRootState): TStatus => state.auth.status
export const selectAuthMemberId = (state: TRootState): string => state.auth.memberId
export const selectIsAuth = (state: TRootState): string | undefined => state.auth.isAuth
export const selectUserPermissions = (state: TRootState): [] | IPermission[] => state.auth.permissions
export const selectUserPermissionsStatus = (state: TRootState): TStatus => state.auth.permissionsStatus
export const selectAuthStatusText = (state: TRootState): string => state.auth.errorStatusText
export const selectAuthTriesResendLeft = (state: TRootState): number | undefined => state.auth.triesResendLeft
export const selectAuthCooldownTimer = (state: TRootState): Date | undefined => state.auth.cooldownTimer
export const selectSnackbarKeys = (state: TRootState): [] | SnackbarKey[] => state.auth.snackbarKeys
export const selectIsWarningRedirect = (state: TRootState): boolean => state.auth.isWarningRedirect

export const { checkoutErrorMessage, logout, refresh, setIsWarningRedirect, updateSnackbarKeys } = authSlice.actions

export default authSlice.reducer
