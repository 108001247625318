import './index.scss'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import EyeClosedIcon from '@admin/assets/img/EyeClosedIcon'
import EyeIcon from '@admin/assets/img/EyeIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import Logo from '@admin/assets/img/Logo'
import AuthLayout from '@admin/components/AuthLayout/AuthLayout'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Skeleton from '@admin/components/shared/Skeleton/Skeleton'
import TextField from '@admin/components/shared/TextField/TextField'
import { action } from '@admin/containers/SnackbarContainer'
import {
  fetchSetUpPassword,
  fetchValidateOtp,
  logout,
  selectAuthMemberId,
  selectAuthStatus,
  selectAuthStatusText,
  selectIsAuth,
  selectSnackbarKeys,
  setIsWarningRedirect,
  updateSnackbarKeys,
} from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { AuthMessages, CommonErrorMessages } from '@admin/store/types/ErrorMessages'

import type { TAny } from '@yzzy/types'

import { SetUpPasswordSchema } from './scheme'

function SetUpPassword() {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [showError, setShowError] = useState({ isShow: false, message: '' })
  const [isValidOtp, setIsValidOtp] = useState(false)

  const statusText = useAppSelector(selectAuthStatusText)
  const status = useAppSelector(selectAuthStatus)
  const snackbarKeys = useAppSelector(selectSnackbarKeys)
  const isAuth = useAppSelector(selectIsAuth)
  const currentMemberId = useAppSelector(selectAuthMemberId)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    formState: { isValid, dirtyFields, errors },
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(SetUpPasswordSchema),
  })

  const [searchParameters] = useSearchParams()
  const code = searchParameters.get('code')
  const isNewMember = searchParameters.get('newMember')
  const memberId = searchParameters.get('memberId')

  useEffect(() => {
    if (!code) {
      if (isAuth) {
        navigate('/ui/users') //TODO поменять на первый доступный раздел после добавления ролей
      } else {
        navigate('/ui/login')
      }
      const key = enqueueSnackbar('Link you have tried to follow is outdated or invalid', {
        autoHideDuration: null,
        action,
        variant: 'warning' as VariantType,
      })

      dispatch(updateSnackbarKeys([...snackbarKeys, key]))

      return
    }

    const initialValidation = async () => {
      setIsValidOtp(false)
      if (isAuth && memberId !== currentMemberId) {
        dispatch(setIsWarningRedirect(true))
        navigate('/ui/users') //TODO поменять на первый доступный раздел после добавления ролей

        return
      }

      await dispatch(fetchValidateOtp({ code: code })).then((result: TAny) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setIsValidOtp(true)
        }
        if (result.meta.requestStatus === 'rejected') {
          setIsValidOtp(false)
          navigate('/ui/login')
          const key = enqueueSnackbar('Link you have tried to follow is outdated or invalid', {
            autoHideDuration: null,
            action,
            variant: 'warning' as VariantType,
          })

          dispatch(updateSnackbarKeys([...snackbarKeys, key]))
        }
      })
    }

    void initialValidation()
  }, [code])

  useEffect(() => {
    switch (statusText) {
      case AuthMessages.BAD_PASSWORD:
        enqueueSnackbar('Password does not match expression', { variant: 'error' as VariantType })
        break
      case AuthMessages.MATCH_OLD_PASSWORD:
        setShowError({ isShow: true, message: 'New password has to be different from the old one' })
        break
      case CommonErrorMessages.UNEXPECTED_ERROR:
        enqueueSnackbar('Password setting up error, try again later', { variant: 'error' as VariantType })
        break
      default:
        setShowError({ isShow: false, message: '' })
    }
  }, [statusText])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const onSubmit = async (data: TAny) => {
    trigger()

    try {
      const result = await dispatch(fetchSetUpPassword({ code: code ?? '', password: data.password }))

      if (result.meta.requestStatus === 'fulfilled') {
        dispatch(logout({ message: '' }))
        navigate('/ui/login')

        if (isNewMember === 'true') {
          enqueueSnackbar('Password has been set up', { variant: 'success' as VariantType })
        } else {
          enqueueSnackbar('Password has been changed. You need to relogin with new credentials', {
            variant: 'success' as VariantType,
          })
        }
      }
    } catch {
      enqueueSnackbar('Password setting up error, try again later', { variant: 'error' as VariantType })
    }
  }

  const info = (
    <div className="authForm-info authForm-info--info">
      <div className="authForm-info-title">
        <InfoIcon /> Password must contain at least:
      </div>
      <ul className="authForm-info-list">
        <li>8-20 characters</li>
        <li>1 upper-case Latin letter</li>
        <li>1 lower-case Latin letter</li>
        <li>1 number</li>
        <li>{`1 special symbol &$@[({<>})]!?:;',~^*#+-=/`}</li>
      </ul>
    </div>
  )

  return (
    <AuthLayout>
      {isValidOtp ? (
        <div className="authContainer">
          <div className="authForm-container">
            <Logo isStatic={true} />
            <p className="authForm-text">Enter {isNewMember === 'true' ? 'your' : 'new'} password</p>
            {info}
            <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register('password')}
                fullWidth={true}
                label={isNewMember === 'true' ? 'Password' : 'New password'}
                name="password"
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        className="authForm-button--minWidth"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={({ target }) => {
                  setValue('password', target.value, { shouldDirty: true })
                  trigger('password')
                }}
                onChange={async ({ target }) => {
                  if (dirtyFields.repeatPassword) {
                    await trigger('repeatPassword')
                  }

                  setShowError({ isShow: false, message: '' })
                  reset({}, { keepDirty: true, keepValues: true })
                  setValue('password', target.value, { shouldDirty: false })
                }}
                className="authForm-field"
                defaultValue=""
                error={!!errors.password || showError.isShow}
                type={showPassword ? 'text' : 'password'}
              />
              {(errors.password || showError.isShow) && (
                <FormHelperText className="authForm-helperText" error>
                  {showError.isShow ? showError.message : (errors.password?.message as string)}
                </FormHelperText>
              )}
              {dirtyFields.password && !errors.password && !showError.isShow && (
                <FormHelperText className="authForm-helperText authForm-helperText--success">Safe password</FormHelperText>
              )}
              <TextField
                {...register('repeatPassword')}
                fullWidth={true}
                label={isNewMember === 'true' ? 'Repeat password' : 'Repeat new password'}
                name="repeatPassword"
                InputProps={{
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        className="authForm-button--minWidth"
                        onClick={() => setShowRepeatPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showRepeatPassword ? <EyeClosedIcon /> : <EyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={({ target }) => {
                  setValue('repeatPassword', target.value, { shouldDirty: true })
                  trigger('repeatPassword')
                }}
                onChange={({ target }) => {
                  trigger('repeatPassword')
                  setValue('repeatPassword', target.value, { shouldValidate: false, shouldDirty: true })
                }}
                className="authForm-field"
                defaultValue=""
                error={!!errors.repeatPassword}
                type={showRepeatPassword ? 'text' : 'password'}
              />
              {dirtyFields.repeatPassword && errors.repeatPassword && (
                <FormHelperText className="authForm-helperText" error>
                  {errors.repeatPassword?.message as string}
                </FormHelperText>
              )}
              {((dirtyFields.repeatPassword && !errors.repeatPassword && !showError.isShow) || isValid) && (
                <FormHelperText className="authForm-helperText authForm-helperText--success">Passwords match</FormHelperText>
              )}
              <LoadingButton
                fullWidth={true}
                className="authForm-field"
                color="primary"
                disabled={!isValid}
                loading={status === 'loading'}
                loadingPosition="start"
                size="large"
                startIcon={null}
                type="submit"
                variant="contained"
              >
                Confirm
              </LoadingButton>
            </form>
          </div>
        </div>
      ) : (
        <Skeleton width={444} height={602} sx={{ borderRadius: '8px' }} variant="rectangular" />
      )}
    </AuthLayout>
  )
}

export default SetUpPassword
