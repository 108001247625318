import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import { IPaymentFee, PaymentFeesStatuses } from '@admin/store/slices/Settings/interface'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { possiblePaymentFeesStatuses } from '../constants'

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {IPaymentFee|null} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  isMenuOpen: boolean
  onClick: (event: MouseEvent<HTMLDivElement>, data: TAny) => void
  props: GridRenderCellParams
  selectedCellData?: IPaymentFee | null
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({ isMenuOpen, onClick, props, selectedCellData }: IRenderStatusSelectProps) => {
  const { row } = props
  const userPermissions = useAppSelector(selectUserPermissions)

  if (!row.providerTariffId) {
    return null
  }
  const isInactive = row.feeStatus?.toUpperCase() === PaymentFeesStatuses.INACTIVE
  const hasAccess = checkPermissions(userPermissions, [Permissions.SETTINGS_CHANGE_PAYMENT_FEE_STATUS])
  const currentStatus = possiblePaymentFeesStatuses.find((element) => row.feeStatus?.toLowerCase() === element.status.toLowerCase())
  const color = currentStatus?.color

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (isInactive && hasAccess) {
      onClick(event, row)
    }
  }

  return (
    <div className={`fees-table__status-select${!(isInactive && hasAccess) ? '--no-pointer' : ''}`} onClick={handleClick} style={{ color }}>
      <Typography color={color} variant="chip">
        {row.feeStatus?.replace(row.feeStatus, currentStatus?.title)}
      </Typography>
      {isInactive &&
        hasAccess &&
        (selectedCellData?.providerTariffId === row.providerTariffId && isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </div>
  )
}
