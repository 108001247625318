export const BYTE = 1
export const KILOBYTE = 1024 * BYTE
export const MEGABYTE = 1024 * KILOBYTE
export const ACCEPTED_FILES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const possibleStatuses = {
  ACTIVE: [
    {
      title: 'Deactivated',
      status: 'DEACTIVATED',
    },
    {
      title: 'Needs review',
      status: 'NEEDS_REVIEW',
    },
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
  ARCHIVE: [
    {
      title: 'Active',
      status: 'ACTIVE',
    },
    {
      title: 'Deactivated',
      status: 'DEACTIVATED',
    },
    {
      title: 'Needs review',
      status: 'NEEDS_REVIEW',
    },
  ],
  ARCHIVE_FOREVER: [
    {
      title: 'Active',
      status: 'ACTIVE',
    },
    {
      title: 'Deactivated',
      status: 'DEACTIVATED',
    },
    {
      title: 'Needs review',
      status: 'NEEDS_REVIEW',
    },
  ],
  DEACTIVATED: [
    {
      title: 'Active',
      status: 'ACTIVE',
    },
    {
      title: 'Needs review',
      status: 'NEEDS_REVIEW',
    },
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
  MASS: [
    {
      title: 'Active',
      status: 'ACTIVE',
    },
    {
      title: 'Deactivated',
      status: 'DEACTIVATED',
    },
    {
      title: 'Needs review',
      status: 'NEEDS_REVIEW',
    },
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
  NEEDS_REVIEW: [
    {
      title: 'Active',
      status: 'ACTIVE',
    },
    {
      title: 'Deactivated',
      status: 'DEACTIVATED',
    },
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
  PENDING: [
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
  TIME_OUT: [
    {
      title: 'Archived',
      status: 'ARCHIVE',
    },
  ],
}

export const allPossibleStatuses = [
  {
    title: 'Active',
    color: '#4CA478',
    status: 'ACTIVE',
  },
  {
    title: 'Deactivated',
    color: '#70778F',
    status: 'DEACTIVATED',
  },
  {
    title: 'Needs review',
    color: '#DA612B',
    status: 'NEEDS_REVIEW',
  },
  {
    title: 'Archived',
    color: '#BCC0CD',
    status: 'ARCHIVE',
  },
  {
    title: 'Timed out',
    color: '#B42929',
    status: 'TIME_OUT',
  },
  {
    title: 'Pending',
    color: '#70778F',
    status: 'PENDING',
  },
]
