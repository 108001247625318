import { createSvgIcon } from '@mui/material'

const LikeIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M15.7376 4.89169C14.7756 5.24136 13.9638 5.95495 13.3848 6.53327C12.6547 7.26245 11.4202 7.26245 10.6901 6.53327C10.1201 5.96395 9.3083 5.2714 8.34499 4.93171C7.44443 4.61415 6.37554 4.58843 5.14465 5.38734C3.88537 6.55847 3.67554 8.06708 3.96294 9.618C4.25499 11.194 5.04865 12.6948 5.62051 13.6039C8.43633 17.3797 10.6037 18.9351 11.5234 19.3604C11.8103 19.4697 12.1651 19.4697 12.452 19.3604C13.3717 18.9351 15.539 17.3797 18.3549 13.6039C18.9266 12.6951 19.722 11.192 20.026 9.61227C20.3261 8.05251 20.1327 6.54296 18.9175 5.37692C17.6896 4.53876 16.6305 4.56715 15.7376 4.89169ZM15.1569 3.29396C16.5418 2.79059 18.2039 2.80335 19.9414 4.01811C19.9743 4.04106 20.0054 4.0663 20.0347 4.09365C21.867 5.80563 22.0636 8.01957 21.6953 9.93352C21.3304 11.8301 20.3965 13.5562 19.7746 14.5396C19.7631 14.5578 19.7509 14.5756 19.738 14.5929C16.8089 18.5271 14.4152 20.3363 13.1355 20.9172C13.1236 20.9226 13.1116 20.9277 13.0995 20.9326C12.3992 21.2123 11.5761 21.2123 10.8759 20.9326C10.8638 20.9277 10.8518 20.9226 10.8399 20.9172C9.56019 20.3363 7.1665 18.5271 4.2374 14.5929C4.22452 14.5756 4.2123 14.5578 4.20078 14.5396C3.57912 13.5566 2.64355 11.8281 2.2914 9.92775C1.93517 8.00536 2.1647 5.79104 4.05012 4.08451C4.08126 4.05632 4.11446 4.03047 4.14943 4.00719C5.88287 2.853 7.534 2.84314 8.91033 3.32847C10.2368 3.79622 11.2668 4.70658 11.8915 5.33044C11.9136 5.3526 11.9629 5.38016 12.0375 5.38016C12.1121 5.38016 12.1613 5.3526 12.1835 5.33044C12.7991 4.71558 13.8291 3.77657 15.1569 3.29396Z"
      fillRule="evenodd"
    />
  </svg>,
  'Link',
)

export default LikeIcon
