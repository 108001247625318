import './index.scss'

import { useMemo, useState } from 'react'

import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'

import Button from '@admin/components/shared/Button/Button'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppSelector } from '@admin/store/hooks'
import { IOperation, OperationTypes, TransactionTypes } from '@admin/store/slices/Payments/interface'
import { selectOperationDetails, selectOperationDetailsStatus } from '@admin/store/slices/Payments/operationsSlice'

import {
  RenderAmountCell,
  RenderInfoCell,
  RenderPayerCell,
  RenderProviderCell,
  RenderRecipientCell,
  RenderSalesTaxAmountCell,
  RenderStatusCell,
  RenderTransactionFeeCell,
  RenderTransactionIDCell,
  RenderTransactionTotalCell,
} from '../../components'

const TransactionsTable = ({ row: rowData }: { row: IOperation }) => {
  const apiReference = useGridApiRef()

  const data = useAppSelector(selectOperationDetails)
  const status = useAppSelector(selectOperationDetailsStatus)

  const isLoading = status === 'loading'
  const isFailed = status === 'failed'

  const isInAppOperation =
    rowData.operationType === OperationTypes.IAP_FOLLOW ||
    rowData.operationType === OperationTypes.IAP_DONATE ||
    rowData.operationType === OperationTypes.IAP_EVENT ||
    rowData.operationType === OperationTypes.IAP_LIFESTYLE

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    currency: false,
    externalOrderId: false,
    salesTaxAmount: false,
    taxJarOrderId: false,
    tierCode: false,
  })

  const handleColumnModel = () => {
    setColumnVisibilityModel((previousModel) => ({
      id: !previousModel.id,
      currency: !previousModel.currency,
      externalOrderId: isInAppOperation ? !previousModel.externalOrderId : false,
      salesTaxAmount: !isInAppOperation ? !previousModel.salesTaxAmount : false,
      taxJarOrderId: !isInAppOperation ? !previousModel.taxJarOrderId : false,
      tierCode: isInAppOperation ? !previousModel.tierCode : false,
    }))
  }

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'hidden',
        flex: 0.1,
        headerName: '',
        renderCell: (parameters) => (
          <Typography color="text.secondary" variant="body2">
            {apiReference.current.getAllRowIds().indexOf(parameters.id) + 1}
          </Typography>
        ),
        sortable: false,
      },
      {
        minWidth: 150,
        field: 'psTransactionId',
        flex: 0.8,
        headerName: 'Transaction ID',
        renderCell: (parameters) => <RenderTransactionIDCell props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 130,
        field: 'provider',
        flex: 0.6,
        headerName: 'Provider / type',
        renderCell: (parameters) => <RenderProviderCell props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 180,
        field: 'id',
        flex: 0.8,
        headerName: 'Internal transaction ID',
        renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 180,
        field: 'externalOrderId',
        flex: 0.8,
        headerName: 'External Order ID',
        renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 160,
        field: 'tierCode',
        flex: 0.8,
        headerName: 'Tier code',
        renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
        sortable: false,
        valueGetter: (_, row) => (row.type === TransactionTypes.GOOGLE_IAP || row.type === TransactionTypes.APPLE_IAP) && rowData.tierCode,
      },
      {
        minWidth: 150,
        field: 'payerId',
        flex: 0.8,
        headerName: 'Payer profile ID',
        renderCell: (parameters) => <RenderPayerCell props={parameters} rowData={rowData} />,
        sortable: false,
      },
      {
        minWidth: 160,
        field: 'recipientProfileId',
        flex: 0.8,
        headerName: 'Recipient profile ID',
        renderCell: (parameters) => <RenderRecipientCell props={parameters} rowData={rowData} />,
        sortable: false,
      },
      {
        minWidth: 115,
        field: 'status',
        flex: 0.4,
        headerName: 'Status',
        renderCell: (parameters) => <RenderStatusCell props={parameters} />,
        sortable: false,
      },
      {
        minWidth: 110,
        field: 'amount',
        flex: 0.4,
        headerName: 'Amount',
        renderCell: (parameters) => {
          return <RenderAmountCell props={parameters} rowData={rowData} />
        },
        sortable: false,
        type: 'number' as const,
      },
      {
        minWidth: 110,
        field: 'fee',
        flex: 0.4,
        headerName: 'Fee',
        renderCell: (parameters) => <RenderTransactionFeeCell props={parameters} rowData={rowData} />,
        sortable: false,
        type: 'number' as const,
      },
      {
        minWidth: 160,
        field: 'taxJarOrderId',
        flex: 0.7,
        headerName: 'Tax transaction ID',
        renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
        sortable: false,
        valueGetter: (_, row) => row.type === TransactionTypes.CARD_PAYMENTS && rowData.taxJarOrderId,
      },
      {
        minWidth: 150,
        field: 'salesTaxAmount',
        flex: 0.7,
        headerName: 'Sales tax amount',
        renderCell: (parameters) => {
          return <RenderSalesTaxAmountCell props={parameters} rowData={rowData} />
        },
        sortable: false,
        type: 'number' as const,
      },
      {
        minWidth: 100,
        field: 'total',
        flex: 0.4,
        headerName: 'Total',
        renderCell: (parameters) => <RenderTransactionTotalCell props={parameters} rowData={rowData} />,
        sortable: false,
        type: 'number',
      },
      {
        minWidth: 90,
        align: 'right',
        field: 'currency',
        flex: 0.4,
        headerAlign: 'right',
        headerName: 'Currency',
        renderCell: ({ value }) => <span>{value ? value || rowData.currency : 'USD'}</span>,
        sortable: false,
      },
      {
        minWidth: 70,
        align: 'center',
        field: 'info',
        flex: 0.3,
        headerAlign: 'center',
        headerName: '',
        renderCell: (parameters) => <RenderInfoCell props={parameters} />,
        renderHeader: () => (
          <Button onClick={handleColumnModel} size="small" sx={{ minWidth: 'unset', p: '4px 5px' }} variant="text">
            {columnVisibilityModel.id ? 'Less' : 'More'}
          </Button>
        ),
        sortable: false,
      },
    ],
    [columnVisibilityModel],
  )

  return (
    <div id="transactionsTableContainer">
      {data?.transactions && !isFailed && (
        <DataGrid
          hideFooter
          apiRef={apiReference}
          className="transactions-table"
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.id}
          loading={isLoading}
          rows={data.transactions}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          disableColumnSelector
          disableRowSelectionOnClick
        />
      )}
    </div>
  )
}

export default TransactionsTable
