enum colors {
  actionDisabled = '#BFC6E2',
  actionDisabledBackground = '#EEF0F6',

  actionSelected = '#BFC6E23D',
  /*---other--- */
  backdropOverlay = '#4F5775',
  background = '#23252f',
  backgroundModal = '#F5F6FA',
  /*---background--- */
  backgroundPaper = '#FFFFFF',
  black = '#000000',

  divider = '#DADCE3',
  error = '#B42929',
  info = '#0288D1',

  lightError = '#e57373',
  outlinedBorder = '#BCC0CD',
  primary = '#4E79E7',
  secondary = '#7360CD',
  snackbar = '#101828',
  standardInputLine = '#8990A7',

  success = '#4CA478',
  /*---light--- */
  text = '#23252F',
  textSecondary = '#70778f',

  warning = '#DA612B',
  /*---common--- */
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  white = '#FFFFFF',
}

export default colors
