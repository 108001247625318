import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import { getEnvironment } from '@admin/shared/libs/getEnvironment'

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: getEnvironment(),
      integrations: [
        Sentry.breadcrumbsIntegration({
          console: false,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV7BrowserTracingIntegration({
          createRoutesFromChildren,
          matchRoutes,
          useEffect,
          useLocation,
          useNavigationType,
        }),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      normalizeDepth: 10,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
    })
  }
}
