import { GridRenderCellParams } from '@mui/x-data-grid'
import startCase from 'lodash/startCase'

import Typography from '@admin/components/shared/Typography/Typography'

import { possibleTaxRuleCertainty } from '../constants'

/**
 * Props for the RenderCertaintyCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderStatusSelectProps {
  props: GridRenderCellParams
}

/**
 * Renders a certainty select cell in a data grid.
 */
export const RenderCertaintyCell = ({ props }: IRenderStatusSelectProps) => {
  const { row } = props

  if (!row.salesTaxRuleId) {
    return null
  }

  const currentCertainty = possibleTaxRuleCertainty.find((element) => row.certainty?.toLowerCase() === element.certainty.toLowerCase())

  if (!currentCertainty) {
    return <Typography variant="body2">Unknown</Typography>
  }

  return (
    <Typography color={currentCertainty.color} variant="body2">
      {startCase(row.certainty?.toLowerCase())}
    </Typography>
  )
}
