import './index.scss'

import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSpacingParams, MuiEvent } from '@mui/x-data-grid'
import { GridRowScrollEndParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDocumentTitle } from '@uidotdev/usehooks'
import isEqual from 'lodash/isEqual'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import FilterIcon from '@admin/assets/img/FilterIcon'
import RefreshIcon from '@admin/assets/img/RefreshIcon'
import SearchIcon from '@admin/assets/img/SearchIcon'
import SortIcon from '@admin/assets/img/SortIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import Event from '@admin/components/ContentDisplay/Event/Event'
import Lifestyle from '@admin/components/ContentDisplay/Lifestyle/Lifestyle'
import Offer from '@admin/components/ContentDisplay/Offer/Offer'
import Wish from '@admin/components/ContentDisplay/Wish/Wish'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import ModerationGridFooter from '@admin/components/Moderation/Footer/ModerationGridFooter'
import MediaPreviewDrawer, { IMediaProps } from '@admin/components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer'
import ModerationCard from '@admin/components/Moderation/ModerationCard/ModerationCard'
import NoMoreTickets from '@admin/components/Moderation/NoMoreTickets/NoMoreTickets'
import RulesModalContent from '@admin/components/Moderation/RulesModalContent/RulesModalContent'
import { decisions, implications, terms } from '@admin/components/Moderation/RulesModalContent/types/rulesTypes'
import ModerationTabs, { Rules } from '@admin/components/Moderation/types/ModerationTabs'
import SubjectType from '@admin/components/Moderation/types/SubjectType'
import { CloseUnassignTicketsConfirmModal } from '@admin/components/Moderation/UnassignTicketsModal/CloseUnassignTicketsConfirmModal'
import UnassignTicketsModal from '@admin/components/Moderation/UnassignTicketsModal/UnassignTicketsModal'
import UserProfileModal from '@admin/components/Moderation/UserProfileModal/UserProfileModal'
import Autocomplete from '@admin/components/shared/Autocomplete/Autocomplete'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import ButtonGroup from '@admin/components/shared/ButtonGroup/ButtonGroup'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import Divider from '@admin/components/shared/Divider/Divider'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { CloseSortingDrawerConfirmModal } from '@admin/components/SortingDrawer/CloseSortingDrawerConfirmModal'
import SortingDrawer from '@admin/components/SortingDrawer/SortingDrawer'
import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { action } from '@admin/containers/SnackbarContainer'
import { selectAuthMemberId, selectUserPermissions } from '@admin/store/authSlice'
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFiltersCurrentData,
  selectFiltersData,
  setCustomFilterValue,
  sortFiltersByDependsOnValue,
} from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchAllMetrics,
  fetchAvailableMetrics,
  fetchDangerousMetrics,
  selectAllMetrics,
  selectAvailableMetrics,
  selectDangerousMetrics,
} from '@admin/store/moderationMetricsSlice'
import {
  clearContentModerationData,
  fetchAssignTickets,
  fetchModerationData,
  fetchMyData,
  getEntityCardInfo,
  selectAssignStatus,
  selectColumn,
  selectConfirmedViolationsLength,
  selectConfirmedViolationsStatus,
  selectContentComplaintsLength,
  selectContentComplaintsMyDataLength,
  selectContentComplaintsStatus,
  selectDeclinedRequestsLength,
  selectDeclinedRequestsStatus,
  selectEntityCardInfoStatus,
  selectHiveLength,
  selectHiveMyDataLength,
  selectHiveStatus,
  selectModerationData,
  selectModerationStatus,
  selectProfileComplaintsLength,
  selectProfileComplaintsMyDataLength,
  selectProfileComplaintsStatus,
  selectUnassignStatus,
  setModerationStatusLoading,
} from '@admin/store/moderationSlice'
import {
  fetchRules,
  IHiveRule,
  IManualRule,
  IProfileRule,
  selectColumn as selectColumnRules,
  selectRules,
  selectRulesStatus,
  TDecision,
  Term,
  TProfileImplications,
} from '@admin/store/rulesSlice'
import { fetchSortingColumns, reverseSortingData, selectSortingCurrentData, selectSortingData } from '@admin/store/sortingSlice'
import colors from '@admin/theme/constants/colors'
import { IMediaItem, Permissions } from '@admin/types/commonTypes'
import { getCustomFilterValue } from '@admin/utils//getCustomFilterValue'
import isAbortError from '@admin/utils//isAbortError'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

export interface IRuleContent {
  action: 'add' | 'delete' | 'edit' | undefined
  availableMetrics?: string[]
  isShowModal: boolean
  rule: IHiveRule | IManualRule | IProfileRule | {}
}

export interface IStatusModal {
  moderator: null | string
  onChangeStatus?: () => void
  openModal: boolean
  status: boolean
  violations: string[]
}

const initialRowCount = 10

const USER_NAME_PARAM = 'userName'
const USER_ID_PARAM = 'userId'

const tabToTicketTypeMap: Record<ModerationTabs, string> = {
  [ModerationTabs.CONFIRMED_VIOLATIONS]: '',
  [ModerationTabs.CONTENT_COMPLAINTS]: 'content',
  [ModerationTabs.DECLINED_REQUESTS]: '',
  [ModerationTabs.HIVE]: 'hive',
  [ModerationTabs.PROFILE_COMPLAINTS]: 'profile',
  [ModerationTabs.RULES]: '',
}

const Moderation = () => {
  const [searchParameters, setSearchParameters] = useSearchParams()
  const userParametersName = searchParameters.get(USER_NAME_PARAM)
  const userParametersId = searchParameters.get(USER_ID_PARAM)

  const apiReference = useGridApiRef()
  const fetchPromiseReference = useRef<TAny>(null)

  const { tabId = ModerationTabs.HIVE } = useParams<{ tabId: ModerationTabs }>()

  const isCorrectPage = useMemo(() => tabId && Object.values(ModerationTabs).includes(tabId), [tabId])

  useDocumentTitle(`Content moderation - ${startCase(tabId)}`)

  const [filterDrawer, setFilterDrawer] = useState(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [sortingDrawerCloseConfirmModal, setSortingDrawerCloseConfirmModal] = useState<boolean>(false)
  const [sortingDrawer, setSortingDrawer] = useState(false)
  const [mediaPreviewDrawer, setMediaPreviewDrawer] = useState<IMediaProps>()
  const [isShowEntityDetailsModal, setIsShowEntityDetailsModal] = useState(false)
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState(false)
  const [isShowUnassignTicketsModal, setIsShowUnassignTicketsModal] = useState(false)
  const [unassignCloseConfirmModal, setUnassignCloseConfirmModal] = useState<boolean>(false)
  const [rowCount, setRowCount] = useState(initialRowCount)
  const [refresh, setRefresh] = useState(false)
  const [rulesValue, setRulesValue] = useState(Rules.HIVE)
  const [isShowRulesModal, setIsShowRulesModal] = useState<IRuleContent>({
    action: undefined,
    availableMetrics: [],
    isShowModal: false,
    rule: {},
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const [subjectTypeConfirmed, setSubjectTypeConfirmed] = useState<SubjectType>(SubjectType.CONTENT)
  const [subjectTypeDeclined, setSubjectTypeDeclined] = useState<SubjectType>(SubjectType.CONTENT)
  const [ticketsGroup, setTicketsGroup] = useState<string>('All')
  const [shrink, setShrink] = useState(false)
  const [scrollEnd, setScrollEnd] = useState(false)
  const [fetchingError, setFetchingError] = useState(false)

  const { userId } = useParams()

  const { showBoundary } = useErrorBoundary()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const moderatorId = useAppSelector(selectAuthMemberId)
  const status = useAppSelector(selectModerationStatus)
  const assignStatus = useAppSelector(selectAssignStatus)
  const unassignStatus = useAppSelector(selectUnassignStatus)
  const hiveStatus = useAppSelector(selectHiveStatus)
  const contentComplaintsStatus = useAppSelector(selectContentComplaintsStatus)
  const profileComplaintsStatus = useAppSelector(selectProfileComplaintsStatus)
  const confirmedViolationsStatus = useAppSelector(selectConfirmedViolationsStatus)
  const declinedRequestsStatus = useAppSelector(selectDeclinedRequestsStatus)
  const statusRules = useAppSelector(selectRulesStatus)
  const moderationData = useAppSelector(selectModerationData)
  const hiveLength = useAppSelector(selectHiveLength)
  const contentComplaintsLength = useAppSelector(selectContentComplaintsLength)
  const profileComplaintsLength = useAppSelector(selectProfileComplaintsLength)
  const confirmedViolationsLength = useAppSelector(selectConfirmedViolationsLength)
  const declinedRequestsLength = useAppSelector(selectDeclinedRequestsLength)
  const rules = useAppSelector(selectRules)
  const availableMetrics = useAppSelector(selectAvailableMetrics)
  const columns = useAppSelector(selectColumn)
  const columnsRules = useAppSelector(selectColumnRules)
  const allMetrics = useAppSelector(selectAllMetrics)
  const dangerousMetrics = useAppSelector(selectDangerousMetrics)
  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const sortingData = useAppSelector(selectSortingData)
  const sortingCurrentData = useAppSelector(selectSortingCurrentData)
  const hiveMyDataLength = useAppSelector(selectHiveMyDataLength)
  const profileComplaintsMyDataLength = useAppSelector(selectProfileComplaintsMyDataLength)
  const contentComplaintsMyDataLength = useAppSelector(selectContentComplaintsMyDataLength)
  const entityCardInfoStatus = useAppSelector(selectEntityCardInfoStatus)
  const mounted = useRef(false)
  const userPermissions = useAppSelector(selectUserPermissions)

  const showPremoderationTab = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_HIVE_MODERATION])
  const viewAllHiveTickets = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_HIVE_MODERATION_SEE_ALL])
  const showContentComplaintsTab = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_CONTENT_COMPLAINS_MODERATION])
  const viewAllContentComplaintsTickets = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_CONTENT_COMPLAINS_MODERATION_SEE_ALL])
  const showProfileComplaintsTab = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_PROFILE_COMPLAINS_MODERATION])
  const viewAllProfileComplaintsTickets = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_PROFILE_COMPLAINS_MODERATION_SEE_ALL])
  const enabledHiveRules = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_HIVE_MODERATION_RULES_MANAGEMENT])
  const enabledContentRules = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_CONTENT_COMPLAINTS_MODERATION_RULES_MANAGEMENT])
  const enabledProfileRules = checkPermissions(userPermissions, [Permissions.CONTENT_MODERATION_PROFILE_COMPLAINTS_MODERATION_RULES_MANAGEMENT])

  const tableScrollTop = useCallback(() => {
    apiReference.current.scroll({ top: 0 })
  }, [])

  const onFilterDrawerClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const onSortingDrawerClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(sortingData, sortingCurrentData) || apply === true) {
        setSortingDrawerCloseConfirmModal(false)
        setSortingDrawer(false)
        dispatch(reverseSortingData())
      } else {
        setSortingDrawerCloseConfirmModal(true)
      }
    },
    [sortingData, sortingCurrentData],
  )

  const onUnassignModalClose = useCallback((unassigned?: boolean) => {
    if (unassigned) {
      setIsShowUnassignTicketsModal(false)
    } else {
      setUnassignCloseConfirmModal(true)
    }
  }, [])

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const cancelCloseSortingSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setSortingDrawerCloseConfirmModal(false)
  }, [])

  const cancelCloseUnassignModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setUnassignCloseConfirmModal(false)
  }, [])

  useEffect(() => {
    if (userId) {
      setIsShowUserProfileModal(true)
    }
  }, [userId])

  useEffect(() => {
    return () => {
      mounted.current = true
    }
  }, [tabId])

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/moderation/${ModerationTabs.HIVE}`)

      return
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'))
    }
  }, [])

  const filtersLength = useMemo(() => Object.values(filtersData).filter((value) => value !== null).length, [filtersData])
  const sortingLength = useMemo(() => Object.values(sortingData).filter((value) => value !== null).length, [sortingData])

  const availableMetricsTab = useMemo(() => {
    switch (rulesValue) {
      case Rules.HIVE:
        return availableMetrics.hive
      case Rules.MANUAL:
        return availableMetrics.content
      case Rules.PROFILE:
        return availableMetrics.profile
    }
  }, [rulesValue, availableMetrics])

  const handleClearFilter = () => {
    dispatch(clearFilters())

    searchParameters.delete(USER_ID_PARAM)
    searchParameters.delete(USER_NAME_PARAM)
    setSearchParameters(searchParameters)
  }

  const handleTabChange = useCallback(async (_event: ChangeEvent<{}>, value: ModerationTabs) => {
    handleClearFilter()
    dispatch(clearContentModerationData())
    setRowCount(initialRowCount)
    setTicketsGroup('All')

    if (searchParameters.has('tab') || searchParameters.has('userName') || searchParameters.has('userId')) {
      searchParameters.delete('tab')
      searchParameters.delete('userName')
      searchParameters.delete('userId')
      setSearchParameters(searchParameters)
      dispatch(removeFilter({ columnId: 'owner_id' }))
    }

    navigate(`/ui/moderation/${value}`)

    if (value === ModerationTabs.CONFIRMED_VIOLATIONS) {
      dispatch(sortFiltersByDependsOnValue(subjectTypeConfirmed))
      setSubjectTypeConfirmed(SubjectType.CONTENT)
    }
    if (value === ModerationTabs.DECLINED_REQUESTS) {
      dispatch(sortFiltersByDependsOnValue(subjectTypeDeclined))
      setSubjectTypeDeclined(SubjectType.CONTENT)
    }
  }, [])

  const [entityModalInfo, setEntityModalInfo] = useState<TAny>(null)
  const [entityCardInfo, setEntityCardInfo] = useState<TAny>(null)

  const showEntityModal = useCallback((entityInfo?: TAny) => {
    if (entityInfo) {
      setIsShowEntityDetailsModal(true)
      setEntityModalInfo(entityInfo)
    } else {
      setIsShowEntityDetailsModal(false)
      setEntityModalInfo(null)
    }
  }, [])

  const loadEntityCardInfo = useCallback(async () => {
    if (entityModalInfo !== null) {
      const result = await dispatch(getEntityCardInfo(entityModalInfo))

      if (result.meta.requestStatus === 'fulfilled' && (result.payload as TAny)) {
        setEntityCardInfo(result.payload as TAny)
      } else if (result.meta.requestStatus === 'fulfilled' && !(result.payload as TAny)) {
        setIsShowEntityDetailsModal(false)
        enqueueSnackbar('Loading entity details error, try again later', {
          variant: 'error' as VariantType,
        })
      } else if (result.meta.requestStatus === 'rejected') {
        setIsShowEntityDetailsModal(false)
        setEntityModalInfo(null)
        enqueueSnackbar('Loading entity details error, try again later', {
          variant: 'error' as VariantType,
        })
      }
    } else {
      setEntityCardInfo(null)
    }
  }, [dispatch, entityModalInfo])

  useEffect(() => {
    loadEntityCardInfo()
  }, [entityModalInfo, loadEntityCardInfo])

  const handleRefreshData = useCallback(
    async (showNotificationRefreshed?: boolean) => {
      try {
        if (fetchPromiseReference.current) {
          if (typeof fetchPromiseReference.current?.abort === 'function') {
            fetchPromiseReference.current.abort()
          }
        }

        let fetchPromise

        if (ticketsGroup === 'All') {
          fetchPromise = dispatch(
            fetchModerationData({
              pageSize: rowCount,
              subjectTypeConfirmed: subjectTypeConfirmed,
              subjectTypeDeclined: subjectTypeDeclined,
              type: tabId,
            }),
          )
          fetchPromiseReference.current = fetchPromise
        } else {
          fetchPromise = dispatch(fetchMyData({ type: tabId }))
        }

        if (fetchPromise) {
          const result = await fetchPromise

          if (result.meta.requestStatus === 'fulfilled' && showNotificationRefreshed) {
            enqueueSnackbar('Data has been refreshed', {
              variant: 'success' as VariantType,
            })
            setFetchingError(false)
          }
          if (result.meta.requestStatus === 'rejected') {
            enqueueSnackbar('Receiving data error', {
              variant: 'error' as VariantType,
            })
            setFetchingError(true)
          }
        }
      } catch (error) {
        if (!isAbortError(error)) {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        }
      } finally {
        setRefresh(false)
      }
    },
    [tabId, ticketsGroup, dispatch, rowCount, subjectTypeDeclined, subjectTypeConfirmed],
  )

  const refreshData = useCallback(async () => {
    setRefresh(true)
    await handleRefreshData(true)
  }, [handleRefreshData])

  const repeatRequest = useCallback(async () => {
    await handleRefreshData()
  }, [handleRefreshData])

  const handleRefreshAllData = useCallback(async () => {
    try {
      dispatch(clearFilters())
      tableScrollTop()
      setRowCount(initialRowCount)

      if (searchParameters.has(USER_ID_PARAM) || searchParameters.has(USER_NAME_PARAM)) {
        searchParameters.delete(USER_ID_PARAM)
        searchParameters.delete(USER_NAME_PARAM)
        setSearchParameters(searchParameters)
      } else {
        await dispatch(
          fetchModerationData({
            pageSize: initialRowCount,
            subjectTypeConfirmed: subjectTypeConfirmed,
            subjectTypeDeclined: subjectTypeDeclined,
            type: tabId,
          }),
        )
      }
    } catch (error) {
      !isAbortError(error) && enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
      !isAbortError(error) && setFetchingError(true)
    }
  }, [dispatch, tabId, subjectTypeDeclined, subjectTypeConfirmed])

  const handleChangeStatus = async () => {
    if (ticketsGroup === 'All') {
      await dispatch(
        fetchModerationData({
          pageSize: rowCount,
          subjectTypeConfirmed: subjectTypeConfirmed,
          subjectTypeDeclined: subjectTypeDeclined,
          type: tabId,
        }),
      )
    } else {
      await dispatch(fetchMyData({ type: tabId }))
    }
  }

  const handleApplyFilter = async () => {
    try {
      onFilterDrawerClose(true)
      setRowCount(initialRowCount)

      tableScrollTop()

      await dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          subjectTypeConfirmed: subjectTypeConfirmed,
          subjectTypeDeclined: subjectTypeDeclined,
          type: tabId,
        }),
      ).unwrap()

      setFetchingError(false)
    } catch {
      setFetchingError(true)
    }
  }

  const handleApplySorting = async () => {
    try {
      setSortingDrawer(false)
      setRowCount(initialRowCount)

      tableScrollTop()

      await dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          subjectTypeConfirmed: subjectTypeConfirmed,
          subjectTypeDeclined: subjectTypeDeclined,
          type: tabId,
        }),
      ).unwrap()

      setFetchingError(false)
    } catch {
      setFetchingError(true)
    }
  }

  const customCellRender: TAny = (props: GridRenderCellParams) => {
    return (
      <ModerationCard
        allMetrics={allMetrics}
        dangerousMetrics={dangerousMetrics}
        onChangeStatus={handleChangeStatus}
        renderCardProps={props.row}
        showDetails={(entityInfo: TAny) => showEntityModal(entityInfo)}
        showPreview={(newValue: IMediaProps) => setMediaPreviewDrawer(newValue)}
        showUsersProfile={() => setIsShowUserProfileModal(true)}
        subjectTypeConfirmed={subjectTypeConfirmed}
        subjectTypeDeclined={subjectTypeDeclined}
        tabValue={tabId}
        ticketsGroup={ticketsGroup}
      />
    )
  }

  const customCellRenderRules: TAny = {
    action: (props: GridRenderCellParams) => (
      <div className="rulesCell rulesCell-props">
        <Button
          onClick={() =>
            setIsShowRulesModal({
              action: 'edit',
              isShowModal: true,
              rule: props.row,
            })
          }
          className="rulesAction"
        >
          <EditIcon />
        </Button>
        <Button
          onClick={() =>
            setIsShowRulesModal({
              action: 'delete',
              isShowModal: true,
              rule: props.row,
            })
          }
          className="rulesAction"
        >
          <DeleteIcon />
        </Button>
      </div>
    ),
    decision: (props: GridRenderCellParams) => <div className="rulesCell rulesCell-props">{decisions[props.row?.decision as TDecision]}</div>,
    metric: (props: GridRenderCellParams) => <div className="rulesCell">{props.row?.metric}</div>,
    profileImplication: (props: GridRenderCellParams) => {
      if (props.row?.profileImplicationDto) {
        return (
          <div className="rulesCell rulesCell-props">
            {implications[props.row?.profileImplicationDto.profileDecision as TProfileImplications]}
            {props.row?.profileImplicationDto.term === 'FOR_PERIOD'
              ? `for ${props.row?.profileImplicationDto.decisionPeriodCount} days`
              : terms[props.row?.profileImplicationDto.term as Term]}
          </div>
        )
      } else if (!props.row?.profileImplicationDto && !props.row?.profileDecision) {
        return <div className="rulesCell rulesCell-props">{implications['NO_IMPLICATIONS' as TProfileImplications]}</div>
      } else {
        return (
          <div className="rulesCell rulesCell-props">
            {implications[props.row?.profileDecision as TProfileImplications]}
            {props.row?.term === 'PERMANENTLY' ? terms[props.row?.term as Term] : `for ${props.row?.decisionPeriodCount} days`}
          </div>
        )
      }
    },
    threshold: (props: GridRenderCellParams) => (
      <div className="rulesCell rulesCell-props">
        {props.row?.reviewMinScore} / {props.row?.decisionMinScore}
      </div>
    ),
  }

  const handleChangeTicketsGroup = async (value: string) => {
    setTicketsGroup(value)
    setRowCount(initialRowCount)
    tableScrollTop()

    if (fetchPromiseReference.current) {
      if (typeof fetchPromiseReference.current?.abort === 'function') {
        fetchPromiseReference.current.abort()
      }
    }

    dispatch(setModerationStatusLoading(tabId))

    try {
      if (value === 'All') {
        fetchPromiseReference.current = await dispatch(
          fetchModerationData({
            pageSize: initialRowCount,
            type: tabId,
          }),
        ).unwrap()
      } else if (value === 'My') {
        handleClearFilter()
        dispatch(clearContentModerationData())
        fetchPromiseReference.current = await dispatch(fetchMyData({ type: tabId })).unwrap()
      }
    } catch (error) {
      showBoundary(error)
    }
  }

  const handleChangeSubjectType = async (value: SubjectType) => {
    handleClearFilter()
    setRowCount(initialRowCount)
    tableScrollTop()

    if (fetchPromiseReference.current) {
      if (typeof fetchPromiseReference.current?.abort === 'function') {
        fetchPromiseReference.current.abort()
      }
    }

    if (tabId === ModerationTabs.CONFIRMED_VIOLATIONS) {
      setSubjectTypeConfirmed(value)
      fetchPromiseReference.current = dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          subjectTypeConfirmed: value,
          type: tabId,
        }),
      )
      dispatch(sortFiltersByDependsOnValue(value))
    } else {
      setSubjectTypeDeclined(value)
      fetchPromiseReference.current = dispatch(
        fetchModerationData({
          pageSize: initialRowCount,
          subjectTypeDeclined: value,
          type: tabId,
        }),
      )
      dispatch(sortFiltersByDependsOnValue(value))
    }
  }

  const getTickets = async (tab: ModerationTabs) => {
    const result = await dispatch(fetchAssignTickets({ type: tab }))

    if (result.meta.requestStatus === 'fulfilled') {
      if (result.payload.allResultsCount > 0) {
        enqueueSnackbar(`${result.payload.allResultsCount} ticket${result.payload.allResultsCount > 1 ? 's' : ''} assigned to you`, {
          variant: 'success' as VariantType,
        })
      } else {
        enqueueSnackbar(`No tickets in the queue, try again later`, {
          variant: 'info' as VariantType,
        })
      }
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Tickets assignation error, try again later', {
        variant: 'error' as VariantType,
      })
    }
  }

  const rows: TAny[] = useMemo(() => {
    return moderationData.map((item) => ({
      ...item,
      id: item?.entityInfo?.entityId || item?.profileInfo?.profileId,
    }))
  }, [moderationData])

  const rowsWithFooter = useMemo(() => {
    if (!scrollEnd || ticketsGroup === 'My') {
      return rows
    }

    if (moderationData.length > 1) {
      const rowsWithAddedFooter = [
        ...rows,
        {
          id: 'footer',
          isFooter: true,
        },
      ]

      return rowsWithAddedFooter
    }

    return rows
  }, [moderationData, rows, scrollEnd, ticketsGroup])

  const rowsRules: TAny[] = useMemo(() => {
    if (rulesValue === Rules.HIVE) {
      return rules.hive
    }
    if (rulesValue === Rules.MANUAL) {
      return rules.manual
    }

    return rules.profile
  }, [rules, rulesValue])

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((item) => ({
      field: item.columnId,
      flex: 1,
      headerName: item.columnName,
      renderCell: (parameters) => {
        if (parameters.row.isFooter && scrollEnd) {
          return <ModerationGridFooter />
        }

        return customCellRender && customCellRender(parameters)
      },
    }))
  }, [columns, customCellRender, scrollEnd])

  const colsRules: GridColDef[] = useMemo(() => {
    if (rulesValue === Rules.HIVE) {
      return columnsRules.hive.map((item) => ({
        width: item.width,
        field: item.columnId,
        flex: item.flex || 0,
        headerName: item.columnName,
        renderCell: customCellRenderRules[item.columnId] ?? null,
        sortable: item.sortable,
      }))
    }
    if (rulesValue === Rules.MANUAL) {
      return columnsRules.manual.map((item) => ({
        width: item.width,
        field: item.columnId,
        flex: item.flex,
        headerName: item.columnName,
        renderCell: customCellRenderRules[item.columnId] ?? null,
        sortable: item.sortable,
      }))
    }

    return columnsRules.profile.map((item) => ({
      width: item.width,
      field: item.columnId,
      flex: item.flex,
      headerName: item.columnName,
      renderCell: customCellRenderRules[item.columnId] ?? null,
      sortable: item.sortable,
    }))
  }, [columnsRules, rulesValue])

  const initialFetchData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(fetchAllMetrics()).unwrap(),
        // dispatch(fetchDataLength()).unwrap(),
        dispatch(fetchDangerousMetrics()).unwrap(),
      ])
    } catch (error: TAny) {
      showBoundary(error)
    }
  }, [dispatch, showBoundary])

  const initialFetchAvailableMetrics = useCallback(() => {
    dispatch(fetchAvailableMetrics()).then((result: TAny) => {
      if (result.meta && result.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(result.error.message)) {
        // showBoundary( new Error(result.error?.message));
      }
    })
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [])

  useEffect(() => {
    if (tabId === ModerationTabs.RULES) {
      initialFetchAvailableMetrics()
    }
  }, [tabId])

  const resetFilters = useCallback(async () => {
    handleClearFilter()
    tableScrollTop()
    await dispatch(
      fetchModerationData({
        pageSize: initialRowCount,
        subjectTypeConfirmed: subjectTypeConfirmed,
        subjectTypeDeclined: subjectTypeDeclined,
        type: tabId,
      }),
    )
  }, [tableScrollTop, dispatch, tabId, subjectTypeDeclined, subjectTypeConfirmed])

  useEffect(() => {
    dispatch(clearFilters())
    dispatch(clearContentModerationData())
    dispatch(setModerationStatusLoading(tabId))

    if (tabId !== ModerationTabs.RULES) {
      ;(async () => {
        try {
          await dispatch(fetchSortingColumns(tabId))
          await dispatch(fetchFilters(tabId))

          if (searchParameters.has(USER_NAME_PARAM) || searchParameters.has(USER_ID_PARAM)) {
            dispatch(setCustomFilterValue(getCustomFilterValue(userParametersName, userParametersId)))
          }

          if (fetchPromiseReference.current) {
            if (typeof fetchPromiseReference.current?.abort === 'function') {
              fetchPromiseReference.current.abort()
            }
          }

          const fetchPromise = dispatch(
            fetchModerationData({
              pageSize: rowCount,
              subjectTypeConfirmed: subjectTypeConfirmed,
              subjectTypeDeclined: subjectTypeDeclined,
              type: tabId,
            }),
          )

          fetchPromiseReference.current = fetchPromise

          await fetchPromise.unwrap()

          if (tabId === ModerationTabs.CONFIRMED_VIOLATIONS) {
            dispatch(sortFiltersByDependsOnValue(subjectTypeConfirmed))
          }
          if (tabId === ModerationTabs.DECLINED_REQUESTS) {
            dispatch(sortFiltersByDependsOnValue(subjectTypeDeclined))
          }
        } catch (error) {
          !isAbortError(error) && showBoundary(error)
        }
      })()
    }

    return () => {
      if (fetchPromiseReference.current && typeof fetchPromiseReference.current?.abort === 'function') {
        fetchPromiseReference.current.abort()
      }
    }
  }, [tabId, userParametersId, userParametersName])

  useEffect(() => {
    dispatch(fetchRules(rulesValue)).then((result: TAny) => {
      if (result.meta && result.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(result.error.message)) {
        // showBoundary( new Error(result.error?.message));
      }
    })
  }, [rulesValue])

  const loadServerRows = useCallback(
    async (newRowLength: number): Promise<boolean | void> => {
      setRowCount(newRowLength)
      fetchPromiseReference.current = dispatch(
        fetchModerationData({
          pageSize: newRowLength,
          subjectTypeConfirmed: subjectTypeConfirmed,
          subjectTypeDeclined: subjectTypeDeclined,
          type: tabId,
        }),
      )
    },
    [tabId, subjectTypeConfirmed, subjectTypeDeclined],
  )

  async function handleOnRowsScrollEnd(
    parameters: GridRowScrollEndParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails,
    length: number,
    myDataLength?: number,
    ticketsGroup?: string,
  ) {
    if (status !== 'idle') {
      return
    }

    if (rowCount < length && parameters.viewportPageSize >= 0 && ticketsGroup !== 'My') {
      await loadServerRows(rowCount + 10)
    } else if (status === 'idle' && rowCount >= length) {
      setScrollEnd(true)
    } else if (myDataLength && rowCount >= myDataLength) {
      setScrollEnd(true)
    }
  }

  const resetScrollEnd = () => {
    setScrollEnd(false)
  }

  useEffect(() => {
    resetScrollEnd()
  }, [tabId, ticketsGroup, filtersData, refresh])

  const openUserProfile = (userId: string) => {
    window.open(`${window.location.origin}/ui/users/user/${userId}`, '_blank')
  }

  const showMediaDrawer = (
    entityId: string,
    entityType: string,
    mediaUrl: string,
    mainEntity: IMediaItem,
    orderedMediaList: IMediaItem[],
    mediaType?: string,
  ) => {
    setMediaPreviewDrawer({
      contentType: mediaType ?? 'Image',
      entityId: entityId,
      entityType: entityType,
      mainEntity: mainEntity,
      mediaUrl: mediaUrl,
      orderedMediaList: orderedMediaList,
    })
  }

  const cardElement = () => {
    if (entityCardInfoStatus === 'loading') {
      return (
        <div style={{ alignItems: 'center', display: 'flex', height: '380px', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )
    }

    if (entityCardInfo && entityModalInfo) {
      switch (entityModalInfo.entityType) {
        case 'EVENT':
          return <Event data={entityCardInfo} modalClose={showEntityModal} openUser={openUserProfile} showMedia={showMediaDrawer} />
        case 'LIFESTYLE':
          return <Lifestyle data={entityCardInfo} modalClose={showEntityModal} openUser={openUserProfile} showMedia={showMediaDrawer} />
        case 'OFFER':
          return <Offer data={entityCardInfo} modalClose={showEntityModal} openUser={openUserProfile} showMedia={showMediaDrawer} />
        case 'WISH':
          return <Wish data={entityCardInfo} modalClose={showEntityModal} openUser={openUserProfile} />
      }
    }

    return null
  }

  const handleRowClick = (parameters: TAny, event: TAny) => {
    event.stopPropagation()
  }

  const isConfirmedContentViolationsLoading = confirmedViolationsStatus.content === 'loading'
  const isConfirmedProfileViolationsLoading = confirmedViolationsStatus.profile === 'loading'

  const isDeclinedContentRequestsLoading = declinedRequestsStatus.content === 'loading'
  const isDeclinedProfileRequestsLoading = declinedRequestsStatus.profile === 'loading'

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !(!isEmptyData || !!filtersLength) || isConfirmedContentViolationsLoading || isConfirmedProfileViolationsLoading
  const isDisabledSortButton = !(!isEmptyData || !!sortingLength) || isConfirmedContentViolationsLoading || isConfirmedProfileViolationsLoading

  useEffect(() => {
    const channel = new BroadcastChannel('moderation_channel')
    const currentTicketType = tabToTicketTypeMap[tabId]

    channel.onmessage = (event) => {
      if (
        event.data.type === 'TICKETS_UNASSIGNED' &&
        ticketsGroup === 'My' &&
        event.data.ticketTypes.includes(currentTicketType) &&
        event.data.moderatorIds.includes(moderatorId)
      ) {
        fetchPromiseReference.current = dispatch(fetchMyData({ type: tabId })).unwrap()
        enqueueSnackbar('Your tickets were unassigned', {
          autoHideDuration: null,
          action,
          preventDuplicate: true,
          variant: 'warning',
        })
      }
    }

    return () => {
      channel.close()
    }
  }, [dispatch, tabId, ticketsGroup])

  return (
    <div className="moderation-container">
      <Typography variant="h6">Content moderation</Typography>
      <TabContext value={tabId}>
        <TabList className="moderation-tabs" onChange={handleTabChange}>
          {showPremoderationTab && <Tab label="Premoderation" className="moderation-tab" value={ModerationTabs.HIVE} />}
          {showContentComplaintsTab && <Tab label="Content complaints " className="moderation-tab" value={ModerationTabs.CONTENT_COMPLAINTS} />}
          {showProfileComplaintsTab && <Tab label="Profile complaints" className="moderation-tab" value={ModerationTabs.PROFILE_COMPLAINTS} />}
          <Divider className="moderation-tabs__divider" orientation="vertical" flexItem />
          <Tab label="Confirmed violations" className="moderation-tab" value={ModerationTabs.CONFIRMED_VIOLATIONS} />
          <Tab label="Declined requests" className="moderation-tab" value={ModerationTabs.DECLINED_REQUESTS} />
          {(enabledHiveRules || enabledContentRules || enabledProfileRules) && (
            <Tab label="Rules" className="moderation-tab" value={ModerationTabs.RULES} disabled />
          )}
        </TabList>
        <TabPanel value={ModerationTabs.HIVE}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              {viewAllHiveTickets && (
                <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2}>
                  <ButtonGroup variant="outlined">
                    <Button className={ticketsGroup === 'All' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('All')} size="small">
                      All Tickets
                    </Button>
                    <Button className={ticketsGroup === 'My' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('My')} size="small">
                      My tickets
                    </Button>
                  </ButtonGroup>
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={hiveStatus === 'loading' || isDisabledFilterButton}
                      onClick={() => setFilterDrawer(true)}
                      size="small"
                      startIcon={<FilterIcon />}
                      variant="outlined"
                    >
                      Filter {!!filtersLength && `(${filtersLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={hiveStatus === 'loading' || isDisabledSortButton}
                      onClick={() => setSortingDrawer(true)}
                      size="small"
                      startIcon={<SortIcon />}
                      variant="outlined"
                    >
                      Sort {!!sortingLength && `(${sortingLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <LoadingButton
                      className="moderation-table-button"
                      disabled={hiveStatus === 'loading'}
                      loading={refresh}
                      loadingPosition="start"
                      onClick={refreshData}
                      size="small"
                      startIcon={<RefreshIcon />}
                    >
                      Refresh data
                    </LoadingButton>
                  )}
                </Stack>
              )}
              {status === 'idle' && (
                <LoadingButton
                  className="moderation-table-button"
                  disabled={ticketsGroup === 'My' ? assignStatus === 'loading' || hiveMyDataLength > 0 : unassignStatus === 'loading'}
                  onClick={ticketsGroup === 'My' ? () => getTickets(ModerationTabs.HIVE) : () => setIsShowUnassignTicketsModal(true)}
                  startIcon={(ticketsGroup === 'My' ? assignStatus : unassignStatus) === 'loading' && <CircularProgress color="inherit" size={20} />}
                  variant="contained"
                >
                  {ticketsGroup === 'My' ? 'Get tickets' : 'Unassign tickets'}
                </LoadingButton>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? hiveMyDataLength : hiveLength}</span>
              </div>
            )}
            <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter
                  getRowSpacing={(parameters: GridRowSpacingParams) => ({
                    bottom: parameters.isLastVisible ? 0 : 16,
                  })}
                  slots={{
                    noRowsOverlay: () =>
                      NoMoreTickets({
                        clearFilter: resetFilters,
                        fetchingError: fetchingError,
                        isFilters: !!filtersLength,
                        refreshData: repeatRequest,
                        ticketsGroup: ticketsGroup,
                      }),
                  }}
                  apiRef={apiReference}
                  columnHeaderHeight={0}
                  columns={cols}
                  filterMode="server"
                  getRowHeight={() => 'auto'}
                  headerFilterHeight={0}
                  loading={hiveStatus === 'loading'}
                  onCellClick={handleRowClick}
                  onRowClick={handleRowClick}
                  onRowsScrollEnd={(p, event, d) => handleOnRowsScrollEnd(p, event, d, hiveLength, hiveMyDataLength, ticketsGroup)}
                  rowBufferPx={rows.length * 400}
                  rows={rowsWithFooter}
                  scrollEndThreshold={200}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSelector
                  disableRowSelectionOnClick
                  disableVirtualization
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.CONTENT_COMPLAINTS}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              {viewAllContentComplaintsTickets && (
                <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2}>
                  <ButtonGroup variant="outlined">
                    <Button className={ticketsGroup === 'All' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('All')} size="small">
                      All Tickets
                    </Button>
                    <Button className={ticketsGroup === 'My' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('My')} size="small">
                      My tickets
                    </Button>
                  </ButtonGroup>
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={contentComplaintsStatus === 'loading' || isDisabledFilterButton}
                      onClick={() => setFilterDrawer(true)}
                      size="small"
                      startIcon={<FilterIcon />}
                      variant="outlined"
                    >
                      Filter {!!filtersLength && `(${filtersLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={contentComplaintsStatus === 'loading' || isDisabledSortButton}
                      onClick={() => setSortingDrawer(true)}
                      size="small"
                      startIcon={<SortIcon />}
                      variant="outlined"
                    >
                      Sort {!!sortingLength && `(${sortingLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <LoadingButton
                      className="moderation-table-button"
                      disabled={contentComplaintsStatus === 'loading'}
                      loading={refresh}
                      loadingPosition="start"
                      onClick={refreshData}
                      size="small"
                      startIcon={<RefreshIcon />}
                    >
                      Refresh data
                    </LoadingButton>
                  )}
                </Stack>
              )}
              {status === 'idle' && (
                <div>
                  <LoadingButton
                    className="moderation-table-button"
                    disabled={ticketsGroup === 'My' ? assignStatus === 'loading' || contentComplaintsMyDataLength > 0 : unassignStatus === 'loading'}
                    onClick={ticketsGroup === 'My' ? () => getTickets(ModerationTabs.CONTENT_COMPLAINTS) : () => setIsShowUnassignTicketsModal(true)}
                    startIcon={assignStatus === 'loading' && <CircularProgress color="inherit" size={20} />}
                    variant="contained"
                  >
                    {ticketsGroup === 'My' ? 'Get tickets' : 'Unassign tickets'}
                  </LoadingButton>
                </div>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? contentComplaintsMyDataLength : contentComplaintsLength}</span>
              </div>
            )}
            <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter
                  getRowSpacing={(parameters: GridRowSpacingParams) => ({
                    bottom: parameters.isLastVisible ? 0 : 16,
                  })}
                  onRowsScrollEnd={(p, event, d) =>
                    handleOnRowsScrollEnd(p, event, d, contentComplaintsLength, contentComplaintsMyDataLength, ticketsGroup)
                  }
                  slots={{
                    noRowsOverlay: () =>
                      NoMoreTickets({
                        clearFilter: resetFilters,
                        fetchingError: fetchingError,
                        isFilters: !!filtersLength,
                        refreshData: repeatRequest,
                        ticketsGroup: ticketsGroup,
                      }),
                  }}
                  apiRef={apiReference}
                  columnHeaderHeight={0}
                  columns={cols}
                  filterMode="server"
                  getRowHeight={() => 'auto'}
                  headerFilterHeight={0}
                  loading={contentComplaintsStatus === 'loading'}
                  onCellClick={handleRowClick}
                  onRowClick={handleRowClick}
                  rowBufferPx={rows.length * 400}
                  rows={rowsWithFooter}
                  scrollEndThreshold={200}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSelector
                  disableRowSelectionOnClick
                  disableVirtualization
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.PROFILE_COMPLAINTS}>
          <div className="moderation-table-container singleCard">
            <Stack direction="row" gap={2} justifyContent="space-between" mb={2}>
              {viewAllProfileComplaintsTickets && (
                <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2}>
                  <ButtonGroup variant="outlined">
                    <Button className={ticketsGroup === 'All' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('All')} size="small">
                      All Tickets
                    </Button>
                    <Button className={ticketsGroup === 'My' ? 'Mui-selected' : ''} onClick={() => handleChangeTicketsGroup('My')} size="small">
                      My tickets
                    </Button>
                  </ButtonGroup>
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={profileComplaintsStatus === 'loading' || isDisabledFilterButton}
                      onClick={() => setFilterDrawer(true)}
                      size="small"
                      startIcon={<FilterIcon />}
                      variant="outlined"
                    >
                      Filter {!!filtersLength && `(${filtersLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <Button
                      className="moderation-table-button"
                      disabled={profileComplaintsStatus === 'loading' || isDisabledSortButton}
                      onClick={() => setSortingDrawer(true)}
                      size="small"
                      startIcon={<SortIcon />}
                      variant="outlined"
                    >
                      Sort {!!sortingLength && `(${sortingLength})`}
                    </Button>
                  )}
                  {ticketsGroup === 'All' && (
                    <LoadingButton
                      className="moderation-table-button"
                      disabled={profileComplaintsStatus === 'loading'}
                      loading={refresh}
                      loadingPosition="start"
                      onClick={refreshData}
                      size="small"
                      startIcon={<RefreshIcon />}
                    >
                      Refresh data
                    </LoadingButton>
                  )}
                </Stack>
              )}
              {status === 'idle' && (
                <div>
                  <LoadingButton
                    className="moderation-table-button"
                    disabled={ticketsGroup === 'My' ? assignStatus === 'loading' || profileComplaintsMyDataLength > 0 : unassignStatus === 'loading'}
                    onClick={ticketsGroup === 'My' ? () => getTickets(ModerationTabs.PROFILE_COMPLAINTS) : () => setIsShowUnassignTicketsModal(true)}
                    startIcon={assignStatus === 'loading' && <CircularProgress color="inherit" size={20} />}
                    variant="contained"
                  >
                    {ticketsGroup === 'My' ? 'Get tickets' : 'Unassign tickets'}
                  </LoadingButton>
                </div>
              )}
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            {(status === 'idle' || status === 'failed') && (
              <div className="moderation-table-tickets-counter">
                Tickets overall:
                <span>{ticketsGroup === 'My' ? profileComplaintsMyDataLength : profileComplaintsLength}</span>
              </div>
            )}
            <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter
                  getRowSpacing={(parameters: GridRowSpacingParams) => ({
                    bottom: parameters.isLastVisible ? 0 : 16,
                  })}
                  onRowsScrollEnd={(p, event, d) =>
                    handleOnRowsScrollEnd(p, event, d, profileComplaintsLength, profileComplaintsMyDataLength, ticketsGroup)
                  }
                  slots={{
                    noRowsOverlay: () =>
                      NoMoreTickets({
                        clearFilter: resetFilters,
                        fetchingError: fetchingError,
                        isFilters: !!filtersLength,
                        refreshData: repeatRequest,
                        ticketsGroup: ticketsGroup,
                      }),
                  }}
                  apiRef={apiReference}
                  columnHeaderHeight={0}
                  columns={cols}
                  filterMode="server"
                  getRowHeight={() => 'auto'}
                  headerFilterHeight={0}
                  loading={profileComplaintsStatus === 'loading'}
                  onCellClick={handleRowClick}
                  onRowClick={handleRowClick}
                  rowBufferPx={rows.length * 400}
                  rows={rowsWithFooter}
                  scrollEndThreshold={100}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSelector
                  disableRowSelectionOnClick
                  disableVirtualization
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.CONFIRMED_VIOLATIONS}>
          <div className="moderation-table-container singleCard">
            <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2} mb={2}>
              <ButtonGroup variant="outlined">
                <Button
                  className={subjectTypeConfirmed === SubjectType.CONTENT ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.CONTENT)}
                  size="small"
                >
                  Content
                </Button>
                <Button
                  className={subjectTypeConfirmed === SubjectType.PROFILE ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.PROFILE)}
                  size="small"
                >
                  Profile complaints
                </Button>
              </ButtonGroup>
              <Button
                className="moderation-table-button"
                disabled={isDisabledFilterButton}
                onClick={() => setFilterDrawer(true)}
                size="small"
                startIcon={<FilterIcon />}
                variant="outlined"
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                className="moderation-table-button"
                onClick={() => setSortingDrawer(true)}
                size="small"
                startIcon={<SortIcon />}
                variant="outlined"
                disabled
              >
                Sort
              </Button>
              <LoadingButton
                className="moderation-table-button"
                disabled={isConfirmedContentViolationsLoading}
                loading={refresh}
                loadingPosition="start"
                onClick={refreshData}
                size="small"
                startIcon={<RefreshIcon />}
              >
                Refresh data
              </LoadingButton>
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter
                  getRowSpacing={() => ({
                    bottom: 16,
                  })}
                  slots={{
                    noRowsOverlay: () =>
                      NoMoreTickets({
                        clearFilter: resetFilters,
                        fetchingError: fetchingError,
                        isFilters: !!filtersLength,
                        refreshData: repeatRequest,
                        ticketsGroup: ticketsGroup,
                      }),
                  }}
                  apiRef={apiReference}
                  columnHeaderHeight={0}
                  columns={cols}
                  filterMode="server"
                  getRowHeight={() => 'auto'}
                  headerFilterHeight={0}
                  loading={isConfirmedContentViolationsLoading || isConfirmedProfileViolationsLoading}
                  onCellClick={handleRowClick}
                  onRowClick={handleRowClick}
                  onRowsScrollEnd={(p, event, d) => handleOnRowsScrollEnd(p, event, d, confirmedViolationsLength)}
                  rowBufferPx={rows.length * 400}
                  rows={rowsWithFooter}
                  scrollEndThreshold={500}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSelector
                  disableRowSelectionOnClick
                  disableVirtualization
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.DECLINED_REQUESTS}>
          <div className="moderation-table-container singleCard">
            <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2} mb={2}>
              <ButtonGroup variant="outlined">
                <Button
                  className={subjectTypeDeclined === SubjectType.CONTENT ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.CONTENT)}
                  size="small"
                >
                  Content
                </Button>
                <Button
                  className={subjectTypeDeclined === SubjectType.PROFILE ? 'Mui-selected' : ''}
                  onClick={() => handleChangeSubjectType(SubjectType.PROFILE)}
                  size="small"
                >
                  Profile complaints
                </Button>
              </ButtonGroup>
              <Button
                className="moderation-table-button"
                disabled={isDisabledFilterButton}
                onClick={() => setFilterDrawer(true)}
                size="small"
                startIcon={<FilterIcon />}
                variant="outlined"
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                className="moderation-table-button"
                onClick={() => setSortingDrawer(true)}
                size="small"
                startIcon={<SortIcon />}
                variant="outlined"
                disabled
              >
                Sort
              </Button>
              <LoadingButton
                className="moderation-table-button"
                disabled={isDeclinedProfileRequestsLoading || isDeclinedContentRequestsLoading}
                loading={refresh}
                loadingPosition="start"
                onClick={refreshData}
                size="small"
                startIcon={<RefreshIcon />}
              >
                Refresh data
              </LoadingButton>
            </Stack>
            {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshAllData} />}
            <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
              <Box sx={{ inset: 0, position: 'absolute' }}>
                <DataGrid
                  hideFooter
                  getRowSpacing={() => ({
                    bottom: 16,
                  })}
                  slots={{
                    noRowsOverlay: () =>
                      NoMoreTickets({
                        clearFilter: resetFilters,
                        fetchingError: fetchingError,
                        isFilters: !!filtersLength,
                        refreshData: repeatRequest,
                        ticketsGroup: ticketsGroup,
                      }),
                  }}
                  apiRef={apiReference}
                  columnHeaderHeight={0}
                  columns={cols}
                  filterMode="server"
                  getRowHeight={() => 'auto'}
                  headerFilterHeight={0}
                  loading={isDeclinedProfileRequestsLoading || isDeclinedContentRequestsLoading}
                  onCellClick={handleRowClick}
                  onRowClick={handleRowClick}
                  onRowsScrollEnd={(p, event, d) => handleOnRowsScrollEnd(p, event, d, declinedRequestsLength)}
                  rowBufferPx={rows.length * 400}
                  rows={rowsWithFooter}
                  scrollEndThreshold={100}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSelector
                  disableRowSelectionOnClick
                  disableVirtualization
                />
              </Box>
            </Box>
          </div>
        </TabPanel>
        <TabPanel value={ModerationTabs.RULES}>
          <div className="moderation-table-container">
            <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2} justifyContent="space-between">
              <ButtonGroup variant="outlined">
                <Button
                  className={rulesValue === Rules.HIVE ? 'Mui-selected' : ''}
                  disabled={!enabledHiveRules}
                  onClick={() => setRulesValue(Rules.HIVE)}
                  size="small"
                >
                  Hive rules
                </Button>
                <Button
                  className={rulesValue === Rules.MANUAL ? 'Mui-selected' : ''}
                  disabled={!enabledContentRules}
                  onClick={() => setRulesValue(Rules.MANUAL)}
                  size="small"
                >
                  Manual rules
                </Button>
                <Button
                  className={rulesValue === Rules.PROFILE ? 'Mui-selected' : ''}
                  disabled={!enabledProfileRules}
                  onClick={() => setRulesValue(Rules.PROFILE)}
                  size="small"
                >
                  Profile rules
                </Button>
              </ButtonGroup>
              <Stack alignItems="center" className="moderation-table-buttons" direction="row" gap={2}>
                <Button
                  onClick={() =>
                    setIsShowRulesModal({
                      action: 'add',
                      availableMetrics: availableMetricsTab,
                      isShowModal: true,
                      rule: {},
                    })
                  }
                  disabled={!availableMetricsTab.length}
                  variant="contained"
                >
                  Add rule
                </Button>
                <Autocomplete
                  inputlabelprops={{
                    shrink: shrink,
                  }}
                  label="Search"
                  inputprops={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ color: colors.actionDisabled, pl: '6px' }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={(event) => {
                    !searchValue && !(event.target as HTMLInputElement).value && setShrink(false)
                  }}
                  onChange={(_event: SyntheticEvent, newValue: null | string) => {
                    typeof newValue === 'string' && newValue.length > 2 ? setSearchValue(newValue) : setSearchValue('')
                  }}
                  sx={{
                    '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                      color: colors.outlinedBorder,
                      transform: 'translate(44px, 9px)',
                    },
                    width: 276,
                  }}
                  onFocus={() => setShrink(true)}
                  options={rowsRules.map(({ metric }) => metric) || []}
                  size="small"
                  freeSolo
                />
              </Stack>
            </Stack>
            <div className="moderation-table">
              <DataGrid
                hideFooter
                rows={rowsRules.filter(({ metric }) => {
                  return metric?.includes(searchValue)
                })}
                apiRef={apiReference}
                columnHeaderHeight={56}
                columns={colsRules}
                loading={statusRules === 'loading'}
                rowHeight={56}
                disableColumnMenu
                disableColumnResize
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
      <UnassignTicketsModal
        currentTab={tabToTicketTypeMap[tabId]}
        onClose={(unassigned) => onUnassignModalClose(unassigned)}
        open={isShowUnassignTicketsModal}
      />
      <CloseUnassignTicketsConfirmModal
        closeModal={() => cancelCloseUnassignModal()}
        onClose={() => setIsShowUnassignTicketsModal(false)}
        open={unassignCloseConfirmModal}
      />
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterDrawerClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterDrawerClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          hasSorting={tabId === ModerationTabs.CONFIRMED_VIOLATIONS || tabId === ModerationTabs.DECLINED_REQUESTS}
          onApply={handleApplyFilter}
          onClose={onFilterDrawerClose}
          type={tabId}
        />
      </Drawer>
      <CloseSortingDrawerConfirmModal
        closeModal={cancelCloseSortingSidebar}
        onClose={() => onSortingDrawerClose(true)}
        open={sortingDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onSortingDrawerClose()} open={sortingDrawer}>
        <SortingDrawer isHidePresets onApply={handleApplySorting} onClose={onSortingDrawerClose} type={tabId} />
      </Drawer>
      <Drawer anchor="right" onClose={() => setMediaPreviewDrawer(undefined)} open={!!mediaPreviewDrawer} sx={{ zIndex: 1400 }}>
        {mediaPreviewDrawer && <MediaPreviewDrawer mediaProps={mediaPreviewDrawer} onClose={() => setMediaPreviewDrawer(undefined)} />}
      </Drawer>

      <Dialog fullWidth maxWidth="lg" onClose={() => showEntityModal()} open={isShowEntityDetailsModal}>
        <div>{cardElement()}</div>
      </Dialog>
      <UserProfileModal
        onClose={() => {
          setIsShowUserProfileModal(false)
          navigate(`/ui/moderation/${tabId}`)
        }}
        open={isShowUserProfileModal}
      />
      <Modal
        onClose={() =>
          setIsShowRulesModal({
            action: undefined,
            isShowModal: false,
            rule: {},
          })
        }
        open={isShowRulesModal.isShowModal}
      >
        <RulesModalContent
          onClose={() =>
            setIsShowRulesModal({
              action: undefined,
              isShowModal: false,
              rule: {},
            })
          }
          contentType={rulesValue}
          item={isShowRulesModal}
        />
      </Modal>
    </div>
  )
}

export default Moderation
