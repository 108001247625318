import { TEntityStatus, TOrderedEntity } from '@admin/store/collectionDetailsSlice'

const changeOrderAndStatus = (entities: TOrderedEntity[], entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'): TOrderedEntity[] => {
  const maxActive = entityType === 'BANNER' ? 5 : 15

  return entities.map((entity, index) => {
    return { ...entity, entityStatus: reorderStatus(entity, maxActive, index), order: index }
  })
}

export default changeOrderAndStatus

const reorderStatus = (entity: TOrderedEntity, maxActive: number, index: number): TEntityStatus => {
  if (entity.entityStatus === 'UNAVAILABLE') {
    return entity.entityStatus
  }

  return index + 1 > maxActive ? 'SPARE' : 'ACTIVE'
}
