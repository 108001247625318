import { GridRenderCellParams } from '@mui/x-data-grid'

import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { OperationTypes } from '@admin/store/slices/Payments/interface'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Props for the RenderFeeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderFeeCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a "Fee" cell in a data grid.
 */
export const RenderFeeCell = ({ props }: IRenderFeeCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const renderFee = (amount: number, type: string, tooltipTitle: string) => {
    return amount ? (
      <Typography component="span" variant="body2">
        <Tooltip title={tooltipTitle} placement="top" followCursor>
          <>
            {currencyFormatter(amount, 'en-US', 'USD', true)}
            <Typography color="text.secondary" component="span" display="inline" variant="body2">
              {type ? ` (${type})` : ''}
            </Typography>
          </>
        </Tooltip>
      </Typography>
    ) : (
      <div>—</div>
    )
  }

  const isPayoutOperation = row.operationType === OperationTypes.PAY_OUT

  return (
    <Stack alignContent="end" textAlign="right">
      {!isPayoutOperation && renderFee(Number(row.serviceFeeClient), 'P', 'For payer')}
      {!isPayoutOperation && renderFee(Number(row.serviceFeeCreator), 'R', 'For recipient')}
      {isPayoutOperation && renderFee(Number(row.paymentFeeAmount), '', 'Payment provider commission')}
    </Stack>
  )
}
