const CommentIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.18677 13.0049C8.87712 13.0049 9.43677 12.4452 9.43677 11.7549C9.43677 11.0645 8.87712 10.5049 8.18677 10.5049C7.49641 10.5049 6.93677 11.0645 6.93677 11.7549C6.93677 12.4452 7.49641 13.0049 8.18677 13.0049Z" />
    <path d="M13.9368 11.7544C13.9368 12.4448 13.3771 13.0044 12.6868 13.0044C11.9964 13.0044 11.4368 12.4448 11.4368 11.7544C11.4368 11.064 11.9964 10.5044 12.6868 10.5044C13.3771 10.5044 13.9368 11.064 13.9368 11.7544Z" />
    <path d="M17.1868 13.0049C17.8771 13.0049 18.4368 12.4452 18.4368 11.7549C18.4368 11.0645 17.8771 10.5049 17.1868 10.5049C16.4964 10.5049 15.9368 11.0645 15.9368 11.7549C15.9368 12.4452 16.4964 13.0049 17.1868 13.0049Z" />
    <path
      clipRule="evenodd"
      d="M12.6872 1.86719C6.79175 1.86719 1.83716 6.13608 1.83716 11.6061C1.83716 13.3403 2.34231 14.9661 3.22103 16.3707L2.27209 19.9123C1.99838 20.9337 2.9331 21.8684 3.95457 21.5947L8.17185 20.4647C9.54929 21.0309 11.0792 21.345 12.6872 21.345C18.5826 21.345 23.5372 17.0761 23.5372 11.6061C23.5372 6.13608 18.5826 1.86719 12.6872 1.86719ZM3.53716 11.6061C3.53716 7.25767 7.53687 3.56719 12.6872 3.56719C17.8374 3.56719 21.8372 7.25767 21.8372 11.6061C21.8372 15.9545 17.8374 19.645 12.6872 19.645C11.2684 19.645 9.92887 19.3614 8.73532 18.8579C8.45815 18.7409 8.14226 18.7127 7.83679 18.7945L4.06042 19.8064L4.89914 16.6762C5.00409 16.2846 4.92739 15.8814 4.72118 15.5619C3.96466 14.3896 3.53716 13.0404 3.53716 11.6061Z"
      fillRule="evenodd"
    />
  </svg>
)

export default CommentIcon
