import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { MEMBERS_API } from '@admin/shared/api/constants'
import { fetchSignIn } from '@admin/store/authSlice'
import { TRootState } from '@admin/store/store'
import { ProfileMessages, TErrorMessages } from '@admin/store/types/ErrorMessages'
import { IMedia, LocalStorageKeys, TStatus } from '@admin/types/commonTypes'

export interface IMember {
  email: string
  firstName: string
  id: string
  lastName: string
  latestActivity: Date | null
  registered: Date | null
  role: {
    id: string
    name: string
    defaultPresetId: string
  }
  status: string
}

export interface IProfileState {
  avatar: IMedia
  avatarPreview: IMedia
  errorStatusText: '' | TErrorMessages
  member: IMember
  status: TStatus
  triesLeft: number
}

const initialMember: IMember = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  latestActivity: null,
  registered: null,
  role: {
    id: '',
    name: '',
    defaultPresetId: '',
  },
  status: '',
}

const initialAvatar: IMedia = {
  id: '',
  width: 0,
  height: 0,
  linkBlurredImage: '',
  originalLink: '',
  type: '',
}

const initialState: IProfileState = {
  avatar: initialAvatar,
  avatarPreview: initialAvatar,
  errorStatusText: '',
  member: localStorage.getItem(LocalStorageKeys.MEMBER) ? JSON.parse(localStorage.getItem(LocalStorageKeys.MEMBER) || '') : initialMember,
  status: 'idle',
  triesLeft: 3,
}

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async () => {
  const response = await axios.get(MEMBERS_API + 'members')

  return response.data
})

export const fetchAvatar = createAsyncThunk('profile/fetchAvatar', async () => {
  const response = await axios.get(MEMBERS_API + 'members/avatar')

  return response.data
})

export const fetchUpdateAvatar = createAsyncThunk(
  'profile/fetchUpdateAvatar',
  async ({ avatar, avatarPreview }: { avatar?: Blob; avatarPreview: Blob }) => {
    const body = new FormData()

    body.append('avatar', avatar ?? '')
    body.append('avatarPreview', avatarPreview ?? '')
    const response = await axios.post(MEMBERS_API + 'members/avatar', body)

    return response.data
  },
)

export const fetchResetPassword = createAsyncThunk('profile/resetPassword', async ({ password }: { password: string }) => {
  const response = await axios
    .post(MEMBERS_API + 'members/password-reset', {
      password: password,
    })
    .catch((error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw Error(error.response.data.title)
      } else {
        throw Error('An unexpected error occurred')
      }
    })

  return response.data
})

export const fetchResetPasswordTriesLeft = createAsyncThunk('profile/resetPasswordTriesLeft', async () => {
  const response = await axios.get(MEMBERS_API + 'members/password-reset-tries-left')

  return response.data
})

export const fetchGlobalSignOut = createAsyncThunk('profile/globalSignOut', async () => {
  return await axios.post(MEMBERS_API + 'members/global-sign-out')
})

export const profileSlice = createSlice({
  name: 'profile',
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }: PayloadAction<IMember>) => {
        state.status = 'idle'
        state.member = payload
        localStorage.setItem(LocalStorageKeys.MEMBER, JSON.stringify(payload))
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchAvatar.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAvatar.fulfilled, (state, { payload }: PayloadAction<{ avatar?: IMedia; avatarPreview?: IMedia }>) => {
        state.status = 'idle'
        state.avatar = payload.avatar ?? initialAvatar
        state.avatarPreview = payload.avatarPreview ?? initialAvatar
      })
      .addCase(fetchAvatar.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchUpdateAvatar.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUpdateAvatar.fulfilled, (state, { payload }: PayloadAction<{ avatar?: IMedia; avatarPreview?: IMedia }>) => {
        state.status = 'idle'
        state.avatar = payload.avatar ?? initialAvatar
        state.avatarPreview = payload.avatarPreview ?? initialAvatar
      })
      .addCase(fetchUpdateAvatar.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchResetPassword.pending, (state) => {
        state.status = 'loading'
        state.errorStatusText = ''
      })
      .addCase(fetchResetPassword.fulfilled, (state, action) => {
        state.status = 'idle'
        state.errorStatusText = ''
      })
      .addCase(fetchResetPassword.rejected, (state, { error }) => {
        state.status = 'failed'
        state.errorStatusText = (error.message ?? '') as TErrorMessages
        if (error.message === ProfileMessages.UNVALIDATED_PASSWORD) {
          state.triesLeft -= 1
        }
      })
      .addCase(fetchResetPasswordTriesLeft.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchResetPasswordTriesLeft.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.triesLeft = payload.triesLeft
      })
      .addCase(fetchResetPasswordTriesLeft.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchGlobalSignOut.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchGlobalSignOut.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(fetchGlobalSignOut.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchSignIn.fulfilled, (state) => {
        state.errorStatusText = ''
        state.triesLeft = 3
      })
  },
  initialState,
  reducers: {},
})

export default profileSlice.reducer
export const selectProfileStatus = (state: TRootState): TStatus => state.profile.status
export const selectProfileStatusText = (state: TRootState): string => state.profile.errorStatusText
export const selectProfile = (state: TRootState): IMember => state.profile.member
export const selectTriesLeft = (state: TRootState): number => state.profile.triesLeft
export const selectAvatar = (state: TRootState): IMedia => state.profile.avatar
export const selectAvatarPreview = (state: TRootState): IMedia => state.profile.avatarPreview
