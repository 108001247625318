import type { TAny } from '@yzzy/types'

const WithdrawPendingWalletIcon = (props: TAny) => {
  return (
    <svg width="25" fill="none" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9605 14.5288C18.9605 14.1146 18.6247 13.7788 18.2105 13.7788C17.7963 13.7788 17.4605 14.1146 17.4605 14.5288V17.1303L16.1797 17.8987C15.8246 18.1118 15.7094 18.5725 15.9225 18.9277C16.1356 19.2829 16.5963 19.3981 16.9515 19.185L18.5964 18.198C18.8223 18.0625 18.9605 17.8184 18.9605 17.5549V14.5288Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M16.7395 8.81476C16.5443 8.71569 16.4722 8.47309 16.5817 8.28352L17.2737 7.08495C16.4781 7.13782 15.6023 7.37975 14.9058 7.94566C14.1401 8.56785 13.4698 9.67976 13.4698 11.7234V12.9376C13.6551 12.7456 13.8531 12.5657 14.0626 12.3994C15.1132 11.5622 16.4426 11.0566 17.8815 11.0566C21.2591 11.0566 23.9952 13.7927 23.9952 17.1703C23.9952 19.0061 23.1831 20.6427 21.9169 21.7563L21.9146 21.7583C20.8355 22.7006 19.4292 23.284 17.8815 23.284C16.9144 23.284 15.9999 23.0597 15.1872 22.6602C14.3376 22.7548 13.4114 22.7944 12.4081 22.7944C8.89032 22.7944 6.3196 22.3079 4.68207 20.6704C3.04453 19.0329 2.55811 16.4621 2.55811 12.9444C2.55811 10.2652 2.84211 8.16456 3.64278 6.59917C4.47703 4.96813 5.82318 4.00787 7.69432 3.52163C8.14867 3.40356 8.61271 3.67617 8.73078 4.13052C8.84885 4.58487 8.57624 5.04891 8.12189 5.16698C6.63685 5.55289 5.733 6.24579 5.15629 7.37331C4.54601 8.56648 4.25811 10.3298 4.25811 12.9444C4.25811 16.4266 4.77168 18.3559 5.88415 19.4683C6.99661 20.5808 8.92589 21.0944 12.4081 21.0944C12.6761 21.0944 12.9348 21.0913 13.1847 21.0852C12.6309 20.4214 12.2152 19.6383 11.9818 18.78C11.8555 18.3158 11.7825 17.8297 11.7698 17.3285C11.7685 17.2759 11.7678 17.2232 11.7678 17.1703C11.7678 17.1174 11.7685 17.0647 11.7698 17.0121V11.7234C11.7698 9.26715 12.5996 7.62907 13.8338 6.62626C14.7748 5.86175 15.8778 5.5261 16.8503 5.41666L13.7274 3.61361C13.3208 3.37889 13.1815 2.85903 13.4162 2.45249C13.651 2.04594 14.1708 1.90664 14.5774 2.14136L20.2125 5.39479L18.5482 8.27745C18.1838 8.90862 17.3894 9.1446 16.7395 8.81476ZM13.4698 17.0355V17.3047C13.5408 19.6812 15.4877 21.584 17.8815 21.584C18.9915 21.584 20.006 21.1676 20.7953 20.4788C21.7129 19.6712 22.2952 18.4922 22.2952 17.1703C22.2952 14.7316 20.3202 12.7566 17.8815 12.7566C16.8468 12.7566 15.8868 13.1193 15.1215 13.7293L15.12 13.7305C14.1403 14.5079 13.5096 15.698 13.4698 17.0355Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default WithdrawPendingWalletIcon
