import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import ArrowDropDownIcon from '@admin/assets/img/ArrowDropDownIcon'
import ArrowDropUpIcon from '@admin/assets/img/ArrowDropUpIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import Button from '@admin/components/shared/Button/Button'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { ITaxModerationCard } from '@admin/store/slices/TaxModeration/interface'

import type { TAny } from '@yzzy/types'

import { taxModerationCardSolutions } from '../constants'

/**
 * Props for the RenderSolutionCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderSolutionCell component.
 * @property {boolean} isMenuOpen - Flag for element anchor.
 * @property {ITaxModerationCard | null} currentSelectedSalesTax - Selected sales tax element.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onEdit - IconButton click event handler.
 */
interface IRenderSolutionCellProps {
  currentSelectedSalesTax: ITaxModerationCard | null
  isMenuOpen: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>, data: TAny) => void
  onEdit: (event: MouseEvent<HTMLButtonElement>, data: TAny) => void
  props: GridRenderCellParams
}

/**
 * Renders a solution cell in a data grid.
 */
export const RenderSolutionCell = ({ currentSelectedSalesTax, isMenuOpen, onClick, onEdit, props }: IRenderSolutionCellProps) => {
  const { row } = props

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onClick(event, row)
  }

  const handleIconButtonClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onEdit(event, row)
  }

  if (!row.id) {
    return null
  }

  if (row.solution) {
    const currentSolutionType = taxModerationCardSolutions.find((element) => row.solution?.toLowerCase() === element.solution?.toLowerCase())

    if (currentSolutionType) {
      return (
        <Stack alignItems="center" direction="row" sx={{ color: currentSolutionType.color }}>
          {row.solution.replace(row.solution, currentSolutionType.title)}
          <Tooltip title="Change sales tax code" placement="top-end" followCursor>
            <IconButton color="default" onClick={(event) => handleIconButtonClick(event)} sx={{ ml: 0.5 }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    }
  } else {
    return (
      <Button
        endIcon={currentSelectedSalesTax?.id === row.id && isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={(event) => handleClick(event)}
        size="small"
        variant="contained"
      >
        Resolve
      </Button>
    )
  }

  return null
}
