import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import WorldTabs from '@admin/components/WorldManagement/types/WorldTabs'
import { WORLD_MANAGEMENT_API } from '@admin/shared/api/constants'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { fetchCohort, fetchEditCohort, ICohort } from '@admin/store/cohortDetailsSlice'
import { ICollection, TCollectionStatus } from '@admin/store/collectionDetailsSlice'
import { TRootState } from '@admin/store/store'
import { TErrorMessages } from '@admin/store/types/ErrorMessages'
import { ILocation, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

export interface ICohortsLinkedCollection {
  cohort: Pick<ICohort, 'id' | 'title'>
  compilation: Pick<ICollection, 'id' | 'title'>
}

export interface ILinkedCollections {
  cohortTitle: string
  creatorLastName?: string
  creatorName?: string
  entityIncluded: boolean
  id: string
  status: TCollectionStatus
  title: string
  totalBanners: number
  totalEvents: number
  totalHeroes: number
  totalOffers: number
  totalWishes: number
}

export type TCohortsTableData = Pick<ICohort, 'createdAt' | 'creatorLastName' | 'creatorName' | 'hasCompilation' | 'id' | 'status' | 'title'>

const collectionsColumns = [
  {
    columnId: 'name',
    columnName: 'Name',
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'status',
    columnName: 'Status',
    flex: 1,
    sortable: false,
  },
  {
    width: 232,
    columnId: 'publicationDate',
    columnName: 'Publication date',
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'cohort',
    columnName: 'ICohort',
    flex: 2,
    sortable: false,
  },
  {
    columnId: 'updatedAt',
    columnName: 'Updated',
    flex: 1,
    sortable: true,
  },
  {
    width: 64,
    columnId: 'action',
    columnName: '',
    flex: 0,
    sortable: false,
  },
]

const bannersColumns = [
  {
    width: 263,
    columnId: 'name',
    columnName: 'Name',
    flex: 2,
    headerClassName: 'headerWithSpace',
    sortable: true,
  },
  {
    width: 148,
    columnId: 'status',
    columnName: 'Status',
    flex: 1,
    sortable: false,
  },
  {
    width: 232,
    columnId: 'expiredDate',
    columnName: 'Validity date',
    flex: 1,
    sortable: false,
  },
  {
    width: 130,
    columnId: 'updated',
    columnName: 'Updated',
    flex: 1,
    sortable: false,
  },
  {
    width: 64,
    columnId: 'action',
    columnName: '',
    flex: 0,
    sortable: false,
  },
]

const cohortsColumns = [
  {
    width: 416,
    columnId: 'name',
    columnName: 'Name',
    flex: 2,
    sortable: true,
  },
  {
    width: 466,
    columnId: 'status',
    columnName: 'Status',
    flex: 2,
    sortable: false,
  },
  {
    width: 466,
    columnId: 'linkedCollection',
    columnName: 'Linked collection',
    flex: 2,
    sortable: false,
  },
  {
    width: 232,
    columnId: 'creationDate',
    columnName: 'Creation date',
    flex: 1,
    sortable: false,
  },
  {
    width: 64,
    columnId: 'action',
    columnName: '',
    flex: 0,
    sortable: false,
  },
]

const linkedCollectionsColumns = [
  {
    columnId: 'name',
    flex: 1,
  },
  {
    columnId: 'cohort',
    flex: 1,
  },
  {
    columnId: 'entities',
    flex: 1,
  },
]

export interface IWorldState {
  allCohortsList: [] | ICohort[]
  archiveBannerStatus: TStatus
  cohortsLinkedCollections: [] | ICohortsLinkedCollection[]
  columns: {
    archive: TAny[]
    banners: TAny[]
    cohorts: TAny[]
    collections: TAny[]
  }
  data: {
    archive: TAny[]
    banners: [] | IBanner[]
    cohorts: [] | TCohortsTableData[]
    collections: [] | ICollection[]
    defaultCollection: ICollection | null
  }
  errorStatusText: '' | TErrorMessages
  expireBannerStatus: TStatus
  filters: []
  linkedCollections: ILinkedCollections[]
  linkedCollectionsColumns: TAny[]
  locations: ILocation[]
  status: TStatus
  totalCount: {
    bannerElements: number
    cohortElements: number
    collectionElements: number
  }
}

const initialState: IWorldState = {
  allCohortsList: [],
  archiveBannerStatus: 'idle',
  cohortsLinkedCollections: [],
  columns: {
    archive: [],
    banners: bannersColumns,
    cohorts: cohortsColumns,
    collections: collectionsColumns,
  },
  data: {
    archive: [],
    banners: [],
    cohorts: [],
    collections: [],
    defaultCollection: null,
  },
  errorStatusText: '',
  expireBannerStatus: 'idle',
  filters: [],
  linkedCollections: [],
  linkedCollectionsColumns: linkedCollectionsColumns,
  locations: [],
  status: 'idle',
  totalCount: {
    bannerElements: 0,
    cohortElements: 0,
    collectionElements: 0,
  },
}

export const fetchWorld = createAsyncThunk(
  'world-management/fetchDataWorld',
  async ({ dataType, page, pageSize }: { dataType: WorldTabs; page: number; pageSize: number }, thunkAPI) => {
    let dataUrl

    const rootState: TRootState = thunkAPI.getState() as TRootState
    const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
    const sortingList = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter((item: TAny) => {
          return item !== null && item.columnId !== null && item.sortingType !== null
        })
      : []

    switch (dataType) {
      case WorldTabs.ARCHIVE: {
        dataUrl = 'timworld/archive'
        break
      }
      case WorldTabs.BANNERS: {
        dataUrl = 'timworld/banners/find'
        break
      }
      case WorldTabs.COHORTS: {
        dataUrl = 'timworld/cohorts/find'
        break
      }
      case WorldTabs.COLLECTIONS: {
        dataUrl = 'timworld/compilations/find'
        break
      }
    }

    const response = await axios.post(WORLD_MANAGEMENT_API + dataUrl, {
      title: null,
      archived: dataType === WorldTabs.ARCHIVE,
      creatorId: null,
      filters: filters,
      pageInfo: {
        page: page,
        size: pageSize,
      },
      sortingList: sortingList,
      statuses: null,
    })

    return { ...response.data, dataType }
  },
)

export const fetchDefaultCollection = createAsyncThunk('world-management/fetchDefaultCollection', async () => {
  const response = await axios.get(WORLD_MANAGEMENT_API + 'timworld/compilations/default')

  return { ...response.data }
})

export const fetchLinkedCollections = createAsyncThunk(
  'world-management/fetchLinkedCollection',
  async ({ entityId, entityType }: { entityId: string; entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH' }) => {
    const response = await axios.get(WORLD_MANAGEMENT_API + 'timworld/compilations', {
      params: {
        entityId,
        entityType,
      },
    })

    return response.data
  },
)

export const fetchAddEntityToTimWorld = createAsyncThunk(
  'world-management/addToTimWorld',
  async ({
    compilationIds,
    entityId,
    entityType,
  }: {
    compilationIds: string[]
    entityId: string
    entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'
  }) => {
    await axios.post(WORLD_MANAGEMENT_API + 'timworld/compilations/elements/add', {
      compilationIds: compilationIds,
      elementId: entityId,
      entityType: entityType,
    })

    return { compilationIds, entityId, entityType }
  },
)

export const fetchArchiveCollections = createAsyncThunk('world-management/archiveCollections', async (compilationIds: string[]) => {
  await axios.post(WORLD_MANAGEMENT_API + 'timworld/compilations/archive', {
    compilationIds: [...compilationIds],
  })

  return compilationIds
})

export const fetchRestoreCollections = createAsyncThunk('world-management/restoreCollections', async (compilationIds: string[]) => {
  await axios.post(WORLD_MANAGEMENT_API + 'timworld/compilations/restore', {
    compilationIds: [...compilationIds],
  })

  return compilationIds
})

export const fetchUnpublishCollection = createAsyncThunk(
  'world-management/unpublishCollection',
  async ({ compilationId, deactivatedDateTime }: { compilationId: string; deactivatedDateTime: string }) => {
    await axios.post(WORLD_MANAGEMENT_API + 'timworld/compilations/status', {
      compilationIds: [compilationId],
      deactivatedDateTime: deactivatedDateTime,
      status: 'DEACTIVATED',
    })

    return { compilationId, deactivatedDateTime }
  },
)

export const fetchPublishCollections = createAsyncThunk(
  'world-management/publishCollection',
  async ({
    compilationIds,
    deactivatedDateTime,
    publishDateTime,
  }: {
    compilationIds: string[]
    deactivatedDateTime?: string
    publishDateTime: string
  }) => {
    await axios.post(WORLD_MANAGEMENT_API + 'timworld/compilations/status', {
      compilationIds: [...compilationIds],
      deactivatedDateTime: deactivatedDateTime ?? null,
      publishDateTime: publishDateTime,
      status: 'LIVE',
    })

    return { compilationIds, deactivatedDateTime, publishDateTime }
  },
)

export const fetchAddCollection = createAsyncThunk(
  'world-management/addCollection',
  async ({ title, cohortId }: { title: string; cohortId: string }) => {
    const response = await axios
      .post(WORLD_MANAGEMENT_API + 'timworld/compilations', {
        title: title,
        cohortId: cohortId,
      })
      .catch((error: unknown) => {
        if (axios.isAxiosError(error)) {
          throw Error(error.response?.data.techInfo.title)
        } else {
          throw Error('An unexpected error occurred')
        }
      })

    return response.data
  },
)

export const fetchAllCohorts = createAsyncThunk('world-management/fetchAllCohorts', async () => {
  const response = await axios.get(WORLD_MANAGEMENT_API + 'timworld/cohorts')

  return response.data
})

export const fetchAddCohort = createAsyncThunk(
  'world-management/addCohort',
  async ({ title, genders, locations, maxAge, minAge }: Pick<ICohort, 'genders' | 'locations' | 'maxAge' | 'minAge' | 'title'>) => {
    const response = await axios
      .post(WORLD_MANAGEMENT_API + 'timworld/cohorts', {
        title,
        genderIds: genders?.map((gender) => gender.id),
        locations,
        maxAge,
        minAge,
      })
      .catch((error: unknown) => {
        if (axios.isAxiosError(error)) {
          throw Error(error.response?.data.techInfo.title)
        } else {
          throw Error('An unexpected error occurred')
        }
      })

    return response.data
  },
)

export const fetchDeleteCohorts = createAsyncThunk('world-management/deleteCohorts', async (cohortIds: string[]) => {
  await axios.delete(WORLD_MANAGEMENT_API + 'timworld/cohorts', {
    data: {
      cohortIds: [...cohortIds],
    },
  })

  return cohortIds
})

export const fetchCohortsLinkedCollections = createAsyncThunk('world-management/cohortsLinkedCollections', async () => {
  const response = await axios.get(WORLD_MANAGEMENT_API + 'timworld/cohorts/compilations/live')

  return response.data
})

export const fetchLocations = createAsyncThunk('world-management/fetchLocations', async ({ text }: { text: string }) => {
  const response = await axios.get(WORLD_MANAGEMENT_API + 'timworld/common/location/reverse', {
    params: {
      text: text,
    },
  })

  return { ...response.data }
})

export const fetchAddBanner = createAsyncThunk(
  'world-management/addBanner',
  async ({ name, subtitle, title, cover, coverPreview }: { name: string; subtitle?: string; title?: string; cover: File; coverPreview: File }) => {
    const body = new FormData()

    body.append('cover', cover)
    body.append('coverPreview', coverPreview)
    body.append('request', new Blob([`{ "name": "${name}", "title": "${title}", "subtitle": "${subtitle}" }`], { type: 'application/json' }))

    const response = await axios.post(WORLD_MANAGEMENT_API + 'timworld/banners', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  },
)

export const fetchArchiveBanner = createAsyncThunk('world-management/archiveBanner', async (bannerIds: string[]) => {
  const response = await axios.post(WORLD_MANAGEMENT_API + 'timworld/banners/archive', {
    bannerIds: bannerIds,
  })

  return response.data
})

export const fetchExpireBanner = createAsyncThunk('world-management/expireBanner', async (bannerIds: string[]) => {
  const response = await axios.post(WORLD_MANAGEMENT_API + 'timworld/banners/expire', {
    bannerIds: bannerIds,
  })

  return response.data
})

export const worldManagementSlice = createSlice({
  name: 'world',
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorld.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchWorld.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        switch (payload.dataType) {
          case WorldTabs.BANNERS:
            state.data.banners = payload.content
            state.totalCount.bannerElements = payload.totalElements
            break
          case WorldTabs.COHORTS:
            state.data.cohorts = payload.content
            state.totalCount.cohortElements = payload.totalElements
            break
          case WorldTabs.COLLECTIONS:
            state.data.collections = payload.content
            state.totalCount.collectionElements = payload.totalElements
            break
        }
      })
      .addCase(fetchWorld.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDefaultCollection.fulfilled, (state, { payload }) => {
        state.data = { ...state.data, defaultCollection: payload }
      })
      .addCase(fetchLinkedCollections.fulfilled, (state, { payload }) => {
        state.linkedCollections = payload
      })
      .addCase(fetchAddEntityToTimWorld.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAddEntityToTimWorld.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(fetchAddEntityToTimWorld.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchArchiveCollections.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchArchiveCollections.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.data.collections = state.data.collections.filter((item) => !payload.includes(item.id))
      })
      .addCase(fetchArchiveCollections.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchAddCollection.fulfilled, (state, { payload }) => {
        state.data.collections = [...state.data.collections, payload]
        state.errorStatusText = ''
      })
      .addCase(fetchAddCollection.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchAddBanner.fulfilled, (state, { payload }) => {
        state.data.banners = [...state.data.banners, payload]
      })
      .addCase(fetchAddCohort.fulfilled, (state, { payload }) => {
        state.data.cohorts = [...state.data.cohorts, payload]
        state.errorStatusText = ''
      })
      .addCase(fetchCohort.fulfilled, (state) => {
        state.errorStatusText = ''
      })
      .addCase(fetchAddCohort.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchEditCohort.fulfilled, (state, { payload }) => {
        const elementIndex = state.data.cohorts.findIndex((cohort) => cohort.id === payload.id)

        state.data.cohorts = [
          ...state.data.cohorts.slice(0, elementIndex),
          { ...state.data.cohorts[elementIndex], title: payload.title },
          ...state.data.cohorts.slice(elementIndex + 1),
        ]
        state.errorStatusText = ''
      })
      .addCase(fetchEditCohort.rejected, (state, { error }) => {
        state.errorStatusText = (error.message ?? '') as TErrorMessages
      })
      .addCase(fetchPublishCollections.fulfilled, (state, { payload }) => {
        state.data.collections = state.data.collections.map((collection) => {
          if (payload.compilationIds.includes(collection.id)) {
            return {
              ...collection,
              expiredDateTime: payload.deactivatedDateTime ? new Date(payload.deactivatedDateTime) : null,
              publishDateTime: new Date(payload.publishDateTime),
              status: 'LIVE',
            }
          }

          return collection
        })
      })
      .addCase(fetchUnpublishCollection.fulfilled, (state, { payload }) => {
        const elementIndex = state.data.collections.findIndex((collection) => collection.id === payload.compilationId)

        state.data.collections = [
          ...state.data.collections.slice(0, elementIndex),
          {
            ...state.data.collections[elementIndex],
            expiredDateTime: new Date(payload.deactivatedDateTime),
            status: 'DEACTIVATED',
          },
          ...state.data.collections.slice(elementIndex + 1),
        ]
      })
      .addCase(fetchDeleteCohorts.fulfilled, (state, { payload }) => {
        state.data.cohorts = state.data.cohorts.filter((item) => !payload.includes(item.id))
      })
      .addCase(fetchAllCohorts.fulfilled, (state, { payload }) => {
        state.allCohortsList = payload
      })
      .addCase(fetchCohortsLinkedCollections.fulfilled, (state, { payload }) => {
        state.cohortsLinkedCollections = payload
      })
      .addCase(fetchLocations.fulfilled, (state, { payload }) => {
        state.locations = payload.content
      })
      .addCase(fetchArchiveBanner.pending, (state, payload) => {
        state.archiveBannerStatus = 'loading'
      })
      .addCase(fetchArchiveBanner.fulfilled, (state, payload) => {
        state.archiveBannerStatus = 'idle'
      })
      .addCase(fetchArchiveBanner.rejected, (state, payload) => {
        state.archiveBannerStatus = 'idle'
      })
      .addCase(fetchExpireBanner.pending, (state, payload) => {
        state.expireBannerStatus = 'loading'
      })
      .addCase(fetchExpireBanner.fulfilled, (state, payload) => {
        state.expireBannerStatus = 'idle'
      })
      .addCase(fetchExpireBanner.rejected, (state, payload) => {
        state.expireBannerStatus = 'idle'
      })
  },
  initialState,
  reducers: {
    clearErrorMessage: (state) => {
      state.errorStatusText = ''
    },
  },
})

export const selectWorldStatus = (state: TRootState): TStatus => state.worldManagement.status
export const selectWorld = (state: TRootState): TAny => state.worldManagement.data
export const selectTotalCount = (state: TRootState): TAny => state.worldManagement.totalCount
export const selectWorldColumns = (state: TRootState): TAny => state.worldManagement.columns
export const selectLocations = (state: TRootState): ILocation[] => state.worldManagement.locations
export const selectErrorText = (state: TRootState): string => state.worldManagement.errorStatusText
export const selectAllCohorts = (state: TRootState): ICohort[] => state.worldManagement.allCohortsList
export const selectLinkedCollections = (state: TRootState): [] | ILinkedCollections[] => state.worldManagement.linkedCollections
export const selectLinkedCollectionsColumns = (state: TRootState): TAny[] => state.worldManagement.linkedCollectionsColumns
export const selectCohortsLinkedCollections = (state: TRootState): [] | ICohortsLinkedCollection[] => state.worldManagement.cohortsLinkedCollections

export const { clearErrorMessage } = worldManagementSlice.actions
export default worldManagementSlice.reducer
