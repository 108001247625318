import colors from '@admin/theme/constants/colors'
import { IDropdownItem, IGroupedDropdownItem, IRefundReason, TStatus } from '@admin/types/commonTypes'

/**
 * Enumerates the possible entity types for sales tax module.
 *
 * @enum {string}
 * @readonly
 * @property {string} DONATION - Donation entity type.
 * @property {string} EVENT - Event entity type.
 * @property {string} GOAL - Goal entity type.
 * @property {string} OFFER - Offer entity type.
 * @property {string} PAID_LIFESTYLE - Paid lifestyle entity type.
 * @property {string} PRIVATE_WISH - Private wish entity type.
 * @property {string} TIP - Tip entity type.
 * @property {string} WISH - Wish entity type.
 */
export enum EntityType {
  DONATION = 'DONATION',
  EVENT = 'EVENT',
  GOAL = 'GOAL',
  OFFER = 'OFFER',
  PAID_LIFESTYLE = 'PAID_LIFESTYLE',
  PRIVATE_WISH = 'PRIVATE_WISH',
  TIP = 'TIP',
  WISH = 'WISH',
}

/**
 * Enumerates the possible formats for entities in app.
 *
 * @enum {string}
 * @readonly
 * @property {string} ONLINE - Online format.
 * @property {string} OFFLINE - Offline format.
 */
export enum EventFormat {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

/**
 * Enumerates the possible online format types for events in sales tax rule.
 *
 * @enum {string}
 * @readonly
 * @property {string} STREAM - Stream format type.
 * @property {string} CONFERENCE - Conference format type.
 */
export enum EventOnlineFormatType {
  CONFERENCE = 'CONFERENCE',
  STREAM = 'STREAM',
}

/**
 * Enumerates the possible status values for payment fees.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status for a payment fee.
 * @property {string} ACTIVE - The active status for a payment fee (**it's always one entry**).
 */
export enum PaymentFeesStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * Enumerates the possible status values for tariff groups.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status for a tariff group.
 * @property {string} ACTIVE - The active status for a tariff group (**it's always one entry**).
 */
export enum TariffGroupsStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * Enumerates the possible status values for taxes.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status.
 * @property {string} ACTIVE - The active status.
 * @property {string} DEFAULT - The default status.
 */
export enum TaxesStatuses {
  ACTIVE = 'ACTIVE',
  DEFAULT = 'DEFAULT',
  INACTIVE = 'INACTIVE',
}

/**
 * Enumerates the possible status values for sales tax rule certainty.
 *
 * @enum {string}
 * @readonly
 * @property {string} FULL - Full certainty.
 * @property {string} UNCERTAIN - Uncertain certainty.
 */
export enum TaxRuleCertainty {
  FULL = 'FULL',
  UNCERTAIN = 'UNCERTAIN',
}

/**
 * Enumerates the possible status values for tier.
 *
 * @enum {string}
 * @readonly
 * @property {string} INACTIVE - The inactive status.
 * @property {string} ACTIVE - The active status.
 */
export enum TierStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * Represents the state of dictionaries in store.
 *
 * @interface
 * @property {IProvider[]} providers - An array of providers.
 * @property {IPaymentMethod[]} paymentMethods - An array of payment methods.
 * @property {ITransactionType[]} transactionTypes - An array of transaction types.
 * @property {IDropdownItem[]} entityTypes - An array of entity types.
 * @property {IDropdownItem[]} formats - An array of formats.
 * @property {IDropdownItem[]} onlineFormatTypes - An array of online format types.
 * @property {IDropdownItem[]} eventCategories - An array of event categories.
 * @property {IGroupedDropdownItem[]} passions - An array of grouped dropdown items representing passions.
 * @property {IGroupedDropdownItem[]} salesTaxCodes - An array of grouped dropdown items representing sales tax codes.
 * @property {Pick<ITaxCode, 'id' | 'code' | 'category' | 'description'>[]} salesTaxCodesList - An array of pick types representing sales tax codes.
 * @property {IDropdownItem[]} certainties - An array of certainties.
 * @property {IRefundReason[]} refundReasons - An array of refund reasons.
 * @property {TStatus} status - The status value for the state data.
 */
export interface IDictionariesState {
  certainties: IDropdownItem[]
  entityTypes: IDropdownItem[]
  eventCategories: IDropdownItem[]
  formats: IDropdownItem[]
  onlineFormatTypes: IDropdownItem[]
  passions: IGroupedDropdownItem[]
  paymentMethods: IPaymentMethod[]
  providers: IProvider[]
  refundReasons: IRefundReason[]
  salesTaxCodes: IGroupedDropdownItem[]
  salesTaxCodesList: Pick<ITaxCode, 'category' | 'code' | 'description' | 'id'>[]
  status: TStatus
  transactionTypes: ITransactionType[]
}

/**
 * Represents a fee condition.
 *
 * @interface
 * @property {string} id - The unique identifier for the condition.
 * @property {string} providerId - The unique identifier for the provider.
 * @property {string} transactionType - The type of transaction.
 * @property {string} paymentMethodId - The unique identifier for the payment method.
 */
export interface IFeeCondition {
  id: string
  paymentMethodId: string
  providerId: string
  transactionType: string
}

/* ------------------------------ Payment fees ------------------------------ */
/**
 * Represents a payment fee.
 *
 * @interface
 * @property {string} providerTariffId - The unique identifier for the fee.
 * @property {string} providerId - The unique identifier for the provider.
 * @property {string} paymentMethodId - The unique identifier for the payment method.
 * @property {string} paymentMethodName - The name of the payment method.
 * @property {TariffGroupsStatuses} feeStatus - The status of the fee.
 * @property {string} paymentFeePercentage - The percentage of the fee.
 * @property {string} paymentFeeFixed - The fixed fee.
 * @property {string} providerName - The name of the provider.
 * @property {string} transactionType - The type of transaction.
 * @property {Date} createdAt - The date of fee's creation.
 */
export interface IPaymentFee {
  createdAt: Date
  feeStatus: PaymentFeesStatuses
  paymentFeeFixed: string
  paymentFeePercentage: string
  paymentMethodId: string
  paymentMethodName: string
  providerId: string
  providerName: string
  providerTariffId: string
  transactionType: string
}

/**
 * Represents the state of payment fees in store.
 *
 * @interface
 * @property {IPaymentFee[]} fees - An array of fees.
 * @property {IFeeCondition[]} feesConditions - An array of conditions.
 * @property {number} feesCount - Total count of payment fees.
 * @property {TStatus} status - The status value for the state data.
 * @property {TStatus} addingFeeStatus - The status value for the fetchAddPaymentFee method.
 */
export interface IPaymentFeesState {
  addingFeeStatus: TStatus
  fees: IPaymentFee[]
  feesConditions: IFeeCondition[]
  feesCount: number
  status: TStatus
}

/**
 * Represents status object for payment fee.
 *
 * @interface
 * @property {PaymentFeesStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface IPaymentFeeStatus {
  color: colors
  status: PaymentFeesStatuses
  title: string
}

/**
 * Represents a payment method.
 *
 * @interface
 * @property {string} paymentMethodId - The ID of the payment method.
 * @property {string} paymentMethodName - The name of the payment method.
 * @property {string} paymentMethodDescription - A description of the payment method.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface IPaymentMethod {
  displayName: string
  paymentMethodDescription: string
  paymentMethodId: string
  paymentMethodName: string
}

/**
 * Represents provider.
 *
 * @interface
 * @property {string} providerId - The ID of the provider.
 * @property {string} providerName - The name of the provider.
 * @property {string} providerDescription - A description of the provider.
 * @property {string} status - The status of the provider.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface IProvider {
  displayName: string
  providerDescription: string
  providerId: string
  providerName: string
  status: string
}

/* ----------------------------- Tariff groups ------------------------------ */
/**
 * Represents a tariff group.
 *
 * @interface
 * @property {string} tariffGroupId - The unique identifier for the tariff group.
 * @property {string} tariffGroupTitle - The title or name of the tariff group.
 * @property {string} groupDescription - The description of the tariff group.
 * @property {TariffGroupsStatuses} status - The status of the tariff group.
 * @property {Date} createdAt - The date of group's creation.
 */
export interface ITariffGroup {
  createdAt: Date
  groupDescription: string
  status: TariffGroupsStatuses
  tariffGroupId: string
  tariffGroupTitle: string
}

/**
 * Represents a tariff group details.
 *
 * @interface
 * @property {string} tariffId - The unique identifier for the tariff.
 * @property {string} priceMin - Minimum price.
 * @property {string} priceMax - Maximum price.
 * @property {string} platformFeePercentageClient - The percentage of platform commission for the client.
 * @property {string} platformFeeFixedClient - The platform fee, fixed client fee.
 * @property {string} platformFeePercentageCreator - The percentage of creator commissions.
 * @property {string} platformFeeFixedCreator - The creator's fixed commission.
 * @property {string} providerName - The name of provider.
 * @property {string} operationType - The type of entity to which the tariff applies.
 * @property {Date} tariffCreatedAt - The date of tariff's creation.
 */
export interface ITariffGroupDetails {
  operationType: string
  platformFeeFixedClient: string
  platformFeeFixedCreator: string
  platformFeePercentageClient: string
  platformFeePercentageCreator: string
  priceMax: string
  priceMin: string
  providerName: string
  tariffCreatedAt: Date
  tariffId: string
}

/**
 * Represents the state of tariff groups in store.
 *
 * @interface
 * @property {ITariffGroup[]} tariffGroups - An array of tariff groups.
 * @property {ITariffGroupDetails[]} tariffGroupDetails - An array of tariffs.
 * @property {TStatus} tariffGroupsStatus - The status object indicating the state data (tariff group).
 * @property {TStatus} tariffGroupDetailsStatus - The status object indicating the state data (tariffs).
 */
export interface ITariffGroupsState {
  tariffGroupDetails: ITariffGroupDetails[]
  tariffGroupDetailsStatus: TStatus
  tariffGroups: ITariffGroup[]
  tariffGroupsStatus: TStatus
}

/**
 * Represents status object for tariff group.
 *
 * @interface
 * @property {TariffGroupsStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITariffGroupStatus {
  color: colors
  status: TariffGroupsStatuses
  title: string
}

/* ------------------------------ Taxes ------------------------------ */
/**
 * Represents a sales tax code.
 *
 * @interface
 * @property {string} id - The unique identifier of entry.
 * @property {string} salesTaxCodeId - Additional unique identifier of entry.
 * @property {string} code - The unique tax code.
 * @property {string} category - The category of tax code.
 * @property {string} description - The description of the tax code.
 * @property {TaxesStatuses} status - The status of the tax code.
 */
export interface ITaxCode {
  category: string
  code: string
  description: string
  id: string
  salesTaxCodeId: string
  status: TaxesStatuses
}

/**
 * Represents the state of sales tax codes in store.
 *
 * @interface
 * @property {ITaxCode[]} taxCodes - An array of sales tax codes.
 * @property {ITaxRule[]} taxRules - An array of sales tax rules.
 * @property {{ taxCodes: number; taxRules: number }} totalCount - Total count of tax codes and rules.
 * @property {TStatus} status - The status value for the state data.
 */
export interface ITaxesState {
  status: TStatus
  taxCodes: ITaxCode[]
  taxRules: ITaxRule[]
  totalCount: {
    taxCodesCount: number
    taxRulesCount: number
  }
}

/**
 * Represents a rule for sales tax.
 *
 * @interface
 * @property {string} [id] - The unique identifier of the sales tax rule (optional).
 * @property {string} salesTaxRuleId - The unique identifier of the sales tax rule.
 * @property {Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'>} salesTaxCode - Information about the associated sales tax code.
 * @property {TaxRuleCertainty} certainty - The certainty level of applying the sales tax rule.
 * @property {EntityType} entityType - The type of entity to which the sales tax applies.
 * @property {EventOnlineFormatType} [eventOnlineFormatType] - The online format type (applies only if entityType is EVENT and format is ONLINE).
 * @property {TaxesStatuses} status - The status of the sales tax rule.
 * @property {boolean} isDefaultSalesTaxRule - Indicates if the sales tax rule is a default one.
 * @property {EventFormat} [format] - The format of the sales tax rule (applies only for entityType Offer/Wish/PrivateWish/Event).
 * @property {(string | void | IDropdownItem)[]} [eventCategories] - Array of event categories (applies only for entityType EVENT).
 * @property {(
 *   | string[]
 *   | void
 *   | IGroupedDropdownItem[]
 * )[]} [passions] - Array of passions (applies only for entityType Offer/Wish/Event).
 */
export interface ITaxRule {
  certainty: TaxRuleCertainty
  entityType: EntityType
  eventCategories?: (IDropdownItem | string | void)[]
  eventOnlineFormatType?: EventOnlineFormatType
  format?: EventFormat
  id?: string
  isDefaultSalesTaxRule: boolean
  passions?: (IGroupedDropdownItem[] | string[] | void)[]
  salesTaxCode: Pick<ITaxCode, 'category' | 'code' | 'id' | 'salesTaxCodeId' | 'status'>
  salesTaxRuleId: string
  status: TaxesStatuses
}

/**
 * Represents status object for sales tax.
 *
 * @interface
 * @property {TaxesStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITaxStatus {
  color: colors
  status: TaxesStatuses
  title: string
}

/* ------------------------------ In App Tiers ------------------------------ */
/**
 * Represents an in-app tier.
 *
 * @interface
 * @property {string} linkId - The unique identifier of entry.
 * @property {string} provider - The platform through which the in-app purchase is made (Apple or Google).
 * @property {string} productId - A unique tier identifier in an Apple or Google Developer account.
 * @property {string} name - The name of the tier in the Apple or Google Developer account.
 * @property {string} price - Purchase price.
 * @property {string} country - The country for which the tier is applied.
 * @property {string} currency - The currency code of purchase.
 * @property {string} operationType - The type of operation (online event, paid lifestyle, stream donation).
 * @property {TierStatuses} status - The status of the tier.
 */
export interface ITier {
  country: string
  currency: string
  linkId: string
  name: string
  operationType: string
  price: string
  productId: string
  providerId: string
  status: TierStatuses
}

/* ------------------------------ Dictionaries ------------------------------ */

/**
 * Represents the state of in-app tiers in store.
 *
 * @interface
 * @property {ITier[]} tiers - An array of tiers.
 * @property {number} totalCount - Number of all tiers.
 * @property {{ value: string; providerName: string }[]} providerOptions - An array of providers for Autocomplete.
 * @property {{ value: string; operationTypeDescription: string }[]} operationTypesOptions - An array of operationTypes for Autocomplete.
 * @property {{ value: string; providerName: string; productId: string; name: string; price: string }[]} linkIdOptions - An array of linkId for Autocomplete.
 * @property {TStatus} status - The status value for the state data.
 */
export interface ITiersState {
  linkIdOptions: {
    name: string
    providerName: string
    price: string
    productId: string
    value: string
  }[]
  operationTypesOptions: {
    operationTypeDescription: string
    value: string
  }[]
  providerOptions: {
    providerName: string
    value: string
  }[]
  status: TStatus
  tiers: ITier[]
  totalCount: number
}

/**
 * Represents status object for in-app tiers.
 *
 * @interface
 * @property {TierStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface ITierStatus {
  color: colors
  status: TierStatuses
  title: string
}

/**
 * Represents a transaction type.
 *
 * @interface
 * @property {string} transactionTypeId - The ID of the transaction type.
 * @property {string} transactionTypeName - The name of the transaction type.
 * @property {string} displayName - Label of element for dropdown.
 */
export interface ITransactionType {
  displayName: string
  transactionTypeId: string
  transactionTypeName: string
}

/**
 * Support type for mapping DataGrid data in sales tax rules.
 */
export type TDataGridRowItem = {
  eventCategories?: {
    id: string
    title: string
  }[]
  passions?: {
    id: string
    title: string
  }[][]
  certainty: TaxRuleCertainty
  entityType: EntityType
  eventOnlineFormatType?: EventOnlineFormatType
  format?: EventFormat
  salesTaxCode: Pick<ITaxCode, 'category' | 'code' | 'id' | 'salesTaxCodeId' | 'status'>
  salesTaxRuleId: string
  status: TaxesStatuses
}
