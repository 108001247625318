import './index.scss'

import { useCallback, useMemo, useState } from 'react'
import { Area } from 'react-easy-crop/types'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import ZoomOuttaIcon from '@admin/assets/img/ZoomOuttaIcon'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Divider from '@admin/components/shared/Divider/Divider'
import Dropzone from '@admin/components/shared/Dropzone/Dropzone'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ImageCrop from '@admin/components/shared/ImageCrop/ImageCrop'
import Modal from '@admin/components/shared/Modal/Modal'
import Slider from '@admin/components/shared/Slider/Slider'
import TextField from '@admin/components/shared/TextField/TextField'
import AddBannerModalCancel from '@admin/components/WorldManagement/Banners/BannersModal/AddBanner/AddBannerModalCancel'
import getValidationSchema from '@admin/components/WorldManagement/Banners/BannersModal/utils/getValidationSchema'
import { useAppDispatch } from '@admin/store/hooks'
import { fetchAddBanner } from '@admin/store/worldManagementSlice'
import { blobToFile } from '@admin/utils//blobToFile'
import { getCroppedImg } from '@admin/utils//canvasUtils'

import type { TAny } from '@yzzy/types'

interface Image {
  file: File
  src: string
  type?: string
}

interface IProps {
  onClose(): void
}

const BYTE = 1
const KILOBYTE = 1024 * BYTE
const MEGABYTE = 1024 * KILOBYTE
const ACCEPTED_FILES = ['image/jpeg', 'image/jpg', 'image/png']

const AddBannerModal = ({ onClose }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [image, setImage] = useState<Image | null>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [zoom, setZoom] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const dispatch = useAppDispatch()

  const schema = useMemo(() => getValidationSchema('add'), [])
  const {
    formState: { isValid, errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm<{ name: string; subtitle?: string; title?: string; cover: File | null; coverPreview: File | null }>({
    defaultValues: {
      name: '',
      subtitle: '',
      title: '',
      cover: null,
      coverPreview: null,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: TAny) => {
    try {
      const croppedImage = await getCroppedImg(
        image?.src ?? '',
        image?.type ?? '',
        croppedAreaPixels ?? {
          width: 366,
          height: 244,
          x: 0,
          y: 0,
        },
        0,
      )

      if (croppedImage) {
        const fileCroppedImage = blobToFile(croppedImage, image?.file.name + 'preview')

        trigger()

        const result = await dispatch(
          fetchAddBanner({
            name: data.name,
            subtitle: data.subtitle,
            title: data.title,
            cover: data.cover || null,
            coverPreview: fileCroppedImage,
          }),
        )

        if (result.meta.requestStatus === 'fulfilled') {
          onClose()
          enqueueSnackbar(`“${data.name}” banner has been created`, { variant: 'success' as VariantType })
        }

        if (result.meta.requestStatus === 'rejected') {
          setErrorMessage('Banner creation error, try again later')
        }
      }
    } catch {
      setErrorMessage('Banner creation error, try again later')

      return
    }
  }

  const onLoadImage = (files: File[]) => {
    if (files && files[0]) {
      const img = new Image()
      const blobUrl: string = URL.createObjectURL(files[0])

      img.onload = () => {
        if (img.width > 5000 || img.height > 5000) {
          setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed')
        } else {
          setImage({
            file: files[0],
            src: blobUrl,
            type: files[0].type,
          })
          setValue('cover', files[0])
          trigger('cover')
        }
      }
      img.src = blobUrl
    }
  }

  const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return (
    <form className="addBannerModal" onSubmit={handleSubmit(onSubmit)}>
      <div className="addBannerModal-header">Add new banner</div>
      <div className={`addBannerModal-content ${errorMessage ? 'addBannerModal-content_overflow' : ''}`}>
        <div>
          <TextField
            {...register('name')}
            fullWidth={true}
            label="Name*"
            name="name"
            defaultValue={''}
            error={!!errors.name}
            onBlur={() => trigger('name')}
            size="small"
          />
          {errors.name && <FormHelperText error>{errors.name?.message as string}</FormHelperText>}
        </div>

        <Divider orientation="horizontal" />

        <TextField {...register('title')} fullWidth={true} label="Title" name="title" defaultValue={''} size="small" />
        <TextField {...register('subtitle')} fullWidth={true} label="Subtitle" name="subtitle" defaultValue={''} size="small" />

        <div className="addBannerModal-content-name">Cover*</div>
        <div className="addBannerModal-cover">
          <div className="addBannerModal-cover-uploadArea">
            {image !== null ? (
              <div className="addBannerModal-cover-uploadArea_containerCropper">
                <IconButton
                  onClick={() => {
                    setImage(null)
                    setValue('cover', null)
                    setValue('coverPreview', null)
                    trigger('cover')
                  }}
                  className="addBannerModal-cover-uploadArea_deleteButton"
                >
                  <DeleteIcon />
                </IconButton>
                <div className="addBannerModal-cover-uploadArea_crop">
                  <ImageCrop
                    style={{
                      containerStyle: { borderRadius: '8px' },
                    }}
                    aspect={4 / 3}
                    image={image.src}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    zoom={zoom}
                  />
                </div>
                <div className="addBannerModal-cover-uploadArea_controls">
                  <IconButton onClick={() => setZoom((previousState) => (previousState <= 1 ? previousState : Number(previousState - 0.1)))}>
                    <ZoomOuttaIcon />
                  </IconButton>
                  <Slider
                    aria-labelledby="Zoom"
                    classes={{ root: 'slider' }}
                    max={3}
                    min={1}
                    onChange={(_event, zoom) => setZoom(Number(zoom))}
                    size="small"
                    step={0.1}
                    value={zoom}
                  />
                  <IconButton onClick={() => setZoom((previousState) => (previousState >= 3 ? previousState : Number(previousState + 0.1)))}>
                    <ZoomInIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className="addBannerModal-cover-uploadArea_containerDropzone">
                <span className="addBannerModal-cover-uploadArea_title">One .jpg, .jpeg or .png file only</span>
                <Dropzone
                  getFileAddedMessage={() => {
                    setErrorMessage('')

                    return ''
                  }}
                  onDropRejected={(rejectedFiles) => {
                    setErrorMessage('')
                    if (rejectedFiles.length > 1) {
                      setErrorMessage('Only one file allowed')

                      return
                    }
                    if (!ACCEPTED_FILES.includes(rejectedFiles[0].type)) {
                      setErrorMessage('Unsupported file extension')

                      return
                    }
                    if (rejectedFiles[0].size > MEGABYTE * 30) {
                      setErrorMessage('Files larger than 30 Mb and bigger than 5000px are not allowed')
                    }
                  }}
                  acceptedFiles={ACCEPTED_FILES}
                  dropzoneClass="addBannerModal-cover-uploadArea_dropzone"
                  dropzoneText={'Drag and drop a file here or click'}
                  filesLimit={1}
                  initialFiles={undefined}
                  maxFileSize={30 * MEGABYTE}
                  onDrop={onLoadImage}
                  showAlerts={false}
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="addBannerModal-footer">
        {errorMessage && errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <div className="addBannerModal-footer-buttons">
          <Button
            onClick={() => {
              const values = getValues()

              if (values.name || values.title || values.subtitle || image) {
                setIsOpenModal(true)

                return
              }
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button color="primary" disabled={!isValid || !image} type="submit" variant="contained">
            Add Banner
          </Button>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <AddBannerModalCancel closeModal={() => setIsOpenModal(false)} onClose={onClose} />
      </Modal>
    </form>
  )
}

export default AddBannerModal
