import './index.scss'

import { CSSProperties, memo, useCallback, useState } from 'react'

import type { TAny } from '@yzzy/types'

import SingleInput from './SingleInput'

export interface IOTPInputProps {
  autoFocus?: boolean
  className?: string

  disabled?: boolean
  inputClassName?: string
  inputStyle?: CSSProperties

  isNumberInput?: boolean
  length: number

  onChangeOTP: (otp: string) => TAny
  style?: CSSProperties
}

export function OTPInputComponent({ className = 'otpContainer', inputClassName = 'otpInput', ...props }: IOTPInputProps) {
  const { autoFocus, disabled, inputStyle, isNumberInput, length, onChangeOTP, ...rest } = props

  const [activeInput, setActiveInput] = useState(0)
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''))

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('')

      onChangeOTP(otpValue)
    },
    [onChangeOTP],
  )

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (string_: string) => {
      const changedValue = string_

      if (!isNumberInput) {
        return changedValue
      }

      return !changedValue || /\d/.test(changedValue) ? changedValue : ''
    },
    [isNumberInput],
  )

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (string_: string) => {
      const updatedOTPValues = [...otpValues]

      updatedOTPValues[activeInput] = string_[0] || ''
      setOTPValues(updatedOTPValues)
      handleOtpChange(updatedOTPValues)
    },
    [activeInput, handleOtpChange, otpValues],
  )

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)

      setActiveInput(selectedIndex)
    },
    [length],
  )

  const focusPreviousInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index)
    },
    [focusInput],
  )

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getRightValue(event.currentTarget.value)

      if (!value) {
        event.preventDefault()

        return
      }
      changeCodeAtFocus(value)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  )

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1)
  }, [])

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case ' ': {
          event.preventDefault()

          break
        }
        case 'ArrowLeft': {
          event.preventDefault()
          focusPreviousInput()
          break
        }
        case 'ArrowRight': {
          event.preventDefault()
          focusNextInput()
          break
        }
        case 'Backspace':
        case 'Delete': {
          event.preventDefault()
          if (otpValues[activeInput]) {
            changeCodeAtFocus('')
          } else {
            focusPreviousInput()
          }
          break
        }
        default:
          break
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPreviousInput, otpValues],
  )

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      const pastedData = event.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('')

      if (pastedData) {
        let nextFocusIndex = 0
        const updatedOTPValues = [...otpValues]

        updatedOTPValues.forEach((value, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || value)

            if (changedValue) {
              updatedOTPValues[index] = changedValue
              nextFocusIndex = index
            }
          }
        })
        setOTPValues(updatedOTPValues)
        handleOtpChange(updatedOTPValues)
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1))
      }
    },
    [activeInput, getRightValue, length, otpValues],
  )

  return (
    <div className={className} {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            autoFocus={autoFocus}
            className={inputClassName}
            disabled={disabled}
            focus={activeInput === index}
            onBlur={onBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus(index)}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            style={inputStyle}
            value={otpValues && otpValues[index]}
          />
        ))}
    </div>
  )
}

const OTPInput = memo(OTPInputComponent)

export default OTPInput
