import './index.scss'

import { motion } from 'motion/react'
import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom'

import { GridColDef, GridPaginationModel, GridRowParams } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useDocumentTitle } from '@uidotdev/usehooks'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import FilterIcon from '@admin/assets/img/FilterIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderExpandableCell from '@admin/components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Radio from '@admin/components/shared/Radio/Radio'
import RadioGroup from '@admin/components/shared/RadioGroup/RadioGroup'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import SortingChip from '@admin/components/SortingChip/SortingChip'
import { TaxModerationRoutes } from '@admin/routes/enum'
import { selectUserPermissions } from '@admin/store/authSlice'
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
  sortFilters,
} from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITaxModerationCard, TaxModerationSolution } from '@admin/store/slices/TaxModeration/interface'
import {
  fetchChangeTaxModerationSolution,
  fetchTaxModerationCards,
  selectTaxModerationCards,
  selectTaxModerationStatus,
  selectTotalCount,
  setTaxModerationStatus,
} from '@admin/store/slices/TaxModeration/taxModerationSlice'
import { clearSortingData, fetchSortingColumns, selectSortingColumns, setSortingData } from '@admin/store/sortingSlice'
import { IFilterItem, LocalStorageKeys, Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderEntityCell, RenderReceivedCell, RenderSolutionCell, RenderTaxCodeCell, TaxModerationSearch } from './components'
import { defaultTaxModerationSortingModel, SOLVED_FILTER_KIND, TaxModerationListType, UNRESOLVED_FILTERS_KIND } from './constants'
import { ApprovalConfirmModal, ChangeTaxCodeModal } from './modals'

const TaxModeration: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { showBoundary } = useErrorBoundary()
  const { id: cardId, listType: listTypeParameter } = useParams()
  const currentOutlet = useOutlet()
  const apiReference = useGridApiRef()

  const taxModerationCards = useAppSelector(selectTaxModerationCards)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectTaxModerationStatus)
  const filters = useAppSelector(selectFilters)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const filtersData = useAppSelector(selectFiltersData)
  const userPermissions = useAppSelector(selectUserPermissions)
  const sortingColumns = useAppSelector(selectSortingColumns)

  const isLoading = status === 'loading'

  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP)
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false

  const [isOpenSalesTaxApprovalModal, setIsOpenSalesTaxApprovalModal] = useState<boolean>(false)
  const [isOpenChangeTaxCodeModal, setIsOpenChangeTaxCodeModal] = useState<boolean>(false)

  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [listType, setListType] = useState(
    listTypeParameter ? (listTypeParameter.toUpperCase() as TaxModerationListType) : TaxModerationListType.UNRESOLVED,
  )

  useDocumentTitle(`Sales tax moderation - ${startCase(listType.toLowerCase())}`)

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const [currentSelectedSalesTax, setCurrentSelectedSalesTax] = useState<ITaxModerationCard | null>(null)

  const [solutionMenuAnchorElement, setSolutionMenuAnchorElement] = useState<HTMLElement | null>(null)
  const isSolutionMenuOpen = Boolean(solutionMenuAnchorElement)

  const [highlightedSalesTaxId, setHighlightedSalesTaxId] = useState<null | string>(null)

  const tableScrollTop = useCallback(() => {
    apiReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const filtersLength = useMemo(() => {
    /** Subtraction due to search using "entity_name" filter. Just UI **/
    if (!isEmpty(filtersCurrentData?.entity_name) || !isEmpty(filtersCurrentData?.sales_tax_moderation_search_by_part_of_entity_name)) {
      return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length - 1
    }

    return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length
  }, [filtersCurrentData, filtersData])

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const handleCardApproval = async () => {
    setIsOpenSalesTaxApprovalModal(false)

    const formattedData = {
      newSalesTaxCodeId: null,
      salesTaxModerationId: currentSelectedSalesTax ? currentSelectedSalesTax.id : null,
      solution: TaxModerationSolution.APPROVED,
    }

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Approved`, {
          variant: 'success' as VariantType,
        })

        setHighlightedSalesTaxId(result.payload.id)
        setTimeout(() => {
          setHighlightedSalesTaxId(null)
          void fetchTaxModerationGrid(paginationModel.page, paginationModel.pageSize, listType)
        }, 2000)
      } else if (result.payload.meta) {
        const { title } = result.payload

        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        })
      } else {
        enqueueSnackbar('An error occurred during card resolution', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred during card resolution', {
        variant: 'error' as VariantType,
      })
    }
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchTaxModerationGrid(0, newPaginationModel.pageSize, listType)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchTaxModerationGrid(newPaginationModel.page, newPaginationModel.pageSize, listType)

      return newPaginationModel
    })
  }

  const handleSolutionMenuClick = (event: MouseEvent<HTMLButtonElement>, data: ITaxModerationCard) => {
    event.stopPropagation()
    setSolutionMenuAnchorElement(event.currentTarget)
    setCurrentSelectedSalesTax(data)
  }

  const handleSolutionMenuClose = () => {
    setSolutionMenuAnchorElement(null)
  }
  const handleMenuItemClick = (option: string) => {
    handleSolutionMenuClose()
    if (option.toLowerCase() === TaxModerationSolution.APPROVED.toLowerCase()) {
      if (!salesTaxApprovalConfirmSkip) {
        setIsOpenSalesTaxApprovalModal(true)
      } else void handleCardApproval()
    } else if (option.toLowerCase() === TaxModerationSolution.MODIFIED.toLowerCase()) {
      setIsOpenChangeTaxCodeModal(true)
    }
  }

  const handleCloseTaxCodeModal = (isSucceed: boolean) => {
    setIsOpenChangeTaxCodeModal(false)
    if (isSucceed && currentSelectedSalesTax) {
      setHighlightedSalesTaxId(currentSelectedSalesTax.id)
      setTimeout(() => {
        setHighlightedSalesTaxId(null)
        void fetchTaxModerationGrid(paginationModel.page, paginationModel.pageSize, listType)
      }, 2000)
    }
  }

  const handleChangeListType = async (event: ChangeEvent<HTMLInputElement>) => {
    setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
    dispatch(clearSortingData())

    if (cardId || location.pathname.includes('all-resolved')) {
      navigate(TaxModerationRoutes.TAX_MODERATION)
    }
    setListType(event.target.value as TaxModerationListType)

    if (event.target.value?.toUpperCase() === TaxModerationListType.UNRESOLVED) {
      dispatch(sortFilters(UNRESOLVED_FILTERS_KIND))
      dispatch(setSortingData([defaultTaxModerationSortingModel]))
      await fetchTaxModerationGrid(0, paginationModel.pageSize, TaxModerationListType.UNRESOLVED)
    } else {
      dispatch(sortFilters(SOLVED_FILTER_KIND))
      dispatch(setSortingData([defaultTaxModerationSortingModel]))
      await fetchTaxModerationGrid(0, paginationModel.pageSize, TaxModerationListType.SOLVED)
    }
  }

  const handleRowClick = (parameters: GridRowParams<TAny>) => {
    const currentRow = parameters.row

    navigate(`/ui/tax-moderation/${listType.toLowerCase()}/details/${currentRow.id}`)
  }

  const columns: GridColDef[] = [
    {
      minWidth: 110,
      field: 'entityType',
      flex: 0.3,
      headerName: 'Entity',
      renderCell: (parameters) => <RenderEntityCell {...parameters} />,
      sortable: false,
    },
    {
      minWidth: 140,
      field: 'entityName',
      flex: 0.5,
      headerName: 'Name',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 180,
      field: 'entityDescription',
      flex: 0.6,
      headerName: 'Description',
      renderCell: (parameters) => <RenderExpandableCell {...parameters} />,
      sortable: false,
    },
    {
      minWidth: 180,
      field: 'salesTaxCode.description',
      flex: 0.6,
      headerName: 'Sales tax description',
      renderCell: (parameters) => <RenderExpandableCell {...parameters} />,
      sortable: false,
      valueGetter: (_, row) => row.salesTaxCode?.description,
    },
    {
      minWidth: 150,
      field: 'salesTaxCode',
      flex: 0.5,
      headerName: 'Sales tax code',
      renderCell: (parameters) => <RenderTaxCodeCell props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 120,
      field: 'createdAt',
      flex: 0.3,
      headerName: 'Received',
      renderCell: (parameters) => <RenderReceivedCell props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 140,
      field: 'solution',
      flex: 0.5,
      headerName: 'Solution',
      renderCell: (parameters) => (
        <RenderSolutionCell
          onEdit={(_, data) => {
            setCurrentSelectedSalesTax(data)
            handleMenuItemClick(TaxModerationSolution.MODIFIED)
          }}
          currentSelectedSalesTax={currentSelectedSalesTax}
          isMenuOpen={!!solutionMenuAnchorElement}
          onClick={(event, data) => handleSolutionMenuClick(event, data)}
          props={parameters}
        />
      ),
      sortable: false,
      type: 'actions',
    },
  ]

  const rows: [] | ITaxModerationCard[] = useMemo(() => {
    if (taxModerationCards) {
      return taxModerationCards
    } else return []
  }, [taxModerationCards])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength)

  const fetchTaxModerationInitial = useCallback(async (page: number, pageSize: number, listType: TaxModerationListType) => {
    try {
      await dispatch(fetchTaxModerationCards({ listType, page, pageSize })).unwrap()
    } catch (error) {
      showBoundary(error)
    }
  }, [])

  const fetchTaxModerationGrid = useCallback(
    async (page: number, pageSize: number, listType: TaxModerationListType) => {
      dispatch(fetchTaxModerationCards({ listType, page, pageSize })).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
    },
    [dispatch],
  )

  const repeatFetchingRequest = useCallback(async () => {
    setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
    await fetchTaxModerationGrid(paginationModel.page, paginationModel.pageSize, listType)
  }, [fetchTaxModerationGrid, paginationModel, listType])

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters())
    await dispatch(
      fetchTaxModerationCards({
        listType,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      }),
    )
  }, [])

  const handleSortingModelApply = async () => {
    await fetchTaxModerationGrid(paginationModel.page, paginationModel.pageSize, listType)
  }

  const initialFetchData = useCallback(async () => {
    dispatch(setTaxModerationStatus('loading'))
    await dispatch(fetchFilters('tax-moderation'))
    await dispatch(fetchSortingColumns('tax-moderation'))
    if (filters && sortingColumns) {
      dispatch(sortFilters(UNRESOLVED_FILTERS_KIND))
      dispatch(setSortingData([defaultTaxModerationSortingModel]))
      await fetchTaxModerationInitial(paginationModel.page, paginationModel.pageSize, listType)
    }
  }, [dispatch, paginationModel, sortingColumns, filters, fetchTaxModerationGrid])

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setListType(location.state?.listType)
    }

    return () => {
      location.state = null
    }
  }, [location.state])

  useEffect(() => {
    dispatch(clearFilters())
    dispatch(clearSortingData())
    initialFetchData()
  }, [])

  useEffect(() => {
    if (filtersCurrentData?.entity_type?.element?.value === 'PRIVATE_WISH') {
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_passions' }))
    }
  }, [dispatch, filtersCurrentData])

  return (
    <div className="tax-moderation-container">
      <Typography variant="h6">Sales tax moderation</Typography>
      <div className="tax-moderation-content">
        <div className="tax-moderation-content__actions">
          <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2} pl={2}>
            <FormControl>
              <RadioGroup onChange={handleChangeListType} value={listType} row>
                <FormControlLabel label="Unresolved" control={<Radio />} value={TaxModerationListType.UNRESOLVED} />
                <FormControlLabel label="Solved" control={<Radio />} value={TaxModerationListType.SOLVED} />
              </RadioGroup>
            </FormControl>
            <Button
              disabled={isDisabledFilterButton}
              onClick={() => setFilterDrawer(true)}
              size="small"
              startIcon={<FilterIcon />}
              variant="outlined"
            >
              Filter {!!filtersLength && `(${filtersLength})`}
            </Button>
            <SortingChip
              defaultSortingModel={defaultTaxModerationSortingModel}
              disabled={isEmptyData}
              onSortingModelApply={() => handleSortingModelApply()}
            />
          </Stack>
          <TaxModerationSearch listType={listType} refreshData={() => repeatFetchingRequest()} />
        </div>
        {filtersLength !== 0 && <ActiveFilterList refreshData={() => repeatFetchingRequest().then(tableScrollTop)} />}
        {currentOutlet}
        {!cardId && !location.pathname.includes('all-resolved') && (
          <motion.div
            animate={{ opacity: 1 }}
            className="tax-moderation-table"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter={isEmptyData && !fetchingError}
                hideFooterSelectedRowCount
                slots={{
                  footer: CustomFooter,
                  noRowsOverlay: () =>
                    CustomNoRowsOverlay({
                      clearFilters: () => resetFilters(),
                      fetchingError: fetchingError,
                      isFiltersApplied: !!filtersLength,
                      refreshData: () => repeatFetchingRequest(),
                      renderNoDataComponent: () => (
                        <Typography color="text.secondary" variant="body2">
                          {listType === TaxModerationListType.UNRESOLVED ? 'No cards have yet been received for moderation' : 'No cards'}
                        </Typography>
                      ),
                    }),
                }}
                apiRef={apiReference}
                columnHeaderHeight={64}
                columns={columns}
                filterMode="server"
                getRowClassName={(parameters) => `${parameters.row.id === highlightedSalesTaxId ? 'tax-moderation-table__highlighted-row' : ''}`}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.id}
                loading={isLoading}
                onPaginationModelChange={handlePaginationModelChange}
                onRowClick={(parameters) => handleRowClick(parameters)}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalCount}
                rows={rows}
                sortingMode="server"
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                disableRowSelectionOnClick
                pagination
              />
            </Box>
          </motion.div>
        )}
      </div>
      <Menu
        id="solution-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={solutionMenuAnchorElement}
        onClose={handleSolutionMenuClose}
        open={isSolutionMenuOpen}
        PaperProps={{ sx: { width: solutionMenuAnchorElement?.offsetWidth ?? 100 } }}
      >
        {checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_APPROVE]) && (
          <MenuItem onClick={() => handleMenuItemClick(TaxModerationSolution.APPROVED)}>
            <ListItemText>Approve</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick(TaxModerationSolution.MODIFIED)}>
          <ListItemText>Modify</ListItemText>
        </MenuItem>
      </Menu>
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterSidebarClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
            fetchTaxModerationGrid(0, paginationModel.pageSize, listType).then(tableScrollTop)
          }}
          onClose={onFilterSidebarClose}
          type="tax-moderation"
          hasSorting
        />
      </Drawer>
      <ApprovalConfirmModal
        onCardApproval={() => handleCardApproval()}
        onClose={() => setIsOpenSalesTaxApprovalModal(false)}
        open={isOpenSalesTaxApprovalModal}
      />
      <ChangeTaxCodeModal
        currentSelectedSalesTax={currentSelectedSalesTax}
        onClose={(isSucceed) => handleCloseTaxCodeModal(isSucceed)}
        open={isOpenChangeTaxCodeModal}
        shouldKeepTaxCode
        withApproveOption
      />
    </div>
  )
}

export default TaxModeration
