import { useEffect } from 'react'

import find from 'lodash/find'
import startCase from 'lodash/startCase'

import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchConsumptionsDictionaries, selectConsumptionDictionaries } from '@admin/store/slices/ConsumptionRequests/consumptionRequestsSlice'

import type { TAny } from '@yzzy/types'

export const CustomDetailPanelContent = (row: TAny) => {
  const { row: requestData } = row
  const dispatch = useAppDispatch()
  const consumptionDictionaries = useAppSelector(selectConsumptionDictionaries)

  useEffect(() => {
    dispatch(fetchConsumptionsDictionaries())
  }, [dispatch])

  return (
    <Stack sx={{ boxSizing: 'border-box', height: '100%' }}>
      <Typography p="6px 16px" variant="subtitle2">
        Entity parameters
      </Typography>

      <Stack alignItems="start" direction="row">
        <Stack width={1} alignItems="start" direction="row" justifyContent="space-between" p="8px 12px">
          <ListItem sx={{ px: 0.5, py: 0 }}>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Platform
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="body2">
                  {startCase(requestData?.consumptionRequestType?.toLowerCase()) || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
          <ListItem sx={{ px: 0.5, py: 0 }}>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Sample content provided
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="body2">
                  Yes
                </Typography>
              }
              disableTypography
            />
          </ListItem>
        </Stack>

        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Account tenure
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.accountTenure, `${requestData.data?.accountTenure}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Consumption status
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.consumptionStatus, `${requestData.data?.consumptionStatus}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Delivery status
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.deliveryStatus, `${requestData.data?.deliveryStatus}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      </Stack>
      <Stack alignItems="start" direction="row">
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                lifetime dollars purchased
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.lifetimeDollarsPurchased, `${requestData.data?.lifetimeDollarsPurchased}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                lifetime dollars refunded
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.lifetimeDollarsRefunded, `${requestData.data?.lifetimeDollarsRefunded}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Play time
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.playTime, `${requestData.data?.playTime}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                User status
              </Typography>
            }
            secondary={
              <Typography color="text.primary" variant="body2">
                {find(consumptionDictionaries?.userStatus, `${requestData.data?.userStatus}`) || '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>
      </Stack>
    </Stack>
  )
}
