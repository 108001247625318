import { GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import Typography from '@admin/components/shared/Typography/Typography'
import useRemainingTimeColor from '@admin/hooks/useRemainingTimeColor'

dayjs.extend(duration)

/**
 * Props for the RenderTimeLeftCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderTimeLeftCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a "Time left" cell in a data grid.
 */
export const RenderTimeLeftCell = ({ props }: IRenderTimeLeftCellProps) => {
  const { row } = props

  const { color, remainingTime } = useRemainingTimeColor(row.createdAt)

  if (!row.consumptionRequestId) {
    return null
  }

  return (
    <Typography color={color} variant="body2">
      {remainingTime ? remainingTime.formatted : '—'}
    </Typography>
  )
}
