import { Fragment } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import Box from '@admin/components/shared/Box/Box'
import Divider from '@admin/components/shared/Divider/Divider'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'

/**
 * Props for the RenderPassionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderPassionsCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a list of passions in a data grid cell.
 */
export const RenderPassionsCell = ({ props }: IRenderPassionsCellProps) => {
  const { row } = props

  if (!row.passions?.flat()?.length) {
    return <span>—</span>
  }

  const renderPassion = (
    passionGroup: {
      id: string
      title: string
    }[],
    index: number,
  ) => (
    <Box key={index} width={1} sx={{ whiteSpace: 'pre-line' }}>
      {passionGroup.map((passion, subIndex) => (
        <Fragment key={subIndex}>
          {passion.title}
          {subIndex < passionGroup?.length - 1 && (
            <Typography color={colors.outlinedBorder} display="inline" variant="body2">
              {'\n + '}
            </Typography>
          )}
        </Fragment>
      ))}

      {row.passions?.length > 1 && index < row.passions?.length - 1 && (
        <Divider key={`divider-${index}`} orientation="horizontal" flexItem>
          <Typography key={`tooltip-${index}`} color={colors.outlinedBorder} variant="tooltip">
            OR
          </Typography>
        </Divider>
      )}
    </Box>
  )

  return (
    <Box width={1} alignItems="flex-start" display="flex" flexDirection="column">
      {row.passions.map(renderPassion)}
    </Box>
  )
}
