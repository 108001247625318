const EyeIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 7.56995C9.55056 7.56995 7.57 9.5505 7.57 11.9999C7.57 14.4494 9.55056 16.4299 12 16.4299C14.4494 16.4299 16.43 14.4494 16.43 11.9999C16.43 9.5505 14.4494 7.56995 12 7.56995ZM9.27 11.9999C9.27 10.4894 10.4894 9.26995 12 9.26995C13.5106 9.26995 14.73 10.4894 14.73 11.9999C14.73 13.5105 13.5106 14.7299 12 14.7299C10.4894 14.7299 9.27 13.5105 9.27 11.9999Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 2.87C8.10962 2.87 4.57752 5.16374 2.17315 8.94322C1.61526 9.81755 1.36499 10.9321 1.36499 11.995C1.36499 13.0577 1.61516 14.172 2.1728 15.0462C4.57718 18.826 8.10942 21.12 12 21.12C15.8904 21.12 19.4225 18.8262 21.8268 15.0468C22.3847 14.1724 22.635 13.0579 22.635 11.995C22.635 10.9323 22.3848 9.81803 21.8272 8.94378L21.8265 8.94266L21.11 9.39999L21.8272 8.94378C19.4228 5.16397 15.8906 2.87 12 2.87ZM2.1728 15.0462L2.88999 14.59L2.17351 15.0473L2.1728 15.0462ZM3.60718 9.85621C5.7828 6.43602 8.83056 4.57 12 4.57C15.1694 4.57 18.2172 6.43602 20.3928 9.85621L20.3935 9.85732C20.7354 10.3929 20.935 11.1681 20.935 11.995C20.935 12.8219 20.7354 13.5971 20.3935 14.1327L20.3928 14.1338C18.2172 17.554 15.1694 19.42 12 19.42C8.83056 19.42 5.7828 17.554 3.60719 14.1338L3.60647 14.1327C3.26461 13.5971 3.06499 12.8219 3.06499 11.995C3.06499 11.1681 3.26461 10.3929 3.60647 9.85732L3.60718 9.85621Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default EyeIcon
