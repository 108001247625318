import { GridRenderCellParams } from '@mui/x-data-grid'

import Typography from '@admin/components/shared/Typography/Typography'
import { IOperation, TransactionTypes } from '@admin/store/slices/Payments/interface'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Props for the RenderSalesTaxAmountCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderSalesTaxAmountCellProps {
  props: GridRenderCellParams
  rowData: IOperation
}

/**
 * Renders a "Sales tax amount" cell in a data grid (Transactions table).
 */
export const RenderSalesTaxAmountCell = ({ props, rowData }: IRenderSalesTaxAmountCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const isCardPaymentTransaction = row.type === TransactionTypes.CARD_PAYMENTS

  return (
    <Typography variant="body2">
      {isCardPaymentTransaction ? (Number(rowData.salesTaxAmount) ? currencyFormatter(rowData.salesTaxAmount, 'en-US', 'USD', true) : '—') : '—'}
    </Typography>
  )
}
