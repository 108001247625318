import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { PAYMENTS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { IFeeCondition, IPaymentFee, IPaymentFeesState } from './interface'

const initialState: IPaymentFeesState = {
  addingFeeStatus: 'idle',
  fees: [],
  feesConditions: [],
  feesCount: 0,
  status: 'idle',
}

export const fetchAddPaymentFee = createAsyncThunk(
  'payments/addPaymentFee',
  async ({
    providerId,
    paymentFeeFixed,
    paymentFeePercentage,
    paymentMethodId,
    transactionType,
  }: Pick<IPaymentFee, 'paymentFeeFixed' | 'paymentFeePercentage' | 'paymentMethodId' | 'providerId' | 'transactionType'>) => {
    const response = await axios.post(PAYMENTS_API + 'payment-adapter/fees', {
      providerId,
      paymentFeeFixed,
      paymentFeePercentage,
      paymentMethodId,
      transactionType,
    })

    return response.data
  },
)

export const fetchPaymentFeeList = createAsyncThunk('payments/feeList', async () => {
  const response = await axios.get(PAYMENTS_API + 'payment-adapter/fees')

  return response.data
})

export const fetchPaymentFees = createAsyncThunk(
  'payments/fees',
  async ({ page, pageSize }: { page: number; pageSize: number }, { getState, rejectWithValue }) => {
    try {
      const rootState: TRootState = getState() as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []

      const response = await axios.post(PAYMENTS_API + 'payment-adapter/fees/find', {
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchFeesConditions = createAsyncThunk('payments/fees/conditions', async () => {
  try {
    const response = await axios.get(PAYMENTS_API + 'payment-adapter/fees/conditions')

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const paymentFeesSlice = createSlice({
  name: 'paymentFees',
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentFees.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPaymentFees.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.fees = payload.content
        state.feesCount = payload.totalElements
      })
      .addCase(fetchPaymentFees.rejected, (state) => {
        state.status = 'failed'
        state.fees = []
        state.feesCount = 0
      })
      .addCase(fetchAddPaymentFee.pending, (state) => {
        state.addingFeeStatus = 'loading'
      })
      .addCase(fetchAddPaymentFee.fulfilled, (state) => {
        state.addingFeeStatus = 'idle'
      })
      .addCase(fetchAddPaymentFee.rejected, (state) => {
        state.addingFeeStatus = 'failed'
      })
      .addCase(fetchFeesConditions.fulfilled, (state, { payload }) => {
        state.feesConditions = payload
      })
  },
  initialState,
  reducers: {
    setPaymentFeesStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectPaymentFees = (state: TRootState): IPaymentFee[] => state.paymentFees.fees
export const selectFeesConditions = (state: TRootState): IFeeCondition[] => state.paymentFees.feesConditions
export const selectTotalCount = (state: TRootState): number => state.paymentFees.feesCount
export const selectPaymentFeesStatus = (state: TRootState): TStatus => state.paymentFees.status
export const selectAddingFeeStatus = (state: TRootState): TStatus => state.paymentFees.addingFeeStatus

export const { setPaymentFeesStatus } = paymentFeesSlice.actions

export default paymentFeesSlice.reducer
