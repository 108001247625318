import { SVGProps } from 'react'
export const TimeQuarterToIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2a4 4 0 0 0-.523.034.5.5 0 0 1-.13-.992A5 5 0 0 1 6 1a.5.5 0 0 1 0 1m-2.343.125a.5.5 0 0 1-.092.701 4 4 0 0 0-.739.74.5.5 0 1 1-.793-.61c.266-.346.577-.657.923-.923a.5.5 0 0 1 .7.092M1.603 4.916a.5.5 0 0 1 .43.56A4 4 0 0 0 2 6a.5.5 0 0 1-1 0q0-.331.042-.652a.5.5 0 0 1 .56-.432"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1.5 6A4.5 4.5 0 1 0 6 1.5" />
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6 4v2.5H3.5" />
  </svg>
)
