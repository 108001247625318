import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'

interface ILogoutWarningModalProps {
  onClose: () => void
  onLogout: () => void
  open: boolean
}

export const LogoutWarningModal = ({ onClose, onLogout, open }: ILogoutWarningModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Unable to open the page due to active session in this browser</DialogTitle>
      <DialogContent>
        <Alert severity="warning">You need to change your browser or terminate current session</Alert>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onLogout} variant="text">
          Log out
        </Button>
        <Button color="primary" onClick={onClose} variant="contained">
          Stay logged
        </Button>
      </DialogActions>
    </Dialog>
  )
}
