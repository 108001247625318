import { Typography } from '@mui/material'

import { BADGE_TYPES_CONTENT } from './constants'
import { SBadge } from './styled'
import { IBadgeProps } from './types'

export const Badge = ({ customIcon, customText, disabled = false, type = 'OFFLINE', withoutIcon = false, ...props }: IBadgeProps) => {
  if (type === 'TEXT' && !customText) {
    throw new Error('customText props is required from type="Text"')
  }

  return (
    <SBadge $disabled={disabled} $type={type} {...props}>
      {!withoutIcon ? customIcon || BADGE_TYPES_CONTENT[type].icon : null}
      <Typography color="inherit" variant="badgeStatuses">
        {customText || BADGE_TYPES_CONTENT[type].text}
      </Typography>
    </SBadge>
  )
}
