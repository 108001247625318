import { TariffGroupsStatuses } from '@admin/store/slices/Settings/interface'
import colors from '@admin/theme/constants/colors'

export const possibleTariffGroupsStatuses = [
  {
    title: 'Active',
    color: colors.success,
    status: TariffGroupsStatuses.ACTIVE,
  },
  {
    title: 'Inactive',
    color: colors.error,
    status: TariffGroupsStatuses.INACTIVE,
  },
]
