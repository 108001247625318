import type { TAny } from '@yzzy/types'

const VerifyReviewIcon = (props: TAny) => {
  return (
    <svg width="24" fill="none" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth="1.7" d="M2.5 12.4385C2.5 6.9496 6.9496 2.5 12.4385 2.5" stroke="#4CA478" strokeDasharray="1 4" strokeLinecap="round" />
      <path
        strokeWidth="1.7"
        d="M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5"
        stroke="#4CA478"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path strokeWidth="1.7" d="M12.9856 8.02078V13.5422H7.46973" stroke="#4CA478" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default VerifyReviewIcon
