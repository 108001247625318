import './index.scss'

import { DialogContentText as DialogContentTextMUI } from '@mui/material'
import { DialogContentTextProps } from '@mui/material/DialogContentText/DialogContentText'

const DialogContentText = (props: DialogContentTextProps) => {
  return <DialogContentTextMUI {...props} />
}

export default DialogContentText
