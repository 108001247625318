import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { useHover } from '@uidotdev/usehooks'
import dayjs from 'dayjs'
import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderTransactionIDCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderTransactionIDCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a "Transaction ID" cell in a data grid.
 */
export const RenderTransactionIDCell = ({ props }: IRenderTransactionIDCellProps) => {
  const { row } = props

  const [reference, hovering] = useHover<HTMLDivElement>()

  const formattedDate = dayjs(row.createDate)

  const copyId = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation()

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', { variant: 'success' as VariantType })

      return navigator.clipboard.writeText(value)
    }
  }

  if (!row.id) {
    return null
  }

  return (
    <div className="MuiDataGrid-cellContent" ref={reference}>
      <Stack>
        <Stack alignItems="center" direction="row" gap="10px" sx={{ minHeight: 28 }}>
          <Tooltip title={row.psTransactionId} placement="top">
            <div style={{ display: 'inline-grid' }}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                component="span"
                variant="body2"
              >
                {row.psTransactionId || '—'}
              </Typography>
            </div>
          </Tooltip>

          {hovering && row.psTransactionId && (
            <Tooltip title="Copy" placement="top">
              <IconButton color="default" onClick={(event) => copyId(event, row.psTransactionId)} size="small">
                <CopyIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Stack alignItems="center" direction="row" gap={1}>
          <Typography color="text.secondary" variant="caption">
            {formattedDate.format('MM/DD/YYYY h:mm A') || '—'}
          </Typography>
        </Stack>
      </Stack>
    </div>
  )
}
