import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { WORLD_MANAGEMENT_API } from '@admin/shared/api/constants'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { TRootState } from '@admin/store/store'
import { ILocation, IMedia, TStatus } from '@admin/types/commonTypes'

export interface ICollection {
  cohortId: string
  cohortTitle: string
  creatorLastName: string
  creatorName: string
  expiredDateTime: Date | null
  id: string
  publishDateTime: Date | null
  status: TCollectionStatus
  title: string
  updatedAt: Date
}

export interface ICollectionDetails extends ICollection {
  banners: TOrderedBanner[]
  events: IOrderedEvent[]
  heroes: IOrderedHero[]
  offers: IOrderedOffer[]
  wishes: IOrderedWish[]
}

export interface IOrderedEvent extends IOrderedItem {
  cover: IMedia
  eventCreator: {
    avatar: IMedia
    firstName: string
    lastName: string
    profileId: string
  }
  format: 'OFFLINE' | 'ONLINE'
  location: ILocation
  price: string
  priceOption: TPriceOption
  startedAt: Date
  title: string
}
export interface IOrderedHero extends IOrderedItem {
  avatar: IMedia
  firstName: string
  lastName: string
  occupation: string
}

export interface IOrderedItem {
  compilationEntityId: string
  createdAt: Date
  entityId: string
  entityStatus: TEntityStatus
  order: number
}

export interface IOrderedOffer extends IOrderedItem {
  cover: IMedia
  format: 'OFFLINE' | 'ONLINE'
  offerCreator: {
    avatar: IMedia
    firstName: string
    lastName: string
    profileId: string
  }
  price: string
  priceOption: TPriceOption
  title: string
}

export interface IOrderedWish extends IOrderedItem {
  cover: IMedia
  format: 'OFFLINE' | 'ONLINE'
  price: string
  priceOption: TPriceOption
  title: string
  wishCreator: {
    avatar: IMedia
    firstName: string
    lastName: string
    profileId: string
  }
}

export type TCollectionStatus = 'ARCHIEVED' | 'DEACTIVATED' | 'INCOMPLETE' | 'LIVE' | 'READY' | 'SCHEDULED'

export type TEntityStatus = 'ACTIVE' | 'SPARE' | 'UNAVAILABLE'

export type TOrderedBanner = IOrderedItem & Omit<IBanner, 'id'>

export type TOrderedEntity = IOrderedEvent | IOrderedHero | IOrderedOffer | IOrderedWish | TOrderedBanner

export type TPriceOption = 'FIXED' | 'FLEXIBLE' | 'FREE'

interface ICollectionDetailsState {
  collection: ICollectionDetails | null
  status: TStatus
}

const initialState: ICollectionDetailsState = {
  collection: null,
  status: 'idle',
}

export const fetchCollection = createAsyncThunk('collectionDetails/collectionDetails', async (collectionId: string) => {
  const response = await axios.get(WORLD_MANAGEMENT_API + `timworld/compilations/${collectionId}`, {
    params: {
      additionalInfo: 'BANNER,HERO,OFFER,WISH,EVENT',
    },
  })

  return { ...response.data }
})

export const fetchEditCollection = createAsyncThunk(
  'collectionDetails/editCollection',
  async ({ id, title, banners, cohortId, events, heroes, offers, wishes, ...rest }: ICollectionDetails) => {
    await axios.patch(WORLD_MANAGEMENT_API + `timworld/compilations/${id}`, {
      title,
      banners: banners.map((banner: TOrderedBanner) => {
        return {
          id: banner.compilationEntityId,
          entityStatus: banner.entityStatus,
          order: banner.order,
        }
      }),
      cohortId,
      events: events.map((event: IOrderedEvent) => {
        return {
          id: event.compilationEntityId,
          entityStatus: event.entityStatus,
          order: event.order,
        }
      }),
      heroes: heroes.map((hero: IOrderedHero) => {
        return {
          id: hero.compilationEntityId,
          entityStatus: hero.entityStatus,
          order: hero.order,
        }
      }),
      offers: offers.map((offer: IOrderedOffer) => {
        return {
          id: offer.compilationEntityId,
          entityStatus: offer.entityStatus,
          order: offer.order,
        }
      }),
      wishes: wishes.map((wish: IOrderedWish) => {
        return {
          id: wish.compilationEntityId,
          entityStatus: wish.entityStatus,
          order: wish.order,
        }
      }),
    })

    return { id, title, banners, cohortId, events, heroes, offers, wishes, ...rest }
  },
)

export const collectionDetailsSlice = createSlice({
  name: 'collectionDetails',
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollection.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.collection = payload
      })
      .addCase(fetchCollection.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchEditCollection.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.collection = payload
      })
  },
  initialState,
  reducers: {},
})

export const selectCollectionDetails = (state: TRootState): ICollectionDetails | null => state.collectionDetails.collection
export const selectCollectionStatus = (state: TRootState): TStatus => state.collectionDetails.status

export default collectionDetailsSlice.reducer
