import './index.scss'

import { Dialog as DialogMUI } from '@mui/material'
import { DialogProps } from '@mui/material/Dialog/Dialog'

const Dialog = (props: DialogProps) => {
  return <DialogMUI {...props} className="dia" />
}

export default Dialog
