import './index.scss'

import { DialogActions as DialogActionsMUI } from '@mui/material'
import { DialogActionsProps } from '@mui/material/DialogActions/DialogActions'

const DialogActions = (props: DialogActionsProps) => {
  return <DialogActionsMUI {...props} />
}

export default DialogActions
