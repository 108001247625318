const CheckIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M20.578 5.37685C20.9222 5.6961 20.9424 6.23391 20.6232 6.57808L9.49213 18.5781C9.32925 18.7537 9.09985 18.8524 8.86036 18.85C8.62087 18.8476 8.39351 18.7442 8.23421 18.5654L3.36523 13.0987C3.05301 12.7481 3.08408 12.2109 3.43463 11.8986C3.78519 11.5864 4.32248 11.6175 4.63471 11.968L8.88172 16.7364L19.3768 5.42197C19.696 5.0778 20.2339 5.0576 20.578 5.37685Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CheckIcon
