export const getEntityDescription = (entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH') => {
  switch (entityType) {
    case 'BANNER': {
      return {
        title: 'Banner',
        alertEntityName: 'Banner',
        entityCountProp: 'totalBanners',
        minAllowedNumberOfEntities: 5,
        pluralEntityName: 'banners',
        singularEntityName: 'banner',
      }
    }
    case 'EVENT': {
      return {
        title: 'Event',
        alertEntityName: 'entity',
        entityCountProp: 'totalEvents',
        minAllowedNumberOfEntities: 15,
        pluralEntityName: 'events',
        singularEntityName: 'event',
      }
    }
    case 'HERO': {
      return {
        title: 'User',
        alertEntityName: 'profile',
        entityCountProp: 'totalHeroes',
        minAllowedNumberOfEntities: 15,
        pluralEntityName: 'profiles',
        singularEntityName: 'profile',
      }
    }
    case 'OFFER': {
      return {
        title: 'Offer',
        alertEntityName: 'entity',
        entityCountProp: 'totalOffers',
        minAllowedNumberOfEntities: 15,
        pluralEntityName: 'offers',
        singularEntityName: 'offer',
      }
    }
    case 'WISH': {
      return {
        title: 'Wish',
        alertEntityName: 'entity',
        entityCountProp: 'totalWishes',
        minAllowedNumberOfEntities: 15,
        pluralEntityName: 'wishes',
        singularEntityName: 'wish',
      }
    }
  }
}
