import { IPermission } from '@admin/types/commonTypes'

/**
 * Checks if the user has access to the specified permissions.
 * @param {IPermission[]} userPermissions - Array of permissions possessed by the user.
 * @param {string | string[]} allowedPermissions - Permission(s) to check against.
 * @returns {boolean} Returns true if the user has access to any of the allowed permissions, otherwise false.
 * @example
 * const userPermissions = useAppSelector(selectUserPermissions);
 * const allowedPermissions = [Permissions.PAYMENTS_VIEW_OPERATIONS]';
 * const hasAccess = checkPermissions(userPermissions, allowedPermissions);
 *
 * {hasAccess && <Button>Add role</Button>}
 */
export const checkPermissions = (userPermissions: IPermission[], allowedPermissions: string | string[]): boolean => {
  if (!userPermissions || userPermissions.length === 0) {
    return false
  }

  if (allowedPermissions.length === 0) {
    return true
  }

  if (!Array.isArray(userPermissions)) {
    return false
  }

  if (!Array.isArray(allowedPermissions)) {
    return false
  }

  return userPermissions.some((permission) => allowedPermissions.includes(permission.name))
}
