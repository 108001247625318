import './index.scss'

import { AvatarProps } from '@mui/material/Avatar/Avatar'

import Avatar from '@admin/components/shared/Avatar/Avatar'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import colors from '@admin/theme/constants/colors'

interface IAvatarItemProps extends AvatarProps {
  avatarHeight?: number
  avatarWidth?: number
  label?: string
  levelAura?: string
  primary?: boolean
  primaryText?: string
  sizes?: string
  subText?: string
  textColor?: string
}

const AvatarItem = (props: IAvatarItemProps) => {
  const avatarSize = {
    width: props.avatarWidth,
    height: props.avatarHeight,
  }

  const sizesClassName = props.sizes ? ` ${props.sizes}` : ''
  const onClickClassName = props.onClick ? ' avatar-item-container--clickable' : ''

  const textColorClassName = props.textColor ? ` ${props.textColor}` : ''

  return (
    <div className={`avatar-item-container${sizesClassName}${onClickClassName}`} onClick={props.onClick}>
      <div className="avatar-wrapper" data-level={props.levelAura}>
        <Avatar
          sx={
            props.levelAura
              ? {
                  ...avatarSize,
                  border: `2px solid ${colors.white}`,
                }
              : avatarSize
          }
          alt={props.primaryText}
          src={props.src}
          variant={props.sizes === 'small' ? 'rounded' : undefined}
        />
      </div>
      <div className="avatar-item-text">
        <Tooltip title={props.primaryText} placement="top-end" followCursor>
          <div className={`avatar-item-primary${textColorClassName}`}>
            <div className="avatar-item-primary-description">{props.primaryText || ' '}</div>
          </div>
        </Tooltip>
        {props?.subText && (
          <div className={`avatar-item-subtext${textColorClassName}`}>
            <Tooltip title={props?.subText && props?.subText?.length > 20 && props?.subText} placement="top-end" followCursor>
              <div>{props?.subText}</div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default AvatarItem
