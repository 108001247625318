import { ConsumptionRequestStatus } from '@admin/store/slices/ConsumptionRequests/interface'
import { ISortingItem } from '@admin/types/commonTypes'

export const consumptionRequestOpenSortingModel: ISortingItem = {
  title: 'Create date',
  columnId: 'created_at',
  sortingType: 'ASC',
}

export const consumptionRequestClosedSortingModel: ISortingItem = {
  title: 'Date Of Close',
  columnId: 'closing_date',
  sortingType: 'DESC',
}

export const enum ConsumptionRequestListType {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export const OPEN_FILTER = {
  columnId: 'consumption_request_status',
  customImplementation: false,
  element: {
    value: ConsumptionRequestStatus.OPEN,
  },
  fromArray: false,
  getValuesImmediately: false,
  required: false,
  type: 'EQUALS',
  valueType: 'STRING',
}

export const CLOSED_FILTER = {
  columnId: 'consumption_request_status',
  customImplementation: false,
  element: {
    value: ConsumptionRequestStatus.CLOSED,
  },
  fromArray: false,
  getValuesImmediately: false,
  required: false,
  type: 'EQUALS',
  valueType: 'STRING',
}
