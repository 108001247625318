import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { UsersTabs } from '@admin/components/Users/types/UsersTabs'
import { USERS_API } from '@admin/shared/api/constants'
import { IFilterItem, TStatus } from '@admin/types/commonTypes'
import { IActiveUser, IBannedUser, TDeletedUser } from '@admin/types/User'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface IUsersState {
  activeUsers: IActiveUser[]
  actualUsersStatus: TStatus
  bannedUsers: IBannedUser[]
  bannedUsersStatus: TStatus
  columns: TAny[]
  deletedUsers: TDeletedUser[]
  deletedUserStatus: TStatus
  filters: IFilterItem[]
  refreshStatus: TStatus
  status: TStatus
  totalActiveCount: number | undefined
  totalBannedCount: number | undefined
  totalDeletedCount: number | undefined
  value: number
}

const initialState: IUsersState = {
  activeUsers: [],
  actualUsersStatus: 'idle',
  bannedUsers: [],
  bannedUsersStatus: 'idle',
  columns: [],
  deletedUsers: [],
  deletedUserStatus: 'idle',
  filters: [],
  refreshStatus: 'idle',
  status: 'idle',
  totalActiveCount: 0,
  totalBannedCount: 0,
  totalDeletedCount: 0,
  value: 0,
}

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ page, pageSize, type }: { page: number; pageSize: number; type?: string }, thunkAPI) => {
    const rootState: TRootState = (await thunkAPI.getState()) as TRootState
    const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
    const sortingList = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
      : []
    const columns =
      type !== 'actual' && Object.keys(rootState.columns.data) ? Object.values(rootState.columns.data).filter((item: TAny) => item !== null) : []

    try {
      const typeString = type ? `/${type}` : ''
      const response = await axios.post(USERS_API + `timusers/users${typeString}`, {
        columns: columns,
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchChangeStatus = createAsyncThunk('users/changeStatus', async (data: TAny) => {
  return data
})

export const usersSlice = createSlice({
  name: 'users',
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading'
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.actualUsersStatus = 'loading'
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.bannedUsersStatus = 'loading'
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.deletedUserStatus = 'loading'
        }
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.refreshStatus = 'idle'
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.activeUsers = action.payload.users
          state.totalActiveCount = action.payload.allResultsCount
          state.actualUsersStatus = 'idle'
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.bannedUsers = action.payload.users
          state.totalBannedCount = action.payload.allResultsCount
          state.bannedUsersStatus = 'idle'
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.deletedUsers = action.payload.users
          state.totalDeletedCount = action.payload.allResultsCount
          state.deletedUserStatus = 'idle'
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        if (action.meta.arg.type === UsersTabs.ACTIVE) {
          state.totalActiveCount = 0
          state.activeUsers = []
          state.actualUsersStatus = 'failed'
        }
        if (action.meta.arg.type === UsersTabs.BANNED) {
          state.totalBannedCount = 0
          state.bannedUsers = []
          state.bannedUsersStatus = 'failed'
        }
        if (action.meta.arg.type === UsersTabs.DELETED) {
          state.totalDeletedCount = 0
          state.deletedUsers = []
          state.deletedUserStatus = 'failed'
        }
        state.status = 'failed'
        state.refreshStatus = 'idle'
      })
      .addCase(fetchChangeStatus.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchChangeStatus.fulfilled, (state, action) => {
        action.payload.data.forEach((iter: TAny) => {
          const index = state.activeUsers.findIndex((item) => item.baseInfo.userId === iter.baseInfo.userId)

          state.activeUsers[index].status = action.payload.value
        })
        state.status = 'idle'
      })
      .addCase(fetchChangeStatus.rejected, (state) => {
        state.status = 'failed'
      })
  },
  initialState,
  reducers: {
    clearData: (state) => {
      state.activeUsers = []
      state.bannedUsers = []
      state.deletedUsers = []
      state.status = 'loading'
    },
    setActualUsersStatus: (state, action: PayloadAction<TStatus>) => {
      state.actualUsersStatus = action.payload
    },
    setBannedUsersStatus: (state, action: PayloadAction<TStatus>) => {
      state.bannedUsersStatus = action.payload
    },
    setDeletedUsersStatus: (state, action: PayloadAction<TStatus>) => {
      state.deletedUserStatus = action.payload
    },
    setUsersRefreshStatus: (state, action: PayloadAction<TStatus>) => {
      state.refreshStatus = action.payload
    },
    setUsersStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectActiveUsers = (state: TRootState): IActiveUser[] => state.users.activeUsers
export const selectBannedUsers = (state: TRootState): IBannedUser[] => state.users.bannedUsers
export const selectDeletedUsers = (state: TRootState): TDeletedUser[] => state.users.deletedUsers
export const selectUsersStatus = (state: TRootState): TStatus => state.users.status
export const selectActualUsersStatus = (state: TRootState): TStatus => state.users.actualUsersStatus
export const selectBannedUsersStatus = (state: TRootState): TStatus => state.users.bannedUsersStatus
export const selectDeletedUsersStatus = (state: TRootState): TStatus => state.users.deletedUserStatus
export const selectUsersRefreshStatus = (state: TRootState): TStatus => state.users.refreshStatus
export const selectActiveUsersCount = (state: TRootState): number | undefined => state.users.totalActiveCount
export const selectBannedUsersCount = (state: TRootState): number | undefined => state.users.totalBannedCount
export const selectDeletedUsersCount = (state: TRootState): number | undefined => state.users.totalDeletedCount

export const { clearData, setActualUsersStatus, setBannedUsersStatus, setDeletedUsersStatus, setUsersRefreshStatus, setUsersStatus } =
  usersSlice.actions

export default usersSlice.reducer
