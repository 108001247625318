import { createSvgIcon } from '@mui/material'

const CheckCircleIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0672 9.05143C18.3948 8.71514 18.3877 8.17699 18.0514 7.84945C17.7151 7.52191 17.1769 7.52901 16.8494 7.86531L10.5719 14.3106L8.16247 11.7343C7.84182 11.3914 7.30393 11.3734 6.96106 11.694C6.6182 12.0147 6.60018 12.5526 6.92083 12.8954L9.93859 16.1223C10.0973 16.292 10.3185 16.3893 10.5508 16.3917C10.7831 16.394 11.0062 16.3012 11.1683 16.1348L18.0672 9.05143Z" />
    <path
      clipRule="evenodd"
      d="M12 1.15002C6.03058 1.15002 1.15002 6.03058 1.15002 12C1.15002 17.9695 6.03058 22.85 12 22.85C17.9695 22.85 22.85 17.9695 22.85 12C22.85 6.03058 17.9695 1.15002 12 1.15002ZM2.85002 12C2.85002 6.96947 6.96947 2.85002 12 2.85002C17.0306 2.85002 21.15 6.96947 21.15 12C21.15 17.0306 17.0306 21.15 12 21.15C6.96947 21.15 2.85002 17.0306 2.85002 12Z"
      fillRule="evenodd"
    />
  </svg>,
  'CheckCircle',
)

export default CheckCircleIcon
