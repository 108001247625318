import {
  IOperationStatus,
  IOperationType,
  IPaymentMethod,
  ITransactionType,
  OperationStatuses,
  OperationTypes,
  PaymentMethods,
  TransactionTypes,
} from '@admin/store/slices/Payments/interface'
import colors from '@admin/theme/constants/colors'
import { ISortingItem } from '@admin/types/commonTypes'

export const defaultOperationsSortingModel: ISortingItem = {
  title: 'Creation date',
  columnId: 'create_date',
  sortingType: 'DESC',
}

export const possibleOperationStatuses: IOperationStatus[] = [
  {
    title: 'Success',
    color: colors.success,
    status: OperationStatuses.SUCCESS,
  },
  {
    title: 'Start',
    color: colors.textSecondary,
    status: OperationStatuses.START,
  },
  {
    title: 'Completed',
    color: colors.success,
    status: OperationStatuses.COMPLETED,
  },
  {
    title: 'Requested',
    color: colors.textSecondary,
    status: OperationStatuses.REQUESTED,
  },
  {
    title: 'Refunded (Refunded)',
    color: colors.success,
    status: OperationStatuses.REFUNDED_REFUNDED,
  },
  {
    title: 'Refunded',
    color: colors.success,
    status: OperationStatuses.REFUNDED,
  },
  {
    title: 'Hold',
    color: colors.primary,
    status: OperationStatuses.HOLD,
  },
  {
    title: 'Frozen',
    color: colors.primary,
    status: OperationStatuses.FROZEN,
  },
  {
    title: 'Refunded (Hold charge)',
    color: colors.primary,
    status: OperationStatuses.REFUNDED_HOLD_CHARGE,
  },
  {
    title: 'Partially refunded',
    color: colors.primary,
    status: OperationStatuses.PARTIALLY_REFUNDED,
  },
  {
    title: 'Hold charge',
    color: colors.primary,
    status: OperationStatuses.HOLD_CHARGE,
  },
  {
    title: 'Pending',
    color: colors.textSecondary,
    status: OperationStatuses.PENDING,
  },
  {
    title: 'In transit',
    color: colors.textSecondary,
    status: OperationStatuses.IN_TRANSIT,
  },
  {
    title: 'Refunded (Pending)',
    color: colors.textSecondary,
    status: OperationStatuses.REFUNDED_PENDING,
  },
  {
    title: 'Refunded (Pending charge)',
    color: colors.textSecondary,
    status: OperationStatuses.REFUNDED_PENDING_CHARGE,
  },
  {
    title: 'Pending refund',
    color: colors.textSecondary,
    status: OperationStatuses.PENDING_REFUND,
  },
  {
    title: 'Canceled',
    color: colors.error,
    status: OperationStatuses.CANCELED,
  },
  {
    title: 'Refunded (Charged)',
    color: colors.error,
    status: OperationStatuses.REFUNDED_CHARGED,
  },
  {
    title: 'Refunded (Frozen)',
    color: colors.error,
    status: OperationStatuses.REFUNDED_FROZEN,
  },
  {
    title: 'Refunded (Canceled)',
    color: colors.error,
    status: OperationStatuses.REFUNDED_CANCELED,
  },
  {
    title: 'Charged',
    color: colors.error,
    status: OperationStatuses.CHARGED,
  },
  {
    title: 'Failed',
    color: colors.error,
    status: OperationStatuses.FAILED,
  },
]

export const possibleOperationTypes: IOperationType[] = [
  {
    subtitle: 'Connect (Follow)',
    title: 'In-App Purchase',
    operationType: OperationTypes.IAP_FOLLOW,
    tierTitle: 'Only iap_follow',
  },
  {
    subtitle: 'Donation',
    title: 'Purchase',
    operationType: OperationTypes.DONATE,
  },
  {
    subtitle: 'Donation',
    title: 'In-App Purchase',
    operationType: OperationTypes.IAP_DONATE,
  },
  {
    subtitle: 'Donation',
    title: 'In-App Purchase',
    operationType: OperationTypes.IAP_DONATION,
    tierTitle: 'Only iap_donation',
  },
  {
    subtitle: 'Goal (Raising money)',
    title: 'Purchase',
    operationType: OperationTypes.RM_GOAL,
  },
  {
    subtitle: 'Paid Lifestyle',
    title: 'In-App Purchase',
    operationType: OperationTypes.IAP_LIFESTYLE,
    tierTitle: 'Only iap_lifestyle',
  },
  {
    subtitle: 'Event',
    title: 'Purchase',
    operationType: OperationTypes.EVENT,
  },
  {
    subtitle: 'Event',
    title: 'In-App Purchase',
    operationType: OperationTypes.IAP_EVENT,
    tierTitle: 'Only iap_event',
  },
  {
    subtitle: 'Offer',
    title: 'Purchase',
    operationType: OperationTypes.OFFER,
  },
  {
    subtitle: 'Wish',
    title: 'Purchase',
    operationType: OperationTypes.WISH,
  },
  {
    subtitle: 'Private Wish',
    title: 'Purchase',
    operationType: OperationTypes.PRIVATE_WISH,
  },
  {
    subtitle: 'Tips Event',
    title: 'Purchase',
    operationType: OperationTypes.TIPS_EVENT,
  },
  {
    subtitle: 'Tips Offer',
    title: 'Purchase',
    operationType: OperationTypes.TIPS_OFFER,
  },
  {
    subtitle: '',
    title: 'Executed boost wish',
    operationType: OperationTypes.BOOST,
  },
  {
    subtitle: 'Standard',
    title: 'Payout',
    operationType: OperationTypes.PAY_OUT,
  },
  {
    subtitle: 'Penalty',
    title: 'Charge',
    operationType: OperationTypes.CHARGE,
  },
]

export const possibleTransactionsTypes: ITransactionType[] = [
  {
    title: 'Apple iap',
    transactionType: TransactionTypes.APPLE_IAP,
  },
  {
    title: 'Reversal transfer',
    transactionType: TransactionTypes.REVERSAL_TRANSFER,
  },
  {
    title: 'Charge',
    transactionType: TransactionTypes.CHARGE,
  },
  {
    title: 'Payout',
    transactionType: TransactionTypes.PAY_OUT,
  },
  {
    title: 'Refund',
    transactionType: TransactionTypes.REFUND,
  },
  {
    title: 'Google iap',
    transactionType: TransactionTypes.GOOGLE_IAP,
  },
  {
    title: 'Card payment',
    transactionType: TransactionTypes.CARD_PAYMENTS,
  },
  {
    title: 'Transfer',
    transactionType: TransactionTypes.TRANSFER,
  },
]

export const possiblePaymentMethods: IPaymentMethod[] = [
  {
    title: 'Apple iap',
    paymentMethod: PaymentMethods.APPLE_IAP,
  },
  {
    title: 'Google iap',
    paymentMethod: PaymentMethods.GOOGLE_IAP,
  },
  {
    title: 'Card',
    paymentMethod: PaymentMethods.CARD,
  },
]
