import './index.scss'

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router'

import { Typography } from '@mui/material'

import Navbar from '@admin/components/Navbar/Navbar'

interface IErrorStatus {
  message: string
  status: number
}

interface IPageData {
  descriptionContactString: string
  descriptionInfo: ReactNode
  descriptionTitle: string
  img: string
  status: number
  subtitle: string
}

export const ErrorBoundaryErrors = [
  'Request failed with status code 400',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 500',
  'Request failed with status code 502',
  'Request failed with status code 503',
  'Request failed with status code 504',
]

export const ErrorPage = ({ error }: { error: IErrorStatus | string }) => {
  const { resetBoundary } = useErrorBoundary()
  const location = useLocation()
  const errorLocation = useRef(location.pathname)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const customStatusCode = error && (error as IErrorStatus)?.status && (error as IErrorStatus).status < 500 ? 400 : 500

  const handleOpen = useCallback((value: boolean) => setIsMenuOpen(value), [])

  let pageData: IPageData = {
    subtitle: 'Bad request',
    descriptionContactString: 'If the problem persists contact your manager',
    descriptionInfo: null,
    descriptionTitle: 'Your client has issued a malformed or illegal request',
    img: '',
    status: customStatusCode,
  }

  switch ((error as IErrorStatus)?.message ? (error as IErrorStatus)?.message : error) {
    case 'Request failed with status code 400': {
      pageData = {
        subtitle: 'Bad request',
        descriptionContactString: 'If the problem persists contact your manager',
        descriptionInfo: <>Try to double-check the address or refresh the page. Also you can choose other module from menu bar.</>,
        descriptionTitle: 'Your client has issued a malformed or illegal request',
        status: 400,
      } as IPageData
      break
    }
    case 'Request failed with status code 403': {
      pageData = {
        subtitle: 'Forbidden',
        descriptionContactString: 'If you think it’s a mistake, contact your manager',
        descriptionInfo: <>Please, choose other module from menu bar.</>,
        descriptionTitle: 'You have insufficient right to access this page',
        status: 403,
      } as IPageData
      break
    }
    case 'Request failed with status code 404': {
      pageData = {
        subtitle: 'Not found',
        descriptionContactString: 'If you think it’s a mistake, contact your manager ',
        descriptionInfo: (
          <>
            The error may be caused by typo in the link or page was deleted. <br />
            Try to double-check the address or choose other module from menu bar.
          </>
        ),
        descriptionTitle: 'Page you have requested does not exist',
        status: 404,
      } as IPageData
      break
    }
    case 'Request failed with status code 500': {
      pageData = {
        subtitle: 'Internal server error',
        descriptionContactString: 'So let your manager know about the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionTitle: 'We are currently trying to fix the issue',
        status: 500,
      } as IPageData
      break
    }
    case 'Request failed with status code 502': {
      pageData = {
        subtitle: 'Bad gateway',
        descriptionContactString: 'So let your manager know about the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionTitle: 'We are currently trying to fix the issue',
        status: 502,
      } as IPageData
      break
    }
    case 'Request failed with status code 503': {
      pageData = {
        subtitle: 'Service is unavailable',
        descriptionContactString: 'So let your manager know about the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionTitle: 'We are currently trying to fix the issue',
        status: 503,
      } as IPageData
      break
    }
    // case 'Request failed with status code 503': { todo ** to be completed when it becomes clear how the technical error will be implemented **
    //   pageData = {
    //     status: 503,
    //     subtitle: 'Server down for maintenance',
    //     descriptionTitle: 'We are going to finish all works at 15 pm (EST) ',
    //   } as PageData;
    //   break;
    // }
    case 'Request failed with status code 504': {
      pageData = {
        subtitle: 'Gateway timeout',
        descriptionContactString: 'So let your manager know about the issue',
        descriptionInfo: <>Perhaps you are the first who has detected this error.</>,
        descriptionTitle: 'We are currently trying to fix the issue',
        status: 504,
      } as IPageData
      break
    }
  }

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetBoundary()
    }
  }, [location.pathname])

  return (
    <div className={`layout-container${isMenuOpen ? ' layout-container--blurred' : ''}`}>
      {pageData && pageData?.status < 500 && <Navbar handleOpen={handleOpen} isMenuOpen={isMenuOpen} resetBoundary={resetBoundary} />}
      <div style={{ width: '64px' }} />
      <div className="ErrorPage">
        <header className="ErrorPage-header">
          <Typography variant="errorTitle">Error {pageData.status}</Typography>
          <Typography color="text.secondary" variant="errorSubtitle">
            {pageData.subtitle}
          </Typography>
        </header>
        <div className="ErrorPage-content">
          <div className={`ErrorPage-content-image ErrorPage-content-image-${pageData.status}`} />
          <div className="ErrorPage-content-description">
            <Typography className="ErrorPage-content-descriptionTitle" variant="h5">
              {pageData.descriptionTitle}
            </Typography>
            <Typography className="ErrorPage-content-descriptionInfo" color="text.secondary" variant="body1">
              {pageData.descriptionInfo}
            </Typography>
            <Typography color="text.secondary" variant="body1">
              {pageData.descriptionContactString}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}
