import './index.scss'

import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { createFilterOptions } from '@mui/material'
import { DateValidationError } from '@mui/x-date-pickers/models'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import some from 'lodash/some'
import toArray from 'lodash/toArray'

import CalendarIcon from '@admin/assets/img/CalendarIcon'
import CloseCircleIcon from '@admin/assets/img/CloseCircleIcon'
import CloseIcon from '@admin/assets/img/CloseIcon'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import Autocomplete, { renderOptions } from '@admin/components/shared/Autocomplete/Autocomplete'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import NumericFormatCustom from '@admin/components/shared/CurrencyFormatCustom/NumericFormatCustom'
import DatePicker from '@admin/components/shared/DatePicker/DatePicker'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Divider from '@admin/components/shared/Divider/Divider'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import Stack from '@admin/components/shared/Stack/Stack'
import Switch from '@admin/components/shared/Switch/Switch'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import {
  clearPossibleOptions,
  deletePreset,
  fetchFilterPresets,
  fetchPossibleOptions,
  saveFilters,
  savePreset,
  selectActivePresetId,
  selectFilters,
  selectFiltersCurrentData,
  selectPossibleOptions,
  selectPresets,
  selectSortedFilters,
  setActiveFilter,
  setActivePreset,
  setFilterValue,
} from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { IDropdownItem, IFilterItem, IPreset } from '@admin/types/commonTypes'
import { INT_LIMIT } from '@admin/yzzy-constants'

import type { TAny } from '@yzzy/types'

import {
  COMPLAINTS_MODERATOR_COLUMN_ID,
  DEFAULT_MODERATOR,
  HIVE_MODERATOR_COLUMN_ID,
  KEEP_OPTIONS_SHOW_IDS,
  MULTIPLE_TITLE_SHOW_IDS,
  PASSIONS_COLUMN_ID,
  PRICE_IDS,
  TOOLTIP_MESSAGES,
} from './constants'

/**
 * Represents the props for a filter drawer component.
 *
 * @interface
 * @property {string} type - The type of the filter drawer.
 * @property {boolean} [isHidePresets] - If true, presets should be hidden.
 * @property {boolean} [hasSorting] - If true, sortedFilters will be returned (sorting based on belongsToKinds).
 * @property {Function} onClose - A function to be called when the filter drawer is closed.
 * @property {Function} onApply - A function to be called when changes are applied in the filter drawer.
 */
interface IFilterDrawerProps {
  hasSorting?: boolean
  isHidePresets?: boolean
  onApply: () => void
  onClose: (apply?: boolean) => void
  type: string
}

dayjs.extend(utc)

const FilterDrawer = ({ isHidePresets = false, hasSorting = false, onApply, onClose, type }: IFilterDrawerProps) => {
  const dispatch = useAppDispatch()

  const data = useAppSelector(selectFiltersCurrentData)
  const possibleOptions = useAppSelector(selectPossibleOptions)
  const filters = useAppSelector(selectFilters)
  const sortedFilters = useAppSelector(selectSortedFilters)
  const presets = useAppSelector(selectPresets)
  const activePresetId = useAppSelector(selectActivePresetId)

  const [previousData] = useState(data)
  const [savePresetModal, setSavePresetModal] = useState<boolean>(false)
  const [presetName, setPresetName] = useState<string>('')
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false)
  const [deletedPreset, setDeletedPreset] = useState<IPreset | null>(null)
  const [errorTo, setErrorTo] = useState<DateValidationError | null | string>(null)
  const [errorFrom, setErrorFrom] = useState<DateValidationError | null | string>(null)
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false)

  const showDeletePresetModal = useCallback((preset: IPreset) => {
    setDeletedPreset(preset)
    setDeletePresetModal(true)
  }, [])
  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false)
  }, [])
  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false)
  }, [])
  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ presetName: presetName.trim(), type: type }))
      handleChangeSavePresetModalClose()
      await dispatch(fetchFilterPresets(type))
    },
    [dispatch, handleChangeSavePresetModalClose, type],
  )
  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ presetId: presetId, type: type }))
      handleChangeDeletePresetModalClose()
      await dispatch(fetchFilterPresets(type))
    },
    [dispatch, handleChangeDeletePresetModalClose, type],
  )
  const setPreset = useCallback(
    (presetId: TAny) => {
      dispatch(setActivePreset({ presetId: presetId, type: type }))
    },
    [dispatch, type],
  )

  const revisedFilters = useMemo(() => (hasSorting ? sortedFilters : filters), [filters, hasSorting, sortedFilters])

  const switchFilter = useCallback(
    async (item: IFilterItem, checked: boolean) => {
      if (checked && item.getValuesImmediately) {
        const additionalValue: string | undefined =
          item.columnId === HIVE_MODERATOR_COLUMN_ID
            ? type === ModerationTabs.CONFIRMED_VIOLATIONS
              ? DEFAULT_MODERATOR
              : type === ModerationTabs.DECLINED_REQUESTS
                ? ' '
                : undefined
            : undefined

        await dispatch(
          fetchPossibleOptions({
            additionalValue,
            field: item.columnId === 'entity_status' && item.dropdownName ? item.dropdownName : item.columnId,
            type: type,
          }),
        )
      }

      dispatch(setActiveFilter({ checked, item }))
    },
    [dispatch, type],
  )

  const setFilterInputValue = useCallback(
    ({ columnId, getValuesImmediately, type, value }: { columnId: string; getValuesImmediately: boolean; type: string; value: null | string }) => {
      const additionalValue = type === ModerationTabs.CONFIRMED_VIOLATIONS && columnId === HIVE_MODERATOR_COLUMN_ID ? DEFAULT_MODERATOR : undefined

      /** For this column, there is a special behavior: search starts with 2 symbols. See: https://clearmindapp.atlassian.net/wiki/x/GgB7Ig */
      const handlePassionsColumn = () => {
        if (value && value.length < 2) {
          dispatch(clearPossibleOptions({ columnId }))
        } else if (value && value.length >= 2 && !getValuesImmediately) {
          dispatch(fetchPossibleOptions({ field: columnId, type, value }))
        }
      }

      /** For these columns, the filter works like a search, but there is a dropdown list with default value. See: https://clearmindapp.atlassian.net/wiki/x/K4DdKw */
      const handleModeratorColumns = () => {
        if (value && value.length < 3) {
          dispatch(clearPossibleOptions({ columnId }))
          dispatch(
            fetchPossibleOptions({
              additionalValue,
              field: columnId,
              type,
            }),
          )
        } else if (value && value.length > 2) {
          dispatch(
            fetchPossibleOptions({
              additionalValue,
              field: columnId,
              type,
              value,
            }),
          )
        }
      }

      const handleDefaultBehavior = () => {
        if (value && value.length < 3 && !getValuesImmediately) {
          dispatch(clearPossibleOptions({ columnId }))
        } else if (!getValuesImmediately && value && value.length > 2) {
          dispatch(fetchPossibleOptions({ field: columnId, type, value }))
        } else if (getValuesImmediately && !possibleOptions[columnId]) {
          dispatch(fetchPossibleOptions({ field: columnId, type }))
        }
      }

      if (columnId === PASSIONS_COLUMN_ID) {
        handlePassionsColumn()
      } else if (columnId === HIVE_MODERATOR_COLUMN_ID || columnId === COMPLAINTS_MODERATOR_COLUMN_ID) {
        handleModeratorColumns()
      } else {
        handleDefaultBehavior()
      }
    },
    [dispatch, possibleOptions],
  )

  const isOptionEqualToValue = (option: TAny, value: TAny) => {
    return option.titles[0] === value.titles[0]
  }

  const close = () => onClose()

  const hadDirty = useCallback(() => {
    const hasChanges = (current: TAny, previous: TAny) => {
      if (!current || !previous) {
        return false
      }

      return !isEqual(current, previous)
    }

    return hasChanges(data, previousData)
  }, [data, previousData])

  const applyFilters = async () => {
    dispatch(saveFilters())

    onApply()
  }

  const validateFilters = useCallback(() => {
    const isDirty = hadDirty()

    if (!isDirty) {
      return true
    }

    return some(toArray(data), (filterItem) => {
      if (isNull(filterItem)) {
        return false
      }

      const hasInvalidDate =
        filterItem.valueType === 'DATE' && (filterItem?.to?.includes('Invalid Date') || filterItem?.from?.includes('Invalid Date'))

      const hasDateError = !!errorTo || !!errorFrom

      if (hasInvalidDate || hasDateError) {
        return true
      }

      if (filterItem.type === 'BETWEEN') {
        if (isEmpty(filterItem?.from) || isEmpty(filterItem?.to)) {
          return true
        }

        if (!isNaN(parseInt(filterItem.from)) && !isNaN(parseInt(filterItem.to))) {
          return parseInt(filterItem.to) < parseInt(filterItem.from)
        }
      }

      if (filterItem?.element) {
        return isEmpty(filterItem.element) || isEmpty(filterItem.element?.value)
      }

      return isEmpty(filterItem?.elements) || some(filterItem?.elements, isEmpty)
    })
  }, [hadDirty, data, errorTo, errorFrom])
  const isValidApplyButton = validateFilters()

  const checkFilterAccessibility = useCallback((entityValue: null | string) => {
    if (entityValue === 'PRIVATE_WISH') {
      return { disabled: true, message: TOOLTIP_MESSAGES.privateWish }
    }

    return { disabled: false, message: '' }
  }, [])

  const getNoOptionsHint = (item: IFilterItem, inputValue: string) => {
    if (item.getValuesImmediately) {
      return 'No options'
    }
    if (inputValue.length < (item.columnId === PASSIONS_COLUMN_ID ? 2 : 3)) {
      return `Enter at least ${item.columnId === PASSIONS_COLUMN_ID ? 2 : 3} symbols`
    }

    return 'No options'
  }

  const filterTypes = {
    BETWEEN: (item: IFilterItem) => {
      const [valueFrom, setValueFrom] = useState<Dayjs | null>(dayjs(data[item.columnId]?.from))
      const [valueTo, setValueTo] = useState<Dayjs | null>(dayjs(data[item.columnId]?.to?.replace('T23:59:59Z', '')))
      const yesterday = dayjs().add(-1, 'day')
      const isErrorTo = parseInt(data[item.columnId]?.from) > parseInt(data[item.columnId]?.to)

      const isPriceFilter = PRICE_IDS.includes(item.columnId.toString())
      const inputProps = isPriceFilter
        ? {
            inputComponent: NumericFormatCustom as TAny,
            inputProps: {
              decimalScale: 2,
              max: INT_LIMIT,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="action.disabled" variant="body1">
                  $
                </Typography>
              </InputAdornment>
            ),
          }
        : {}

      return (
        <div style={{ marginBottom: '16px' }}>
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Switch
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  switchFilter(item, event.target.checked)

                  if (data[item.columnId]?.from !== 'undefined' && data[item.columnId]?.from !== 'Invalid Date') {
                    setValueFrom(dayjs(data[item.columnId]?.from))
                  }
                  if (data[item.columnId]?.to !== 'undefined' && data[item.columnId]?.to !== 'Invalid Date') {
                    setValueTo(dayjs(data[item.columnId]?.to?.replace('T23:59:59Z', '')))
                  }
                }}
                checked={!!data[item.columnId]}
              />
            </div>
          </div>
          {data[item.columnId] && (
            <Stack justifySelf="space-between">
              {data[item.columnId].displayValueType === 'DATE' || data[item.columnId].valueType === 'DATE' ? (
                <>
                  <Stack alignItems="center" direction="row" gap={2}>
                    <DatePicker
                      label="From"
                      onChange={(newValue) => {
                        const formattedDate = dayjs(newValue)

                        setValueFrom(dayjs(newValue).isValid() ? formattedDate : null)
                        dispatch(
                          setFilterValue({
                            columnId: item.columnId,
                            value: formattedDate.format('YYYY-MM-DD'),
                            valueType: 'from',
                          }),
                        )
                        if (dayjs(newValue).isValid() && dayjs(newValue).isAfter('1899-12-31')) {
                          setErrorFrom(null)
                        }
                      }}
                      slotProps={{
                        clearButton: { sx: { padding: '2px' } },
                        field: {
                          clearable: true,
                          sx: {
                            '& +.MuiInputAdornment-root': {
                              marginLeft: 0,
                            },
                          },
                        },
                        openPickerButton: { sx: { padding: '2px' } },
                        textField: { fullWidth: true, size: 'small' },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      onAccept={() => setErrorFrom(null)}
                      onError={(newError) => setErrorFrom(newError)}
                      value={valueFrom}
                    />

                    <DatePicker
                      label="To"
                      onChange={(newValue) => {
                        const formattedDate = dayjs(newValue).endOf('day').utc()

                        setValueTo(dayjs(newValue).isValid() ? formattedDate : null)
                        dispatch(
                          setFilterValue({
                            columnId: item.columnId,
                            value: formattedDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                            valueType: 'to',
                          }),
                        )
                        if (dayjs(newValue).isValid() && dayjs(newValue).isBefore('2100-01-01')) {
                          setErrorTo(null)
                        }
                      }}
                      slotProps={{
                        clearButton: { sx: { padding: '2px' } },
                        field: {
                          clearable: true,
                          sx: {
                            '& +.MuiInputAdornment-root': {
                              marginLeft: 0,
                            },
                          },
                        },
                        openPickerButton: { sx: { padding: '2px' } },
                        textField: { fullWidth: true, size: 'small' },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      minDate={valueFrom || yesterday}
                      onAccept={() => setErrorTo(null)}
                      onError={(newError) => setErrorTo(newError)}
                      value={valueTo}
                    />
                  </Stack>
                  {errorTo && (
                    <FormHelperText sx={{ pl: 1.75 }} error>
                      "To" date cannot precede the "From" date
                    </FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
                    <TextField
                      label="From"
                      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        dispatch(
                          setFilterValue({
                            columnId: item.columnId,
                            value: event.target.value,
                            valueType: 'from',
                          }),
                        )
                      }}
                      error={Boolean(errorFrom)}
                      InputProps={inputProps}
                      size="small"
                      value={data[item.columnId].from}
                    />
                    <TextField
                      label="To"
                      onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        dispatch(
                          setFilterValue({
                            columnId: item.columnId,
                            value: event.target.value,
                            valueType: 'to',
                          }),
                        )
                      }}
                      error={isErrorTo}
                      InputProps={inputProps}
                      size="small"
                      value={data[item.columnId].to}
                    />
                  </Stack>
                  {isErrorTo && (
                    <FormHelperText sx={{ pl: 1.75 }} error>
                      "To" cannot be less than "From"
                    </FormHelperText>
                  )}
                </>
              )}
            </Stack>
          )}
        </div>
      )
    },
    EQUALS: (item: IFilterItem) => {
      const [value, setValue] = useState(data[item.columnId]?.element || null)
      const [inputValue, setInputValue] = useState(data[item.columnId]?.element?.value || '')

      const onChangeTextField = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setInputValue(event.target.value)

          dispatch(
            setFilterValue({
              columnId: item.columnId,
              value: { value: event.target.value },
              valueType: 'element',
            }),
          )
        },
        [item.columnId],
      )

      return (
        <div style={{ marginBottom: '16px' }}>
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Switch
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  switchFilter(item, event.target.checked)

                  if (!event.target.checked) {
                    setInputValue('')
                    setValue(null)
                  }
                }}
                checked={!!data[item.columnId]}
              />
            </div>
          </div>
          {data[item.columnId] &&
            (item?.dropdownDisabled ? (
              <TextField
                fullWidth
                slotProps={{
                  htmlInput: {
                    maxLength: 50,
                  },
                }}
                // InputProps={{
                //   endAdornment: inputValue && (
                //     <IconButton size="small" onClick={() => setInputValue('')}>
                //       <CloseIcon sx={{ fontSize: 20 }} />
                //     </IconButton>
                //   ),
                // }}
                onChange={onChangeTextField}
                size="small"
                value={inputValue}
              />
            ) : (
              <Autocomplete
                onChange={(_event: SyntheticEvent<Element, Event>, newValue: IDropdownItem | string) => {
                  setValue(newValue)
                  dispatch(
                    setFilterValue({
                      columnId: item.columnId,
                      value: isObject(newValue) ? { titles: newValue.titles, value: newValue.id } : { value: newValue },
                      valueType: 'element',
                    }),
                  )
                }}
                onInputChange={(_event: SyntheticEvent<Element, Event>, newInputValue: string) => {
                  setInputValue(newInputValue)
                  setFilterInputValue({
                    columnId: item.columnId,
                    getValuesImmediately: item.getValuesImmediately,
                    type: type,
                    value: newInputValue,
                  })
                }}
                clearOnBlur={false}
                freeSolo={!item.getValuesImmediately}
                getOptionLabel={(option: IDropdownItem) => (option.titles?.length > 0 ? option.titles[0] : (option.value ?? ''))}
                inputValue={inputValue}
                isOptionEqualToValue={(option, value) => option.id === value.value}
                maxLength={50}
                options={possibleOptions[item.columnId] || []}
                size="small"
                value={value}
                autoSelect
              />
            ))}
        </div>
      )
    },
    IN: (item: IFilterItem) => {
      const isSalesTaxModerationPassions = item.columnId === PASSIONS_COLUMN_ID
      const showMultipleOptions = MULTIPLE_TITLE_SHOW_IDS.includes(item.columnId)
      const keepOptionsVisible = KEEP_OPTIONS_SHOW_IDS.includes(item.columnId)
      const { disabled, message } = checkFilterAccessibility(data.entity_type?.element?.value)
      const [inputValue, setInputValue] = useState('')
      const [value, setValue] = useState(data[item.columnId]?.elements || [])
      const noOptionsHint = useMemo(() => getNoOptionsHint(item, inputValue), [item, inputValue])

      useEffect(() => {
        if (isSalesTaxModerationPassions && disabled) {
          setValue([])
        }
      }, [isSalesTaxModerationPassions, disabled])

      const columnId = item.columnId === 'entity_status' && item.dropdownName ? item.dropdownName : item.columnId

      return (
        <div key={item.columnId} style={{ marginBottom: '16px' }}>
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Tooltip title={isSalesTaxModerationPassions ? message : ''} placement="top-end" followCursor>
                <Switch
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    switchFilter(item, event.target.checked)

                    if (!event.target.checked) {
                      setInputValue('')
                      setValue([])
                    }
                  }}
                  checked={!!data[item.columnId]}
                  disabled={isSalesTaxModerationPassions && disabled}
                />
              </Tooltip>
            </div>
          </div>
          {data[item.columnId] && (
            <Autocomplete
              filterOptions={
                showMultipleOptions ? createFilterOptions({ stringify: (option: TAny) => option.titles[0] + ' ' + option.titles[1] }) : undefined
              }
              ListboxProps={{
                style: {
                  boxSizing: 'border-box',
                  maxHeight: '352px',
                },
              }}
              onChange={(_event: SyntheticEvent<Element, Event>, newValue) => {
                setValue(newValue)

                if (!newValue?.length && !item.getValuesImmediately && !keepOptionsVisible) {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }))
                }

                dispatch(setFilterValue({ columnId: item.columnId, value: newValue, valueType: 'elements' }))
              }}
              onInputChange={(_event: SyntheticEvent<Element, Event>, newInputValue: null | string, reason) => {
                if (reason === 'input') {
                  setInputValue(newInputValue as string)
                  if (newInputValue !== null && newInputValue !== undefined && !item.getValuesImmediately) {
                    dispatch(clearPossibleOptions({ columnId: item.columnId }))
                  }
                }
                if (reason === 'clear') {
                  setInputValue('')
                  if (!item.getValuesImmediately) {
                    dispatch(clearPossibleOptions({ columnId: item.columnId }))
                  }
                }
                if (reason === 'reset' && !item.getValuesImmediately && !keepOptionsVisible) {
                  setInputValue('')
                  dispatch(clearPossibleOptions({ columnId: item.columnId }))
                }
                setFilterInputValue({
                  columnId,
                  getValuesImmediately: item.getValuesImmediately,
                  type: type,
                  value: newInputValue,
                })
              }}
              onOpen={() => {
                setFilterInputValue({
                  columnId,
                  getValuesImmediately: item.getValuesImmediately,
                  type: type,
                  value: null,
                })
              }}
              renderOption={(props, option, { inputValue, selected }) =>
                renderOptions(
                  props,
                  option.titles[0],
                  inputValue,
                  selected,
                  true,
                  false,
                  showMultipleOptions,
                  `#${option.titles[1]}`,
                  `@${option.titles[0]}`,
                )
              }
              clearOnBlur={false}
              disableCloseOnSelect={item.getValuesImmediately || keepOptionsVisible}
              getOptionLabel={(option: TAny) => (showMultipleOptions ? `@${option.titles[0]}` : option.titles[0])}
              inputValue={inputValue}
              isOptionEqualToValue={isOptionEqualToValue}
              maxLength={50}
              noOptionsText={noOptionsHint}
              options={possibleOptions[columnId] || []}
              size="small"
              value={value}
              multiple
            />
          )}
        </div>
      )
    },
    OR: (item: IFilterItem) => {
      const isSalesTaxModerationPassions = item.columnId === PASSIONS_COLUMN_ID
      const { disabled, message } = checkFilterAccessibility(data.entity_type?.element?.value)
      const [inputValue, setInputValue] = useState('')
      const [value, setValue] = useState(data[item.columnId]?.elements || [])
      const noOptionsHint = useMemo(() => getNoOptionsHint(item, inputValue), [item, inputValue])

      return (
        <div key={item.columnId} style={{ marginBottom: '16px' }}>
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Tooltip title={isSalesTaxModerationPassions ? message : ''} placement="top-end" followCursor>
                <Switch
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    switchFilter(item, event.target.checked)

                    if (!event.target.checked) {
                      setInputValue('')
                      setValue([])
                    }
                  }}
                  checked={!!data[item.columnId]}
                  disabled={isSalesTaxModerationPassions && disabled}
                />
              </Tooltip>
            </div>
          </div>
          {data[item.columnId] && (
            <Autocomplete
              onChange={(_event: SyntheticEvent<Element, Event>, newValue) => {
                setValue(newValue)
                if (!newValue?.length && !item.getValuesImmediately) {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }))
                }
                dispatch(setFilterValue({ columnId: item.columnId, value: newValue, valueType: 'elements' }))
              }}
              onInputChange={(_event: SyntheticEvent<Element, Event>, newInputValue: null | string, reason) => {
                setInputValue(newInputValue as string)
                if (reason === 'reset') {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }))
                }
                setFilterInputValue({
                  columnId: item.columnId,
                  getValuesImmediately: item.getValuesImmediately,
                  type: type,
                  value: newInputValue,
                })
              }}
              clearOnBlur={false}
              getOptionLabel={(option: TAny) => option.titles[0]}
              inputValue={inputValue}
              isOptionEqualToValue={isOptionEqualToValue}
              maxLength={50}
              noOptionsText={noOptionsHint}
              options={possibleOptions[item.columnId] || []}
              renderOption={(props, option, { inputValue, selected }) => renderOptions(props, option.titles[0], inputValue, selected, true)}
              size="small"
              value={value}
              disableCloseOnSelect
              multiple
            />
          )}
        </div>
      )
    },
  }

  useLayoutEffect(() => {
    const item = document.querySelector('.filterDrawer--content')

    setIsScrollVisible(item?.clientHeight !== item?.scrollHeight)
  }, [])

  return (
    <div className={`filterDrawer--container ${(type === 'users' || Object.values(ModerationTabs).includes(type as ModerationTabs)) && 'wide'}`}>
      <div className="filterDrawer--header">
        <Typography variant="h5">Filter</Typography>
        <IconButton onClick={close} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={`filterDrawer--content${isScrollVisible ? ' scrollable' : ''}`}>
        {!isHidePresets && presets.length > 0 && (
          <>
            <Stack className="filterDrawer--group" direction="column" gap={1}>
              <Typography variant="h6">Presets</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1.5} mb={2}>
                {presets.map((preset: IPreset) => {
                  return (
                    <Chip
                      label={preset.presetName}
                      active={preset.presetId === activePresetId}
                      className="filterDrawer--group__chip"
                      deleteIcon={<CloseCircleIcon />}
                      onClick={() => setPreset(preset.presetId)}
                      onDelete={() => showDeletePresetModal(preset)}
                    />
                  )
                })}
              </Stack>
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </>
        )}

        <div className="filterDrawer--group">
          {!isHidePresets && <Typography variant="h6">Available filters</Typography>}
          {revisedFilters.map((item: IFilterItem) => (
            <div key={item.columnId}>{filterTypes[item.type](item)}</div>
          ))}
        </div>
      </div>
      <Box boxShadow={5} className="filterDrawer--footer">
        <Button fullWidth disabled={isValidApplyButton} onClick={applyFilters} size="large" variant="contained">
          Apply
        </Button>
        {!isHidePresets && (
          <Button
            fullWidth
            onClick={() => {
              setSavePresetModal(true)
            }}
            disabled={type === 'users'}
            size="large"
            variant="outlined"
          >
            Save set
          </Button>
        )}
      </Box>
      <Dialog fullWidth maxWidth="xs" open={savePresetModal}>
        <DialogTitle variant="h6">Save new preset</DialogTitle>
        <DialogContent>
          <DialogContentText color="text.primary" variant="subtitle1">
            Enter name for new filter preset
          </DialogContentText>
          <TextField
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)}
            placeholder="Name"
            size="small"
            sx={{ mt: 1 }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeSavePresetModalClose}>Cancel</Button>
          <Button disabled={presetName.trim().length < 3} onClick={() => handleSavePreset(presetName)} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={deletePresetModal}>
        <DialogTitle variant="h6">Delete preset?</DialogTitle>
        <DialogContent>
          <DialogContentText color="text.primary" variant="body1">
            You are going to delete preset {deletedPreset?.presetName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleChangeDeletePresetModalClose()}>Cancel</Button>
          <Button color="error" onClick={() => deletedPreset?.presetId && handleDeletePreset(deletedPreset.presetId)} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FilterDrawer
