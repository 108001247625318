import { createSvgIcon } from '@mui/material'

const SearchIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M16.0198 17.2219C14.6628 18.3156 12.9371 18.9704 11.0584 18.9704C6.68878 18.9704 3.14648 15.4281 3.14648 11.0584C3.14648 6.68878 6.68878 3.14648 11.0584 3.14648C15.4281 3.14648 18.9704 6.68878 18.9704 11.0584C18.9704 12.9371 18.3156 14.6628 17.2219 16.0198L20.6043 19.4023C20.9363 19.7342 20.9363 20.2724 20.6043 20.6043C20.2724 20.9363 19.7342 20.9363 19.4023 20.6043L16.0198 17.2219ZM4.84648 11.0584C4.84648 7.62767 7.62767 4.84648 11.0584 4.84648C14.4892 4.84648 17.2704 7.62767 17.2704 11.0584C17.2704 12.7645 16.5826 14.3099 15.4692 15.4326C15.4629 15.4385 15.4567 15.4445 15.4506 15.4506C15.4445 15.4567 15.4385 15.4629 15.4326 15.4692C14.3099 16.5826 12.7645 17.2704 11.0584 17.2704C7.62767 17.2704 4.84648 14.4892 4.84648 11.0584Z"
      fillRule="evenodd"
    />
  </svg>,
  'Search',
)

export default SearchIcon
