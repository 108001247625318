import './index.scss'

import { DropzoneArea, DropzoneAreaProps } from 'mui-file-dropzone'

interface IDropZoneProps extends DropzoneAreaProps {
  label?: string
  primary?: boolean
}

const Dropzone = (props: IDropZoneProps) => {
  return (
    <DropzoneArea
      classes={{
        root: 'MuiBox-root',
        active: 'MuiBox-root-active',
        // invalid: "MuiBox-root-invalid" // todo с текущей версией либы есть проблема на определение невалидного файла в хроме с реакт 18 (проблема решена с версией react-dropzone 14.1.1)
      }}
      disableRejectionFeedback={false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      fileObjects={[]}
      {...props}
    />
  )
}

export default Dropzone
