/**
 * Enumerates the possible status values for user.
 *
 * @enum {string}
 * @readonly
 * @property {string} ACTIVE - The active status.
 * @property {string} BANNED - The banned status.
 * @property {string} DELETED - The deleted status.
 * @property {string} ARCHIVE - The archive status.
 * @property {string} NEEDS_REVIEW - The needs review status.
 */
export enum UserStatuses {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

/**
 * Represents status object for users.
 *
 * @interface
 * @property {TierStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {'error' | 'success' | 'warning' | 'info' | 'inherit' | 'primary' | 'secondary' | undefined} color - Associated color code.
 */
export interface IUserStatus {
  color: 'error' | 'info' | 'inherit' | 'primary' | 'secondary' | 'success' | 'warning' | undefined
  status: UserStatuses
  title: string
}
