const ContentIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1973 10.7824C13.5935 11.1668 13.6014 11.8014 13.2173 12.1978C12.8321 12.5933 12.1994 12.6019 11.8037 12.217C11.408 11.8321 11.399 11.1993 11.7837 10.8033C12.1683 10.4074 12.8011 10.398 13.1973 10.7824Z" />
    <path
      clipRule="evenodd"
      d="M5.14989 6.00002V6.24417C3.43257 6.63116 2.14989 8.1658 2.14989 10V18C2.14989 20.1263 3.87359 21.85 5.99989 21.85H13.9999C15.8341 21.85 17.3688 20.5673 17.7557 18.85H17.9999C20.1262 18.85 21.8499 17.1263 21.8499 15V6.00002C21.8499 3.87373 20.1262 2.15002 17.9999 2.15002H8.99989C6.87359 2.15002 5.14989 3.87373 5.14989 6.00002ZM8.99989 3.85002C7.81247 3.85002 6.84989 4.81261 6.84989 6.00002V6.15002H13.9999C16.1262 6.15002 17.8499 7.87373 17.8499 10V17.15H17.9999C19.1873 17.15 20.1499 16.1874 20.1499 15V6.00002C20.1499 4.81261 19.1873 3.85002 17.9999 3.85002H8.99989ZM13.9999 20.15C15.1873 20.15 16.1499 19.1874 16.1499 18V10C16.1499 8.81261 15.1873 7.85002 13.9999 7.85002H5.99989C4.81247 7.85002 3.84989 8.81261 3.84989 10V12.3739C3.88826 12.3731 3.92663 12.3725 3.96502 12.3722L3.96704 12.3722L3.97223 12.3722C6.00261 12.3722 7.94983 13.1788 9.38552 14.6145C10.8212 16.0502 11.6278 17.9974 11.6278 20.0278L11.6277 20.0356C11.6274 20.0737 11.6268 20.1119 11.6258 20.15H13.9999ZM3.84989 14.0745V18C3.84989 19.1874 4.81248 20.15 5.99989 20.15H9.92522C9.92651 20.108 9.92736 20.0659 9.92778 20.0238C9.92674 18.4457 9.2994 16.9325 8.18344 15.8165C7.06726 14.7004 5.55364 14.073 3.9752 14.0722C3.93342 14.0725 3.89164 14.0733 3.84989 14.0745Z"
      fillRule="evenodd"
    />
  </svg>
)

export default ContentIcon
