import { createSvgIcon } from '@mui/material'

const CloseCircleIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22C6.49 22 2 17.51 2 12ZM7.75736 15.0405L10.7979 12L7.75729 8.95944C7.42534 8.62749 7.42534 8.0893 7.75729 7.75736C8.08924 7.42541 8.62743 7.42541 8.95937 7.75736L11.9999 10.7979L15.0406 7.75729C15.3725 7.42534 15.9107 7.42534 16.2426 7.75729C16.5746 8.08924 16.5746 8.62743 16.2426 8.95937L13.202 12L16.2426 15.0406C16.5745 15.3725 16.5745 15.9107 16.2426 16.2426C15.9106 16.5746 15.3724 16.5746 15.0405 16.2426L11.9999 13.2021L8.95944 16.2426C8.6275 16.5745 8.08931 16.5745 7.75736 16.2426C7.42542 15.9106 7.42542 15.3724 7.75736 15.0405Z"
      fillRule="evenodd"
    />
  </svg>,
  'CloseCircle',
)

export default CloseCircleIcon
