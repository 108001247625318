import './index.scss'

import { useCallback, useEffect, useState } from 'react'
import { SortEnd, SortEvent, SortEventWithTag, SortStart } from 'react-sortable-hoc'

import { arrayMoveImmutable } from 'array-move'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import Button from '@admin/components/shared/Button/Button'
import SortableItem from '@admin/components/shared/SortableList/SortableItem'
import SortableList from '@admin/components/shared/SortableList/SortableList'
import CollectionEntity from '@admin/components/WorldManagement/Collections/CollectionDetails/CollectionEntity/CollectionEntity'
import changeOrderAndStatus from '@admin/components/WorldManagement/Collections/CollectionDetails/utils/changeOrderAndStatus'
import { TOrderedEntity } from '@admin/store/collectionDetailsSlice'

interface IProps {
  collectionId: string
  entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'
  heightEntity: number
  isCanDrag: boolean
  isDefaultCollection: boolean
  items: [] | TOrderedEntity[]
  onSortEntities(entities: TOrderedEntity[]): void
  parentsWidth?: number
  setIsDeleteEntities(entities: string[]): void
  widthEntity: number
}

const CollectionBoard = ({
  parentsWidth,
  widthEntity,
  collectionId,
  entityType,
  heightEntity,
  isCanDrag,
  isDefaultCollection,
  items,
  onSortEntities,
  setIsDeleteEntities,
}: IProps) => {
  const [entities, setEntities] = useState<[] | TOrderedEntity[]>([])
  const [maxAvailableIndex, setMaxAvailableIndex] = useState<number>()
  const [selectedEntities, setSelectedEntities] = useState<string[]>([])
  const [maxWidth, setMaxWidth] = useState(0)

  const onSortEnd = ({ newIndex, oldIndex }: SortEnd) => {
    const newAvailableIndex = maxAvailableIndex && newIndex >= maxAvailableIndex ? maxAvailableIndex - 1 : newIndex
    const sortedArray = arrayMoveImmutable(entities, oldIndex, newAvailableIndex)
    const ordered = changeOrderAndStatus(sortedArray, entityType)

    onSortEntities(ordered)
    setEntities(ordered)
  }

  const onSortStart = ({ helper }: SortStart) => {
    helper.setAttribute('isDragging', 'true')
  }

  const initialEntitiesOrder = useCallback(() => {
    const unavailable: [] | TOrderedEntity[] = items.filter(({ entityStatus }) => entityStatus === 'UNAVAILABLE')
    const available: [] | TOrderedEntity[] = items.filter(({ entityStatus }) => entityStatus !== 'UNAVAILABLE')

    setMaxAvailableIndex(available.length)
    const orderedArray = [...available].sort((item1, item2) => item1.order - item2.order)

    setEntities([...orderedArray, ...unavailable])
  }, [items])

  const toggleSelectedEntity = (entity: TOrderedEntity, isCheck: boolean) => {
    setSelectedEntities((previousState) => {
      return isCheck ? [...previousState, entity.entityId] : previousState?.filter((entityId) => entityId !== entity.entityId)
    })
  }

  useEffect(() => {
    /* Gap между энтити 8 */
    const maxEntity = Math.floor((parentsWidth! + 8) / (widthEntity + 8))

    setMaxWidth(maxEntity * (widthEntity + 8))
  }, [parentsWidth, widthEntity])

  useEffect(() => {
    initialEntitiesOrder()
    setSelectedEntities((previousState) => {
      return previousState.filter((value) => items.find(({ entityId }) => entityId === value))
    })
  }, [items])

  return (
    <div className="collectionBoard" style={{ width: `${maxWidth}px` }}>
      {selectedEntities.length > 0 && (
        <div className="collectionBoard-toolbar">
          <div>
            <span className="collectionBoard-toolbar-selectedAmount">{selectedEntities.length} selected</span>
            <Button
              className="collectionBoard-toolbar-selectButton"
              onClick={() => setSelectedEntities(entities.map(({ entityId }) => entityId))}
              variant="text"
            >
              Select all
            </Button>
            <Button className="collectionBoard-toolbar-selectButton" onClick={() => setSelectedEntities([])} variant="text">
              Deselect all
            </Button>
          </div>
          <Button color="error" onClick={() => setIsDeleteEntities(selectedEntities)} startIcon={<DeleteIcon />} variant="outlined">
            Delete
          </Button>
        </div>
      )}
      {entities && (
        <SortableList
          shouldCancelStart={(event: SortEvent | SortEventWithTag) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            return (event as SortEventWithTag) && ['input', 'button', 'svg', 'g', 'path'].indexOf(event.target.tagName?.toLowerCase()) !== -1
          }}
          axis="xy"
          className="collectionBoard-flex"
          onSortEnd={(sort) => onSortEnd(sort)}
          onSortStart={(sort) => onSortStart(sort)}
        >
          {entities.map((value: TOrderedEntity, index) => (
            <SortableItem key={`item-${value.compilationEntityId}`} disabled={!isCanDrag || value.entityStatus === 'UNAVAILABLE'} index={index}>
              <CollectionEntity
                widthEntity={widthEntity}
                collectionId={collectionId}
                entity={value}
                entityType={entityType}
                heightEntity={heightEntity}
                isCanDrag={isCanDrag && value.entityStatus !== 'UNAVAILABLE'}
                isDefaultCollection={isDefaultCollection}
                isSelectedEntity={selectedEntities.includes(value.entityId)}
                setDeleteEntity={setIsDeleteEntities}
                toggleSelectedEntity={toggleSelectedEntity}
              />
            </SortableItem>
          ))}
        </SortableList>
      )}
    </div>
  )
}

export default CollectionBoard
