const SubscriptionsIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M8.4502 2.6499C5.57873 2.6499 3.2002 4.9057 3.2002 7.7499C3.2002 10.5941 5.57873 12.8499 8.4502 12.8499C11.3217 12.8499 13.7002 10.5941 13.7002 7.7499C13.7002 4.9057 11.3217 2.6499 8.4502 2.6499ZM4.9002 7.7499C4.9002 5.89968 6.46155 4.3499 8.4502 4.3499C10.4388 4.3499 12.0002 5.89968 12.0002 7.7499C12.0002 9.60012 10.4388 11.1499 8.4502 11.1499C6.46155 11.1499 4.9002 9.60012 4.9002 7.7499Z"
      fill="#BFC6E2"
      fillRule="evenodd"
    />
    <path
      d="M15.0502 4.7749C14.5808 4.7749 14.2002 5.15546 14.2002 5.6249C14.2002 6.09434 14.5808 6.4749 15.0502 6.4749C16.1276 6.4749 16.9502 7.31114 16.9502 8.28115C16.9502 9.25117 16.1276 10.0874 15.0502 10.0874C14.5808 10.0874 14.2002 10.468 14.2002 10.9374C14.2002 11.4068 14.5808 11.7874 15.0502 11.7874C17.0104 11.7874 18.6502 10.2452 18.6502 8.28115C18.6502 6.31716 17.0104 4.7749 15.0502 4.7749Z"
      fill="#BFC6E2"
    />
    <path
      d="M2.7 18.2864C2.7 17.8413 3.08031 17.2753 4.24599 16.778C5.351 16.3067 6.881 16.0374 8.45 16.0374C9.1667 16.0374 9.87894 16.1046 10.5476 16.226C10.7353 16.2601 10.928 16.1906 11.0455 16.0403L11.7712 15.1114C11.8805 14.9715 11.813 14.7656 11.6407 14.7227C11.5561 14.7017 11.4707 14.6814 11.3848 14.662C10.4528 14.4512 9.44801 14.3374 8.45 14.3374C6.71901 14.3374 4.949 14.63 3.57901 15.2143C2.26969 15.7728 1 16.7563 1 18.2864C1 18.9469 1.34477 19.4723 1.78849 19.8504C2.22259 20.2204 2.80106 20.4982 3.44086 20.7084C4.7268 21.1311 6.47546 21.3499 8.45 21.3499C9.36383 21.3499 10.2855 21.2868 11.15 21.1646C11.3452 21.137 11.4269 20.9034 11.2948 20.7569L10.3879 19.7514C10.2816 19.6336 10.1257 19.5736 9.9676 19.5869C9.47355 19.6282 8.96321 19.6499 8.45 19.6499C6.57454 19.6499 5.0232 19.439 3.97164 19.0934C3.44269 18.9196 3.09303 18.7286 2.8912 18.5566C2.69997 18.3936 2.69999 18.3035 2.7 18.2864Z"
      fill="#BFC6E2"
    />
    <path
      d="M18.2578 14.5029C18.5904 14.1715 19.1286 14.1725 19.4599 14.505L22.8487 17.906C23.0434 18.1013 23.0431 18.4175 22.8481 18.6125L19.4599 22.0001C19.128 22.332 18.5898 22.3319 18.2578 22C17.9259 21.668 17.926 21.1298 18.2579 20.7979L19.9477 19.1084H12.3546C11.8851 19.1084 11.5046 18.7279 11.5046 18.2584C11.5046 17.789 11.8851 17.4084 12.3546 17.4084H19.9531L18.2557 15.705C17.9243 15.3724 17.9253 14.8342 18.2578 14.5029Z"
      fill="#BFC6E2"
    />
  </svg>
)

export default SubscriptionsIcon
