import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { injectStore } from './store/utils/axiosInstance'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license'

import { theme } from '@yzzy/ui'

import '@admin/assets/styles/index.scss'
import { initSentry } from '@admin/shared/thirdParty/sentry'

import { StrictModeContainer } from './components/StrictModeContainer'
import { AppRoutes } from './routes/AppRoutes'
import { store } from './store/store'

initSentry()

injectStore(store)

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENCE_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictModeContainer>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  </StrictModeContainer>,
)
