import { GridRenderCellParams } from '@mui/x-data-grid'

import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { IOperation, TransactionTypes } from '@admin/store/slices/Payments/interface'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Props for the RenderTransactionTotalCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderTransactionTotalCellProps {
  props: GridRenderCellParams
  rowData: IOperation
}

/**
 * Renders a "Total" cell in a data grid (Transactions table).
 */
export const RenderTransactionTotalCell = ({ props, rowData }: IRenderTransactionTotalCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const isPayment = row.type === TransactionTypes.CARD_PAYMENTS || row.type === TransactionTypes.REFUND
  const isInAppTransaction = row.type === TransactionTypes.GOOGLE_IAP || row.type === TransactionTypes.APPLE_IAP
  const isTransfer = row.type === TransactionTypes.TRANSFER
  const isReversalTransfer = row.type === TransactionTypes.REVERSAL_TRANSFER

  return (
    <Stack alignContent={'flex-end'}>
      {isPayment && <Typography variant="body2">{currencyFormatter(Number(rowData.total), 'en-US', 'USD', true)}</Typography>}
      {isInAppTransaction && <Typography variant="body2">{currencyFormatter(Number(rowData.total), 'en-US', 'USD', true)}</Typography>}
      {isTransfer && <Typography variant="body2">{currencyFormatter(Number(rowData.totalAfterAllFees), 'en-US', 'USD', true)}</Typography>}
      {isReversalTransfer && <Typography variant="body2">{currencyFormatter(Number(rowData.totalAfterAllFees), 'en-US', 'USD', true)}</Typography>}
      {!isPayment && !isTransfer && !isInAppTransaction && !isReversalTransfer && '—'}
    </Stack>
  )
}
