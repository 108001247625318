import { useCallback } from 'react'

import { enqueueSnackbar, VariantType } from 'notistack'

import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { OperationActions } from '@admin/store/slices/Payments/interface'
import { fetchOperationAction, selectOperationActionStatus } from '@admin/store/slices/Payments/operationsSlice'

interface ISuspensionConfirmModalProps {
  isOperationSuspended: boolean
  onClose: () => void
  onDataRefresh: () => void
  open: boolean
  operationId: string
}

export const SuspensionConfirmModal = ({ isOperationSuspended, onClose, onDataRefresh, open, operationId }: ISuspensionConfirmModalProps) => {
  const dispatch = useAppDispatch()
  const operationActionStatus = useAppSelector(selectOperationActionStatus)
  const isOperationActionLoading = operationActionStatus === 'loading'

  const onSubmit = useCallback(async () => {
    const formattedData = {
      action: isOperationSuspended ? OperationActions.UNSUSPEND : OperationActions.SUSPEND,
      operationId: operationId,
    }

    const result = await dispatch(fetchOperationAction(formattedData))

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Transactions on the operation have been ${isOperationSuspended ? 'resumed' : 'suspended'}`, {
        variant: 'success' as VariantType,
      })
      onClose()
      onDataRefresh()
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Operation error, try again later', {
        variant: 'error' as VariantType,
      })
    }
  }, [isOperationSuspended])

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">{isOperationSuspended ? 'Unblock operation?' : 'Suspend operation?'}</DialogTitle>
      <DialogContent>
        <Typography mb={3} variant="body1">
          {isOperationSuspended
            ? 'All transactions for the operation will be resumed'
            : 'All transactions for the operation will be suspended. The operation can always be unlocked later and payments will continue'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton color="primary" loading={isOperationActionLoading} onClick={onSubmit} variant="contained">
          {isOperationSuspended ? 'Unblock' : 'Suspend'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
