import * as yup from 'yup'

export const EditMemberSchema = yup.object({
  email: yup
    .string()
    .required('Enter valid email')
    .matches(
      // eslint-disable-next-line sonarjs/regex-complexity,sonarjs/single-char-in-character-classes
      /^([\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.]?){2,192}(?<![\w][\!\#\$\%\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.])(@{1})(([\w][\-]?){1,63})(?<![-])([\.]{1})(([\w][\-]?){2,})(?<![-])$/,
      'Enter valid email',
    ),
  firstName: yup
    .string()
    .matches(/^(?:[a-zA-Z\s\-\']*)$/, "First name must contain only Latin letters A-Z, a-z; space and special symbols '-")
    .matches(/^(?:[a-zA-Z\s\-\.]*[a-zA-Z][a-zA-Z\s\-\']*)$/, 'First name must contain Latin letters')
    .min(2, 'First name should be at least 2 symbols'),
  lastName: yup
    .string()
    .matches(/^(?:[a-zA-Z\s\-\']*)$/, "Last name must contain only Latin letters A-Z, a-z; space and special symbols '-")
    .matches(/^(?:[a-zA-Z\s\-\.]*[a-zA-Z][a-zA-Z\s\-\']*)$/, 'Last name must contain Latin letters')
    .min(2, 'Last name should be at least 2 symbols'),
  roleId: yup.string(),
})

export type TEditMemberSchema = yup.InferType<typeof EditMemberSchema>
