import { createSvgIcon } from '@mui/material'

const FlashIcon = createSvgIcon(
  <svg width="25" fill="currentColor" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M12.0196 1.62854C13.284 0.546067 15.3106 1.41541 15.3106 3.11243V8.86336H18.5516C19.4492 8.86336 20.1475 9.41544 20.4225 10.148C20.6908 10.8626 20.5469 11.7045 19.9478 12.3469L13.2748 22.1367C13.2448 22.1806 13.2108 22.2217 13.1731 22.2593C12.5512 22.8805 11.7004 22.9936 11.0057 22.6825C10.3186 22.3749 9.8343 21.6775 9.8343 20.8197V15.0688H6.06885C5.15631 15.0688 4.48693 14.5005 4.23203 13.783C3.98997 13.1016 4.11676 12.2843 4.68174 11.6805L11.885 1.77433C11.924 1.72064 11.9692 1.67171 12.0196 1.62854ZM13.1821 2.88117L6.02204 12.7279C5.99582 12.7639 5.96681 12.7979 5.93527 12.8294C5.81015 12.9544 5.79482 13.1037 5.83395 13.2139C5.85334 13.2685 5.88329 13.305 5.91282 13.3268C5.93814 13.3454 5.98324 13.3688 6.06885 13.3688H9.84513C10.6621 13.3688 11.5343 13.9947 11.5343 15.057V20.8197C11.5343 21.0096 11.6269 21.0981 11.7004 21.131C11.7409 21.1491 11.7814 21.1535 11.8186 21.1469C11.8443 21.1423 11.8816 21.1303 11.9268 21.096L18.5835 11.3302C18.6135 11.2862 18.6475 11.2451 18.6851 11.2075C18.874 11.0189 18.8685 10.8454 18.831 10.7456C18.7913 10.64 18.7029 10.5634 18.5516 10.5634H15.2998C14.4828 10.5634 13.6106 9.93746 13.6106 8.87515V3.11243C13.6106 3.01962 13.5609 2.93184 13.4445 2.87974C13.3524 2.83852 13.2609 2.8398 13.1821 2.88117Z"
      fillRule="evenodd"
    />
  </svg>,
  'Flash',
)

export default FlashIcon
