import { GridRenderCellParams } from '@mui/x-data-grid'

import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderCellWithTooltip component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {string} title - Tooltip title.
 */
interface IRenderCellWithTooltipProps {
  props: GridRenderCellParams
  title: string
}

/**
 * Renders a cell with Tooltip in a data grid.
 */
const RenderCellWithTooltip = ({ title, props }: IRenderCellWithTooltipProps) => {
  const { row, value } = props

  if (row) {
    return (
      <Tooltip title={title} placement="top-end" style={{ display: 'inline-grid' }} followCursor>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'wrap',
          }}
          color="text.primary"
          component="span"
          variant="body2"
        >
          {value}
        </Typography>
      </Tooltip>
    )
  }

  return null
}

export default RenderCellWithTooltip
