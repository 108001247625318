import './index.scss'

import { useCallback, useState } from 'react'

import { GridOverlay } from '@mui/x-data-grid'

import CheckCircleIcon from '@admin/assets/img/CheckCircleIcon'
import Button from '@admin/components/shared/Button/Button'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppSelector } from '@admin/store/hooks'
import { selectAssignStatus } from '@admin/store/moderationSlice'
import colors from '@admin/theme/constants/colors'

interface IProps {
  clearFilter?: () => void
  fetchingError?: boolean
  isFilters?: boolean
  refreshData?: () => void
  ticketsGroup?: string
}

const NoMoreTickets = ({ clearFilter, fetchingError, isFilters, refreshData, ticketsGroup }: IProps) => {
  const assignStatus = useAppSelector(selectAssignStatus)

  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false)

  const cancelClearFilters = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setClearFiltersConfirmModal(false)
  }, [])

  return (
    <GridOverlay className="moderation-table-container__overlay">
      {fetchingError && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            There are some troubles with data receiving. Repeat request in a while.
          </Typography>
          <Typography color="text.secondary" fontSize="small" variant="body2">
            WARNING! In case of page reload you could lose your requested data
          </Typography>
          <Button onClick={() => refreshData && refreshData()} variant="text">
            Repeat request
          </Button>
        </Stack>
      )}

      {isFilters && !fetchingError && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change their value
          </Typography>
          <Button onClick={() => clearFilter && setClearFiltersConfirmModal(true)} variant="text">
            Clear filters
          </Button>
        </Stack>
      )}

      {!isFilters &&
        !fetchingError &&
        (ticketsGroup === 'My' ? (
          <Stack alignItems="center" gap={2}>
            {assignStatus === 'loading' && (
              <div className="loading-overlay">
                <Stack alignItems="center" height={1}>
                  <CircularProgress color="primary" sx={{ margin: 'auto' }} />
                </Stack>
              </div>
            )}
            <CheckCircleIcon sx={{ color: colors.outlinedBorder, fontSize: 32 }} />
            <Typography color={colors.outlinedBorder} variant="h6">
              There are no tickets assigned to you.
            </Typography>
            <Typography color={colors.outlinedBorder} variant="h6">
              Press GET TICKETS button if you want to request a batch of tickets
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="center">
            <Typography color={colors.outlinedBorder} variant="h6">
              No tickets to display
            </Typography>
          </Stack>
        ))}
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => cancelClearFilters()} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={() => clearFilter && clearFilter()} variant="contained">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </GridOverlay>
  )
}

export default NoMoreTickets
