import { GridRenderCellParams } from '@mui/x-data-grid'

import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { TransactionTypes } from '@admin/store/slices/Payments/interface'

import { possiblePaymentMethods, possibleTransactionsTypes } from '../constants'

/**
 * Props for the RenderProviderCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderProviderCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a "Provider / type" cell in a data grid.
 */
export const RenderProviderCell = ({ props }: IRenderProviderCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const currentTransactionType = possibleTransactionsTypes.find((element) => element.transactionType?.toLowerCase() === row.type?.toLowerCase())

  let providerName = 'Stripe'

  if (currentTransactionType) {
    switch (currentTransactionType.transactionType) {
      case TransactionTypes.APPLE_IAP:
        providerName = 'Apple'
        break
      case TransactionTypes.GOOGLE_IAP:
        providerName = 'Google'
        break
    }
  }

  const paymentMethodName =
    currentTransactionType?.transactionType !== TransactionTypes.GOOGLE_IAP &&
    currentTransactionType?.transactionType !== TransactionTypes.APPLE_IAP &&
    possiblePaymentMethods.find((element) => element.paymentMethod?.toLowerCase() === row.paymentMethodName?.toLowerCase())

  return (
    <Stack>
      <Typography variant="body2">{providerName || '—'}</Typography>
      <Typography color="text.secondary" variant="caption">
        {currentTransactionType?.title || '—'}
      </Typography>
      <Typography color="text.secondary" variant="caption">
        {paymentMethodName && paymentMethodName.title}
      </Typography>
    </Stack>
  )
}
