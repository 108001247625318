import { GridRenderCellParams } from '@mui/x-data-grid'

import InfoIcon from '@admin/assets/img/InfoIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { IOperation, OperationTypes, TransactionTypes } from '@admin/store/slices/Payments/interface'
import colors from '@admin/theme/constants/colors'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Props for the RenderTransactionFeeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderTransactionFeeCellProps {
  props: GridRenderCellParams
  rowData: IOperation
}

/**
 * Renders a "Fee" cell in a data grid (Transactions table).
 */
export const RenderTransactionFeeCell = ({ props, rowData }: IRenderTransactionFeeCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const renderFee = (amount: number, tooltipTitle: string) => {
    return amount ? (
      <Stack alignItems="center" direction="row" gap={1} justifyContent="flex-end">
        <Typography variant="body2">{currencyFormatter(amount, 'en-US', 'USD', true)}</Typography>
        <Tooltip title={tooltipTitle} placement="right" arrow>
          <IconButton size="small" sx={{ p: 0 }} disabled disableRipple>
            <InfoIcon sx={{ color: colors.actionDisabled, fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    ) : (
      <div>—</div>
    )
  }

  const isCardPaymentTransaction = row.type === TransactionTypes.CARD_PAYMENTS
  const isTransferTransaction = row.type === TransactionTypes.TRANSFER
  const isInAppTransaction = row.type === TransactionTypes.GOOGLE_IAP || row.type === TransactionTypes.APPLE_IAP
  const isInAppOperation =
    rowData.operationType === OperationTypes.IAP_FOLLOW ||
    rowData.operationType === OperationTypes.IAP_DONATE ||
    rowData.operationType === OperationTypes.IAP_EVENT ||
    rowData.operationType === OperationTypes.IAP_LIFESTYLE

  return (
    <Stack alignContent="end" textAlign="right">
      {isCardPaymentTransaction && !isInAppTransaction && renderFee(Number(rowData.platformFeeClient), 'Platform fee client')}
      {isCardPaymentTransaction && !isInAppTransaction && renderFee(Number(rowData.paymentFeeAmount), 'Provider fee')}
      {isTransferTransaction && !isInAppOperation && renderFee(Number(rowData.serviceFeeCreator), 'Service fee creator')}

      {isInAppOperation && isTransferTransaction && renderFee(Number(rowData.platformFeeCreator), 'Platform fee creator')}
      {isTransferTransaction && isInAppOperation && renderFee(Number(rowData.providerFeeCreator), 'Provider fee creator')}

      {!isTransferTransaction && !isCardPaymentTransaction && '—'}
    </Stack>
  )
}
