import { GridColumnHeaderParams } from '@mui/x-data-grid-pro'

import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderWithTooltipHeader component.
 *
 * @interface
 * @property {string} title - The title of tooltip.
 * @property {GridColumnHeaderParams} props - Parameters for rendering the cell.
 */
interface IRenderHeaderWithTooltipProps {
  props: GridColumnHeaderParams
  title: string
}

/**
 * Renders a header cell with tooltip in a data grid.
 */
export const RenderWithTooltipHeader = ({ title, props }: IRenderHeaderWithTooltipProps) => {
  const { colDef } = props

  return (
    <Tooltip title={title} placement="top" arrow>
      <div>
        <Typography variant="tableHeader">{colDef.headerName}</Typography>
      </div>
    </Tooltip>
  )
}
