import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import ContentDisplayTabs from '@admin/components/ContentDisplay/types/ContentDisplayTabs'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import { CONTENT_DISPLAY_API, MEMBERS_API, MODERATION_API, OPERATIONS_API, PAYMENTS_API, TAXES_API, USERS_API } from '@admin/shared/api/constants'
import { IPreset, ISortingItem, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface ISortingState {
  activePresetId: null | string
  columns: ISortingItem[]
  currentData: ISortingItem[]
  data: ISortingItem[]
  presets: IPreset[]
  status: TStatus
}

const initialState: ISortingState = {
  activePresetId: null,
  columns: [],
  currentData: [],
  data: [],
  presets: [],
  status: 'idle',
}

export const fetchSortingColumns = createAsyncThunk('sorting/fetchColumns', async (type: string) => {
  let columnsUrl

  switch (type) {
    case 'consumption-requests': {
      columnsUrl = OPERATIONS_API + 'screen/sorting?belongsToKinds=consumption_requests_screen'
      break
    }
    case ContentDisplayTabs.EVENTS:
    case ContentDisplayTabs.LIFESTYLES:
    case ContentDisplayTabs.OFFERS:
    case ContentDisplayTabs.WISHES: {
      columnsUrl = CONTENT_DISPLAY_API + `content/screen/${type}/sorting`
      break
    }
    case 'members': {
      columnsUrl = MEMBERS_API + 'members/screen/sorting'
      break
    }
    case ModerationTabs.CONFIRMED_VIOLATIONS:
    case ModerationTabs.DECLINED_REQUESTS: {
      columnsUrl = MODERATION_API + `moderation/screen/history/sorting`
      break
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      columnsUrl = MODERATION_API + `moderation/screen/content/sorting`
      break
    }
    case ModerationTabs.HIVE: {
      columnsUrl = MODERATION_API + `moderation/screen/hive/sorting`
      break
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      columnsUrl = MODERATION_API + `moderation/screen/profile/sorting`
      break
    }
    case 'operations': {
      columnsUrl = OPERATIONS_API + 'screen/sorting?belongsToKinds=operation_find'
      break
    }
    case 'tax-moderation': {
      columnsUrl = TAXES_API + 'sales/tax/screen/sorting'
      break
    }
    case 'tiers': {
      columnsUrl = PAYMENTS_API + 'screen/sorting?belongsToKind=tier_find'
      break
    }
    case 'users':
    default: {
      columnsUrl = USERS_API + 'timusers/presets/columns'
    }
  }
  const response = await axios.get(columnsUrl, {
    // headers: {
    //   'Member-Id': '0'
    // }
  })

  return { columns: response.data.columns ?? response.data.sortingList, type: type }
})

export const fetchSortingPresets = createAsyncThunk('sorting/fetchPresets', async (type: string) => {
  const presetsUrl = 'timusers/presets?presetType=SORTING'

  const response = await axios.get(USERS_API + presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const setActivePreset = createAsyncThunk(
  'sorting/setActivePreset',
  async ({ presetId, type }: { presetId: string; type: string }, thunkAPI) => {
    const presetUrl = `timusers/presets/sorting/${presetId}`
    const rootState: TRootState = (await thunkAPI.getState()) as TRootState
    const columns = rootState.sorting.columns

    const response = await axios.get(USERS_API + presetUrl, {
      headers: {
        'Member-Id': '0',
      },
    })

    return { columns: columns, presetId: presetId, sortingList: response.data.sortingList }
  },
)

export const savePreset = createAsyncThunk(
  'sorting/savePreset',
  async (
    {
      presetName,
      type,
    }: {
      presetName: string
      type: string
    },
    thunkAPI,
  ) => {
    const rootState: TRootState = (await thunkAPI.getState()) as TRootState
    const sorting = Object.keys(rootState.sorting.data) ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null) : []
    const presetsUrl = 'timusers/presets?presetType=SORTING'

    const response = await axios.post(
      USERS_API + presetsUrl,
      {
        presetName: presetName,
        presetType: 'SORTING',
        sortingList: sorting,
      },
      {
        headers: {
          'Member-Id': '0',
        },
      },
    )

    return { presets: response.data.presets }
  },
)

export const deletePreset = createAsyncThunk('sorting/deletePreset', async ({ presetId, type }: { presetId: string; type: string }) => {
  const presetsUrl = `timusers/presets/${presetId}`

  const response = await axios.delete(USERS_API + presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const sortingSlice = createSlice({
  name: 'sorting',
  extraReducers: (builder) => {
    builder
      .addCase(fetchSortingColumns.pending, (state) => {
        state.status = 'loading'
        state.data = []
      })
      .addCase(fetchSortingColumns.fulfilled, (state, action) => {
        state.status = 'idle'
        state.columns = action.payload.columns
        if (action.payload.type === 'members') {
          const nameColumn = action.payload.columns.find((element: TAny) => element.columnId === 'full_name')

          state.data = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
          state.currentData = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
        }
        if (action.payload.type === ModerationTabs.HIVE) {
          const nameColumn = action.payload.columns.find((element: TAny) => element.columnId === 'hive_task_created_date')

          state.data = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
          state.currentData = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
        }
        if (action.payload.type === ModerationTabs.PROFILE_COMPLAINTS) {
          const nameColumn = action.payload.columns.find((element: TAny) => element.columnId === 'complaint_task_created_date')

          state.data = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
          state.currentData = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
        }
        if (action.payload.type === ModerationTabs.CONTENT_COMPLAINTS) {
          const nameColumn = action.payload.columns.find((element: TAny) => element.columnId === 'complaint_task_created_date')

          state.data = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
          state.currentData = [{ title: nameColumn.title, columnId: nameColumn.columnId, sortingType: 'ASC' }]
        }
      })
      .addCase(fetchSortingColumns.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchSortingPresets.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSortingPresets.fulfilled, (state, action) => {
        state.status = 'idle'
        state.presets = action.payload.presets
      })
      .addCase(fetchSortingPresets.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: TAny[] = []

        action.payload.sortingList.forEach((item: TAny) => {
          const object = { ...action.payload.columns.find((col: TAny) => col.columnName === item.columnId) }

          object.sortingType = item.sortingType
          object.label = object.title
          object.id = object.columnName
          newData.push(object)
        })
        state.data = newData
        state.activePresetId = action.payload.presetId
      })
  },
  initialState,
  reducers: {
    addLevel: (state) => {
      state.currentData.push({
        id: '',
        label: '',
        title: '',
        columnId: null,
        sortingType: 'ASC',
      })
    },
    changeSortingField: (state, action) => {
      state.currentData[action.payload.index] = {
        sortingType: state.currentData[action.payload.index].sortingType,
        ...action.payload.newValue,
        columnId: action.payload.newValue?.columnId,
      }
    },
    changeSortingType: (state, action) => {
      state.currentData[action.payload.index].sortingType = action.payload.sortingType
    },
    clearSorting: (state) => {
      state.columns = []
    },
    clearSortingColumns: (state) => {
      state.columns = []
    },
    clearSortingData: (state) => {
      state.data = []
      state.currentData = []
    },
    removeLevel: (state, action) => {
      state.currentData.splice(action.payload.index, 1)
    },
    removeSortingField: (state, action) => {
      state.currentData.splice(action.payload, 1)
    },
    reverseSortingData: (state) => {
      state.currentData = [...state.data]
    },
    saveSortingData: (state) => {
      state.data = [...state.currentData]
    },
    setNewSortingArray: (state, action) => {
      state.data = action.payload
    },
    setSortingCurrentData: (state, action) => {
      state.currentData = action.payload
    },
    setSortingData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const {
  addLevel,
  changeSortingField,
  changeSortingType,
  clearSortingColumns,
  clearSortingData,
  removeLevel,
  removeSortingField,
  reverseSortingData,
  saveSortingData,
  setSortingCurrentData,
  setSortingData,
} = sortingSlice.actions

export const selectSortingColumns = (state: TRootState): ISortingItem[] => state.sorting.columns
export const selectSortingData = (state: TRootState): ISortingItem[] => state.sorting.data
export const selectSortingCurrentData = (state: TRootState): ISortingItem[] => state.sorting.currentData
export const selectSortingPresets = (state: TRootState): IPreset[] => state.sorting.presets
export const selectSortingStatus = (state: TRootState): TStatus => state.sorting.status

export default sortingSlice.reducer
