import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Typography from '@admin/components/shared/Typography/Typography'

interface IConfirmCancelModalProps {
  closeModal: () => void
  onClose: () => void
  open: boolean
}

export const ConfirmCancelModal = ({ closeModal, onClose, open }: IConfirmCancelModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">Exit without saving data?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">You entered data into a form. Data will be erased upon exit</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => {
            closeModal()
            onClose()
          }}
          color="primary"
          variant="contained"
        >
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
