import CardType from '@admin/components/Moderation/types/CardType'
import ModerationTabs from '@admin/components/Moderation/types/ModerationTabs'
import getConfirmedHiveViolations from '@admin/components/Moderation/utils/getConfirmedHiveViolations'
import groupComplaints from '@admin/components/Moderation/utils/groupComplaints'
import { ContentType } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

const getRenderCardProps = (props: TAny, tabValue: ModerationTabs): TAny => {
  switch (tabValue) {
    case ModerationTabs.CONTENT_COMPLAINTS:
      return {
        amountViolations: props.complaints?.length,
        cardType: CardType.CONTENT,
        complaints: groupComplaints(props.complaints),
        complaintsModerator: props.complaintsModerator,
        confirmedComplaintsViolations: [],
        createdDate: props.complaintTaskCreatedDate,
        entityId: props.entityInfo?.entityId,
        entityInfo: props.entityInfo,
        entityOwnerInfo: props.entityOwnerInfo,
        entityType: props.entityInfo?.entityType?.toLowerCase(),
        violationsFrom: props.complaints?.length > 1 ? 'complaints from users' : 'complaint from users',
      }
    case ModerationTabs.HIVE: {
      const amountViolations = props.moderationJobResults?.reduce((sum: number, result: TAny) => sum + result.metrics?.length, 0)

      return {
        amountViolations: amountViolations,
        cardType: CardType.HIVE,
        confirmedHiveViolations: props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
        createdDate: props.hiveTaskCreatedDate,
        entityId: props.entityInfo?.entityId,
        entityInfo: props.entityInfo,
        entityOwnerInfo: props.entityOwnerInfo,
        entityType: props.entityInfo?.entityType?.toLowerCase(),
        hiveModerator: props.hiveModerator,
        manualMode: props.manualMode,
        moderationJobResults: props.moderationJobResults
          ?.filter((element: TAny) => {
            return element.status === 'NEEDS_REVIEW'
          })
          .map((result: TAny) => {
            return {
              ...result,
              contentType: result.contentType?.toUpperCase() === ContentType.PHOTO ? 'Image' : result.contentType?.toLowerCase(),
            }
          }),
        profileInfo: props.profileInfo,
        violationsFrom: `${amountViolations > 1 ? 'violations from HIVE' : 'violation from HIVE'}`,
      }
    }
    case ModerationTabs.PROFILE_COMPLAINTS:
      return {
        amountViolations: props.complaints?.length,
        cardType: CardType.PROFILE,
        complaints: groupComplaints(props.complaints),
        complaintsModerator: props.complaintsModerator,
        confirmedComplaintsViolations: [],
        createdDate: props.complaintTaskCreatedDate,
        entityId: props.profileInfo?.profileId,
        entityType: 'User',
        profileInfo: props.profileInfo,
        violationsFrom: props.complaints?.length > 1 ? 'complaints from users' : 'complaint from users',
      }
    case ModerationTabs.CONFIRMED_VIOLATIONS:
      if (props.hiveTaskCreatedDate && !props.lastComplaints) {
        const amountViolations = props.moderationJobResults?.reduce((sum: number, result: TAny) => sum + result.metrics?.length, 0)

        return {
          amountViolations: amountViolations,
          cardType: CardType.HIVE,
          complaintsModerator: props.complaintsModerator,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedHiveViolations: props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.hiveTaskCreatedDate,
          entityId: props.entityInfo?.entityId,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          hiveModerator: props.hiveModerator,
          hiveModeratorId: props.hiveModeratorId,
          hiveViolationConfirmed: props.hiveViolationConfirmed,
          manualMode: props.manualMode,
          moderationJobResults: props.moderationJobResults?.map((result: TAny) => {
            return {
              ...result,
              contentType: result.contentType?.toUpperCase() === ContentType.PHOTO ? 'Image' : result.contentType?.toLowerCase(),
            }
          }),
          violationsFrom: `${amountViolations > 1 ? 'violations from HIVE' : 'violation from HIVE'}`,
        }
      } else if (props.sourceType === 'CONTENT') {
        return {
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          cardType: CardType.CONTENT,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          complaintsModerator: props.complaintsModerator,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedHiveViolations: props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.complaintTaskCreatedDate,
          entityId: props.entityInfo?.entityId,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          hiveModerator: props.hiveModerator,
          hiveModeratorId: props.hiveModeratorId,
          manualMode: props.lastComplaints?.length > 0 ? false : props.manualMode,
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
        }
      } else {
        return {
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          cardType: CardType.PROFILE,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          complaintsModerator: props.complaintsModerator,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.complaintTaskCreatedDate,
          entityId: props.profileInfo?.profileId,
          entityType: 'User',
          manualMode: props.manualMode,
          profileInfo: props.profileInfo,
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
        }
      }
    case ModerationTabs.DECLINED_REQUESTS:
      if (props.hiveTaskCreatedDate && !props.lastComplaints) {
        const amountViolations = props.moderationJobResults?.reduce((sum: number, result: TAny) => sum + result.metrics?.length, 0)

        return {
          amountViolations: amountViolations,
          cardType: CardType.HIVE,
          confirmedHiveViolations: props.hiveViolationConfirmed === true ? getConfirmedHiveViolations(props.moderationJobResults) : [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.hiveTaskCreatedDate,
          entityId: props.entityInfo?.entityId,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          hiveModerator: props.hiveModerator,
          hiveModeratorId: props.hiveModeratorId,
          manualMode: props.manualMode,
          moderationJobResults: !props.manualMode
            ? props.moderationJobResults
                ?.filter((result: TAny) => result?.metrics?.length !== 0)
                .map((result: TAny) => {
                  return {
                    ...result,
                    contentType: result.contentType?.toUpperCase() === ContentType.PHOTO ? 'Image' : result.contentType?.toLowerCase(),
                  }
                })
            : props.moderationJobResults?.map((result: TAny) => {
                return {
                  ...result,
                  contentType: result.contentType?.toUpperCase() === ContentType.PHOTO ? 'Image' : result.contentType?.toLowerCase(),
                }
              }),
          violationsFrom: `${amountViolations > 1 ? 'violations from HIVE' : 'violation from HIVE'}`,
        }
      } else if (props.sourceType === 'CONTENT') {
        return {
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          cardType: CardType.CONTENT,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          complaintsModerator: props.complaintsModerator,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.complaintTaskCreatedDate,
          entityId: props.entityInfo?.entityId,
          entityInfo: props.entityInfo,
          entityOwnerInfo: props.entityOwnerInfo,
          entityType: props.entityInfo?.entityType?.toLowerCase(),
          manualMode: props.manualMode,
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
        }
      } else {
        return {
          amountViolations: props.complaints?.length || props.lastComplaints?.length,
          cardType: CardType.PROFILE,
          complaints: groupComplaints(props.complaints || props.lastComplaints),
          complaintsModerator: props.complaintsModerator,
          confirmedComplaintsViolations: props.confirmedComplaintsViolations ?? [],
          confirmedManualModeViolations: props.confirmedManualModeViolations ?? [],
          createdDate: props.complaintTaskCreatedDate,
          entityId: props.profileInfo?.profileId,
          entityType: 'User',
          manualMode: props.manualMode,
          profileInfo: props.profileInfo,
          violationsFrom:
            (props.complaints?.length ? props.complaints?.length : props.lastComplaints?.length) > 1
              ? 'complaints from users'
              : 'complaint from users',
        }
      }
  }
}

export default getRenderCardProps
