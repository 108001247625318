import './index.scss'

import { useMemo } from 'react'

import { format } from 'date-fns'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'

import CalendarIcon from '@admin/assets/img/CalendarIcon'
import CameraIcon from '@admin/assets/img/CameraIcon'
import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import CloseIcon from '@admin/assets/img/CloseIcon'
import FlashIcon from '@admin/assets/img/FlashIcon'
import LikeIcon from '@admin/assets/img/LikeIcon'
import LocationIcon from '@admin/assets/img/LocationIcon'
import MoneyIcon from '@admin/assets/img/MoneyIcon'
import WorldSendIcon from '@admin/assets/img/WorldSendIcon'
import ContentDisplayInfoBlock from '@admin/components/ContentDisplay/ContentDisplayInfoBlock/ContentDisplayInfoBlock'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Typography from '@admin/components/shared/Typography/Typography'
import useExpandableDescription from '@admin/hooks/useExpandableDescription'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'
import { Permissions } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import WishCard from './WishCard'

const Wish = (props: TAny) => {
  const data = props.data
  const publishDate = data?.publishedAt ? new Date(data.publishedAt) : null
  const userInfo = data.userInfoDto ?? {}

  const { isExpanded, renderedDescription, toggleExpansion } = useExpandableDescription(data.description)

  const userPermissions = useAppSelector(selectUserPermissions)
  const showTopSearchSelectionButton = checkPermissions(userPermissions, [Permissions.WORLD_MANAGEMENT_WORLD_SELECTION])

  const priceOption = useMemo(() => {
    if (data.priceOption === 'FREE')
      return {
        price: '$0',
        priceOption: 'Free',
      }
    if (data.priceOption === 'FLEXIBLE')
      return {
        price: `Up to ${currencyFormatter(data.price, 'en-US', 'USD')}`,
        priceOption: 'Flexible price',
      }
    if (data.priceOption === 'FIXED')
      return {
        price: `${currencyFormatter(data.price, 'en-US', 'USD')}`,
        priceOption: 'Fixed price',
      }

    return {
      price: '$0',
      priceOption: 'Free',
    }
  }, [data.priceOption, data.price])

  const timeOption = useMemo(() => {
    const result = {
      date: 'Open date',
      duration: 'Duration flexible',
    }

    if (data.startedAt && data.finishedAt) {
      result.date = dayjs(data.startedAt).format('MM/DD/YYYY') + ' - ' + dayjs(data.finishedAt).format('MM/DD/YYYY')
    }
    if (data.sessionDuration) {
      const durationHours = Math.floor(data.sessionDuration / 60)
      const durationMinutes = data.sessionDuration % 60

      result.duration = `Duration:${durationHours > 0 ? ` ${durationHours} hour${durationHours > 1 ? 's' : ''}` : ''}${
        durationMinutes > 0 ? ` ${durationMinutes} minute${durationMinutes > 1 ? 's' : ''}` : ''
      }`
    }

    return result
  }, [data])

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`

    return 'Location'
  }, [data.locationInfo])

  const locationText = useMemo(() => {
    const { locationCity, locationCountry } = data.locationInfo
    const parts = [locationCity, locationCountry].filter(Boolean)

    return parts.length > 0 ? parts.join(', ') : 'Not specified'
  }, [data.locationInfo])

  return (
    <div className={'wish--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="wish--containerHeader">
        <div className="wish--eventName">
          <div className="wish--eventId">Wish #{data.entityId}</div>
          {publishDate && (
            <div className="wish--eventPublishDate">
              Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
            </div>
          )}
        </div>
        {props.modalClose ? (
          <div className="wish--eventActions">
            <IconButton onClick={() => props.modalClose()} size="small" sx={{ margin: '-4px -12px 0 0' }}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className="wish--eventActions">
            {showTopSearchSelectionButton && (
              <Button
                onClick={() => props.linkCollections({ entityId: data.entityId, entityType: data.entityType })}
                size="small"
                startIcon={<WorldSendIcon />}
                variant="outlined"
                disabled
              >
                Top Search
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="wish--content">
        <div className="wishContent--card">
          <WishCard data={data} />
        </div>
        <div className="wishContent--descriptionContainer">
          <AvatarItem
            onClick={() => props.openUser(userInfo.userId || '1')}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            src={userInfo.avatarUrl}
            subText={`@${userInfo.username}`}
          />
          <div>{data.title}</div>
          <div className="wishContent--description">
            <Typography className="text" color="text.secondary" component="span" variant="body2">
              {renderedDescription}{' '}
            </Typography>
            {data.description && data.description.length > 250 && (
              <Typography
                onClick={(event) => {
                  event.stopPropagation()
                  toggleExpansion()
                }}
                className="trigger"
                component="span"
              >
                <Typography component="span" variant="body2">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Typography>
                {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
              </Typography>
            )}
          </div>
          <div className="wishContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
          </div>
        </div>
        <div className="wishContent--passions">
          <div className="wishContent--passionsTitle">Passions</div>
          <div className="wishContent--passionsChips">
            {data.passions.length > 0 ? (
              data.passions.map((element: TAny) => <Chip key={element} label={element} size="small" variant="filled" />)
            ) : (
              <Typography color={colors.outlinedBorder} variant="body2">
                Not specified
              </Typography>
            )}
          </div>
        </div>
        <div className="wishContent--terms">
          {data.entityStatus && (
            <ContentDisplayInfoBlock icon={<FlashIcon />} primary={startCase(data.entityStatus.toLowerCase())} secondary="Status" />
          )}
          {data.priceOption && <ContentDisplayInfoBlock icon={<MoneyIcon />} primary={priceOption.price} secondary={priceOption.priceOption} />}
          {<ContentDisplayInfoBlock icon={<CalendarIcon />} primary={timeOption.date} secondary={timeOption.duration} />}
          {data.format === 'OFFLINE' && (
            <ContentDisplayInfoBlock icon={<LocationIcon />} primary={locationText} secondary={locationHeader} withTooltip />
          )}
          {data.format === 'ONLINE' && <ContentDisplayInfoBlock icon={<CameraIcon />} primary="Online" secondary="Format" />}
        </div>
      </div>
    </div>
  )
}

export default Wish
