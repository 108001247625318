export const COMMON_CLASSIFIER_API = '/common/tim-common-classifier-facade-api/api/v1/'
export const USERS_API = '/moderation/tim-users/api/v1/'
export const AUTH_MEMBERS_API = '/moderation/tim-members/public/api/v1/'
export const MEMBERS_API = '/moderation/tim-members/api/v1/'
export const CONTENT_DISPLAY_API = '/moderation/content-display/api/v1/'
export const MODERATION_API = '/moderation/moderation-screen-service/api/v1/'
export const MODERATION_RULES_API = '/moderation/tim-admin-moderation-rules-api/api/v1/'
export const WORLD_MANAGEMENT_API = '/moderation/tim-admin-tim-world-service-api/api/v1/'
export const PAYMENTS_API = '/moderation/tim-admin-payment-adapter-service-api/api/v1/'
export const TAXES_API = '/moderation/tim-admin-sales-tax-rule-service-api/api/v1/'
export const OPERATIONS_API = '/moderation/tim-admin-operation-adapter/api/v1/'
