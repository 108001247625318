import { memo, useLayoutEffect, useRef } from 'react'

import usePrevious from '@admin/hooks/usePrevious'

export interface ISingleOTPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean
}

export function SingleOTPInputComponent(props: ISingleOTPInputProps) {
  const { autoFocus, focus, ...rest } = props
  const inputReference = useRef<HTMLInputElement>(null)
  const previousFocus = usePrevious(!!focus)

  useLayoutEffect(() => {
    if (inputReference.current) {
      if (focus && autoFocus) {
        inputReference.current.focus()
      }
      if (focus && autoFocus && focus !== previousFocus) {
        inputReference.current.focus()
        inputReference.current.select()
      }
    }
  }, [autoFocus, focus, previousFocus])

  return <input ref={inputReference} {...rest} />
}

const SingleOTPInput = memo(SingleOTPInputComponent)

export default SingleOTPInput
