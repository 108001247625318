import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import withAuth from '@admin/components/Authorization/withAuth'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Typography from '@admin/components/shared/Typography/Typography'
import { ADMIN_EMAIL } from '@admin/shared/constants/info'

const NotFound = () => {
  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address has been copied to clipboard', { variant: 'success' as VariantType })

      return navigator.clipboard.writeText(ADMIN_EMAIL)
    }
  }

  return (
    <div className="ErrorPage">
      <header className="ErrorPage-header">
        <Typography variant="errorTitle">Error 404</Typography>
        <Typography color="text.secondary" variant="errorSubtitle">
          Not found
        </Typography>
      </header>
      <div className="ErrorPage-content">
        <div className={`ErrorPage-content-image ErrorPage-content-image-404`} />
        <div className="ErrorPage-content-description">
          <Typography className="ErrorPage-content-descriptionTitle" variant="h5">
            Page you have requested does not exist
          </Typography>
          <Typography className="ErrorPage-content-descriptionInfo" color="text.secondary" variant="body1">
            The error may be caused by typo in the link or page was deleted. <br />
            Try to double-check the address or choose other module from menu bar.
          </Typography>
          <Typography color="text.secondary" variant="body1">
            If you think it’s a mistake, contact to admin:
            <Typography variant="alertTitle">{ADMIN_EMAIL} </Typography>
            <IconButton className="ErrorPage-content-descriptionInfo-button" onClick={() => void copyEmail()} size="small">
              <CopyIcon color="disabled" fontSize="small" />
            </IconButton>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default withAuth(NotFound)
