import { ChangeEvent, forwardRef, Key, ReactNode, useEffect, useState } from 'react'

import { Fade, Paper, Popper, useAutocomplete } from '@mui/material'
import { useDebounce } from '@uidotdev/usehooks'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import isEmpty from 'lodash/isEmpty'

import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Divider from '@admin/components/shared/Divider/Divider'
import List from '@admin/components/shared/List/List'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemButton from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { clearFilters, removeFilter, selectFiltersCurrentData, setCustomFilterValue } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { IOperationSearchItem } from '@admin/store/slices/Payments/interface'
import {
  clearOperationsSearch,
  fetchOperationsSearch,
  selectOperationsSearchAllResults,
  selectOperationsSearchResults,
} from '@admin/store/slices/Payments/operationsSlice'

import type { TAny } from '@yzzy/types'

interface IOperationsSearchProps {
  clearFilter?: () => Promise<void>
  refreshData?: () => Promise<void>
}

export const OperationsSearch = ({ clearFilter, refreshData }: IOperationsSearchProps) => {
  const dispatch = useAppDispatch()

  const SEARCH_FILTER = {
    title: 'ID',
    columnId: 'id',
    elements: [],
    type: 'IN',
    valueType: 'STRING',
  }

  const SEARCH_FILTER_ALL = {
    title: 'Operation search',
    columnId: 'operation_search',
    customImplementation: true,
    element: {
      titles: ['Operation ID'],
      value: '',
    },
    type: 'EQUALS',
    valueType: 'STRING',
  }

  const operationsSearchResults = useAppSelector(selectOperationsSearchResults)
  const operationsSearchAllResults = useAppSelector(selectOperationsSearchAllResults)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isSearchError, setIsSearchError] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue.trim(), 300)

  const { getInputProps, getListboxProps, getOptionProps, getRootProps, inputValue } = useAutocomplete({
    id: 'search-autocomplete',
    disableCloseOnSelect: true,
    getOptionLabel: (option: IOperationSearchItem) => option.operationId,
    inputValue: searchValue,
    options: operationsSearchResults ? operationsSearchResults : [],
  })

  const hasFiltersToClear = clearFilter && refreshData && (!isEmpty(filtersCurrentData.id) || !isEmpty(filtersCurrentData.operation_search))

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    setSearchValue(inputValue)

    if ((inputValue.length === 0 || inputValue.length < 3) && operationsSearchResults.length !== 0) {
      dispatch(clearOperationsSearch())
    }

    if (inputValue.length === 0 && hasFiltersToClear) {
      dispatch(clearOperationsSearch())
      dispatch(removeFilter({ columnId: 'operation_search' }))
      void clearFilter()
      void refreshData()
    }
  }

  const handleClearEvent = () => {
    dispatch(clearOperationsSearch())
    setSearchValue('')

    if (hasFiltersToClear) {
      dispatch(removeFilter({ columnId: 'operation_search' }))
      void clearFilter()
      void refreshData()
    }
  }

  const handleSearchEvent = async () => {
    setIsLoadingSearch(true)

    try {
      const result = await dispatch(fetchOperationsSearch({ searchValue: debouncedSearchValue }))

      if (result.meta.requestStatus === 'fulfilled') {
        setIsSearchError(false)
        setIsLoadingSearch(false)
      } else if (result.meta.requestStatus === 'rejected') {
        setIsSearchError(true)
        setIsLoadingSearch(false)
      }
    } catch {
      setIsSearchError(true)
      setIsLoadingSearch(false)
    }
  }

  const handleListItemButtonClick = (id: string, uid: string) => {
    const isUidMatch = uid.toLowerCase().includes(inputValue.toLowerCase())

    if (isUidMatch) {
      setSearchValue(uid)
    } else {
      setSearchValue(id)
    }
    setAnchorElement(null)
    ;(SEARCH_FILTER.elements as { value: string }[]) = [{ value: id }]
    dispatch(removeFilter({ columnId: 'operation_search' }))
    dispatch(clearFilters())
    dispatch(setCustomFilterValue(SEARCH_FILTER))

    if (refreshData) {
      void refreshData()
    }
  }

  const handleAllResultsClick = () => {
    // eslint-disable-next-line sonarjs/no-useless-react-setstate
    setSearchValue(searchValue)
    setAnchorElement(null)

    SEARCH_FILTER_ALL.element.value = searchValue
    dispatch(clearFilters())
    dispatch(setCustomFilterValue(SEARCH_FILTER_ALL))
    if (refreshData) {
      void refreshData()
    }
  }

  const renderOptions = (option: IOperationSearchItem, index: number): ReactNode => {
    const matchesID = match(option.operationId, inputValue, { insideWords: true })
    const partsID = parse(option.operationId, matchesID)

    const matchesUID = match(option.userFriendlyOperationId, inputValue, { insideWords: true })
    const partsUID = parse(option.userFriendlyOperationId, matchesUID)

    return (
      <ListItem {...getOptionProps({ index, option })} key={option.operationId} disablePadding>
        <ListItemButton onClick={() => handleListItemButtonClick(option.operationId, option.userFriendlyOperationId)}>
          <ListItemText
            primary={
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography width="20px" color="text.secondary" variant="caption">
                  UID
                </Typography>
                <Typography
                  sx={{
                    wordBreak: 'break-all',
                  }}
                  variant="body2"
                >
                  {partsUID.map((part, index: Key) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Stack>
            }
            secondary={
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography width="20px" color="text.secondary" variant="caption">
                  ID
                </Typography>
                <Typography
                  sx={{
                    wordBreak: 'break-all',
                  }}
                  variant="body2"
                >
                  {partsID.map((part, index: Key) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Stack>
            }
            disableTypography
          />
        </ListItemButton>
      </ListItem>
    )
  }

  const renderContent = () => {
    if (isSearchError) {
      return (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                    component="p"
                    variant="body2Bold"
                  >
                    {inputValue} {operationsSearchResults.length > 0 ? `(${operationsSearchResults.length})` : ''}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" component="p" variant="caption">
                    All results
                  </Typography>
                }
                sx={{ m: 0 }}
                disableTypography
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />
          <Stack alignItems="center" gap={1} pt={1}>
            <Typography color="error" variant="body2">
              Loading results error
            </Typography>
            <LoadingButton
              color="primary"
              loading={isLoadingSearch}
              loadingPosition="start"
              onClick={handleSearchEvent}
              startIcon={null}
              sx={{ minWidth: 105, p: 0 }}
              variant="text"
            >
              Retry
            </LoadingButton>
          </Stack>
        </>
      )
    }

    if (isLoadingSearch) {
      return (
        <Stack alignItems="center" p="8px 0">
          <CircularProgress color="primary" size={24} sx={{ margin: 'auto' }} />
        </Stack>
      )
    }

    if (inputValue.length > 2 && operationsSearchResults && operationsSearchResults.length > 0) {
      return (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                    component="p"
                    variant="body2Bold"
                  >
                    {`${inputValue} (${operationsSearchAllResults.length})`}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" component="p" variant="caption">
                    All results
                  </Typography>
                }
                sx={{ m: 0 }}
                disableTypography
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />
          {operationsSearchResults.map((result, index) => renderOptions(result, index))}
        </>
      )
    }

    if (inputValue.length < 3) {
      return (
        <Typography color="text.primary" p="8px 0" textAlign="center" variant="body2">
          Enter at least 3 symbols
        </Typography>
      )
    }

    return (
      <Stack alignItems="center" gap={1} p="8px 0">
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    )
  }

  const RenderList = forwardRef<TAny, {}>((_, reference) => {
    return (
      <List {...getListboxProps()} ref={reference}>
        {renderContent()}
      </List>
    )
  })

  useEffect(() => {
    if (isEmpty(filtersCurrentData)) {
      setSearchValue('')
      dispatch(clearOperationsSearch())
    }
  }, [filtersCurrentData])

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      void handleSearchEvent()
    }
  }, [debouncedSearchValue])

  return (
    <div id="operations-search" {...getRootProps()}>
      <SearchField
        customWidth="476px"
        label="Search by user operation ID or operation ID"
        clearEvent={() => handleClearEvent()}
        inputProps={{ ...getInputProps() }}
        maxlength={50}
        onBlur={() => setAnchorElement(null)}
        onChange={handleChangeSearch}
        onClick={(event) => setAnchorElement(event.currentTarget)}
        searchEvent={handleSearchEvent}
        searchValue={inputValue}
      />

      <Popper
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        placement="bottom-start"
        sx={{ width: anchorElement?.offsetWidth || 476, borderRadius: '4px', zIndex: 1200 }}
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Paper elevation={4} sx={{ backgroundColor: 'background.paper', maxHeight: '393px', overflow: 'auto' }}>
              <RenderList />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}
