const EyeClosedIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M22.601 2.60107C22.933 2.26912 22.933 1.73093 22.601 1.39898C22.2691 1.06704 21.7309 1.06704 21.399 1.39898L17.9695 4.8284C16.1731 3.5746 14.1291 2.88 12 2.88C8.10963 2.88 4.57754 5.17375 2.17317 8.95323C1.61528 9.82756 1.36501 10.9421 1.36501 12.005C1.36501 13.0679 1.61527 14.1824 2.17313 15.0567C2.91245 16.2172 3.76448 17.2433 4.69556 18.1024L1.39895 21.399C1.06701 21.731 1.06701 22.2691 1.39895 22.6011C1.7309 22.933 2.26909 22.933 2.60103 22.6011L10.071 15.1311L10.0786 15.1235L15.1248 10.0772C15.1269 10.0752 15.129 10.0731 15.131 10.0711L22.601 2.60107ZM14.4726 8.32538L16.7454 6.05256C15.2724 5.08826 13.6531 4.58 12 4.58C8.83057 4.58 5.78282 6.44603 3.6072 9.86622L3.60649 9.86733C3.26463 10.4029 3.06501 11.1782 3.06501 12.005C3.06501 12.8319 3.26463 13.6071 3.60649 14.1427C4.28653 15.2101 5.0639 16.1377 5.89875 16.8992L8.32545 14.4725C7.84863 13.767 7.57001 12.9177 7.57001 11.9999C7.57001 9.5505 9.55057 7.56995 12 7.56995C12.9178 7.56995 13.767 7.84857 14.4726 8.32538ZM9.27001 11.9999C9.27001 10.4894 10.4894 9.26995 12 9.26995C12.4452 9.26995 12.8633 9.37551 13.2335 9.56434L9.5644 13.2335C9.37557 12.8632 9.27001 12.4451 9.27001 11.9999Z"
        fillRule="evenodd"
      />
      <path d="M19.5173 7.26765C19.883 6.97343 20.4181 7.03145 20.7123 7.39724C21.0981 7.87681 21.4775 8.39271 21.8277 8.94454C22.385 9.81867 22.635 10.9326 22.635 11.995C22.635 13.0579 22.3847 14.1724 21.8268 15.0468C19.4225 18.8262 15.8904 21.12 12 21.12C10.6493 21.12 9.32747 20.8344 8.09015 20.3134C7.6575 20.1312 7.45444 19.6328 7.63661 19.2001C7.81878 18.7675 8.31719 18.5644 8.74985 18.7466C9.79253 19.1856 10.8907 19.42 12 19.42C15.1694 19.42 18.2172 17.554 20.3928 14.1338L20.3935 14.1327C20.7354 13.5971 20.935 12.8218 20.935 11.995C20.935 11.1681 20.7354 10.3929 20.3935 9.85732L20.3923 9.85544C20.0825 9.36726 19.7419 8.90316 19.3877 8.46273C19.0934 8.09694 19.1515 7.56188 19.5173 7.26765Z" />
      <path d="M16.3459 12.8541C16.431 12.3925 16.1258 11.9492 15.6641 11.8641C15.2025 11.779 14.7592 12.0842 14.6741 12.5459C14.478 13.6092 13.5992 14.488 12.5359 14.6841C12.0742 14.7692 11.769 15.2125 11.8541 15.6741C11.9392 16.1358 12.3825 16.441 12.8441 16.3559C14.6008 16.032 16.022 14.6108 16.3459 12.8541Z" />
    </svg>
  )
}

export default EyeClosedIcon
