import { createSvgIcon } from '@mui/material'

const LinkIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.8499 12.5009C3.8499 9.24136 4.38105 7.37544 5.2642 6.31596C6.10823 5.30341 7.43018 4.85088 9.5441 4.85088C10.0135 4.85088 10.3941 4.47032 10.3941 4.00088C10.3941 3.53144 10.0135 3.15088 9.5441 3.15088C7.22945 3.15088 5.2793 3.64279 3.95838 5.22747C2.67657 6.7652 2.1499 9.14928 2.1499 12.5009C2.1499 15.8321 2.6385 18.2871 4.29127 19.848C5.92798 21.3938 8.48894 21.8509 11.9999 21.8509C15.5332 21.8509 18.0006 21.3828 19.5957 20.3217C21.2844 19.1983 21.8499 17.52 21.8499 15.5723C21.8499 15.1029 21.4693 14.7223 20.9999 14.7223C20.5305 14.7223 20.1499 15.1029 20.1499 15.5723C20.1499 17.1643 19.7154 18.2003 18.6541 18.9063C17.4992 19.6745 15.4666 20.1509 11.9999 20.1509C8.51087 20.1509 6.57183 19.6635 5.45853 18.6121C4.3613 17.5758 3.8499 15.7808 3.8499 12.5009Z" />
    <path d="M15.3032 3.15088C14.8338 3.15088 14.4532 3.53144 14.4532 4.00088C14.4532 4.47032 14.8338 4.85088 15.3032 4.85088H18.7592L13.0683 10.5415C12.7364 10.8735 12.7364 11.4117 13.0683 11.7436C13.4003 12.0756 13.9384 12.0756 14.2704 11.7436L20.1285 5.88575V9.5686C20.1285 10.038 20.509 10.4186 20.9785 10.4186C21.4479 10.4186 21.8285 10.038 21.8285 9.5686V5.50088C21.8285 4.20301 20.7763 3.15088 19.4785 3.15088H15.3032Z" />
  </svg>,
  'Link',
)

export default LinkIcon
