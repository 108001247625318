import { createSvgIcon } from '@mui/material'

const CopyIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.14999 7.14999V6.89999C7.14999 5.06616 7.51156 3.56135 8.53645 2.53645C9.56135 1.51156 11.0662 1.14999 12.9 1.14999H17.1C18.9338 1.14999 20.4386 1.51156 21.4635 2.53645C22.4884 3.56135 22.85 5.06616 22.85 6.89999V11.1C22.85 12.9338 22.4884 14.4386 21.4635 15.4635C20.4386 16.4884 18.9338 16.85 17.1 16.85H16.85V17.1C16.85 18.9338 16.4884 20.4386 15.4635 21.4635C14.4386 22.4884 12.9338 22.85 11.1 22.85H6.89999C5.06616 22.85 3.56135 22.4884 2.53645 21.4635C1.51156 20.4386 1.14999 18.9338 1.14999 17.1V12.9C1.14999 11.0662 1.51156 9.56135 2.53645 8.53645C3.56135 7.51156 5.06616 7.14999 6.89999 7.14999H7.14999ZM8.84999 6.89999C8.84999 5.23383 9.18843 4.28864 9.73853 3.73853C10.2886 3.18843 11.2338 2.84999 12.9 2.84999H17.1C18.7662 2.84999 19.7114 3.18843 20.2615 3.73853C20.8116 4.28864 21.15 5.23383 21.15 6.89999V11.1C21.15 12.7662 20.8116 13.7114 20.2615 14.2615C19.7114 14.8116 18.7662 15.15 17.1 15.15H16.85V12.9C16.85 11.0662 16.4884 9.56135 15.4635 8.53645C14.4386 7.51156 12.9338 7.14999 11.1 7.14999H8.84999V6.89999ZM6.89999 8.84999C5.23383 8.84999 4.28864 9.18843 3.73853 9.73853C3.18843 10.2886 2.84999 11.2338 2.84999 12.9V17.1C2.84999 18.7662 3.18843 19.7114 3.73853 20.2615C4.28864 20.8116 5.23383 21.15 6.89999 21.15H11.1C12.7662 21.15 13.7114 20.8116 14.2615 20.2615C14.8116 19.7114 15.15 18.7662 15.15 17.1V12.9C15.15 11.2338 14.8116 10.2886 14.2615 9.73853C13.7114 9.18843 12.7662 8.84999 11.1 8.84999H6.89999Z"
      fillRule="evenodd"
    />
  </svg>,
  'CopyIcon',
)

export default CopyIcon
