import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import Button from '@admin/components/shared/Button/Button'

import type { TAny } from '@yzzy/types'

/**
 * Props for the RenderActionCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderSolutionCell component.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderActionCellProps {
  onClick: (event: MouseEvent<HTMLButtonElement>, data: TAny) => void
  props: GridRenderCellParams
}

/**
 * Renders an action cell in a data grid.
 */
export const RenderActionCell = ({ onClick, props }: IRenderActionCellProps) => {
  const { row } = props

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    onClick(event, row)
  }

  if (!row.consumptionRequestId) {
    return null
  }

  return (
    <Button onClick={handleClick} size="small" variant="contained" disableElevation disableRipple>
      Resolve
    </Button>
  )
}
