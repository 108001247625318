const ArchiveIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0297 6.2561C9.69771 5.92416 9.15952 5.92416 8.82757 6.2561C8.49563 6.58805 8.49563 7.12624 8.82757 7.45819L11.399 10.0296C11.7309 10.3616 12.2691 10.3616 12.6011 10.0296L15.1725 7.45819C15.5045 7.12624 15.5045 6.58805 15.1725 6.2561C14.8406 5.92416 14.3024 5.92416 13.9704 6.2561L12.85 7.37649V1.71429C12.85 1.24485 12.4695 0.864288 12 0.864288C11.5306 0.864288 11.15 1.24485 11.15 1.71429V7.37649L10.0297 6.2561Z" />
    <path
      clipRule="evenodd"
      d="M4.7175 4.99917C5.13463 3.88682 6.19802 3.14988 7.38603 3.14988H9.42861C9.89805 3.14988 10.2786 3.53044 10.2786 3.99988C10.2786 4.46932 9.89805 4.84988 9.42861 4.84988H7.38603C6.90667 4.84988 6.47758 5.14724 6.30925 5.59609L4.2267 11.1496H7.49279C8.55863 11.1496 9.50752 11.7813 9.96389 12.7397L10.3395 13.5285C10.5285 13.9253 10.8987 14.1496 11.2758 14.1496H12.8887C13.2225 14.1496 13.5521 13.9745 13.7559 13.6534L14.5298 12.4346C15.0324 11.643 15.8925 11.1496 16.8323 11.1496H19.7734L17.6909 5.59615C17.5225 5.14726 17.0934 4.84988 16.614 4.84988H14.5715C14.102 4.84988 13.7215 4.46932 13.7215 3.99988C13.7215 3.53044 14.102 3.14988 14.5715 3.14988H16.614C17.8021 3.14988 18.8654 3.88684 19.2826 4.99911L21.479 10.8562C21.7294 11.5241 21.838 12.2369 21.7978 12.949L21.6067 16.3332L21.6051 16.3558C21.5122 17.4721 21.0777 18.3679 20.3916 19.0556C19.721 19.7276 18.8503 20.1616 17.9321 20.4492C16.1145 21.0186 13.8841 21.0799 12.0208 21.0828C10.1518 21.0857 7.90245 21.0327 6.07236 20.4662C5.14802 20.1801 4.27071 19.7459 3.59793 19.0694C2.90889 18.3766 2.47825 17.4726 2.39682 16.3466L2.39589 16.3338L2.20292 12.9509C2.16222 12.2377 2.27075 11.5238 2.52157 10.855L4.7175 4.99917ZM3.89991 12.8496H7.49279C7.86991 12.8496 8.2401 13.0738 8.42905 13.4706L8.80469 14.2594C9.26106 15.2178 10.2099 15.8496 11.2758 15.8496H12.8887C13.8285 15.8496 14.6885 15.3562 15.1911 14.5646L15.965 13.3458C16.1689 13.0246 16.4984 12.8496 16.8323 12.8496H20.1007L19.9101 16.2254C19.8479 16.9453 19.5822 17.4599 19.1881 17.8548C18.7766 18.2673 18.184 18.5889 17.4239 18.827C15.885 19.309 13.8996 19.3798 12.0182 19.3828C10.1296 19.3857 8.12586 19.3223 6.57505 18.8422C5.80895 18.6051 5.21387 18.2834 4.80329 17.8706C4.41007 17.4752 4.14675 16.9582 4.09284 16.2301L3.89991 12.8496Z"
      fillRule="evenodd"
    />
  </svg>
)

export default ArchiveIcon
