import authReducer from './authSlice'
import bannerDetailsReducer from './bannerDetailsSlice'
import cohortReducer from './cohortDetailsSlice'
import collectionDetailsReducer from './collectionDetailsSlice'
import columnsReducer from './columnsSlice'
import commonReducer from './commonSlice'
import contentDisplayReducer from './contentDisplaySlice'
import filtersReducer from './filtersSlice'
import membersReducer from './membersSlice'
import moderationMetricsReducer from './moderationMetricsSlice'
import moderationReducer from './moderationSlice'
import profileReducer from './profileSlice'
import rulesReducer from './rulesSlice'
import consumptionRequestsReducer from './slices/ConsumptionRequests/consumptionRequestsSlice'
import operationsReducer from './slices/Payments/operationsSlice'
import dictionariesReducer from './slices/Settings/dictionariesSlice'
import paymentFeesReducer from './slices/Settings/paymentFeesSlice'
import tariffsReducer from './slices/Settings/tariffsSlice'
import taxesReducer from './slices/Settings/taxesSlice'
import tiersReducer from './slices/Settings/tiersSlice'
import taxModerationReducer from './slices/TaxModeration/taxModerationSlice'
import sortingReducer from './sortingSlice'
import userProfileReducer from './userProfileSlice'
import usersReducer from './usersSlice'
import worldManagementReducer from './worldManagementSlice'

export const reducers = {
  common: commonReducer,
  auth: authReducer,
  bannerDetails: bannerDetailsReducer,
  cohortDetails: cohortReducer,
  collectionDetails: collectionDetailsReducer,
  columns: columnsReducer,
  consumptionRequests: consumptionRequestsReducer,
  contentDisplay: contentDisplayReducer,
  dictionaries: dictionariesReducer,
  filters: filtersReducer,
  members: membersReducer,
  metrics: moderationMetricsReducer,
  moderation: moderationReducer,
  operations: operationsReducer,
  paymentFees: paymentFeesReducer,
  profile: profileReducer,
  rules: rulesReducer,
  sorting: sortingReducer,
  tariffs: tariffsReducer,
  taxes: taxesReducer,
  taxModeration: taxModerationReducer,
  tiers: tiersReducer,
  userProfile: userProfileReducer,
  users: usersReducer,
  worldManagement: worldManagementReducer,
}
