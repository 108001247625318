import { useEffect, useState } from 'react'

import Button from '@admin/components/shared/Button/Button'
import Checkbox from '@admin/components/shared/Checkbox/Checkbox'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import Typography from '@admin/components/shared/Typography/Typography'
import { LocalStorageKeys } from '@admin/types/commonTypes'

interface IApprovalConfirmModalProps {
  onCardApproval: () => void
  onClose: () => void
  open: boolean
}

export const ApprovalConfirmModal = ({ onCardApproval, onClose, open }: IApprovalConfirmModalProps) => {
  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP)
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false

  const [showApprovalConfirmModal, setShowApprovalConfirmModal] = useState<boolean>(salesTaxApprovalConfirmSkip)

  const handleCloseModal = () => {
    setShowApprovalConfirmModal(false)
    localStorage.setItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP, JSON.stringify(false))
    onClose()
  }

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP, JSON.stringify(showApprovalConfirmModal))
  }, [showApprovalConfirmModal])

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">Approve sales tax?</DialogTitle>
      <DialogContent>
        <Typography mb={3} variant="body1">
          After the approval, you will still have the opportunity to change the sale tax, if necessary
        </Typography>
        <FormControl fullWidth>
          <FormControlLabel
            label="Don't show this confirmation again. Immediately approve"
            control={
              <Checkbox checked={showApprovalConfirmModal} onChange={(event) => setShowApprovalConfirmModal(event.target.checked)} disableRipple />
            }
            sx={{ pl: 1 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCloseModal} variant="text">
          Cancel
        </Button>
        <Button color="primary" onClick={onCardApproval} variant="contained">
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  )
}
