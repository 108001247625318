import './index.scss'

import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'

import { GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import { enqueueSnackbar, VariantType } from 'notistack'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import FilterIcon from '@admin/assets/img/FilterIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import RenderCellWithTooltip from '@admin/components/shared/DataGrid/RenderCellWithTooltip/RenderCellWithTooltip'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import SortingChip from '@admin/components/SortingChip/SortingChip'
import { possibleOperationTypes } from '@admin/containers/Payments/views/Operations/constants'
import { selectUserPermissions } from '@admin/store/authSlice'
import { clearFilters, fetchFilters, reverseFilters, selectFilters, selectFiltersCurrentData, selectFiltersData } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITier, ITierStatus } from '@admin/store/slices/Settings/interface'
import {
  fetchChangeStatus,
  fetchTiers,
  selectTiers,
  selectTiersStatus,
  selectTotalCount,
  setTiersStatus,
} from '@admin/store/slices/Settings/tiersSlice'
import { fetchSortingColumns, selectSortingColumns, setSortingData } from '@admin/store/sortingSlice'
import { Permissions } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderStatusSelect } from './components'
import { currencyTooltipTitles, defaultTiersSortingModel, possibleTierStatuses } from './constants'
import { AddTierModal } from './modals'

export const InAppTiers: FC = () => {
  const dispatch = useAppDispatch()
  const apiReference = useGridApiRef()

  const userPermissions = useAppSelector(selectUserPermissions)
  const data = useAppSelector(selectTiers)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectTiersStatus)
  const filters = useAppSelector(selectFilters)
  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const sortingColumns = useAppSelector(selectSortingColumns)
  const isLoading = status === 'loading'

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: TAny) => item !== null).length
  }, [filtersData])

  const { showBoundary } = useErrorBoundary()
  const [isAddingTier, setIsAddingTier] = useState(false)

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [tableMenuAnchorElement, setTableMenuAnchorElement] = useState<HTMLElement | null>(null)
  const tableMenu = Boolean(tableMenuAnchorElement)

  const [statusMenuAnchorElement, setStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const statusMenuOpen = Boolean(statusMenuAnchorElement)

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITierStatus | null>(null)
  const [currentSelectedTier, setCurrentSelectedTier] = useState<ITier | null>(null)
  const [highlightedTier, setHighlightedTier] = useState<null | { linkId: string; referenceName: string }>(null)

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: ITier) => {
    event.stopPropagation()
    setStatusMenuAnchorElement(event.currentTarget)
    setCurrentSelectedStatus(null)
    setCurrentSelectedTier(data)
  }

  const handleTableMenuClose = () => {
    setTableMenuAnchorElement(null)
  }

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorElement(null)
  }

  const handleChangeStatusClick = (data: ITierStatus) => {
    setCurrentSelectedStatus(data)
    handleStatusMenuClose()
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchTiersGrid(0, newPaginationModel.pageSize)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchTiersGrid(newPaginationModel.page, newPaginationModel.pageSize)

      return newPaginationModel
    })
  }

  const tableScrollTop = useCallback(() => {
    apiReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const fetchTiersInitial = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchTiers({ page, pageSize })).unwrap()
    } catch (error) {
      showBoundary(error)
    }
  }, [])

  const fetchTiersGrid = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchTiers({ page, pageSize })).unwrap()
      setFetchingError(false)
    } catch {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
      setFetchingError(true)
    }
  }, [])

  const repeatFetchingRequest = useCallback(async () => {
    fetchTiersGrid(paginationModel.page, paginationModel.pageSize)
  }, [paginationModel])

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters())
    await dispatch(fetchTiers({ page: paginationModel.page, pageSize: paginationModel.pageSize }))
  }, [])

  const initialFetchData = useCallback(async () => {
    dispatch(setTiersStatus('loading'))
    await dispatch(fetchFilters('tiers'))
    await dispatch(fetchSortingColumns('tiers'))
    if (filters && sortingColumns) {
      dispatch(setSortingData([defaultTiersSortingModel]))
      await fetchTiersInitial(paginationModel.page, paginationModel.pageSize)
    }
  }, [filters, sortingColumns, paginationModel])

  const handleChangeStatus = useCallback(async () => {
    const result = await dispatch(
      fetchChangeStatus({
        currentSelectedStatus: currentSelectedStatus,
        currentSelectedTier: currentSelectedTier,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      void fetchTiersGrid(0, paginationModel.pageSize)
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, {
        variant: 'error' as VariantType,
      })
    }
  }, [currentSelectedStatus, currentSelectedTier])

  const handleSortingModelApply = async () => {
    await fetchTiersGrid(paginationModel.page, paginationModel.pageSize)
  }

  const handleAddTierModalClose = (isAddedNewTier?: boolean, newTier?: ITier) => {
    setIsAddingTier(false)
    if (isAddedNewTier && newTier) {
      dispatch(fetchTiers({ page: paginationModel.page, pageSize: paginationModel.pageSize })).then(() => {
        if (!isLoading) {
          setHighlightedTier({
            linkId: newTier?.linkId,
            referenceName: newTier?.name,
          })
          setTimeout(() => {
            setHighlightedTier(null)
          }, 2000)
        }
      })
    }
  }

  useEffect(() => {
    dispatch(clearFilters())
    initialFetchData()
  }, [])

  useEffect(() => {
    currentSelectedStatus?.status !== currentSelectedTier?.status && currentSelectedStatus && handleChangeStatus()
  }, [currentSelectedStatus, currentSelectedTier, handleChangeStatus])

  const columns: GridColDef[] = [
    {
      minWidth: 140,
      display: 'flex',
      field: 'linkId',
      flex: 0.5,
      headerName: 'Link ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'provider',
      flex: 0.3,
      headerName: 'Provider',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 140,
      display: 'flex',
      field: 'productId',
      flex: 0.5,
      headerName: 'Product ID',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 140,
      display: 'flex',
      field: 'referenceName',
      flex: 0.5,
      headerName: 'Reference name',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'price',
      flex: 0.3,
      headerName: 'Price',
      renderCell: ({ value }) => <span>{currencyFormatter(value, 'en-US', 'USD')}</span>,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'country',
      flex: 0.3,
      headerName: 'Country',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'currency',
      flex: 0.3,
      headerName: 'Currency',
      renderCell: (parameters) => (
        <RenderCellWithTooltip title={currencyTooltipTitles && currencyTooltipTitles[parameters.value]} props={parameters} />
      ),
      sortable: false,
    },
    {
      minWidth: 130,
      display: 'flex',
      field: 'tierOperationType',
      flex: 0.3,
      headerName: 'Operation type',
      renderCell: ({ value }) => (
        <span>
          {value ? possibleOperationTypes.find((element) => value?.toLowerCase() === element.operationType?.toLowerCase())?.tierTitle : '—'}
        </span>
      ),
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'status',
      flex: 0.4,
      headerName: 'Status',
      renderCell: (parameters) => (
        <RenderStatusSelect
          isMenuOpen={statusMenuOpen}
          onClick={(event, data) => handleStatusMenuClick(event, data)}
          props={parameters}
          selectedCellData={currentSelectedTier}
        />
      ),
      sortable: false,
    },
  ]

  const rows: [] | ITier[] = useMemo(() => {
    if (data) {
      return data
    } else return []
  }, [data])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength)

  return (
    <div className="tiers-table-container">
      <div className="tiers-table-container__actions">
        <Stack alignItems="center" direction="row" gap={2}>
          <Button disabled={isDisabledFilterButton} onClick={() => setFilterDrawer(true)} size="small" startIcon={<FilterIcon />} variant="outlined">
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
          <SortingChip defaultSortingModel={defaultTiersSortingModel} disabled={isEmptyData} onSortingModelApply={() => handleSortingModelApply()} />
        </Stack>
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_IN_APP_TIER]) && (
          <Button onClick={() => setIsAddingTier(true)} variant="contained">
            <Typography variant="buttonMedium">Add tier</Typography>
          </Button>
        )}
      </div>
      {filtersLength !== 0 && (
        <ActiveFilterList
          refreshData={() => {
            setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
            fetchTiersGrid(paginationModel.page, paginationModel.pageSize).then(tableScrollTop)
          }}
        />
      )}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter={isEmptyData && !fetchingError}
            hideFooterSelectedRowCount
            getRowClassName={(parameters) =>
              `${
                parameters.row.linkId === highlightedTier?.linkId && parameters.row.referenceName === highlightedTier?.referenceName
                  ? 'tiers-table__highlighted-row'
                  : ''
              }`
            }
            getRowSpacing={() => ({
              bottom: 16,
            })}
            slots={{
              footer: CustomFooter,
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  clearFilters: () => resetFilters(),
                  fetchingError: fetchingError,
                  isFiltersApplied: !!filtersLength,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <>
                      <Typography color="text.secondary" variant="body2">
                        No tiers has been added yet
                      </Typography>
                      {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_IN_APP_TIER]) && (
                        <Button color="primary" onClick={() => setIsAddingTier(true)} variant="text">
                          <Typography variant="buttonMedium">Add tier</Typography>
                        </Button>
                      )}
                    </>
                  ),
                }),
            }}
            apiRef={apiReference}
            className="tiers-table"
            columnHeaderHeight={64}
            columns={columns}
            filterMode="server"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.productId + uniqueId()}
            loading={isLoading}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={totalCount}
            rows={rows}
            sortingMode="server"
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
            pagination
          />
        </Box>
      </Box>
      <Menu
        id="table-menu"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={tableMenuAnchorElement}
        onClose={handleTableMenuClose}
        open={tableMenu}
      >
        <MenuItem>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        id="status-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={statusMenuAnchorElement}
        onClose={handleStatusMenuClose}
        open={statusMenuOpen}
      >
        {possibleTierStatuses.map((status: ITierStatus) => (
          <MenuItem key={status.title} onClick={() => handleChangeStatusClick(status)} selected={status.status === currentSelectedTier?.status}>
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <AddTierModal onClose={(isAddedNewTier, newTier) => handleAddTierModalClose(isAddedNewTier, newTier)} open={isAddingTier} />
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterSidebarClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
            fetchTiersGrid(0, paginationModel.pageSize).then(tableScrollTop)
          }}
          onClose={onFilterSidebarClose}
          type="tiers"
        />
      </Drawer>
    </div>
  )
}
