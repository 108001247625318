import { useMemo } from 'react'

import { Alert as AlertBase } from '@mui/material'
import { AlertProps } from '@mui/material/Alert/Alert'

import CheckCircleIcon from '@admin/assets/img/CheckCircleIcon'
import ErrorIcon from '@admin/assets/img/ErrorIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import WarningIcon from '@admin/assets/img/WarningIcon'

interface IAlertProps extends Omit<AlertProps, 'icon'> {
  label?: string
  primary?: boolean
}

const Alert = (props: IAlertProps) => {
  const icon = useMemo(() => {
    switch (props.severity) {
      case 'error':
        return <ErrorIcon sx={{ fontSize: 22 }} />
      case 'info':
        return <InfoIcon sx={{ fontSize: 22 }} />
      case 'success':
        return <CheckCircleIcon sx={{ fontSize: 22 }} />
      case 'warning':
        return <WarningIcon sx={{ fontSize: 22 }} />
    }
  }, [props])

  return <AlertBase {...props} icon={icon} />
}

export default Alert
