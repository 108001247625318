import './index.scss'

import React from 'react'

import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { useAppSelector } from '@admin/store/hooks'

interface IProps {
  expire(item: IBanner): void
  item: IBanner
  onClose(): void
}

const ExpiredBannerModal = ({ expire, item, onClose }: IProps) => {
  const expireBannerStatus = useAppSelector((state) => state.worldManagement.expireBannerStatus)

  return (
    <div className="expiredBannerModal">
      <div className="expiredBannerModal-header">You are going to set banner as expired:</div>
      <div className="expiredBannerModal-content">
        <div className="item-content">
          <div className="item-primary">{item.name}</div>
          <div className="item-secondary">
            {item.creatorName} {item.creatorLastName}
          </div>
        </div>
      </div>
      <Alert severity="warning">After this action banner will be invisible for app users</Alert>
      <div className="expiredBannerModal-footer">
        <div className="expiredBannerModal-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton color="warning" loading={expireBannerStatus === 'loading'} onClick={() => expire(item)} variant="contained">
            Set banner as expired
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default ExpiredBannerModal
