import './index.scss'

import React from 'react'

import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'

interface IProps {
  deleteEntities(entities: string[], entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'): void
  entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'
  items: string[]
  onClose(): void
  willUnpublishWarning?: boolean
}

const DeleteEntities = ({ deleteEntities, entityType, items, onClose, willUnpublishWarning = false }: IProps) => {
  let title

  const isProfile = entityType === 'HERO'
  const isMultiple = items.length > 1

  if (isMultiple) {
    title = `You are going to delete ${items.length} ${isProfile ? 'profiles' : 'entities'} from collection`
  } else {
    title = `You are going to delete ${isProfile ? 'profile' : 'entity'} from collection`
  }

  const handleDelete = async () => {
    deleteEntities(items, entityType)
    onClose()
  }

  return (
    <div className="deleteEntities">
      <div className="deleteEntities-header">{title}</div>
      <div className="deleteEntities-footer">
        {willUnpublishWarning && <Alert severity="warning">After deleting will be not enough entities for publishing</Alert>}
        <div className="deleteEntities-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button color="error" onClick={handleDelete} variant="contained">
            Delete {isMultiple ? `${isProfile ? 'profiles' : 'entities'}` : `${isProfile ? 'profile' : 'entity'}`}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteEntities
