import type { TAny } from '@yzzy/types'

const EarnPendingWalletIcon = (props: TAny) => {
  return (
    <svg {...props} width="24" fill="none" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2938 14.5305C18.2938 14.1163 17.958 13.7805 17.5438 13.7805C17.1296 13.7805 16.7938 14.1163 16.7938 14.5305V17.132L15.513 17.9004C15.1579 18.1135 15.0427 18.5742 15.2558 18.9294C15.4689 19.2846 15.9296 19.3998 16.2848 19.1867L17.9297 18.1997C18.1556 18.0642 18.2938 17.8201 18.2938 17.5566V14.5305Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M5.47594 18.3258C6.56341 19.4133 8.4314 19.9284 11.7675 19.951C11.3415 19.1173 11.1012 18.1727 11.1012 17.1718C11.1012 15.2482 11.9876 13.5187 13.396 12.4009C14.4466 11.5637 15.776 11.0581 17.2149 11.0581C18.2787 11.0581 19.2788 11.3295 20.1499 11.8069V11.8018C20.1499 9.18724 19.862 7.42395 19.2517 6.23078C18.675 5.10325 17.7712 4.41035 16.2861 4.02444C15.8318 3.90638 15.5592 3.44234 15.6772 2.98799C15.7953 2.53363 16.2593 2.26102 16.7137 2.37909C18.5848 2.86533 19.931 3.8256 20.7652 5.45664C21.5659 7.02203 21.8499 9.12269 21.8499 11.8018C21.8499 12.2688 21.8413 12.719 21.8226 13.1526C22.7605 14.2271 23.3285 15.6328 23.3285 17.1718C23.3285 19.0076 22.5165 20.6441 21.2503 21.7578L21.248 21.7598C20.1689 22.7021 18.7625 23.2854 17.2149 23.2854C15.599 23.2854 14.13 22.6593 13.0372 21.6362C12.7011 21.6469 12.3553 21.6518 11.9999 21.6518C8.48212 21.6518 5.9114 21.1654 4.27386 19.5279C2.63633 17.8903 2.1499 15.3196 2.1499 11.8018C2.1499 11.3324 2.53046 10.9518 2.9999 10.9518C3.46934 10.9518 3.8499 11.3324 3.8499 11.8018C3.8499 15.284 4.36348 17.2133 5.47594 18.3258ZM17.2149 12.7581C16.1802 12.7581 15.2202 13.1208 14.4549 13.7308L14.4534 13.732C13.4409 14.5354 12.8012 15.7795 12.8012 17.1718C12.8012 19.6105 14.7761 21.5854 17.2149 21.5854C18.3248 21.5854 19.3394 21.1691 20.1287 20.4803C21.0462 19.6727 21.6285 18.4936 21.6285 17.1718C21.6285 14.733 19.6536 12.7581 17.2149 12.7581Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.83786 4.95125C7.03856 4.30183 6.00316 4.07906 5.12386 4.07906C4.65442 4.07906 4.27386 3.6985 4.27386 3.22906C4.27386 2.75962 4.65442 2.37906 5.12386 2.37906C6.24456 2.37906 7.70916 2.65629 8.90986 3.63186C10.1441 4.63467 10.9739 6.27274 10.9739 8.72903L10.9739 9.30507L13.5231 6.75582C13.8551 6.42387 14.3933 6.42387 14.7252 6.75582C15.0571 7.08776 15.0571 7.62596 14.7252 7.9579L10.1242 12.5589L5.52311 7.9579C5.19117 7.62596 5.19117 7.08776 5.52311 6.75582C5.85506 6.42387 6.39325 6.42387 6.7252 6.75582L9.27386 9.30448V8.72903C9.27386 6.68535 8.60363 5.57344 7.83786 4.95125Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default EarnPendingWalletIcon
