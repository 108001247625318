import './index.scss'

import { Dispatch, SetStateAction } from 'react'

import { IMediaItem } from '@admin/types/commonTypes'

import MediaThumbnail from './MediaThumbnail/MediaThumbnail'

interface IMediaThumbNailsProps {
  currentMainEntity: IMediaItem
  error: boolean
  isReloadingMainMedia: boolean
  onThumbnailClick: (maniEntity: IMediaItem) => void
  orderedMediaList: IMediaItem[]
  setIsReloadingMainMedia: Dispatch<SetStateAction<boolean>>
}

const MediaThumbnails = ({
  currentMainEntity,
  error,
  isReloadingMainMedia,
  onThumbnailClick,
  orderedMediaList,
  setIsReloadingMainMedia,
}: IMediaThumbNailsProps) => {
  return (
    <div className="multimedia-preview">
      {orderedMediaList.map((mediaItem: IMediaItem) => {
        const isActive = currentMainEntity.mediaUrl.startsWith(mediaItem.mediaUrl)

        return (
          <MediaThumbnail
            key={mediaItem.mediaId}
            currentMainEntity={currentMainEntity}
            isActive={isActive}
            isReloadingMainMedia={isReloadingMainMedia}
            mainMediaError={error}
            mediaItem={mediaItem}
            onThumbnailClick={onThumbnailClick}
            setIsReloadingMainMedia={setIsReloadingMainMedia}
          />
        )
      })}
    </div>
  )
}

export default MediaThumbnails
