import type { TAny } from '@yzzy/types'

const VerifyPendingIcon = (props: TAny) => {
  return (
    <svg width="24" fill="none" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth="1.7" d="M7.25027 20.2291C6.30379 19.6816 5.46053 18.9755 4.75684 18.1471" stroke="#4CA478" strokeLinecap="round" />
      <path strokeWidth="1.7" d="M7.25027 3.771C6.30379 4.31851 5.46053 5.02463 4.75684 5.85301" stroke="#4CA478" strokeLinecap="round" />
      <path
        strokeWidth="1.7"
        d="M2.76447 9.76476C2.59159 10.4816 2.5 11.2301 2.5 12C2.5 12.77 2.59159 13.5185 2.76447 14.2353"
        stroke="#4CA478"
        strokeLinecap="round"
      />
      <path strokeWidth="1.7" d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5" stroke="#4CA478" strokeLinecap="round" />
      <path strokeWidth="1.7" d="M8.0459 11.9302L11.1764 14.9658L16.6136 9.69339" stroke="#4CA478" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default VerifyPendingIcon
