import './index.scss'

import * as React from 'react'

import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDateTimePickerProps } from '@mui/x-date-pickers/DesktopDateTimePicker/DesktopDateTimePicker.types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'

const DateTimePicker = (props: DesktopDateTimePickerProps<Dayjs, false>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker className="desktopDateTimePicker" {...props} />
    </LocalizationProvider>
  )
}

export default DateTimePicker
