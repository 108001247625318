import './index.scss'

import { ListItemIcon as ListItemIconMUI } from '@mui/material'
import { ListItemIconProps } from '@mui/material/ListItemIcon/ListItemIcon'

const ListItemIcon = (props: ListItemIconProps) => {
  return <ListItemIconMUI {...props} />
}

export default ListItemIcon
