import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { useHover } from '@uidotdev/usehooks'
import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderCellWithCopy component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderCellWithCopy component.
 * @property {[boolean]} isWrappedText - Flag for controlling wrapped text.
 */
interface IRenderCellWithCopyProps {
  isWrappedText?: boolean
  props: GridRenderCellParams
}

/**
 * Renders a cell with copyToClipboard functionality.
 */
const RenderCellWithCopy = ({ isWrappedText, props }: IRenderCellWithCopyProps) => {
  const { value } = props
  const [reference, hovering] = useHover<HTMLDivElement>()

  const copyValue = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      })

      return navigator.clipboard.writeText(value)
    }
  }

  return (
    <div className="MuiDataGrid-cellContent" ref={reference}>
      <Stack alignItems="flex-start" direction="row" gap={1}>
        <Tooltip title={value} placement="top">
          <div style={{ display: 'inline-grid' }}>
            <Typography
              sx={{
                display: isWrappedText ? '-webkit-box' : '',
                lineClamp: '2',
                minHeight: '28px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                whiteSpace: isWrappedText ? 'normal' : 'nowrap',
                wordBreak: 'break-all',
              }}
              component="span"
              variant="body2"
            >
              {value || '—'}
            </Typography>
          </div>
        </Tooltip>
        {hovering && value && (
          <Tooltip title="Copy" placement="top">
            <IconButton color="default" onClick={copyValue} size="small">
              <CopyIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </div>
  )
}

export default RenderCellWithCopy
