import { MouseEventHandler } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import ContextIcon from '@admin/assets/img/ContextIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { TariffGroupsStatuses } from '@admin/store/slices/Settings/interface'

/**
 * Props for the RenderActionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {MouseEventHandler<HTMLButtonElement>} onClick - Click event handler for the context button.
 */
interface IRenderActionsCellProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  props: GridRenderCellParams
}

/**
 * Renders actions cell in a data grid.
 */
export const RenderActionsCell = ({ onClick, props }: IRenderActionsCellProps) => {
  const { row } = props

  if (row.tariffGroupId && row.status !== TariffGroupsStatuses.ACTIVE) {
    return (
      <Tooltip title="Actions" placement="top-end" followCursor>
        <IconButton color="default" onClick={onClick} size="small">
          <ContextIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return null
}
