import './index.scss'

import { ReactNode } from 'react'

import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'

interface IContentDisplayInfoBlockProps {
  icon: ReactNode
  primary: null | string
  secondary: string
  withTooltip?: boolean
}

const ContentDisplayInfoBlock = ({ icon, primary, secondary, withTooltip }: IContentDisplayInfoBlockProps) => (
  <ListItem className="info-block__container" disablePadding>
    {icon && <ListItemIcon className="info-block__icon">{icon}</ListItemIcon>}
    <ListItemText
      primary={
        <Tooltip title={withTooltip ? primary : ''} placement="top-end" followCursor>
          <Typography
            className={withTooltip ? 'info-block__text-primary--ellipsis' : 'info-block__text-primary'}
            color={primary === 'Not specified' ? colors.outlinedBorder : 'text.primary'}
            component="div"
            variant="body2"
          >
            {primary}
          </Typography>
        </Tooltip>
      }
      secondary={
        <Tooltip title={withTooltip ? secondary : ''} placement="top-end" followCursor>
          <Typography
            className={withTooltip ? 'info-block__text-secondary--ellipsis' : 'info-block__text-secondary'}
            color={secondary === 'Not specified' ? colors.outlinedBorder : 'text.secondary'}
            component="div"
            variant="body2"
          >
            {secondary}
          </Typography>
        </Tooltip>
      }
      className="info-block__text"
      disableTypography
    />
  </ListItem>
)

export default ContentDisplayInfoBlock
