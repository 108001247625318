import { ReactNode, StrictMode } from 'react'

interface IStrictModeContainerProps {
  children: ReactNode
}

export const StrictModeContainer = ({ children }: IStrictModeContainerProps) => {
  if (import.meta.env.VITE_STRICT_MODE === 'true') {
    return <StrictMode>{children}</StrictMode>
  }

  return children
}
