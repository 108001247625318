import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import { ITier } from '@admin/store/slices/Settings/interface'
import { Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { possibleTierStatuses } from '../constants'

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {ITier} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  isMenuOpen: boolean
  onClick: (event: MouseEvent<HTMLDivElement>, data: TAny) => void
  props: GridRenderCellParams
  selectedCellData?: ITier | null
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({ isMenuOpen, onClick, props, selectedCellData }: IRenderStatusSelectProps) => {
  const { row } = props
  const userPermissions = useAppSelector(selectUserPermissions)

  if (!row.linkId) {
    return null
  }

  const hasAccess = checkPermissions(userPermissions, [Permissions.SETTINGS_CHANGE_IN_APP_TIER_STATUS])
  const currentStatus = possibleTierStatuses.find((element) => row.status?.toLowerCase() === element.status.toLowerCase())
  const color = currentStatus?.color

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (hasAccess) {
      onClick(event, row)
    }
  }

  return (
    <div className={`tiers-table__status-select${!hasAccess ? '--no-pointer' : ''}`} onClick={handleClick} style={{ color }}>
      <Typography color={color} variant="chip">
        {row.status?.replace(row.status, currentStatus?.title)}
      </Typography>
      {hasAccess && (selectedCellData?.linkId === row.linkId && isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </div>
  )
}
