import { createSvgIcon } from '@mui/material'

const UsdLockedIcon = createSvgIcon(
  <svg width="24" fill="none" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      strokeWidth="1.7"
      d="M17.5004 20.5559V19.9448M18.7736 15.7226C18.519 15.3226 18.0097 15.0559 17.4495 15.0559C16.6347 15.0559 15.9727 15.6337 15.9727 16.3893C15.9727 16.3893 15.9727 17.5004 17.5004 17.5004C19.0282 17.5004 19.0282 18.6115 19.0282 18.6115C19.0282 19.367 18.3662 19.9448 17.5514 19.9448C16.9912 19.9448 16.4819 19.6782 16.2273 19.2782M17.5004 15.0559V14.4448"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      strokeWidth="1.7"
      d="M17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5C23 14.4624 20.5376 12 17.5 12Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      strokeWidth="1.7"
      d="M16 10V7C16 4.23858 13.7614 2 11 2C8.23858 2 6 4.23858 6 7V10M12.2 20H7.8C6.11984 20 5.27976 20 4.63803 19.673C4.07354 19.3854 3.6146 18.9265 3.32698 18.362C3 17.7202 3 16.8802 3 15.2V14.8C3 13.1198 3 12.2798 3.32698 11.638C3.6146 11.0735 4.07354 10.6146 4.63803 10.327C5.27976 10 6.11984 10 7.8 10H14.2C15.8802 10 16.7202 10 17.362 10.327C17.9265 10.6146 18.3854 11.0735 18.673 11.638"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'UsdLocked',
)

export default UsdLockedIcon
