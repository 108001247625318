import { createSvgIcon } from '@mui/material'

const InfoIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 8.6499C10.996 8.0979 11.444 7.6499 11.996 7.6499C12.552 7.6499 13 8.0979 13 8.6499C13 9.2019 12.552 9.6499 12 9.6499C11.448 9.6499 11 9.2019 11 8.6499Z" />
    <path d="M12.8499 11.9999C12.8499 11.5305 12.4693 11.1499 11.9999 11.1499C11.5305 11.1499 11.1499 11.5305 11.1499 11.9999V16.9999C11.1499 17.4693 11.5305 17.8499 11.9999 17.8499C12.4693 17.8499 12.8499 17.4693 12.8499 16.9999V11.9999Z" />
    <path
      clipRule="evenodd"
      d="M11.9999 2.1499C8.44349 2.1499 5.88644 2.77518 4.26066 4.47486C2.64346 6.16557 2.1499 8.71278 2.1499 11.9999C2.1499 15.5177 2.63633 18.0884 4.27386 19.7259C5.9114 21.3635 8.48212 21.8499 11.9999 21.8499C15.5578 21.8499 18.1023 21.2239 19.7251 19.6054C21.3512 17.9837 21.8499 15.5665 21.8499 12.5293C21.8499 9.02793 21.496 6.33767 19.9528 4.56963C18.3788 2.7664 15.7848 2.1499 11.9999 2.1499ZM3.8499 11.9999C3.8499 8.78702 4.35635 6.83423 5.48915 5.64994C6.61336 4.47463 8.55631 3.8499 11.9999 3.8499C15.715 3.8499 17.621 4.48341 18.672 5.68753C19.7538 6.92684 20.1499 9.00129 20.1499 12.5293C20.1499 15.4627 19.6486 17.2808 18.5247 18.4017C17.3975 19.5259 15.442 20.1499 11.9999 20.1499C8.51769 20.1499 6.58841 19.6363 5.47594 18.5239C4.36348 17.4114 3.8499 15.4821 3.8499 11.9999Z"
      fillRule="evenodd"
    />
  </svg>,
  'Info',
)

export default InfoIcon
