import { useEffect, useRef } from 'react'

function usePrevious<T>(value?: T) {
  const reference = useRef<T>(undefined)

  // Store current value in ref
  useEffect(() => {
    reference.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return reference.current
}

export default usePrevious
