import { alpha, Avatar as AvatarMUI } from '@mui/material'
import isEmpty from 'lodash/isEmpty'

import SystemIcon from '@admin/assets/img/SystemIcon'
import Avatar from '@admin/components/shared/Avatar/Avatar'
import Button from '@admin/components/shared/Button/Button'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import Popover from '@admin/components/shared/Popover/Popover'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { ILastAdminOperationHistory, OperationActions } from '@admin/store/slices/Payments/interface'
import colors from '@admin/theme/constants/colors'
import { IMemberInfo } from '@admin/types/Member'

interface IMemberProfilePopoverProps {
  action: null | OperationActions
  anchorEl: HTMLButtonElement | null
  coordinates: { x: number; y: number }
  lastAdminOperationHistory?: ILastAdminOperationHistory
  onClose: () => void
}

export const MemberProfilePopover = ({ action, anchorEl, coordinates, lastAdminOperationHistory, onClose }: IMemberProfilePopoverProps) => {
  const isOpen = !!anchorEl
  const initiatorInfo = lastAdminOperationHistory?.memberInfo
  const isEmptyOperationHistory = isEmpty(lastAdminOperationHistory)
  const isRefund = action === OperationActions.REFUND

  const title = isRefund ? 'Return Initiator' : 'Initiator of the suspension procedure'

  function getInitiatorName(isEmptyOperationHistory: boolean, isRefund: boolean, initiatorInfo: IMemberInfo | undefined) {
    if (isEmptyOperationHistory) {
      return isRefund ? 'Automatic refund' : 'Automatic suspension'
    }

    return `${initiatorInfo?.firstName} ${initiatorInfo?.lastName}`
  }

  const initiatorName = getInitiatorName(isEmptyOperationHistory, isRefund, initiatorInfo)

  const avatarContent = isEmptyOperationHistory ? (
    <AvatarMUI
      sx={{
        width: 40,
        backgroundColor: alpha(colors.primary, 0.08),
        height: 40,
      }}
    >
      <SystemIcon sx={{ color: colors.textSecondary, fontSize: 24 }} />
    </AvatarMUI>
  ) : (
    <Avatar alt={initiatorName} src={lastAdminOperationHistory?.avatarPreview?.originalLink} />
  )

  const roleInfo = !isEmptyOperationHistory && initiatorInfo?.role?.name && (
    <Typography color="text.secondary" variant="caption">
      {initiatorInfo.role.name}
    </Typography>
  )

  return (
    <Popover
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      anchorPosition={{ left: coordinates.x, top: coordinates.y + 20 }}
      anchorReference="anchorPosition"
      onClose={onClose}
      open={isOpen}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack gap={2} sx={{ minWidth: 248, p: '24px 24px 0' }}>
        <Typography variant="h6">{title}</Typography>
        <Stack alignItems="center" direction="row" flex="auto" gap={1}>
          {avatarContent}
          <Stack flex="auto">
            <Typography variant="body2">{initiatorName}</Typography>
            {roleInfo}
          </Stack>
        </Stack>
      </Stack>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Popover>
  )
}
