import { forwardRef } from 'react'

import { RadioGroup as RadioGroupMUI } from '@mui/material'
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>((props, reference) => {
  return <RadioGroupMUI {...props} ref={reference} />
})

export default RadioGroup
