import { createSvgIcon } from '@mui/material'

const DisputeIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.5C12.3739 7.5 12.677 7.81318 12.677 8.1995V8.54938C13.4352 8.7313 14.1076 9.18322 14.5069 9.83135C14.7077 10.1572 14.6148 10.5896 14.2994 10.7971C13.984 11.0045 13.5655 10.9085 13.3647 10.5826C13.1185 10.183 12.5758 9.8591 11.9226 9.8591C10.9878 9.8591 10.3593 10.5201 10.354 11.2452L10.3553 11.261C10.3574 11.2832 10.3623 11.3217 10.3732 11.3709C10.3953 11.4707 10.4394 11.6019 10.5252 11.7309C10.671 11.9499 11.0269 12.3005 12 12.3005C13.3499 12.3005 14.1555 12.8227 14.5905 13.4765C14.7951 13.784 14.8961 14.0891 14.9466 14.3166C14.972 14.4312 14.9853 14.529 14.9922 14.6023C14.9957 14.639 14.9977 14.6699 14.9987 14.694L14.9995 14.7161L14.9998 14.7251L15 14.7367L15 14.7415L15 14.7437L15 14.7456C15 16.1734 13.9582 17.2274 12.677 17.4811V17.8005C12.677 18.1868 12.3739 18.5 12 18.5C11.6261 18.5 11.323 18.1868 11.323 17.8005V17.4506C10.5648 17.2687 9.89236 16.8168 9.49306 16.1686C9.29228 15.8428 9.3852 15.4104 9.70061 15.2029C10.016 14.9955 10.4345 15.0915 10.6353 15.4174C10.8815 15.817 11.4242 16.1409 12.0774 16.1409C13.0122 16.1409 13.6407 15.4799 13.646 14.7548L13.6447 14.739C13.6426 14.7168 13.6377 14.6783 13.6268 14.6291C13.6047 14.5293 13.5606 14.3981 13.4748 14.2691C13.329 14.0501 12.9731 13.6995 12 13.6995C10.6501 13.6995 9.84453 13.1773 9.4095 12.5235C9.20493 12.216 9.10386 11.9109 9.0534 11.6834C9.028 11.5688 9.01474 11.471 9.00778 11.3977C9.00429 11.361 9.00235 11.3301 9.00128 11.306C9.00074 11.2939 9.00042 11.2835 9.00024 11.2749L9.00005 11.2633L9.00001 11.2585L9 11.2563L9 11.2544C9 9.82659 10.0418 8.77263 11.323 8.51886V8.1995C11.323 7.81318 11.6261 7.5 12 7.5Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M5.93981 22.2602H11.869C11.9117 22.2668 11.9553 22.2702 11.9998 22.2702H18.0598C19.9774 22.2702 21.5498 21.5757 22.34 20.2324C23.13 18.8893 22.9737 17.1763 22.043 15.4976L15.983 4.5877C15.0096 2.83248 13.5999 1.74268 11.9998 1.74268C10.3998 1.74268 8.98982 2.83302 8.01645 4.58797L1.95643 15.488C1.02632 17.1655 0.866801 18.878 1.65551 20.2217C2.4447 21.5662 4.01808 22.2602 5.93981 22.2602ZM9.50317 5.41238C10.3098 3.95775 11.25 3.44268 11.9998 3.44268C12.7496 3.44268 13.6902 3.95846 14.4968 5.41309L20.5564 16.3223C21.3057 17.6737 21.2596 18.716 20.8747 19.3705C20.4898 20.0247 19.6022 20.5702 18.0598 20.5702H12.1306C12.088 20.5636 12.0443 20.5602 11.9998 20.5602H5.93981C4.39154 20.5602 3.50491 20.0142 3.1216 19.3611C2.73785 18.7074 2.69353 17.6646 3.44319 16.3123L9.50317 5.41238Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>,
  'DisputeIcon',
)

export default DisputeIcon
