import './index.scss'

import { useCallback, useState } from 'react'
import { Outlet } from 'react-router-dom'

import withAuth from '@admin/components/Authorization/withAuth'
import Navbar from '@admin/components/Navbar/Navbar'

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const handleOpen = useCallback((value: boolean) => setIsMenuOpen(value), [])

  return (
    <div className={`layout-container${isMenuOpen ? ' layout-container--blurred' : ''}`}>
      <Navbar handleOpen={handleOpen} isMenuOpen={isMenuOpen} />
      <div style={{ width: '64px' }} />
      <Outlet />
    </div>
  )
}

export default withAuth(Layout)
