const ExtendIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="Extend">
        <path
          id="Vector"
          clipRule="evenodd"
          d="M2.75 3.6C2.75 3.13056 3.13056 2.75 3.6 2.75H8.4C8.86944 2.75 9.25 3.13056 9.25 3.6C9.25 4.06944 8.86944 4.45 8.4 4.45H5.65208L12 10.7979L18.3479 4.45H15.6C15.1306 4.45 14.75 4.06944 14.75 3.6C14.75 3.13056 15.1306 2.75 15.6 2.75H20.4C20.8694 2.75 21.25 3.13056 21.25 3.6V8.4C21.25 8.86944 20.8694 9.25 20.4 9.25C19.9306 9.25 19.55 8.86944 19.55 8.4V5.65208L13.2021 12L19.55 18.3479V15.6C19.55 15.1306 19.9306 14.75 20.4 14.75C20.8694 14.75 21.25 15.1306 21.25 15.6V20.4C21.25 20.8694 20.8694 21.25 20.4 21.25H15.6C15.1306 21.25 14.75 20.8694 14.75 20.4C14.75 19.9306 15.1306 19.55 15.6 19.55H18.3479L12 13.2021L5.65208 19.55H8.4C8.86944 19.55 9.25 19.9306 9.25 20.4C9.25 20.8694 8.86944 21.25 8.4 21.25H3.6C3.13056 21.25 2.75 20.8694 2.75 20.4V15.6C2.75 15.1306 3.13056 14.75 3.6 14.75C4.06944 14.75 4.45 15.1306 4.45 15.6V18.3479L10.7979 12L4.45 5.65208V8.4C4.45 8.86944 4.06944 9.25 3.6 9.25C3.13056 9.25 2.75 8.86944 2.75 8.4V3.6Z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default ExtendIcon
