import './index.scss'

import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import every from 'lodash/every'
import isNull from 'lodash/isNull'

import CloseIcon from '@admin/assets/img/CloseIcon'
import DeleteIcon from '@admin/assets/img/DeleteIcon'
import DragIndicatorIcon from '@admin/assets/img/DragIndicatorIcon'
import Autocomplete from '@admin/components/shared/Autocomplete/Autocomplete'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Radio from '@admin/components/shared/Radio/Radio'
import RadioGroup from '@admin/components/shared/RadioGroup/RadioGroup'
import Stack from '@admin/components/shared/Stack/Stack'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectActivePresetId } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  addLevel,
  changeSortingField,
  changeSortingType,
  clearSortingData,
  deletePreset,
  fetchSortingPresets,
  removeSortingField,
  savePreset,
  saveSortingData,
  selectSortingColumns,
  selectSortingCurrentData,
  selectSortingData,
  selectSortingPresets,
  setActivePreset,
  setSortingCurrentData,
} from '@admin/store/sortingSlice'
import colors from '@admin/theme/constants/colors'
import { IPreset, ISortingItem } from '@admin/types/commonTypes'

interface ISortingDrawerProps {
  isHidePresets?: boolean
  onApply: () => void
  onClose: (apply?: boolean) => void
  type: string
}

const SortingDrawer = ({ isHidePresets, onApply, onClose, type }: ISortingDrawerProps) => {
  const dispatch = useAppDispatch()

  const sortingColumns = useAppSelector(selectSortingColumns)
  const sortingData = useAppSelector(selectSortingData)
  const sortingCurrentData = useAppSelector(selectSortingCurrentData)
  const sortingPresets = useAppSelector(selectSortingPresets)

  const [savePresetModal, setSavePresetModal] = useState<boolean>(false)
  const [presetName, setPresetName] = useState<string>('')
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false)
  const [deletedPreset, setDeletedPreset] = useState<IPreset | null>(null)

  const setPreset = useCallback(
    (presetId: string) => {
      dispatch(setActivePreset({ presetId: presetId, type: type }))
    },
    [dispatch, type],
  )

  const activePresetId = useAppSelector(selectActivePresetId)

  const showDeletePresetModal = useCallback((preset: IPreset) => {
    setDeletedPreset(preset)
    setDeletePresetModal(true)
  }, [])

  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false)
  }, [])

  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false)
  }, [])

  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ presetName: presetName, type: type }))
      handleChangeSavePresetModalClose()
      await dispatch(fetchSortingPresets(type))
    },
    [type, dispatch],
  )

  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ presetId: presetId, type: type }))
      handleChangeDeletePresetModalClose()
      await dispatch(fetchSortingPresets(type))
    },
    [type],
  )

  const sorting = useMemo(() => sortingCurrentData, [sortingCurrentData])

  const possibleColumns = useMemo(() => {
    const columns = sortingColumns.filter(
      (item: ISortingItem) =>
        sortingCurrentData.findIndex((value: ISortingItem) => value?.columnName === item.columnName && value?.columnId === item.columnId) === -1,
    )

    return columns
  }, [sortingColumns, sortingCurrentData])

  const reorder = (list: ISortingItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const newArray = reorder(sortingCurrentData, result.source.index, result.destination.index)

    dispatch(setSortingCurrentData(newArray))
  }

  const getSortingTypeLabel = (columnId: null | string, ascending: boolean) => {
    if (!columnId) return <Typography variant="body1">{ascending ? 'A-Z' : 'Z-A'}</Typography>

    const lowerColumnId = columnId.toLowerCase()
    const dateKeywords = ['date', 'created']
    const priceKeywords = ['price', 'amount', 'total', 'fee', 'count']

    let label = ascending ? 'A-Z' : 'Z-A'

    if (dateKeywords.some((keyword) => lowerColumnId.includes(keyword))) {
      label = ascending ? 'Oldest first' : 'Newest first'
    } else if (priceKeywords.some((keyword) => lowerColumnId.includes(keyword))) {
      label = ascending ? 'Lowest first' : 'Highest first'
    }

    return <Typography variant="body1">{label}</Typography>
  }

  const handleApplySorting = () => {
    dispatch(saveSortingData())
    if (every(sortingData, isNull)) {
      dispatch(clearSortingData())
      onClose(true)

      return
    }
    onApply()
  }

  const isDragDisabled = sorting.length < 2

  const handleClose = () => onClose()
  const handleAddLevel = () => dispatch(addLevel())

  return (
    <div className="sortingDrawer--container">
      <div className="sortingDrawer--header">
        <Typography variant="h5">Sort</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="sortingDrawer--content">
        {!isHidePresets && (
          <div className="sortingDrawer--group">
            <Typography mb={1} variant="h6">
              Presets
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1.5} mb={2}>
              {sortingPresets.map((preset: IPreset) => {
                return (
                  <Chip
                    label={preset.presetName}
                    active={preset.presetId === activePresetId}
                    onClick={() => setPreset(preset.presetId)}
                    onDelete={() => showDeletePresetModal(preset)}
                  />
                )
              })}
            </Stack>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" ignoreContainerClipping={false} isCombineEnabled={false} isDropDisabled={false}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sorting.map((item: ISortingItem, index: number) => {
                  const onDelete = () => dispatch(removeSortingField(index))

                  return (
                    <Draggable
                      key={item.columnId || item.columnName}
                      draggableId={item.columnId || `draggable-${index}`}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(providedItem) => (
                        <div ref={providedItem.innerRef} {...providedItem.draggableProps} {...providedItem.dragHandleProps}>
                          <div className="sortingDrawer--sortElementContainer">
                            <Stack alignItems="center" direction="row" gap={1} mt={1}>
                              <DragIndicatorIcon
                                sx={{ color: isDragDisabled ? colors.actionDisabled : undefined, opacity: isDragDisabled ? 0.4 : 1 }}
                              />
                              <Autocomplete
                                onChange={(_, newValue: ISortingItem) => {
                                  dispatch(
                                    changeSortingField({
                                      index,
                                      newValue,
                                    }),
                                  )
                                }}
                                getOptionLabel={(option: ISortingItem) => option.label ?? option.title}
                                isOptionEqualToValue={(option, value) => option.columnId === value.columnId}
                                options={possibleColumns}
                                size="small"
                                style={{ width: '272px', flex: 'auto' }}
                                value={item.columnId ? item : null}
                                disableClearable
                              />
                              <IconButton
                                sx={{
                                  opacity: isDragDisabled ? 0.5 : 1,
                                }}
                                disabled={isDragDisabled}
                                onClick={onDelete}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                            <RadioGroup className="sortingDrawer--sortElementOrder">
                              <FormControlLabel
                                label={getSortingTypeLabel(item.columnId, true)}
                                control={<Radio checked={item?.sortingType === 'ASC'} size="small" />}
                                onClick={() => dispatch(changeSortingType({ index, sortingType: 'ASC' }))}
                                sx={{ ml: 'unset' }}
                                value="ASC"
                              />
                              <FormControlLabel
                                label={getSortingTypeLabel(item.columnId, false)}
                                control={<Radio checked={item?.sortingType === 'DESC'} size="small" />}
                                onClick={() => dispatch(changeSortingType({ index, sortingType: 'DESC' }))}
                                sx={{ ml: 'unset' }}
                                value="DESC"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder as ReactNode}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          disabled={possibleColumns.length === 0 || sortingCurrentData.length >= sortingColumns.length}
          onClick={handleAddLevel}
          sx={{ marginTop: 1 }}
          variant="text"
        >
          Add level
        </Button>
      </div>
      <div className="sortingDrawer--footer">
        <Button fullWidth onClick={handleApplySorting} style={{ height: '42px' }} variant="contained">
          Apply
        </Button>
        {!isHidePresets && (
          <Button
            fullWidth
            onClick={() => {
              setSavePresetModal(true)
            }}
            style={{ height: '42px' }}
            variant="outlined"
          >
            Save set
          </Button>
        )}
      </div>
      <Modal customstyle={{ minHeight: 256 }} onClose={handleChangeSavePresetModalClose} open={savePresetModal}>
        <div className="savePresetModal">
          <div>
            <div className="savePresetModal--header">Save new preset</div>
            <div className="savePresetModal--text">Enter name for new filter preset</div>
          </div>
          <TextField onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)} placeholder="Name" variant="outlined" />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button onClick={() => handleChangeSavePresetModalClose()} style={{ marginRight: '16px' }}>
              Cancel
            </Button>
            <Button onClick={() => handleSavePreset(presetName)} variant="contained">
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal customstyle={{ minHeight: 188 }} onClose={handleChangeDeletePresetModalClose} open={deletePresetModal}>
        <div className="deletePresetModal">
          <div>
            <div className="deletePresetModal--header">Delete preset?</div>
            <div className="deletePresetModal--text">You are going to delete preset {deletedPreset?.presetName}</div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', justifySelf: 'end' }}>
            <Button onClick={() => handleChangeDeletePresetModalClose()} style={{ marginRight: '16px' }}>
              Cancel
            </Button>
            <Button onClick={() => deletedPreset?.presetId && handleDeletePreset(deletedPreset.presetId)} variant="contained">
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SortingDrawer
