import './index.scss'

import { useCallback, useMemo, useState } from 'react'

import { Box, Typography } from '@mui/material'

import CloseCircleIcon from '@admin/assets/img/CloseCircleIcon'
import { PRICE_IDS } from '@admin/components/FilterDrawer/constants'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Stack from '@admin/components/shared/Stack/Stack'
import { clearFilters, removeFilter, selectFilters, selectFiltersData } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'
import { IFilterItem } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { formatBetweenFilter, formatDateRange, getEqualsFilterText, getSingleElementText, isSingleElementFilter } from './utils'

const ActiveFilterList = (props: TAny) => {
  const dispatch = useAppDispatch()
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false)

  const cancelClearFilters = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setClearFiltersConfirmModal(false)
  }, [])

  const data = useAppSelector(selectFiltersData)
  const filtersList = useAppSelector(selectFilters)

  /** Additionally, compare the filters list with the applied filters to exclude custom (search) filters from being displayed */
  const filtersData = useMemo(() => {
    const filtersColumnIds = filtersList.map((filter) => filter.columnId)

    return Object.values(data).filter((element: IFilterItem) => element !== null && filtersColumnIds.includes(element.columnId)) as TAny
  }, [data, filtersList])

  const handleRemoveFilter = useCallback(
    (columnId: string) => {
      dispatch(removeFilter({ columnId }))
      setClearFiltersConfirmModal(false)

      props.refreshData?.()
    },
    [data, dispatch, props.refreshData],
  )

  const handleRemoveAllFilters = useCallback(() => {
    dispatch(clearFilters())
    setClearFiltersConfirmModal(false)
    props.refreshData?.()
  }, [dispatch, props.refreshData])

  const getChipText = useCallback((filter: IFilterItem) => {
    const isPriceFilter = PRICE_IDS.includes(filter.columnId.toString())

    if (filter.valueType === 'DATE') {
      return formatDateRange(filter.from ?? '', filter.to ?? '')
    }
    if (filter.type === 'BETWEEN') {
      return formatBetweenFilter(isPriceFilter, filter.from ?? '', filter.to ?? '')
    }
    if (filter.type === 'EQUALS') {
      return getEqualsFilterText(filter.element ?? null)
    }
    if (filter.elements && isSingleElementFilter(filter)) {
      const isUsername = filter.title.toLowerCase().includes('username')

      if (isUsername) {
        return `@${getSingleElementText(filter.elements[0])}`
      }

      return getSingleElementText(filter.elements[0])
    }

    return `${filter.elements ? filter.elements.length : 0} selected`
  }, [])

  return (
    <Stack className="filter-active-list" direction="row" gap={1.5} mb={2}>
      {filtersData.map((element: TAny) => (
        <Chip
          key={element.title}
          label={getChipText(element)}
          icon={
            // TODO: fixed !important
            <Box
              sx={{
                display: 'flex',
                fontSize: '0 !important',
                marginLeft: '0 !important',
                marginRight: '0 !important',
              }}
            >
              <Typography color="text.secondary" ml={1.5} variant="chip">
                {element.title}

                <Typography color={colors.primary} ml={0.5} mr={0.5} variant="chip">
                  |
                </Typography>
              </Typography>
            </Box>
          }
          className="filter-active-list__chip"
          deleteIcon={<CloseCircleIcon />}
          onDelete={() => handleRemoveFilter(element.columnId)}
          size="small"
        />
      ))}

      {filtersData.length > 0 && (
        <Typography className="filter-active-list__clear-filters-link" onClick={() => setClearFiltersConfirmModal(true)} variant="body1">
          Clear filters
        </Typography>
      )}
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => cancelClearFilters()} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleRemoveAllFilters()} variant="contained">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  )
}

export default ActiveFilterList
