const RestrictInProgressIcon = () => {
  return (
    <svg width="24" fill="none" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth="1.7" d="M2.5 12.4385C2.5 6.9496 6.9496 2.5 12.4385 2.5" stroke="#FFB74D" strokeDasharray="1 4" strokeLinecap="round" />
      <path
        strokeWidth="1.7"
        d="M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5"
        stroke="#FFB74D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path strokeWidth="1.7" d="M12.499 12.1429L12.499 7.00003" stroke="#FFB74D" strokeLinecap="round" />
      <path
        d="M12.5 16.1428C12.7761 16.1428 13 15.919 13 15.6428C13 15.3667 12.7761 15.1428 12.5 15.1428C12.2239 15.1428 12 15.3667 12 15.6428C12 15.919 12.2239 16.1428 12.5 16.1428Z"
        fill="#FFB74D"
        stroke="#FFB74D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RestrictInProgressIcon
