import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Typography from '@admin/components/shared/Typography/Typography'

interface IChangeStatusWarningModalProps {
  onClose: () => void
  open: boolean
}

export const ChangeStatusWarningModal = ({ onClose, open }: IChangeStatusWarningModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h6">This tax code cannot be made inactive</DialogTitle>
      <DialogContent>
        <Typography variant="body1">This tax code is used in the rules on the "Rules for sales tax" page. You can't make it inactive</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="contained">
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  )
}
