import './index.scss'

import { DialogContent as DialogContentMUI } from '@mui/material'
import { DialogContentProps } from '@mui/material/DialogContent/DialogContent'

const DialogContent = (props: DialogContentProps) => {
  return <DialogContentMUI {...props} />
}

export default DialogContent
