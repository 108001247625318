import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { TaxModerationListType } from '@admin/containers/TaxModeration/constants'
import { TAXES_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { ITaxModerationCard, ITaxModerationSearchItem, ITaxModerationState, TaxModerationSolution } from './interface'

const initialState: ITaxModerationState = {
  allIds: [],
  searchValue: '',
  status: 'idle',
  taxModerationCardDetails: null,
  taxModerationCards: [],
  taxModerationCardsCount: 0,
  taxModerationSearch: [],
  taxModerationSearchAll: [],
  taxModerationSearchStatus: 'idle',
}

export const fetchChangeTaxModerationSolution = createAsyncThunk(
  'taxModeration/changeSolution',
  async (
    {
      newSalesTaxCodeId,
      salesTaxModerationId,
      solution,
    }: {
      newSalesTaxCodeId: null | string
      salesTaxModerationId: null | string
      solution: TaxModerationSolution
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(TAXES_API + `sales/tax/moderation/${salesTaxModerationId}/solution`, {
        newSalesTaxCodeId: newSalesTaxCodeId,
        solution: solution,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchTaxModerationCards = createAsyncThunk(
  'taxModeration/cards',
  async (
    {
      listType,
      page,
      pageSize,
    }: {
      listType: TaxModerationListType
      page: number
      pageSize: number
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const rootState: TRootState = getState() as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
        : []
      const response = await axios.post(TAXES_API + `sales/tax/moderation/${listType.toLowerCase()}`, {
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchTaxModerationCard = createAsyncThunk('taxModeration/card', async (salesTaxModerationId: string, { rejectWithValue }) => {
  try {
    const response = await axios.get(TAXES_API + `sales/tax/moderation/${salesTaxModerationId}`)

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchTaxModerationSearch = createAsyncThunk(
  'taxModeration/search',
  async ({ listType, searchValue }: { listType: string; searchValue: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(TAXES_API + `sales/tax/moderation/search/${listType.toLowerCase()}`, {
        value: searchValue,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const removeApprovedCardId = createAction<string>('taxModeration/removeApprovedId')

export const TaxModerationSlice = createSlice({
  name: 'taxModeration',
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxModerationCards.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTaxModerationCards.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.taxModerationCards = payload.content
        state.allIds = payload.allIds
        state.taxModerationCardsCount = payload.totalElements
      })
      .addCase(fetchTaxModerationCards.rejected, (state) => {
        state.status = 'failed'
        state.taxModerationCards = []
        state.allIds = []
        state.taxModerationCardsCount = 0
      })
      .addCase(fetchTaxModerationCard.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTaxModerationCard.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.taxModerationCardDetails = payload
      })
      .addCase(fetchTaxModerationCard.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchChangeTaxModerationSolution.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        payload && (state.taxModerationCardDetails = payload)
      })
      .addCase(fetchChangeTaxModerationSolution.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(removeApprovedCardId, (state, action) => {
        state.allIds = state.allIds.filter((id) => id !== action.payload)
      })
      .addCase(fetchTaxModerationSearch.pending, (state) => {
        state.taxModerationSearchStatus = 'loading'
      })
      .addCase(fetchTaxModerationSearch.fulfilled, (state, { payload }) => {
        state.taxModerationSearch = payload.listOfTitleAndIds
        state.taxModerationSearchAll = payload.allIds
        state.taxModerationSearchStatus = 'idle'
      })
      .addCase(fetchTaxModerationSearch.rejected, (state) => {
        state.taxModerationSearchStatus = 'failed'
      })
  },
  initialState,
  reducers: {
    clearSearchValue: (state) => {
      state.searchValue = ''
    },
    clearTaxModerationSearch: (state) => {
      state.taxModerationSearch = []
      state.taxModerationSearchAll = []
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setTaxModerationStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectTaxModerationCards = (state: TRootState): ITaxModerationCard[] => state.taxModeration.taxModerationCards
export const selectTaxModerationCardIds = (state: TRootState): string[] => state.taxModeration.allIds
export const selectTaxModerationCardDetails = (state: TRootState): ITaxModerationCard | null => state.taxModeration.taxModerationCardDetails
export const selectTotalCount = (state: TRootState): number => state.taxModeration.taxModerationCardsCount
export const selectTaxModerationSearchResults = (state: TRootState): ITaxModerationSearchItem[] => state.taxModeration.taxModerationSearch
export const selectTaxModerationSearchAllResults = (state: TRootState): string[] => state.taxModeration.taxModerationSearchAll
export const selectTaxModerationStatus = (state: TRootState): TStatus => state.taxModeration.status
export const selectTaxModerationSearchStatus = (state: TRootState): TStatus => state.taxModeration.taxModerationSearchStatus
export const selectSearchValue = (state: TRootState): string => state.taxModeration.searchValue

export const { clearSearchValue, clearTaxModerationSearch, setSearchValue, setTaxModerationStatus } = TaxModerationSlice.actions

export default TaxModerationSlice.reducer
