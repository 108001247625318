import { AttentionIcon, LocationIcon, OnlineIcon, TimeQuarterToIcon } from '@yzzy/icons'

export const BADGE_TYPES_CONTENT = {
  CANCELLED: {
    icon: <AttentionIcon width={12} height={12} />,
    text: 'called off',
  },
  EXPIRED: {
    icon: <TimeQuarterToIcon width={12} height={12} />,
    text: 'past',
  },
  OFFLINE: {
    icon: <LocationIcon width={12} height={12} />,
    text: 'irl',
  },
  ONLINE: {
    icon: <OnlineIcon width={12} height={12} />,
    text: 'online',
  },
  TEXT: {
    icon: '',
    text: '',
  },
} as const
