import './index.scss'

import { FC, ForwardedRef, forwardRef } from 'react'

import { ToggleButton } from '@mui/material'
import { EditorState } from 'draft-js'
import MUIRichTextEditor, { TMUIRichTextEditorProps, TMUIRichTextEditorRef, TToolbarComponentProps } from 'mui-rte'

import type { TAny } from '@yzzy/types'

export interface ITextEditorProps extends Omit<TMUIRichTextEditorProps, 'onChange'> {
  onChange?: (editorState: EditorState) => void
}

const TextEditor = forwardRef(({ onChange, ...props }: ITextEditorProps, reference: ForwardedRef<null | TMUIRichTextEditorRef>) => {
  return <MUIRichTextEditor {...props} onChange={onChange} ref={reference} />
})

export default TextEditor

export const HeaderBlockStyle = (props: TAny) => {
  return <div className={props.readOnly ? 'TextEditor-readonlyStyle' : 'TextEditor-headerBlockStyle'}>{props.children}</div>
}

export const HeaderToolbarButton: FC<TToolbarComponentProps> = (props) => {
  return (
    <ToggleButton
      id={props.id}
      onMouseDown={(event) => {
        props.onMouseDown(event)
      }}
      className={props.active ? 'TextEditor-button TextEditor-button_active' : 'TextEditor-button'}
      disabled={props.disabled}
      size="small"
      value="header"
    >
      Heading
    </ToggleButton>
  )
}
