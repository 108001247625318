import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'

interface IProps {
  isWarningRedirect: boolean
  logout: () => void
  onClose: () => void
  open: boolean
}

const LogoutModal = ({ isWarningRedirect, logout, onClose, open }: IProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">Are you sure you want to log out? </DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          You will be signed out and your session will be terminated
        </DialogContentText>
        {isWarningRedirect && (
          <Alert severity="info" sx={{ marginTop: '16px' }}>
            After that you will need to follow the link again to set up password
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button color="error" onClick={logout} variant="contained">
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LogoutModal
