import './index.scss'

import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import Autocomplete, { renderOptions } from '@admin/components/shared/Autocomplete/Autocomplete'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import NumericFormatCustom from '@admin/components/shared/CurrencyFormatCustom/NumericFormatCustom'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Radio from '@admin/components/shared/Radio/Radio'
import RadioGroup from '@admin/components/shared/RadioGroup/RadioGroup'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITier } from '@admin/store/slices/Settings/interface'
import {
  fetchAddTier,
  fetchDropdownOptions,
  selectLinkIdOptions,
  selectOperationTypesOptions,
  selectProviderOptions,
} from '@admin/store/slices/Settings/tiersSlice'
import colors from '@admin/theme/constants/colors'

import type { TAny } from '@yzzy/types'

import { ConfirmCancelModal } from './ConfirmCancelModal'
import { getValidationSchema, renderLinkIdOptions } from './utils'

interface IAddTierModalProps {
  onClose: (isAddedNewTier?: boolean, newTier?: ITier) => void
  open: boolean
}

export const AddTierModal = ({ onClose, open }: IAddTierModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(), [])

  const providerOptions = useAppSelector(selectProviderOptions)
  const operationTypeOptions = useAppSelector(selectOperationTypesOptions)
  const linkIdOptions = useAppSelector(selectLinkIdOptions)

  const [selectedProvider, setSelectedProvider] = useState<null | { providerName: string; value: string }>(null)
  const [selectedOperationType, setSelectedOperationType] = useState<null | string>(null)
  const [selectedLinkId, setSelectedLinkId] = useState<null | string>(null)
  const [isSelectedPriceField, setIsSelectedPriceField] = useState(false)

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    resetField,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      providerId: '',
      country: 'US',
      currency: 'USD',
      linkId: '',
      operationType: '',
      price: '',
      productId: '',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      radioValue: 'new',
    },
    resolver: yupResolver(schema),
  })

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)

  const handleClose = (isAddedNewTier?: boolean, newTier?: ITier) => {
    reset()
    setSelectedProvider(null)
    setSelectedOperationType(null)
    setSelectedLinkId(null)
    onClose(isAddedNewTier, newTier)
  }

  const onSubmit = async (data: Omit<ITier, 'status'>) => {
    trigger()

    const formattedData = {
      name: data.name.trim(),
      providerId: data.providerId,
      country: data.country,
      currency: data.currency,
      linkId: data.linkId,
      operationType: data.operationType,
      price: data.price,
      productId: data.productId.trim(),
    }

    try {
      const result = await dispatch(fetchAddTier(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose(true, result.payload)
        enqueueSnackbar('Tier has been added', {
          variant: 'success' as VariantType,
        })
      } else if (result.payload.meta) {
        const { title, meta } = result.payload
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITier, 'status'>>

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: title ?? meta[key] }))
        } else {
          enqueueSnackbar('An error occurred while adding the tier', {
            variant: 'error' as VariantType,
          })
        }
      } else {
        enqueueSnackbar('An error occurred while adding the tier', {
          variant: 'error' as VariantType,
        })
      }
    } catch {
      enqueueSnackbar('An error occurred while adding the tier', {
        variant: 'error' as VariantType,
      })
    }
  }

  useEffect(() => {
    dispatch(fetchDropdownOptions({ additionalValue: '', fieldForDropdown: 'provider' }))
    dispatch(fetchDropdownOptions({ additionalValue: '', fieldForDropdown: 'operation-type' }))
  }, [dispatch])

  useEffect(() => {
    if (selectedProvider) {
      dispatch(fetchDropdownOptions({ additionalValue: selectedProvider?.value ?? '', fieldForDropdown: 'link-id' }))
    }
  }, [dispatch, selectedProvider])

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} disableScrollLock>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new tier</DialogTitle>
          <DialogContent>
            <DialogContentText color="text.primary" variant="body1">
              All fields are required
            </DialogContentText>
            <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" rowSpacing={2} container>
              <SimpleGrid xs={12} item>
                <Controller
                  name="providerId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        label="Provider"
                        inputprops={{
                          error: !!errors.providerId,
                        }}
                        onChange={(_, value) => {
                          setValue('providerId', value ? value.value : null, { shouldDirty: true })
                          setSelectedProvider(value)

                          trigger('providerId')
                        }}
                        clearIcon={null}
                        getOptionLabel={({ providerName }) => providerName}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onBlur={() => trigger('providerId')}
                        options={providerOptions || []}
                        renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.providerName, inputValue)}
                        size="small"
                        value={selectedProvider}
                      />
                      <FormHelperText error={!!errors.providerId}>{errors.providerId?.message as string}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('productId')}
                  fullWidth
                  label="Product ID"
                  name="productId"
                  error={!!errors.productId}
                  helperText={errors.productId && (errors.productId?.message as string)}
                  inputProps={{ maxLength: 50 }}
                  onBlur={() => trigger('productId')}
                  size="small"
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <TextField
                  {...register('name')}
                  fullWidth
                  label="Reference Name"
                  name="name"
                  error={!!errors.name}
                  helperText={errors.name && (errors.name?.message as string)}
                  inputProps={{ maxLength: 50 }}
                  onBlur={() => trigger('name')}
                  size="small"
                />
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <Box sx={{ border: `1px dashed ${colors.divider}`, borderRadius: 2, p: 1 }}>
                  <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" rowSpacing={2} container>
                    <SimpleGrid xs={12} item>
                      <TextField
                        {...register('price')}
                        fullWidth
                        label="Price"
                        name="price"
                        InputProps={{
                          inputComponent: NumericFormatCustom as TAny,
                          inputProps: {
                            decimalScale: 2,
                            fixedDecimalScale: 2,
                          },
                          startAdornment: isSelectedPriceField ? (
                            <InputAdornment color="text.primary" position="start">
                              <Typography color="action.disabled" variant="body1">
                                $
                              </Typography>
                            </InputAdornment>
                          ) : undefined,
                        }}
                        onBlur={() => {
                          setIsSelectedPriceField(false)

                          trigger('price')
                        }}
                        error={!!errors.price}
                        helperText={errors.price && (errors.price?.message as string)}
                        onFocus={() => setIsSelectedPriceField(true)}
                        placeholder={isSelectedPriceField ? '0.00' : undefined}
                        size="small"
                      />
                    </SimpleGrid>
                    <SimpleGrid xs={12} item>
                      <Controller
                        name="country"
                        render={() => (
                          <FormControl fullWidth>
                            <Autocomplete
                              label="Country"
                              inputprops={{
                                error: !!errors.country,
                              }}
                              clearIcon={null}
                              onBlur={() => trigger('country')}
                              // isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                              // getOptionLabel={({ country }) => country}
                              options={[]}
                              size="small"
                              value="US"
                              disabled
                            />
                            <FormHelperText error={!!errors.country}>{errors.country?.message as string}</FormHelperText>
                          </FormControl>
                        )}
                        control={control}
                      />
                      {errors.country && <FormHelperText error>{errors.country?.message as string}</FormHelperText>}
                    </SimpleGrid>
                    <SimpleGrid xs={12} item>
                      <Controller
                        name="currency"
                        render={() => (
                          <FormControl fullWidth>
                            <Autocomplete
                              label="Currency"
                              inputprops={{
                                error: !!errors.currency,
                              }}
                              clearIcon={null}
                              onBlur={() => trigger('currency')}
                              // isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                              // getOptionLabel={({ country }) => country}
                              options={[]}
                              size="small"
                              value="USD"
                              disabled
                            />
                            <FormHelperText error={!!errors.currency}>{errors.currency?.message as string}</FormHelperText>
                          </FormControl>
                        )}
                        control={control}
                      />
                      {errors.currency && <FormHelperText error>{errors.currency?.message as string}</FormHelperText>}
                    </SimpleGrid>
                  </SimpleGrid>
                </Box>
              </SimpleGrid>
              <SimpleGrid xs={12} item>
                <Controller
                  name="operationType"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        label="Operation type"
                        inputprops={{
                          error: !!errors.operationType,
                        }}
                        onChange={(_, value) => {
                          setValue('operationType', value ? value.value : null, { shouldDirty: true })
                          setSelectedOperationType(value)

                          trigger('operationType')
                        }}
                        clearIcon={null}
                        getOptionLabel={({ operationTypeDescription }) => operationTypeDescription}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onBlur={() => trigger('operationType')}
                        options={operationTypeOptions || []}
                        renderOption={(parameters, option, { inputValue }) => renderOptions(parameters, option.operationTypeDescription, inputValue)}
                        size="small"
                        value={selectedOperationType}
                      />
                      <FormHelperText error={!!errors.operationType}>{errors.operationType?.message as string}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>

              <SimpleGrid xs={12} item>
                <FormControl>
                  <Typography variant="subtitle2">Link ID</Typography>
                  <Controller
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    name="radioValue"
                    render={({ field }) => {
                      return (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            label="Create a new ID link"
                            onClick={() => {
                              setSelectedLinkId(null)
                              resetField('linkId')
                            }}
                            control={<Radio />}
                            sx={{ ml: 'unset' }}
                            value="new"
                          />
                          <FormControlLabel label="Select an ID link from the existing ones" control={<Radio />} sx={{ ml: 'unset' }} value="list" />
                        </RadioGroup>
                      )
                    }}
                    control={control}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid xs={12} item>
                <Controller
                  name="linkId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        label="Link ID"
                        inputprops={{
                          error: !!errors.linkId,
                        }}
                        onChange={(_, value) => {
                          setValue('linkId', value ? value.value : null, { shouldDirty: true })
                          setSelectedLinkId(value)
                          trigger('linkId')
                        }}
                        clearIcon={null}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        disabled={watch('radioValue') === 'new' || !selectedProvider}
                        getOptionLabel={({ value }) => value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onBlur={() => trigger('linkId')}
                        options={linkIdOptions || []}
                        renderOption={(props, option, { inputValue }) => renderLinkIdOptions(props, option, inputValue)}
                        size="small"
                        value={selectedLinkId}
                      />
                      <FormHelperText error={!!errors.linkId}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-expect-error */}
                        {watch('radioValue') !== 'new' && !errors.linkId && !selectedProvider
                          ? 'Choose a provider first'
                          : (errors.linkId?.message as string)}
                      </FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (isDirty) {
                  setIsOpenConfirmCancelModal(true)
                } else {
                  handleClose()
                }
              }}
              color="primary"
              variant="text"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              // disabled={!isValid || !isDirty}
              variant="contained"
            >
              Add tier
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleClose} open={isOpenConfirmCancelModal} />
    </>
  )
}
