import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import TextField from '@admin/components/shared/TextField/TextField'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchBanUser, fetchRestoreUser, selectBanUserStatus, selectRestoreUserStatus } from '@admin/store/userProfileSlice'

import { IUserStatus, UserStatuses } from '../interface'
import getValidationSchema from '../utils/getValidationSchema'
import { ConfirmCancelModal } from './ConfirmCancelModal'

interface IChangeStatusModalProps {
  currentSelectedStatus: IUserStatus | null
  onClose: () => void
  onDataRefresh: () => void
  open: boolean
  profileId: string
}

export const ChangeUserStatusModal = ({ currentSelectedStatus, onClose, onDataRefresh, open, profileId }: IChangeStatusModalProps) => {
  const dispatch = useAppDispatch()
  const schema = useMemo(() => getValidationSchema(), [])

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)
  const [isShowError, setIsShowError] = useState(false)

  const restoreStatus = useAppSelector(selectRestoreUserStatus)
  const banStatus = useAppSelector(selectBanUserStatus)

  const {
    formState: { isValid, errors, isDirty },
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(schema),
  })

  const confirmButtonColor = currentSelectedStatus?.status === UserStatuses.BANNED ? 'error' : 'primary'
  const isConfirmButtonDisabled = currentSelectedStatus?.status === UserStatuses.ACTIVE ? false : !isValid || !isDirty

  const handleClose = () => {
    setIsShowError(false)
    reset()
    onClose()
  }

  const onSubmit = async (data: { comment?: string }) => {
    trigger()

    let isResultReceived = false
    let isTimerElapsed = false

    const timer = setTimeout(() => {
      isTimerElapsed = true
      if (!isResultReceived) {
        handleClose()
        enqueueSnackbar(`User #${profileId} changing status in progress`, {
          variant: 'info' as VariantType,
        })
      }
    }, 10000)

    const formattedData = {
      comment: data.comment?.trim() || '',
      profileId: profileId,
    }

    try {
      let result

      if (currentSelectedStatus?.status === UserStatuses.BANNED) {
        result = await dispatch(fetchBanUser(formattedData))
      } else if (currentSelectedStatus?.status === UserStatuses.ACTIVE) {
        result = await dispatch(fetchRestoreUser({ profileId: profileId }))
      }

      if (result) {
        if (result.meta.requestStatus === 'fulfilled') {
          isResultReceived = true
          clearTimeout(timer)
          enqueueSnackbar(`User #${profileId} status has been changed to ${currentSelectedStatus?.status?.toUpperCase()}`, {
            variant: 'success' as VariantType,
          })
          handleClose()
          onDataRefresh()
        } else if (result.meta.requestStatus === 'rejected') {
          isResultReceived = true
          clearTimeout(timer)
          if (currentSelectedStatus?.status === UserStatuses.BANNED) {
            setIsShowError(true)
            if (isTimerElapsed) {
              enqueueSnackbar('Changing status error, try again later', {
                variant: 'error' as VariantType,
              })
            }
          } else if (currentSelectedStatus?.status === UserStatuses.ACTIVE) {
            enqueueSnackbar('Changing status error, try again later', {
              variant: 'error' as VariantType,
            })
          }
        }
      }
    } catch {
      clearTimeout(timer)
      enqueueSnackbar('Changing status error, try again later', {
        variant: 'error' as VariantType,
      })
    }
  }

  const onCancel = () => {
    if (isDirty) {
      setIsOpenConfirmCancelModal(true)
    } else {
      handleClose()
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">You are going to change user’s status to {currentSelectedStatus?.status}</DialogTitle>
          <DialogContent>
            {currentSelectedStatus?.status === UserStatuses.BANNED && (
              <TextField
                {...register('comment')}
                fullWidth
                label="Comment"
                name="comment"
                error={!!errors.comment}
                helperText={errors.comment && (errors.comment?.message as string)}
                inputProps={{ maxLength: 640 }}
                onBlur={() => trigger('comment')}
                placeholder="Reason of ban or another info"
                rows={5}
                size="small"
                sx={{ m: '8px 0' }}
                type="text"
                multiline
              />
            )}
            {isShowError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Changing status error, try again later
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onCancel} variant="text">
              Cancel
            </Button>
            <LoadingButton
              color={confirmButtonColor}
              disabled={isConfirmButtonDisabled}
              loading={restoreStatus === 'loading' || banStatus === 'loading'}
              type="submit"
              variant="contained"
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleClose} open={isOpenConfirmCancelModal} />
    </>
  )
}
