import { GridRenderCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderDeadlineCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - The properties of the RenderDeadlineCell component.
 * */
interface IRenderDeadlineCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a deadline cell for deleted user in a data grid.
 */
export const RenderDeadlineCell = ({ props }: IRenderDeadlineCellProps) => {
  const { value } = props

  const now = dayjs()
  const deadline = dayjs(value)

  if (now.isAfter(deadline)) {
    return (
      <Typography color="text.primary" variant="body2">
        Over
      </Typography>
    )
  }

  const duration = dayjs.duration(deadline.diff(now))
  const totalHours = duration.asHours()
  const days = Math.floor(totalHours / 24)
  const hours = Math.floor(totalHours % 24)

  const getDisplayText = () => {
    if (days > 0 && hours < 1) {
      return `${days} ${days > 1 ? 'days' : 'day'}`
    }
    if (days === 0 && hours >= 1) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    }
    if (days === 0 && hours < 1) {
      return 'less than 1 hour'
    }

    return `${days} ${days > 1 ? 'days' : 'day'} ${hours} ${hours > 1 ? 'hours' : 'hour'}`
  }

  const displayText = getDisplayText()

  return (
    <Tooltip title={deadline.format('MM/DD/YYYY, hh:mm A')} placement="top-end" followCursor>
      <Typography color="text.primary" variant="body2">
        {displayText}
      </Typography>
    </Tooltip>
  )
}
