import './index.scss'

import { Fragment, HTMLAttributes, Key, ReactNode, useEffect, useState } from 'react'

import { Autocomplete as AutocompleteBase, AutocompleteRenderGroupParams, Collapse, InputLabelProps, useTheme } from '@mui/material'
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import startCase from 'lodash/startCase'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Box from '@admin/components/shared/Box/Box'
import Checkbox from '@admin/components/shared/Checkbox/Checkbox'
import Divider from '@admin/components/shared/Divider/Divider'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'

import type { TAny } from '@yzzy/types'

interface IAutocompleteProps extends AutocompleteProps<TAny, boolean, boolean, boolean> {
  helperText?: string
  inputlabelprops?: Partial<InputLabelProps>
  inputprops?: Partial<StandardInputProps>
  label?: string
  maxLength?: number
  options: TAny[]
}

/**
 * Render highlighted options for an Autocomplete component.
 *
 * @param {HTMLAttributes<HTMLLIElement>} props - HTML attributes for the list item.
 * @param {string} option - The option to be displayed, which may contain the inputValue.
 * @param {string} inputValue - The current input value for highlighting.
 * @param {boolean} [selected] - Indicates whether the option is selected (for multiple selection).
 * @param {boolean} [multiple] - Indicates whether the Autocomplete allows multiple selection.
 * @param {boolean} [allSelected] - Indicates whether all options are selected (for "Select All" functionality).
 * @param {boolean} [multipleTitles] - Determines if multiple title formats should be displayed.
 * @param {string} [primary] - Primary text to display for the option.
 * @param {string} [secondary] - Secondary text to display (additional context) for the option.
 *
 * @returns {ReactNode} React element representing the highlighted option.
 */
export const renderOptions = (
  props: HTMLAttributes<HTMLLIElement> & {
    key: string
  },
  option: string,
  inputValue: string,
  selected?: boolean,
  multiple?: boolean,
  allSelected?: boolean,
  multipleTitles?: boolean,
  primary?: string,
  secondary?: string,
): ReactNode => {
  const { key, ...rest } = props
  const matches = match(option, inputValue, { insideWords: true })
  const parts = parse(option, matches)
  const selectAllProps = option.toLowerCase().startsWith('all') ? { checked: allSelected } : {}

  const matchesPrimary = match(primary || '', inputValue, { insideWords: true })
  const partsPrimary = parse(primary || '', matchesPrimary)

  const matchesSecondary = match(secondary || '', inputValue, { insideWords: true })
  const partsSecondary = parse(secondary || '', matchesSecondary)

  return (
    <Fragment key={key}>
      <li {...rest}>
        {multiple && <Checkbox checked={selected} style={{ marginRight: 8 }} {...selectAllProps} />}
        {multipleTitles ? (
          <ListItemText
            primary={
              <Typography color="text.primary" variant="body2">
                {partsPrimary.map((part, index: Key) => (
                  <span key={`${part.text}-${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {partsSecondary.map((part, index: Key) => (
                  <span key={`${part.text}-${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
            }
            sx={{ wordBreak: 'break-all' }}
            disableTypography
          />
        ) : (
          <Typography variant="body1">
            {parts.map((part, index: Key) => (
              <span key={`${part.text}-${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </Typography>
        )}
      </li>
      {multiple && option.toLowerCase().startsWith('all') && <Divider orientation="horizontal" flexItem />}
    </Fragment>
  )
}

export const CollapseGroup = ({ inputValue, params }: { inputValue?: string; params: AutocompleteRenderGroupParams }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (inputValue) {
      setOpen(true)
    }
  }, [inputValue])

  return (
    <Box p="4px 0">
      <IconButton aria-label="expand row" onClick={() => setOpen(!open)} size="small" disableRipple>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </IconButton>
      <Typography display="inline-block" onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }} variant="body2Bold">
        {startCase(params.group?.toLowerCase())}
      </Typography>
      <Collapse in={open} timeout="auto">
        {params.children}
      </Collapse>
    </Box>
  )
}

const Autocomplete = (props: Omit<IAutocompleteProps, 'renderInput'>) => {
  const theme = useTheme()

  return (
    <AutocompleteBase
      {...props}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          label={props.label}
          InputLabelProps={{
            ...parameters.InputLabelProps,
            ...props.inputlabelprops,
          }}
          InputProps={{
            ...parameters.InputProps,
            ...props.inputprops,
          }}
          inputProps={{
            ...parameters.inputProps,
            maxLength: props.maxLength,
          }}
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              color: theme.palette.common.black,
            },
            '& .MuiAutocomplete-clearIndicator': {
              display: props.clearIcon === null ? 'none' : 'inline-flex',
            },
            ...props.sx,
          }}
          error={props.inputprops?.error}
          helperText={props.helperText}
          variant="outlined"
        />
      )}
    />
  )
}

export default Autocomplete
