import { GridRenderCellParams } from '@mui/x-data-grid'

import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { possibleOperationTypes } from '@admin/containers/Payments/views/Operations/constants'

/**
 * Props for the RenderOperationTypeCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderOperationTypeCellProps {
  props: GridRenderCellParams
}

/**
 * Renders an "Operation type" cell in a data grid.
 */
const RenderOperationTypeCell = ({ props }: IRenderOperationTypeCellProps) => {
  const { row } = props

  if (!row.id && !row.consumptionRequestId) {
    return null
  }

  const currentOperationType = possibleOperationTypes.find((element) => row.operationType?.toLowerCase() === element.operationType?.toLowerCase())

  return (
    <Stack>
      <Typography variant="body2">{currentOperationType?.title}</Typography>
      <Typography color="text.secondary" variant="caption">
        {currentOperationType?.subtitle}
      </Typography>
    </Stack>
  )
}

export default RenderOperationTypeCell
