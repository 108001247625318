import './index.scss'

import { FC, useRef, useState } from 'react'

import { InputBaseProps } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { BaseTextFieldProps } from '@mui/material/TextField/TextField'

import CloseIcon from '@admin/assets/img/CloseIcon'
import SearchIcon from '@admin/assets/img/SearchIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import TextField from '@admin/components/shared/TextField/TextField'
import colors from '@admin/theme/constants/colors'

/**
 * Props for a search field component.
 *
 * @interface
 * @extends BaseTextFieldProps - Inherited properties from a base text field component.
 * @property {string} searchValue - The current value of the search field.
 * @property {string} label - The label for the search field.
 * @property {string} [placeholder] - The placeholder text for the search field.
 * @property {string} [customWidth] - Custom width for SearchField.
 * @property {number} [maxlength] - The maximum number of characters allowed in the search field (optional).
 * @property {boolean} [disabled] - If true, the field is disabled.
 * @property {StandardInputProps['onChange']} onChange - A function to handle changes in the search field input.
 * @property {() => void} [searchEvent] - An optional callback function to trigger a search event.
 * @property {() => void} [clearEvent] - An optional callback function to trigger a clear event.
 * @property {Partial<InputBaseProps['inputProps']>} [inputProps] - Additional properties to be passed down to the input element.
 * @property {(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void} [onBlur] - A function to handle the blur event.
 * @property {(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void} [onFocus] - A function to handle the focus event.
 * @property {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} [onClick] - A function to handle the click event.
 */
interface ISearchFieldProps extends BaseTextFieldProps {
  clearEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void
  customWidth?: string
  disabled?: boolean
  inputProps?: Partial<InputBaseProps['inputProps']>
  label: string
  maxlength?: number
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
  onChange: StandardInputProps['onChange']
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
  placeholder?: string
  searchEvent?: () => void
  searchValue: string
}

const SearchField: FC<ISearchFieldProps> = (props) => {
  const {
    customWidth,
    label,
    clearEvent,
    disabled,
    inputProps,
    maxlength,
    onBlur,
    onChange,
    onClick,
    onFocus,
    placeholder,
    searchEvent,
    searchValue,
  } = props

  const [shrink, setShrink] = useState(false)
  const inputReference = useRef<HTMLInputElement>(null)

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    clearEvent?.(event)

    if (inputReference.current) {
      inputReference.current.focus()
    }
  }

  const startAdornment = (
    <InputAdornment position="start">
      <SearchIcon color="disabled" />
    </InputAdornment>
  )

  const endAdornment = (
    <InputAdornment position="end">
      {searchValue.length > 0 && (
        <IconButton color="secondary" onClick={handleClear} size="small">
          <CloseIcon />
        </IconButton>
      )}
    </InputAdornment>
  )

  return (
    <div className="searchField">
      <TextField
        id="search-field"
        label={label || 'Search'}
        name="searchField"
        InputLabelProps={{
          shrink: shrink,
        }}
        InputProps={{
          endAdornment: endAdornment,
          startAdornment: startAdornment,
        }}
        onBlur={(event) => {
          !event.target.value && setShrink(false)
          onBlur?.(event)
        }}
        onFocus={(event) => {
          setShrink(true)
          onFocus?.(event)
        }}
        onKeyDown={(event) => {
          ;(event.key === 'Enter' || event.code === '13') && searchEvent && searchEvent()
        }}
        sx={{
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            color: colors.outlinedBorder,
            transform: 'translate(44px, 9px)',
          },
          width: customWidth,
        }}
        disabled={disabled}
        inputProps={{ maxLength: maxlength, ...inputProps }}
        inputRef={inputReference}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        size="small"
        value={searchValue}
      />
    </div>
  )
}

export default SearchField
