import { ITierStatus, TierStatuses } from '@admin/store/slices/Settings/interface'
import colors from '@admin/theme/constants/colors'
import { ISortingItem } from '@admin/types/commonTypes'

export const defaultTiersSortingModel: ISortingItem = {
  title: 'Time added',
  columnId: 'created_date',
  sortingType: 'DESC',
}

export const possibleTierStatuses: ITierStatus[] = [
  {
    title: 'Active',
    color: colors.success,
    status: TierStatuses.ACTIVE,
  },
  {
    title: 'Inactive',
    color: colors.error,
    status: TierStatuses.INACTIVE,
  },
]

export const currencyTooltipTitles: { [currencyCode: string]: string } = {
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  EUR: 'Euro',
  GBP: 'British Pound',
  JPY: 'Japanese Yen',
  USD: 'US dollar',
}
