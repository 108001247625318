import { motion } from 'motion/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import GoodJob from '@admin/assets/img/GoodJob'
import Button from '@admin/components/shared/Button/Button'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { TaxModerationRoutes } from '@admin/routes/enum'
import { useAppSelector } from '@admin/store/hooks'
import { selectTaxModerationCardIds } from '@admin/store/slices/TaxModeration/taxModerationSlice'

import { TaxModerationListType } from '../constants'

export const CustomNoCardsOverlay = () => {
  const allIds = useAppSelector(selectTaxModerationCardIds)
  const navigate = useNavigate()

  useEffect(() => {
    if (allIds.length !== 0) {
      navigate(TaxModerationRoutes.TAX_MODERATION, { state: { listType: TaxModerationListType.UNRESOLVED } })
    }
  }, [allIds.length])

  return (
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ margin: 'auto', opacity: 0 }} transition={{ duration: 0.5 }}>
      <Stack alignItems="center" mt={2}>
        <Typography variant="errorTitle">The end</Typography>
        <Typography color="text.secondary" variant="errorSubtitle">
          No unresolved request cards
        </Typography>
        <GoodJob />
        <Button
          color="primary"
          onClick={() => navigate(TaxModerationRoutes.TAX_MODERATION, { state: { listType: TaxModerationListType.SOLVED } })}
          variant="contained"
        >
          Go to solved cards
        </Button>
      </Stack>
    </motion.div>
  )
}
