import { Box, css, styled, Theme } from '@mui/material'

import { squareBlockCSS, styledOmitCustomProps } from '../../utils'
import { IBadgeProps } from './types'

const getColors = (theme: Theme, type: IBadgeProps['type'] = 'OFFLINE') => {
  const COLORS = {
    CANCELLED: {
      backgroundColor: theme.palette.orange[500],
      color: theme.palette.common['white'],
    },
    EXPIRED: {
      backgroundColor: theme.palette.orange[500],
      color: theme.palette.common['white'],
    },
    OFFLINE: {
      backgroundColor: theme.palette.violet[500],
      color: theme.palette.common['white'],
    },
    ONLINE: {
      backgroundColor: theme.palette.success[500],
      color: theme.palette.common['white'],
    },
    TEXT: {
      backgroundColor: theme.palette.gray[300],
      color: theme.palette.common['white'],
    },
  } as const

  return COLORS[type]
}

interface ISBadgeProps {
  $disabled?: IBadgeProps['disabled']
  $type?: IBadgeProps['type']
}

export const SBadge = styled(
  Box,
  styledOmitCustomProps,
)<ISBadgeProps>(({ $disabled, $type = 'OFFLINE', theme }) => {
  const colors = getColors(theme, $type)

  return css`
    display: inline-flex;
    align-items: center;
    min-height: 20px;
    padding: 4px 8px;
    background: ${colors['backgroundColor']};
    color: ${colors['color']};
    border-radius: 5px;
    gap: 4px;

    & > span {
      white-space: nowrap;
    }

    & > svg {
      ${squareBlockCSS(12)};
    }

    ${$disabled &&
    css`
      background: ${theme.palette.gray[200]};
    `}
  `
})
