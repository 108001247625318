// TODO: Remove after https://clearmindapp.atlassian.net/browse/DO-157
export const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'local'
    case 'qa.tim.ws':
      return 'qa'
    case 'tim.ws':
      return 'production'
    case 'uat.tim.ws':
      return 'uat'
  }
}
