import './index.scss'

import { useState } from 'react'

import ArchiveIcon from '@admin/assets/img/ArchiveIcon'
import CalendarExpiredIcon from '@admin/assets/img/CalendarExpiredIcon'
import CheckIcon from '@admin/assets/img/CheckIcon'
import ContextIcon from '@admin/assets/img/ContextIcon'
import DeleteIcon from '@admin/assets/img/DeleteIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import WorldSendIcon from '@admin/assets/img/WorldSendIcon'
import Divider from '@admin/components/shared/Divider/Divider'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { ICohort } from '@admin/store/cohortDetailsSlice'
import { ICollection } from '@admin/store/collectionDetailsSlice'

interface IProps {
  item: IBanner | ICohort | ICollection
  setArchiveBanners?: (item: IBanner) => void
  setArchiveCollections?: (item: ICollection) => void
  setDeleteCohorts?: (item: ICohort) => void
  setEditCohorts?: (itemId: string) => void
  setExpiredBanners?: (item: IBanner) => void
  setLinkedCollections?: (item: IBanner) => void
  setPublishCollections?: (item: ICollection) => void
  setUnpublishCollections?: (item: ICollection) => void
}

const ContextMenu = ({
  item,
  setArchiveBanners,
  setArchiveCollections,
  setDeleteCohorts,
  setEditCohorts,
  setExpiredBanners,
  setLinkedCollections,
  setPublishCollections,
  setUnpublishCollections,
}: IProps) => {
  const [anchorElementContextMenu, setAnchorElementContextMenu] = useState<HTMLElement | null>(null)
  const openContextMenu = Boolean(anchorElementContextMenu)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementContextMenu(event.currentTarget)
    event.stopPropagation()
  }
  const handleClose = () => {
    setAnchorElementContextMenu(null)
  }

  return (
    <div className="worldContextMenu">
      <IconButton
        id="basic-button"
        aria-controls={openContextMenu ? 'basic-menu' : undefined}
        aria-expanded={openContextMenu ? 'true' : undefined}
        aria-haspopup="true"
        className="worldAction"
        onClick={handleClick}
      >
        <ContextIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        aria-labelledby="basic-button"
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorEl={anchorElementContextMenu}
        className="worldContextMenu-menu"
        onClose={handleClose}
        open={openContextMenu}
      >
        {setPublishCollections && item.status === 'INCOMPLETE' && (
          <Tooltip title="You can not publish incomplete collection" placement="top-start">
            <span>
              <MenuItem
                onClick={() => {
                  handleClose()
                  setPublishCollections(item as ICollection)
                }}
                disabled={true}
              >
                <CheckIcon />
                Publish now
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {setPublishCollections && (item.status === 'READY' || item.status === 'SCHEDULED') && (
          <MenuItem
            onClick={() => {
              handleClose()
              setPublishCollections(item as ICollection)
            }}
            disabled={false}
          >
            <CheckIcon />
            Publish now
          </MenuItem>
        )}
        {setUnpublishCollections && item.status === 'LIVE' && (
          <MenuItem
            onClick={() => {
              handleClose()
              setUnpublishCollections(item as ICollection)
            }}
          >
            <CheckIcon />
            Unpublish now
          </MenuItem>
        )}
        {setLinkedCollections && (
          <MenuItem
            onClick={() => {
              handleClose()
              setLinkedCollections(item as IBanner)
            }}
            disabled={item.status === 'INCOMPLETE'}
          >
            <WorldSendIcon />
            Manage linked collections
          </MenuItem>
        )}
        {setEditCohorts && item.status === 'ACTIVE' && (
          <Tooltip title="You can not edit cohorts in an active status" placement="top-start">
            <span>
              <MenuItem
                onClick={() => {
                  handleClose()
                  setEditCohorts((item as ICohort).id)
                }}
                disabled={true}
              >
                <EditIcon />
                Edit
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {setEditCohorts && item.status !== 'ACTIVE' && (
          <MenuItem
            onClick={() => {
              handleClose()
              setEditCohorts((item as ICohort).id)
            }}
            disabled={false}
          >
            <EditIcon />
            Edit
          </MenuItem>
        )}
        {item.status !== 'DEACTIVATED' && <Divider flexItem />}
        {setArchiveCollections && item.status !== 'LIVE' && (
          <MenuItem
            onClick={() => {
              handleClose()
              setArchiveCollections(item as ICollection)
            }}
            disabled={false}
          >
            <ArchiveIcon />
            Archive
          </MenuItem>
        )}
        {setArchiveCollections && item.status === 'LIVE' && (
          <Tooltip title="You can not archive live collection" placement="top-start">
            <span>
              <MenuItem
                onClick={() => {
                  handleClose()
                  setArchiveCollections(item as ICollection)
                }}
                disabled={true}
              >
                <ArchiveIcon />
                Archive
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {setExpiredBanners && (
          <MenuItem
            onClick={() => {
              handleClose()
              setExpiredBanners(item as IBanner)
            }}
            disabled={item.status === 'EXPIRED'}
          >
            <CalendarExpiredIcon />
            Set as expired
          </MenuItem>
        )}
        {setArchiveBanners && (
          <MenuItem
            onClick={() => {
              handleClose()
              setArchiveBanners(item as IBanner)
            }}
            disabled={item.status === 'LIVE'}
          >
            <ArchiveIcon />
            Archive
          </MenuItem>
        )}
        {setDeleteCohorts && (item as ICohort).hasCompilation && (
          <Tooltip title="You can not delete cohorts linked to collections" placement="top-start">
            <span>
              <MenuItem
                onClick={() => {
                  handleClose()
                  setDeleteCohorts(item as ICohort)
                }}
                disabled={true}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {setDeleteCohorts && !(item as ICohort).hasCompilation && (
          <MenuItem
            onClick={() => {
              handleClose()
              setDeleteCohorts(item as ICohort)
            }}
            disabled={false}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default ContextMenu
