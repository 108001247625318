const Users2Icon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M9.80005 2.6499C6.92858 2.6499 4.55005 4.9057 4.55005 7.7499C4.55005 10.5941 6.92858 12.8499 9.80005 12.8499C12.6715 12.8499 15.05 10.5941 15.05 7.7499C15.05 4.9057 12.6715 2.6499 9.80005 2.6499ZM6.25005 7.7499C6.25005 5.89968 7.81141 4.3499 9.80005 4.3499C11.7887 4.3499 13.35 5.89968 13.35 7.7499C13.35 9.60012 11.7887 11.1499 9.80005 11.1499C7.81141 11.1499 6.25005 9.60012 6.25005 7.7499Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.79985 14.3374C8.06886 14.3374 6.29886 14.63 4.92886 15.2143C3.61954 15.7728 2.34985 16.7563 2.34985 18.2864C2.34985 18.9469 2.69463 19.4723 3.13834 19.8504C3.57245 20.2204 4.15091 20.4982 4.79071 20.7084C6.07666 21.1311 7.82532 21.3499 9.79985 21.3499C11.5112 21.3499 13.2501 21.1284 14.5893 20.7127C15.2541 20.5064 15.8726 20.2364 16.343 19.8832C16.8067 19.5351 17.2499 19.006 17.2499 18.2864C17.2499 17.5606 16.9081 16.9448 16.4439 16.4644C15.9836 15.988 15.3649 15.6012 14.6843 15.2969C13.3214 14.6874 11.5502 14.3374 9.79985 14.3374ZM4.04985 18.2864C4.04985 17.8413 4.43017 17.2753 5.59584 16.778C6.70085 16.3067 8.23085 16.0374 9.79985 16.0374C11.3495 16.0374 12.8783 16.3515 13.9904 16.8488C14.5473 17.0978 14.9599 17.3751 15.2214 17.6457C15.4764 17.9096 15.5483 18.1221 15.5498 18.2812C15.5435 18.3026 15.5059 18.3859 15.3223 18.5238C15.0708 18.7126 14.6582 18.9114 14.0854 19.0891C12.9496 19.4417 11.3885 19.6499 9.79985 19.6499C7.92439 19.6499 6.37305 19.439 5.32149 19.0934C4.79255 18.9196 4.44288 18.7286 4.24105 18.5566C4.04982 18.3936 4.04985 18.3035 4.04985 18.2864Z"
      fillRule="evenodd"
    />
    <path d="M15.5538 13.5652C15.5973 13.0978 16.0115 12.7542 16.4789 12.7977C17.8028 12.9209 19.0262 13.253 19.9473 13.7674C20.8349 14.2632 21.6501 15.0596 21.6501 16.1657C21.6501 16.7411 21.4219 17.2593 20.9885 17.667C20.5802 18.0511 20.0209 18.31 19.3746 18.487C18.9218 18.611 18.4543 18.3444 18.3303 17.8917C18.2063 17.4389 18.4728 16.9713 18.9256 16.8473C19.4287 16.7096 19.6944 16.5504 19.8237 16.4287C19.928 16.3307 19.9501 16.2555 19.9501 16.1657C19.9501 15.986 19.8089 15.6374 19.1183 15.2516C18.4611 14.8846 17.4846 14.5986 16.3213 14.4903C15.8539 14.4468 15.5103 14.0326 15.5538 13.5652Z" />
    <path d="M16.4 4.7749C15.9306 4.7749 15.55 5.15546 15.55 5.6249C15.55 6.09434 15.9306 6.4749 16.4 6.4749C17.4774 6.4749 18.3001 7.31114 18.3001 8.28115C18.3001 9.25117 17.4774 10.0874 16.4 10.0874C15.9306 10.0874 15.55 10.468 15.55 10.9374C15.55 11.4068 15.9306 11.7874 16.4 11.7874C18.3602 11.7874 20 10.2452 20 8.28115C20 6.31716 18.3602 4.7749 16.4 4.7749Z" />
  </svg>
)

export default Users2Icon
