import { MouseEvent, useState } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { useHover } from '@uidotdev/usehooks'
import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { IOperation, TransactionTypes } from '@admin/store/slices/Payments/interface'
import { fetchUserProfile, selectUserProfile } from '@admin/store/userProfileSlice'

import { ProfilePopover } from '../modals'

/**
 * Props for the RenderRecipientCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderRecipientCellProps {
  props: GridRenderCellParams
  rowData: IOperation
}

/**
 * Renders a "Recipient profile ID" cell in a data grid.
 */
export const RenderRecipientCell = ({ props, rowData }: IRenderRecipientCellProps) => {
  const { row } = props
  const dispatch = useAppDispatch()
  const profileInfo = useAppSelector(selectUserProfile)

  const [reference, hovering] = useHover<HTMLDivElement>()
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [buttonLoadingState, setButtonLoadingState] = useState<{ [profileId: string]: boolean }>({})

  const handleLoadProfileInfo = async (profileId: string) => {
    setButtonLoadingState((previousLoadingStates) => ({
      ...previousLoadingStates,
      [profileId]: true,
    }))

    const result = await dispatch(fetchUserProfile(profileId))

    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load the profile, try again later', {
        variant: 'error' as VariantType,
      })
    }

    setButtonLoadingState((previousLoadingStates) => ({
      ...previousLoadingStates,
      [profileId]: false,
    }))
  }

  const copyId = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation()

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', { variant: 'success' as VariantType })

      return navigator.clipboard.writeText(value)
    }
  }

  const isSystemRecipient =
    row.type === TransactionTypes.CARD_PAYMENTS ||
    row.type === TransactionTypes.REVERSAL_TRANSFER ||
    row.type === TransactionTypes.APPLE_IAP ||
    row.type === TransactionTypes.GOOGLE_IAP

  const isRefund = row.type === TransactionTypes.REFUND
  const recipientProfileId = isRefund ? rowData.profileId : rowData.recipientProfileId

  if (!row.id) {
    return null
  }

  return (
    <div className="MuiDataGrid-cellContent" ref={reference}>
      <Stack>
        <Stack alignItems="center" direction="row" spacing={1} sx={{ minHeight: 28 }}>
          <Tooltip title={!isSystemRecipient && recipientProfileId} placement="top">
            <div style={{ display: 'inline-grid' }}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                component="span"
                variant="body2"
              >
                {(isSystemRecipient ? 'YZZY' : recipientProfileId) || '—'}
              </Typography>
            </div>
          </Tooltip>

          {hovering && !isSystemRecipient && recipientProfileId && (
            <Tooltip title="Copy" placement="top-end">
              <IconButton color="default" onClick={(event) => copyId(event, recipientProfileId)} size="small">
                <CopyIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {!isSystemRecipient && recipientProfileId && (
          <>
            <ProfilePopover
              anchorEl={anchorElement}
              coordinates={coordinates}
              copyId={copyId}
              onClose={() => setAnchorElement(null)}
              profileId={recipientProfileId}
              profileInfo={profileInfo}
            />
            <LoadingButton
              onClick={(event) => {
                void handleLoadProfileInfo(recipientProfileId)
                setAnchorElement(event.currentTarget)
                setCoordinates({ x: event.clientX, y: event.clientY })
              }}
              loading={buttonLoadingState[recipientProfileId] || false}
              size="small"
              sx={{ minWidth: 'unset', mr: 'auto', p: '4px 0' }}
              variant="text"
            >
              More
            </LoadingButton>
          </>
        )}
      </Stack>
    </div>
  )
}
