import { createSvgIcon } from '@mui/material'

const FilterIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M17.8501 2.9999C17.8501 2.53046 17.4695 2.1499 17.0001 2.1499C16.5307 2.1499 16.1501 2.53046 16.1501 2.9999L16.1501 5.9999C16.1501 6.07096 16.1588 6.13997 16.1752 6.20594C15.4763 6.31166 14.9124 6.57851 14.5504 7.1026C14.32 7.43624 14.229 7.79915 14.1882 8.11134C14.1497 8.40616 14.1497 8.71678 14.1498 8.97457L14.1498 8.9999L14.1496 9.0746C14.1481 9.51407 14.1455 10.2509 14.5425 10.8464C15.0262 11.5719 15.8875 11.8499 16.9998 11.8499C18.112 11.8499 18.9733 11.5719 19.457 10.8464C19.854 10.2509 19.8515 9.51407 19.85 9.0746L19.8498 8.9999L19.8498 8.97458C19.8498 8.71679 19.8499 8.40615 19.8114 8.11134C19.7706 7.79915 19.6796 7.43624 19.4492 7.1026C19.0873 6.57865 18.5236 6.31181 17.8249 6.20603C17.8414 6.14004 17.8501 6.07099 17.8501 5.9999V2.9999ZM15.8739 8.33149C15.8512 8.5055 15.8498 8.71024 15.8498 8.9999C15.8498 9.26176 15.8511 9.45357 15.8736 9.62194C15.8951 9.78337 15.9296 9.86227 15.957 9.90341C15.9756 9.93133 16.1205 10.1499 16.9998 10.1499C17.879 10.1499 18.0235 9.93208 18.042 9.90416C18.0695 9.86303 18.1045 9.78337 18.126 9.62194C18.1484 9.45357 18.1498 9.26176 18.1498 8.9999C18.1498 8.71024 18.1484 8.5055 18.1257 8.33149C18.104 8.16513 18.07 8.09707 18.0504 8.06878C18.0423 8.05644 17.9059 7.8499 16.9998 7.8499C16.0936 7.8499 15.9576 8.05602 15.9494 8.06836C15.9299 8.09665 15.8956 8.16512 15.8739 8.33149Z"
      fillRule="evenodd"
    />
    <path d="M6.1499 10.0001C6.1499 10.4695 6.53046 10.8501 6.9999 10.8501C7.46934 10.8501 7.8499 10.4695 7.8499 10.0001L7.8499 3.0001C7.8499 2.53066 7.46935 2.1501 6.9999 2.1501C6.53046 2.1501 6.1499 2.53066 6.1499 3.0001L6.1499 10.0001Z" />
    <path
      clipRule="evenodd"
      d="M6.1499 21.0001C6.1499 21.4695 6.53046 21.8501 6.9999 21.8501C7.46934 21.8501 7.8499 21.4695 7.8499 21.0001L7.8499 18.0001C7.8499 17.9272 7.84074 17.8565 7.8235 17.7891C8.53924 17.6726 9.10063 17.381 9.45703 16.8464C9.85403 16.2509 9.85149 15.5141 9.84997 15.0746L9.84979 14.9999L9.84979 14.9746C9.84983 14.7168 9.84989 14.4062 9.81138 14.1113C9.77061 13.7991 9.67962 13.4362 9.44916 13.1026C8.95372 12.3853 8.0802 12.1499 6.99979 12.1499C5.91938 12.1499 5.04586 12.3853 4.55041 13.1026C4.31996 13.4362 4.22897 13.7991 4.1882 14.1113C4.14969 14.4062 4.14974 14.7168 4.14979 14.9746L4.14979 14.9999L4.14961 15.0746C4.14809 15.5141 4.14554 16.2509 4.54255 16.8464C4.89898 17.3811 5.46046 17.6727 6.1763 17.7891C6.15907 17.8566 6.1499 17.9273 6.1499 18.0001L6.1499 21.0001ZM5.84979 14.9999C5.84979 14.7102 5.85116 14.5055 5.87388 14.3315C5.89561 14.1651 5.92962 14.0971 5.94916 14.0688C5.95731 14.0564 6.09364 13.8499 6.99979 13.8499C7.90594 13.8499 8.04199 14.056 8.05013 14.0684C8.06968 14.0966 8.10397 14.1651 8.1257 14.3315C8.14842 14.5055 8.14979 14.7102 8.14979 14.9999C8.14979 15.2618 8.14845 15.4536 8.126 15.6219C8.10447 15.7834 8.06997 15.8623 8.04255 15.9034C8.02403 15.9313 7.87905 16.1499 6.99979 16.1499C6.12053 16.1499 5.97605 15.9321 5.95753 15.9042C5.93011 15.863 5.89511 15.7834 5.87358 15.6219C5.85113 15.4536 5.84979 15.2618 5.84979 14.9999Z"
      fillRule="evenodd"
    />
    <path d="M17.0001 13.1499C17.4695 13.1499 17.8501 13.5305 17.8501 13.9999L17.8501 20.9999C17.8501 21.4693 17.4695 21.8499 17.0001 21.8499C16.5307 21.8499 16.1501 21.4693 16.1501 20.9999L16.1501 13.9999C16.1501 13.5305 16.5307 13.1499 17.0001 13.1499Z" />
  </svg>,
  'Filter',
)

export default FilterIcon
