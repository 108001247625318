import * as yup from 'yup'

import { Rules } from '@admin/components/Moderation/types/ModerationTabs'

const getValidationSchema = (contentType: Rules, action: string) => {
  if (action === 'delete') {
    return yup.object().shape({
      id: yup.string(),
      metric: yup.mixed(),
    })
  }

  return yup.object().shape(
    {
      countForPeriod: yup
        .number()
        .typeError('Number of violations must be in a range from 1 to 20')
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          otherwise: (schema) =>
            schema
              .default(2)
              .integer('Number of violations must be in a range from 1 to 20')
              .min(2, 'Number of violations must be in a range from 1 to 20')
              .max(20, 'Number of violations must be in a range from 1 to 20'),
          then: (schema) => schema.default(null),
        }),
      decision: yup.string().when('decision', (_, schema) => {
        return contentType !== Rules.PROFILE ? schema.required('Decision field must be filled') : schema
      }),
      decisionMinScore: yup
        .number()
        .typeError('Confirmation score must be in a range from 0.6 to 1.0')
        .when('decisionMinScore', (_, schema) => {
          return contentType === Rules.HIVE ? schema.required('Confirmation score must be in a range from 0.6 to 1.0') : schema
        })
        .min(0.6, 'Confirmation score must be in a range from 0.6 to 1.0')
        .max(1.0, 'Confirmation score must be in a range from 0.6 to 1.0'),
      decisionPeriodCount: yup
        .number()
        .typeError('Period must be in a range from 1 to 30')
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          then: (schema) => schema.default(null),
        })
        .when('term', {
          is: 'PERMANENTLY',
          otherwise: (schema) =>
            schema
              .integer('Period must be in a range from 1 to 30')
              .min(1, 'Period must be in a range from 1 to 30')
              .max(30, 'Period must be in a range from 1 to 30')
              .required('Period must be in a range from 1 to 30')
              .default(14),
          then: (schema) => schema.default(null),
        }),
      decisionPeriodUnit: yup
        .string()
        .required()
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          otherwise: (schema) => schema.default('DAYS'),
          then: (schema) => schema.default(null),
        }),
      metric: yup.string().required('Category field must be filled'),
      periodCount: yup
        .number()
        .typeError('Period must be in a range from 1 to 365 days or 12 month')
        .nullable()
        .integer('Period must be more than 0')
        .min(1, 'Period must be more than 0')
        .when('periodUnit', {
          is: 'DAYS',
          otherwise: (schema) => schema.max(12, 'Period must be less than 12 month'),
          then: (schema) => schema.max(365, 'Period must be less than 365 days'),
        })
        .when('decision', {
          is: 'LIMIT',
          then: (schema) => schema.default(null),
        }),
      periodUnit: yup
        .string()
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          otherwise: (schema) => schema.default('DAYS'),
          then: (schema) => schema.default(null),
        }),
      profileDecision: yup.string().when('decision', {
        is: 'LIMIT',
        otherwise: (schema) => schema.default('BLOCK_PUBLICATION').required('Implication field must be filled'),
        then: (schema) => schema.default('NO_IMPLICATIONS'),
      }),
      restrictions: yup
        .array()
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          otherwise: (schema) => schema.default(null).notRequired(),
          then: (schema) => schema.default([{ age: '18' }]).required('Restrictions field must be filled'),
        }),
      reviewMinScore: yup
        .number()
        .typeError('Review score must be in a range from 0.6 to 1.0')
        .when('reviewMinScore', (_, schema) => {
          return contentType === Rules.HIVE ? schema.required('Review score must be in a range from 0.6 to 1.0') : schema
        })
        .min(0.6, 'Review score must be in a range from 0.6 to 1.0')
        .max(1, 'Review score must be in a range from 0.6 to 1.0')
        .lessThan(yup.ref('decisionMinScore'), 'Review score can’t be more than confirmation score'),
      term: yup
        .string()
        .required()
        .nullable()
        .when('decision', {
          is: 'LIMIT',
          otherwise: (schema) => schema.default('FOR_PERIOD'),
          then: (schema) => schema.default(null),
        }),
      type: yup.string(),
    },
    [
      ['reviewMinScore', 'reviewMinScore'],
      ['decisionMinScore', 'decisionMinScore'],
      ['decision', 'decision'],
    ],
  )
}

export default getValidationSchema
