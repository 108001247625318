import './index.scss'

import { motion } from 'motion/react'
import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { createSearchParams, Outlet, useNavigate, useParams } from 'react-router-dom'

import { GridColDef, GridEventListener, GridPaginationModel, GridRowClassNameParams } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import { GridRowId } from '@mui/x-data-grid/models/gridRows'
import { useDocumentTitle } from '@uidotdev/usehooks'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import Box from '@admin/components/shared/Box/Box'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import RenderDetailPanelToggle from '@admin/components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle'
import RenderExpandableCell from '@admin/components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell'
import RenderOperationTypeCell from '@admin/components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import Radio from '@admin/components/shared/Radio/Radio'
import RadioGroup from '@admin/components/shared/RadioGroup/RadioGroup'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { ConsumptionRequestsRoutes } from '@admin/routes/enum'
import { clearFilters, setCustomFilterValue } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  clearConsumptionRequestCases,
  fetchConsumptionRequestCases,
  selectConsumptionRequestCases,
  selectConsumptionRequestCasesStatus,
  selectTotalCount,
  setConsumptionRequestStatus,
} from '@admin/store/slices/ConsumptionRequests/consumptionRequestsSlice'
import { IConsumptionRequestCase } from '@admin/store/slices/ConsumptionRequests/interface'
import { clearSortingData, fetchSortingColumns, setSortingData } from '@admin/store/sortingSlice'
import { IMemberInfo } from '@admin/types/Member'

import type { TAny } from '@yzzy/types'

import { CustomDetailPanelContent, RenderActionCell, RenderTimeLeftCell, RequestsSearch } from './components'
import {
  CLOSED_FILTER,
  consumptionRequestClosedSortingModel,
  ConsumptionRequestListType,
  consumptionRequestOpenSortingModel,
  OPEN_FILTER,
} from './constants'

const ConsumptionRequests: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const apiReference = useGridApiRef()
  const { showBoundary } = useErrorBoundary()
  const { id: requestId } = useParams()

  const consumptionRequestCases = useAppSelector(selectConsumptionRequestCases)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectConsumptionRequestCasesStatus)

  const isLoading = status === 'loading'

  const [listType, setListType] = useState(ConsumptionRequestListType.OPEN)

  useDocumentTitle(`Requests refunds & disputes - ${startCase(listType.toLowerCase())}`)

  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])
  const isClosedListType = listType === ConsumptionRequestListType.CLOSED

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchConsumptionRequestGrid(0, newPaginationModel.pageSize)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchConsumptionRequestGrid(newPaginationModel.page, newPaginationModel.pageSize)

      return newPaginationModel
    })
  }

  const handleChangeListType = async (event: ChangeEvent<HTMLInputElement>) => {
    setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
    dispatch(clearFilters())
    dispatch(clearSortingData())
    dispatch(setConsumptionRequestStatus('loading'))
    dispatch(clearConsumptionRequestCases())

    if (requestId) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
    }
    setListType(event.target.value as ConsumptionRequestListType)
    if (event.target.value?.toUpperCase() === ConsumptionRequestListType.CLOSED) {
      dispatch(setCustomFilterValue(CLOSED_FILTER))
      dispatch(setSortingData([consumptionRequestClosedSortingModel]))

      await fetchConsumptionRequestGrid(0, paginationModel.pageSize)
    } else {
      dispatch(setCustomFilterValue(OPEN_FILTER))
      dispatch(setSortingData([consumptionRequestOpenSortingModel]))

      await fetchConsumptionRequestGrid(0, paginationModel.pageSize)
    }
  }

  const handleResolve = (event: MouseEvent<HTMLButtonElement>, data: IConsumptionRequestCase) => {
    const options = {
      pathname: `details/${data.consumptionRequestId}`,
      search: `?${createSearchParams({
        creationDate: data.createdAt,
        operationId: data.operationId,
        platform: data.consumptionRequestType,
      } as TAny)}`,
    }

    navigate(options)
    event.stopPropagation()
  }

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (parameters) => {
      if (isClosedListType) {
        return
      }
      apiReference.current.toggleDetailPanel(parameters.id)
    },
    [apiReference],
  )

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent row={row} />,
    [],
  )

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const getRowClassName = (parameters: GridRowClassNameParams) => {
    if (isClosedListType && parameters.row.consumptionRequestId === detailPanelExpandedRowIds[0]) {
      return 'Mui-selected'
    } else if (!isClosedListType) {
      return 'Mui-empty'
    } else {
      return ''
    }
  }

  const columns: [] | GridColDef[] = useMemo(() => {
    if (listType === ConsumptionRequestListType.OPEN) {
      return [
        {
          minWidth: 140,
          field: 'consumptionRequestId',
          flex: 0.4,
          headerName: 'Case ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'operationType',
          flex: 0.5,
          headerName: 'Operation type',
          renderCell: (parameters) => <RenderOperationTypeCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'operationId',
          flex: 0.6,
          headerName: 'Operation ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'profileId',
          flex: 0.6,
          headerName: 'Profile ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 120,
          field: 'createdAt',
          flex: 0.3,
          headerName: 'Time left',
          renderCell: (parameters) => <RenderTimeLeftCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 100,
          field: 'hidden',
          flex: 0.3,
          headerName: '',
          renderCell: (parameters) => <RenderActionCell onClick={(event, data) => handleResolve(event, data)} props={parameters} />,
          sortable: false,
        },
      ]
    } else
      return [
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (parameters) => <RenderDetailPanelToggle id={parameters.id} value={parameters.value} />,
        },
        {
          minWidth: 140,
          field: 'consumptionRequestId',
          flex: 0.4,
          headerName: 'Case ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'operationType',
          flex: 0.4,
          headerName: 'Operation type',
          renderCell: (parameters) => <RenderOperationTypeCell props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 140,
          field: 'operationId',
          flex: 0.4,
          headerName: 'Operation ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },

        {
          minWidth: 140,
          field: 'profileId',
          flex: 0.4,
          headerName: 'Profile ID',
          renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'reason',
          flex: 0.6,
          headerName: 'Reason',
          sortable: false,
          valueFormatter: (value) => {
            return value ? value : '—'
          },
        },
        {
          minWidth: 200,
          field: 'comment',
          flex: 0.6,
          headerName: 'Comment (for YZZY only)',
          renderCell: (parameters) => <RenderExpandableCell {...parameters} />,
          sortable: false,
        },
        {
          minWidth: 160,
          field: 'memberInfo',
          flex: 0.6,
          headerName: 'Resolved by',
          sortable: false,
          valueFormatter: (value: IMemberInfo) => {
            const fullName = `${value?.firstName} ${value?.lastName}`

            return value ? `${fullName.length > 45 ? `${fullName.substring(0, 45)}...` : fullName}` : '—'
          },
        },
      ]
  }, [listType])

  const rows: [] | IConsumptionRequestCase[] = useMemo(() => {
    if (consumptionRequestCases) {
      return consumptionRequestCases
    } else return []
  }, [consumptionRequestCases])

  const isEmptyData = !rows.length

  const fetchConsumptionRequestInitial = useCallback(async (page: number, pageSize: number) => {
    try {
      await dispatch(fetchConsumptionRequestCases({ page, pageSize })).unwrap()
    } catch (error) {
      showBoundary(error)
    }
  }, [])

  const fetchConsumptionRequestGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchConsumptionRequestCases({ page, pageSize })).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
    },
    [dispatch],
  )

  const repeatFetchingRequest = useCallback(async () => {
    fetchConsumptionRequestGrid(paginationModel.page, paginationModel.pageSize)
  }, [dispatch, paginationModel])

  const initialFetchData = useCallback(async () => {
    dispatch(fetchSortingColumns('consumption-requests'))
    dispatch(setCustomFilterValue(OPEN_FILTER))
    dispatch(setSortingData([consumptionRequestOpenSortingModel]))
    await fetchConsumptionRequestInitial(paginationModel.page, paginationModel.pageSize)
  }, [dispatch, paginationModel])

  useEffect(() => {
    dispatch(clearFilters())
    dispatch(clearSortingData())
    initialFetchData()
  }, [])

  return (
    <div className="consumption-requests-container">
      <Typography variant="h6">Requests refunds & disputes</Typography>
      <div className="consumption-requests-content">
        <div className="consumption-requests-content__actions">
          <Stack alignItems="center" direction="row" gap={1} pl={2}>
            <FormControl>
              <RadioGroup onChange={handleChangeListType} value={listType} row>
                <FormControlLabel label="Open" control={<Radio />} value={ConsumptionRequestListType.OPEN} />
                <FormControlLabel label="Closed" control={<Radio />} value={ConsumptionRequestListType.CLOSED} />
              </RadioGroup>
            </FormControl>
          </Stack>

          {!isEmptyData && <RequestsSearch />}
        </div>
        <Outlet context={{ page: paginationModel.page, pageSize: paginationModel.pageSize }} />
        {!requestId && (
          <motion.div
            animate={{ opacity: 1 }}
            className="consumption-requests-table"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter={isEmptyData && !fetchingError}
                hideFooterSelectedRowCount
                columnVisibilityModel={{
                  __detail_panel_toggle__: isClosedListType,
                }}
                slots={{
                  footer: CustomFooter,
                  noRowsOverlay: () =>
                    CustomNoRowsOverlay({
                      fetchingError: fetchingError,
                      isFiltersApplied: false,
                      refreshData: () => repeatFetchingRequest(),
                      renderNoDataComponent: () => (
                        <Typography color="text.secondary" variant="body2">
                          No {listType === ConsumptionRequestListType.OPEN ? 'open' : 'closed'} requests
                        </Typography>
                      ),
                    }),
                }}
                apiRef={apiReference}
                columnHeaderHeight={64}
                columns={columns}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                getRowClassName={getRowClassName}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.consumptionRequestId as GridRowId}
                loading={isLoading}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                onPaginationModelChange={handlePaginationModelChange}
                onRowClick={isClosedListType ? handleRowClick : undefined}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalCount}
                rows={rows}
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                disableRowSelectionOnClick
                pagination
              />
            </Box>
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default ConsumptionRequests
