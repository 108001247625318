import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import colors from '@admin/theme/constants/colors'

/**
 * Hook to calculate remaining time and determine color based on thresholds.
 *
 * @param {string} pointDate - The date and time to calculate from.
 * @returns {{ color: colors, elapsedTime: { hours: number, minutes: number, formatted: string } } | null} Object containing color and remaining time.
 */
function useRemainingTimeColor(pointDate: string): {
  color: colors
  remainingTime: null | { formatted: string; hours: number; minutes: number }
} {
  const [color, setColor] = useState(colors.text)
  const [remainingTime, setRemainingTime] = useState<null | {
    formatted: string
    hours: number
    minutes: number
  }>({ formatted: '', hours: 12, minutes: 0 })

  useEffect(() => {
    const startDate = dayjs(pointDate)
    const currentDate = dayjs()
    const diff = dayjs.duration(currentDate.diff(startDate))
    const elapsedTimeInMinutes = diff.asMinutes()
    const remainingTimeInMinutes = 720 - elapsedTimeInMinutes
    const sign = Math.sign(remainingTimeInMinutes)

    let dateColor: colors

    if (isNaN(remainingTimeInMinutes)) {
      dateColor = colors.text
    } else if (remainingTimeInMinutes >= 300) {
      dateColor = colors.success
    } else if (remainingTimeInMinutes >= 120) {
      dateColor = colors.warning
    } else {
      dateColor = colors.error
    }

    setColor(dateColor)

    const remainingHours = Math.floor(Math.abs(remainingTimeInMinutes) / 60)
    const remainingMinutes = Math.floor(Math.abs(remainingTimeInMinutes) % 60)

    if (isNaN(remainingTimeInMinutes)) {
      setRemainingTime(null)
    } else {
      let formattedTime = ''

      if (remainingHours !== 0) {
        const hoursText = remainingHours === 1 ? 'hour' : 'hours'

        formattedTime += `${remainingHours * sign} ${hoursText}`
      }
      if (remainingMinutes !== 0) {
        const minutesText = remainingMinutes === 1 ? 'minute' : 'minutes'

        formattedTime += ` ${remainingMinutes * sign} ${minutesText}`
      }

      setRemainingTime({
        formatted: formattedTime.trim(),
        hours: remainingHours * sign,
        minutes: remainingMinutes * sign,
      })
    }
  }, [pointDate])

  return { color, remainingTime: remainingTime }
}

export default useRemainingTimeColor
