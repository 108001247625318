import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { OPERATIONS_API, PAYMENTS_API, TAXES_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { IDropdownItem, IGroupedDropdownItem, IRefundReason, TStatus } from '@admin/types/commonTypes'

import { IDictionariesState, IPaymentMethod, IProvider, ITaxCode, ITransactionType } from './interface'

const initialState: IDictionariesState = {
  providers: [],
  certainties: [],
  entityTypes: [],
  eventCategories: [],
  formats: [],
  onlineFormatTypes: [],
  passions: [],
  paymentMethods: [],
  refundReasons: [],
  salesTaxCodes: [],
  salesTaxCodesList: [],
  status: 'idle',
  transactionTypes: [],
}

export const fetchProviders = createAsyncThunk('dictionaries/providers', async () => {
  try {
    const response = await axios.get(PAYMENTS_API + 'payment-adapter/dictionary/providers')

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchTransactionTypes = createAsyncThunk('dictionaries/transactionTypes', async () => {
  try {
    const response = await axios.get(PAYMENTS_API + 'payment-adapter/dictionary/transaction-types')

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchPaymentsMethods = createAsyncThunk('dictionaries/paymentsMethods', async () => {
  try {
    const response = await axios.get(PAYMENTS_API + 'payment-adapter/dictionary/methods')

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchEntityTypes = createAsyncThunk('dictionaries/entityTypes', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=entity_type')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchFormats = createAsyncThunk('dictionaries/formats', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=format')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchOnlineFormatTypes = createAsyncThunk('dictionaries/onlineFormatTypes', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=event_online_format_type')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchEventCategories = createAsyncThunk('dictionaries/eventCategories', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=sales_tax_moderation_event_category_id')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchPassions = createAsyncThunk('dictionaries/passions', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=sales_tax_moderation_passions')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchCertainties = createAsyncThunk('dictionaries/certainties', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=certainty')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchSalesTaxCodes = createAsyncThunk('dictionaries/salesTaxCodes', async () => {
  try {
    const response = await axios.get(TAXES_API + 'sales/tax/screen/filters/dropdown?columnId=sales_tax_code_entity')

    return response.data.values
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchSalesTaxCodesList = createAsyncThunk('dictionaries/salesTaxCodesList', async () => {
  const response = await axios.get(TAXES_API + 'sales/tax/code')

  return response.data
})

export const fetchRefundReasons = createAsyncThunk('dictionaries/refundReasons', async () => {
  try {
    const response = await axios.get(OPERATIONS_API + `operations/refund-reasons`)

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchProviders.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.providers = payload
      })
      .addCase(fetchProviders.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchPaymentsMethods.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPaymentsMethods.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.paymentMethods = payload
      })
      .addCase(fetchPaymentsMethods.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchTransactionTypes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTransactionTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.transactionTypes = payload
      })
      .addCase(fetchTransactionTypes.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchEntityTypes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchEntityTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.entityTypes = payload
      })
      .addCase(fetchEntityTypes.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchFormats.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFormats.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.formats = payload
      })
      .addCase(fetchFormats.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchOnlineFormatTypes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchOnlineFormatTypes.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.onlineFormatTypes = payload
      })
      .addCase(fetchOnlineFormatTypes.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchEventCategories.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchEventCategories.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.eventCategories = payload
      })
      .addCase(fetchEventCategories.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchPassions.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPassions.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.passions = payload
      })
      .addCase(fetchPassions.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchCertainties.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCertainties.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.certainties = payload
      })
      .addCase(fetchCertainties.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchSalesTaxCodes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSalesTaxCodes.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.salesTaxCodes = payload
      })
      .addCase(fetchSalesTaxCodes.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchSalesTaxCodesList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSalesTaxCodesList.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.salesTaxCodesList = payload
      })
      .addCase(fetchSalesTaxCodesList.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchRefundReasons.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchRefundReasons.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.refundReasons = payload
      })
      .addCase(fetchRefundReasons.rejected, (state) => {
        state.status = 'failed'
      })
  },
  initialState,
  reducers: {},
})

export const selectProviders = (state: TRootState): IProvider[] => state.dictionaries.providers
export const selectPaymentMethods = (state: TRootState): IPaymentMethod[] => state.dictionaries.paymentMethods
export const selectTransactionTypes = (state: TRootState): ITransactionType[] => state.dictionaries.transactionTypes
export const selectEntityTypes = (state: TRootState): IDropdownItem[] => state.dictionaries.entityTypes
export const selectFormats = (state: TRootState): IDropdownItem[] => state.dictionaries.formats
export const selectOnlineFormatTypes = (state: TRootState): IDropdownItem[] => state.dictionaries.onlineFormatTypes
export const selectEventCategories = (state: TRootState): IDropdownItem[] => state.dictionaries.eventCategories
export const selectPassions = (state: TRootState): IGroupedDropdownItem[] => state.dictionaries.passions
export const selectSalesTaxCodes = (state: TRootState): IGroupedDropdownItem[] => state.dictionaries.salesTaxCodes
export const selectSalesTaxCodesList = (state: TRootState): Pick<ITaxCode, 'category' | 'code' | 'description' | 'id'>[] =>
  state.dictionaries.salesTaxCodesList
export const selectCertainties = (state: TRootState): IDropdownItem[] => state.dictionaries.certainties
export const selectRefundReasons = (state: TRootState): IRefundReason[] => state.dictionaries.refundReasons
export const selectDictionariesStatus = (state: TRootState): TStatus => state.dictionaries.status

export default dictionariesSlice.reducer
