import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { PAYMENTS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import { ITariffGroup, ITariffGroupDetails, ITariffGroupsState } from './interface'

const initialState: ITariffGroupsState = {
  tariffGroupDetails: [],
  tariffGroupDetailsStatus: 'idle',
  tariffGroups: [],
  tariffGroupsStatus: 'idle',
}

export const fetchTariffGroups = createAsyncThunk('payments/tariffGroups', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(PAYMENTS_API + 'payment-adapter/tariffs/groups')

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchTariffGroup = createAsyncThunk('payments/tariffGroupDetails', async (tariffGroupId: string, { rejectWithValue }) => {
  try {
    const response = await axios.get(PAYMENTS_API + `payment-adapter/tariffs/groups/${tariffGroupId}`)

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const tariffsSlice = createSlice({
  name: 'tariffs',
  extraReducers: (builder) => {
    builder
      .addCase(fetchTariffGroups.pending, (state) => {
        state.tariffGroupsStatus = 'loading'
      })
      .addCase(fetchTariffGroups.fulfilled, (state, { payload }) => {
        state.tariffGroupsStatus = 'idle'
        state.tariffGroups = payload
      })
      .addCase(fetchTariffGroups.rejected, (state) => {
        state.tariffGroupsStatus = 'failed'
        state.tariffGroups = []
      })
      .addCase(fetchTariffGroup.pending, (state) => {
        state.tariffGroupDetailsStatus = 'loading'
      })
      .addCase(fetchTariffGroup.fulfilled, (state, { payload }) => {
        state.tariffGroupDetailsStatus = 'idle'
        state.tariffGroupDetails = payload
      })
      .addCase(fetchTariffGroup.rejected, (state) => {
        state.tariffGroupDetailsStatus = 'failed'
      })
  },
  initialState,
  reducers: {},
})

export const selectTariffGroups = (state: TRootState): ITariffGroup[] => state.tariffs.tariffGroups
export const selectTariffGroupDetails = (state: TRootState): ITariffGroupDetails[] => state.tariffs.tariffGroupDetails
export const selectTariffGroupsStatus = (state: TRootState): TStatus => state.tariffs.tariffGroupsStatus
export const selectTariffGroupDetailsStatus = (state: TRootState): TStatus => state.tariffs.tariffGroupDetailsStatus

export default tariffsSlice.reducer
