import * as yup from 'yup'

const getValidationSchema = () => {
  return yup.object().shape({
    comment: yup.string().trim().max(5000, 'Max limit of 5000 characters is exceeded'),
    reason: yup.string().required('Required field'),
  })
}

export default getValidationSchema
