import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { Rules } from '@admin/components/Moderation/types/ModerationTabs'
import { MODERATION_RULES_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

export interface IColumnsRules {
  hive: TAny[]
  manual: TAny[]
  profile: TAny[]
}
export interface IHiveRule {
  decision: TDecision
  decisionMinScore: number
  id: string
  metric: string
  profileImplicationDto: {
    countForPeriod: number
    decisionPeriodCount: number
    decisionPeriodUnit: string
    periodCount: number
    periodUnit: string
    profileDecision: TProfileImplications
    term: Term
  }
  restrictions?: IRestriction[]
  reviewMinScore: number
  type: string
}
export interface IManualRule {
  decision: TDecision
  id: string
  metric: string
  profileImplicationDto: {
    countForPeriod: number
    decisionPeriodCount: number
    decisionPeriodUnit: string
    periodCount: number
    periodUnit: string
    profileDecision: TProfileImplications
    term: Term
  }
  restrictions?: IRestriction[]
  type: string
}

export interface IProfileRule {
  countForPeriod: number
  decisionPeriodCount: number
  decisionPeriodUnit: string
  id: string
  metric: string
  periodCount: number
  periodUnit: string
  profileDecision: TProfileImplications
  term: Term
}

export interface IRestriction {
  age?: string
  location?: string
}

export interface IRules {
  hive: IHiveRule[]
  manual: IManualRule[]
  profile: IProfileRule[]
}

export type TDecision = 'DELETE' | 'LIMIT'

export type Term = 'FOR_PERIOD' | 'PERMANENTLY'

export type TProfileImplications = 'BAN_PROFILE' | 'BLOCK_PUBLICATION' | 'NO_IMPLICATIONS'

const initialColumnsState: IColumnsRules = {
  hive: [
    {
      columnId: 'metric',
      columnName: 'Category',
      flex: 1,
      sortable: true,
    },
    {
      width: 176,
      columnId: 'threshold',
      columnName: 'Threshold',
      sortable: false,
    },
    {
      width: 160,
      columnId: 'decision',
      columnName: 'Decision',
      sortable: false,
    },
    {
      width: 256,
      columnId: 'profileImplication',
      columnName: 'Profile implication',
      sortable: false,
    },
    {
      width: 96,
      columnId: 'action',
      columnName: '',
      sortable: false,
    },
  ],
  manual: [
    {
      columnId: 'metric',
      columnName: 'Category',
      flex: 1,
      sortable: true,
    },
    {
      width: 160,
      columnId: 'decision',
      columnName: 'Decision',
      sortable: false,
    },
    {
      width: 256,
      columnId: 'profileImplication',
      columnName: 'Profile implication',
      sortable: false,
    },
    {
      width: 96,
      columnId: 'action',
      columnName: '',
      sortable: false,
    },
  ],
  profile: [
    {
      columnId: 'metric',
      columnName: 'Category',
      flex: 1,
      sortable: true,
    },
    {
      width: 256,
      columnId: 'profileImplication',
      columnName: 'Profile implication',
      sortable: false,
    },
    {
      width: 96,
      columnId: 'action',
      columnName: '',
      sortable: false,
    },
  ],
}

export interface IRulesState {
  columns: IColumnsRules
  rulesData: IRules
  status: TStatus
}

const initialState: IRulesState = {
  columns: initialColumnsState,
  rulesData: {
    hive: [],
    manual: [],
    profile: [],
  },
  status: 'idle',
}

export const fetchRules = createAsyncThunk('rules/fetchRules', async (parameters: Rules) => {
  let dataUrl

  switch (parameters) {
    case Rules.HIVE: {
      dataUrl = 'moderation/hive-rules/full'
      break
    }
    case Rules.MANUAL: {
      dataUrl = 'moderation/manual-content-rules/full'
      break
    }
    case Rules.PROFILE: {
      dataUrl = 'moderation/profile-only-rules'
      break
    }
  }

  const response = await axios.get(MODERATION_RULES_API + dataUrl)

  return { ...response, typeRule: parameters }
})

export const fetchAddRule = createAsyncThunk('rules/addRule', async ({ data, type }: { data: TAny; type: Rules }) => {
  let dataUrl

  switch (type) {
    case Rules.HIVE: {
      dataUrl = 'moderation/hive-rules'
      break
    }
    case Rules.MANUAL: {
      dataUrl = 'moderation/manual-content-rules'
      break
    }
    case Rules.PROFILE: {
      dataUrl = 'moderation/manual-profile-rules'
      break
    }
  }

  const response = await axios.post(MODERATION_RULES_API + dataUrl, {
    ...data,
  })

  return { ...response, typeRule: type }
})

export const fetchUpdateRule = createAsyncThunk('rules/updateRule', async ({ data, type }: { data: TAny; type: Rules }) => {
  let dataUrl

  switch (type) {
    case Rules.HIVE: {
      dataUrl = `moderation/hive-rules/${data.id}`
      break
    }
    case Rules.MANUAL: {
      dataUrl = `moderation/manual-content-rules/${data.id}`
      break
    }
    case Rules.PROFILE: {
      dataUrl = `moderation/manual-profile-rules/${data.id}`
      break
    }
  }

  const response = await axios.post(MODERATION_RULES_API + dataUrl, {
    ...data,
  })

  return { ...response, typeRule: type }
})

export const fetchDeleteRule = createAsyncThunk('rules/deleteRule', async ({ data, type }: { data: TAny; type: Rules }) => {
  let dataUrl

  switch (type) {
    case Rules.HIVE: {
      dataUrl = `moderation/hive-rules/${data.id}`
      break
    }
    case Rules.MANUAL: {
      dataUrl = `moderation/manual-content-rules/${data.id}`
      break
    }
    case Rules.PROFILE: {
      dataUrl = `moderation/manual-profile-rules/${data.id}`
      break
    }
  }

  const response = await axios.delete(MODERATION_RULES_API + dataUrl)

  return { ...response, ...data, typeRule: type }
})

export const rulesSlice = createSlice({
  name: 'rules',
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchRules.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = payload.data
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = payload.data
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = payload.data
        }
      })
      .addCase(fetchRules.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchAddRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAddRule.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = [...state.rulesData.hive, payload.data]
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = [...state.rulesData.manual, payload.data]
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = [...state.rulesData.profile, payload.data]
        }
      })
      .addCase(fetchAddRule.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchUpdateRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUpdateRule.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = [...state.rulesData.hive.filter((value) => value.id !== payload.data.id), payload.data]
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = [...state.rulesData.manual.filter((value) => value.id !== payload.data.id), payload.data]
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = [...state.rulesData.profile.filter((value) => value.id !== payload.data.id), payload.data]
        }
      })
      .addCase(fetchUpdateRule.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDeleteRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDeleteRule.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.rulesData.hive = state.rulesData.hive.filter((value) => value.id !== payload.id)
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.rulesData.manual = state.rulesData.manual.filter((value) => value.id !== payload.id)
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.rulesData.profile = state.rulesData.profile.filter((value) => value.id !== payload.id)
        }
      })
      .addCase(fetchDeleteRule.rejected, (state) => {
        state.status = 'failed'
      })
  },
  initialState,
  reducers: {},
})

export const selectRulesStatus = (state: TRootState): TStatus => state.rules.status
export const selectRules = (state: TRootState): IRules => state.rules.rulesData
export const selectColumn = (state: TRootState): IColumnsRules => state.rules.columns

export default rulesSlice.reducer
