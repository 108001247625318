import { GridColumnHeaderParams } from '@mui/x-data-grid-pro'

import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Props for the RenderHeaderWithSecondaryText component.
 *
 * @interface
 * @property {string} secondaryText - Cell's secondary text.
 * @property {GridColumnHeaderParams} props - Parameters for rendering the cell.
 */
interface IRenderHeaderWithTooltipProps {
  props: GridColumnHeaderParams
  secondaryText: string
  tooltipTitle?: string
}

/**
 * Renders a header cell with secondary text in a data grid.
 */
export const RenderHeaderWithSecondaryText = ({ props, secondaryText, tooltipTitle }: IRenderHeaderWithTooltipProps) => {
  const { colDef } = props

  return (
    <Tooltip title={tooltipTitle || ''} placement="top" followCursor>
      <Typography variant="tableHeader">
        {colDef.headerName}
        {secondaryText && (
          <Typography color="text.secondary" variant="tableHeader">
            {secondaryText}
          </Typography>
        )}
      </Typography>
    </Tooltip>
  )
}
