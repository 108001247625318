import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'

interface ICloseUnassignTicketsConfirmModalProps {
  closeModal: () => void
  onClose: () => void
  open: boolean
}

export const CloseUnassignTicketsConfirmModal = ({ closeModal, onClose, open }: ICloseUnassignTicketsConfirmModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">Cancel removing the assignment of the tickets?</DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          Changes won’t be applied
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Don’t cancel
        </Button>
        <Button
          onClick={() => {
            closeModal()
            onClose()
          }}
          color="primary"
          variant="contained"
        >
          Cancel unassigning
        </Button>
      </DialogActions>
    </Dialog>
  )
}
