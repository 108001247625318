import './index.scss'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'
import * as yup from 'yup'

import EyeClosedIcon from '@admin/assets/img/EyeClosedIcon'
import EyeIcon from '@admin/assets/img/EyeIcon'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import TextField from '@admin/components/shared/TextField/TextField'
import { logout } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchResetPassword,
  fetchResetPasswordTriesLeft,
  selectProfileStatus,
  selectProfileStatusText,
  selectTriesLeft,
} from '@admin/store/profileSlice'
import { CommonErrorMessages, ProfileMessages } from '@admin/store/types/ErrorMessages'

import type { TAny } from '@yzzy/types'

interface IProps {
  onClose(): void
}

const ChangePassword = ({ onClose }: IProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const reference = useRef<boolean>(false)

  const triesLeft = useAppSelector(selectTriesLeft)
  const status = useAppSelector(selectProfileStatus)
  const statusText = useAppSelector(selectProfileStatusText)

  const dispatch = useAppDispatch()

  const schema = useMemo(() => yup.object().shape({ password: yup.string().required().min(1) }), [])

  const {
    formState: { isValid, errors },
    handleSubmit,
    register,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: TAny) => {
    void trigger()
    try {
      const result = await dispatch(fetchResetPassword({ password: data.password }))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Link for setting up new password has been sent to your email', { variant: 'success' as VariantType })
        onClose()

        return
      }
      setError('password', {})
    } catch {
      setErrorMessage('An error occurred, try again later')
    }
  }

  const initState = useCallback(async () => {
    await dispatch(fetchResetPasswordTriesLeft())
  }, [])

  useEffect(() => {
    void initState()
  }, [])

  useEffect(() => {
    if (triesLeft < 3 && triesLeft > 0) {
      setErrorMessage(`You entered the wrong password earlier. You have ${triesLeft} attempt${triesLeft > 1 ? 's' : ''} left`)
    } else if (triesLeft === 0) {
      setErrorMessage(`You entered the wrong password earlier. You have no attempts left. Please contact your manager`)
    } else {
      setErrorMessage('')
    }
  }, [triesLeft])

  useEffect(() => {
    if (!reference.current) {
      reference.current = true

      return
    }

    switch (statusText) {
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setErrorMessage('An error occurred, try again later')
        break
      case ProfileMessages.NO_TRIES_CHANGE_PASSWORD:
        dispatch(logout({ message: ProfileMessages.NO_TRIES }))
        break
      case ProfileMessages.UNVALIDATED_PASSWORD:
        if (triesLeft < 3) {
          setErrorMessage(`You’ve entered the wrong password. You have ${triesLeft} attempt${triesLeft && 's'} left`)
        }
        break
      default:
        break
    }
  }, [statusText, reference, triesLeft])

  return (
    <div className="profileModal">
      <div className="profileModal-title">Change password</div>
      <form className="profileModal-form" onSubmit={handleSubmit(onSubmit)}>
        <span>Enter your current password</span>
        <TextField
          {...register('password')}
          fullWidth={true}
          label="Password"
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          defaultValue=""
          error={!!errors.password}
          type={showPassword ? 'text' : 'password'}
        />
        {errorMessage && errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <div className="profileModal-buttonsGroup">
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid || triesLeft === 0}
            loading={status === 'loading'}
            loadingPosition="start"
            startIcon={null}
            type="submit"
            variant="contained"
          >
            Change password
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
