import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { PAYMENTS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { ITier, ITiersState, ITierStatus } from './interface'

const initialState: ITiersState = {
  providerOptions: [],
  linkIdOptions: [],
  operationTypesOptions: [],
  status: 'idle',
  tiers: [],
  totalCount: 0,
}

export const fetchTiers = createAsyncThunk(
  'payments/inAppTiers',
  async ({ page, pageSize }: { page: number; pageSize: number }, { getState, rejectWithValue }) => {
    try {
      const rootState: TRootState = getState() as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
        : []
      const response = await axios.post(PAYMENTS_API + 'payment-adapter/tiers/all', {
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchAddTier = createAsyncThunk(
  'payments/addInAppTier',
  async ({ name, providerId, country, currency, linkId, operationType, price, productId }: Omit<ITier, 'status'>, { rejectWithValue }) => {
    try {
      const response = await axios.post(PAYMENTS_API + 'payment-adapter/tiers', {
        name,
        providerId,
        country,
        currency,
        linkId,
        operationType,
        price,
        productId,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchDropdownOptions = createAsyncThunk(
  'payments/tiers/providers',
  async (
    {
      additionalValue,
      fieldForDropdown,
    }: {
      additionalValue?: string
      fieldForDropdown: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get(PAYMENTS_API + `payment-adapter/tiers/dropdown/${fieldForDropdown}`, {
        params: {
          additionalValue: additionalValue,
        },
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchChangeStatus = createAsyncThunk(
  'payments/tiers/changeStatus',
  async (
    {
      currentSelectedStatus,
      currentSelectedTier,
    }: {
      currentSelectedStatus: ITierStatus | null
      currentSelectedTier: ITier | null
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(PAYMENTS_API + 'payment-adapter/tiers', {
        productId: currentSelectedTier?.productId,
        status: currentSelectedStatus?.status,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const tiersSlice = createSlice({
  name: 'tiers',
  extraReducers: (builder) => {
    builder
      .addCase(fetchTiers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTiers.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.tiers = payload.tiers
        state.totalCount = payload.totalCount
      })
      .addCase(fetchTiers.rejected, (state) => {
        state.status = 'failed'
        state.tiers = []
        state.totalCount = 0
      })
      .addCase(fetchAddTier.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAddTier.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(fetchAddTier.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDropdownOptions.fulfilled, (state, { meta, payload }) => {
        const { fieldForDropdown } = meta.arg

        if (fieldForDropdown === 'provider') {
          state.providerOptions = payload.values
        } else if (fieldForDropdown === 'operation-type') {
          state.operationTypesOptions = payload.values
        } else if (fieldForDropdown === 'link-id') {
          state.linkIdOptions = payload.values
        }
      })
      .addCase(fetchChangeStatus.fulfilled, (state) => {
        state.status = 'idle'
      })
  },
  initialState,
  reducers: {
    setTiersStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectTiers = (state: TRootState): ITier[] => state.tiers.tiers
export const selectTotalCount = (state: TRootState): number => state.tiers.totalCount
export const selectTiersStatus = (state: TRootState): TStatus => state.tiers.status
export const selectProviderOptions = (state: TRootState) => state.tiers.providerOptions
export const selectOperationTypesOptions = (state: TRootState) => state.tiers.operationTypesOptions
export const selectLinkIdOptions = (state: TRootState) => state.tiers.linkIdOptions

export const { setTiersStatus } = tiersSlice.actions

export default tiersSlice.reducer
