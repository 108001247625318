import './index.scss'

import { useCallback, useEffect, useMemo, useState } from 'react'

import { GridColDef, GridRenderCellParams, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { enqueueSnackbar, VariantType } from 'notistack'

import { getEntityDescription } from '@admin/components/ManageLinkedCollections/utils/getEntityDescription'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Modal from '@admin/components/shared/Modal/Modal'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchAddEntityToTimWorld,
  fetchLinkedCollections,
  selectLinkedCollections,
  selectLinkedCollectionsColumns,
  selectWorldStatus,
} from '@admin/store/worldManagementSlice'

import type { TAny } from '@yzzy/types'

interface IProps {
  entityId: string
  /* Тайтл баннера для хедера. Остальные сущности отображаются c id */
  entityTitle?: string
  entityType: 'BANNER' | 'EVENT' | 'HERO' | 'OFFER' | 'WISH'

  onClose(): void

  open: boolean
  shouldDisabledCollection?: string
}

// todo тут будет добавлена иконка с тултипом в хедер. сейчас как раз дизайн доделывается.
const ManageLinkedCollectionsModal = ({ entityId, entityTitle, entityType, onClose, open, shouldDisabledCollection }: IProps) => {
  const [initialSelection, setInitialSelection] = useState<string[]>([])
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const [errorMessage, setErrorMessage] = useState('')

  const entityDescription = useMemo(() => getEntityDescription(entityType), [entityType])

  const linkedCollections = useAppSelector(selectLinkedCollections)
  const linkedCollectionsColumns = useAppSelector(selectLinkedCollectionsColumns)
  const status = useAppSelector(selectWorldStatus)

  const dispatch = useAppDispatch()
  const title = `${entityDescription.title} ${entityTitle ?? '#' + entityId}`

  const customCellRender: TAny = useMemo(() => {
    return {
      name: (props: GridRenderCellParams) => (
        <div className="item-content">
          <div className="item-primary">{props.row?.title}</div>
          {props.row?.id !== 'DEFAULT' && (
            <div className="item-secondary">
              {props.row?.creatorName} {props.row?.creatorLastName}
            </div>
          )}
        </div>
      ),
      cohort: (props: GridRenderCellParams) => <div className="cohort-cell">{props.row?.cohortTitle}</div>,
      entities: (props: GridRenderCellParams) => {
        let length = props.row?.[entityDescription.entityCountProp]

        if (!props.row?.entityIncluded && selectionModel.includes(props.row?.id)) {
          length += 1
        }

        if (props.row?.entityIncluded && !selectionModel.includes(props.row?.id)) {
          length -= 1
        }

        return (
          <div className={`entitiesCount-cell ${length >= entityDescription.minAllowedNumberOfEntities ? 'entitiesCount-cell_success' : ''}`}>
            {length} {length === 1 ? entityDescription.singularEntityName : entityDescription.pluralEntityName}
          </div>
        )
      },
    }
  }, [entityType, entityDescription, selectionModel])

  const rows: TAny = useMemo(() => {
    return linkedCollections?.map((item: TAny) => ({ ...item, id: item.id })) ?? []
  }, [linkedCollections])

  const cols: GridColDef[] = useMemo(() => {
    return linkedCollectionsColumns.map((item: TAny) => ({
      field: item.columnId,
      flex: item.flex,
      renderCell: customCellRender[item.columnId] ?? null,
    }))
  }, [linkedCollectionsColumns, selectionModel])

  useEffect(() => {
    if (linkedCollections) {
      const newArray: string[] = []

      linkedCollections?.forEach((currentValue) => {
        if (currentValue.entityIncluded) {
          newArray.push(currentValue.id)
        }
      }, [])
      setSelectionModel(newArray)
      setInitialSelection(newArray)
    }
  }, [linkedCollections])

  useEffect(() => {
    dispatch(fetchLinkedCollections({ entityId, entityType }))
  }, [entityId, entityType])

  const handleSubmit = useCallback(async () => {
    try {
      const result = await dispatch(
        fetchAddEntityToTimWorld({
          compilationIds: selectionModel as string[],
          entityId,
          entityType,
        }),
      )

      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
        enqueueSnackbar(`${title} collections list has been changed`, { variant: 'success' as VariantType })
      }
      if (result.meta.requestStatus === 'rejected') {
        setErrorMessage('Saving linked collections list error, try again later')
      }
    } catch {
      setErrorMessage('Saving linked collections list error, try again later')

      return
    }
  }, [selectionModel, title])

  return (
    <Modal customstyle={{ minHeight: 200, padding: 0 }} open={open}>
      <div className="linkedCollectionsModal">
        <div className="linkedCollectionsModal-header">{title} linked collections</div>
        <div className={`linkedCollectionsModal-content ${!rows.length ? 'linkedCollectionsModal-content_empty' : ''}`}>
          <DataGrid
            hideFooter
            isRowSelectable={(parameters: GridRowParams) =>
              !((parameters.row.status === 'LIVE' && parameters.row.id !== 'DEFAULT') || parameters.row.id === shouldDisabledCollection)
            }
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel)
            }}
            slots={{
              noRowsOverlay: () => <span className="empty-cell">No data to display.</span>,
            }}
            columnHeaderHeight={0}
            columns={cols}
            loading={false}
            pagination={false}
            rowHeight={54}
            rows={rows}
            rowSelectionModel={selectionModel}
            checkboxSelection
            disableColumnMenu
            disableColumnResize
          />
        </div>
        <div className="linkedCollectionsModal-footer">
          {!errorMessage && (
            <Alert severity="warning">
              This {entityDescription.alertEntityName} is linked to {initialSelection.length} collections yet
            </Alert>
          )}
          <div className="linkedCollectionsModal-footer-buttons">
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Button color="primary" onClick={onClose} variant="text">
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              disabled={initialSelection == selectionModel}
              loading={status === 'loading'}
              onClick={handleSubmit}
              variant="contained"
            >
              Confirm
            </LoadingButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ManageLinkedCollectionsModal
