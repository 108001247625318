import type { TAny } from '@yzzy/types'

const groupComplaints = (props: TAny[] = []): TAny[] => {
  const collection = new Map()

  props.forEach((complaint: TAny) => {
    const author = { ...complaint.author, comment: complaint.comment || null }

    if (collection.has(complaint.category)) {
      const value = collection.get(complaint.category)

      collection.set(complaint.category, [...value, author])

      return
    }
    collection.set(complaint.category, [author])
  })

  return Array.from(collection, ([key, value]) => ({ key, value }))
}

export default groupComplaints
