import './index.scss'

import { ForwardedRef, forwardRef } from 'react'

import { TextField as TextFieldMUI } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

const TextField = forwardRef((props: TextFieldProps, reference: ForwardedRef<HTMLDivElement | null>) => {
  return <TextFieldMUI ref={reference} {...props} />
})

export default TextField
