const EyeClosedFilledIcon = () => {
  return (
    <svg width="19" fill="none" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2706 5.96544C16.0499 5.6911 15.6487 5.64758 15.3743 5.86825C15.338 5.89749 15.3057 5.92991 15.2775 5.96478L12.8843 8.35048C12.9975 8.68527 13.0589 9.04411 13.0589 9.41747C13.0589 11.2546 11.5735 12.74 9.73639 12.74C9.35938 12.74 8.99718 12.6774 8.65959 12.5621L6.61919 14.5961C6.60688 14.6075 6.59499 14.6193 6.58354 14.6317C6.5344 14.6847 6.49351 14.7471 6.46383 14.8176C6.3272 15.1421 6.47949 15.5159 6.80398 15.6525C7.73197 16.0433 8.72331 16.2575 9.73637 16.2575C12.6542 16.2575 15.3032 14.5372 17.1065 11.7026C17.5249 11.0468 17.7126 10.2109 17.7126 9.41375C17.7126 8.61696 17.5251 7.78151 17.1071 7.12591C16.8445 6.71204 16.5599 6.32512 16.2706 5.96544Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M17.6872 1.46675C17.9361 1.71571 17.9361 2.11935 17.6872 2.36831L12.0847 7.97081L12.08 7.97545L8.2953 11.7601L8.28966 11.7658L2.68716 17.3683C2.4382 17.6173 2.03456 17.6173 1.7856 17.3683C1.53664 17.1194 1.53664 16.7157 1.7856 16.4668L4.25805 13.9943C3.55974 13.35 2.92072 12.5804 2.36623 11.7101C1.94783 11.0543 1.76014 10.2184 1.76014 9.42127C1.76014 8.62408 1.94784 7.78818 2.36626 7.13243C4.16954 4.29782 6.8186 2.57751 9.73639 2.57751C11.3332 2.57751 12.8662 3.09846 14.2135 4.03881L16.7856 1.46675C17.0346 1.21779 17.4382 1.21779 17.6872 1.46675ZM6.41389 9.41747C6.41389 7.58039 7.89931 6.09497 9.73639 6.09497C10.4241 6.09497 11.0626 6.30316 11.5923 6.66005L11.5908 6.66154L10.6615 7.59076C10.4663 7.49118 10.2533 7.42248 10.028 7.39047C9.93285 7.37695 9.83551 7.36997 9.73639 7.36997C8.60347 7.36997 7.68889 8.28455 7.68889 9.41747C7.68889 9.43834 7.6892 9.45913 7.68981 9.47983L7.69086 9.50857C7.69382 9.57664 7.70008 9.64378 7.7095 9.70986C7.74156 9.93489 7.81022 10.1476 7.90968 10.3426L6.98047 11.2719L6.97897 11.2734C6.62208 10.7437 6.41389 10.1052 6.41389 9.41747Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default EyeClosedFilledIcon
