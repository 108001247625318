import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import List from '@admin/components/shared/List/List'
import { IModeratorWithTickets } from '@admin/types/Member'

interface IUnassignTicketsConfirmModalProps {
  closeModal: () => void
  moderators: IModeratorWithTickets[]
  onConfirm: () => void
  open: boolean
}

export const UnassignTicketsConfirmModal = ({ closeModal, moderators, onConfirm, open }: IUnassignTicketsConfirmModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">You are going to unassign tickets from:</DialogTitle>
      <DialogContent sx={moderators.length > 3 ? { border: '1px solid #DADCE3' } : {}}>
        <List sx={{ maxHeight: '158px' }}>
          {moderators.map((moderator) => (
            <AvatarItem
              key={moderator.id}
              primaryText={moderator.firstName + ' ' + moderator.lastName}
              src={moderator.avatarUrl || undefined}
              // subText={moderator.role}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => {
            closeModal()
            onConfirm()
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
