import './index.scss'

import { useState } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import trancate from 'lodash/truncate'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Renders a cell with expand/collapse functionality for long texts.
 */
const RenderExpandableCell = (props: GridRenderCellParams) => {
  const { value } = props
  const [isExpanded, setIsExpanded] = useState(false)

  if (!value) {
    return <>—</>
  }

  return (
    <div className="expandable-cell">
      <Typography className="text" component="span" variant="body2">
        {isExpanded
          ? value
          : trancate(value, {
              length: 205,
            })}{' '}
      </Typography>
      {value?.length > 205 && (
        <Typography
          onClick={(event) => {
            event.stopPropagation()
            setIsExpanded(!isExpanded)
          }}
          className="trigger"
          component="span"
        >
          <Typography component="span" variant="body2">
            {isExpanded ? 'Collapse' : 'Expand'}
          </Typography>
          {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
        </Typography>
      )}
    </div>
  )
}

export default RenderExpandableCell
