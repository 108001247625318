import { isValidElement } from 'react'

import { GridRenderCellParams, useGridSelector } from '@mui/x-data-grid'
import { gridDetailPanelExpandedRowsContentCacheSelector, useGridApiContext } from '@mui/x-data-grid-pro'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'

/**
 * Renders a toggle button for expanding/collapsing the detail panel in a DataGrid cell.
 *
 * @param {object} props - The properties for the RenderDetailPanelToggle component.
 * @param {string} props.id - The unique identifier of the row.
 * @param {boolean} props.value - The current expansion state of the detail panel.
 */
const RenderDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
  const { id, value: isExpanded } = props
  const apiReference = useGridApiContext()

  const contentCache = useGridSelector(apiReference, gridDetailPanelExpandedRowsContentCacheSelector)

  // Check if the detail panel has content for the specified row
  const hasDetail = isValidElement(contentCache[id])

  return (
    <IconButton
      aria-label={isExpanded ? 'Close' : 'Open'}
      disabled={!hasDetail}
      size="small"
      sx={{ left: 16, p: 0, position: 'absolute' }}
      tabIndex={-1}
    >
      <ChevronDownIcon
        sx={{
          fontSize: 32,
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  )
}

export default RenderDetailPanelToggle
