import { createSvgIcon } from '@mui/material'

const VideoIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.27 2.85002C6.62255 2.85002 6.09705 2.87004 5.6334 2.93245L5.61453 2.93478C4.34086 3.07732 3.76333 3.42045 3.43067 3.9245C3.04808 4.5042 2.84999 5.48867 2.84999 7.26003C2.84999 7.72948 2.46944 8.11003 1.99999 8.11003C1.53055 8.11003 1.14999 7.72948 1.14999 7.26003C1.14999 5.4714 1.32691 4.02588 2.01182 2.98809C2.74513 1.87697 3.92419 1.41472 5.41608 1.24638C5.98968 1.16985 6.60113 1.15002 7.27 1.15002H16.74C17.3986 1.15002 18.02 1.16982 18.5939 1.24638C20.0858 1.41472 21.2649 1.87697 21.9982 2.98809C22.6831 4.02588 22.86 5.4714 22.86 7.26003V13.58C22.86 15.3687 22.6831 16.8142 21.9982 17.852C21.2649 18.9631 20.0858 19.4254 18.5939 19.5937C18.0203 19.6702 17.4089 19.6901 16.74 19.6901H7.27C6.61138 19.6901 5.98996 19.6703 5.41609 19.5937C3.9242 19.4254 2.74513 18.9631 2.01182 17.852C1.32691 16.8142 1.14999 15.3687 1.14999 13.58V11.1C1.14999 10.6306 1.53055 10.25 1.99999 10.25C2.46944 10.25 2.84999 10.6306 2.84999 11.1V13.58C2.84999 15.3514 3.04808 16.3359 3.43067 16.9156C3.76333 17.4196 4.34086 17.7628 5.61453 17.9053L5.6334 17.9076C6.09689 17.97 6.63225 17.9901 7.27 17.9901H16.74C17.3874 17.9901 17.9129 17.97 18.3766 17.9076L18.3955 17.9053C19.6691 17.7628 20.2467 17.4196 20.5793 16.9156C20.9619 16.3359 21.16 15.3514 21.16 13.58V7.26003C21.16 5.48867 20.9619 4.5042 20.5793 3.92449C20.2467 3.42045 19.6691 3.07732 18.3955 2.93478L18.3766 2.93245C17.9131 2.87006 17.3777 2.85002 16.74 2.85002H7.27Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.14999 22C6.14999 21.5306 6.53055 21.15 6.99999 21.15H17C17.4694 21.15 17.85 21.5306 17.85 22C17.85 22.4695 17.4694 22.85 17 22.85H6.99999C6.53055 22.85 6.14999 22.4695 6.14999 22Z"
      fillRule="evenodd"
    />
    <path d="M16.5306 9.68249C17.1565 10.0458 17.1565 10.9542 16.5306 11.3175L10.4081 14.8721C9.78228 15.2355 9 14.7813 9 14.0546L9 6.94539C9 6.21871 9.78228 5.76454 10.4081 6.12788L16.5306 9.68249Z" />
  </svg>,
  'Video',
)

export default VideoIcon
