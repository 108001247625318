import { IUserStatus, UserStatuses } from './interface'

export const possibleUserStatuses: IUserStatus[] = [
  {
    title: 'Active',
    color: 'success',
    status: UserStatuses.ACTIVE,
  },
  {
    title: 'Banned',
    color: 'inherit',
    status: UserStatuses.BANNED,
  },
]
