import './index.scss'

import { FC } from 'react'

import CloseIcon from '@admin/assets/img/CloseIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Typography from '@admin/components/shared/Typography/Typography'
import { EntityType } from '@admin/types/commonTypes'
import { stringCapitalize } from '@admin/utils//stringCapitalize'

interface IMediaHeaderProps {
  contentType: string
  entityId: string
  entityOwnerId?: string
  entityType: string
  mediaId?: string
  onClose: () => void
  showDetailedHeader: boolean
}

const MediaHeader: FC<IMediaHeaderProps> = ({ contentType, entityId, entityOwnerId, entityType, mediaId, onClose, showDetailedHeader }) => {
  const getMediaHeader = () => {
    if (contentType?.toUpperCase() === EntityType.AVATAR && mediaId !== entityId) return 'User’s profile pic'
    if (mediaId === entityId) return 'Image of user’s profile pic'
    if (showDetailedHeader) return `${stringCapitalize(entityType)} ${contentType.toLowerCase()}`

    return `${stringCapitalize(entityType)} media`
  }

  return (
    <div className="media-header">
      <div className="media-header-title">
        <Typography variant="h5">{getMediaHeader()}</Typography>
        <Typography color="text.secondary" variant="body1">
          {entityType?.toUpperCase() === EntityType.AVATAR && entityOwnerId ? `#${entityOwnerId}` : `#${entityId}`}
        </Typography>
      </div>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default MediaHeader
