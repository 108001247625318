import { ProtectedRoute } from '@admin/routes/ProtectedRoute'

import type { TAny } from '@yzzy/types'

const UpdatedComponent = (OriginalComponent: TAny) => {
  function NewComponent() {
    return (
      <ProtectedRoute>
        <OriginalComponent />
      </ProtectedRoute>
    )
  }

  return NewComponent
}

export default UpdatedComponent
