import { createSvgIcon } from '@mui/material'

const ArrowDropUpIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.20712 13.5C7.76167 13.5 7.53858 12.9614 7.85356 12.6464L11.6465 8.85354C11.8417 8.65828 12.1583 8.65828 12.3536 8.85354L16.1465 12.6464C16.4614 12.9614 16.2384 13.5 15.7929 13.5H8.20712Z" />
  </svg>,
  'ArrowDropUp',
)

export default ArrowDropUpIcon
