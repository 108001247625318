import { MouseEvent, useEffect, useState } from 'react'

import { enqueueSnackbar, VariantType } from 'notistack'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppDispatch } from '@admin/store/hooks'
import { fetchSalesTaxCodes } from '@admin/store/slices/Settings/dictionariesSlice'
import { ITaxCode, ITaxRule, ITaxStatus, TaxesStatuses } from '@admin/store/slices/Settings/interface'
import { fetchChangeTaxCodeStatus, fetchChangeTaxStatus, fetchTaxRules } from '@admin/store/slices/Settings/taxesSlice'

import { possibleTaxStatuses, selectableTaxStatuses } from '../constants'

interface IChangeStatusConfirmModalProps {
  data: (ITaxCode & ITaxRule) | null
  onClose: () => void
  open: boolean
  page: number
  pageSize: number
}

export const ChangeStatusConfirmModal = ({ data, onClose, open, page, pageSize }: IChangeStatusConfirmModalProps) => {
  const dispatch = useAppDispatch()

  const salesTaxCode: Pick<ITaxCode, 'category' | 'code' | 'id' | 'salesTaxCodeId' | 'status'> | undefined = data?.salesTaxCode

  const [statusMenuAnchorElement, setStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITaxStatus | null>(null)
  const statusMenuOpen = Boolean(statusMenuAnchorElement)

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorElement(null)
  }

  const handleChangeStatusClick = async (salesTaxCode: ITaxStatus) => {
    setCurrentSelectedStatus(salesTaxCode)
    handleStatusMenuClose()
    const result = await dispatch(
      fetchChangeTaxCodeStatus({
        currentSelectedStatus: salesTaxCode,
        salesTaxCode: data?.salesTaxCode ? data.salesTaxCode : null,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Status of sales tax code has been changed to "${salesTaxCode?.title}"`, {
        variant: 'success' as VariantType,
      })
      dispatch(fetchSalesTaxCodes())
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, { variant: 'error' as VariantType })
    }
  }

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setStatusMenuAnchorElement(event.currentTarget)
  }

  const handleConfirm = async () => {
    const result = await dispatch(
      fetchChangeTaxStatus({
        currentSelectedRow: data,
        currentSelectedStatus: currentSelectedStatus,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Status of rule has been changed to "${currentSelectedStatus?.title}"`, {
        variant: 'success' as VariantType,
      })
      dispatch(fetchSalesTaxCodes())
      dispatch(fetchTaxRules({ page, pageSize }))
      onClose()
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred, try again later`, { variant: 'error' as VariantType })
    }
  }

  useEffect(() => {
    const currentStatus = possibleTaxStatuses.find((element) => salesTaxCode?.status?.toLowerCase() === element.status.toLowerCase())

    if (currentStatus) {
      setCurrentSelectedStatus(currentStatus)
    }
  }, [data, salesTaxCode?.status])

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">You are going to change the status of the sales tax rule to active</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          It is impossible to change the status because the status of the tax code is "Inactive" on the "sales tax codes" page. Change the status of
          the tax code directly in this window, and you can change the status of the rule.
        </Typography>
        <Stack direction="row" mt={2} spacing={2}>
          <Stack width="70%">
            <Typography mb={1} variant="tableHeader">
              Tax code
            </Typography>
            <Typography variant="body1">{salesTaxCode?.category}</Typography>
            <Typography variant="body1">{salesTaxCode?.code}</Typography>
          </Stack>

          <Stack>
            <Typography mb={1} variant="tableHeader">
              Status
            </Typography>
            <Box
              className="confirmation-modal__status-select"
              component="div"
              onClick={(event) => handleStatusMenuClick(event)}
              sx={{ color: currentSelectedStatus?.color }}
            >
              <Typography color={currentSelectedStatus?.color} m="auto 0" variant="body2">
                {currentSelectedStatus?.title}
              </Typography>
              {currentSelectedStatus?.status === salesTaxCode?.status && statusMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Box>

            <Menu
              id="status-menu"
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorEl={statusMenuAnchorElement}
              onClose={handleStatusMenuClose}
              open={statusMenuOpen}
            >
              {selectableTaxStatuses.map((status: ITaxStatus) => (
                <MenuItem
                  key={status.title}
                  onClick={() => handleChangeStatusClick(status)}
                  selected={status.status === currentSelectedStatus?.status}
                >
                  {status.title}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!currentSelectedStatus?.status || currentSelectedStatus?.status === TaxesStatuses.INACTIVE}
          onClick={handleConfirm}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
