import { createSvgIcon } from '@mui/material'

const ErrorIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 7.1499C12.4693 7.1499 12.8499 7.53046 12.8499 7.9999V12.9999C12.8499 13.4693 12.4693 13.8499 11.9999 13.8499C11.5305 13.8499 11.1499 13.4693 11.1499 12.9999V7.9999C11.1499 7.53046 11.5305 7.1499 11.9999 7.1499Z" />
    <path d="M11 17C10.996 16.448 11.444 16 11.996 16C12.552 16 13 16.448 13 17C13 17.552 12.552 18 12 18C11.448 18 11 17.552 11 17Z" />
    <path
      clipRule="evenodd"
      d="M1.1499 11.9999C1.1499 6.03046 6.03046 1.1499 11.9999 1.1499C17.9693 1.1499 22.8499 6.03046 22.8499 11.9999C22.8499 17.9693 17.9693 22.8499 11.9999 22.8499C6.03046 22.8499 1.1499 17.9693 1.1499 11.9999ZM11.9999 2.8499C6.96934 2.8499 2.8499 6.96934 2.8499 11.9999C2.8499 17.0305 6.96934 21.1499 11.9999 21.1499C17.0305 21.1499 21.1499 17.0305 21.1499 11.9999C21.1499 6.96934 17.0305 2.8499 11.9999 2.8499Z"
      fillRule="evenodd"
    />
  </svg>,
  'Error',
)

export default ErrorIcon
