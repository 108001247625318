import { ChangeEvent, forwardRef, useState } from 'react'

// import { useNavigate } from 'react-router-dom';
// import startCase from 'lodash/startCase';
// import { useDebounce } from '@uidotdev/usehooks';
// import { useAppDispatch } from '@admin/store/hooks'
import { Fade, Paper, Popper, useAutocomplete } from '@mui/material'

import List from '@admin/components/shared/List/List'
// import ListItem from '@admin/components/shared/List/ListItem/ListItem';
// import ListItemButton from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton';
// import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'

import type { TAny } from '@yzzy/types'

export const RequestsSearch = () => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  const searchResults: readonly TAny[] = []

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [searchValue, setSearchValue] = useState('')
  // const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isSearchError] = useState(false)
  // const debouncedSearchValue = useDebounce(searchValue.trim(), 500);

  const { getInputProps, getListboxProps, getRootProps, inputValue } = useAutocomplete({
    id: 'search-autocomplete',
    // getOptionLabel: (option) => option,
    disableCloseOnSelect: true,
    disabled: true,
    inputValue: searchValue,
    options: searchResults ? searchResults : [],
  })

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleClearEvent = () => {
    setSearchValue('')
  }

  // const handleSearchEvent = async () => {
  //   setIsLoadingSearch(true);
  //   try {
  //     const result = await dispatch(fetchTaxModerationSearch({ searchValue: debouncedSearchValue, listSize: 6 }));
  //
  //     if (result.meta.requestStatus === 'fulfilled') {
  //       setIsSearchError(false);
  //       setIsLoadingSearch(false);
  //     } else if (result.meta.requestStatus === 'rejected') {
  //       setIsSearchError(true);
  //       setIsLoadingSearch(false);
  //     }
  //   } catch (error) {
  //     setIsSearchError(true);
  //     setIsLoadingSearch(false);
  //   }
  // };

  // const handleListItemButtonClick = (id: string) => {
  // navigate(`/ui/tax-moderation/details/${id}`);
  // }

  // const renderOptions = (option, index: number): ReactNode => {
  //   const highlightParts = option.entityName?.split(new RegExp(`(${inputValue})`, 'gi'));
  //
  //   return (
  //     <ListItem {...getOptionProps({ option, index })} key={option.id} disablePadding>
  //       <ListItemButton onClick={() => handleListItemButtonClick(option.id)}>
  //         <ListItemText
  //           disableTypography
  //           primary={
  //             <Typography variant="body1">
  //               {highlightParts.map((part: string, index: Key) => (
  //                 <span style={{ fontWeight: part.toLowerCase() === inputValue.toLowerCase() ? 500 : 400 }} key={index}>
  //                   {part}
  //                 </span>
  //               ))}
  //             </Typography>
  //           }
  //           secondary={
  //             <Typography color="text.secondary" variant="body2">
  //               {startCase(option.entityType.toLowerCase())}
  //             </Typography>
  //           }
  //         />
  //       </ListItemButton>
  //     </ListItem>
  //   );
  // };

  const renderContent = () => {
    if (isSearchError) {
      return (
        <Stack alignItems="center" pt={1} spacing={1}>
          <Typography color="error" variant="body2">
            Loading results error
          </Typography>
          <LoadingButton
            color="primary"
            loading={true}
            loadingPosition="start"
            startIcon={null}
            sx={{ minWidth: 105, p: 0 }}
            // onClick={handleSearchEvent}
            variant="text"
          >
            Retry
          </LoadingButton>
        </Stack>
      )
    }

    // if (inputValue && searchResults && searchResults.length > 0) {
    //   return searchResults.map((result, index) => renderOptions(result, index));
    // }

    return (
      <Stack alignItems="center" gap={1} p="8px 0">
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    )
  }

  const RenderList = forwardRef<TAny, {}>((_, reference) => {
    return (
      <List {...getListboxProps()} ref={reference}>
        {renderContent()}
      </List>
    )
  })

  // useEffect(() => {
  //   handleSearchEvent();
  // }, [debouncedSearchValue]);

  return (
    <div id="consumtion-requests-search" {...getRootProps()}>
      <SearchField
        label="Search by case ID, operation ID"
        // searchEvent={handleSearchEvent}
        clearEvent={handleClearEvent}
        inputProps={{ ...getInputProps() }}
        maxlength={50}
        onBlur={() => setAnchorElement(null)}
        onChange={handleChangeSearch}
        onClick={(event) => setAnchorElement(event.currentTarget)}
        searchValue={inputValue}
        disabled
      />

      <Popper
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        placement="bottom-start"
        sx={{ minWidth: '300px', borderRadius: '4px', zIndex: 1200 }}
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <Paper elevation={4} sx={{ backgroundColor: 'background.paper', maxHeight: '393px', overflow: 'auto' }}>
              <RenderList />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}
