import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { Rules } from '@admin/components/Moderation/types/ModerationTabs'
import { MODERATION_RULES_API } from '@admin/shared/api/constants'
import { fetchAddRule, fetchDeleteRule } from '@admin/store/rulesSlice'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

export interface IModerationMetricsState {
  allMetrics: {
    content: string[]
    profile: string[]
  }
  availableMetrics: {
    content: string[]
    hive: string[]
    profile: string[]
  }
  dangerousMetrics: {
    content: string[]
    hive: string[]
    profile: string[]
  }
  status: TStatus
}

const initialState: IModerationMetricsState = {
  allMetrics: {
    content: [],
    profile: [],
  },
  availableMetrics: {
    content: [],
    hive: [],
    profile: [],
  },
  dangerousMetrics: {
    content: [],
    hive: [],
    profile: [],
  },
  status: 'idle',
}

export const fetchAllMetrics = createAsyncThunk('metrics/all', async () => {
  const url = MODERATION_RULES_API

  const responseContent = await axios.get(url + 'moderation/metrics/manual-content')
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile')

  return {
    content: responseContent.data,
    profile: responseProfile.data,
  }
})

export const fetchDangerousMetrics = createAsyncThunk('metrics/dangerous', async () => {
  const url = MODERATION_RULES_API

  const responseHiveText = await axios.get(url + 'moderation/metrics/hive/dangerous/TEXT')
  const responseHiveVISUAL = await axios.get(url + 'moderation/metrics/hive/dangerous/VISUAL')
  const responseContent = await axios.get(url + 'moderation/metrics/manual-content/dangerous')
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile/dangerous')

  return {
    content: responseContent.data,
    hive: responseHiveText.data.concat(responseHiveVISUAL.data),
    profile: responseProfile.data,
  }
})

export const fetchAvailableMetrics = createAsyncThunk('metrics/available', async () => {
  const url = MODERATION_RULES_API

  const responseHive = await axios.get(url + 'moderation/metrics/hive/available')
  const responseContent = await axios.get(url + 'moderation/metrics/manual-content/available')
  const responseProfile = await axios.get(url + 'moderation/metrics/manual-profile/available')

  return {
    content: responseContent.data,
    hive: responseHive.data,
    profile: responseProfile.data,
  }
})

export const moderationMetricsSlice = createSlice({
  name: 'metrics',
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMetrics.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAllMetrics.fulfilled, (state, action) => {
        state.status = 'idle'
        state.allMetrics = action.payload
      })
      .addCase(fetchAllMetrics.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDangerousMetrics.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDangerousMetrics.fulfilled, (state, action) => {
        state.status = 'idle'
        state.dangerousMetrics = action.payload
      })
      .addCase(fetchDangerousMetrics.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchAvailableMetrics.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAvailableMetrics.fulfilled, (state, action) => {
        state.status = 'idle'
        state.availableMetrics = action.payload
      })
      .addCase(fetchAvailableMetrics.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDeleteRule.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.availableMetrics.hive = [...state.availableMetrics.hive, payload.metric]
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.availableMetrics.content = [...state.availableMetrics.content, payload.metric]
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.availableMetrics.profile = [...state.availableMetrics.profile, payload.metric]
        }
      })
      .addCase(fetchAddRule.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        if (payload.typeRule === Rules.HIVE) {
          state.availableMetrics.hive = [...state.availableMetrics.hive.filter((value) => value !== payload.data.metric)]
        }
        if (payload.typeRule === Rules.MANUAL) {
          state.availableMetrics.content = [...state.availableMetrics.content.filter((value) => value !== payload.data.metric)]
        }
        if (payload.typeRule === Rules.PROFILE) {
          state.availableMetrics.profile = [...state.availableMetrics.profile.filter((value) => value !== payload.data.metric)]
        }
      })
  },
  initialState,
  reducers: {},
})

export default moderationMetricsSlice.reducer
export const selectAllMetrics = (state: TRootState): TAny => state.metrics.allMetrics
export const selectAvailableMetrics = (state: TRootState): TAny => state.metrics.availableMetrics
export const selectDangerousMetrics = (state: TRootState): TAny => state.metrics.dangerousMetrics
