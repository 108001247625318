import './index.scss'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { enqueueSnackbar, VariantType } from 'notistack'

import Button from '@admin/components/shared/Button/Button'
import { ICollection } from '@admin/store/collectionDetailsSlice'
import { useAppDispatch } from '@admin/store/hooks'
import { fetchUnpublishCollection } from '@admin/store/worldManagementSlice'

dayjs.extend(utc)

interface IProps {
  item: ICollection
  onClose(): void
}

// todo проверить, когда будут коллекции в статусе Live
const UnpublishCollectionModal = ({ item, onClose }: IProps) => {
  const dispatch = useAppDispatch()

  const handleUnpublish = async () => {
    try {
      const result = await dispatch(
        fetchUnpublishCollection({
          compilationId: item.id,
          deactivatedDateTime: dayjs().utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        }),
      )

      onClose()
      result.meta.requestStatus === 'fulfilled' &&
        enqueueSnackbar(`"${item.title}" collection has been unpublished`, { variant: 'success' as VariantType })
      result.meta.requestStatus === 'rejected' && enqueueSnackbar(`Unpublishing error, try again later`, { variant: 'error' as VariantType })
    } catch {
      enqueueSnackbar(`Unpublishing error, try again later`, { variant: 'error' as VariantType })
    }
  }

  return (
    <div className="unpublishCollectionsModal">
      <div className="unpublishCollectionsModal-header">You are going to unpublish {item.title} collection</div>
      <div className="unpublishCollectionsModal-footer">
        <div className="unpublishCollectionsModal-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button color="warning" onClick={handleUnpublish} variant="contained">
            Unpublish collection
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UnpublishCollectionModal
