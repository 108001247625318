import { LoadingButton as LoadingButtonBase } from '@mui/lab'
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton'

interface ILoadingButtonProps extends LoadingButtonProps {
  label?: string
  primary?: boolean
}

const LoadingButton = (props: ILoadingButtonProps) => {
  return <LoadingButtonBase {...props} />
}

export default LoadingButton
