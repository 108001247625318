import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

interface IResendActivationLinkModalProps {
  closeModal: () => void
  onResend: () => void
  open: boolean
  resendActivationLinkStatus: TStatus
  resendActivationLinkUser: IMemberInfo | null
}

export const ResendActivationLinkModal = ({
  closeModal,
  onResend,
  open,
  resendActivationLinkStatus,
  resendActivationLinkUser,
}: IResendActivationLinkModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">
        Resend activation link for {resendActivationLinkUser?.firstName + ' ' + resendActivationLinkUser?.lastName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          {resendActivationLinkUser?.firstName + ' ' + resendActivationLinkUser?.lastName}’s status will be changed to PENDING
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <LoadingButton loading={resendActivationLinkStatus === 'loading'} onClick={() => onResend()} variant="contained">
          Resend
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
