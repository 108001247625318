import './index.scss'

import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link, useLocation } from 'react-router-dom'

import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { enqueueSnackbar, VariantType } from 'notistack'

import ArrowBack from '@admin/assets/img/ArrowBack'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import Box from '@admin/components/shared/Box/Box'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import Button from '@admin/components/shared/Button/Button'
import CheckIfEmptyCell from '@admin/components/shared/DataGrid/CheckIfEmptyCell/CheckIfEmptyCell'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinkMUI from '@admin/components/shared/Link/Link'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Typography from '@admin/components/shared/Typography/Typography'
import { PaymentsSettingsRoutes } from '@admin/routes/enum'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { ITariffGroupDetails } from '@admin/store/slices/Settings/interface'
import { fetchTariffGroup, selectTariffGroupDetails, selectTariffGroupDetailsStatus } from '@admin/store/slices/Settings/tariffsSlice'
import { Permissions } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderWithTooltipHeader } from './components'

export const TariffDetails: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const parameters = new URLSearchParams(location.search)
  const tariffGroupId = parameters.get('tariffGroupId')
  const tariffGroupTitle = parameters.get('tariffGroupTitle')

  const userPermissions = useAppSelector(selectUserPermissions)
  const data = useAppSelector(selectTariffGroupDetails)
  const status = useAppSelector(selectTariffGroupDetailsStatus)
  const isLoading = status === 'loading'

  const { showBoundary } = useErrorBoundary()
  const [searchValue, setSearchValue] = useState<string>('')
  const [fetchingError, setFetchingError] = useState<boolean>(false)

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const fetchTariffGroupInitial = useCallback(async () => {
    try {
      tariffGroupId && (await dispatch(fetchTariffGroup(tariffGroupId)).unwrap())
    } catch (error) {
      showBoundary(error)
    }
  }, [dispatch])

  const fetchTariffGroupGrid = useCallback(async () => {
    tariffGroupId &&
      dispatch(fetchTariffGroup(tariffGroupId)).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
  }, [dispatch])

  const repeatFetchingRequest = useCallback(async () => {
    fetchTariffGroupGrid()
  }, [dispatch])

  const initialFetchData = useCallback(async () => {
    await fetchTariffGroupInitial()
  }, [])

  useEffect(() => {
    initialFetchData()
  }, [])

  const columns: GridColDef[] = [
    {
      minWidth: 140,
      display: 'flex',
      field: 'tariffId',
      flex: 0.5,
      headerName: 'Tariff ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 140,
      display: 'flex',
      field: 'operationType',
      flex: 0.3,
      headerName: 'Operation Type',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'providerName',
      flex: 0.3,
      headerName: 'Provider',
      sortable: false,
      valueFormatter: (value) => (value ? value : 'No provider'),
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'priceMin',
      flex: 0.3,
      headerName: 'Price min',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'priceMax',
      flex: 0.3,
      headerName: 'Price max',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'platformFeePercentageClient',
      flex: 0.3,
      headerName: 'PFP (Cl)',
      renderCell: (parameters) => CheckIfEmptyCell(parameters),
      renderHeader: (parameters) => <RenderWithTooltipHeader title="Platform fee percentage (client)" props={parameters} />,
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => `${value}%`,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'platformFeeFixedClient',
      flex: 0.3,
      headerName: 'PFF (Cl)',
      renderCell: (parameters) => CheckIfEmptyCell(parameters),
      renderHeader: (parameters) => <RenderWithTooltipHeader title="Platform fee fixed (client)" props={parameters} />,
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => {
        return value ? currencyFormatter(value, 'en-US', 'USD', true) : '—'
      },
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'platformFeePercentageCreator',
      flex: 0.3,
      headerName: 'PFP (Cr)',
      renderCell: ({ value }) => <span>{`${value}%`}</span>,
      renderHeader: (parameters) => <RenderWithTooltipHeader title="Platform fee percentage (creator)" props={parameters} />,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 100,
      display: 'flex',
      field: 'platformFeeFixedCreator',
      flex: 0.3,
      headerName: 'PFF (Cr)',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      renderHeader: (parameters) => <RenderWithTooltipHeader title="Platform fee fixed (creator)" props={parameters} />,
      sortable: false,
      type: 'number' as const,
    },
  ]

  const rows: [] | ITariffGroupDetails[] = useMemo(() => {
    if (data) {
      return data
    } else return []
  }, [data])

  const isEmptyData = !rows.length

  return (
    <div className="tariff-details-table-container">
      <div className="tariff-details-table-container__actions">
        {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_IN_GROUP]) && (
          <Button variant="contained" disabled>
            Add tariff
          </Button>
        )}

        {!isEmptyData && (
          <SearchField
            label="Search by tariff ID"
            clearEvent={() => setSearchValue('')}
            maxlength={50}
            onChange={handleChangeSearch}
            searchValue={searchValue}
            disabled
          />
        )}
      </div>
      <div className="tariff-details-table-container__breadcrumbs">
        <Link className="unstyled-link" color="default" to={PaymentsSettingsRoutes.TARIFFS}>
          <IconButton color="default" size="small">
            <ArrowBack />
          </IconButton>
        </Link>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI color="text.secondary" component={Link} to={PaymentsSettingsRoutes.TARIFFS} underline="hover" variant="h6">
            Tariff groups
          </LinkMUI>
          <Typography color="text.primary" variant="h6">
            {`${tariffGroupTitle} `}
            <span>
              <Typography color="text.secondary" display="inline" variant="h6">
                ({tariffGroupId})
              </Typography>
            </span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ flex: 1, mt: 2, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter
            columnVisibilityModel={{
              hidden: false,
            }}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            slots={{
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  fetchingError: fetchingError,
                  isFiltersApplied: false,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <>
                      <Typography color="text.secondary" variant="body2">
                        No tariffs fee has been added yet
                      </Typography>
                      {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_TARIFF_IN_GROUP]) && (
                        <Button color="primary" variant="text">
                          <Typography variant="buttonMedium">Create tariff</Typography>
                        </Button>
                      )}
                    </>
                  ),
                }),
            }}
            className="tariff-details-table"
            columnHeaderHeight={64}
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.tariffId}
            loading={isLoading}
            rows={rows}
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    </div>
  )
}
