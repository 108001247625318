import { PaymentFeesStatuses } from '@admin/store/slices/Settings/interface'
import colors from '@admin/theme/constants/colors'

export const possiblePaymentFeesStatuses = [
  {
    title: 'Active',
    color: colors.success,
    status: PaymentFeesStatuses.ACTIVE,
  },
  {
    title: 'Inactive',
    color: colors.error,
    status: PaymentFeesStatuses.INACTIVE,
  },
]
