import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { OPERATIONS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { IConsumptionRequestCase, IConsumptionRequestState, IConsumptionsDictionaries } from './interface'

const initialState: IConsumptionRequestState = {
  allIds: [],
  consumptionRequestCaseDetails: null,
  consumptionRequestCases: [],
  consumptionRequestCasesCount: 0,
  consumptionsDictionaries: null,
  status: 'idle',
}

export const fetchConsumptionRequestCases = createAsyncThunk(
  'consumptionRequest/cases',
  async ({ page, pageSize }: { page: number; pageSize: number }, { getState, rejectWithValue }) => {
    try {
      const rootState: TRootState = getState() as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
        : []
      const response = await axios.post(OPERATIONS_API + 'consumptions/find', {
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchConsumptionRequestSearch = createAsyncThunk(
  'consumptionRequest/cases',
  async ({ listSize = 3, searchValue }: { listSize?: number; searchValue: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(OPERATIONS_API + 'consumptions/search', {
        size: listSize,
        value: searchValue,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchConsumptionsDictionaries = createAsyncThunk('consumptions/dictionaries', async () => {
  try {
    const response = await axios.get(OPERATIONS_API + 'consumptions/dictionaries')

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error)
  }
})

export const fetchSendConsumptionRequest = createAsyncThunk('consumptions/sendRequest', async (data: TAny, { rejectWithValue }) => {
  try {
    const response = await axios.post(OPERATIONS_API + `consumptions/send`, data)

    return response.data
  } catch (error: unknown) {
    return isAxiosError(error) && rejectWithValue(error.response?.data)
  }
})

export const removeResolvedRequestId = createAction<string>('consumptions/removeResolvedRequestId')

export const ConsumptionRequestsSlice = createSlice({
  name: 'consumptionRequests',
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumptionRequestCases.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchConsumptionRequestCases.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.consumptionRequestCases = payload.data.content
        state.allIds = payload.allIds
        state.consumptionRequestCasesCount = payload.data.totalElements
      })
      .addCase(fetchConsumptionRequestCases.rejected, (state) => {
        state.status = 'failed'
        state.consumptionRequestCases = []
        state.allIds = []
        state.consumptionRequestCasesCount = 0
      })
      .addCase(fetchConsumptionsDictionaries.fulfilled, (state, { payload }) => {
        state.consumptionsDictionaries = payload
      })
      .addCase(removeResolvedRequestId, (state, action) => {
        state.allIds = state.allIds.filter((id) => id !== action.payload)
      })
  },
  initialState,
  reducers: {
    clearConsumptionRequestCases: (state) => {
      state.consumptionRequestCases = []
    },
    setConsumptionRequestStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectConsumptionRequestCases = (state: TRootState): IConsumptionRequestCase[] => state.consumptionRequests.consumptionRequestCases
export const selectConsumptionRequestCasesIds = (state: TRootState): string[] => state.consumptionRequests.allIds
export const selectTotalCount = (state: TRootState): number => state.consumptionRequests.consumptionRequestCasesCount
export const selectConsumptionRequestCasesStatus = (state: TRootState): TStatus => state.consumptionRequests.status
export const selectConsumptionDictionaries = (state: TRootState): IConsumptionsDictionaries | null =>
  state.consumptionRequests.consumptionsDictionaries

export const { clearConsumptionRequestCases, setConsumptionRequestStatus } = ConsumptionRequestsSlice.actions

export default ConsumptionRequestsSlice.reducer
