import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { WORLD_MANAGEMENT_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { IGender, ILocation } from '@admin/types/commonTypes'

export interface ICohort {
  createdAt: Date
  creatorLastName: string
  creatorName: string
  genders: IGender[] | null
  hasCompilation?: boolean
  id: string
  locations: ILocation[] | null
  maxAge: null | number
  minAge: null | number
  status: TCohortsStatus
  title: string
}

export interface ICohortState {
  cohort: null | TCohortDetails
}

export type TCohortDetails = Omit<ICohort, 'createdAt' | 'creatorLastName' | 'creatorName' | 'hasCompilation' | 'status'>

export type TCohortsStatus = 'ACTIVE' | 'WAITING'

const initialState: ICohortState = {
  cohort: null,
}

export const fetchCohort = createAsyncThunk('cohortDetails/fetchCohort', async (id: string) => {
  const result = await axios.get(WORLD_MANAGEMENT_API + `timworld/cohorts/${id}`)

  return { ...result.data }
})

export const fetchEditCohort = createAsyncThunk(
  'cohortDetails/editCohort',
  async ({ id, title, genders, locations, maxAge, minAge }: Pick<ICohort, 'genders' | 'id' | 'locations' | 'maxAge' | 'minAge' | 'title'>) => {
    await axios
      .patch(WORLD_MANAGEMENT_API + `timworld/cohorts/${id}`, {
        title,
        genderIds: genders?.map((gender) => gender.id),
        locations,
        maxAge,
        minAge,
      })
      .catch((error: unknown) => {
        if (axios.isAxiosError(error)) {
          throw Error(error.response?.data.techInfo.title)
        } else {
          throw Error('An unexpected error occurred')
        }
      })

    return { id, title, genders, locations, maxAge, minAge }
  },
)

export const cohortDetailsSlice = createSlice({
  name: 'cohortDetails',
  extraReducers: (builder) => {
    builder
      .addCase(fetchCohort.fulfilled, (state, { payload }) => {
        state.cohort = payload
      })
      .addCase(fetchCohort.rejected, (state) => {
        state.cohort = null
      })
      .addCase(fetchEditCohort.fulfilled, (state, { payload }) => {
        state.cohort = payload
      })
  },
  initialState,
  reducers: {
    clearDetails: (state) => {
      state.cohort = null
    },
  },
})

export const selectCohortDetails = (state: TRootState): null | TCohortDetails => state.cohortDetails.cohort

export const { clearDetails } = cohortDetailsSlice.actions
export default cohortDetailsSlice.reducer
