import { useState } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import InfoFilledIcon from '@admin/assets/img/InfoFilledIcon'
import Button from '@admin/components/shared/Button/Button'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Popover from '@admin/components/shared/Popover/Popover'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { TransactionTypes } from '@admin/store/slices/Payments/interface'
import colors from '@admin/theme/constants/colors'

/**
 * Props for the RenderInfoCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderInfoCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a special cell in a transaction table.
 */
export const RenderInfoCell = ({ props }: IRenderInfoCellProps) => {
  const { row } = props

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })

  if (!row.id) {
    return null
  }

  const isRefund = row.type === TransactionTypes.REFUND

  return (
    <>
      {isRefund && (
        <IconButton
          onClick={(event) => {
            setAnchorElement(event.currentTarget)
            setCoordinates({ x: event.clientX, y: event.clientY })
          }}
          size="small"
        >
          <InfoFilledIcon sx={{ color: anchorElement ? colors.standardInputLine : colors.actionDisabled, fontSize: 20 }} />
        </IconButton>
      )}
      <Popover
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorPosition={{
          left: coordinates.x - 20,
          top: coordinates.y,
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        anchorReference="anchorPosition"
        onClose={() => setAnchorElement(null)}
        open={!!anchorElement}
      >
        <Stack sx={{ p: '24px 24px 0' }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Refund reason
                </Typography>
              }
              secondary={
                <Typography color="text.primary" variant="subtitle1">
                  {row.reason || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Comment
                </Typography>
              }
              secondary={
                <Typography color="text.primary" sx={{ maxWidth: '30vw', maxHeight: '30vh', overflow: 'auto' }} variant="subtitle1">
                  {row.comment || '—'}
                </Typography>
              }
              disableTypography
            />
          </ListItem>
        </Stack>

        <DialogActions>
          <Button onClick={() => setAnchorElement(null)}>Close</Button>
        </DialogActions>
      </Popover>
    </>
  )
}
