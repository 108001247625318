import * as yup from 'yup'

import { ModalAction } from '@admin/types/commonTypes'

import { SalesTaxGroup } from '../../constants'

const commonRules = {
  certainty: yup.string().required('Required field'),
  entityType: yup.string().required('Required field'),
  eventCategories: yup.array(),
  eventOnlineFormatType: yup.string(),
  format: yup.string().required('Required field'),
  isDefaultSalesTaxRule: yup.boolean(),
  passions: yup.array(),
  salesTaxCode: yup.object().shape({
    category: yup.string().required('Required field'),
    code: yup.string().required('Required field'),
  }),
}

const getValidationSchema = (action: ModalAction, contentType: SalesTaxGroup, isDefaultRule?: boolean) => {
  if (action === ModalAction.ADD && contentType === SalesTaxGroup.CODES) {
    return yup.object().shape({
      category: yup.string().required('Required field').trim().max(100, 'Max limit of 100 characters is exceeded'),
      code: yup.string().required('Required field').trim().max(25, 'Max limit of 25 characters is exceeded'),
      description: yup.string().required('Required field').trim().max(800, 'Max limit of 800 characters is exceeded'),
    })
  }

  if (action === ModalAction.ADD && contentType === SalesTaxGroup.RULES) {
    return yup.object().shape(commonRules)
  }

  if (action === ModalAction.EDIT && isDefaultRule && contentType === SalesTaxGroup.RULES) {
    return yup.object().shape({
      certainty: yup.string().required('Required field'),
      entityType: yup.string(),
      format: yup.string(),
      salesTaxCode: yup.object().shape({
        category: yup.string().required('Required field'),
        code: yup.string().required('Required field'),
      }),
    })
  } else {
    return yup.object().shape(commonRules)
  }
}

export default getValidationSchema
