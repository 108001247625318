import { useMemo, useState } from 'react'

import { truncate } from 'lodash'

/**
 * Hook that manages the state for expanding and collapsing a text description.
 *
 * @param {string} description - The full description text that may be expanded or truncated.
 * @param {number} [maxLength=250] - The maximum length of the truncated description.
 * @returns An object containing the current expansion state, a function to toggle the state, and a function to render the description.
 */
const useExpandableDescription = (description = '', maxLength = 250) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpansion = () => setIsExpanded((previousState) => !previousState)

  const truncatedDescription = useMemo(
    () =>
      truncate(description, {
        length: maxLength + 3, // Account for ellipsis (...)
      }),
    [description, maxLength],
  )

  const renderedDescription = isExpanded ? description : truncatedDescription

  return { isExpanded, renderedDescription, toggleExpansion }
}

export default useExpandableDescription
