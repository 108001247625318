import { TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

import type { TAny } from '@yzzy/types'

import { OperationTypes } from '../Payments/interface'
import { EntityType } from '../Settings/interface'

/**
 * Enumerates the possible status values for a consumption request.
 *
 * @enum {string}
 * @readonly
 */
export enum ConsumptionRequestStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

/**
 * Enumerates the possible types for a consumption request case.
 *
 * @enum {string}
 * @readonly
 */
export enum ConsumptionRequestTypes {
  APPLE = 'APPLE',
  OTHER = 'OTHER',
}

/* ------------------------------ Requests Refunds & Disputes ------------------------------ */
/**
 * Represents a consumption request case.
 *
 * @interface
 * @property {string} operationId - The unique identifier of the operation associated with the consumption request case.
 * @property {string} consumptionRequestId - The unique identifier of the consumption request.
 * @property {string} profileId - The unique identifier of the profile associated with the consumption request case.
 * @property {EntityType} entityType - The type of entity to which the consumption request case applies.
 * @property {OperationTypes} operationType - The type of operation associated with the consumption request case.
 * @property {any} data - Additional data related to the consumption request case.
 * @property {ConsumptionRequestStatus} consumptionRequestStatus - The status of the consumption request case.
 * @property {ConsumptionRequestTypes} consumptionRequestType - The type of consumption request.
 * @property {Date} consumptionRequestCreationDate - The date when the consumption request case was created.
 * @property {IMemberInfo} [memberInfo] - Information about the member associated with the consumption request case.
 * @property {string} [reason] - The reason for the consumption request case.
 * @property {string} [comment] - Any additional comments related to the consumption request case.
 * @property {Date} updateAt - The date the record's data was last updated.
 * @property {Date} createdAt - The date the record was created.
 * @property {Date} closingDate - The date the record was assigned consumptionRequestStatus = 'CLOSED'.
 */
export interface IConsumptionRequestCase {
  closingDate?: Date
  comment?: string
  consumptionRequestCreationDate: Date
  consumptionRequestId: string
  consumptionRequestStatus: ConsumptionRequestStatus
  consumptionRequestType: ConsumptionRequestTypes
  createdAt?: Date
  data: TAny
  entityType: EntityType
  memberInfo?: IMemberInfo
  operationId: string
  operationType: OperationTypes
  profileId: string
  reason?: string
  updateAt?: Date
}

/**
 * Represents the state of consumption requests in the store.
 *
 * @interface
 * @property {IConsumptionRequestCase[]} consumptionRequestCases - An array of consumption request cases.
 * @property {string[]} allIds - An array of unique identifiers for all consumption request cases.
 * @property {IConsumptionRequestCase | null} consumptionRequestCaseDetails - Details of a specific consumption request case.
 * @property {number} consumptionRequestCasesCount - The total count of consumption request cases.
 * @property {IConsumptionsDictionaries | null} consumptionsDictionaries - Dictionaries for consumption request case.
 * @property {TStatus} status - The status value for the state data.
 */
export interface IConsumptionRequestState {
  allIds: string[]
  consumptionRequestCaseDetails: IConsumptionRequestCase | null
  consumptionRequestCases: IConsumptionRequestCase[]
  consumptionRequestCasesCount: number
  consumptionsDictionaries: IConsumptionsDictionaries | null
  status: TStatus
}

/**
 * Interface for consumption dictionaries.
 */
export interface IConsumptionsDictionaries {
  accountTenure: { [key: string]: string }
  consumptionStatus: { [key: string]: string }
  deliveryStatus: { [key: string]: string }
  lifetimeDollarsPurchased: { [key: string]: string }
  lifetimeDollarsRefunded: { [key: string]: string }
  playTime: { [key: string]: string }
  userStatus: { [key: string]: string }
}
