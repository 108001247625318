import * as yup from 'yup'

const getValidationSchema = (action: 'add' | 'edit') => {
  if (action === 'add') {
    return yup.object().shape({
      name: yup.string().required('Name is a required field'),
      subtitle: yup.string().max(50, ''),
      title: yup.string().max(70, ''),
      // cover: yup.mixed<File>().test(
      //   "required",
      //   "Please select a file",
      //   files => !files),
    })
  }

  return yup.object().shape({
    name: yup.string().required('Name is a required field'),
    subtitle: yup.string().max(50, ''),
    title: yup.string().max(70, ''),
    article: yup.object().shape({
      editorString: yup.string(),
      textString: yup.string().max(2000, ''),
    }),
    expiredDate: yup.date().nullable(''), //.min(new Date(), ''),
  })
}

export default getValidationSchema
