import './index.scss'

import { useEffect, useState } from 'react'

import { DateTimePickerTabs } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { enqueueSnackbar, VariantType } from 'notistack'

import CalendarIcon from '@admin/assets/img/CalendarIcon'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import DateTimePicker from '@admin/components/shared/DateTimePicker/DateTimePicker'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { ICollection } from '@admin/store/collectionDetailsSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchCohortsLinkedCollections,
  fetchPublishCollections,
  ICohortsLinkedCollection,
  selectCohortsLinkedCollections,
} from '@admin/store/worldManagementSlice'
import { TStatus } from '@admin/types/commonTypes'

dayjs.extend(utc)

interface IProps {
  items: ICollection | ICollection[]
  onClose(): void
  status: TStatus
}

// todo проверить, когда будут коллекции в статусе Live
const PublishCollectionsModal = ({ items, onClose, status }: IProps) => {
  let content
  let title
  const isArray = Array.isArray(items)
  const compilationIds = isArray ? items.map(({ id }) => id) : [items.id]

  const [dateWithoutInitialValue, setDateWithoutInitialValue] = useState<Dayjs | null>(null)
  const [error, setError] = useState(false)
  const [willUnpublishCollections, setWillUnpublishCollections] = useState<ICohortsLinkedCollection[]>([])

  const allLinkedCollections: [] | ICohortsLinkedCollection[] = useAppSelector(selectCohortsLinkedCollections)

  const dispatch = useAppDispatch()

  if (Array.isArray(items)) {
    title = `Publish ${items.length} collections`
    content = (
      <div className={`publishCollectionsModal-content ${items.length > 3 && 'publishCollectionsModal-content_scroll'}`}>
        {items.map((item) => (
          <div key={item.id} className="item-content">
            <div className="item-primary">{item.title}</div>
            <div className="item-secondary">
              {item.creatorName} {item.creatorLastName}
            </div>
          </div>
        ))}
      </div>
    )
  } else {
    title = `Publish ${items.title} collection`
    content = null
  }

  const unpublishList = willUnpublishCollections.length ? (
    <Alert severity="warning">
      {!isArray ? (
        `Collection "${willUnpublishCollections[0].compilation.title}" for "${willUnpublishCollections[0].cohort.title}" cohort will be unpublished`
      ) : (
        <ul className="publishCollectionsModal-footer-list">
          {willUnpublishCollections.map((unpublish) => (
            <li key={unpublish.compilation.id} className="publishCollectionsModal-footer-listItem">
              {`Collection "${unpublish.compilation.title}" for "${unpublish.cohort.title}" cohort will be unpublished`}
            </li>
          ))}
        </ul>
      )}
    </Alert>
  ) : null

  const handlePublish = async () => {
    try {
      const result = await dispatch(
        fetchPublishCollections({
          compilationIds: compilationIds,
          deactivatedDateTime: dateWithoutInitialValue ? dateWithoutInitialValue.utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]') : undefined,
          publishDateTime: dayjs().utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        }),
      )

      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
        enqueueSnackbar(`${isArray ? items.length + 'collections have' : `"${items.title}" collection has`} been published`, {
          variant: 'success' as VariantType,
        })
      }
      result.meta.requestStatus === 'rejected' && enqueueSnackbar('Publishing error, try again later', { variant: 'error' as VariantType })
      result.meta.requestStatus === 'rejected' && setError(true)
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    dispatch(fetchCohortsLinkedCollections())
  }, [])

  useEffect(() => {
    setWillUnpublishCollections(allLinkedCollections.filter((linked) => compilationIds.includes(linked.compilation.id)))
  }, [allLinkedCollections, compilationIds])

  return (
    <div className="publishCollectionsModal">
      <div className="publishCollectionsModal-header">{title}</div>
      {content}
      <div className="publishCollectionsModal-footer">
        <DateTimePicker
          label="Deactivation date & time (optional)"
          onChange={(newValue) => {
            setDateWithoutInitialValue(newValue)
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              size: 'small',
            },
          }}
          slots={{
            openPickerIcon: CalendarIcon,
            tabs: DateTimePickerTabs,
          }}
          minDateTime={dayjs()}
          value={dateWithoutInitialValue}
        />
        {error ? <Alert severity="error">Publishing error. Try later</Alert> : unpublishList}
        <div className="publishCollectionsModal-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton color="primary" loading={status === 'loading'} onClick={handlePublish} variant="contained">
            Publish collection{Array.isArray(items) ? 's' : ''}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default PublishCollectionsModal
