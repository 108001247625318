import './index.scss'

import { useMemo } from 'react'

import MemberAvatar from '@admin/components/Profile/MemberProfile/MemberAvatar/MemberAvatar'
import Button from '@admin/components/shared/Button/Button'
import List from '@admin/components/shared/List/List'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import { TAvatarAction } from '@admin/containers/Profile/Profile'
import { IMember } from '@admin/store/profileSlice'
import { IMedia } from '@admin/types/commonTypes'

interface IProps {
  avatarPreview: IMedia
  memberProfile: IMember
  setShowChangePasswordModal(): void
  setShowGlobalLogoutModal(): void
  setShowSetAvatar(action: TAvatarAction): void
}

const MemberProfile = ({ avatarPreview, memberProfile, setShowChangePasswordModal, setShowGlobalLogoutModal, setShowSetAvatar }: IProps) => {
  const registered = useMemo(() => {
    return new Date(memberProfile.registered ?? '').toLocaleString('en-AU', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
  }, [])

  return (
    <div className="profileCard">
      <div className="profileCard-generalInfo">
        <MemberAvatar avatarPreview={avatarPreview} setShowSetAvatar={setShowSetAvatar} />
        <div className="profileCard-generalInfo-info">
          <div className="profileCard-title">Info</div>
          <div className="profileCard-generalInfo-info-data">
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={memberProfile.firstName} />
                <ListItemText primary="Surname" secondary={memberProfile.lastName} />
                <ListItemText primary="Role" secondary={memberProfile.role.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={memberProfile.email} />
                <ListItemText primary="Registration date" secondary={registered.toLowerCase()} />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
      <div className="profileCard-controls">
        <div className="profileCard-title">Controls</div>
        <div className="profileCard-controls-buttonGroup">
          <Button onClick={() => setShowChangePasswordModal()} variant="outlined">
            Change password
          </Button>
          <Button onClick={() => setShowGlobalLogoutModal()} variant="text">
            Log out from ALL DEVICES
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MemberProfile
