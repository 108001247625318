import Carousel from 'react-material-ui-carousel'

import UsdLockedIcon from '@admin/assets/img/UsdLockedIcon'
import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import colors from '@admin/theme/constants/colors'

import type { TAny } from '@yzzy/types'

const LifestyleCard = (props: TAny) => {
  const widthEntity = 200
  const heightEntity = 300
  const isPaidLifeStyle = props.data.price && props.data.priceOption !== 'FREE'

  return props.data.orderedMediaList.length > 1 ? (
    <Carousel
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      animation="slide"
      autoPlay={false}
      className="lifestyleCard--Carousel"
      height={300}
      indicatorContainerProps={{ style: { display: 'flex', gap: '4px', justifyContent: 'center' } }}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      navButtonsAlwaysInvisible={true}
      swipe={false}
    >
      {props.data.orderedMediaList.map((element: TAny, index: number) => (
        <div key={index} className="carouselEntity">
          <div
            onClick={() => {
              props.showMedia(
                element.mediaUrl ? element.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                element,
                element.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              )
            }}
            className="overlay"
            style={{ width: widthEntity, height: heightEntity }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <div className="lifestyleCard--background">
            <img
              width={widthEntity}
              alt="Lifestyle media"
              height={heightEntity}
              src={element?.mediaPreviewUrl ? element.mediaPreviewUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'}
            />
          </div>
          {isPaidLifeStyle && (
            <IconButton className="lifestyleCardContent--paidIcon" size="small" disabled disableRipple>
              <UsdLockedIcon sx={{ color: colors.white }} />
            </IconButton>
          )}
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaType: 'Image',
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
          }

          props.showMedia(mediaItem.mediaUrl, mediaItem, mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image', props.data.orderedMediaList)
        }}
        className="overlay"
        style={{ width: widthEntity, height: heightEntity }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <div className="lifestyleCard--background">
        <img
          src={
            props.data.orderedMediaList[0]?.mediaPreviewUrl
              ? props.data.orderedMediaList[0].mediaPreviewUrl
              : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'
          }
          alt="Lifestyle media"
        />
      </div>
      {isPaidLifeStyle && (
        <IconButton className="lifestyleCardContent--paidIcon" size="small" disabled disableRipple>
          <UsdLockedIcon sx={{ color: colors.white }} />
        </IconButton>
      )}
    </div>
  )
}

export default LifestyleCard
