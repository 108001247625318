import './index.scss'

import { useMemo } from 'react'

import { Badge } from '@yzzy/ui'

import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

import type { TAny } from '@yzzy/types'

const WishCard = (props: TAny) => {
  const userInfo = props.data.userInfoDto ?? {}
  const { firstName, lastName } = userInfo

  const isExpired = props.data.entityStatus === 'EXPIRED'

  const wishTypeClass = useMemo(() => {
    const price = Number(props.data.price)
    const priceOption = props.data.priceOption

    if (priceOption === 'FLEXIBLE') return 'wish-flexible'
    if (price === 0) return 'wish-0'
    if (price < 51) return 'wish-50'
    if (price < 250) return 'wish-250'
    if (price < 500) return 'wish-500'

    return 'wish-max'
  }, [props.data.price, props.data.priceOption])

  return (
    <div className="wishCard--container">
      <div className={`wishCard--bg ${wishTypeClass} ${isExpired ? 'wishCard--bg__disabled' : ''}`} />
      <div className="wishCard--description">
        <div>
          <Badge disabled={isExpired} type={props.data.format} />
        </div>
        <div>
          {isExpired && (
            <Badge
              sx={{
                marginBottom: '8px',
              }}
              customText="timed out"
              type="EXPIRED"
            />
          )}
          <div className="wishCard--title">{props.data.title}</div>
          <div className="wishCard--avatar">
            <AvatarItem
              primaryText={firstName && lastName ? `${firstName} ${lastName}` : 'Unknown user'}
              sizes="small"
              src={userInfo.avatarUrl}
              subText="Creator"
              textColor="white"
            />
          </div>
        </div>
      </div>
      <div className="wishCard--priceContainer">
        <div className="wishCard--priceText">You'll get{props.data.priceOption === 'FIXED' ? '' : ' up to'}:</div>
        <div className="wishCard--priceValue">
          {Number(props.data.price) === 0 ? 'Free' : `${currencyFormatter(props.data.price, 'en-US', 'USD')}`}
        </div>
      </div>
    </div>
  )
}

export default WishCard
