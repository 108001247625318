import {
  ITaxModerationCardSolution,
  ITaxModerationCardStatus,
  TaxModerationSolution,
  TaxModerationStatus,
} from '@admin/store/slices/TaxModeration/interface'
import colors from '@admin/theme/constants/colors'
import { ISortingItem } from '@admin/types/commonTypes'

export const defaultTaxModerationSortingModel: ISortingItem = {
  title: 'Received',
  columnId: 'sales_tax_moderation_created_at',
  sortingType: 'DESC',
}

export const taxModerationCardSolutions: ITaxModerationCardSolution[] = [
  {
    title: 'Approved',
    color: colors.success,
    solution: TaxModerationSolution.APPROVED,
  },
  {
    title: 'Modified',
    color: colors.warning,
    solution: TaxModerationSolution.MODIFIED,
  },
  {
    title: 'Assigned to Supervisor',
    color: colors.warning,
    solution: TaxModerationSolution.ASSIGN_TO_SUPERVISOR,
  },
  {
    title: 'Approved',
    color: colors.success,
    solution: TaxModerationSolution.AUTO_APPROVED,
  },
  {
    title: 'Unresolved',
    color: colors.warning,
    solution: TaxModerationSolution.NEEDS_REVIEW,
  },
]

export const taxModerationCardStatuses: ITaxModerationCardStatus[] = [
  {
    title: 'Unresolved',
    color: colors.warning,
    status: TaxModerationStatus.NEEDS_REVIEW,
  },
  {
    title: 'Solved',
    color: colors.success,
    status: TaxModerationStatus.CALCULATED,
  },
  {
    title: 'Assigned to Supervisor',
    color: colors.success,
    status: TaxModerationStatus.ASSIGN_TO_SUPERVISOR,
  },
  {
    title: 'Solved',
    color: colors.success,
    status: TaxModerationStatus.RESOLVED,
  },
]

export const TaxModerationCardSolutionItems: Omit<ITaxModerationCardSolution, 'color'>[] = [
  {
    title: 'Approve',
    solution: TaxModerationSolution.APPROVED,
  },
  {
    title: 'Modify',
    solution: TaxModerationSolution.MODIFIED,
  },
]

export const enum TaxModerationListType {
  SOLVED = 'SOLVED',
  UNRESOLVED = 'UNRESOLVED',
}

export const UNRESOLVED_FILTERS_KIND = 'unresolved_sales_tax_moderation'
export const SOLVED_FILTER_KIND = 'solved_sales_tax_moderation'
