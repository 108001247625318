import { Store } from '@reduxjs/toolkit'
import axios from 'axios'
import { enqueueSnackbar, VariantType } from 'notistack'

import { action } from '@admin/containers/SnackbarContainer'
import { AUTH_MEMBERS_API } from '@admin/shared/api/constants'
import { logout, refresh, updateSnackbarKeys } from '@admin/store/authSlice'
import { LocalStorageKeys } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

let store: Store

export const injectStore = (_store: Store) => {
  store = _store
}

axios.interceptors.request.use(
  (config) => {
    if (
      config.url === AUTH_MEMBERS_API + 'members/sign-in' ||
      config.url === AUTH_MEMBERS_API + 'members/password-setup' ||
      config.url === AUTH_MEMBERS_API + 'members/verify-otp'
    ) {
      return config
    }
    const accessToken: string = localStorage.getItem('accessToken') ?? ''
    const memberId: string = store.getState().auth.memberId

    config.headers.set('Member-Id', memberId)
    config.headers.set('Access-Token', accessToken)

    return config
  },
  (error) => {
    return error
  },
)

let failedQueue: TAny[] = []
let isRefreshing = false

const processQueue = (error: unknown, data: null | { accessToken: string; memberId: string } = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(data)
    }
  })

  failedQueue = []
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalConfig = error.config

    if (
      (originalConfig.url !== AUTH_MEMBERS_API + 'members/sign-in' ||
        originalConfig.url !== AUTH_MEMBERS_API + 'members/password-setup' ||
        originalConfig.url !== AUTH_MEMBERS_API + 'members/verify-otp') &&
      error.response
    ) {
      // Access Token was expired
      if (error.response.status === 401 && store.getState().auth.refreshToken && store.getState().auth.isAuth) {
        if (isRefreshing) {
          // eslint-disable-next-line promise/no-promise-in-callback
          return new Promise(function (resolve, reject) {
            failedQueue.push({ reject, resolve })
          })
            .then((data) => {
              const { accessToken, memberId } = data as { accessToken: string; memberId: string }

              originalConfig.headers.set('Member-Id', memberId)
              originalConfig.headers.set('Access-Token', accessToken)

              return axios(originalConfig)
            })
            .catch((error_) => {
              // eslint-disable-next-line promise/no-return-wrap
              return Promise.reject(error_)
            })
        }

        isRefreshing = true
        let memberId = ''
        let accessToken = ''

        try {
          const refreshData = await axios.post(AUTH_MEMBERS_API + 'members/refresh-token', {
            memberId: store.getState().auth.memberId,
            refreshToken: store.getState().auth.refreshToken,
          })

          accessToken = refreshData.data.accessToken
          memberId = refreshData.data.memberId
          localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken)
          localStorage.setItem(LocalStorageKeys.MEMBER_ID, memberId)

          originalConfig.headers.set('Member-Id', memberId)
          originalConfig.headers.set('Access-Token', accessToken)
          store.dispatch(refresh({ accessToken, memberId }))
        } catch (_error) {
          store.dispatch(logout({ message: '' }))
          processQueue(_error, null)
          const key = enqueueSnackbar('Session has been ended, you have to relogin', {
            autoHideDuration: null,
            action,
            variant: 'warning' as VariantType,
          })
          const snackbarKeys = store.getState().auth.snackbarKeys

          store.dispatch(updateSnackbarKeys([...snackbarKeys, key]))

          return Promise.reject(_error)
        } finally {
          isRefreshing = false
        }
        processQueue(null, { accessToken, memberId })

        return await axios(originalConfig)
      }
    }

    return Promise.reject(error)
  },
)
