import { ITaxStatus, TaxesStatuses, TaxRuleCertainty } from '@admin/store/slices/Settings/interface'
import colors from '@admin/theme/constants/colors'

export const possibleTaxStatuses: ITaxStatus[] = [
  {
    title: 'Active',
    color: colors.success,
    status: TaxesStatuses.ACTIVE,
  },
  {
    title: 'Inactive',
    color: colors.error,
    status: TaxesStatuses.INACTIVE,
  },
  {
    title: 'Default',
    color: colors.success,
    status: TaxesStatuses.DEFAULT,
  },
]

export const selectableTaxStatuses: ITaxStatus[] = [
  {
    title: 'Active',
    color: colors.success,
    status: TaxesStatuses.ACTIVE,
  },
  {
    title: 'Inactive',
    color: colors.error,
    status: TaxesStatuses.INACTIVE,
  },
]

/**
 * Represents sales tax groups.
 *
 * @enum {string}
 * @readonly
 */
export enum SalesTaxGroup {
  CODES = 'Codes',
  RULES = 'Rules',
}

export const possibleTaxRuleCertainty = [
  {
    certainty: TaxRuleCertainty.FULL,
    color: colors.success,
  },
  {
    certainty: TaxRuleCertainty.UNCERTAIN,
    color: colors.warning,
  },
]

/**
 * Enum representing error codes in taxes.
 *
 * @enum {string}
 * @readonly
 * @property {string} TAX_CODE_USED_IN_RULES - Error code for when a tax code is used in the rules.
 * @property {string} INCORRECT_TAX_CODE_STATUS - Error code for incorrect tax code status.
 */
export enum ErrorCodes {
  INCORRECT_TAX_CODE_STATUS = 'ASTR-002',
  TAX_CODE_USED_IN_RULES = 'ASTR-001',
}
