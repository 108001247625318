import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as DatePickerBase, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'

const DatePicker = (props: DatePickerProps<Dayjs, false>) => {
  return (
    <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
      <DatePickerBase {...props} />
    </LocalizationProvider>
  )
}

export default DatePicker
