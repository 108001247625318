import './index.scss'

import { useEffect, useRef, useState } from 'react'

import CheckCircleIcon from '@admin/assets/img/CheckCircleIcon'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'

const ModerationGridFooter = () => {
  const timeoutId = useRef<null | ReturnType<typeof setTimeout>>(null)

  const [isAllLoaded, setIsAllLoaded] = useState(true)

  useEffect(() => {
    if (isAllLoaded) {
      timeoutId.current = setTimeout(function () {
        setIsAllLoaded(false)
      }, 3000)
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [isAllLoaded])

  if (!isAllLoaded) {
    return null
  }

  return (
    <div id="grid-footer-container" className="container">
      <Stack alignItems="center" mt={2}>
        <CheckCircleIcon sx={{ color: colors.outlinedBorder, fontSize: 32 }} />
        <Typography color={colors.outlinedBorder} variant="h6">
          No more tickets
        </Typography>
      </Stack>
    </div>
  )
}

export default ModerationGridFooter
