import { IMetrics } from '@admin/store/moderationSlice'

import type { TAny } from '@yzzy/types'

const getConfirmedHiveViolations = (moderationResult: TAny[]): [] | string[] => {
  if (!moderationResult || !moderationResult.length) {
    return []
  }

  const set = new Set<string>()

  moderationResult.forEach(({ metrics }) => metrics.forEach(({ metricName }: IMetrics) => set.add(metricName)))

  return Array.from(set)
}

export default getConfirmedHiveViolations
