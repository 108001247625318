import './index.scss'

import React from 'react'

import Button from '@admin/components/shared/Button/Button'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { useAppSelector } from '@admin/store/hooks'
import { selectProfileStatus } from '@admin/store/profileSlice'

interface IProps {
  logout(): void
  onClose(): void
}

const GlobalLogout = ({ logout, onClose }: IProps) => {
  const status = useAppSelector(selectProfileStatus)

  return (
    <div className="profileModal">
      <div className="profileModal-title">Log out from all devices</div>
      <div className="profileModal-form">
        <span>You will be logged out from all devices including current one</span>
        <div className="profileModal-buttonsGroup">
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton color="error" loading={status === 'loading'} loadingPosition="start" onClick={logout} startIcon={null} variant="contained">
            Log out
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default GlobalLogout
