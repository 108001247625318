const SpeedIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.19481 16.5463C1.37054 17.4313 2.22788 18 3.16657 18H20.8334C21.7721 18 22.6295 17.4313 22.8052 16.5463C22.9331 15.9023 23 15.2375 23 14.5578C23 8.72689 18.0751 4 12 4C5.92487 4 1 8.72689 1 14.5578C1 15.2375 1.06693 15.9023 1.19481 16.5463ZM18.2692 13.7176C17.7528 13.7176 17.3342 14.1194 17.3342 14.615C17.3342 15.1107 17.7528 15.5125 18.2692 15.5125H20.1958C20.7122 15.5125 21.1308 15.1107 21.1308 14.615C21.1308 14.1194 20.7122 13.7176 20.1958 13.7176H18.2692ZM2.87344 14.615C2.87344 14.1194 3.29205 13.7176 3.80844 13.7176H5.73508C6.25147 13.7176 6.67008 14.1194 6.67008 14.615C6.67008 15.1107 6.25147 15.5125 5.73508 15.5125H3.80844C3.29205 15.5125 2.87344 15.1107 2.87344 14.615ZM11.065 8.54555C11.065 9.04118 11.4836 9.44296 12 9.44296C12.5164 9.44296 12.935 9.04118 12.935 8.54555V6.69636C12.935 6.20073 12.5164 5.79894 12 5.79894C11.4836 5.79894 11.065 6.20073 11.065 6.69636V8.54555ZM16.1217 10.6548C15.7565 10.3044 15.7565 9.73617 16.1217 9.38571L17.1618 8.38736C17.527 8.0369 18.119 8.0369 18.4841 8.38736C18.8493 8.73782 18.8493 9.30604 18.4841 9.6565L17.444 10.6548C17.0788 11.0053 16.4868 11.0053 16.1217 10.6548ZM7.79232 9.47451C8.15746 9.82497 8.15746 10.3932 7.79232 10.7436C7.42718 11.0941 6.83517 11.0941 6.47003 10.7436L5.42987 9.74529C5.06473 9.39483 5.06473 8.82662 5.42987 8.47616C5.79501 8.1257 6.38702 8.1257 6.75216 8.47616L7.79232 9.47451ZM14.3117 12.5843C14.3625 12.3739 14.4154 12.1481 14.4703 11.9057C14.5575 11.5206 14.6498 11.0936 14.747 10.6205L14.7885 10.4175C14.8378 10.1743 14.5409 10.008 14.3447 10.1691L14.1809 10.3039C13.8 10.6184 13.459 10.9063 13.1537 11.1697C12.9607 11.3363 12.782 11.493 12.6166 11.6404C10.7595 13.2948 10.5709 13.7748 10.5709 13.7748C10.1152 14.5323 10.3856 15.5008 11.1748 15.9381C11.964 16.3755 12.9731 16.1159 13.4287 15.3585C13.4287 15.3585 13.7404 14.9497 14.3117 12.5843Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SpeedIcon
