import { MutableRefObject } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { GridApiCommunity } from '@mui/x-data-grid/internals'

import Tooltip from '@admin/components/shared/Tooltip/Tooltip'

/**
 * Check if there is no value in a cell and return formatted value.
 */
const CheckIfEmptyCell = (parameters: GridRenderCellParams) => {
  return !parameters.value ? (
    <Tooltip title="Not needed for this provider" placement="top">
      <span>—</span>
    </Tooltip>
  ) : (
    parameters.colDef.valueFormatter &&
      parameters.colDef.valueFormatter(parameters.value as never, parameters.row, parameters.colDef, {} as MutableRefObject<GridApiCommunity>)
  )
}

export default CheckIfEmptyCell
