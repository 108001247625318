import { GridRenderCellParams } from '@mui/x-data-grid'
import startCase from 'lodash/startCase'

import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'

/**
 * Renders an entity cell in a data grid.
 */
export const RenderEntityCell = (props: GridRenderCellParams) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  return (
    <Stack>
      <Typography variant="body2">{startCase(row.entityType?.toLowerCase())}</Typography>
      <Typography color="text.secondary" variant="caption">
        {startCase(row.format?.toLowerCase()) || '—'}
      </Typography>
      <Typography color="text.secondary" variant="caption">
        {startCase(row.eventOnlineFormatType?.toLowerCase()) || '—'}
      </Typography>
    </Stack>
  )
}
