import { CardMedia } from '@mui/material'

import Card from '@admin/components/shared/Card/Card'
import CardActionArea from '@admin/components/shared/Card/CardActionArea'
import CardContent from '@admin/components/shared/Card/CardContent'
import { TOrderedBanner } from '@admin/store/collectionDetailsSlice'

interface IProps {
  entity: TOrderedBanner
  height: number
  width: number
}

const BannerCard = ({ width, entity, height }: IProps) => {
  return (
    <Card className="bannerCard">
      <CardActionArea>
        <CardMedia width={width} alt="Banner cover" component="img" height={height} image={entity.cover?.originalLink || ''} />
        <CardContent>
          {entity.subtitle && <span>{entity.subtitle}</span>}
          {entity.title && <span className="banner-name">{entity.title}</span>}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BannerCard
