import './index.scss'

import Alert from '@admin/components/shared/Alert/Alert'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { useAppSelector } from '@admin/store/hooks'

interface IProps {
  archive(items: IBanner | IBanner[]): void
  errorMessage?: string
  items: IBanner | IBanner[]
  onClose(): void
}

const ArchiveBannersModal = ({ archive, errorMessage, items, onClose }: IProps) => {
  let content
  let setScroll
  let title
  const archiveBannerStatus = useAppSelector((state) => state.worldManagement.archiveBannerStatus)

  if (Array.isArray(items)) {
    title = 'You are going to archive following banners:'
    setScroll = items.length > 3
    content = items.map((item) => (
      <AvatarItem primaryText={item.name} src={item.cover.originalLink} subText={`by ${item.creatorName} ${item.creatorLastName}`} />
    ))
  } else {
    title = 'You are going to archive banner:'
    content = <AvatarItem primaryText={items.name} src={items.cover.originalLink} subText={`by ${items.creatorName} ${items.creatorLastName}`} />
  }

  return (
    <div className="archiveBannerModal">
      <div className="archiveBannerModal-header">{title}</div>
      <div className={`archiveBannerModal-content ${setScroll && 'archiveBannerModal-content_scroll'}`}>{content}</div>
      <div className="archiveBannerModal-footer">
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <div className="archiveBannerModal-footer-buttons">
          <Button color="primary" onClick={onClose} variant="text">
            Cancel
          </Button>
          <LoadingButton color="error" loading={archiveBannerStatus === 'loading'} onClick={() => archive(items)} variant="contained">
            Archive banner{Array.isArray(items) ? 's' : ''}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default ArchiveBannersModal
