import { createSvgIcon } from '@mui/material'

const UserBrokenIcon = createSvgIcon(
  <svg width="25" fill="none" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
    <g id="User-broken">
      <g id="Vector">
        <path
          d="M5.58636 6.7499C5.58636 3.9057 7.9649 1.6499 10.8364 1.6499C13.7078 1.6499 16.0864 3.9057 16.0864 6.7499C16.0864 7.19535 16.028 7.62637 15.9186 8.03655C15.8649 8.23774 15.6798 8.37001 15.4721 8.38518C15.0029 8.41944 14.5444 8.4952 14.1006 8.60898C13.9837 8.63894 13.8826 8.49891 13.943 8.39444C14.2261 7.90447 14.3864 7.3426 14.3864 6.7499C14.3864 4.89968 12.825 3.3499 10.8364 3.3499C8.84772 3.3499 7.28637 4.89968 7.28637 6.7499C7.28637 8.60012 8.84772 10.1499 10.8364 10.1499C10.9134 10.1499 10.9526 10.2505 10.8947 10.3012C10.7397 10.4371 10.5902 10.5791 10.4464 10.7268C10.2275 10.9519 10.0221 11.1906 9.8315 11.4413C9.70794 11.6039 9.50162 11.687 9.30586 11.629C7.16655 10.9955 5.58636 9.06815 5.58636 6.7499Z"
          fill="#70778f"
        />
        <path
          d="M8.58092 13.8813C8.63625 13.7006 8.48747 13.5182 8.30128 13.5505C7.45786 13.6966 6.66007 13.918 5.96518 14.2143C4.65585 14.7728 3.38617 15.7563 3.38617 17.2864C3.38617 17.9469 3.73094 18.4723 4.17466 18.8504C4.60876 19.2204 5.18723 19.4982 5.82703 19.7084C6.76901 20.018 7.95928 20.2183 9.30474 20.3032C9.36026 20.3067 9.39657 20.246 9.36824 20.1982C9.10717 19.7726 8.88733 19.3198 8.71299 18.8462C8.64465 18.6606 8.48016 18.5258 8.28395 18.5007C7.53197 18.4048 6.88264 18.2659 6.35781 18.0934C5.82886 17.9196 5.4792 17.7286 5.27737 17.5566C5.08614 17.3936 5.08616 17.3035 5.08617 17.2864C5.08617 16.8413 5.46648 16.2753 6.63216 15.778C7.01932 15.6129 7.45865 15.4726 7.93356 15.3599C8.14467 15.3098 8.30422 15.1357 8.33883 14.9215C8.39616 14.5666 8.47742 14.2192 8.58092 13.8813Z"
          fill="#70778f"
        />
        <path
          d="M11.6631 20.3328C11.6394 20.3338 11.6158 20.3347 11.5922 20.3356C11.3244 20.0544 11.0853 19.7458 10.8828 19.4177L11.6631 20.3328Z"
          fill="#70778f"
        />
        <path
          clipRule="evenodd"
          d="M16.0485 10.0642C17.851 10.0642 19.4854 10.8433 20.5852 12.0958C21.5628 13.1803 22.1585 14.6162 22.1585 16.1742C22.1585 16.663 22.0974 17.1365 21.9752 17.5948C21.8378 18.2058 21.5934 18.8015 21.2726 19.3209C20.2186 21.0928 18.2787 22.2842 16.0485 22.2842C14.4752 22.2842 13.0546 21.6884 11.9854 20.7108C11.5271 20.3137 11.13 19.8402 10.8245 19.3209C10.2593 18.4044 9.93854 17.3198 9.93854 16.1742C9.93854 14.5245 10.5954 13.0123 11.6646 11.9125C12.7797 10.7669 14.3377 10.0642 16.0485 10.0642ZM20.3167 17.2216C20.3216 17.1999 20.3269 17.1783 20.3326 17.1567C20.4156 16.8457 20.4585 16.5197 20.4585 16.1742C20.4585 15.0557 20.0317 14.0208 19.3225 13.234L19.3078 13.2175C18.527 12.3283 17.3562 11.7642 16.0485 11.7642C14.8117 11.7642 13.689 12.2704 12.8835 13.0976C12.1131 13.8902 11.6385 14.9812 11.6385 16.1742C11.6385 17.0011 11.8698 17.7772 12.2715 18.4285C12.2777 18.4386 12.2838 18.4487 12.2898 18.4589C12.4992 18.8149 12.7762 19.1466 13.0988 19.4262C13.1101 19.436 13.1214 19.446 13.1325 19.4562C13.8999 20.1578 14.9151 20.5842 16.0485 20.5842C17.6573 20.5842 19.0527 19.7276 19.8115 18.4518L19.8263 18.4275C20.0479 18.0686 20.2206 17.6487 20.3167 17.2216Z"
          fill="#B42929"
          fillRule="evenodd"
        />
        <path
          d="M13.6717 13.8019C14.0036 13.47 14.5418 13.47 14.8737 13.8019L16.0464 14.9746L17.2191 13.8019C17.5511 13.47 18.0892 13.47 18.4212 13.8019C18.7531 14.1339 18.7531 14.6721 18.4212 15.004L17.2485 16.1767L18.4212 17.3494C18.7531 17.6813 18.7531 18.2195 18.4212 18.5515C18.0892 18.8834 17.551 18.8834 17.2191 18.5515L16.0464 17.3788L14.8738 18.5515C14.5418 18.8834 14.0036 18.8834 13.6717 18.5515C13.3397 18.2195 13.3397 17.6813 13.6717 17.3494L14.8443 16.1767L13.6717 15.004C13.3397 14.6721 13.3397 14.1339 13.6717 13.8019Z"
          fill="#B42929"
        />
      </g>
      <g id="Vector_2">
        <path
          d="M5.58636 6.7499C5.58636 3.9057 7.9649 1.6499 10.8364 1.6499C13.7078 1.6499 16.0864 3.9057 16.0864 6.7499C16.0864 7.19535 16.028 7.62637 15.9186 8.03655C15.8649 8.23774 15.6798 8.37001 15.4721 8.38518C15.0029 8.41944 14.5444 8.4952 14.1006 8.60898C13.9837 8.63894 13.8826 8.49891 13.943 8.39444C14.2261 7.90447 14.3864 7.3426 14.3864 6.7499C14.3864 4.89968 12.825 3.3499 10.8364 3.3499C8.84772 3.3499 7.28637 4.89968 7.28637 6.7499C7.28637 8.60012 8.84772 10.1499 10.8364 10.1499C10.9134 10.1499 10.9526 10.2505 10.8947 10.3012C10.7397 10.4371 10.5902 10.5791 10.4464 10.7268C10.2275 10.9519 10.0221 11.1906 9.8315 11.4413C9.70794 11.6039 9.50162 11.687 9.30586 11.629C7.16655 10.9955 5.58636 9.06815 5.58636 6.7499Z"
          fill="#70778f"
        />
        <path
          d="M8.58092 13.8813C8.63625 13.7006 8.48747 13.5182 8.30128 13.5505C7.45786 13.6966 6.66007 13.918 5.96518 14.2143C4.65585 14.7728 3.38617 15.7563 3.38617 17.2864C3.38617 17.9469 3.73094 18.4723 4.17466 18.8504C4.60876 19.2204 5.18723 19.4982 5.82703 19.7084C6.76901 20.018 7.95928 20.2183 9.30474 20.3032C9.36026 20.3067 9.39657 20.246 9.36824 20.1982C9.10717 19.7726 8.88733 19.3198 8.71299 18.8462C8.64465 18.6606 8.48016 18.5258 8.28395 18.5007C7.53197 18.4048 6.88264 18.2659 6.35781 18.0934C5.82886 17.9196 5.4792 17.7286 5.27737 17.5566C5.08614 17.3936 5.08616 17.3035 5.08617 17.2864C5.08617 16.8413 5.46648 16.2753 6.63216 15.778C7.01932 15.6129 7.45865 15.4726 7.93356 15.3599C8.14467 15.3098 8.30422 15.1357 8.33883 14.9215C8.39616 14.5666 8.47742 14.2192 8.58092 13.8813Z"
          fill="#70778f"
        />
        <path
          d="M11.6631 20.3328C11.6394 20.3338 11.6158 20.3347 11.5922 20.3356C11.3244 20.0544 11.0853 19.7458 10.8828 19.4177L11.6631 20.3328Z"
          fill="#70778f"
        />
        <path
          clipRule="evenodd"
          d="M10.8828 19.4177C11.0907 19.7547 11.3373 20.0711 11.6139 20.3583C11.6066 20.3508 11.5994 20.3432 11.5922 20.3356L11.6631 20.3328L10.8828 19.4177Z"
          fill="#70778f"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>,
  'UserBrokenIcon',
)

export default UserBrokenIcon
