import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'

import { LocalStorageKeys } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

const useVideoPlayer = (videoElement: MutableRefObject<HTMLVideoElement | null>) => {
  const playerSettings = useMemo(() => {
    return {
      mutedVolume: localStorage.getItem(LocalStorageKeys.MUTED_VOLUME),
      speed: localStorage.getItem(LocalStorageKeys.SPEED),
      volume: localStorage.getItem(LocalStorageKeys.VOLUME),
    }
  }, [])

  const [playerState, setPlayerState] = useState({
    currentTime: 0,
    duration: 0,
    isMuted: playerSettings.volume ? Number(playerSettings.volume) === 0 : false,
    isPlaying: false,
    mutedVolume: playerSettings.volume ? Number(playerSettings.mutedVolume) : 0,
    progress: 0,
    speed: playerSettings.speed ? Number(playerSettings.speed) : 1,
    volume: playerSettings.volume ? Number(playerSettings.volume) : 50,
  })

  const togglePlay = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.muted = playerState.isMuted

      if (playerState.isPlaying) {
        if (playerState.progress !== 100) {
          videoElement.current?.pause()
        } else {
          videoElement.current?.play()
        }
      }

      setPlayerState({
        ...playerState,
        isPlaying: playerState.progress === 100 ? true : !playerState.isPlaying,
      })
    }
  }, [videoElement.current, playerState.isPlaying, playerState])

  const toggleMute = useCallback(() => {
    if (videoElement.current) {
      const volume = !playerState.isMuted ? 0 : Number(playerState.mutedVolume)
      let newPlayerSettings: TAny = {
        isMuted: !playerState.isMuted,
        volume: volume,
      }

      if (!playerState.isMuted) {
        newPlayerSettings = {
          ...newPlayerSettings,
          mutedVolume: Number(playerState.volume),
        }
      }
      videoElement.current.muted = !playerState.isMuted
      videoElement.current.volume = volume / 100
      setPlayerState((previousState) => {
        return {
          ...previousState,
          ...newPlayerSettings,
        }
      })
    }
  }, [videoElement.current, playerState.isMuted, playerState])

  const handleOnTimeUpdate = useCallback(() => {
    if (videoElement.current) {
      const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100

      setPlayerState({
        ...playerState,
        progress,
      })
    }
  }, [videoElement.current, playerState, playerState.currentTime, playerState.duration])

  const handleVideoProgress = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const manualChange = Number(value)

        videoElement.current.currentTime = manualChange
        setPlayerState({
          ...playerState,
          isPlaying: false,
          progress: manualChange,
        })
      }
    },
    [videoElement.current, playerState],
  )

  const handleVideoSpeed = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const speed = Number(value)

        videoElement.current.playbackRate = speed
        setPlayerState({
          ...playerState,
          speed,
        })
      }
    },
    [videoElement.current, playerState],
  )

  const handleVideoVolume = useCallback(
    (event: TAny, value: TAny) => {
      if (videoElement.current) {
        const manualChange = Number(value)

        videoElement.current.volume = manualChange / 100
        if (manualChange === 0) {
          videoElement.current.muted = true
          setPlayerState({
            ...playerState,
            isMuted: true,
            volume: manualChange,
          })
        } else {
          videoElement.current.muted = false
          setPlayerState({
            ...playerState,
            isMuted: false,
            volume: manualChange,
          })
        }
      }
    },
    [videoElement.current, videoElement.current?.volume, playerState],
  )

  const handleReloadVideo = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.load()
    }
  }, [videoElement.current])

  useEffect(() => {
    if (videoElement.current) {
      setPlayerState({
        ...playerState,
        currentTime: videoElement.current?.currentTime || 0,
        duration: videoElement.current?.duration || 0,
      })
    }
  }, [playerState.duration, playerState.currentTime, videoElement.current?.duration, videoElement.current?.currentTime])

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.playbackRate = playerState.speed
    }
  }, [videoElement, playerState.speed])

  useEffect(() => {
    if (playerState.isPlaying) {
      videoElement.current?.play()
    } else {
      videoElement.current?.pause()
    }
  }, [playerState.isPlaying, videoElement])

  useEffect(() => {
    return () => {
      localStorage.setItem(LocalStorageKeys.SPEED, playerState.speed.toString())
      localStorage.setItem(LocalStorageKeys.VOLUME, playerState.volume.toString())
      localStorage.setItem(LocalStorageKeys.MUTED_VOLUME, playerState.mutedVolume.toString())
    }
  })

  return {
    handleReloadVideo,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoVolume,
    handleOnTimeUpdate,
    playerState,
    setPlayerState,
    toggleMute,
    togglePlay,
  }
}

export default useVideoPlayer
