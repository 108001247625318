import { Divider as DividerMUI, type DividerProps } from '@mui/material'

const Divider = ({ absolute, children, classes, className, flexItem, light, orientation, style, sx, textAlign, variant }: DividerProps) => {
  return (
    <DividerMUI
      absolute={absolute}
      classes={classes}
      className={className}
      flexItem={flexItem}
      light={light}
      orientation={orientation}
      style={style}
      sx={sx}
      textAlign={textAlign}
      variant={variant}
    >
      {children}
    </DividerMUI>
  )
}

export default Divider
