import './index.scss'

import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import ErrorIcon from '@admin/assets/img/ErrorIcon'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import colors from '@admin/theme/constants/colors'
import { IMediaItem } from '@admin/types/commonTypes'

interface IMediaThumbNailProps {
  currentMainEntity: IMediaItem
  isActive: boolean
  isReloadingMainMedia: boolean
  mainMediaError: boolean
  mediaItem: IMediaItem
  onThumbnailClick: (maniEntity: IMediaItem) => void
  setIsReloadingMainMedia: Dispatch<SetStateAction<boolean>>
}

const MediaThumbnail = ({
  currentMainEntity,
  isActive,
  isReloadingMainMedia,
  mainMediaError,
  mediaItem,
  onThumbnailClick,
  setIsReloadingMainMedia,
}: IMediaThumbNailProps) => {
  const { mediaId, mediaPreviewUrl } = mediaItem
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const imageSourceReference = useRef(mediaPreviewUrl)

  const handleReloadMedia = useCallback(async () => {
    const now = new Date()

    imageSourceReference.current = `${mediaPreviewUrl}?timestamp=${now.getTime()}`
  }, [mediaPreviewUrl])

  useEffect(() => {
    if (mainMediaError) {
      setIsLoading(false)
    }
  }, [mainMediaError])

  useEffect(() => {
    if (isReloadingMainMedia) {
      setIsLoading(true)
      handleReloadMedia().then(() => setIsReloadingMainMedia(false))
    }
  }, [handleReloadMedia, isReloadingMainMedia])

  const loadingClass = isLoading ? 'multimedia-preview-item__loading--show' : ''
  const errorClass = ((mainMediaError && mediaId === currentMainEntity.mediaId) || error) && !isLoading ? 'multimedia-preview-item__error--show' : ''
  const itemActiveClass = isActive ? 'multimedia-preview-item--active' : ''
  const imgActiveClass = isActive ? 'multimedia-preview-item__img--active' : ''

  return (
    <div
      onClick={() => {
        if (error) {
          setIsReloadingMainMedia(true)
        }
        onThumbnailClick(mediaItem)
      }}
      className={`multimedia-preview-item ${itemActiveClass}`}
    >
      <div>
        <div className={`multimedia-preview-item__loading ${loadingClass}`}>
          <CircularProgress />
        </div>
        <div className={`multimedia-preview-item__error ${errorClass}`}>
          <ErrorIcon sx={{ color: colors.lightError }} />
        </div>
        <img
          onLoad={() => {
            setError(false)
            setIsLoading(false)
          }}
          alt=""
          className={`multimedia-preview-item__img ${imgActiveClass}`}
          onError={() => setError(true)}
          src={imageSourceReference.current}
        />
      </div>
    </div>
  )
}

export default MediaThumbnail
