const PlayIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M15.8639 2.1499C15.0746 2.1499 14.3019 2.29256 13.7411 2.81877C13.1667 3.35768 13.0039 4.11731 13.0039 4.8899V19.1099C13.0039 19.8789 13.1625 20.6397 13.7361 21.1799C14.2967 21.7079 15.0713 21.8499 15.8639 21.8499H18.99C19.7826 21.8499 20.5572 21.7079 21.1178 21.1799C21.6914 20.6397 21.85 19.8789 21.85 19.1099V4.8899C21.85 4.12087 21.6914 3.36012 21.1178 2.81989C20.5572 2.29189 19.7826 2.1499 18.99 2.1499H15.8639Z"
        fillRule="evenodd"
      />
      <path d="M2.88212 2.81989C3.44271 2.29189 4.21725 2.1499 5.0099 2.1499H8.14997C8.94262 2.1499 9.71716 2.29189 10.2778 2.81989C10.8513 3.36012 11.01 4.12087 11.01 4.8899V19.1099C11.01 19.8789 10.8513 20.6397 10.2778 21.1799C9.71716 21.7079 8.94262 21.8499 8.14997 21.8499H5.0099C4.21725 21.8499 3.44271 21.7079 2.88212 21.1799C2.30854 20.6397 2.1499 19.8789 2.1499 19.1099V4.8899C2.1499 4.12087 2.30854 3.36012 2.88212 2.81989Z" />
    </svg>
  )
}

export default PlayIcon
