import { GridRowSelectionModel } from '@mui/x-data-grid'

import Alert from '@admin/components/shared/Alert/Alert'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import List from '@admin/components/shared/List/List'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

interface IChangeMemberStatusModalProps {
  changeStatusStatus: TStatus
  closeModal: () => void
  currentSelectedStatus: TAny
  currentStatusChangeUser: TAny
  isMultipleStatusChanging: boolean
  onConfirm: () => void
  open: boolean
  selectedRowsData: TAny[]
  selectedStatus: TAny
  selectionModel: GridRowSelectionModel
}

export const ChangeMemberStatusModal = ({
  changeStatusStatus,
  closeModal,
  currentSelectedStatus,
  currentStatusChangeUser,
  isMultipleStatusChanging,
  onConfirm,
  open,
  selectedRowsData,
  selectedStatus,
  selectionModel,
}: IChangeMemberStatusModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">
        You are going to change {isMultipleStatusChanging && selectionModel.length > 1 ? `${selectionModel.length} users’ statuses` : 'user’s status'}{' '}
        to {selectedStatus(currentSelectedStatus?.status)}:
      </DialogTitle>
      <DialogContent sx={isMultipleStatusChanging && selectedRowsData.length > 3 ? { border: '1px solid #DADCE3' } : {}}>
        {!isMultipleStatusChanging ? (
          <AvatarItem
            primaryText={currentStatusChangeUser?.firstName + ' ' + currentStatusChangeUser?.lastName}
            src={currentStatusChangeUser.avatarPreview ? currentStatusChangeUser.avatarPreview.originalLink : null}
            subText={currentStatusChangeUser.role ? currentStatusChangeUser.role.name : ''}
          />
        ) : (
          <List sx={{ maxHeight: '158px' }}>
            {selectedRowsData.map((item: TAny) => (
              <AvatarItem
                key={item.id}
                primaryText={item.firstName + ' ' + item.lastName}
                src={item.avatarPreview ? item.avatarPreview.originalLink : null}
                subText={item.role.name}
              />
            ))}
          </List>
        )}

        {changeStatusStatus === 'failed' && (
          <Alert severity="error">
            Changing {isMultipleStatusChanging && selectionModel.length > 1 ? 'statuses' : 'status'} error, try again later
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <LoadingButton loading={changeStatusStatus === 'loading'} onClick={() => onConfirm()} variant="contained">
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
