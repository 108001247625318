import { createSvgIcon } from '@mui/material'

const EditIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M15.505 1.33824C16.7113 1.18399 17.9201 1.68005 19.1328 2.82107L19.1343 2.82255C20.3509 3.97305 20.9195 5.15481 20.8345 6.37065C20.7526 7.54156 20.0748 8.52449 19.3182 9.32386L18.0529 10.6631C17.9967 10.7494 17.9255 10.8245 17.8433 10.885L11.1127 18.0091C10.8712 18.2718 10.5496 18.4913 10.2486 18.6522C9.94327 18.8154 9.58718 18.9569 9.24908 19.017L9.24342 19.018L6.02472 19.5678L6.02347 19.568C5.21663 19.7067 4.43548 19.5056 3.87665 18.9756C3.31837 18.4462 3.07538 17.6773 3.1655 16.8663L3.16579 16.8637L3.53579 13.6237L3.53776 13.6078C3.58267 13.2709 3.70677 12.9106 3.8512 12.6009C3.99506 12.2924 4.19307 11.9612 4.43078 11.7082L4.43244 11.7064L11.2535 4.48651C11.2659 4.47256 11.2787 4.45901 11.2919 4.44588L12.6424 3.01639C13.3995 2.21667 14.3432 1.4868 15.505 1.33824ZM18.0824 8.15639L17.0063 9.29544C14.9382 8.92063 13.2877 7.3594 12.8074 5.31723L13.8776 4.18448C14.5404 3.48441 15.1425 3.09844 15.7206 3.02451C16.2542 2.95628 16.9801 3.13011 17.9671 4.05846C18.9598 4.99759 19.1761 5.71558 19.1386 6.25211C19.098 6.83339 18.7456 7.45587 18.0824 8.15639ZM5.66982 12.8721L11.4765 6.72595C12.2336 8.59548 13.7707 10.0466 15.6782 10.7012L9.87244 16.8464L9.86219 16.8574C9.79427 16.9319 9.64863 17.0453 9.44703 17.153C9.2487 17.2591 9.06594 17.3225 8.95383 17.3428L5.73719 17.8923L5.73588 17.8925C5.38319 17.9533 5.16482 17.8544 5.04645 17.7421C4.92736 17.6292 4.81547 17.4134 4.85497 17.0552L4.8551 17.054L5.22372 13.8261C5.24 13.7123 5.29564 13.5258 5.3919 13.3194C5.49025 13.1085 5.59755 12.9493 5.66982 12.8721Z"
      fillRule="evenodd"
    />
    <path d="M3.0001 21.1501C2.53066 21.1501 2.1501 21.5307 2.1501 22.0001C2.1501 22.4696 2.53066 22.8501 3.0001 22.8501H21.0001C21.4695 22.8501 21.8501 22.4696 21.8501 22.0001C21.8501 21.5307 21.4695 21.1501 21.0001 21.1501H3.0001Z" />
  </svg>,
  'Edit',
)

export default EditIcon
