import { createSvgIcon } from '@mui/material'

const LockIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M8.64999 16C8.64999 14.1499 10.1498 12.65 12 12.65C13.8501 12.65 15.35 14.1499 15.35 16C15.35 17.8502 13.8501 19.35 12 19.35C10.1498 19.35 8.64999 17.8502 8.64999 16ZM12 14.35C11.0887 14.35 10.35 15.0888 10.35 16C10.35 16.9113 11.0887 17.65 12 17.65C12.9113 17.65 13.65 16.9113 13.65 16C13.65 15.0888 12.9113 14.35 12 14.35Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.85 9.2468V8.00002C18.85 6.29418 18.6003 4.54569 17.5369 3.22556C16.4416 1.86572 14.6455 1.15002 12 1.15002C9.35452 1.15002 7.55842 1.86572 6.46304 3.22556C5.39965 4.54569 5.14999 6.29418 5.14999 8.00002V9.2468C4.01241 9.38998 3.07962 9.71832 2.39895 10.399C1.37865 11.4193 1.14999 13.0061 1.14999 15V17C1.14999 18.9939 1.37865 20.5808 2.39895 21.6011C3.41926 22.6214 5.00607 22.85 6.99999 22.85H17C18.9939 22.85 20.5807 22.6214 21.601 21.6011C22.6213 20.5808 22.85 18.9939 22.85 17V15C22.85 13.0061 22.6213 11.4193 21.601 10.399C20.9204 9.71832 19.9876 9.38998 18.85 9.2468ZM7.78695 4.29199C7.10034 5.14436 6.84999 6.39587 6.84999 8.00002V9.15047C6.89973 9.15017 6.94973 9.15002 6.99999 9.15002H17C17.0503 9.15002 17.1003 9.15017 17.15 9.15047V8.00002C17.15 6.39587 16.8996 5.14436 16.213 4.29199C15.5584 3.47933 14.3545 2.85002 12 2.85002C9.64547 2.85002 8.44157 3.47933 7.78695 4.29199ZM2.84999 15C2.84999 12.9939 3.12134 12.0808 3.60103 11.6011C4.08073 11.1214 4.99392 10.85 6.99999 10.85H17C19.0061 10.85 19.9193 11.1214 20.399 11.6011C20.8786 12.0808 21.15 12.9939 21.15 15V17C21.15 19.0061 20.8786 19.9193 20.399 20.399C19.9193 20.8787 19.0061 21.15 17 21.15H6.99999C4.99392 21.15 4.08073 20.8787 3.60103 20.399C3.12134 19.9193 2.84999 19.0061 2.84999 17V15Z"
      fillRule="evenodd"
    />
  </svg>,
  'Lock',
)

export default LockIcon
