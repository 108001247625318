import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { TAXES_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import { ITaxCode, ITaxesState, ITaxRule, ITaxStatus } from './interface'

const initialState: ITaxesState = {
  status: 'idle',
  taxCodes: [],
  taxRules: [],
  totalCount: {
    taxCodesCount: 0,
    taxRulesCount: 0,
  },
}

export const fetchTaxCodes = createAsyncThunk(
  'payments/taxCodes',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post(TAXES_API + 'sales/tax/code/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchTaxRules = createAsyncThunk(
  'payments/rulesForSalesTax',
  async (
    {
      page,
      pageSize,
      salesTaxRuleId,
    }: {
      page: number
      pageSize: number
      salesTaxRuleId?: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(TAXES_API + 'sales/tax/rule/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        salesTaxRuleId: salesTaxRuleId,
        sortingList: [{ columnId: 'created_at', sortingType: 'DESC' }],
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchAddTaxCode = createAsyncThunk(
  'payments/addTaxCode',
  async ({ category, code, description }: Pick<ITaxCode, 'category' | 'code' | 'description'>, { rejectWithValue }) => {
    try {
      const response = await axios.post(TAXES_API + 'sales/tax/code', {
        category,
        code,
        description,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchAddTaxRule = createAsyncThunk(
  'payments/addTaxRule',
  async (
    {
      certainty,
      entityType,
      eventCategories,
      eventOnlineFormatType,
      format,
      isDefaultSalesTaxRule,
      passions,
      salesTaxCode,
    }: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(TAXES_API + 'sales/tax/rule', {
        certainty,
        entityType,
        eventCategories,
        eventOnlineFormatType,
        format,
        isDefaultSalesTaxRule,
        passions,
        salesTaxCodeId: salesTaxCode.salesTaxCodeId,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchEditTaxRule = createAsyncThunk(
  'payments/editTaxRule',
  async (
    {
      certainty,
      entityType,
      eventCategories,
      eventOnlineFormatType,
      format,
      isDefaultSalesTaxRule,
      passions,
      salesTaxCode,
      salesTaxRuleId,
    }: Omit<ITaxRule, 'status'>,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.put(TAXES_API + `sales/tax/rule/${salesTaxRuleId}`, {
        certainty,
        entityType,
        eventCategories,
        eventOnlineFormatType,
        format,
        isDefaultSalesTaxRule,
        passions,
        salesTaxCodeId: salesTaxCode.salesTaxCodeId,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchChangeTaxStatus = createAsyncThunk(
  'payments/changeStatus',
  async (
    {
      currentSelectedRow,
      currentSelectedStatus,
    }: {
      currentSelectedRow: (ITaxCode & ITaxRule) | null
      currentSelectedStatus: ITaxStatus | null
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(TAXES_API + `sales/tax/${currentSelectedRow?.salesTaxRuleId ? 'rule' : 'code'}/status`, {
        salesTaxCodeId: currentSelectedRow?.id,
        salesTaxRuleId: currentSelectedRow?.salesTaxRuleId,
        status: currentSelectedStatus?.status,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchChangeTaxCodeStatus = createAsyncThunk(
  'payments/taxCodes/changeStatus',
  async (
    {
      currentSelectedStatus,
      salesTaxCode,
    }: {
      currentSelectedStatus: ITaxStatus | null
      salesTaxCode: null | Pick<ITaxCode, 'category' | 'code' | 'id' | 'salesTaxCodeId' | 'status'>
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(TAXES_API + `sales/tax/code/status`, {
        salesTaxCodeId: salesTaxCode?.id,
        status: currentSelectedStatus?.status,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const taxesSlice = createSlice({
  name: 'taxes',
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxCodes.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTaxCodes.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.taxCodes = payload.content
        state.totalCount.taxCodesCount = payload.totalElements
      })
      .addCase(fetchTaxCodes.rejected, (state) => {
        state.status = 'failed'
        state.taxCodes = []
        state.totalCount.taxCodesCount = 0
      })
      .addCase(fetchTaxRules.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchTaxRules.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.taxRules = payload.content
        state.totalCount.taxRulesCount = payload.totalElements
      })
      .addCase(fetchTaxRules.rejected, (state) => {
        state.status = 'failed'
        state.taxRules = []
        state.totalCount.taxRulesCount = 0
      })
      .addCase(fetchAddTaxRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAddTaxRule.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(fetchAddTaxRule.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchEditTaxRule.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchEditTaxRule.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(fetchEditTaxRule.rejected, (state) => {
        state.status = 'failed'
      })
  },
  initialState,
  reducers: {},
})

export const selectTaxCodes = (state: TRootState): ITaxCode[] => state.taxes.taxCodes
export const selectTaxRules = (state: TRootState): ITaxRule[] => state.taxes.taxRules
export const selectTotalCount = (
  state: TRootState,
): {
  taxCodesCount: number
  taxRulesCount: number
} => state.taxes.totalCount
export const selectTaxesStatus = (state: TRootState): TStatus => state.taxes.status

export default taxesSlice.reducer
