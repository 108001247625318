import { GridRowSelectionModel } from '@mui/x-data-grid'

import Alert from '@admin/components/shared/Alert/Alert'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import List from '@admin/components/shared/List/List'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

interface IChangeMemberRoleModalProps {
  changeRoleStatus: TStatus
  closeModal: () => void
  currentSelectedRole: TAny
  onConfirm: () => void
  open: boolean
  selectedRowsData: TAny[]
  selectionModel: GridRowSelectionModel
}

export const ChangeMemberRoleModal = ({
  changeRoleStatus,
  closeModal,
  currentSelectedRole,
  onConfirm,
  open,
  selectedRowsData,
  selectionModel,
}: IChangeMemberRoleModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">
        You are going to change {selectionModel.length > 1 ? `${selectionModel.length} users’ roles` : 'user’s role'} to{' '}
        {currentSelectedRole && currentSelectedRole.name}:
      </DialogTitle>
      <DialogContent sx={selectedRowsData.length > 3 ? { border: '1px solid #DADCE3' } : {}}>
        <List sx={{ maxHeight: '158px' }}>
          {selectedRowsData.map((item: TAny) => (
            <AvatarItem
              key={item.id}
              primaryText={item.firstName + ' ' + item.lastName}
              src={item?.avatarPreview ? item.avatarPreview.originalLink : null}
              subText={item.role.name}
            />
          ))}
        </List>
        {changeRoleStatus === 'failed' && (
          <Alert severity="error">Changing {selectionModel.length > 1 ? 'roles' : 'role'} error, try again later</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <LoadingButton loading={changeRoleStatus === 'loading'} onClick={() => onConfirm()} variant="contained">
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
