import { SVGProps } from 'react'
export const AttentionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M6 6.428V3.857M8.662 8.787c-2.366 2.366-2.87 2.455-5.49-.166-2.622-2.622-2.536-3.122 0-5.657s2.942-2.715 5.656 0c2.715 2.714 2.622 3.035-.166 5.823Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M6 8.429a.25.25 0 1 0 0-.5.25.25 0 0 0 0 .5"
    />
  </svg>
)
