import { useState } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import EditIcon from '@admin/assets/img/EditIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'

import type { TAny } from '@yzzy/types'

/**
 * Props for the RenderCommentDeletedCell component.
 *
 * @interface
 * @property {(data: TAny) => void} openCommentModal - Event handler to open comment modal.
 * @property {GridRenderCellParams} props - The properties of the RenderCommentDeletedCell component.
 */
interface IRenderCommentDeletedCellProps {
  openCommentModal: (data: TAny) => void
  props: GridRenderCellParams
}

/**
 * Renders a comment cell for deleted user in a data grid.
 */
export const RenderCommentDeletedCell = ({ openCommentModal, props }: IRenderCommentDeletedCellProps) => {
  const { row, value } = props

  const [showOuterTooltip, setShowOuterTooltip] = useState(true)
  const [showInnerTooltip, setShowInnerTooltip] = useState(false)
  const handleMouseEnterIcon = () => {
    setShowInnerTooltip(true)
    setShowOuterTooltip(false)
  }
  const handleMouseLeaveIcon = () => {
    setShowInnerTooltip(false)
    setShowOuterTooltip(true)
  }
  const isEmptyComment = !value

  if (isEmptyComment) {
    return (
      <div className="deletedUsers--commentContainer" onMouseEnter={handleMouseEnterIcon} onMouseLeave={handleMouseLeaveIcon}>
        <Typography color={colors.outlinedBorder} variant="caption">
          No comment
        </Typography>
        <Tooltip title="Add comment" className="deletedUsers--commentIcon" open={showInnerTooltip} placement="top-end">
          <IconButton onClick={() => openCommentModal(row)} size="small" sx={{ p: '2px' }}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <Tooltip title={showOuterTooltip ? value : ''} placement="top-end" followCursor>
      <div className="deletedUsers--commentContainer">
        <Typography className="deletedUsers--comment" color="text.secondary" variant="caption">
          {props.value}
        </Typography>
        <Tooltip title="Edit comment" className="deletedUsers--commentIcon" placement="top-end" followCursor>
          <IconButton
            onClick={() => openCommentModal(row)}
            onMouseEnter={handleMouseEnterIcon}
            onMouseLeave={handleMouseLeaveIcon}
            size="small"
            sx={{ p: '2px' }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    </Tooltip>
  )
}
