import { SVGProps } from 'react'
export const OnlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M2 4.25S3.538 3 6 3s4 1.25 4 1.25M3 6s1.154-1 3-1 3 1 3 1M4.5 7.5S5.077 7 6 7s1.5.5 1.5.5"
    />
    <path fill="currentColor" d="M6 9.25a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
  </svg>
)
