import './index.scss'

import { useEffect, useMemo, useState } from 'react'

import { GridColDef, GridOverlay, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import filter from 'lodash/filter'
import { enqueueSnackbar, VariantType } from 'notistack'

import Alert from '@admin/components/shared/Alert/Alert'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Checkbox from '@admin/components/shared/Checkbox/Checkbox'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import FormControl from '@admin/components/shared/FormControl/FormControl'
import FormControlLabel from '@admin/components/shared/FormControlLabel/FormControlLabel'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { allPossibleStatuses } from '@admin/containers/TimMembers/constants'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchModeratorWithTickets,
  fetchUnassignTickets,
  selectModerators,
  selectModeratorsStatus,
  selectUnassignStatus,
} from '@admin/store/moderationSlice'

import { UnassignTicketsConfirmModal } from './UnassignTicketsConfirmModal'

interface IUnassignTicketsModalProps {
  currentTab: string
  onClose: (unassigned?: boolean) => void
  open: boolean
}

const UnassignTicketsModal = ({ currentTab, onClose, open }: IUnassignTicketsModalProps) => {
  const apiReference = useGridApiRef()
  const dispatch = useAppDispatch()

  const ticketTypes = ['hive', 'content', 'profile']

  const ticketTypesMap: Record<string, string> = {
    content: 'Content',
    hive: 'Premoderation',
    profile: 'Profile',
  }

  const moderators = useAppSelector(selectModerators)
  const moderatorsStatus = useAppSelector(selectModeratorsStatus)
  const unassignStatus = useAppSelector(selectUnassignStatus)

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false)
  const [isAllTypesSelected, setIsAllTypesSelected] = useState(false)
  const [selectedModeratorIds, setSelectedModeratorIds] = useState<GridRowSelectionModel>([])
  const [selectedTicketTypes, setSelectedTicketTypes] = useState<string[]>([])
  const [isUnassignError, setIsUnassignError] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const handleTicketTypeSelection = (ticketType: string, isChecked: boolean) => {
    setSelectedTicketTypes((previous) => (isChecked ? [...previous, ticketType] : previous.filter((type) => type !== ticketType)))
    setIsDirty(true)
  }

  const handleSelectAll = () => {
    if (selectedTicketTypes.length === ticketTypes.length) {
      setSelectedTicketTypes([])
      setIsAllTypesSelected(false)
    } else {
      setSelectedTicketTypes(ticketTypes)
      setIsAllTypesSelected(true)
    }
    setIsDirty(true)
  }

  const handleCloseModal = (unassigned: boolean) => {
    onClose(unassigned)
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)

    return format(date, 'MM/dd/yyyy')
  }

  const handleUnassign = async () => {
    setIsUnassignError(false)

    const result = await dispatch(
      fetchUnassignTickets({
        moderatorIds: selectedModeratorIds,
        ticketTypes: selectedTicketTypes,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      handleCloseModal(true)
      setIsDirty(false)
      setSelectedModeratorIds([])
      setSelectedTicketTypes([])
      dispatch(fetchModeratorWithTickets())
      enqueueSnackbar('The tickets have been unassigned', {
        variant: 'success' as VariantType,
      })
    } else if (result.meta.requestStatus === 'rejected') {
      setIsUnassignError(true)
    }
  }

  const isUnassignDisabled = selectedModeratorIds.length === 0 || selectedTicketTypes.length === 0

  useEffect(() => {
    dispatch(fetchModeratorWithTickets())
  }, [dispatch])

  useEffect(() => {
    setSelectedModeratorIds([])
    setSelectedTicketTypes(currentTab ? [currentTab] : [])
  }, [open, currentTab])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        minWidth: 250,
        field: 'id',
        flex: 0.8,
        headerClassName: 'moderators-table__name-header',
        headerName: 'Name',
        renderCell: (parameters) => (
          <AvatarItem
            key={parameters.row.id}
            primaryText={parameters.row.firstName + ' ' + parameters.row.lastName}
            src={parameters?.row.avatarUrl ? parameters.row.avatarUrl : null}
            // subText={parameters.row.role}
          />
        ),
        renderHeader: (parameters) => {
          return (
            <strong
              style={{
                marginLeft: '54px',
              }}
            >
              {parameters.colDef.headerName}
            </strong>
          )
        },
        sortable: false,
      },
      {
        minWidth: 136,
        field: 'status',
        flex: 0.6,
        headerName: 'Status',
        renderCell: (parameters) => {
          const currentStatus = allPossibleStatuses.find((element) => parameters.row.status?.includes(element.status))

          if (parameters.row.id) {
            return <div style={{ color: currentStatus?.color }}>{parameters.row.status.replace(currentStatus?.status, currentStatus?.title)}</div>
          } else {
            return '—'
          }
        },
        sortable: false,
      },
      {
        minWidth: 123,
        field: 'latestActivity',
        flex: 0.3,
        headerName: 'Latest activity',
        renderCell: (parameters) => {
          if (parameters.row.latestActivity) {
            return (
              <Tooltip title={formatDate(parameters.row.latestActivity)} placement="top-end" followCursor>
                <div>{formatDate(parameters.row.latestActivity)}</div>
              </Tooltip>
            )
          }

          return '—'
        },
        sortable: false,
        type: 'date',
        valueGetter: (_, row) => {
          if (row.latestActivity) {
            return new Date(formatDate(row.latestActivity))
          }
        },
      },
      {
        minWidth: 128,
        field: 'premoderationTicketsCount',
        flex: 0.3,
        headerName: 'Premoderation',
        sortable: false,
        type: 'number',
      },
      {
        minWidth: 83,
        field: 'contentTicketsCount',
        flex: 0.3,
        headerName: 'Content',
        sortable: false,
        type: 'number',
      },
      {
        minWidth: 75,
        field: 'profileTicketsCount',
        flex: 0.3,
        headerName: 'Profile',
        sortable: false,
        type: 'number',
      },
      {
        minWidth: 64,
        field: 'totalTicketsCount',
        flex: 0.3,
        headerName: 'Total',
        renderCell: (parameters) => <Typography variant="body2Bold">{parameters.value}</Typography>,
        sortable: false,
        type: 'number',
      },
    ],
    [],
  )

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle variant="h6">Choose moderator(s) to unassign tickets</DialogTitle>
        <DialogContent className="no-padding">
          <div className="moderators-table-container">
            <DataGrid
              hideFooter
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedModeratorIds(newRowSelectionModel)
                setIsDirty(true)
              }}
              slots={{
                noRowsOverlay: () => (
                  <GridOverlay className="moderators-table__overlay">
                    <Typography color="text.secondary" variant="body2">
                      There are no tickets to unassign
                    </Typography>
                  </GridOverlay>
                ),
              }}
              apiRef={apiReference}
              className="moderators-table"
              columnHeaderHeight={64}
              columns={columns}
              getRowHeight={() => 'auto'}
              loading={moderatorsStatus === 'loading'}
              rows={moderators}
              rowSelectionModel={selectedModeratorIds}
              checkboxSelection
              disableColumnFilter
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableColumnSelector
              disableRowSelectionOnClick
            />
          </div>
        </DialogContent>
        {isUnassignError && (
          <Alert severity="error" sx={{ m: '16px 24px 24px' }}>
            Unassignment error, try again later
          </Alert>
        )}
        <DialogActions sx={{ alignItems: 'flex-end' }}>
          <Stack flex="auto">
            <Stack direction="row" ml={1.5} spacing={3}>
              <Typography color="text.secondary" variant="body1">
                Tickets to unassign
              </Typography>
              <Typography className="select-button" color="primary" onClick={handleSelectAll} variant="body1">
                {isAllTypesSelected ? 'Deselect all' : 'Select all'}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              {ticketTypes.map((type) => (
                <FormControl key={type}>
                  <FormControlLabel
                    label={ticketTypesMap[type]}
                    control={
                      <Checkbox
                        checked={selectedTicketTypes.includes(type)}
                        onChange={(event) => handleTicketTypeSelection(type, event.target.checked)}
                        size="small"
                      />
                    }
                    sx={{ m: 'unset' }}
                    value={type}
                  />
                </FormControl>
              ))}
            </Stack>
          </Stack>
          <Button color="primary" onClick={() => handleCloseModal(!isDirty)} variant="text">
            Cancel
          </Button>
          <Tooltip title={isUnassignDisabled ? 'Choose at least one moderator' : ''} placement="top-end" followCursor>
            <LoadingButton
              color="primary"
              disabled={isUnassignDisabled}
              loading={unassignStatus === 'loading'}
              onClick={() => setIsOpenConfirmModal(true)}
              variant="contained"
            >
              Unassign
            </LoadingButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <UnassignTicketsConfirmModal
        closeModal={() => setIsOpenConfirmModal(false)}
        moderators={filter(moderators, (moderator) => selectedModeratorIds.includes(moderator.id))}
        onConfirm={handleUnassign}
        open={isOpenConfirmModal}
      />
    </>
  )
}

export default UnassignTicketsModal
