import { GridRenderCellParams } from '@mui/x-data-grid'

import Typography from '@admin/components/shared/Typography/Typography'
import { IOperation, TransactionTypes } from '@admin/store/slices/Payments/interface'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Props for the RenderAmountCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderAmountCellProps {
  props: GridRenderCellParams
  rowData: IOperation
}

/**
 * Renders an "Amount" cell in a data grid (Transactions table).
 */
export const RenderAmountCell = ({ props, rowData }: IRenderAmountCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const isRefundOrTransfer = row.type === TransactionTypes.REFUND || row.type === TransactionTypes.REVERSAL_TRANSFER

  return <Typography variant="body2">{isRefundOrTransfer ? '—' : currencyFormatter(Number(rowData.price), 'en-US', 'USD', true)}</Typography>
}
