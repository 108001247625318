import { Switch as SwitchBase } from '@mui/material'
import { SwitchProps } from '@mui/material/Switch/Switch'

import colors from '@admin/theme/constants/colors'

interface ISwitchProps extends SwitchProps {
  label?: string
  primary?: boolean
}

const Switch = (props: ISwitchProps) => {
  return (
    <SwitchBase
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: colors.textSecondary,
        },
      }}
      {...props}
    />
  )
}

export default Switch
