export const PALETTE = {
  common: {
    actionSelected: '#BFC6E23D',
    black: '#23252F',
    buttonDisable: '#BFC6E2',
    inputBg: '#F5F6FA',
    lightGray: '#FBFBFD',
    white: '#FFF',
  },
  divider: '#DADCE3',
  action: {
    disabled: '#BFC6E2',
    disabledBackground: '#EEF0F6',
  },
  error: {
    50: '#F3CBCB',
    200: '#EAA2A2',
    400: '#DA5A5A',
    500: '#B42929',
    800: '#7F2F2F',
    main: '#B42929',
  },
  gray: {
    50: '#DADCE3',
    200: '#BCC0CD',
    300: '#8990A7',
    400: '#70778F',
    500: '#646C86',
    600: '#4F5775',
    900: '#101828',
  },
  info: {
    main: '#0288D1',
  },
  orange: {
    50: '#F6DACD',
    100: '#F3CDBC',
    200: '#EFBDA6',
    300: '#EAA788',
    400: '#E38A60',
    500: '#DA612B',
    600: '#AD5731',
    700: '#8E4C2F',
    800: '#75412C',
    900: '#603928',
  },
  primary: {
    50: '#D9E3FD',
    100: '#CDDAFC',
    200: '#BCCEFC',
    300: '#A6BEFA',
    400: '#88A8F9',
    500: '#608AF7',
    600: '#437BE1',
    700: '#4E79E7',
    800: '#5074CF',
    900: '#3457B4',
    main: '#4E79E7',
  },
  secondary: {
    main: '#7360CD',
  },
  success: {
    100: '#CAEBDD',
    200: '#B9E5D1',
    300: '#A2DCC2',
    400: '#83D0AD',
    500: '#59C193',
    600: '#4CA478',
    700: '#448762',
    800: '#3D7051',
    900: '#365D44',
    main: '#4CA478',
  },
  text: {
    primary: '#23252F',
    secondary: '#70778F',
  },
  violet: {
    50: '#DED9F3',
    100: '#D2CDEF',
    200: '#C4BCEA',
    300: '#B0A5E3',
    400: '#9588DA',
    500: '#7360CD',
    600: '#544BB5',
    700: '#444496',
    800: '#3D427C',
    900: '#373E66',
  },
  warning: {
    main: '#DA612B',
  },
  yellow: {
    50: '#FFF2C3',
    100: '#FFEEAF',
    200: '#FFE894',
    300: '#FFE070',
    400: '#FFD641',
    500: '#FFC801',
    600: '#C49B16',
    700: '#9F7E1A',
    800: '#82671C',
    900: '#6A541C',
  },
} as const
