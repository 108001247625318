import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import { TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

interface IResetPasswordModalProps {
  closeModal: () => void
  onReset: () => void
  open: boolean
  passwordResetStatus: TStatus
  tableMenuActiveElement: IMemberInfo | null
}

export const ResetPasswordModal = ({ closeModal, onReset, open, passwordResetStatus, tableMenuActiveElement }: IResetPasswordModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">
        Reset password for {tableMenuActiveElement?.firstName} {tableMenuActiveElement?.lastName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          Link for setting up new password will be sent to {tableMenuActiveElement?.firstName} {tableMenuActiveElement?.lastName}’s email
        </DialogContentText>
        {passwordResetStatus === 'failed' && <Alert severity="error">Password resetting error, try again later</Alert>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Cancel
        </Button>
        <LoadingButton color="primary" loading={passwordResetStatus === 'loading'} onClick={() => onReset()} variant="contained">
          Reset password
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
