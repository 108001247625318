import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { isAxiosError } from 'axios'

import { OPERATIONS_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { IOperation, IOperationDetails, IOperationSearchItem, IOperationsState, OperationActions } from './interface'

const initialState: IOperationsState = {
  actionStatus: 'idle',
  currentAction: null,
  operationDetails: null,
  operationDetailsStatus: 'idle',
  operations: [],
  operationsCount: 0,
  operationsSearch: [],
  operationsSearchAll: [],
  operationsSearchStatus: 'idle',
  status: 'idle',
}

export const fetchOperations = createAsyncThunk(
  'payments/operations',
  async ({ page, pageSize }: { page: number; pageSize: number }, { getState, rejectWithValue }) => {
    try {
      const rootState: TRootState = getState() as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
        : []
      const response = await axios.post(OPERATIONS_API + 'operations/find', {
        filters: filters,
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: sortingList,
      })

      return response.data
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchOperationDetails = createAsyncThunk('payments/operationDetails', async (operationId: string, { rejectWithValue }) => {
  try {
    const response = await axios.get(OPERATIONS_API + `operations/${operationId}/details`)

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchOperationAction = createAsyncThunk(
  'payments/operationAction',
  async (
    {
      action,
      comment,
      inApp,
      operationId,
      reason,
    }: {
      action: OperationActions
      comment?: string
      inApp?: boolean | null
      operationId: string
      reason?: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(`${OPERATIONS_API}operations/${operationId}/status`, {
        action: action,
        comment: comment,
        inApp: inApp,
        reason: reason,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const fetchOperationsSearch = createAsyncThunk(
  'payments/operationsSearch',
  async ({ listSize, searchValue }: { listSize?: number; searchValue: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(OPERATIONS_API + `operations/search`, {
        size: listSize,
        value: searchValue,
      })

      return response.data
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data)
    }
  },
)

export const OperationsSlice = createSlice({
  name: 'operations',
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperations.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchOperations.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.operations = payload.content
        state.operationsCount = payload.totalElements
      })
      .addCase(fetchOperations.rejected, (state) => {
        state.status = 'failed'
        state.operations = []
        state.operationsCount = 0
      })
      .addCase(fetchOperationDetails.pending, (state) => {
        state.operationDetailsStatus = 'loading'
      })
      .addCase(fetchOperationDetails.fulfilled, (state, { payload }) => {
        state.operationDetailsStatus = 'idle'
        state.operationDetails = payload
      })
      .addCase(fetchOperationDetails.rejected, (state) => {
        state.operationDetailsStatus = 'failed'
      })
      .addCase(fetchOperationAction.pending, (state, action) => {
        state.currentAction = action.meta.arg.action
        state.actionStatus = 'loading'
      })
      .addCase(fetchOperationAction.fulfilled, (state) => {
        state.currentAction = null
        state.actionStatus = 'idle'
      })
      .addCase(fetchOperationAction.rejected, (state) => {
        state.currentAction = null
        state.actionStatus = 'failed'
      })
      .addCase(fetchOperationsSearch.pending, (state) => {
        state.operationsSearchStatus = 'loading'
      })
      .addCase(fetchOperationsSearch.fulfilled, (state, { payload }) => {
        state.operationsSearch = payload.operations
        state.operationsSearchAll = payload.allIds
        state.operationsSearchStatus = 'idle'
      })
      .addCase(fetchOperationsSearch.rejected, (state) => {
        state.operationsSearchStatus = 'failed'
      })
  },
  initialState,
  reducers: {
    clearOperationsSearch: (state) => {
      state.operationsSearch = []
      state.operationsSearchAll = []
    },
    setOperationsStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const selectOperations = (state: TRootState): IOperation[] => state.operations.operations
export const selectOperationsSearchResults = (state: TRootState): IOperationSearchItem[] => state.operations.operationsSearch
export const selectOperationsSearchAllResults = (state: TRootState): string[] => state.operations.operationsSearchAll
export const selectOperationsSearchStatus = (state: TRootState): TStatus => state.operations.operationsSearchStatus
export const selectOperationDetails = (state: TRootState): IOperationDetails | null => state.operations.operationDetails
export const selectTotalCount = (state: TRootState): number => state.operations.operationsCount
export const selectOperationsStatus = (state: TRootState): TStatus => state.operations.status
export const selectOperationDetailsStatus = (state: TRootState): TStatus => state.operations.operationDetailsStatus
export const selectOperationActionStatus = (state: TRootState): TStatus => state.operations.actionStatus
export const selectCurrentOperationAction = (state: TRootState): null | OperationActions => state.operations.currentAction

export const { clearOperationsSearch, setOperationsStatus } = OperationsSlice.actions

export default OperationsSlice.reducer
