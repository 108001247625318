import { TDecision, Term, TProfileImplications } from '@admin/store/rulesSlice'

export const implications: Record<TProfileImplications, string> = {
  BAN_PROFILE: 'Ban profile',
  BLOCK_PUBLICATION: 'Prohibit content creation',
  NO_IMPLICATIONS: 'No implications',
}

export const terms: Record<Term, string> = {
  FOR_PERIOD: 'for period',
  PERMANENTLY: 'permanently',
}

export const decisions: Record<TDecision, string> = {
  DELETE: 'Delete',
  LIMIT: 'Limit',
}
