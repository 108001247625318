import './GridToolbar.scss'

import { useCallback, useMemo, useRef, useState } from 'react'

import { GridRowSelectionModel, GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid'

import ArrowDropDownIcon from '@admin/assets/img/ArrowDropDownIcon'
import ArrowDropUpIcon from '@admin/assets/img/ArrowDropUpIcon'
import Button from '@admin/components/shared/Button/Button'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import Typography from '@admin/components/shared/Typography/Typography'
import { useAppSelector } from '@admin/store/hooks'
import { selectProfile } from '@admin/store/profileSlice'

import type { TAny } from '@yzzy/types'

interface IGridToolbarProps extends GridToolbarProps, ToolbarPropsOverrides {
  handleChangeRoleClick: (element: TAny, role: string) => void
  handleChangeStatusClick: (element: TAny, enabled: boolean) => void
  possibleStatuses: TAny[]
  roles: TAny
  rows: TAny
  selectionModel: GridRowSelectionModel
}

export const GridToolbar = (props: IGridToolbarProps) => {
  const currentMember = useAppSelector(selectProfile)

  const firstAnchorElement = useRef<HTMLButtonElement | null>(null)
  const [multipleChangeStatusMenuAnchorElement, setMultipleChangeStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const handleMultipleChangeStatusMenuOpen = useCallback((event: TAny) => {
    event.stopPropagation()
    firstAnchorElement.current = event.currentTarget
    setMultipleChangeStatusMenuAnchorElement(event.currentTarget)
  }, [])
  const handleMultipleChangeStatusMenuClose = useCallback(() => {
    setMultipleChangeStatusMenuAnchorElement(null)
  }, [])

  const secondAnchorElement = useRef<HTMLButtonElement | null>(null)
  const [multipleChangeRoleMenuAnchorElement, setMultipleChangeRoleMenuAnchorElement] = useState<HTMLElement | null>(null)
  const handleMultipleChangeRoleMenuOpen = useCallback((event: TAny) => {
    event.stopPropagation()
    secondAnchorElement.current = event.currentTarget
    setMultipleChangeRoleMenuAnchorElement(event.currentTarget)
  }, [])
  const handleMultipleChangeRoleMenuClose = useCallback(() => {
    setMultipleChangeRoleMenuAnchorElement(null)
  }, [])

  const archivedMembersSelected = useMemo(() => {
    return props.rows.some((row: TAny) => row.status === 'ARCHIVE')
  }, [props.rows])

  const currentUserSelected = useMemo(() => {
    return props.rows.some((row: TAny) => row.email === currentMember.email)
  }, [props.rows, currentMember])

  const inactiveUserSelected = useMemo(() => {
    return props.rows.some((row: TAny) => row.status === 'TIME_OUT' || row.status.includes('PENDING'))
  }, [props.rows])

  return (
    <div className="membersTable--header">
      <Typography color="text.primary" variant="tableHeader">
        {props.selectionModel.length > 0 ? `${props.selectionModel.length} selected` : ''}
      </Typography>
      <div className="membersTableHeader--buttonContainer">
        <Button
          className="membersTableHeader--button"
          disabled={currentUserSelected || props.selectionModel.length <= 0}
          endIcon={multipleChangeStatusMenuAnchorElement ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={handleMultipleChangeStatusMenuOpen}
          variant="outlined"
        >
          Change status
        </Button>
        <Button
          disabled={archivedMembersSelected || currentUserSelected || props.selectionModel.length <= 0}
          endIcon={multipleChangeRoleMenuAnchorElement ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={handleMultipleChangeRoleMenuOpen}
          variant="outlined"
        >
          Change role
        </Button>
        <Menu
          id="multiple-change-status-menu"
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorEl={firstAnchorElement.current}
          onClose={handleMultipleChangeStatusMenuClose}
          open={Boolean(multipleChangeStatusMenuAnchorElement)}
        >
          {props.possibleStatuses.map((element: TAny) => (
            <MenuItem
              key={element.status}
              disabled={inactiveUserSelected && element.status !== 'ARCHIVE'}
              onClick={() => props.handleChangeStatusClick(element, true)}
              sx={{ width: 172 }}
            >
              {element.title}
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="multiple-change-role-menu"
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorEl={secondAnchorElement.current}
          onClose={handleMultipleChangeRoleMenuClose}
          open={Boolean(multipleChangeRoleMenuAnchorElement)}
        >
          {props.roles.map((element: TAny) => (
            <MenuItem key={element.id} onClick={() => props.handleChangeRoleClick(element, props.rows[0])}>
              {element.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  )
}
