import { ComponentClass, ReactNode } from 'react'
import { SortableElement, SortableElementProps } from 'react-sortable-hoc'

import type { TAny } from '@yzzy/types'

interface ISortableElement {
  children: ReactNode
}

interface ISortableItem extends SortableElementProps {
  children: ReactNode
}

const SortableItem: ComponentClass<ISortableItem, TAny> = SortableElement(({ children }: ISortableElement) => children)

export default SortableItem
