import './index.scss'

import { useEffect } from 'react'

import MuiPagination from '@mui/material/Pagination'
import { GridFooterContainer, gridPageCountSelector, gridPageSelector, gridPageSizeSelector, GridPagination, useGridSelector } from '@mui/x-data-grid'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useLocalStorage } from '@uidotdev/usehooks'

import Typography from '@admin/components/shared/Typography/Typography'

const Empty = () => {
  return null
}

const CustomFooter = () => {
  const apiReference = useGridApiContext()
  const page = useGridSelector(apiReference, gridPageSelector)
  const pageCount = useGridSelector(apiReference, gridPageCountSelector)
  const rowsPerPage = useGridSelector(apiReference, gridPageSizeSelector)
  const [gridPageCountState, saveGridPageCountState] = useLocalStorage('gridPageCountState', 0)

  const setPage = (value: number) => {
    apiReference.current.setPage(value)
  }

  const setPageSize = (value: number) => {
    apiReference.current.setPageSize(value)
  }

  useEffect(() => {
    if (pageCount !== 0) {
      saveGridPageCountState(pageCount)
    }
  }, [pageCount])

  return (
    gridPageCountState !== 0 && (
      <GridFooterContainer className="customFooter">
        <Typography sx={{ flex: '0 1 30%' }} variant="caption">
          Page {page + 1} out of {gridPageCountState}
        </Typography>
        <MuiPagination
          count={gridPageCountState}
          defaultPage={1}
          onChange={(event, value) => setPage(value - 1)}
          page={page + 1}
          sx={{ display: 'flex', flex: '0 1 auto', justifyContent: 'center' }}
        />
        <GridPagination
          onPageChange={() => {
            if (pageCount !== 0) saveGridPageCountState(pageCount)
          }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
              transformOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
            },
          }}
          ActionsComponent={Empty}
          count={apiReference.current.getRowsCount()}
          onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value))}
          page={page}
          rowsPerPage={rowsPerPage}
          sx={{ flex: '0 1 30%' }}
        />
      </GridFooterContainer>
    )
  )
}

export default CustomFooter
