import './index.scss'

import { useEffect, useMemo, useRef, useState } from 'react'
// import { useErrorBoundary } from 'react-error-boundary'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'

import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { closeSnackbar, enqueueSnackbar, VariantType } from 'notistack'

import CloseIcon from '@admin/assets/img/CloseIcon'
import CopyIcon from '@admin/assets/img/CopyIcon'
import ErrorIcon from '@admin/assets/img/ErrorIcon'
import EyeClosedIcon from '@admin/assets/img/EyeClosedIcon'
import EyeIcon from '@admin/assets/img/EyeIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import Logo from '@admin/assets/img/Logo'
import WarningIcon from '@admin/assets/img/WarningIcon'
import AuthLayout from '@admin/components/AuthLayout/AuthLayout'
import getValidationSchema from '@admin/components/Authorization/utils/getValidationShema'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import InputAdornment from '@admin/components/shared/InputAdornment/InputAdornment'
import LinkMUI from '@admin/components/shared/Link/Link'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import OTPInput from '@admin/components/shared/OTPInput'
import Popover from '@admin/components/shared/Popover/Popover'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { ADMIN_EMAIL } from '@admin/shared/constants/info'
import {
  checkoutErrorMessage,
  fetchSignIn,
  fetchVerifyOtp,
  selectAuthCooldownTimer,
  selectAuthStatus,
  selectAuthStatusText,
  selectAuthTriesResendLeft,
  selectIsAuth,
  // selectSnackbarKeys,
  updateSnackbarKeys,
} from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchProfile } from '@admin/store/profileSlice'
import { AuthMessages, CommonErrorMessages, OtherMessages, ProfileMessages } from '@admin/store/types/ErrorMessages'

import type { TAny } from '@yzzy/types'

dayjs.extend(utc)

interface Info {
  infoType: TInfoType | undefined
  isShow: boolean
  message: string
}

type TInfoType = 'error' | 'message' | 'warning'

function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [showOtp, setShowOtp] = useState(false)
  const [showInfo, setShowInfo] = useState<Info>({ infoType: undefined, isShow: false, message: '' })
  const reference = useRef<boolean>(false)
  const [anchorElement, setAnchorElement] = useState<HTMLSpanElement | null>(null)
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })

  const statusText = useAppSelector(selectAuthStatusText)
  const status = useAppSelector(selectAuthStatus)
  const triesResendLeft = useAppSelector(selectAuthTriesResendLeft)
  const cooldownTimer = useAppSelector(selectAuthCooldownTimer)
  // const snackbarKeys = useAppSelector(selectSnackbarKeys)
  const isAuth = useAppSelector(selectIsAuth)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { minutes, restart, seconds } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(dayjs(cooldownTimer).local().format()),
  })

  const schema = useMemo(() => getValidationSchema(showOtp ? 'otp' : 'login'), [showOtp])

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    register,
    reset,
    resetField,
    trigger,
  } = useForm({
    defaultValues: showOtp
      ? {
          otp: '',
        }
      : {
          email: '',
          password: '',
        },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(schema),
  })

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address has been copied to clipboard', { variant: 'success' as VariantType })

      return navigator.clipboard.writeText(ADMIN_EMAIL)
    }
  }
  const [bannedUsers, setBannedUsers] = useState<string[]>([])
  const [blockedUsers, setBlockedUsers] = useState<string[]>([])
  const [isDisableSignInButton, setIsDisableSignInButton] = useState(false)
  const isSignInButtonDisabled = (!isValid && !errors.otp) || isDisableSignInButton
  const email = useWatch({ name: 'email', control }) || ''

  // const { showBoundary } = useErrorBoundary()

  const onSubmit = async (data: TAny) => {
    if (!showOtp) {
      closeSnackbar()
      dispatch(updateSnackbarKeys([]))
    }

    void trigger()

    try {
      if (showOtp) {
        const result = await dispatch(fetchVerifyOtp({ email: data.email, otp: data.otp }))

        if (result.meta.requestStatus === 'fulfilled') {
          navigate('/ui/users') // todo поменять на первый доступный раздел после добавления ролей
          await dispatch(fetchProfile())
        }
      } else {
        const result = await dispatch(fetchSignIn({ email: data.email, password: data.password }))

        if (result.meta.requestStatus === 'fulfilled') {
          setShowOtp(true)
          dispatch(checkoutErrorMessage({ showInfoLoginMessage: false }))
          setShowInfo({ infoType: undefined, isShow: false, message: '' })
        } else if (result.meta.requestStatus === 'rejected') {
          const { title } = result.payload
          const userEmail = result.meta.arg.email

          if (title === AuthMessages.NO_TRIES_LOGIN || title === ProfileMessages.NO_TRIES) {
            setBannedUsers((previousBannedUsers) => [...previousBannedUsers, userEmail])
          } else if (title === AuthMessages.TRY_SIGN_IN_LATER) {
            setBlockedUsers((previousBlockedUsers) => [...previousBlockedUsers, userEmail])
          } else {
            enqueueSnackbar('Signing in error, try again later', { variant: 'error' as VariantType })
          }
        }
      }
    } catch {
      enqueueSnackbar('Signing in error, try again later', { variant: 'error' as VariantType })
    }
  }

  useEffect(() => {
    if (cooldownTimer) {
      restart(new Date(dayjs(cooldownTimer).local().format()))
    }
  }, [cooldownTimer])

  useEffect(() => {
    if (isAuth) {
      navigate('/ui/users') // todo поменять на первый доступный раздел после добавления ролей
    }
  }, [isAuth])

  const isEmailBlocked = blockedUsers.includes(email)

  useEffect(() => {
    const isEmailBanned = bannedUsers.includes(email)

    if (statusText === AuthMessages.NO_TRIES_LOGIN && isEmailBanned) {
      setShowInfo({
        infoType: 'error',
        isShow: true,
        message: `Your account has been blocked due to multiple unsuccessful login attempts.\n\nTo recover access, please contact your manager.`,
      })
      setIsDisableSignInButton(true)
    } else if (statusText === ProfileMessages.NO_TRIES && isEmailBanned) {
      setShowInfo({
        infoType: 'error',
        isShow: true,
        message: `Your account has been blocked due to multiple unsuccessful password changes.\n\nTo recover access, please contact your manager.`,
      })
      setIsDisableSignInButton(true)
    } else if (statusText === AuthMessages.TRY_SIGN_IN_LATER && isEmailBlocked && minutes + seconds > 0) {
      setIsDisableSignInButton(true)
    } else {
      setShowInfo({ infoType: undefined, isShow: false, message: '' })
      setIsDisableSignInButton(false)
    }
    if (statusText === ProfileMessages.NO_TRIES) {
      setShowInfo({
        infoType: 'error',
        isShow: true,
        message: `Your account has been blocked due to multiple unsuccessful password changes.\n\nTo recover access, please contact your manager `,
      })
    }
  }, [bannedUsers, isEmailBlocked, email, statusText])

  useEffect(() => {
    if (!reference.current) {
      reference.current = true

      return
    }

    switch (statusText) {
      case AuthMessages.DEACTIVATED_OTP_CODE:
      case AuthMessages.EXPIRED_OTP_CODE:
        setShowInfo({ infoType: 'error', isShow: true, message: `Code was deactivated.\nRequest another one.` })
        break
      case AuthMessages.INCORRECT_CREDENTIALS:
        setShowInfo({ infoType: 'error', isShow: true, message: 'Incorrect email or password' })
        break
      case AuthMessages.NO_TRIES_GET_OTP:
        setShowInfo({
          infoType: 'error',
          isShow: true,
          message: `You have made too many attempts to resend the one time password.\n\nTo recover access, please contact your manager.`,
        })
        break
      case AuthMessages.NO_TRIES_LOGIN:
        setShowInfo({
          infoType: 'error',
          isShow: true,
          message: `Your account has been blocked due to multiple unsuccessful login attempts.\n\nTo recover access, please contact your manager.`,
        })
        break
      case AuthMessages.TRY_SIGN_IN_LATER:
        setShowInfo({
          infoType: 'error',
          isShow: true,
          message: `You have unsuccessfully tried to enter 5 times.\nTry again 10 minutes later.`,
        })
        break
      case CommonErrorMessages.UNEXPECTED_ERROR:
        enqueueSnackbar('Signing in error, try again later', { variant: 'error' as VariantType })
        showOtp && setShowOtp(false)
        resetField('otp')
        break
      case OtherMessages.SHOULD_ENTER_CREDENTIALS_AGAIN:
        setShowInfo({
          infoType: 'message',
          isShow: true,
          message: 'You have to enter your credentials again to request new code.',
        })
        break
      case AuthMessages.INCORRECT_OTP:
      default:
        break
    }
  }, [statusText, reference])

  useEffect(() => {
    if (showOtp) {
      void trigger('otp')
      if (triesResendLeft && triesResendLeft === 1) {
        setShowInfo({
          infoType: 'warning',
          isShow: true,
          message: `If you don’t enter one time password now, you will be able to request new one 1 more time.\nIf you don’t enter one time password then, your account will be blocked.`,
        })

        return
      }
      !triesResendLeft &&
        setShowInfo({
          infoType: 'warning',
          isShow: true,
          message: 'If you don’t enter one time password now, your account will be blocked.',
        })
    }
  }, [triesResendLeft, showOtp])

  const info = showInfo.isShow ? (
    <div className={`authForm-info authForm-info--${showInfo.infoType === 'message' ? 'info' : showInfo.infoType} fd-r`}>
      <div>{showInfo.infoType === 'message' ? <InfoIcon /> : showInfo.infoType === 'warning' ? <WarningIcon /> : <ErrorIcon />}</div>
      <div className="authForm-info-text">
        {showInfo.message}
        {statusText === AuthMessages.NO_TRIES_LOGIN && (
          <IconButton className="authForm-button--minWidth" onClick={() => void copyEmail()}>
            <CopyIcon />
          </IconButton>
        )}
      </div>
    </div>
  ) : null

  return (
    <AuthLayout>
      <div className="authContainer">
        <div className="authForm-container">
          <Logo isStatic={true} />
          <p className="authForm-text">{showOtp ? 'Enter the code we have sent to your email' : 'Enter your credentials'}</p>
          {info}
          <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
            {showOtp ? (
              <>
                <Controller
                  name="otp"
                  render={({ field: { onChange, ...field } }) => (
                    <div>
                      {/*<MuiOtpInput*/}
                      {/*  sx={{ gap: 1 }}*/}
                      {/*  onChange={(value) => {*/}
                      {/*    onChange(value);*/}
                      {/*    trigger('otp');*/}
                      {/*  }}*/}
                      {/*  {...field}*/}
                      {/*  length={6}*/}
                      {/*  TextFieldsProps={{ error: statusText === AuthMessages.INCORRECT_OTP || statusText === AuthMessages.DEACTIVATED_OTP_CODE }}*/}
                      {/*/>*/}

                      <OTPInput
                        {...field}
                        inputClassName={
                          (showInfo.isShow && showInfo.infoType) === 'error' ||
                          statusText === AuthMessages.INCORRECT_OTP ||
                          statusText === AuthMessages.DEACTIVATED_OTP_CODE
                            ? 'otpInput otpInput_error'
                            : 'otpInput'
                        }
                        length={6}
                        onChangeOTP={onChange}
                        autoFocus
                      />
                      {(showInfo.isShow && showInfo.infoType) === 'error' ||
                      statusText === AuthMessages.INCORRECT_OTP ||
                      statusText === AuthMessages.DEACTIVATED_OTP_CODE ? (
                        <FormHelperText error>Incorrect code</FormHelperText>
                      ) : null}
                    </div>
                  )}
                  control={control}
                  rules={{ validate: (value) => value?.length === 6 }}
                />
                <LinkMUI
                  onClick={(event) => {
                    if (triesResendLeft && triesResendLeft > 0) {
                      setShowOtp(false)
                      dispatch(checkoutErrorMessage({ showInfoLoginMessage: true }))
                      reset()

                      return
                    }
                    setAnchorElement(event.currentTarget)
                    setCoordinates({ x: event.clientX, y: event.clientY })
                  }}
                  color="primary"
                  component="span"
                  sx={{ cursor: 'pointer', display: '-webkit-box', m: '8px 0 0 auto' }}
                  underline="hover"
                  variant="body2"
                >
                  <Tooltip
                    title={
                      triesResendLeft && triesResendLeft > 1
                        ? `You can request new code ${triesResendLeft} more times`
                        : (triesResendLeft === 1 && `You can request new code ${triesResendLeft} more time`) || 'You can’t request new code'
                    }
                    placement="top-end"
                    followCursor
                  >
                    <span> Request new code</span>
                  </Tooltip>
                </LinkMUI>
                <Popover
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  anchorPosition={{
                    left: coordinates.x,
                    top: coordinates.y,
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  anchorReference="anchorPosition"
                  onClose={() => setAnchorElement(null)}
                  open={!!anchorElement}
                >
                  <div className="infoContent">
                    <div className="infoContent-textInfo">
                      You have used maximum attempts of code request. To recover password, please contact your manager.
                      {/*<br />*/}
                      {/*<span className="infoContent-textInfo--main"> {ADMIN_EMAIL} </span>*/}
                      {/*<IconButton className="infoContent-copyButton" onClick={() => void copyEmail()}>*/}
                      {/*  <CopyIcon />*/}
                      {/*</IconButton>*/}
                    </div>

                    <IconButton className="infoContent-closeButton" onClick={() => setAnchorElement(null)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Popover>
                <LoadingButton
                  fullWidth
                  className="authForm-field"
                  color="primary"
                  disabled={!isValid || !!errors.otp}
                  loading={status === 'loading'}
                  loadingPosition="start"
                  size="large"
                  startIcon={null}
                  type="submit"
                  variant="contained"
                >
                  Confirm
                </LoadingButton>
              </>
            ) : (
              <>
                <TextField
                  {...register('email')}
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="off"
                  className="authForm-field"
                  defaultValue=""
                  error={!!errors.email}
                  onBlur={() => trigger('email')}
                />
                {errors.email && <FormHelperText error>{errors.email?.message as string}</FormHelperText>}
                <TextField
                  {...register('password')}
                  fullWidth
                  label="Password"
                  name="password"
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className="authForm-button--minWidth"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className="authForm-field"
                  defaultValue=""
                  error={!!errors.password}
                  onBlur={() => trigger('password')}
                  type={showPassword ? 'text' : 'password'}
                />
                {errors.password && <FormHelperText error>{errors.password?.message as string}</FormHelperText>}
                <LinkMUI
                  onClick={(event) => {
                    setAnchorElement(event.currentTarget)
                    setCoordinates({ x: event.clientX, y: event.clientY })
                  }}
                  color="primary"
                  component="span"
                  sx={{ cursor: 'pointer', m: '8px 0 0 auto' }}
                  underline="hover"
                  variant="body2"
                >
                  Forgot password or have any issues?
                </LinkMUI>
                <Popover
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  anchorPosition={{
                    left: coordinates.x,
                    top: coordinates.y,
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  anchorEl={anchorElement}
                  anchorReference="anchorPosition"
                  onClose={() => setAnchorElement(null)}
                  open={!!anchorElement}
                >
                  <div className="infoContent">
                    <div className="infoContent-textInfo">
                      If you forgot your password or have other problems, please contact your manager.
                      {/*<span className="infoContent-textInfo--main"> {ADMIN_EMAIL} </span>*/}
                      {/*<IconButton className="authForm-button--minWidth" onClick={() => void copyEmail()}>*/}
                      {/*  <CopyIcon />*/}
                      {/*</IconButton>*/}
                    </div>

                    <IconButton className="infoContent-closeButton" onClick={() => setAnchorElement(null)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Popover>

                <LoadingButton
                  fullWidth
                  className="authForm-field"
                  color="primary"
                  disabled={isSignInButtonDisabled}
                  loading={status === 'loading'}
                  loadingPosition="start"
                  size="large"
                  startIcon={null}
                  type="submit"
                  variant="contained"
                >
                  Sign in{' '}
                  {cooldownTimer && isEmailBlocked && minutes + seconds > 0
                    ? `(${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds})`
                    : ''}
                </LoadingButton>
              </>
            )}
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Login
