import React from 'react'

import Button from '@admin/components/shared/Button/Button'
import '@admin/components/WorldManagement/Banners/BannersModal/AddBanner/index.scss'

interface IProps {
  closeModal(): void
  onClose(): void
}

const AddBannerModalCancel = ({ closeModal, onClose }: IProps) => {
  return (
    <div className="addBannerModal">
      <div className="addBannerModal-header">Cancel adding new banner?</div>
      <div className="addBannerModal-content">Banner won’t be added</div>
      <div className="addBannerModal-footer">
        <div className="addBannerModal-footer-buttons">
          <Button onClick={closeModal}>Don’t cancel</Button>
          <Button
            onClick={() => {
              closeModal()
              onClose()
            }}
            color="primary"
            variant="contained"
          >
            Cancel adding
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddBannerModalCancel
