import './index.scss'

import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const AuthLayout = ({ children }: IProps) => {
  return <div className="authLayout">{children}</div>
}

export default AuthLayout
