import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom'

import { GridColDef, GridEventListener } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import { GridRowId } from '@mui/x-data-grid/models/gridRows'
import startCase from 'lodash/startCase'
import { enqueueSnackbar } from 'notistack'

import Button from '@admin/components/shared/Button/Button'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import RenderDetailPanelToggle from '@admin/components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle'
import RenderOperationTypeCell from '@admin/components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell'
import Divider from '@admin/components/shared/Divider/Divider'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Select, { ISelectOptions } from '@admin/components/shared/Select/Select'
import SimpleGrid from '@admin/components/shared/SimpleGrid/SimpleGrid'
import Stack from '@admin/components/shared/Stack/Stack'
import TextField from '@admin/components/shared/TextField/TextField'
import Typography from '@admin/components/shared/Typography/Typography'
import useRemainingTimeColor from '@admin/hooks/useRemainingTimeColor'
import { ConsumptionRequestsRoutes } from '@admin/routes/enum'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchConsumptionRequestCases,
  fetchConsumptionsDictionaries,
  fetchSendConsumptionRequest,
  selectConsumptionDictionaries,
} from '@admin/store/slices/ConsumptionRequests/consumptionRequestsSlice'
import { IOperation } from '@admin/store/slices/Payments/interface'
import { selectOperationDetails } from '@admin/store/slices/Payments/operationsSlice'
import { fetchRefundReasons, selectRefundReasons } from '@admin/store/slices/Settings/dictionariesSlice'
import { LocalStorageKeys } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { sentenceCase } from '@admin/utils//sentenceCase'

import type { TAny } from '@yzzy/types'

import {
  CustomDetailPanelContent,
  RenderCreationDateCell,
  RenderFeeCell,
  RenderHeaderWithSecondaryText,
  RenderStatusCell,
} from '../../Payments/views/Operations/components'
import { ApprovalConfirmModal, ConfirmCancelModal } from '../modals'

export interface IFormValues {
  appleConsumptionRequestData: {
    accountTenure: string
    consumptionStatus: string
    deliveryStatus: string
    lifetimeDollarsPurchased: string
    lifetimeDollarsRefunded: string
    playTime: string
    userStatus: string
  }
  comment: string
  consumptionRequestType: string
  reason: string
}

interface IProps {
  onNavigateToNextRequest: () => void
  useFormMethods: UseFormReturn<IFormValues>
}

export const RenderConsumptionRequest = ({ onNavigateToNextRequest, useFormMethods }: IProps) => {
  const {
    formState: { isValid, errors, isDirty },
    getValues,
    register,
    reset,
    setError,
    setValue,
    trigger,
  } = useFormMethods

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id: requestId } = useParams()
  const apiReference = useGridApiRef()

  const [searchParameters] = useSearchParams()
  const creationDate = searchParameters.get('creationDate')
  const platform = searchParameters.get('platform')

  const { page, pageSize }: { page: number; pageSize: number } = useOutletContext()

  const refundReasons = useAppSelector(selectRefundReasons)
  const consumptionDictionaries = useAppSelector(selectConsumptionDictionaries)
  const operationDetails = useAppSelector(selectOperationDetails)

  type TDynamicFieldName = keyof typeof consumptionDictionaries

  const localData = localStorage.getItem(LocalStorageKeys.CONSUMPTION_REQUEST_SENDING_CONFIRM_SKIP)
  const responseSendingConfirmSkip = localData !== null ? !!JSON.parse(localData) : false

  const [isOpenApprovalModal, setIsOpenApprovalModal] = useState<boolean>(false)
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false)
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const { color, remainingTime } = useRemainingTimeColor(creationDate ?? '')

  const handleCancelSolving = () => {
    isDirty ? setIsOpenConfirmCancelModal(true) : navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
  }

  const handleCloseConfirmModal = () => {
    reset()
    navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
  }

  const checkRequestSending = async () => {
    await trigger()

    if (!responseSendingConfirmSkip && isValid && isDirty) {
      setIsOpenApprovalModal(true)
    } else if (isValid && isDirty) {
      await handleRequestSending()
    }
  }

  const handleRefreshData = useCallback(async () => {
    dispatch(
      fetchConsumptionRequestCases({
        page: page,
        pageSize: pageSize,
      }),
    )
  }, [dispatch, page, pageSize])

  const handleRequestSending = async () => {
    setIsOpenApprovalModal(false)

    const data = getValues()
    const formattedData = {
      ...data,
      comment: data.comment ? data.comment.trim() : null,
      operationId: operationDetails?.operation.id ?? null,
    }

    try {
      const result = await dispatch(fetchSendConsumptionRequest(formattedData))

      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Sent. Request closed`, {
          autoHideDuration: 1000,
          variant: 'success',
        })

        if (requestId) {
          setTimeout(() => {
            // dispatch(removeResolvedRequestId(requestId));
            navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS)
            handleRefreshData()
            // onNavigateToNextRequest();
          }, 1000)
        }
      } else if (result.payload) {
        const { title, techInfo } = result.payload
        const techInfoKeys = Object.keys(techInfo) as TAny

        if (techInfoKeys.length) {
          techInfoKeys.forEach((key: string) => setError(key as TAny, { message: techInfo[key] ?? title }))
        } else {
          enqueueSnackbar(`An error occurred during request resolution: ${title}`, {
            variant: 'error',
          })
        }
      } else {
        enqueueSnackbar('An error occurred during request resolution', {
          variant: 'error',
        })
      }
    } catch {
      enqueueSnackbar('An error occurred during request resolution', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    dispatch(fetchConsumptionsDictionaries())
    dispatch(fetchRefundReasons())
  }, [dispatch])

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      display: 'flex',
      renderCell: (parameters) => <RenderDetailPanelToggle id={parameters.id} value={parameters.value} />,
    },
    {
      display: 'flex',
      field: 'id',
      flex: 0.5,
      headerName: 'Operation ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      display: 'flex',
      field: 'operationType',
      flex: 0.5,
      headerName: 'Operation type',
      renderCell: (parameters) => <RenderOperationTypeCell props={parameters} />,
      sortable: false,
    },
    {
      display: 'flex',
      field: 'createDate',
      flex: 0.3,
      headerName: 'Creation date',
      renderCell: (parameters) => <RenderCreationDateCell props={parameters} />,
      sortable: false,
    },
    {
      display: 'flex',
      field: 'status',
      flex: 0.3,
      headerName: 'Status',
      renderCell: (parameters) => <RenderStatusCell props={parameters} />,
      sortable: false,
    },
    {
      display: 'flex',
      field: 'price',
      flex: 0.4,
      headerName: 'Amount',
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'
      },
    },
    {
      display: 'flex',
      field: 'platformFeeClient',
      flex: 0.4,
      headerName: 'Fee',
      renderCell: (parameters) => <RenderFeeCell props={parameters} />,
      sortable: false,
      type: 'number' as const,
    },
    {
      display: 'flex',
      field: 'total',
      flex: 0.4,
      headerName: 'Total',
      renderHeader: (parameters) => <RenderHeaderWithSecondaryText props={parameters} secondaryText=" (P)" tooltipTitle="For payer" />,
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'
      },
    },
    {
      display: 'flex',
      field: 'totalAfterAllFees',
      flex: 0.4,
      headerName: 'Received ',
      renderHeader: (parameters) => <RenderHeaderWithSecondaryText props={parameters} secondaryText="(R)" tooltipTitle="For recipient" />,
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => {
        return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'
      },
    },
    // TODO Additional (after release) Net parameter
    // {
    //   field: 'netParam',
    //   headerName: 'Net',
    //   flex: 1,
    //   sortable: false,
    //   type: 'number' as const,
    //   valueFormatter: (value) => {
    //     return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
    //   },
    // },
  ]

  const rows: [] | IOperation[] = useMemo(() => {
    if (operationDetails) {
      return [operationDetails.operation]
    } else return []
  }, [operationDetails])

  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (parameters) => {
      apiReference.current.toggleDetailPanel(parameters.id)
    },
    [apiReference],
  )

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent row={row} />,
    [],
  )

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  return (
    <Stack className="consumption-requests-carousel__card-wrapper">
      <Stack p="16px 8px">
        <Typography m="0 0 0 24px" variant="subtitle2">
          Operation
        </Typography>
        <DataGrid
          hideFooter
          hideFooterSelectedRowCount
          slots={{
            noRowsOverlay: () => (
              <Typography sx={{ position: 'absolute', right: '50%', top: '50%' }} variant="body2">
                No data to display
              </Typography>
            ),
          }}
          apiRef={apiReference}
          className="operations-table--brief"
          columns={columns}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowClassName={(parameters) => `${parameters.row.id === detailPanelExpandedRowIds[0] ? 'Mui-selected' : ''}`}
          getRowId={(row) => row.id}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          onRowClick={onRowClick}
          rowHeight={68}
          rows={rows}
          autoHeight
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          disableRowSelectionOnClick
        />
      </Stack>
      <Divider orientation="horizontal" flexItem />
      <Stack alignItems="center" direction="row" justifyContent="space-between" p={3}>
        <Stack maxWidth={'45%'} alignSelf="self-start" flex={'45%'}>
          <Typography variant="subtitle2">Entity parameters</Typography>
          <Stack direction="row" gap={4} justifyContent="flex-start">
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Platform
                  </Typography>
                }
                secondary={
                  <Typography color="text.primary" variant="body2">
                    {startCase(platform?.toLowerCase()) || '—'}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary={
                  <Typography color="text.secondary" variant="body2">
                    Sample content provided
                  </Typography>
                }
                secondary={
                  <Typography color="text.primary" variant="body2">
                    Yes
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
          </Stack>

          <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} sx={{ paddingTop: 2 }} container>
            {consumptionDictionaries &&
              Object.entries(consumptionDictionaries)
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .map(([key, value]) => (
                  <SimpleGrid key={key} xs={12} item>
                    <Select
                      {...register(`appleConsumptionRequestData.${key}` as TDynamicFieldName)}
                      fullWidth
                      name={key}
                      onChange={(event) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setValue(`appleConsumptionRequestData.${key}` as TDynamicFieldName, event.target.value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                        trigger(`appleConsumptionRequestData.${key}` as TDynamicFieldName)
                      }}
                      options={Object.entries(value).map(([optionValue, optionText]) => {
                        return {
                          text: optionText,
                          value: optionValue,
                        } as ISelectOptions
                      })}
                      defaultValue=""
                      error={!!errors.appleConsumptionRequestData?.[key as TDynamicFieldName]}
                      onBlur={() => trigger(`appleConsumptionRequestData.${key}` as TDynamicFieldName)}
                      placeholder={sentenceCase(key)}
                      size="small"
                      variant="outlined"
                    />
                    {errors.appleConsumptionRequestData?.[key as TDynamicFieldName] && (
                      <FormHelperText error={!!errors.appleConsumptionRequestData?.[key as TDynamicFieldName]}>
                        {errors.appleConsumptionRequestData[key as TDynamicFieldName]['message']}
                      </FormHelperText>
                    )}
                  </SimpleGrid>
                ))}
          </SimpleGrid>
        </Stack>

        <Divider orientation="vertical" sx={{ margin: '0 48px' }} flexItem />

        <Stack maxWidth={'45%'} alignSelf="self-start" flex={'45%'}>
          <Typography variant="subtitle2">YZZY solution</Typography>
          <SimpleGrid alignContent="stretch" flexDirection="column" minHeight="100%" spacing={2} sx={{ paddingTop: 2 }} container>
            <SimpleGrid pt={2} xs={12} item>
              <Select
                {...register('reason')}
                fullWidth
                name="reason"
                options={
                  refundReasons.length
                    ? refundReasons.map(
                        ({ name }) =>
                          ({
                            text: name,
                            value: name,
                          }) as ISelectOptions,
                      )
                    : []
                }
                defaultValue=""
                error={!!errors.reason}
                onBlur={() => trigger('reason')}
                placeholder="Reason"
                size="small"
                variant="outlined"
              />
              {errors.reason?.message && <FormHelperText error={!!errors.reason}>{errors.reason.message}</FormHelperText>}
            </SimpleGrid>
            <SimpleGrid xs={12} item>
              <TextField
                {...register('comment')}
                fullWidth
                label="Comment"
                name="comment"
                error={!!errors.comment}
                helperText={errors.comment && (errors.comment?.message as string)}
                maxRows={16}
                onBlur={() => trigger('comment')}
                size="small"
                type="text"
                multiline
              />
            </SimpleGrid>
          </SimpleGrid>
        </Stack>
      </Stack>
      <Divider orientation="horizontal" flexItem />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <ListItem>
          <ListItemText
            primary={
              <Typography color="text.secondary" variant="body2">
                Time left
              </Typography>
            }
            secondary={
              <Typography color={color} variant="body2">
                {remainingTime ? remainingTime.formatted : '—'}
              </Typography>
            }
            disableTypography
          />
        </ListItem>

        <Stack direction="row" gap={2} mr={3}>
          <Button onClick={handleCancelSolving} variant="text">
            Cancel
          </Button>
          <Button onClick={() => checkRequestSending()} size="large" variant="contained">
            Send
          </Button>
        </Stack>
      </Stack>
      <ConfirmCancelModal closeModal={() => setIsOpenConfirmCancelModal(false)} onClose={handleCloseConfirmModal} open={isOpenConfirmCancelModal} />
      <ApprovalConfirmModal onClose={() => setIsOpenApprovalModal(false)} onRequestSend={() => handleRequestSending()} open={isOpenApprovalModal} />
    </Stack>
  )
}
