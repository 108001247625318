import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { COMMON_CLASSIFIER_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { IGender } from '@admin/types/commonTypes'

export interface ICommonState {
  genders: IGender[]
}

const initialState: ICommonState = {
  genders: [],
}

export const fetchGenders = createAsyncThunk('common/fetchGenders', async () => {
  const response = await axios.get(COMMON_CLASSIFIER_API + 'genders')

  return { ...response.data }
})

export const commonSlice = createSlice({
  name: 'common',
  extraReducers: (builder) => {
    builder.addCase(fetchGenders.fulfilled, (state, { payload }) => {
      state.genders = payload.genders
    })
  },
  initialState,
  reducers: {},
})

export const selectGenders = (state: TRootState): IGender[] => state.common.genders

export default commonSlice.reducer
