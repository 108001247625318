import './index.scss'

import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'

import { GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import isEqual from 'lodash/isEqual'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import DeleteIcon from '@admin/assets/img/DeleteIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import FilterIcon from '@admin/assets/img/FilterIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CheckIfEmptyCell from '@admin/components/shared/DataGrid/CheckIfEmptyCell/CheckIfEmptyCell'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Menu from '@admin/components/shared/Menu/Menu'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import SearchField from '@admin/components/shared/SearchField/SearchField'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { clearFilters, fetchFilters, reverseFilters, selectFilters, selectFiltersCurrentData, selectFiltersData } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { IPaymentFee, IPaymentFeeStatus } from '@admin/store/slices/Settings/interface'
import {
  fetchPaymentFees,
  selectPaymentFees,
  selectPaymentFeesStatus,
  selectTotalCount,
  setPaymentFeesStatus,
} from '@admin/store/slices/Settings/paymentFeesSlice'
import { Permissions } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { RenderActionsCell, RenderStatusSelect } from './components'
import { possiblePaymentFeesStatuses } from './constants'
import { AddFeeModal } from './modals'

export const PaymentFees = () => {
  const dispatch = useAppDispatch()
  const apiReference = useGridApiRef()
  const userPermissions = useAppSelector(selectUserPermissions)
  const data = useAppSelector(selectPaymentFees)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectPaymentFeesStatus)
  const filters = useAppSelector(selectFilters)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const filtersData = useAppSelector(selectFiltersData)
  const isLoading = status === 'loading'

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: TAny) => item !== null).length
  }, [filtersData])

  const { showBoundary } = useErrorBoundary()
  const [isAddingFee, setIsAddingFee] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [highlightedProviderTariffId, setHighlightedProviderTariffId] = useState<null | string>(null)

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [tableMenuAnchorElement, setTableMenuAnchorElement] = useState<HTMLElement | null>(null)
  const tableMenu = Boolean(tableMenuAnchorElement)

  const [statusMenuAnchorElement, setStatusMenuAnchorElement] = useState<HTMLElement | null>(null)
  const statusMenuOpen = Boolean(statusMenuAnchorElement)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,sonarjs/no-unused-vars
  const [_, setCurrentSelectedStatus] = useState<IPaymentFeeStatus | null>(null)
  const [currentSelectedFee, setCurrentSelectedFee] = useState<IPaymentFee | null>(null)

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchPaymentFeesGrid(0, newPaginationModel.pageSize)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchPaymentFeesGrid(newPaginationModel.page, newPaginationModel.pageSize)

      return newPaginationModel
    })
  }

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>, data: IPaymentFee) => {
    event.stopPropagation()
    setStatusMenuAnchorElement(event.currentTarget)
    setCurrentSelectedFee(data)
  }

  const handleTableMenuClick = (event: MouseEvent<HTMLButtonElement>, data: IPaymentFee) => {
    event.stopPropagation()
    setTableMenuAnchorElement(event.currentTarget)
    setCurrentSelectedFee(data)
  }

  const handleTableMenuClose = () => {
    setTableMenuAnchorElement(null)
  }

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorElement(null)
  }

  const handleChangeStatusClick = (element: IPaymentFeeStatus) => {
    setCurrentSelectedStatus(element)
    handleStatusMenuClose()
  }

  const handleAddFeeModalClose = (isAdded?: boolean) => {
    setIsAddingFee(false)
    if (isAdded) {
      dispatch(fetchPaymentFees({ page: paginationModel.page, pageSize: paginationModel.pageSize })).then((result) => {
        if (!isLoading) {
          setHighlightedProviderTariffId(result.payload?.content[0]?.providerTariffId)
          setTimeout(() => {
            setHighlightedProviderTariffId(null)
          }, 2000)
        }
      })
    }
  }

  const tableScrollTop = useCallback(() => {
    apiReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const fetchPaymentFeesInitial = useCallback(
    async (page: number, pageSize: number) => {
      try {
        await dispatch(fetchPaymentFees({ page, pageSize })).unwrap()
      } catch (error) {
        showBoundary(error)
      }
    },
    [dispatch],
  )

  const fetchPaymentFeesGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchPaymentFees({ page, pageSize })).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
    },
    [dispatch],
  )

  const repeatFetchingRequest = useCallback(async () => {
    await fetchPaymentFeesGrid(paginationModel.page, paginationModel.pageSize)
  }, [dispatch, paginationModel])

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters())
    await dispatch(fetchPaymentFees({ page: paginationModel.page, pageSize: paginationModel.pageSize }))
  }, [])

  const initialFetchData = useCallback(async () => {
    dispatch(setPaymentFeesStatus('loading'))
    await dispatch(fetchFilters('fees'))
    if (filters) {
      await fetchPaymentFeesInitial(paginationModel.page, paginationModel.pageSize)
    }
  }, [dispatch, filters, paginationModel])

  useEffect(() => {
    dispatch(clearFilters())
    initialFetchData()
  }, [])

  const columns: GridColDef[] = [
    {
      minWidth: 160,
      display: 'flex',
      field: 'providerTariffId',
      flex: 0.6,
      headerName: 'ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      minWidth: 80,
      display: 'flex',
      field: 'providerName',
      flex: 0.5,
      headerName: 'Provider',
      renderCell: ({ value }) => <span>{value ? startCase(value.toLowerCase()) : 'No provider'}</span>,
      sortable: false,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'transactionType',
      flex: 0.5,
      headerName: 'Transaction type',
      sortable: false,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'paymentMethodName',
      flex: 0.5,
      headerName: 'Payment method',
      renderCell: ({ value }) => <span>{value ? value : '—'}</span>,
      sortable: false,
    },
    {
      minWidth: 190,
      display: 'flex',
      field: 'paymentFeePercentage',
      flex: 0.5,
      headerName: 'Payment fee percentage',
      renderCell: (parameters) => CheckIfEmptyCell(parameters),
      sortable: false,
      type: 'number' as const,
      valueFormatter: (value) => `${Number(value).toString()}%`,
    },
    {
      minWidth: 150,
      display: 'flex',
      field: 'paymentFeeFixed',
      flex: 0.3,
      headerName: 'Payment fee fixed',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 80,
      display: 'flex',
      field: 'feeStatus',
      flex: 0.3,
      headerName: 'Status',
      renderCell: (parameters) => (
        <RenderStatusSelect
          isMenuOpen={statusMenuOpen}
          onClick={(error, data) => handleStatusMenuClick(error, data)}
          props={parameters}
          selectedCellData={currentSelectedFee}
        />
      ),
      sortable: false,
    },
    {
      minWidth: 60,
      display: 'flex',
      field: 'hidden',
      flex: 0.2,
      headerName: '',
      renderCell: (parameters) => <RenderActionsCell onClick={(error, data) => handleTableMenuClick(error, data)} props={parameters} />,
      sortable: false,
    },
  ]

  const rows: [] | IPaymentFee[] = useMemo(() => {
    if (data) {
      return data
    } else return []
  }, [data])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength)

  const isActionMenuVisible =
    checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_PAYMENT_FEE]) ||
    checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_PAYMENT_FEE])

  return (
    <div className="fees-table-container">
      <div className="fees-table-container__actions">
        <Stack direction="row" gap={2}>
          <Button disabled={isDisabledFilterButton} onClick={() => setFilterDrawer(true)} size="small" startIcon={<FilterIcon />} variant="outlined">
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
        </Stack>
        <Stack direction="row" gap={2}>
          {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_PAYMENT_FEE]) && (
            <Button onClick={() => setIsAddingFee(true)} variant="contained">
              <Typography variant="buttonMedium">Add fee</Typography>
            </Button>
          )}
          {!isEmptyData && (
            <SearchField
              label="Search by ID"
              clearEvent={() => setSearchValue('')}
              maxlength={50}
              onChange={handleChangeSearch}
              searchValue={searchValue}
              disabled
            />
          )}
        </Stack>
      </div>
      {filtersLength !== 0 && (
        <ActiveFilterList
          refreshData={() => {
            setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
            fetchPaymentFeesGrid(paginationModel.page, paginationModel.pageSize).then(tableScrollTop)
          }}
        />
      )}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter={isEmptyData && !fetchingError}
            columnVisibilityModel={{
              hidden: isActionMenuVisible,
            }}
            getRowClassName={(parameters) =>
              `${parameters.row.providerTariffId === highlightedProviderTariffId ? 'fees-table__highlighted-row' : ''}`
            }
            getRowSpacing={() => ({
              bottom: 16,
            })}
            slots={{
              footer: CustomFooter,
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  clearFilters: () => resetFilters(),
                  fetchingError: fetchingError,
                  isFiltersApplied: !!filtersLength,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <>
                      <Typography color="text.secondary" variant="body2">
                        No payment fee has been created yet
                      </Typography>
                      {checkPermissions(userPermissions, [Permissions.SETTINGS_ADD_PAYMENT_FEE]) && (
                        <Button color="primary" onClick={() => setIsAddingFee(true)} variant="text">
                          <Typography variant="buttonMedium">Create fee</Typography>
                        </Button>
                      )}
                    </>
                  ),
                }),
            }}
            apiRef={apiReference}
            className="fees-table"
            columnHeaderHeight={64}
            columns={columns}
            filterMode="server"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.providerTariffId}
            loading={isLoading}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={totalCount}
            rows={rows}
            sortingMode="server"
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
            pagination
          />
        </Box>
      </Box>
      <Menu
        id="table-menu"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        MenuListProps={{
          'aria-labelledby': 'table-button',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={tableMenuAnchorElement}
        onClose={handleTableMenuClose}
        open={tableMenu}
      >
        {checkPermissions(userPermissions, [Permissions.SETTINGS_EDIT_PAYMENT_FEE]) && (
          <MenuItem disabled>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {checkPermissions(userPermissions, [Permissions.SETTINGS_DELETE_PAYMENT_FEE]) && (
          <MenuItem disabled>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Menu
        id="status-menu"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={statusMenuAnchorElement}
        onClose={handleStatusMenuClose}
        open={statusMenuOpen}
      >
        {possiblePaymentFeesStatuses.map((status: IPaymentFeeStatus) => (
          <MenuItem
            key={status.title}
            onClick={() => handleChangeStatusClick(status)}
            selected={status.status === currentSelectedFee?.feeStatus}
            disabled
          >
            {status.title}
          </MenuItem>
        ))}
      </Menu>
      <AddFeeModal onClose={(isAdded) => handleAddFeeModalClose(isAdded)} open={isAddingFee} />
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterSidebarClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
            fetchPaymentFeesGrid(0, paginationModel.pageSize).then(tableScrollTop)
          }}
          onClose={onFilterSidebarClose}
          type="fees"
        />
      </Drawer>
    </div>
  )
}
