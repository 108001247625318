const PlayIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6.80198 19.7909L19.8462 12.2598C20.0462 12.1444 20.0462 11.8557 19.8462 11.7402L6.80198 4.20912C6.60198 4.09365 6.35198 4.23799 6.35198 4.46892L6.35198 19.5311C6.35198 19.7621 6.60198 19.9064 6.80198 19.7909ZM20.6962 13.7321C22.0296 12.9623 22.0296 11.0378 20.6962 10.268L7.65198 2.73688C6.31865 1.96708 4.65198 2.92932 4.65198 4.46892V19.5311C4.65198 21.0707 6.31864 22.033 7.65198 21.2632L20.6962 13.7321Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default PlayIcon
