import './index.scss'

import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import find from 'lodash/find'
import startCase from 'lodash/startCase'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUp from '@admin/assets/img/ChevronUpIcon'
import ImageIcon from '@admin/assets/img/ImageIcon'
import LinkIcon from '@admin/assets/img/LinkIcon'
import PlayIcon from '@admin/assets/img/PlayIcon'
import TextIcon from '@admin/assets/img/TextIcon'
import UserNoAvatar from '@admin/assets/img/UserNoAvatar.svg'
import UserNoAvatarPreview from '@admin/assets/img/UserNoAvatarPreview.svg'
import VideoIcon from '@admin/assets/img/VideoIcon'
import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import { IMediaProps } from '@admin/components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer'
import CardType from '@admin/components/Moderation/types/CardType'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import List from '@admin/components/shared/List/List'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { IMetrics } from '@admin/store/moderationSlice'
import { ContentType, EntityType } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

interface IProps {
  amountViolations: number
  cardType: CardType
  complaints: TAny[]
  entityInfo: TAny
  entityOwnerInfo: TAny
  hiveViolationConfirmed: boolean
  isFullHeightCard: boolean
  isManualMode: boolean
  moderationResults: TAny
  mostDangerousMetrics: string[]
  profileInfo: TAny
  setIsFullHeightCard: Dispatch<SetStateAction<boolean>>
  showDetails: (entityInfo: TAny) => void
  showPreview: (newValue: IMediaProps) => void
  showUsersProfile: () => void
  tabValue: TAny
  violationsFrom: string
}

const ModerationCardContent = ({
  amountViolations,
  cardType,
  complaints,
  entityInfo,
  entityOwnerInfo,
  hiveViolationConfirmed,
  isFullHeightCard,
  isManualMode,
  moderationResults,
  mostDangerousMetrics,
  profileInfo,
  setIsFullHeightCard,
  showDetails,
  showPreview,
  showUsersProfile,
  tabValue,
  violationsFrom,
}: IProps) => {
  const navigate = useNavigate()
  const [isShowButton, setIsShowButton] = useState(false)
  const contentParentReference = useRef<HTMLDivElement>(null)
  const contentLeftReference = useRef<HTMLDivElement>(null)
  const contentRightReference = useRef<HTMLDivElement>(null)

  const filteredModerationResults =
    hiveViolationConfirmed && !isManualMode ? moderationResults?.filter((result: TAny) => result?.metrics?.length !== 0) : moderationResults

  const profilePicPreviewJob = find(moderationResults, { mediaId: entityInfo?.entityId })

  const isShowEntityDetails =
    !profileInfo &&
    entityInfo &&
    !(
      entityInfo.entityType?.toUpperCase() === EntityType.AVATAR ||
      entityInfo.entityType?.toUpperCase() === EntityType.ABOUT_ME ||
      entityInfo.entityType?.toUpperCase() === EntityType.COMMENT ||
      entityInfo.entityType?.toUpperCase() === EntityType.MOOD
    )

  const isShowEntityInfo =
    entityInfo &&
    entityInfo.entityType?.toUpperCase() !== EntityType.COMMENT &&
    entityInfo.entityType?.toUpperCase() !== EntityType.AVATAR &&
    entityInfo.entityType?.toUpperCase() !== EntityType.ABOUT_ME

  const isShowEntityOwnerInfo =
    entityOwnerInfo &&
    (entityOwnerInfo.userName || entityOwnerInfo.firstName) &&
    (entityInfo.entityType === EntityType.AVATAR || entityInfo.entityType === EntityType.ABOUT_ME)

  const isMoodEntityType = entityInfo?.entityType?.toUpperCase() === EntityType.MOOD

  const isShowEntityHeader = !(
    entityInfo?.entityType?.toUpperCase() === EntityType.MOOD ||
    entityInfo?.entityType?.toUpperCase() === EntityType.AVATAR ||
    entityInfo?.entityType?.toUpperCase() === EntityType.ABOUT_ME
  )

  const publicationDate = useMemo(() => dayjs(entityInfo?.publicationDate).format('MM/DD/YYYY, hh:mm A') || null, [entityInfo?.publicationDate])

  const location = useMemo(() => {
    if (profileInfo && profileInfo?.locationCity && profileInfo?.locationCountry) {
      return `${profileInfo?.locationCity}, ${profileInfo.locationCountry}`
    }
    if (profileInfo && !profileInfo?.locationCity && profileInfo?.locationCountry) {
      return profileInfo.locationCountry
    }
    if (entityInfo && entityInfo.entityFormat === 'ONLINE') {
      return 'Online'
    }
    if (entityInfo && entityInfo?.locationCity && entityInfo?.locationCountry) {
      return `${entityInfo.locationCity}, ${entityInfo.locationCountry}`
    }
    if (entityInfo && !entityInfo?.locationCity && entityInfo?.locationCountry) {
      return entityInfo.locationCountry
    }

    return 'Not specified'
  }, [entityInfo, profileInfo])

  const getContentType = (contentType: string, mediaId: string) => {
    if (contentType?.toUpperCase() === ContentType.AVATAR && profilePicPreviewJob?.mediaId !== mediaId) {
      return 'Profile pic'
    }
    if (contentType?.toUpperCase() === ContentType.ABOUT_ME) {
      return 'What im about'
    }
    // if (contentType?.toUpperCase() === ContentType.IMAGE && hasVideoModerationJob) {
    //   return 'Preview';
    // }
    if (profilePicPreviewJob?.mediaId === mediaId) {
      return 'Image'
    } else return startCase(contentType?.toLowerCase())
  }

  useEffect(() => {
    const observerCallback = () => {
      if (contentParentReference.current && contentLeftReference.current && contentRightReference.current) {
        const parentContentHeight = contentParentReference.current.getBoundingClientRect().height
        const contentLeftHeight = contentLeftReference.current.getBoundingClientRect().height
        const contentRightHeight = contentRightReference.current.getBoundingClientRect().height

        if (contentLeftHeight >= parentContentHeight || contentRightHeight >= parentContentHeight) {
          setIsShowButton(true)
        }
      }
    }

    const resizeObserver = new ResizeObserver(observerCallback)

    if (contentParentReference.current) {
      resizeObserver.observe(contentParentReference.current)
    }
    if (contentLeftReference.current) {
      resizeObserver.observe(contentLeftReference.current)
    }
    if (contentRightReference.current) {
      resizeObserver.observe(contentRightReference.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [contentParentReference, contentLeftReference, contentRightReference])

  const handleToggleHeight = () => {
    setIsFullHeightCard(!isFullHeightCard)
  }

  return (
    <>
      <div
        style={{
          maxHeight: isFullHeightCard ? 'fit-content' : '320px',
          overflow: isFullHeightCard ? 'visible' : 'hidden',
        }}
        className="content"
        ref={contentParentReference}
      >
        <div className="content-violationBlock" ref={contentLeftReference}>
          <Typography sx={{ pb: isShowEntityHeader ? 3 : 0 }} variant="h6">
            {isManualMode ? (isShowEntityHeader ? 'Entity content' : '') : `${amountViolations} ${violationsFrom}`}
          </Typography>
          {filteredModerationResults &&
            filteredModerationResults.map((result: TAny) => {
              const text = result.text ?? `Text is NULL, mediaId ${result.mediaId}`

              const isTextContentType =
                result.contentType?.toUpperCase() === ContentType.TITLE ||
                result.contentType?.toUpperCase() === ContentType.DESCRIPTION ||
                result.contentType?.toUpperCase() === ContentType.ABOUT_ME ||
                result.contentType?.toUpperCase() === ContentType.BENEFITS
              const isVideoContentType = result.contentType?.toUpperCase() === ContentType.VIDEO
              const isImageContentType = result.contentType?.toUpperCase() === ContentType.IMAGE
              const isAvatarContentType = result.contentType?.toUpperCase() === ContentType.AVATAR

              const getMetricsIcon = () => {
                if (isTextContentType) {
                  return <TextIcon />
                }

                if (isVideoContentType) {
                  return <VideoIcon />
                }

                return <ImageIcon />
              }

              return (
                <div key={result.mediaId} className="content-violation">
                  <Typography className="content-type" color="text.secondary" variant="body2">
                    {getContentType(result.contentType, result.mediaId)}
                  </Typography>
                  <div className="content-violationSubject">
                    {!isManualMode && (
                      <List className="violations-list">
                        {result.metrics.map(({ metricName, score }: IMetrics) => (
                          <ListItem key={metricName} className="violations-list__description">
                            <ListItemIcon className="violations-list__icon">{getMetricsIcon()}</ListItemIcon>
                            <ListItemText
                              className={mostDangerousMetrics.some((element) => element === metricName) ? 'mostDangerous' : ''}
                              primary={metricName}
                              secondary={`Accuracy ${score}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <div className={`content-item ${isManualMode ? 'content-item--wide' : ''} `}>
                      {isTextContentType ? (
                        <Typography className="content-item-text" color="text.secondary" variant="body2">
                          {result.contentType?.toUpperCase() === ContentType.BENEFITS ? (
                            <ul className="content-item-text__list">
                              {text.split('\n').map((item: string) => (
                                <li key={item}>{item.trim()}</li>
                              ))}
                            </ul>
                          ) : (
                            text
                          )}
                        </Typography>
                      ) : (
                        <>
                          {(isImageContentType || isAvatarContentType) && <img alt="media" className="content-item-preview" src={result.mediaUrl} />}
                          {(isImageContentType || isAvatarContentType) && (
                            <Button
                              onClick={() =>
                                showPreview({
                                  contentType: result.contentType,
                                  entityId: entityInfo.entityId,
                                  entityOwnerId: entityOwnerInfo?.entityOwnerId,
                                  entityType: entityInfo.entityType,
                                  mediaId: result.mediaId,
                                  mediaUrl: result.mediaUrl,
                                  showDetailedHeader: true,
                                } as IMediaProps)
                              }
                              className="content-item-button"
                              startIcon={<ZoomInIcon />}
                              variant="outlined"
                            >
                              Show
                            </Button>
                          )}
                          {result.contentType?.toUpperCase() === ContentType.VIDEO && (
                            <Button
                              onClick={() =>
                                showPreview({
                                  contentType: result.contentType,
                                  entityId: entityInfo.entityId,
                                  entityType: entityInfo.entityType,
                                  mediaUrl: result.mediaUrl,
                                  showDetailedHeader: true,
                                } as IMediaProps)
                              }
                              className="content-item-button"
                              startIcon={<PlayIcon />}
                              variant="outlined"
                            >
                              Play
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          {complaints &&
            complaints?.map(({ key, value }: { key: string; value: TAny[] }) => {
              return (
                <div key={key} className="content-complaints">
                  <div className="content-type">
                    <span className={mostDangerousMetrics.some((element) => element === key) ? 'mostDangerous' : ''}>
                      {key} ({value.length})
                    </span>
                  </div>
                  <List className="violations-list">
                    {value.map((author: TAny) => (
                      <div key={author.authorId}>
                        <ListItem className="content-violationSubject-item">
                          <ListItemText
                            primary={
                              <>
                                @{author.userName} <span>(#{author.authorId})</span>
                              </>
                            }
                            secondary={
                              <span className="trust-block">
                                <span className="trust-block-validComplaints">
                                  {author.validComplaintsSent}/{author.allComplaintsSent}
                                </span>
                                <span className="trust-block-text"> valid complaints sent</span>
                              </span>
                            }
                          />
                        </ListItem>
                        {author.comment && (
                          <div className="content-item-comment">
                            <Typography color="text.secondary" variant="body2">
                              {author.comment}
                            </Typography>
                          </div>
                        )}
                      </div>
                    ))}
                  </List>
                </div>
              )
            })}
        </div>
        <div className="content-violationBlock" ref={contentRightReference}>
          {isShowEntityOwnerInfo && (
            <div className="content-profile">
              <div className="">
                <div className="content-profileInfo-userName">
                  <Typography variant="avatarInitials">
                    {entityOwnerInfo?.firstName}&nbsp;{entityOwnerInfo?.lastName}
                  </Typography>
                  {/*<Link to={`/ui/users/user/${entityOwnerInfo?.entityOwnerId}`} target="_blank">*/}
                  <LinkIcon color="disabled" fontSize="small" />
                  {/*</Link>*/}
                </div>
                <span className="trust-block">
                  <span className="trust-block-text">@{entityOwnerInfo?.userName}</span>
                </span>
              </div>
              <div className="content-profileSettings">
                <Stack gap={2}>
                  <div className="content-profile-imageContainer">
                    <div
                      onClick={() =>
                        showPreview({
                          contentType: 'Avatar',
                          entityId: entityOwnerInfo.entityOwnerId,
                          entityType: "User's",
                          mediaUrl: entityOwnerInfo?.avatarUrl || UserNoAvatar,
                        } as IMediaProps)
                      }
                      className="overlay"
                      style={{ width: '100%', height: '100%' }}
                    >
                      <ZoomInIcon sx={{ fontSize: 56 }} />
                    </div>
                    <img alt="Avatar of user" src={entityOwnerInfo?.avatarUrl || UserNoAvatarPreview} />
                    {/*<div className="content-profile-levelAura"></div>*/}
                    {/*<div className="content-profile-levelAuraPlate">{entityOwnerInfo?.levelAura}</div>*/}
                  </div>
                  <Button
                    fullWidth
                    onClick={() => {
                      showUsersProfile()
                      navigate(`/ui/moderation/${tabValue}/${entityOwnerInfo?.entityOwnerId}`)
                    }}
                    variant="outlined"
                  >
                    User’s card
                  </Button>
                </Stack>
              </div>
            </div>
          )}

          {isShowEntityInfo && (
            <List className="content-violationInfo">
              <ListItem className="content-violationInfo-item">
                <ListItemText
                  secondary={
                    <>
                      <div className="content-violationInfo-user">
                        {entityOwnerInfo?.userName && (
                          <Typography color="text.primary" variant="subtitle2">
                            @{entityOwnerInfo?.userName}
                          </Typography>
                        )}
                        <Tooltip title={entityOwnerInfo?.entityOwnerId} className="content-violationInfo-tooltipId" placement="top-end" followCursor>
                          <Typography color="text.primary" component="span" variant="body2">
                            (#{entityOwnerInfo?.entityOwnerId})
                          </Typography>
                        </Tooltip>
                      </div>
                      {entityInfo.entityType !== EntityType.AVATAR && cardType !== CardType.HIVE && (
                        <span className="trust-block">
                          <span className="trust-block-validComplaints">
                            {entityOwnerInfo?.validComplaintsReceived}/{entityOwnerInfo?.allComplaintsReceived}
                          </span>
                          <span className="trust-block-text"> valid complaints received</span>
                        </span>
                      )}
                    </>
                  }
                  className="content-violationInfo-itemDescription_bold overflow-hide"
                  primary="Author"
                  primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                />
                {!isMoodEntityType && (
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold"
                    primary="Entity created"
                    primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                    secondary={publicationDate}
                    secondaryTypographyProps={{ color: 'text.primary', variant: 'subtitle2' }}
                  />
                )}
              </ListItem>
              {isMoodEntityType && (
                <div className="content-profile">
                  <div className="content-profileSettings">
                    <div className="content-profile-imageContainer">
                      <div
                        onClick={() =>
                          showPreview({
                            contentType: 'Avatar',
                            entityId: entityOwnerInfo.entityOwnerId,
                            entityType: "User's",
                            mediaUrl: entityOwnerInfo?.avatarUrl || UserNoAvatar,
                          } as IMediaProps)
                        }
                        className="overlay"
                        style={{ width: '100%', height: '100%' }}
                      >
                        <ZoomInIcon sx={{ fontSize: 56 }} />
                      </div>
                      <img alt="Avatar of user" src={entityOwnerInfo?.avatarUrl || UserNoAvatarPreview} />
                    </div>
                  </div>
                </div>
              )}
              {!isMoodEntityType && (
                <ListItem className="content-violationInfo-item">
                  <Stack width="50%">
                    <ListItemText
                      secondaryTypographyProps={{
                        color: `${location === 'Not specified' ? 'text.secondary' : 'text.primary'}`,
                        variant: 'subtitle2',
                      }}
                      className="content-violationInfo-itemDescription_bold"
                      primary="Location"
                      primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                      secondary={location}
                    />
                    {isShowEntityDetails && (
                      <Button onClick={() => showDetails(entityInfo)} sx={{ width: 'max-content' }} variant="outlined">
                        Entity details
                      </Button>
                    )}
                  </Stack>
                  <ListItemText
                    secondary={
                      <span className="content-chipsContainer">
                        {entityInfo?.passions.length > 0 ? (
                          entityInfo?.passions?.map((passion: string, index: number) => (
                            <Chip key={passion + index} label={passion} size="small" variant="filled" />
                          ))
                        ) : (
                          <Typography color="text.secondary" variant="subtitle2">
                            Not specified
                          </Typography>
                        )}
                      </span>
                    }
                    className="content-violationInfo-itemDescription"
                    primary="Passions"
                    primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                  />
                </ListItem>
              )}
            </List>
          )}
          {entityInfo && entityInfo.entityType === EntityType.COMMENT && (
            <div className="contentViolation--commentContainer">
              <List className="content-violationInfo">
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    secondary={
                      <div className="content-violationInfo-commentContainer">
                        <Typography color="text.secondary" variant="body2">
                          {entityInfo.textData || 'Comment not found'}
                        </Typography>
                      </div>
                    }
                    className="content-violationInfo-itemDescription_bold overflow-hide"
                    primary="Comment"
                    primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                  />
                </ListItem>
              </List>
              <List className="content-violationInfo">
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    secondary={
                      <>
                        <div className="content-violationInfo-user">
                          {entityOwnerInfo?.userName && (
                            <Typography color="text.primary" variant="subtitle2">
                              @{entityOwnerInfo?.userName}
                            </Typography>
                          )}
                          <Tooltip
                            title={entityOwnerInfo?.entityOwnerId}
                            className="content-violationInfo-tooltipId"
                            placement="top-end"
                            followCursor
                          >
                            <Typography color="text.primary" component="span" variant="body2">
                              (#{entityOwnerInfo?.entityOwnerId})
                            </Typography>
                          </Tooltip>
                        </div>
                        {cardType !== CardType.HIVE && (
                          <span className="trust-block">
                            <span className="trust-block-validComplaints">
                              {entityOwnerInfo?.validComplaintsReceived}/{entityOwnerInfo?.allComplaintsReceived}
                            </span>
                            <span className="trust-block-text"> valid complaints received</span>
                          </span>
                        )}
                      </>
                    }
                    className="content-violationInfo-itemDescription_bold overflow-hide"
                    primary="Author"
                    primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                  />
                </ListItem>
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold"
                    primary="Entity created"
                    primaryTypographyProps={{ color: 'text.secondary', variant: 'body2' }}
                    secondary={publicationDate}
                    secondaryTypographyProps={{ color: 'text.primary', variant: 'subtitle2' }}
                  />
                </ListItem>
              </List>
            </div>
          )}
          {profileInfo && (
            <div className="content-profile">
              <div className="">
                <div className="content-profileInfo-userName">
                  <Typography variant="avatarInitials">
                    {profileInfo?.firstName}&nbsp;{profileInfo?.lastName}
                  </Typography>
                  {/*<Link to={`/ui/users/user/${profileInfo?.profileId}`} target="_blank">*/}
                  <LinkIcon color="disabled" fontSize="small" />
                  {/*</Link>*/}
                </div>
                <span className="trust-block">
                  <span className="trust-block-text">@{profileInfo?.username}</span>
                  <span className="trust-block-separator">|</span>
                  <span className="trust-block-validComplaints">
                    {profileInfo?.validComplaintsReceived}/{profileInfo?.allComplaintsReceived}
                  </span>
                  <span className="trust-block-text"> valid complaints received</span>
                </span>
              </div>
              <div className="content-profileSettings">
                <Stack gap={2}>
                  <div className="content-profile-imageContainer">
                    {/* todo сделать аватар дефолтный, если урл пустой. и "сломанный" если ошибка при загрузке */}
                    <div
                      onClick={() =>
                        showPreview({
                          contentType: 'Avatar',
                          entityId: profileInfo.profileId,
                          entityType: "User's",
                          mediaUrl: profileInfo?.avatarUrl || UserNoAvatar,
                        } as IMediaProps)
                      }
                      className="overlay"
                      style={{ width: '100%', height: '100%' }}
                    >
                      <ZoomInIcon sx={{ fontSize: 56 }} />
                    </div>
                    <img alt="usersAvatar" src={profileInfo?.avatarUrl || UserNoAvatarPreview} />
                    {/*<div className="content-profile-levelAura"></div>*/}
                    {/*<div className="content-profile-levelAuraPlate">{profileInfo?.levelAura}</div>*/}
                  </div>
                  <Button
                    fullWidth
                    onClick={() => {
                      showUsersProfile()
                      navigate(`/ui/moderation/${tabValue}/${profileInfo?.profileId}`)
                    }}
                    className="button"
                    variant="outlined"
                  >
                    User’s card
                  </Button>
                </Stack>
                {/*{isManualMode && (*/}
                <div className="content-profile-info">
                  <div className="content-profileLocation">
                    <Typography className="content-profileTitle" color="text.secondary" variant="body2">
                      Location
                    </Typography>
                    <Typography color={location === 'Not specified' ? 'text.secondary' : 'text.primary'} variant="subtitle2">
                      {location}
                    </Typography>
                  </div>
                  <div className="content-profilePassions">
                    <Typography className="content-profileTitle" color="text.secondary" variant="body2">
                      Passions
                    </Typography>
                    <div className="content-chipsContainer">
                      {profileInfo?.passions?.map((element: TAny) => <Chip label={element} size="small" variant="filled" />)}
                      {!profileInfo?.passions ||
                        (profileInfo?.passions.length < 1 && (
                          <Typography color="text.secondary" variant="subtitle2">
                            Not specified
                          </Typography>
                        ))}
                    </div>
                  </div>
                </div>
                {/*)}*/}
              </div>
            </div>
          )}
        </div>
      </div>
      {isShowButton && (
        <div className="content-violationButtonsGroup">
          <div
            className={isFullHeightCard ? 'content-violationButton' : 'content-violationButton shadow'}
            style={{ width: entityInfo?.entityType === 'COMMENT' ? '100%' : '45%' }}
          >
            <Stack
              alignItems="center"
              className={'content-violationButton__custom-button'}
              direction="row"
              gap={0.5}
              onClick={handleToggleHeight}
              pt={0.5}
            >
              <Typography variant="bodyLarge">{isFullHeightCard ? 'Show less' : 'Show more'}</Typography>
              {isFullHeightCard ? <ChevronUp /> : <ChevronDownIcon />}
            </Stack>
          </div>
          <div className={isFullHeightCard ? '' : 'shadow'} style={{ width: entityInfo?.entityType === 'COMMENT' ? '100%' : '30%' }} />
        </div>
      )}
    </>
  )
}

export default ModerationCardContent
