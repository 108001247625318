import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import EditIcon from '@admin/assets/img/EditIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'

import type { TAny } from '@yzzy/types'

/**
 * Props for the RenderActionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler for the context button.
 */
interface IRenderActionsCellProps {
  onClick: (event: MouseEvent<HTMLButtonElement>, data: TAny) => void
  props: GridRenderCellParams
}

/**
 * Renders actions cell in a data grid.
 */
export const RenderActionsCell = ({ onClick, props }: IRenderActionsCellProps) => {
  const { row } = props

  if (row.salesTaxRuleId) {
    return (
      <Tooltip title="Edit rule">
        <IconButton color="default" onClick={(event) => onClick(event, row)} size="small">
          <EditIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return null
}
