import { createSvgIcon } from '@mui/material'

const ArrowUpIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6011 2.89886C12.4417 2.73946 12.2255 2.6499 12.0001 2.6499C11.7746 2.6499 11.5584 2.73946 11.399 2.89886L5.32904 8.96886C4.99709 9.30081 4.99709 9.839 5.32904 10.1709C5.66098 10.5029 6.19917 10.5029 6.53112 10.1709L11.1499 5.55216V20.4998C11.1499 20.9693 11.5305 21.3498 11.9999 21.3498C12.4693 21.3498 12.8499 20.9693 12.8499 20.4998V5.55181L17.469 10.1709C17.801 10.5029 18.3392 10.5029 18.6711 10.1709C19.0031 9.839 19.0031 9.30081 18.6711 8.96886L12.6011 2.89886Z" />
  </svg>,
  'ArrowUp',
)

export default ArrowUpIcon
