import * as yup from 'yup'

const getValidationSchema = () => {
  return yup.object().shape({
    id: yup.string().nullable(),
    title: yup
      .string()
      .required('The name is too short. It should be as a minimum 3 symbols')
      .min(3, 'The name is too short. It should be as a minimum 3 symbols')
      .max(50, '')
      .matches(/^(?:[a-zA-Z0-9_:,\. ]*)$/, 'Invalid name. Only letters, numbers, underscores, dots, colons and commas are allowed')
      .matches(/^[a-zA-Z0-9]/, 'Please, start from letter or number'),
    genders: yup
      .array(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
        }),
      )
      .nullable('null')
      .test('oneOrMore', '', (value, context) => {
        return !!value?.length || !!context.parent.minAge || !!context.parent.maxAge || !!context.parent.locations?.length
      }),
    locations: yup
      .array()
      .nullable('')
      .test('oneOrMore', '', (value, context) => {
        return !!value?.length || !!context.parent.minAge || !!context.parent.maxAge || !!context.parent.genders?.length
      }),
    maxAge: yup
      .number()
      .nullable('')
      .typeError('')
      .integer('')
      .positive('')
      .test('notLess', '', (value, context) => (value && context.parent.minAge ? value >= context.parent.minAge : true))
      .test('oneOrMore', '', (value, context) => {
        return !!value || !!context.parent.minAge || !!context.parent.genders?.length || !!context.parent.locations?.length
      })
      .min(13, '')
      .max(150, ''),
    minAge: yup
      .number()
      .nullable('')
      .typeError('')
      .integer('')
      .positive('')
      .test('notMore', 'Invalid age range', (value, context) => (value && context.parent.maxAge ? value <= context.parent.maxAge : true))
      .test('oneOrMore', '', (value, context) => {
        return !!value || !!context.parent.maxAge || !!context.parent.genders?.length || !!context.parent.locations?.length
      })
      .min(13, '')
      .max(150, ''),
  })
}

export default getValidationSchema
