import { HTMLAttributes, Key, ReactNode } from 'react'

import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import Divider from '@admin/components/shared/Divider/Divider'
import Typography from '@admin/components/shared/Typography/Typography'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

/**
 * Render linked ID options for a list item.
 *
 * @param {HTMLAttributes<HTMLLIElement>} props - HTML attributes for the list item.
 * @param {{
 *   value: string;
 *   providerName: string;
 *   productId: string;
 *   name: string;
 *   price: string;
 * }} option - The option data to be displayed.
 * @param {string} inputValue - The current input value for highlighting.
 * @returns {ReactNode} React element representing the linked ID option.
 */
export const renderLinkIdOptions = (
  props: HTMLAttributes<HTMLLIElement>,
  option: {
    name: string
    providerName: string
    price: string
    productId: string
    value: string
  },
  inputValue: string,
): ReactNode => {
  const matches = match(option.value, inputValue, { insideWords: true })
  const parts = parse(option.value, matches)

  return (
    <div key={`${props?.id}-${option.value}`}>
      <li {...props}>
        <div className="LinkIdOption-content">
          <Typography className="LinkIdOption-content__title" variant="body2">
            {parts.map((part, index: Key) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </Typography>

          <div className="LinkIdOption-content__info">
            <div className="LinkIdOption-content__info-block">
              <div className="LinkIdOption-content__info-item">
                <Typography color="text.secondary" variant="inputLabel">
                  Provider:
                </Typography>
                <Typography variant="inputLabel">{option.providerName}</Typography>
              </div>
              <div className="LinkIdOption-content__info-item">
                <Typography color="text.secondary" variant="inputLabel">
                  Product ID:
                </Typography>
                <Typography variant="inputLabel">{option.productId}</Typography>
              </div>
            </div>
            <div className="LinkIdOption-content__info-block">
              <div className="LinkIdOption-content__info-item">
                <Typography color="text.secondary" variant="inputLabel">
                  Reference name:
                </Typography>
                <Typography variant="inputLabel">{option.name}</Typography>
              </div>
              <div className="LinkIdOption-content__info-item">
                <Typography color="text.secondary" variant="inputLabel">
                  Price:
                </Typography>
                <Typography variant="inputLabel">{currencyFormatter(Number(option.price), 'en-US', 'USD', true)}</Typography>
              </div>
            </div>
          </div>
        </div>
      </li>
      <Divider orientation="horizontal" flexItem />
    </div>
  )
}
