const GenderIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M18.4203 1.5769C17.9509 1.5769 17.5703 1.95746 17.5703 2.4269C17.5703 2.89635 17.9509 3.2769 18.4203 3.2769H19.3684L17.7475 4.89777C16.7765 4.07128 15.5179 3.57251 14.1429 3.57251C11.8263 3.57251 9.8403 4.98823 9.00297 7.00171L9.00032 7.00171C5.92725 7.00171 3.43604 9.49292 3.43604 12.566C3.43604 15.3506 5.48156 17.6575 8.15199 18.066C8.15041 18.0872 8.14961 18.1085 8.14961 18.13V19.0014H7.27843C6.80899 19.0014 6.42843 19.382 6.42843 19.8514C6.42843 20.3209 6.80899 20.7014 7.27843 20.7014H8.14961V21.5729C8.14961 22.0423 8.53017 22.4229 8.99961 22.4229C9.46905 22.4229 9.84961 22.0423 9.84961 21.5729V20.7014H10.7213C11.1907 20.7014 11.5713 20.3209 11.5713 19.8514C11.5713 19.382 11.1907 19.0014 10.7213 19.0014H9.84961V18.13C9.84961 18.1086 9.84881 18.0873 9.84725 18.0662C11.7929 17.7691 13.407 16.4643 14.1403 14.7011L14.1429 14.7011C17.216 14.7011 19.7072 12.2099 19.7072 9.1368C19.7072 8.05346 19.3976 7.04244 18.8621 6.18737L20.5703 4.47912V5.4269C20.5703 5.89635 20.9509 6.2769 21.4203 6.2769C21.8898 6.2769 22.2703 5.89635 22.2703 5.4269V2.4269C22.2703 1.95746 21.8898 1.5769 21.4203 1.5769H18.4203ZM10.7515 7.28288C11.4078 6.08484 12.6805 5.27251 14.1429 5.27251C16.2771 5.27251 18.0072 7.00261 18.0072 9.1368C18.0072 11.1337 16.4925 12.7768 14.5494 12.98C14.5595 12.8433 14.5646 12.7052 14.5646 12.566C14.5646 10.1048 12.9666 8.01674 10.7515 7.28288ZM12.3917 14.4199C11.7354 15.618 10.4627 16.4303 9.00032 16.4303C6.86613 16.4303 5.13604 14.7002 5.13604 12.566C5.13604 10.5691 6.65068 8.926 8.59378 8.72284C8.58373 8.85951 8.57861 8.99756 8.57861 9.1368C8.57861 11.598 10.1766 13.6861 12.3917 14.4199ZM12.8586 12.7825C11.3558 12.2531 10.2786 10.8208 10.2786 9.1368C10.2786 9.06412 10.2806 8.99192 10.2846 8.92024C11.7875 9.44964 12.8646 10.882 12.8646 12.566C12.8646 12.6387 12.8626 12.7109 12.8586 12.7825Z"
      fill="#BFC6E2"
      fillRule="evenodd"
    />
  </svg>
)

export default GenderIcon
