import { Chip as ChipBase, useTheme } from '@mui/material'
import { ChipProps } from '@mui/material/Chip/Chip'

interface IChipProps extends ChipProps {
  active?: boolean
  label?: string
  primary?: boolean
}

const Chip = (props: IChipProps) => {
  const theme = useTheme()

  return (
    <ChipBase
      {...props}
      sx={{
        color: theme.palette.common.black,
        ...props.sx,
      }}
      variant={(props.variant ?? props.active) ? 'filled' : 'outlined'}
    />
  )
}

export default Chip
