import { GridRenderCellParams } from '@mui/x-data-grid'

import LockIcon from '@admin/assets/img/LockIcon'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

import { possibleOperationStatuses } from '../constants'

/**
 * Props for the RenderStatusCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderStatusCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a status cell in a data grid.
 */
export const RenderStatusCell = ({ props }: IRenderStatusCellProps) => {
  const { row } = props

  if (!row.id) {
    return null
  }

  const isOperationSuspended = row.isTransferSuspended
  const isSuspendedTransaction = row.suspended && !row.userFriendlyOperationId
  const currentStatus = possibleOperationStatuses.find((element) => row.status?.toLowerCase() === element.status?.toLowerCase())
  const displayedStatus = currentStatus?.title ?? 'Unknown'

  const getTransactionTitle = () => {
    return isSuspendedTransaction ? 'Transaction suspended' : 'Operation suspended'
  }

  return (
    <Stack maxWidth={1} alignItems="center" direction="row" flexWrap="nowrap">
      <Tooltip title={row.status.replace(row.status, displayedStatus)} placement="top" style={{ display: 'inline-grid' }} disableInteractive>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          color={currentStatus?.color}
          variant="chip"
        >
          {row.status.replace(row.status, displayedStatus)}
        </Typography>
      </Tooltip>
      {(isOperationSuspended || row.suspended) && (
        <Tooltip title={getTransactionTitle()} placement="top" disableInteractive>
          <LockIcon color="warning" sx={{ fontSize: 16, ml: 0.5, verticalAlign: 'sub' }} />
        </Tooltip>
      )}
    </Stack>
  )
}
