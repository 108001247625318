import './index.scss'

import React from 'react'

import Button from '@admin/components/shared/Button/Button'

interface IProps {
  closeModal(): void
  onClose(): void
}

const BannerDetailsCloseModal = ({ closeModal, onClose }: IProps) => {
  return (
    <div className="closeBannerDetailsModal">
      <div className="closeBannerDetailsModal-header">Exit without saving changes?</div>
      <div className="closeBannerDetailsModal-content">All unsaved data will be lost</div>
      <div className="closeBannerDetailsModal-footer">
        <div className="closeBannerDetailsModal-footer-buttons">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={() => {
              closeModal()
              onClose()
            }}
            color="warning"
            variant="contained"
          >
            Exit without saving
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BannerDetailsCloseModal
