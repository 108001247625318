import './index.scss'

import { useCallback, useMemo } from 'react'

import { format } from 'date-fns'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import CalendarIcon from '@admin/assets/img/CalendarIcon'
import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import CloseIcon from '@admin/assets/img/CloseIcon'
import CommentIcon from '@admin/assets/img/CommentIcon'
import EyeClosedFilledIcon from '@admin/assets/img/EyeClosedFilledIcon'
import EyeClosedIcon from '@admin/assets/img/EyeClosedIcon'
import FlashIcon from '@admin/assets/img/FlashIcon'
import LikeIcon from '@admin/assets/img/LikeIcon'
import LocationIcon from '@admin/assets/img/LocationIcon'
import MoneyIcon from '@admin/assets/img/MoneyIcon'
import StarFilledIcon from '@admin/assets/img/StarFilledIcon'
import StarIcon from '@admin/assets/img/StarIcon'
import Users3Icon from '@admin/assets/img/Users3Icon'
import WorldSendIcon from '@admin/assets/img/WorldSendIcon'
import ContentDisplayInfoBlock from '@admin/components/ContentDisplay/ContentDisplayInfoBlock/ContentDisplayInfoBlock'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Button from '@admin/components/shared/Button/Button'
import Chip from '@admin/components/shared/Chip/Chip'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ToggleButton from '@admin/components/shared/ToggleButton/ToggleButton'
import ToggleButtonGroup from '@admin/components/shared/ToggleButtonGroup/ToggleButtonGroup'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import useExpandableDescription from '@admin/hooks/useExpandableDescription'
import { selectUserPermissions } from '@admin/store/authSlice'
import { fetchUpdateContentStatus } from '@admin/store/contentDisplaySlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'
import { EntityStatus, IMediaItem, Permissions } from '@admin/types/commonTypes'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import EventCard from './EventCard'

const Event = (props: TAny) => {
  const data = props.data
  const publishDate = data?.publishedAt ? new Date(data.publishedAt) : null
  const userInfo = data.userInfoDto ?? {}
  const isEntityStatusActive = data.entityStatus === EntityStatus.ACTIVE

  const dispatch = useAppDispatch()
  const userPermissions = useAppSelector(selectUserPermissions)
  const showTopSearchSelectionButton = checkPermissions(userPermissions, [Permissions.WORLD_MANAGEMENT_WORLD_SELECTION])
  const showMarkHideButtonGroup = checkPermissions(userPermissions, [Permissions.CONTENT_DISPLAY_MARK_HIDE_PUBLICATIONS])
  const { isExpanded, renderedDescription, toggleExpansion } = useExpandableDescription(data.description)

  const setContentStatus = useCallback(async (entityId: string, recommenderStatus: null | string, status: string) => {
    const result = await dispatch(
      fetchUpdateContentStatus({
        entityId: entityId,
        entityType: 'EVENT',
        status: recommenderStatus !== status ? status : null,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Event #${data.entityId} has been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}`, {
        variant: 'success' as VariantType,
      })
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(`Event #${data.entityId} hasn't been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}, try again later`, {
        variant: 'error' as VariantType,
      })
    }
  }, [])

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`

    return 'Location'
  }, [data.locationInfo])

  const locationText = useMemo(() => {
    const { locationCity, locationCountry } = data.locationInfo
    const parts = [locationCity, locationCountry].filter(Boolean)

    return parts.length > 0 ? parts.join(', ') : 'Not specified'
  }, [data.locationInfo])

  return (
    <div className={'event--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="event--containerHeader">
        <div className="event--eventName">
          <div className="event--eventId">Event #{data.entityId}</div>
          {publishDate && (
            <div className="event--eventPublishDate">
              Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
            </div>
          )}
        </div>
        {props.modalClose ? (
          <div className="event--eventActions">
            <IconButton onClick={() => props.modalClose()} size="small" sx={{ margin: '-4px -12px 0 0' }}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className="event--eventActions">
            {showTopSearchSelectionButton && (
              <Button
                onClick={() => props.linkCollections({ entityId: data.entityId, entityType: data.entityType })}
                size="small"
                startIcon={<WorldSendIcon />}
                variant="outlined"
                disabled
              >
                Top Search
              </Button>
            )}
            {showMarkHideButtonGroup && (
              <Tooltip title={!isEntityStatusActive ? 'Unavailable for entities in this status' : ''} placement="top-end" followCursor>
                <ToggleButtonGroup className="event--actionButtonsGroup" disabled={!isEntityStatusActive} value={data.recommenderStatus} exclusive>
                  <ToggleButton
                    className="event--actionButton starred"
                    onClick={(_event, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                    size="small"
                    value="STARRED"
                  >
                    {data.recommenderStatus === 'STARRED' ? <StarFilledIcon /> : <StarIcon />}
                  </ToggleButton>
                  <ToggleButton
                    className="event--actionButton hidden"
                    onClick={(_event, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                    size="small"
                    value="HIDDEN"
                  >
                    {data.recommenderStatus === 'HIDDEN' ? <EyeClosedFilledIcon /> : <EyeClosedIcon />}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="event--content">
        <div className="eventContent--card">
          <EventCard
            showMedia={(mediaUrl: string, mainEntity: IMediaItem, mediaType?: string, orderedMediaList?: IMediaItem[]) => {
              if (data.orderedMediaList) {
                props.showMedia(data.entityId, data.entityType, mediaUrl, mainEntity, orderedMediaList, mediaType)
              } else {
                props.showMedia(data.entityId, data.entityType, mediaUrl)
              }
            }}
            data={data}
          />
        </div>
        <div className="eventContent--descriptionContainer">
          {Object.keys(userInfo).length > 0 && (
            <AvatarItem
              onClick={() => props.openUser(userInfo.userId || '1')}
              primaryText={`${userInfo.firstName || ''} ${userInfo.lastName || ''}`}
              src={userInfo.avatarUrl}
              subText={`@${userInfo.username || ''}`}
            />
          )}
          <div className="eventContent--title">{data.title}</div>
          <div className="eventContent--description">
            <Typography className="text" color="text.secondary" component="span" variant="body2">
              {renderedDescription}{' '}
            </Typography>
            {data.description && data.description.length > 250 && (
              <Typography
                onClick={(event) => {
                  event.stopPropagation()
                  toggleExpansion()
                }}
                className="trigger"
                component="span"
              >
                <Typography component="span" variant="body2">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Typography>
                {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
              </Typography>
            )}
          </div>
          <div className="eventContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
            <div>
              <CommentIcon /> {data.commentsCounter}
            </div>
          </div>
        </div>
        <div className="eventContent--passions">
          <div className="eventContent--passionsHeader">Passions</div>
          <div className="eventContent--passionsChips">
            {data.passions.length > 0 ? (
              data.passions.map((element: TAny) => <Chip key={element} label={element} size="small" variant="filled" />)
            ) : (
              <Typography color={colors.outlinedBorder} variant="body2">
                Not specified
              </Typography>
            )}
          </div>
        </div>
        <div className="eventContent--terms">
          {data.entityStatus && (
            <ContentDisplayInfoBlock icon={<FlashIcon />} primary={startCase(data.entityStatus.toLowerCase())} secondary="Status" />
          )}
          <ContentDisplayInfoBlock icon={<MoneyIcon />} primary="$0" secondary="Free" />
          {data.startedAt && (
            <ContentDisplayInfoBlock
              icon={<CalendarIcon />}
              primary={`${dayjs(data.startedAt).format('MM/DD/YYYY, hh:mm A')} ${data.finishedAt ? '–' : ''}`}
              secondary={data.finishedAt ? dayjs(data.finishedAt).format('MM/DD/YYYY, hh:mm A') : 'Start date'}
            />
          )}
          {data.locationInfo && <ContentDisplayInfoBlock icon={<LocationIcon />} primary={locationText} secondary={locationHeader} withTooltip />}
          {data.participantsCounter > 0 && (
            <ContentDisplayInfoBlock icon={<Users3Icon />} primary={data.participantsCounter} secondary="Participants" />
          )}
        </div>
      </div>
    </div>
  )
}

export default Event
