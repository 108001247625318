import './index.scss'

import { Skeleton as SkeletonMUI } from '@mui/material'
import { SkeletonProps } from '@mui/material/Skeleton/Skeleton'

const Skeleton = (props: SkeletonProps) => {
  return <SkeletonMUI {...props} />
}

export default Skeleton
