import { useHover } from '@uidotdev/usehooks'
import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemIcon from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

import type { TAny } from '@yzzy/types'

interface IUserProfileInfoBlockProps {
  hasEllipsis?: boolean
  icon?: TAny
  primary: TAny
  secondary: TAny
  withCopy?: boolean
}

const UserProfileDataBlock = ({ hasEllipsis = true, icon, primary, secondary, withCopy }: IUserProfileInfoBlockProps) => {
  const [reference, hovering] = useHover<HTMLDivElement>()

  const copyId = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation()

    if (!secondary) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      })

      return
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      })

      if (navigator.clipboard) {
        return navigator.clipboard.writeText(secondary)
      }
    }
  }

  return (
    <div className="data-block" ref={reference}>
      <ListItem
        secondaryAction={
          withCopy &&
          hovering && (
            <Tooltip title="Copy" placement="top-end" followCursor>
              <IconButton color="default" edge="end" onClick={(event) => copyId(event)} size="small">
                <CopyIcon />
              </IconButton>
            </Tooltip>
          )
        }
        sx={{ p: '6px 48px 6px 0' }}
      >
        {icon && (
          <ListItemIcon
            sx={{
              minWidth: 'auto',
              alignSelf: 'self-start',
              mr: 2,
              mt: '18px',
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography color="text.secondary" variant="body2">
              {primary}
            </Typography>
          }
          secondary={
            <Typography
              sx={{
                overflow: hasEllipsis ? 'hidden' : 'visible',
                textOverflow: hasEllipsis ? 'ellipsis' : 'clip',
                whiteSpace: hasEllipsis ? 'nowrap' : 'normal',
              }}
              color="text.primary"
              variant="subtitle1"
            >
              {secondary}
            </Typography>
          }
          disableTypography
        />
      </ListItem>
    </div>
  )
}

export default UserProfileDataBlock
