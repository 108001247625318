const CalendarExpiredIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 9.15107C7 8.68163 7.38056 8.30107 7.85 8.30107H16.15C16.6194 8.30107 17 8.68163 17 9.15107C17 9.62052 16.6194 10.0011 16.15 10.0011H7.85C7.38056 10.0011 7 9.62052 7 9.15107Z" />
    <path d="M14.8358 14.8019C15.1677 14.47 15.7059 14.47 16.0379 14.8019L17.2106 15.9746L18.3832 14.8019C18.7152 14.47 19.2534 14.47 19.5853 14.8019C19.9173 15.1339 19.9173 15.672 19.5853 16.004L18.4126 17.1767L19.5853 18.3493C19.9173 18.6813 19.9173 19.2195 19.5853 19.5514C19.2534 19.8834 18.7152 19.8834 18.3832 19.5514L17.2106 18.3788L16.0379 19.5514C15.7059 19.8834 15.1678 19.8834 14.8358 19.5514C14.5039 19.2195 14.5039 18.6813 14.8358 18.3493L16.0085 17.1767L14.8358 16.004C14.5038 15.672 14.5038 15.1339 14.8358 14.8019Z" />
    <path
      clipRule="evenodd"
      d="M8.1499 3.42628C6.53962 3.71045 5.25517 4.24321 4.29127 5.15355C2.6385 6.7145 2.1499 9.1695 2.1499 12.5007C2.1499 15.8319 2.6385 18.2869 4.29127 19.8478C5.92798 21.3936 8.48894 21.8507 11.9999 21.8507C12.4345 21.8507 12.8542 21.8437 13.2593 21.8286C14.3261 22.7364 15.7087 23.2841 17.2192 23.2841C20.5957 23.2841 23.3329 20.547 23.3329 17.1705C23.3329 15.6445 22.7738 14.2491 21.8494 13.178C21.8497 13.1192 21.8499 13.0601 21.8499 13.0007C21.8499 9.43175 21.3651 6.85554 19.7229 5.22993C18.7564 4.27318 17.4654 3.72196 15.8499 3.43059V2.9999C15.8499 2.53046 15.4693 2.1499 14.9999 2.1499C14.5305 2.1499 14.1499 2.53046 14.1499 2.9999V3.22218C13.4772 3.1721 12.7605 3.15068 11.9999 3.15068C11.2387 3.15068 10.5221 3.17217 9.8499 3.22158V2.9999C9.8499 2.53046 9.46934 2.1499 8.9999 2.1499C8.53046 2.1499 8.1499 2.53046 8.1499 2.9999V3.42628ZM11.1055 17.1705C11.1055 18.2525 11.3865 19.2688 11.8797 20.1505C8.46621 20.1394 6.55897 19.6512 5.45853 18.6119C4.3613 17.5756 3.8499 15.7806 3.8499 12.5007C3.8499 9.22076 4.3613 7.42575 5.45853 6.38948C6.06829 5.8136 6.92575 5.40694 8.1499 5.15688V5.9999C8.1499 6.46934 8.53046 6.8499 8.9999 6.8499C9.46934 6.8499 9.8499 6.46934 9.8499 5.9999V4.92733C10.492 4.87593 11.2058 4.85068 11.9999 4.85068C12.7948 4.85068 13.5085 4.87601 14.1499 4.92811V5.9999C14.1499 6.46934 14.5305 6.8499 14.9999 6.8499C15.4693 6.8499 15.8499 6.46934 15.8499 5.9999V5.16269C17.0682 5.41848 17.9197 5.83698 18.5269 6.4381C19.5015 7.40282 20.0174 9.00771 20.1274 11.7915C19.2625 11.3229 18.2719 11.0568 17.2192 11.0568C13.8427 11.0568 11.1055 13.794 11.1055 17.1705ZM21.6329 17.1705C21.6329 19.6081 19.6568 21.5841 17.2192 21.5841C14.7815 21.5841 12.8055 19.6081 12.8055 17.1705C12.8055 14.7328 14.7815 12.7568 17.2192 12.7568C19.6568 12.7568 21.6329 14.7328 21.6329 17.1705Z"
      fillRule="evenodd"
    />
  </svg>
)

export default CalendarExpiredIcon
