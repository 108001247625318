import * as yup from 'yup'

export const SetUpPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password does not meet the above-mentioned conditions')
    .matches(
      /^(?:[0-9a-zA-Z!@#&()\-\[{}\]:;',?\/*~$^+=<>]*)$/,
      `Password must contain only Latin letters A-Z, a-z; numbers \n0-9 and special symbols &$@[({<>})]!?:;',~^*#+-=/`,
    )
    .max(20, 'Max limit of 20 characters is exceeded')
    .matches(
      /(?=.*[0-9])(?=.*[!@#&()\-\[{}\]:;',?\/*~$^+=<>])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#&()\-\[{}\]:;',?\/*~$^+=<>]{8,}/,
      'Password does not meet the above-mentioned conditions',
    )
    .min(8, 'Password does not meet the above-mentioned conditions'),
  repeatPassword: yup.string().oneOf([yup.ref('password')], 'Passwords don’t match'),
})
