/**
 * Formats a numeric value as currency using the specified locale and currency.
 *
 * @example in DataGrid column
 * valueFormatter: (value) => {
 *  return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
 * },
 *
 *
 * @param {number} value - The numeric value to format as currency.
 * @param {string} locale - The locale to use for formatting (e.g., 'en-US').
 * @param {string} currency - The currency code (e.g., 'USD').
 * @param {boolean} [isCent=false] - Indicates whether the value is in cents (if true, it will be divided by 100).
 * @returns {string} The formatted currency string.
 */
export const currencyFormatter = (value: number, locale: string, currency: string, isCent = false): string => {
  const currentValue = isCent ? value / 100 : value

  const formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    style: 'currency',
  })

  const formattedCurrency = formatter.format(currentValue)

  return formattedCurrency
}
