import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { USERS_API } from '@admin/shared/api/constants'
import { TStatus } from '@admin/types/commonTypes'

import { TRootState } from './store'

export interface IUserActivePaidContent {
  eventsCount: number
  lifestylesCount: number
  offersCount: number
  wishesCount: number
}

/**
 * Represents user profile data.
 *
 * @interface
 * @property {IUserProfileMainData} main - The main data of the user profile.
 * @property {IUserProfilePersonalData} personal - The personal data of the user profile.
 * @property {IUserProfilePaymentInfo} paymentInfo - The payment information of the user profile.
 */
export interface IUserProfileData {
  main: IUserProfileMainData
  paymentInfo: IUserProfilePaymentInfo
  personal: IUserProfilePersonalData
}

/**
 * Represents the main data of a user profile.
 *
 * @interface
 * @property {string} userId - The unique identifier of the user.
 * @property {string} username - The username of the user.
 * @property {string} avatarUrl - The URL of the user's avatar.
 * @property {string} levelAura - The level aura of the user.
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} status - The status of the user.
 * @property {number} profileViolationsCount - The count of profile violations for the user.
 * @property {number} contentViolationsCount - The count of content violations for the user.
 */
export interface IUserProfileMainData {
  activePaidContent?: IUserActivePaidContent
  avatarUrl: string
  contentViolationsCount: number
  firstName: string
  lastName: string
  levelAura: string
  profileViolationsCount: number
  status: string
  userId: string
  username: string
}

/**
 * Represents the payment information of a user profile.
 *
 * @interface
 * @property {number} balance - The user's balance.
 * @property {number} balancePendingEarned - The pending earned balance.
 * @property {number} balancePendingWithdrawal - The pending withdrawal balance.
 * @property {number} balanceAvailableWithdrawal - The available withdrawal balance.
 * @property {string} walletStatusCode - The status code of the wallet.
 */
export interface IUserProfilePaymentInfo {
  balance: number
  balanceAvailableWithdrawal: number
  balancePendingEarned: number
  balancePendingWithdrawal: number
  walletStatusCode: string
}

/**
 * Represents the personal data of a user profile.
 *
 * @interface
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} username - The username of the user.
 * @property {string} birthDate - The birthdate of the user.
 * @property {string} gender - The gender of the user.
 * @property {string} occupation - The occupation of the user.
 * @property {string} location - The location of the user.
 * @property {string} aboutMe - Information about the user.
 * @property {number} followersCount - The count of followers for the user.
 * @property {number} subscriptionsCount - The count of subscriptions for the user.
 * @property {string} phoneNumber - The phone number of the user.
 * @property {string} email - The email address of the user.
 * @property {string[]} passions - The list of passions or interests of the user.
 */
export interface IUserProfilePersonalData {
  aboutMe: string
  birthDate: string
  email: string
  firstName: string
  followersCount: number
  gender: string
  lastName: string
  location: string
  occupation: string
  passions: string[]
  phoneNumber: string
  subscriptionsCount: number
  username: string
}

/**
 * Represents the state of the user profile.
 *
 * @interface
 * @property {IUserProfileData} data - The user profile data.
 * @property {TStatus} status - The status of the overall user profile state.
 * @property {TStatus} deleteStatus - The status of the delete operation for the user profile.
 * @property {TStatus} restoreStatus - The status of the restore operation for the user profile.
 * @property {TStatus} banStatus - The status of the ban operation for the user profile.
 * @property {TStatus} updateCommentStatus - The status of the update comment operation for the user profile.
 */
export interface IUserProfileState {
  banStatus: TStatus
  data: IUserProfileData
  deleteStatus: TStatus
  restoreStatus: TStatus
  status: TStatus
  updateCommentStatus: TStatus
}

const initialState: IUserProfileState = {
  banStatus: 'idle',
  data: {
    main: {
      activePaidContent: {
        eventsCount: 0,
        lifestylesCount: 0,
        offersCount: 0,
        wishesCount: 0,
      },
      username: '',
      avatarUrl: '',
      contentViolationsCount: 0,
      firstName: '',
      lastName: '',
      levelAura: '',
      profileViolationsCount: 0,
      status: '',
      userId: '',
    },
    paymentInfo: {
      balance: 0,
      balanceAvailableWithdrawal: 0,
      balancePendingEarned: 0,
      balancePendingWithdrawal: 0,
      walletStatusCode: '',
    },
    personal: {
      username: '',
      aboutMe: '',
      birthDate: '',
      email: '',
      firstName: '',
      followersCount: 0,
      gender: '',
      lastName: '',
      location: '',
      occupation: '',
      passions: [],
      phoneNumber: '',
      subscriptionsCount: 0,
    },
  },
  deleteStatus: 'idle',
  restoreStatus: 'idle',
  status: 'idle',
  updateCommentStatus: 'idle',
}

export const fetchUserProfile = createAsyncThunk('userProfile/fetchUserProfile', async (profileId: string, thunkAPI) => {
  try {
    const backupProfileId = profileId ?? '4da07f22-f77c-494d-b86c-0766e070a17b'
    const response = await axios.get(USERS_API + `timusers/users/${backupProfileId}`)

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchDeleteUser = createAsyncThunk(
  'userProfile/fetchDeleteUser',
  async ({ comment, profileId }: { comment: string; profileId: string }) => {
    const response = await axios.post(USERS_API + `timusers/users/delete`, {
      comment: comment,
      profileId: profileId,
    })

    return response.data
  },
)

export const fetchBanUser = createAsyncThunk('userProfile/fetchBanUser', async ({ comment, profileId }: { comment: string; profileId: string }) => {
  const response = await axios.post(USERS_API + `timusers/users/ban`, {
    comment: comment,
    profileId: profileId,
  })

  return response.data
})

export const fetchRestoreUser = createAsyncThunk('userProfile/fetchRestoreUser', async ({ profileId }: { profileId: string }) => {
  const response = await axios.post(USERS_API + `timusers/users/restore`, {
    profileId: profileId,
  })

  return response.data
})

export const fetchUpdateComment = createAsyncThunk(
  'userProfile/fetchUpdateComment',
  async ({ comment, profileId }: { comment: string; profileId: string }) => {
    const response = await axios.patch(USERS_API + `timusers/users/deactivated/update`, {
      comment: comment,
      profileId: profileId,
    })

    return response.data
  },
)

export const userProfileSlice = createSlice({
  name: 'users',
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchDeleteUser.pending, (state) => {
        state.deleteStatus = 'loading'
      })
      .addCase(fetchDeleteUser.fulfilled, (state) => {
        state.deleteStatus = 'idle'
      })
      .addCase(fetchDeleteUser.rejected, (state) => {
        state.deleteStatus = 'idle'
      })
      .addCase(fetchRestoreUser.pending, (state) => {
        state.restoreStatus = 'loading'
      })
      .addCase(fetchRestoreUser.fulfilled, (state) => {
        state.restoreStatus = 'idle'
      })
      .addCase(fetchRestoreUser.rejected, (state) => {
        state.restoreStatus = 'idle'
      })
      .addCase(fetchBanUser.pending, (state) => {
        state.banStatus = 'loading'
      })
      .addCase(fetchBanUser.fulfilled, (state) => {
        state.banStatus = 'idle'
      })
      .addCase(fetchBanUser.rejected, (state) => {
        state.banStatus = 'idle'
      })
      .addCase(fetchUpdateComment.pending, (state) => {
        state.updateCommentStatus = 'loading'
      })
      .addCase(fetchUpdateComment.fulfilled, (state) => {
        state.updateCommentStatus = 'idle'
      })
      .addCase(fetchUpdateComment.rejected, (state) => {
        state.updateCommentStatus = 'idle'
      })
  },
  initialState,
  reducers: {},
})

export const selectUserProfile = (state: TRootState): IUserProfileData => state.userProfile.data
export const selectUserProfileStatus = (state: TRootState): TStatus => state.userProfile.status
export const selectUpdateCommentStatus = (state: TRootState): TStatus => state.userProfile.updateCommentStatus
export const selectDeleteUserStatus = (state: TRootState): TStatus => state.userProfile.deleteStatus
export const selectRestoreUserStatus = (state: TRootState): TStatus => state.userProfile.restoreStatus
export const selectBanUserStatus = (state: TRootState): TStatus => state.userProfile.banStatus

export default userProfileSlice.reducer
