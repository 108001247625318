import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import List from '@admin/components/shared/List/List'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Typography from '@admin/components/shared/Typography/Typography'

interface IChangeTicketStatusModalProps {
  moderator: null | string
  onChangeStatus?: () => void
  onClose: () => void
  open: boolean
  status: boolean
  violations: string[]
}

const ChangeTicketStatusModal = ({ moderator, onChangeStatus, onClose, open, status, violations }: IChangeTicketStatusModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">You are going to change {moderator} decision</DialogTitle>
      <DialogContent sx={{ maxHeight: '184px' }}>
        {status && (
          <>
            <Typography variant="body1">Confirm that entity has violations for:</Typography>
            <List sx={{ listStyleType: 'disc', pl: 3 }} disablePadding>
              {violations.map((violation) => (
                <ListItem key={violation} sx={{ display: 'list-item' }} disablePadding>
                  <ListItemText>{violation}</ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {!status && <Typography variant="body1">Confirm that entity has no violations</Typography>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onChangeStatus?.()
            onClose()
          }}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeTicketStatusModal
