import './index.scss'

import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { DataGridProProps, GridRowScrollEndParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDocumentTitle } from '@uidotdev/usehooks'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar, VariantType } from 'notistack'

import FilterIcon from '@admin/assets/img/FilterIcon'
import RefreshIcon from '@admin/assets/img/RefreshIcon'
import SortIcon from '@admin/assets/img/SortIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import Event from '@admin/components/ContentDisplay/Event/Event'
import ContentDisplayGridFooter from '@admin/components/ContentDisplay/Footer/ContentDisplayGridFooter'
import Lifestyle from '@admin/components/ContentDisplay/Lifestyle/Lifestyle'
import NoMoreContent from '@admin/components/ContentDisplay/NoMoreContent/NoMoreContent'
import Offer from '@admin/components/ContentDisplay/Offer/Offer'
import ContentDisplayTabs from '@admin/components/ContentDisplay/types/ContentDisplayTabs'
import Wish from '@admin/components/ContentDisplay/Wish/Wish'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import ManageLinkedCollectionsModal from '@admin/components/ManageLinkedCollections/ManageLinkedCollectionsModal'
import MediaPreviewDrawer, { IMediaProps } from '@admin/components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer'
import UserProfileModal from '@admin/components/Moderation/UserProfileModal/UserProfileModal'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import SortingDrawer from '@admin/components/SortingDrawer/SortingDrawer'
import { selectUserPermissions } from '@admin/store/authSlice'
import {
  clearContentDisplayData,
  fetchContent,
  selectContentDisplayData,
  selectContentDisplayRefreshStatus,
  selectContentDisplayStatus,
  selectContentDisplayTotalElements,
  selectEventsStatus,
  selectLifestylesStatus,
  selectOffersStatus,
  selectWishesStatus,
  setRefreshContentStatus,
} from '@admin/store/contentDisplaySlice'
import { clearFilters, fetchFilters, reverseFilters, selectFiltersCurrentData, selectFiltersData } from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { clearSortingColumns, fetchSortingColumns } from '@admin/store/sortingSlice'
import { IMediaItem, Permissions } from '@admin/types/commonTypes'
import isAbortError from '@admin/utils//isAbortError'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

const initialRowCount = 10

function ContentDisplay() {
  const dispatch = useAppDispatch()
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()

  const mounted = useRef(true)
  const fetchPromiseReference = useRef<TAny>(null)
  const tableReference = useGridApiRef()

  const [searchValue] = useState<string>('')
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState<string>('')
  const [rowCount, setRowCount] = useState(initialRowCount)

  const userPermissions = useAppSelector(selectUserPermissions)
  const loadingStatus = useAppSelector(selectContentDisplayStatus)
  const refreshStatus = useAppSelector(selectContentDisplayRefreshStatus)
  const eventsStatus = useAppSelector(selectEventsStatus)
  const wishesStatus = useAppSelector(selectWishesStatus)
  const offersStatus = useAppSelector(selectOffersStatus)
  const lifestylesStatus = useAppSelector(selectLifestylesStatus)

  // const handleChangeSearch = useCallback(
  //   (event: TAny) => {
  //     setSearchValue(event.target.value);
  //   },
  //   [setSearchValue],
  // );

  const { tabId = ContentDisplayTabs.EVENTS } = useParams<{ tabId?: ContentDisplayTabs }>()
  const isCorrectPage = useMemo(() => tabId && Object.values(ContentDisplayTabs).includes(tabId as ContentDisplayTabs), [tabId])

  const pluralTitles: Record<ContentDisplayTabs, string> = {
    [ContentDisplayTabs.EVENTS]: 'Events',
    [ContentDisplayTabs.LIFESTYLES]: 'Lifestyles',
    [ContentDisplayTabs.OFFERS]: 'Offers',
    [ContentDisplayTabs.WISHES]: 'Wishes',
  }

  useDocumentTitle(`Content display - ${pluralTitles[tabId]}`)

  const handleTabChange = async (_event: SyntheticEvent, tab: string) => {
    if (fetchPromiseReference.current) {
      if (typeof fetchPromiseReference.current?.abort === 'function') {
        fetchPromiseReference.current.abort()
      }
    }

    navigate(`/ui/content-display/${tab}`)

    setRowCount(initialRowCount)
    dispatch(clearFilters())
    dispatch(clearSortingColumns())
    dispatch(clearContentDisplayData())
  }

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [sortingDrawer, setSortingDrawer] = useState<boolean>(false)
  const [mediaPreviewDrawer, setMediaPreviewDrawer] = useState<IMediaProps>()
  const [openLinkedCollections, setOpenLinkedCollections] = useState<TAny>(null)
  const [scrollEnd, setScrollEnd] = useState(false)
  const [fetchingError, setFetchingError] = useState(false)

  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)
  const sorting = useAppSelector((state) => state.sorting.data)
  const content = useAppSelector(selectContentDisplayData)
  const totalElements = useAppSelector(selectContentDisplayTotalElements)

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const fetchContentFunction = useCallback(
    async (searchValue: string, tab: string, customRowCount?: number) => {
      try {
        const fetchPromise = dispatch(
          fetchContent({
            pageSize: customRowCount || rowCount,
            searchValue: searchValue,
            tab: tab,
          }),
        )

        fetchPromiseReference.current = fetchPromise

        setFetchingError(false)

        if (fetchPromise) {
          const result = await fetchPromise

          if (result.meta.requestStatus === 'rejected') {
            setFetchingError(true)
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          }
        }
      } catch (error) {
        if (!isAbortError(error)) {
          return setFetchingError(true)
        }
      }
    },
    [dispatch],
  )

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: TAny) => item !== null).length
  }, [filtersData])

  const initialFetchData = useCallback(async () => {
    try {
      setRowCount(10)
      dispatch(clearSortingColumns())

      dispatch(fetchSortingColumns(tabId))
      dispatch(fetchFilters(tabId))
      const fetchPromise = dispatch(
        fetchContent({
          pageSize: rowCount,
          searchValue: searchValue,
          tab: tabId,
        }),
      )

      fetchPromiseReference.current = fetchPromise
      await fetchPromise.unwrap()
    } catch (error) {
      if (!isAbortError(error)) {
        return showBoundary(error)
      }
    }
  }, [tabId, showBoundary])

  useEffect(() => {
    dispatch(clearFilters())
    dispatch(clearContentDisplayData())
    initialFetchData()

    return () => {
      if (fetchPromiseReference.current && typeof fetchPromiseReference.current?.abort === 'function') {
        fetchPromiseReference.current.abort()
      }
    }
  }, [dispatch, initialFetchData])

  const handleRefreshData = useCallback(
    async (showNotificationRefreshed?: boolean) => {
      try {
        if (fetchPromiseReference.current) {
          if (typeof fetchPromiseReference.current?.abort === 'function') {
            fetchPromiseReference.current.abort()
          }
        }
        const fetchPromise = dispatch(fetchContent({ pageSize: rowCount, searchValue: searchValue, tab: tabId }))

        fetchPromiseReference.current = fetchPromise

        if (fetchPromise) {
          const result = await fetchPromise

          if (result.meta.requestStatus === 'fulfilled' && showNotificationRefreshed) {
            enqueueSnackbar('Data has been refreshed', { variant: 'success' as VariantType })
            setFetchingError(false)
          }
          if (result.meta.requestStatus === 'rejected') {
            setFetchingError(true)
            enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          }
        }
      } catch (error) {
        if (!isAbortError(error)) {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        }
      }
    },
    [dispatch, rowCount, searchValue, tabId],
  )

  const refreshData = useCallback(async () => {
    dispatch(setRefreshContentStatus('loading'))
    await handleRefreshData(true)
  }, [dispatch, handleRefreshData])

  const repeatRequest = useCallback(async () => {
    await handleRefreshData()
  }, [handleRefreshData])

  useEffect(() => {
    return () => {
      mounted.current = true
    }
  }, [tabId])

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/content-display/${ContentDisplayTabs.EVENTS}`, { replace: false })

      return
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'))
    }
  }, [])

  const handleOpenUserProfileModal = (userId: string) => {
    if (checkPermissions(userPermissions, [Permissions.USERS_CARD_VIEW_USERS_CARDS])) {
      setIsShowUserProfileModal(userId)
    } else return
  }

  const loadServerRows = useCallback(
    async (newRowLength: number) => {
      setRowCount(newRowLength)

      fetchPromiseReference.current = dispatch(
        fetchContent({
          pageSize: newRowLength,
          searchValue: searchValue,
          tab: tabId,
        }),
      )
    },
    [tabId],
  )

  const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = async (parameters: GridRowScrollEndParams) => {
    if (rowCount < totalElements && parameters.viewportPageSize >= 0 && loadingStatus === 'idle') {
      await loadServerRows(parameters.visibleRowsCount + 10)
    } else if (loadingStatus === 'idle' && rowCount > totalElements) {
      setScrollEnd(true)
    }
  }

  const resetScrollEnd = () => {
    setScrollEnd(false)
  }

  useEffect(() => {
    resetScrollEnd()
  }, [tabId, filtersData, refreshData])

  const tableScrollTop = useCallback(() => {
    tableReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const resetFilters = useCallback(
    async (isNotClearFilters?: boolean) => {
      tableScrollTop()
      setRowCount(initialRowCount)

      if (!isNotClearFilters) {
        dispatch(clearFilters())
      }

      await dispatch(fetchContent({ pageSize: initialRowCount, searchValue: searchValue, tab: tabId }))
    },
    [dispatch, searchValue, tabId, tableScrollTop],
  )

  const showMediaDrawer = (
    entityId: string,
    entityType: string,
    mediaUrl: string,
    mainEntity: IMediaItem,
    orderedMediaList: IMediaItem[],
    mediaType?: string,
  ) => {
    setMediaPreviewDrawer({
      contentType: mediaType ?? 'Image',
      entityId: entityId,
      entityType: entityType,
      mainEntity: mainEntity,
      mediaUrl: mediaUrl,
      orderedMediaList: orderedMediaList,
    })
  }

  const customCellRender: TAny = (props: GridRenderCellParams) => {
    const type = props.row.entityType.toLowerCase()

    switch (type) {
      case ContentDisplayTabs.EVENTS:
        return <Event data={props.row} linkCollections={setOpenLinkedCollections} openUser={handleOpenUserProfileModal} showMedia={showMediaDrawer} />
      case ContentDisplayTabs.LIFESTYLES:
        return <Lifestyle data={props.row} openUser={handleOpenUserProfileModal} showMedia={showMediaDrawer} />
      case ContentDisplayTabs.OFFERS:
        return <Offer data={props.row} linkCollections={setOpenLinkedCollections} openUser={handleOpenUserProfileModal} showMedia={showMediaDrawer} />
      case ContentDisplayTabs.WISHES:
        return <Wish data={props.row} linkCollections={setOpenLinkedCollections} openUser={handleOpenUserProfileModal} showMedia={showMediaDrawer} />
      default:
        return <div>{'test'}</div>
    }
  }

  const columns = [{ columnId: '1', columnName: 'Test' }]

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((item) => ({
      field: item.columnId,
      flex: 1,
      headerName: item.columnName,
      renderCell: (parameters) => {
        if (parameters.row.isFooter && scrollEnd) {
          return <ContentDisplayGridFooter />
        }

        return customCellRender && customCellRender(parameters)
      },
    }))
  }, [columns, customCellRender, scrollEnd])

  const rows: TAny[] = useMemo(() => content, [content])

  const rowsWithFooter = useMemo(() => {
    if (!scrollEnd) {
      return rows
    }
    if (totalElements > 1) {
      const rowsWithAddedFooter = [
        ...rows,
        {
          entityId: 'footer',
          isFooter: true,
        },
      ]

      return rowsWithAddedFooter
    }

    return rows
  }, [rows, scrollEnd, totalElements])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !(!isEmptyData || !!filtersLength)
  const statusMapping = {
    [ContentDisplayTabs.EVENTS]: eventsStatus,
    [ContentDisplayTabs.LIFESTYLES]: lifestylesStatus,
    [ContentDisplayTabs.OFFERS]: offersStatus,
    [ContentDisplayTabs.WISHES]: wishesStatus,
  }
  const isDisabledRefreshButton = statusMapping[tabId] === 'loading'

  return (
    <div className="contentDisplay--container">
      <div className="contentDisplay--header">Content display</div>
      <TabContext value={tabId}>
        <TabList className="contentDisplay--tabs" onChange={handleTabChange}>
          <Tab label="Events" value={ContentDisplayTabs.EVENTS} />
          <Tab label="Offers" value={ContentDisplayTabs.OFFERS} />
          <Tab label="Wishes" value={ContentDisplayTabs.WISHES} />
          <Tab label="Lifestyle" value={ContentDisplayTabs.LIFESTYLES} />
        </TabList>
        <div className="contentDisplay--listHeader">
          <div className="contentDisplay--listButtonsContainer">
            <div>
              <Button
                className="contentDisplay--listButton"
                disabled={isDisabledFilterButton}
                onClick={() => setFilterDrawer(true)}
                size="small"
                startIcon={<FilterIcon />}
                variant="outlined"
              >
                Filter {!!filtersLength && `(${filtersLength})`}
              </Button>
              <Button
                className="contentDisplay--listButton"
                onClick={() => setSortingDrawer(true)}
                size="small"
                startIcon={<SortIcon />}
                variant="outlined"
                disabled
              >
                Sort {sorting.length > 0 && `(${sorting.length})`}
              </Button>
              <LoadingButton
                className="contentDisplay--listButton"
                disabled={isDisabledRefreshButton}
                loading={refreshStatus === 'loading'}
                loadingPosition="start"
                onClick={refreshData}
                size="small"
                startIcon={<RefreshIcon />}
              >
                Refresh data
              </LoadingButton>
            </div>
          </div>
          {filtersLength !== 0 && <ActiveFilterList refreshData={() => resetFilters(true)} />}
        </div>
        <TabPanel value={ContentDisplayTabs.EVENTS}>
          <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter
                getRowSpacing={() => ({
                  bottom: 16,
                })}
                slots={{
                  noRowsOverlay: () =>
                    NoMoreContent({
                      clearFilters: resetFilters,
                      fetchingError: fetchingError,
                      isFilters: !!filtersLength,
                      refreshData: repeatRequest,
                    }),
                }}
                apiRef={tableReference}
                columnHeaderHeight={0}
                columns={cols}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.entityId}
                headerFilterHeight={0}
                loading={eventsStatus === 'loading'}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                rows={rowsWithFooter}
                scrollEndThreshold={200}
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                disableColumnSelector
                disableRowSelectionOnClick
                disableVirtualization
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.OFFERS}>
          <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter
                getRowSpacing={() => ({
                  bottom: 16,
                })}
                slots={{
                  noRowsOverlay: () =>
                    NoMoreContent({
                      clearFilters: resetFilters,
                      fetchingError: fetchingError,
                      isFilters: !!filtersLength,
                      refreshData: repeatRequest,
                    }),
                }}
                apiRef={tableReference}
                columnHeaderHeight={0}
                columns={cols}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.entityId}
                loading={offersStatus === 'loading'}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                rows={rowsWithFooter}
                scrollEndThreshold={200}
                disableRowSelectionOnClick
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.WISHES}>
          <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter
                getRowSpacing={() => ({
                  bottom: 16,
                })}
                slots={{
                  noRowsOverlay: () =>
                    NoMoreContent({
                      clearFilters: resetFilters,
                      fetchingError: fetchingError,
                      isFilters: !!filtersLength,
                      refreshData: repeatRequest,
                    }),
                }}
                apiRef={tableReference}
                columnHeaderHeight={0}
                columns={cols}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.entityId}
                loading={wishesStatus === 'loading'}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                rows={rowsWithFooter}
                scrollEndThreshold={200}
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                disableColumnSelector
                disableRowSelectionOnClick
                disableVirtualization
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.LIFESTYLES}>
          <Box sx={{ flex: 1, position: 'relative' }}>
            <Box sx={{ inset: 0, position: 'absolute' }}>
              <DataGrid
                hideFooter
                getRowSpacing={() => ({
                  bottom: 16,
                })}
                slots={{
                  noRowsOverlay: () =>
                    NoMoreContent({
                      clearFilters: resetFilters,
                      fetchingError: fetchingError,
                      isFilters: !!filtersLength,
                      refreshData: repeatRequest,
                    }),
                }}
                apiRef={tableReference}
                columnHeaderHeight={0}
                columns={cols}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.entityId}
                loading={lifestylesStatus === 'loading'}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                rows={rowsWithFooter}
                scrollEndThreshold={200}
                disableColumnFilter
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                disableColumnSelector
                disableRowSelectionOnClick
                disableVirtualization
              />
            </Box>
          </Box>
        </TabPanel>
        <CloseFilterDrawerConfirmModal
          closeModal={() => cancelCloseFilterSidebar()}
          onClose={() => onFilterSidebarClose(true)}
          open={filterDrawerCloseConfirmModal}
        />
      </TabContext>
      {isShowUserProfileModal.length > 0 && (
        <UserProfileModal
          onClose={() => {
            setIsShowUserProfileModal('')
            // navigate(`/ui/world-management/collections/${collectionId}`);
          }}
          currentUserId={isShowUserProfileModal}
          open={isShowUserProfileModal.length > 0}
        />
      )}
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            setRowCount(initialRowCount)
            fetchContentFunction(searchValue, tabId, initialRowCount)
            tableScrollTop()
          }}
          onClose={onFilterSidebarClose}
          type="content-display"
        />
      </Drawer>
      <Drawer anchor="right" onClose={() => setSortingDrawer(false)} open={sortingDrawer}>
        <SortingDrawer
          isHidePresets
          onApply={() => {
            setSortingDrawer(false)
            fetchContentFunction(searchValue, tabId)
          }}
          onClose={() => setSortingDrawer(false)}
          type="content-display"
        />
      </Drawer>
      <Drawer
        onClose={() => {
          setMediaPreviewDrawer(undefined)
        }}
        anchor="right"
        open={!!mediaPreviewDrawer}
      >
        {mediaPreviewDrawer && <MediaPreviewDrawer mediaProps={mediaPreviewDrawer} onClose={() => setMediaPreviewDrawer(undefined)} />}
      </Drawer>
      {openLinkedCollections && (
        <ManageLinkedCollectionsModal
          entityId={openLinkedCollections?.entityId}
          entityType={openLinkedCollections?.entityType}
          onClose={() => setOpenLinkedCollections(null)}
          open={!!openLinkedCollections}
        />
      )}
    </div>
  )
}

export default ContentDisplay
