import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { MEMBERS_API } from '@admin/shared/api/constants'
import { TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface IUsersState {
  changeRoleStatus: TStatus
  changeStatusStatus: TStatus
  columns: TAny[]
  data: TAny[]
  filters: TAny[]
  newMemberStatus: TStatus
  oldMembers: TAny
  passwordResetStatus: TStatus
  refreshStatus: TStatus
  resendActivationLinkStatus: TStatus
  roles: TAny[]
  status: TStatus
  statuses: TAny[]
  totalCount: number | undefined
  value: number
}

const initialState: IUsersState = {
  changeRoleStatus: 'idle',
  changeStatusStatus: 'idle',
  columns: [],
  data: [],
  filters: [],
  newMemberStatus: 'idle',
  oldMembers: {},
  passwordResetStatus: 'idle',
  refreshStatus: 'idle',
  resendActivationLinkStatus: 'idle',
  roles: [],
  status: 'loading',
  statuses: [],
  totalCount: 0,
  value: 0,
}

export const fetchMembers = createAsyncThunk('members/fetchMembers', async (searchValue: string, thunkAPI) => {
  try {
    const rootState: TRootState = (await thunkAPI.getState()) as TRootState
    const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
    const sortingList = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
      : []
    const response = await axios.post(MEMBERS_API + 'members/all', {
      name: searchValue,
      filters: filters,
      pageInfo: {
        page: 1,
        size: 1000,
      },
      sortingList: sortingList,
    })

    return response.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({
        message: error.message || 'An unexpected error occurred',
        status: error.response?.status,
      })
    } else {
      return thunkAPI.rejectWithValue({
        message: 'An unexpected error occurred',
      })
    }
  }
})

export const fetchRoles = createAsyncThunk('members/fetchRoles', async () => {
  const response = await axios.get(MEMBERS_API + 'roles')

  return response.data
})

export const fetchSaveMember = createAsyncThunk('members/saveMember', async (data: TAny, thunkAPI) => {
  try {
    const response = await axios.post(MEMBERS_API + 'members', data)

    return response.data
  } catch (error: TAny) {
    const axiosError = error as AxiosError
    const isMemberExist = axiosError.response ? (axiosError.response?.data as TAny).title.indexOf('Member with email') !== -1 : false

    return thunkAPI.rejectWithValue({ isMemberExist: isMemberExist })
  }
})

export const fetchEditMember = createAsyncThunk('members/editMember', async (data: TAny, thunkAPI) => {
  try {
    const response = await axios.patch(MEMBERS_API + 'members', data)

    return response.data
  } catch (error: TAny) {
    const axiosError = error as AxiosError

    return thunkAPI.rejectWithValue(axiosError.response?.status)
  }
})

export const fetchResetPassword = createAsyncThunk('members/resetPassword', async (member: IMemberInfo | null, thunkAPI) => {
  try {
    const response = await axios.post(MEMBERS_API + 'members/send-password-reset-otp', {
      email: member?.email,
    })

    return response.data
  } catch (error: TAny) {
    const axiosError = error as AxiosError

    return thunkAPI.rejectWithValue(axiosError.response?.status)
  }
})

export const fetchResendActivationLink = createAsyncThunk('members/resendActivationLink', async (member: IMemberInfo | null) => {
  const response = await axios.post(MEMBERS_API + 'members/send-password-reset-otp', {
    email: member?.email,
  })

  return response.data
})

export const fetchStatuses = createAsyncThunk('members/fetchStatuses', async () => {
  const response = await axios.get(MEMBERS_API + 'statuses')

  return response.data
})

export const fetchChangeRole = createAsyncThunk(
  'members/fetchChangeRole',
  async ({ currentSelectedRole, selectionModel }: { currentSelectedRole: TAny; selectionModel: TAny }) => {
    const response = await axios.patch(MEMBERS_API + 'members/role', {
      members: selectionModel,
      roleId: currentSelectedRole.id,
    })

    return response.data
  },
)

export const fetchChangeStatus = createAsyncThunk(
  'members/fetchChangeStatus',
  async ({ currentSelectedStatus, members }: { currentSelectedStatus: TAny; members: TAny }) => {
    const response = await axios.patch(MEMBERS_API + 'members/status', {
      members: members,
      status: currentSelectedStatus.status,
    })

    return response.data
  },
)

export const membersSlice = createSlice({
  name: 'members',
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.status = 'idle'
        state.refreshStatus = 'idle'
        state.data = action.payload.members
        state.totalCount = action.payload.totalCount
      })
      .addCase(fetchMembers.rejected, (state) => {
        state.status = 'failed'
        state.refreshStatus = 'idle'
        state.data = []
        state.totalCount = 0
      })
      .addCase(fetchChangeStatus.pending, (state) => {
        state.changeStatusStatus = 'loading'
      })
      .addCase(fetchChangeStatus.fulfilled, (state) => {
        state.changeStatusStatus = 'idle'
      })
      .addCase(fetchChangeStatus.rejected, (state) => {
        state.changeStatusStatus = 'failed'
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload.roles
      })
      .addCase(fetchResendActivationLink.pending, (state) => {
        state.resendActivationLinkStatus = 'loading'
      })
      .addCase(fetchResendActivationLink.fulfilled, (state) => {
        state.resendActivationLinkStatus = 'idle'
      })
      .addCase(fetchResendActivationLink.rejected, (state) => {
        state.resendActivationLinkStatus = 'idle'
      })
      .addCase(fetchResetPassword.pending, (state) => {
        state.passwordResetStatus = 'loading'
      })
      .addCase(fetchResetPassword.fulfilled, (state) => {
        state.passwordResetStatus = 'idle'
      })
      .addCase(fetchResetPassword.rejected, (state) => {
        state.passwordResetStatus = 'failed'
      })
      .addCase(fetchChangeRole.pending, (state) => {
        state.changeRoleStatus = 'loading'
      })
      .addCase(fetchChangeRole.fulfilled, (state) => {
        state.changeRoleStatus = 'idle'
      })
      .addCase(fetchChangeRole.rejected, (state) => {
        state.changeRoleStatus = 'failed'
      })
      .addCase(fetchSaveMember.pending, (state) => {
        state.newMemberStatus = 'loading'
      })
      .addCase(fetchSaveMember.fulfilled, (state) => {
        state.newMemberStatus = 'idle'
      })
      .addCase(fetchSaveMember.rejected, (state, action) => {
        if ((action.payload as TAny).isMemberExist) {
          state.newMemberStatus = 'idle'
        } else {
          state.newMemberStatus = 'failed'
        }
      })
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload
      })
  },
  initialState,
  reducers: {
    cleanOldMembers: (state) => {
      state.oldMembers = {}
    },
    clearContentMembersData: (state) => {
      state.status = 'loading'
      state.data = []
    },
    conserveMembers: (state) => {
      const result: TAny = {}

      state.data.forEach((element: TAny) => (result[element.id] = element))
      state.oldMembers = result
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    setChangeStatusStatus: (state, action: PayloadAction<TStatus>) => {
      state.changeStatusStatus = action.payload
    },
    setNewMemberStatus: (state, action: PayloadAction<TStatus>) => {
      state.newMemberStatus = action.payload
    },
    setRefreshStatus: (state, action) => {
      state.refreshStatus = action.payload
    },
    setUsersStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload
    },
  },
})

export const {
  cleanOldMembers,
  clearContentMembersData,
  conserveMembers,
  incrementByAmount,
  setChangeStatusStatus,
  setNewMemberStatus,
  setRefreshStatus,
  setUsersStatus,
} = membersSlice.actions

export const selectMembersCount = (state: TRootState): number => state.members.data.length
export const selectMembers = (state: TRootState): TAny[] => state.members.data
export const selectOldMembers = (state: TRootState): TAny => state.members.oldMembers
export const selectRoles = (state: TRootState): TAny[] => state.members.roles
export const selectMembersStatus = (state: TRootState): TStatus => state.members.status
export const selectMembersRefreshStatus = (state: TRootState): TStatus => state.members.refreshStatus
export const selectMembersStatuses = (state: TRootState): TAny[] => state.members.statuses
export const selectNewMemberCreationStatus = (state: TRootState): TStatus => state.members.newMemberStatus
export const selectPasswordResetStatus = (state: TRootState): TStatus => state.members.passwordResetStatus
export const selectChangeRoleStatus = (state: TRootState): TStatus => state.members.changeRoleStatus
export const selectChangeStatusStatus = (state: TRootState): TStatus => state.members.changeStatusStatus
export const selectResendActivationLinkStatus = (state: TRootState): TStatus => state.members.resendActivationLinkStatus

export default membersSlice.reducer
