import './index.scss'

import { useCallback, useState } from 'react'

import { GridOverlay } from '@mui/x-data-grid'

import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import Typography from '@admin/components/shared/Typography/Typography'

interface IProps {
  clearFilters: () => void
  fetchingError: boolean
  isFiltersApplied: boolean
  refreshData: () => void
  renderNoDataComponent?: () => void
}

const NoUsersData = ({ clearFilters, fetchingError, isFiltersApplied, refreshData, renderNoDataComponent }: IProps) => {
  const [clearFiltersConfirmModal, setClearFiltersConfirmModal] = useState<boolean>(false)

  const cancelClearFilters = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setClearFiltersConfirmModal(false)
  }, [])

  const handleRemoveAllFilters = useCallback(() => {
    clearFilters()
    setClearFiltersConfirmModal(false)
    refreshData?.()
  }, [refreshData])

  return (
    <GridOverlay className="noUsersData--container">
      {fetchingError ? (
        <>
          <Typography color="text.secondary" variant="body2">
            There are some troubles with data receiving. Repeat request in a while.
          </Typography>
          <Typography color="text.secondary" fontSize="small" variant="body2">
            WARNING! In case of page reload you could lose your requested data
          </Typography>
          {refreshData && (
            <Button onClick={refreshData} sx={{ zIndex: 1 }} variant="text">
              Repeat request
            </Button>
          )}
        </>
      ) : isFiltersApplied ? (
        <>
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change their value
          </Typography>
          <Button onClick={() => setClearFiltersConfirmModal(true)} sx={{ zIndex: 1 }} variant="text">
            Clear filters
          </Button>
        </>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{renderNoDataComponent && renderNoDataComponent()}</>
      )}
      {clearFiltersConfirmModal && (
        <Dialog fullWidth maxWidth="xs" open={clearFiltersConfirmModal}>
          <DialogTitle variant="h6">Clear filters?</DialogTitle>
          <DialogContent>
            <Typography variant="body1">All data will be shown</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={cancelClearFilters} variant="text">
              Cancel
            </Button>
            <Button color="primary" onClick={handleRemoveAllFilters} variant="contained">
              Clear
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </GridOverlay>
  )
}

export default NoUsersData
