import { SVGProps } from 'react'
export const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 8.929c.75 0 3-1.75 3-4.167 0-1.833-1.25-3.333-3-3.333s-3 1.5-3 3.333c0 2.417 2.25 4.167 3 4.167"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 5.893A1.286 1.286 0 1 0 6 3.32a1.286 1.286 0 0 0 0 2.572M3.214 9.429c-.428 0-.643.3-.643.469 0 .463 1.543.816 3.429.816s3.428-.354 3.428-.816c0-.168-.214-.47-.642-.47"
    />
  </svg>
)
