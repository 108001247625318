import colors from '@admin/theme/constants/colors'
import { IMedia, TStatus } from '@admin/types/commonTypes'
import { IMemberInfo } from '@admin/types/Member'

/**
 * Enumerates the possible administrative actions for a payment operation.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationActions {
  REFUND = 'REFUND',
  SUSPEND = 'SUSPEND',
  UNSUSPEND = 'UNSUSPEND',
}

/**
 * Enumerates the possible status values for operation.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationStatuses {
  CANCELED = 'CANCELED',
  CHARGED = 'CHARGED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  FROZEN = 'FROZEN',
  HOLD = 'HOLD',
  HOLD_CHARGE = 'HOLD_CHARGE',
  IN_TRANSIT = 'IN_TRANSIT',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED',
  REFUNDED_CANCELED = 'REFUNDED_CANCELED',
  REFUNDED_CHARGED = 'REFUNDED_CHARGED',
  REFUNDED_FROZEN = 'REFUNDED_FROZEN',
  REFUNDED_HOLD_CHARGE = 'REFUNDED_HOLD_CHARGE',
  REFUNDED_PENDING = 'REFUNDED_PENDING',
  REFUNDED_PENDING_CHARGE = 'REFUNDED_PENDING_CHARGE',
  REFUNDED_REFUNDED = 'REFUNDED_REFUNDED',
  REQUESTED = 'REQUESTED',
  START = 'START',
  SUCCESS = 'SUCCESS',
}

/**
 * Enumerates the possible types of payment operations.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationTypes {
  BOOST = 'BOOST',
  CHARGE = 'CHARGE',
  DONATE = 'DONATE',
  EVENT = 'EVENT',
  IAP_DONATE = 'IAP_DONATE',
  IAP_DONATION = 'IAP_DONATION',
  IAP_EVENT = 'IAP_EVENT',
  IAP_FOLLOW = 'IAP_FOLLOW',
  IAP_LIFESTYLE = 'IAP_LIFESTYLE',
  OFFER = 'OFFER',
  PAY_OUT = 'PAY_OUT',
  PRIVATE_WISH = 'PRIVATE_WISH',
  RM_GOAL = 'RM_GOAL',
  TIPS_EVENT = 'TIPS_EVENT',
  TIPS_OFFER = 'TIPS_OFFER',
  WISH = 'WISH',
}

/**
 * Enumerates possible payment methods for transaction.
 *
 * @enum {string}
 * @readonly
 */
export enum PaymentMethods {
  APPLE_IAP = 'APPLE_IAP',
  CARD = 'CARD',
  GOOGLE_IAP = 'GOOGLE_IAP',
}

/**
 * Enumerates the possible status values for transaction.
 *
 * @enum {string}
 * @readonly
 */
export enum TransactionStatuses {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  HOLD = 'HOLD',
  IN_TRANSIT = 'IN_TRANSIT',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  REVERSED = 'REVERSED',
  START = 'START',
  SUCCESS = 'SUCCESS',
}

/**
 * Enumerates the possible types of transaction.
 *
 * @enum {string}
 * @readonly
 */
export enum TransactionTypes {
  APPLE_IAP = 'APPLE_IAP',
  CARD_PAYMENTS = 'CARD_PAYMENTS',
  CHARGE = 'CHARGE',
  GOOGLE_IAP = 'GOOGLE_IAP',
  INCOME = 'INCOME',
  PAY_OUT = 'PAY_OUT',
  PAYMENT_FEE = 'PAYMENT_FEE',
  REFUND = 'REFUND',
  REVERSAL_TRANSFER = 'REVERSAL_TRANSFER',
  TRANSFER = 'TRANSFER',
}

/**
 * Represents the administrative history of the last operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the administrative history.
 * @property {IMemberInfo} memberInfo - Information about the member associated with the administrative action.
 * @property {IMedia} avatarPreview - The member's avatar.
 * @property {OperationActions} action - The type of administrative action performed.
 * @property {Date} createdAt - The date when the administrative action was performed.
 */
export interface ILastAdminOperationHistory {
  action: OperationActions
  avatarPreview: IMedia
  createdAt: Date
  id: string
  memberInfo: IMemberInfo
}

/* ----------------------------- Payments ------------------------------ */
/**
 * Represents a payment operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the operation.
 * @property {string} userFriendlyOperationId - The unique identifier for the operation (short).
 * @property {string} operationType - The type of operation.
 * @property {string} profileId - The identifier of the user (payer) in the YZZY application.
 * @property {string} profileName - The name of the user (payer) in the YZZY application.
 * @property {string} providerName - The name of the payment service provider used for the transaction.
 * @property {string} currency - The currency used for the payment operation.
 * @property {string} taxJarOrderId - The identifier for the order in TaxJar (Tax Transaction ID).
 * @property {string} recipientProfileId - The identifier of the user (payee) in the YZZY application.
 * @property {string} recipientProfileName - The name of the user (payee) in the YZZY application.
 * @property {string} [entityId] - The identifier of the paid entity (optional).
 * @property {string} [entityTitle] - The name of the paid entity (optional).
 * @property {string} [entityDescription] - The description of the paid entity (optional).
 * @property {string} [entityExecutionId] - The unique identifier for execution entity.
 * @property {string} [externalPayerId] - The external identifier of the payer, if applicable.
 * @property {string} [externalTaxPayerId] - The external tax identifier of the payer, if applicable.
 * @property {string} [externalReceiverId] - The external identifier of the receiver, if applicable.
 * @property {number} entityVersion - The version of the paid entity.
 * @property {number} total - The total amount of the payment operation, including taxes and the cost of the paid entity.
 * @property {number} price - The cost of the paid entity within the operation.
 * @property {number} totalAfterAllFees - The amount after deducting (calculating) all fees.
 * @property {number} platformFeeClient - The platform fee (YZZY) for the buyer.
 * @property {number} platformFeeCreator - The platform fee (YZZY) for the seller.
 * @property {number} serviceFeeClient - The sum of the platform commission and the payment system commission paid by the client.
 * @property {number} serviceFeeCreator - The sum of ${platform_fee_creator} + ${provider_fee_creator}.
 * @property {number} paymentFeeAmount - The payment service fee through which the payment will be made (e.g., Stripe). Paid by the buyer.
 * @property {number} providerFeeCreator - The payment service fee through which the payment will be made (e.g., Stripe). Paid by the seller.
 * @property {number} salesTaxAmount - Taxes paid by the buyer.
 * @property {boolean} isTransferSuspended - Indicates whether the transfer is suspended.
 * @property {OperationStatuses} status - The status of the operation.
 * @property {Date} createDate - The date of operation's creation.
 * @property {string} tierCode - The tier code of operation.
 */
export interface IOperation {
  createDate: Date
  currency: string
  entityDescription?: string
  entityExecutionId?: string
  entityId?: string
  entityTitle?: string
  entityVersion: number
  externalPayerId?: string
  externalReceiverId?: string
  externalTaxPayerId?: string
  id: string
  isTransferSuspended: boolean
  operationType: string
  paymentFeeAmount: number
  platformFeeClient: number
  platformFeeCreator: number
  price: number
  profileId: string
  profileName: string
  providerFeeCreator: number
  providerName: string
  recipientProfileId: string
  recipientProfileName: string
  salesTaxAmount: number
  serviceFeeClient: number
  serviceFeeCreator: number
  status: OperationStatuses
  taxJarOrderId: string
  tierCode: string
  total: number
  totalAfterAllFees: number
  userFriendlyOperationId: string
}

/**
 * Represents details of a payment operation.
 *
 * @interface
 * @property {IOperation} operation - Operation itself.
 * @property {ILastAdminOperationHistory} lastAdminOperationHistory - The last administrative history related to the operation.
 * @property {ITransaction[]} transactions - An array of transactions associated with the operation.
 */
export interface IOperationDetails {
  lastAdminOperationHistory: ILastAdminOperationHistory
  operation: IOperation
  transactions: ITransaction[]
}

/**
 * Represents SearchItem for operations.
 */
export interface IOperationSearchItem {
  operationId: string
  userFriendlyOperationId: string
}

/**
 * Represents the state of operations in the store.
 *
 * @interface
 * @property {IOperation[]} operations - An array of payment operations.
 * @property {IOperationDetails | null} operationDetails - Details of a specific payment operation, or null if not available.
 * @property {number} operationsCount - The total number of payment operations.
 * @property {TStatus} status - The status value indicating the state of the operation's data.
 * @property {TStatus} actionStatus - The status value indicating the state of the action on operation.
 * @property {OperationActions} currentAction - Current operation's action.
 * @property {TStatus} operationDetailsStatus - The status value indicating the state of the specific operation details data.
 * @property {IOperationSearchItem[]} operationsSearch - An array of search results.
 * @property {string[]} operationsSearchAll - An array of "allIds" search results.
 * @property {TStatus} operationsSearchStatus - The status value indicating the state of search loading.
 */
export interface IOperationsState {
  actionStatus: TStatus
  currentAction: null | OperationActions
  operationDetails: IOperationDetails | null
  operationDetailsStatus: TStatus
  operations: IOperation[]
  operationsCount: number
  operationsSearch: IOperationSearchItem[]
  operationsSearchAll: string[]
  operationsSearchStatus: TStatus
  status: TStatus
}

/**
 * Represents a status object for operations.
 *
 * @interface
 * @property {OperationStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface IOperationStatus {
  color: colors
  status: OperationStatuses
  title: string
}

/**
 * Represents an operation type object for operations.
 *
 * @interface
 * @property {OperationTypes} operationType - Operation type value.
 * @property {string} title - Operation type title.
 * @property {string} subtitle - Operation type subtitle.
 * @property {[string]} subtitle - Operation type title for in-app tiers table.
 */
export interface IOperationType {
  operationType: OperationTypes
  subtitle: string
  tierTitle?: string
  title: string
}

/**
 * Represents a payment method.
 *
 * @interface
 * @property {PaymentMethods} paymentMethod - The payment method.
 * @property {string} title - The title of the payment method.
 */
export interface IPaymentMethod {
  paymentMethod: PaymentMethods
  title: string
}

/**
 * Represents a transaction associated with a payment operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the transaction.
 * @property {string} type - The type of the transaction.
 * @property {OperationStatuses} status - The status of the transaction.
 * @property {string} amount - The amount of the transaction.
 * @property {string} currency - The currency used for the transaction.
 * @property {string} externalOrderId - The unique identifier for external order.
 * @property {string} paymentMethodName - The name of the payment method used for the transaction.
 * @property {string} psTransactionId - The unique identifier provided by the payment service for the transaction.
 * @property {string} [reason] - The reason for a refund, if applicable.
 * @property {string} [comment] - Additional information or message related to refund.
 * @property {Date} createDate - The date when the transaction was created.
 * @property {boolean} suspended - If true, transaction is suspended.
 */
export interface ITransaction {
  amount: string
  comment?: string
  createDate: Date
  currency: string
  externalOrderId: string
  id: string
  paymentMethodName: string
  psTransactionId: string
  reason?: string
  status: TransactionStatuses
  suspended: boolean
  type: string
}

/**
 * Represents a transaction type.
 *
 * @interface
 * @property {TransactionTypes} transactionType - The type of the transaction.
 * @property {string} title - The title or description associated with the transaction type.
 */
export interface ITransactionType {
  title: string
  transactionType: TransactionTypes
}
