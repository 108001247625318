import { MouseEvent } from 'react'

import { intervalToDuration } from 'date-fns'

import CopyIcon from '@admin/assets/img/CopyIcon'
import Avatar from '@admin/components/shared/Avatar/Avatar'
import Button from '@admin/components/shared/Button/Button'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import Divider from '@admin/components/shared/Divider/Divider'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import ListItem from '@admin/components/shared/List/ListItem/ListItem'
import ListItemText from '@admin/components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText'
import Popover from '@admin/components/shared/Popover/Popover'
import Stack from '@admin/components/shared/Stack/Stack'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import { IUserProfileMainData, IUserProfilePaymentInfo, IUserProfilePersonalData } from '@admin/store/userProfileSlice'
import colors from '@admin/theme/constants/colors'

interface IProfilePopoverProps {
  anchorEl: HTMLButtonElement | null
  coordinates: { x: number; y: number }
  copyId: (event: MouseEvent<HTMLButtonElement>, value: string) => void
  onClose: () => void
  profileId: string
  profileInfo: {
    main: IUserProfileMainData
    paymentInfo: IUserProfilePaymentInfo
    personal: IUserProfilePersonalData
  }
}

export const ProfilePopover = ({ anchorEl, coordinates, copyId, onClose, profileId, profileInfo }: IProfilePopoverProps) => {
  const mainInfo = profileInfo?.main
  const personalInfo = profileInfo?.personal

  if (!mainInfo || mainInfo.userId !== profileId) {
    return null
  }

  const birthDate = personalInfo?.birthDate ? new Date(personalInfo.birthDate) : null
  const age = birthDate ? intervalToDuration({ end: new Date(), start: birthDate }).years : null

  return (
    <Popover
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      anchorPosition={{
        left: coordinates.x - 35,
        top: coordinates.y + 20,
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorReference="anchorPosition"
      onClose={onClose}
      open={!!anchorEl}
    >
      <Stack direction="row" gap={2} sx={{ p: '24px 24px 0' }}>
        <div className="avatar-wrapper-payments" data-level={mainInfo.levelAura}>
          <Avatar
            sx={
              mainInfo.levelAura
                ? {
                    border: `2px solid ${colors.white}`,
                  }
                : null
            }
            alt={`${mainInfo.firstName} ${mainInfo.lastName}`}
            src={mainInfo.avatarUrl}
          />
        </div>

        <Stack flex="auto" gap={1}>
          <Typography variant="body2">{`${mainInfo.firstName} ${mainInfo.lastName}`}</Typography>
          <Typography variant="body2">{`@${mainInfo.username}`}</Typography>
          <Stack direction="row" gap={1}>
            {age && <Typography variant="body2">{`${age} y.o.`}</Typography>}
            <Typography variant="body2">{personalInfo?.location}</Typography>
          </Stack>
          <Divider orientation="horizontal" flexItem />
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary={
                <Typography color="text.secondary" variant="body2">
                  Profile ID
                </Typography>
              }
              secondary={
                <Stack direction="row" gap={1}>
                  <Typography color="text.primary" variant="subtitle1">
                    {mainInfo.userId || '—'}
                  </Typography>
                  {mainInfo.userId && (
                    <Tooltip title="Copy" placement="top">
                      <IconButton color="default" onClick={(event) => copyId(event, mainInfo.userId)} size="small">
                        <CopyIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              }
              disableTypography
            />
          </ListItem>
        </Stack>
      </Stack>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Popover>
  )
}
