import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { USERS_API } from '@admin/shared/api/constants'
import { IColumnItem, IPreset, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface IFiltersState {
  activePresetId: null | string
  columns: IColumnItem[]
  currentData: IColumnItem[]
  data: IColumnItem[]
  presets: IPreset[]
  status: TStatus
}

const initialState: IFiltersState = {
  activePresetId: null,
  columns: [],
  currentData: [],
  data: [],
  presets: [],
  status: 'idle',
}

export const fetchColumns = createAsyncThunk('columns/fetchColumns', async (type: string) => {
  const columnsUrl = 'timusers/presets/columns'

  const response = await axios.get(USERS_API + columnsUrl, {
    // headers: {
    //   'Member-Id': '0'
    // }
  })

  return { columns: response.data.columns }
})

export const fetchColumnsPresets = createAsyncThunk('columns/fetchPresets', async (type: string) => {
  const presetsUrl = 'timusers/presets?presetType=COLUMNS'

  const response = await axios.get(USERS_API + presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const setActivePreset = createAsyncThunk('columns/setActivePreset', async ({ presetId, type }: { presetId: string; type: string }) => {
  const presetUrl = `timusers/presets/columns/${presetId}`

  const response = await axios.get(USERS_API + presetUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { columns: response.data.columns, presetId: presetId }
})

export const savePreset = createAsyncThunk('columns/savePreset', async ({ presetName, type }: { presetName: string; type: string }, thunkAPI) => {
  const rootState: TRootState = (await thunkAPI.getState()) as TRootState
  const columns = Object.keys(rootState.columns.data) ? Object.values(rootState.columns.data).filter((item: TAny) => item !== null) : []
  const presetsUrl = 'timusers/presets?presetType=COLUMNS'

  const response = await axios.post(
    USERS_API + presetsUrl,
    {
      columns: columns,
      presetName: presetName,
      presetType: 'COLUMNS',
    },
    {
      headers: {
        'Member-Id': '0',
      },
    },
  )

  return { presets: response.data.presets }
})

export const deletePreset = createAsyncThunk('columns/deletePreset', async ({ presetId, type }: { presetId: string; type: string }) => {
  const presetsUrl = `timusers/presets/${presetId}`

  const response = await axios.delete(USERS_API + presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  })

  return { presets: response.data.presets }
})

export const columnsSlice = createSlice({
  name: 'columns',
  extraReducers: (builder) => {
    builder
      .addCase(fetchColumns.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchColumns.fulfilled, (state, action) => {
        state.status = 'idle'
        state.columns = action.payload.columns
        state.currentData = action.payload.columns.filter((item: IColumnItem) => !item.hideByDefault)
        state.data = action.payload.columns.filter((item: IColumnItem) => !item.hideByDefault)
      })
      .addCase(fetchColumns.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchColumnsPresets.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchColumnsPresets.fulfilled, (state, action) => {
        state.status = 'idle'
        state.presets = action.payload.presets
      })
      .addCase(fetchColumnsPresets.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: TAny = {}

        action.payload.columns.forEach((item: TAny) => {
          newData[item.columnId] = item
        })
        state.data = newData
        state.currentData = newData
        state.activePresetId = action.payload.presetId
      })
  },
  initialState,
  reducers: {
    addColumn: (state, { payload }: { payload: IColumnItem }) => {
      const foundColumn = state.columns.find((item: IColumnItem) => item.columnName === payload.columnName)!

      state.currentData.push(foundColumn)
    },
    applyColumns: (state) => {
      state.data = state.currentData
    },
    clearColumns: (state) => {
      state.columns = []
    },
    removeColumn: (state, action) => {
      state.currentData.splice(action.payload.index, 1)
    },
    setNewColumnsArray: (state, action) => {
      state.currentData = action.payload
    },
    syncColumnsData: (state) => {
      state.currentData = state.data
    },
  },
})

export const { addColumn, applyColumns, clearColumns, removeColumn, setNewColumnsArray, syncColumnsData } = columnsSlice.actions

export default columnsSlice.reducer
