import { MouseEvent, useState } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'
import { enqueueSnackbar, VariantType } from 'notistack'

import CopyIcon from '@admin/assets/img/CopyIcon'
import EditIcon from '@admin/assets/img/EditIcon'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'

import type { TAny } from '@yzzy/types'

/**
 * Props for the RenderCommentBannedCell component.
 *
 * @interface
 * @property {(data: TAny) => void} openCommentModal - Event handler to open comment modal.
 * @property {GridRenderCellParams} props - The properties of the RenderCommentBannedCell component.
 * */
interface IRenderCommentBannedCellProps {
  openCommentModal: (data: TAny) => void
  props: GridRenderCellParams
}

/**
 * Renders a comment cell for banned user in a data grid.
 */
export const RenderCommentBannedCell = ({ openCommentModal, props }: IRenderCommentBannedCellProps) => {
  const { row, value } = props

  const copyComment = (event: MouseEvent<HTMLButtonElement>, value: string) => {
    event.stopPropagation()

    if (!value) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      })

      return
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      })

      return navigator.clipboard.writeText(value)
    }
  }

  const [showTooltip, setShowTooltip] = useState(true)

  const handleMouseEnterIcon = () => setShowTooltip(false)
  const handleMouseLeaveIcon = () => setShowTooltip(true)

  if (value === '' || value === undefined) {
    return <>—</>
  }

  return (
    <Tooltip title={showTooltip ? props.value : ''} placement="top-end" disableInteractive followCursor>
      <div className="deletedUsers--commentContainer">
        <Typography className="deletedUsers--comment" color="text.secondary" variant="caption">
          {props.value}
        </Typography>
        {row?.deactivatedByInfo?.memberId ? (
          <Tooltip title="Edit comment" className="deletedUsers--commentIcon" onClick={() => openCommentModal(row)} placement="top-end" followCursor>
            <IconButton onMouseEnter={handleMouseEnterIcon} onMouseLeave={handleMouseLeaveIcon} size="small" sx={{ p: '2px' }}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Copy" className="deletedUsers--commentIcon" placement="top-end" followCursor>
            <IconButton
              onClick={(event) => copyComment(event, props.value ?? '')}
              onMouseEnter={handleMouseEnterIcon}
              onMouseLeave={handleMouseLeaveIcon}
              size="small"
              sx={{ p: '2px' }}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Tooltip>
  )
}
