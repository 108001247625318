import { createSvgIcon } from '@mui/material'

const HistoryIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.51782 10.4787L2.31822 9.14925C2.03373 8.83396 1.55307 8.81413 1.24464 9.10494C0.936209 9.39576 0.916804 9.88711 1.2013 10.2024L3.62539 12.8889C3.90988 13.2042 4.39054 13.224 4.69897 12.9332L7.32703 10.4552C7.63546 10.1644 7.65486 9.67303 7.37037 9.35775C7.08588 9.04246 6.60522 9.02262 6.29679 9.31344L5.0647 10.4752C5.3635 9.00799 6.09694 7.61977 7.25664 6.52629C10.2139 3.73787 14.8226 3.92806 17.5504 6.95111C20.2782 9.97415 20.0921 14.6853 17.1348 17.4737C14.1775 20.2621 9.56885 20.0719 6.84107 17.0489C6.55658 16.7336 6.07592 16.7138 5.76749 17.0046C5.45906 17.2954 5.43965 17.7868 5.72414 18.102C9.02092 21.7557 14.5909 21.9855 18.1651 18.6155C21.7392 15.2454 21.9641 9.55158 18.6673 5.89796C15.3705 2.24435 9.80056 2.01448 6.2264 5.38454C4.74079 6.78532 3.83372 8.58861 3.51782 10.4787Z" />
    <path d="M16.4203 12.9411C16.4203 12.5122 16.0801 12.1644 15.6605 12.1644H11.9511V7.58942C11.9511 7.16049 11.611 6.81277 11.1914 6.81277C10.7718 6.81277 10.4316 7.16049 10.4316 7.58942V12.9411C10.4316 13.37 10.7718 13.7177 11.1914 13.7177H15.6605C16.0801 13.7177 16.4203 13.37 16.4203 12.9411Z" />
  </svg>,
  'History',
)

export default HistoryIcon
