import { ForwardedRef, forwardRef } from 'react'

import { InputLabel, Select as SelectBase, SelectProps } from '@mui/material'

import FormControl from '@admin/components/shared/FormControl/FormControl'
import MenuItem from '@admin/components/shared/Menu/MenuItem/MenuItem'
import colors from '@admin/theme/constants/colors'

export interface ISelectOptions {
  color?: string
  text?: string
  value: number | readonly string[] | string | undefined
}

type TSelectProps<T extends ISelectOptions> = SelectProps & {
  label?: string
  options?: T[]
  optionsName?: string
  placeholder?: string
  primary?: boolean
}

const Select = forwardRef(<T extends ISelectOptions>(props: TSelectProps<T>, reference: ForwardedRef<unknown>) => {
  return (
    <FormControl fullWidth={props.fullWidth} size={props.size} variant={props.variant}>
      {props.placeholder && <InputLabel error={props.error}>{props.placeholder}</InputLabel>}
      <SelectBase
        ref={reference}
        {...props}
        label={props.placeholder}
        MenuProps={{
          sx: { width: 'fit-content' },
        }}
        sx={{
          '& .MuiSvgIcon-root': {
            color: colors.actionDisabled,
          },
          ...props.sx,
        }}
        onChange={props.onChange}
        value={props.value}
      >
        {(props.options || []).map((item: T, index: number) => (
          <MenuItem key={index} sx={item.color ? { color: item.color, whiteSpace: 'normal' } : { whiteSpace: 'normal' }} value={item.value}>
            {props.optionsName ? (item[props.optionsName as keyof T] as string) : item.text}
          </MenuItem>
        ))}
      </SelectBase>
    </FormControl>
  )
})

export default Select
