import { Input as InputBase } from '@mui/material'
import { InputProps } from '@mui/material/Input/Input'

interface IInputProps extends InputProps {
  label?: string
  primary?: boolean
}

const Input = (props: IInputProps) => {
  return <InputBase {...props} />
}

export default Input
