import './index.scss'

import { useState } from 'react'

import { Avatar as AvatarMUI } from '@mui/material'
import { AvatarProps } from '@mui/material/Avatar/Avatar'

import UserBrokenIcon from '@admin/assets/img/UserBrokenIcon'
import UserIcon from '@admin/assets/img/UserIcon'

interface ICustomAvatarProps extends AvatarProps {
  iconSize?: string
}

const Avatar = ({ iconSize, ...props }: ICustomAvatarProps) => {
  const [error, setError] = useState(false)

  return (
    <AvatarMUI className={error ? 'Avatar-error' : 'Avatar'} onError={() => setError(true)} onLoad={() => setError(false)} {...props}>
      {error ? <UserBrokenIcon sx={{ fontSize: iconSize }} /> : <UserIcon sx={{ fontSize: iconSize }} />}
    </AvatarMUI>
  )
}

export default Avatar
