import './index.scss'

import { useEffect, useState } from 'react'

import { GridToolbarContainer } from '@mui/x-data-grid'
import { GridRowId, GridRowModel } from '@mui/x-data-grid/models/gridRows'

import ArchiveIcon from '@admin/assets/img/ArchiveIcon'
import CheckIcon from '@admin/assets/img/CheckIcon'
import DeleteIcon from '@admin/assets/img/DeleteIcon'
import Button from '@admin/components/shared/Button/Button'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { IBanner } from '@admin/store/bannerDetailsSlice'
import { ICohort } from '@admin/store/cohortDetailsSlice'
import { ICollection } from '@admin/store/collectionDetailsSlice'

interface IProps {
  selectedRows: Map<GridRowId, GridRowModel>
  setArchiveBanners?: (items: IBanner[]) => void
  setArchiveCollections?: (items: ICollection[]) => void
  setDeleteCohorts?: (items: ICohort[]) => void
  setPublishCollections?: (items: ICollection[]) => void
}

const CustomToolbar = ({ selectedRows, setArchiveBanners, setArchiveCollections, setDeleteCohorts, setPublishCollections }: IProps) => {
  const [banners, setBanners] = useState<IBanner[]>([])
  const [cohorts, setCohorts] = useState<ICohort[]>([])
  const [collections, setCollections] = useState<ICollection[]>([])
  const [isLive, setIsLive] = useState(false)
  const [isReadyForLive, setIsReadyForLive] = useState(true)
  const selectedCount = selectedRows.size

  useEffect(() => {
    if (setArchiveBanners) {
      const array: IBanner[] = []

      selectedRows.forEach((value) => {
        array.push(value as IBanner)
      })
      setBanners(array)
      for (const element of array) {
        if (element.status === 'LIVE') {
          setIsLive(true)

          return
        }
      }
      setIsLive(false)
    }
    if (setDeleteCohorts) {
      const array: ICohort[] = []

      selectedRows.forEach((value) => {
        array.push(value as ICohort)
      })
      setCohorts(array)
      for (const element of array) {
        if (element.status === 'ACTIVE' || element.hasCompilation) {
          setIsLive(true)

          return
        }
      }
      setIsLive(false)
    }
    if (setArchiveCollections || setPublishCollections) {
      const array: ICollection[] = []

      selectedRows.forEach((value) => {
        array.push(value as ICollection)
      })
      setCollections(array)
      setIsReadyForLive(true)
      setIsLive(false)

      for (const element of array) {
        if (element.status === 'LIVE') {
          setIsLive(true)
          break
        }
      }
      for (const element of array) {
        if (element.status !== 'READY' && element.status !== 'SCHEDULED') {
          setIsReadyForLive(false)
          break
        }
      }
    }
  }, [selectedRows])

  return (
    !!selectedCount && (
      <div className="customToolbar">
        <GridToolbarContainer>
          <span className="customToolbar-selectNumber">{selectedCount} selected</span>
          <span className="customToolbar-selectActions">
            {setArchiveBanners && (
              <Button
                onClick={() => {
                  setArchiveBanners(banners as IBanner[])
                }}
                color="error"
                disabled={isLive}
                startIcon={<ArchiveIcon />}
                variant="outlined"
              >
                Archive
              </Button>
            )}
            {setDeleteCohorts && (
              <Tooltip title="You can not delete cohorts linked to collections" placement="top-start">
                <span>
                  <Button
                    onClick={() => {
                      setDeleteCohorts(cohorts as ICohort[])
                    }}
                    color="error"
                    disabled={isLive}
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            )}
            {setPublishCollections && (
              <Button
                onClick={() => {
                  setPublishCollections(collections as ICollection[])
                }}
                color="primary"
                disabled={!isReadyForLive}
                startIcon={<CheckIcon />}
                variant="outlined"
              >
                Publish
              </Button>
            )}
            {setArchiveCollections && (
              <Button
                onClick={() => {
                  setArchiveCollections(collections as ICollection[])
                }}
                color="error"
                disabled={isLive}
                startIcon={<ArchiveIcon />}
                variant="outlined"
              >
                Archive
              </Button>
            )}
          </span>
        </GridToolbarContainer>
      </div>
    )
  )
}

export default CustomToolbar
