import { createSvgIcon } from '@mui/material'

const WarningIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 8.1499C12.4693 8.1499 12.8499 8.53046 12.8499 8.9999V13.9999C12.8499 14.4693 12.4693 14.8499 11.9999 14.8499C11.5305 14.8499 11.1499 14.4693 11.1499 13.9999V8.9999C11.1499 8.53046 11.5305 8.1499 11.9999 8.1499Z" />
    <path d="M10.9898 18C10.9858 17.448 11.4338 17 11.9858 17C12.5418 17 12.9898 17.448 12.9898 18C12.9898 18.552 12.5418 19 11.9898 19C11.4378 19 10.9898 18.552 10.9898 18Z" />
    <path
      clipRule="evenodd"
      d="M11.869 22.2602H5.93981C4.01808 22.2602 2.4447 21.5662 1.65551 20.2217C0.866801 18.878 1.02632 17.1655 1.95643 15.488L8.01645 4.58797C8.98982 2.83302 10.3998 1.74268 11.9998 1.74268C13.5999 1.74268 15.0096 2.83248 15.983 4.5877L22.043 15.4976C22.9737 17.1763 23.13 18.8893 22.34 20.2324C21.5498 21.5757 19.9774 22.2702 18.0598 22.2702H11.9998C11.9553 22.2702 11.9117 22.2668 11.869 22.2602ZM11.9998 3.44268C11.25 3.44268 10.3098 3.95775 9.50317 5.41238L3.44319 16.3123C2.69353 17.6646 2.73785 18.7074 3.1216 19.3611C3.50491 20.0142 4.39154 20.5602 5.93981 20.5602H11.9998C12.0443 20.5602 12.088 20.5636 12.1306 20.5702H18.0598C19.6022 20.5702 20.4898 20.0247 20.8747 19.3705C21.2596 18.716 21.3057 17.6737 20.5564 16.3223L14.4968 5.41309C13.6902 3.95846 12.7496 3.44268 11.9998 3.44268Z"
      fillRule="evenodd"
    />
  </svg>,
  'Warning',
)

export default WarningIcon
