import './index.scss'

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'

import { GridColDef, GridEventListener, GridPaginationModel } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps'
import { GridRowId } from '@mui/x-data-grid/models/gridRows'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar, VariantType } from 'notistack'

import FilterIcon from '@admin/assets/img/FilterIcon'
import ActiveFilterList from '@admin/components/ActiveFilterList/ActiveFilterList'
import { CloseFilterDrawerConfirmModal } from '@admin/components/FilterDrawer/CloseFilterDrawerConfirmModal'
import FilterDrawer from '@admin/components/FilterDrawer/FilterDrawer'
import Box from '@admin/components/shared/Box/Box'
import Button from '@admin/components/shared/Button/Button'
import CustomFooter from '@admin/components/shared/DataGrid/CustomFooter/CustomFooter'
import CustomNoRowsOverlay from '@admin/components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay'
import DataGrid from '@admin/components/shared/DataGrid/DataGrid'
import RenderCellWithCopy from '@admin/components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy'
import RenderDetailPanelToggle from '@admin/components/shared/DataGrid/RenderDetailPanelToggle/RenderDetailPanelToggle'
import RenderOperationTypeCell from '@admin/components/shared/DataGrid/RenderOperationTypeCell/RenderOperationTypeCell'
import Drawer from '@admin/components/shared/Drawer/Drawer'
import Stack from '@admin/components/shared/Stack/Stack'
import Typography from '@admin/components/shared/Typography/Typography'
import SortingChip from '@admin/components/SortingChip/SortingChip'
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from '@admin/store/filtersSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { IOperation } from '@admin/store/slices/Payments/interface'
import {
  fetchOperations,
  selectOperations,
  selectOperationsStatus,
  selectTotalCount,
  setOperationsStatus,
} from '@admin/store/slices/Payments/operationsSlice'
import { fetchSortingColumns, setSortingData } from '@admin/store/sortingSlice'
import { IFilterItem } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'

import type { TAny } from '@yzzy/types'

import {
  CustomDetailPanelContent,
  OperationsSearch,
  RenderCreationDateCell,
  RenderFeeCell,
  RenderHeaderWithSecondaryText,
  RenderStatusCell,
} from './components'
import { defaultOperationsSortingModel } from './constants'

export const Operations: FC = () => {
  const dispatch = useAppDispatch()
  const { showBoundary } = useErrorBoundary()
  const apiReference = useGridApiRef()

  const data = useAppSelector(selectOperations)
  const totalCount = useAppSelector(selectTotalCount)
  const status = useAppSelector(selectOperationsStatus)
  const filters = useAppSelector(selectFilters)
  const filtersData = useAppSelector(selectFiltersData)
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData)

  const isLoading = status === 'loading'

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false)
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false)
  const [fetchingError, setFetchingError] = useState<boolean>(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const filtersLength = useMemo(() => {
    /** Subtraction due to search using "ID" filter. Just UI **/
    if (!isEmpty(filtersCurrentData?.id) || !isEmpty(filtersCurrentData?.operation_search)) {
      return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length - 1
    }

    return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length
  }, [filtersCurrentData, filtersData])

  const tableScrollTop = useCallback(() => {
    apiReference?.current?.scrollToIndexes({
      rowIndex: 0,
    })
  }, [])

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false)
        setFilterDrawer(false)
        dispatch(reverseFilters())
      } else {
        setFilterDrawerCloseConfirmModal(true)
      }
    },
    [filtersData, filtersCurrentData],
  )

  const cancelCloseFilterSidebar = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setFilterDrawerCloseConfirmModal(false)
  }, [])

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel((previousState) => {
      if (previousState.pageSize !== newPaginationModel.pageSize) {
        void fetchOperationsGrid(0, newPaginationModel.pageSize)

        return { page: 0, pageSize: newPaginationModel.pageSize }
      }
      void fetchOperationsGrid(newPaginationModel.page, newPaginationModel.pageSize)

      return newPaginationModel
    })
  }

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (parameters) => {
      apiReference.current.toggleDetailPanel(parameters.id)
    },
    [apiReference],
  )

  const handleRefreshData = () => {
    setPaginationModel((previousState) => ({ ...previousState, page: 0 }))
    const currentState = apiReference.current.exportState()

    if (currentState.pagination) {
      dispatch(
        fetchOperations({
          page: currentState.pagination?.paginationModel?.page as number,
          pageSize: currentState.pagination?.paginationModel?.pageSize as number,
        }),
      ).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
          tableScrollTop()
        }
      })
    }
  }

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => <CustomDetailPanelContent onDataRefresh={handleRefreshData} row={row} />,
    [],
  )

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const fetchOperationsInitial = useCallback(
    async (page: number, pageSize: number) => {
      try {
        await dispatch(fetchOperations({ page, pageSize })).unwrap()
      } catch (error) {
        showBoundary(error)
      }
    },
    [showBoundary],
  )

  const fetchOperationsGrid = useCallback(
    async (page: number, pageSize: number) => {
      dispatch(fetchOperations({ page, pageSize })).then((result: TAny) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType })
          setFetchingError(true)
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false)
        }
      })
    },
    [dispatch],
  )

  const repeatFetchingRequest = useCallback(async () => {
    await fetchOperationsGrid(0, 25)
  }, [])

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters())
    await dispatch(fetchOperations({ page: paginationModel.page, pageSize: paginationModel.pageSize }))
  }, [])

  const resetIdFilter = useCallback(async () => {
    dispatch(removeFilter({ columnId: 'id' }))
  }, [])

  const initialFetchData = useCallback(async () => {
    dispatch(setOperationsStatus('loading'))
    await dispatch(fetchFilters('operations'))
    await dispatch(fetchSortingColumns('operations'))

    if (filters) {
      dispatch(setSortingData([defaultOperationsSortingModel]))
      await fetchOperationsInitial(paginationModel.page, paginationModel.pageSize)
    }
  }, [dispatch, fetchOperationsInitial, paginationModel, filters])

  useEffect(() => {
    dispatch(clearFilters())
    initialFetchData()
  }, [])

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      display: 'flex',
      renderCell: (parameters) => <RenderDetailPanelToggle id={parameters.id} value={parameters.value} />,
    },
    {
      minWidth: 170,
      display: 'flex',
      field: 'userFriendlyOperationId',
      flex: 0.5,
      headerName: 'User operation ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      minWidth: 160,
      display: 'flex',
      field: 'id',
      flex: 0.5,
      headerName: 'Operation ID',
      renderCell: (parameters) => <RenderCellWithCopy props={parameters} isWrappedText />,
      sortable: false,
    },
    {
      minWidth: 160,
      display: 'flex',
      field: 'operationType',
      flex: 0.5,
      headerName: 'Operation type',
      renderCell: (parameters) => <RenderOperationTypeCell props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 130,
      display: 'flex',
      field: 'createDate',
      flex: 0.3,
      headerName: 'Creation date',
      renderCell: (parameters) => <RenderCreationDateCell props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 110,
      display: 'flex',
      field: 'status',
      flex: 0.3,
      headerName: 'Status',
      renderCell: (parameters) => <RenderStatusCell props={parameters} />,
      sortable: false,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'price',
      flex: 0.4,
      headerName: 'Amount',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'platformFeeClient',
      flex: 0.4,
      headerName: 'Fee',
      renderCell: (parameters) => <RenderFeeCell props={parameters} />,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'total',
      flex: 0.4,
      headerName: 'Total',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      renderHeader: (parameters) => <RenderHeaderWithSecondaryText props={parameters} secondaryText=" (P)" tooltipTitle="For payer" />,
      sortable: false,
      type: 'number' as const,
    },
    {
      minWidth: 120,
      display: 'flex',
      field: 'totalAfterAllFees',
      flex: 0.4,
      headerName: 'Received ',
      renderCell: ({ value }) => <span>{Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—'}</span>,
      renderHeader: (parameters) => <RenderHeaderWithSecondaryText props={parameters} secondaryText="(R)" tooltipTitle="For recipient" />,
      sortable: false,
      type: 'number' as const,
    },
    // TODO Additional (after release) Net parameter
    // {
    //   field: 'netParam',
    //   headerName: 'Net',
    //   flex: 1,
    //   sortable: false,
    //   type: 'number' as const,
    //   valueFormatter: (value) => {
    //     return Number(value) ? currencyFormatter(value, 'en-US', 'USD', true) : '—';
    //   },
    // },
  ]

  const rows: [] | IOperation[] = useMemo(() => {
    if (data) {
      return data
    } else return []
  }, [data])

  const isEmptyData = !rows.length
  const isDisabledFilterButton = !((!isEmptyData && !fetchingError) || !!filtersLength)

  const handleSortingModelApply = async () => {
    await fetchOperationsGrid(paginationModel.page, paginationModel.pageSize)
  }

  return (
    <div className="operations-table-container">
      <div className="operations-table-container__actions">
        <Stack alignItems="center" direction="row" gap={2}>
          <Button disabled={isDisabledFilterButton} onClick={() => setFilterDrawer(true)} size="small" startIcon={<FilterIcon />} variant="outlined">
            Filter {!!filtersLength && `(${filtersLength})`}
          </Button>
          <SortingChip
            defaultSortingModel={defaultOperationsSortingModel}
            disabled={isEmptyData}
            onSortingModelApply={() => handleSortingModelApply()}
            preferableOrder="DESC"
          />
        </Stack>
        <OperationsSearch clearFilter={() => resetIdFilter()} refreshData={() => repeatFetchingRequest()} />
      </div>
      {filtersLength !== 0 && <ActiveFilterList refreshData={handleRefreshData} />}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ inset: 0, position: 'absolute' }}>
          <DataGrid
            hideFooter={isEmptyData && !fetchingError}
            hideFooterSelectedRowCount
            slots={{
              footer: CustomFooter,
              noRowsOverlay: () =>
                CustomNoRowsOverlay({
                  clearFilters: () => resetFilters(),
                  fetchingError: fetchingError,
                  isFiltersApplied: !!filtersLength,
                  refreshData: () => repeatFetchingRequest(),
                  renderNoDataComponent: () => (
                    <Typography color="text.secondary" variant="body2">
                      No operation has been performed
                    </Typography>
                  ),
                }),
            }}
            apiRef={apiReference}
            className="operations-table"
            columnHeaderHeight={64}
            columns={columns}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            filterMode="server"
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            getRowClassName={(parameters) => `${parameters.row.id === detailPanelExpandedRowIds[0] ? 'Mui-selected' : ''}`}
            getRowId={(row) => row.id}
            loading={isLoading}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            onPaginationModelChange={handlePaginationModelChange}
            onRowClick={handleRowClick}
            pageSizeOptions={[10, 25, 50]} //TODO back не может переварить 100 записей (ломается на 86)
            paginationMode="server"
            paginationModel={paginationModel}
            rowCount={totalCount}
            rowHeight={68}
            rows={rows}
            sortingMode="server"
            disableColumnMenu
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
            pagination
          />
        </Box>
      </Box>
      <CloseFilterDrawerConfirmModal
        closeModal={() => cancelCloseFilterSidebar()}
        onClose={() => onFilterSidebarClose(true)}
        open={filterDrawerCloseConfirmModal}
      />
      <Drawer anchor="right" onClose={() => onFilterSidebarClose()} open={filterDrawer}>
        <FilterDrawer
          isHidePresets
          onApply={() => {
            onFilterSidebarClose(true)
            setPaginationModel((previousState) => ({ page: 0, pageSize: previousState.pageSize }))
            fetchOperationsGrid(0, paginationModel.pageSize).then(tableScrollTop)
          }}
          onClose={onFilterSidebarClose}
          type="operations"
        />
      </Drawer>
    </div>
  )
}
