import './index.scss'

import { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { enqueueSnackbar, VariantType } from 'notistack'

import CloseIcon from '@admin/assets/img/CloseIcon'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import UserProfile from '@admin/components/UserProfile/UserProfile'
import { ErrorBoundaryErrors } from '@admin/containers/ErrorPage/ErrorPage'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchUserProfile, selectUserProfile } from '@admin/store/userProfileSlice'
import colors from '@admin/theme/constants/colors'

import type { TAny } from '@yzzy/types'

interface IProps {
  currentUserId?: string
  onClose: () => void
  open: boolean
}

const UserProfileModal = ({ currentUserId, onClose, open }: IProps) => {
  const dispatch = useAppDispatch()

  const { userId } = useParams()
  const usedUserId = currentUserId ?? userId

  const userProfileData = useAppSelector(selectUserProfile)
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false)

  useEffect(() => {
    if (usedUserId) {
      dispatch(fetchUserProfile(usedUserId)).then((result: TAny) => {
        if (result.meta?.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(result.error.message)) {
          onClose()
          enqueueSnackbar('Loading user’s card error, try again later', { variant: 'error' as VariantType })
        }
      })
    }
  }, [usedUserId])

  useLayoutEffect(() => {
    const item = document.querySelector('.MuiDialogContent-root')

    setIsScrollVisible(item?.clientHeight !== item?.scrollHeight)
  })

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{
        '& ::-webkit-scrollbar-thumb': {
          border: `8px solid ${colors.backgroundModal}`,
        },

        '& ::-webkit-scrollbar-track': {
          background: 'transparent',
        },
      }}
      className="user-profile-modal"
      onClose={onClose}
      open={open}
    >
      <DialogTitle className="user-profile-modal__header" variant="h6">
        User #{usedUserId}
        {/* <Link to={`/ui/users/user/${usedUserId}`} target="_blank">
            <Button size="small" startIcon={<LinkIcon />}>
              Show in YZZY users
            </Button>
          </Link> */}
        <IconButton className="user-profile-modal__header-close" onClick={onClose} size="medium">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={isScrollVisible ? 'scrollable' : ''} sx={{ backgroundColor: colors.backgroundModal }}>
        {userProfileData.main?.userId !== usedUserId ? (
          <div className="user-profile-modal__overlay">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className="user-profile-modal__content">
            <div className="user-profile-modal__container">
              <UserProfile />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default UserProfileModal
