import './index.scss'

import { useCallback, useEffect, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link, useParams } from 'react-router-dom'

import { Menu, MenuItem } from '@mui/material'
import find from 'lodash/find'
import { enqueueSnackbar } from 'notistack'

import ArrowBack from '@admin/assets/img/ArrowBack'
import ArrowDropDownIcon from '@admin/assets/img/ArrowDropDownIcon'
import ArrowDropUpIcon from '@admin/assets/img/ArrowDropUpIcon'
import CheckCircleFilledIcon from '@admin/assets/img/CheckCircleFilledIcon'
import ChevronRightIcon from '@admin/assets/img/ChevronRightIcon'
import DeleteIcon from '@admin/assets/img/DeleteIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import RestoreIcon from '@admin/assets/img/RestoreIcon'
import WorldSendIcon from '@admin/assets/img/WorldSendIcon'
import ManageLinkedCollectionsModal from '@admin/components/ManageLinkedCollections/ManageLinkedCollectionsModal'
import Alert from '@admin/components/shared/Alert/Alert'
import Breadcrumbs from '@admin/components/shared/Breadcrumbs/Breadcrumbs'
import Button from '@admin/components/shared/Button/Button'
import CircularProgress from '@admin/components/shared/CircularProgress/CircularProgress'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import LinkMUI from '@admin/components/shared/Link/Link'
import LoadingButton from '@admin/components/shared/LoadingButton/LoadingButton'
import Modal from '@admin/components/shared/Modal/Modal'
import Stack from '@admin/components/shared/Stack/Stack'
import TextField from '@admin/components/shared/TextField/TextField'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import UserProfile from '@admin/components/UserProfile/UserProfile'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import {
  fetchDeleteUser,
  fetchRestoreUser,
  fetchUserProfile,
  selectDeleteUserStatus,
  selectRestoreUserStatus,
  selectUserProfile,
  selectUserProfileStatus,
} from '@admin/store/userProfileSlice'
import { stringCapitalize } from '@admin/utils//stringCapitalize'

import { possibleUserStatuses } from './constant'
import { IUserStatus } from './interface'
import { ChangeUserStatusModal } from './modals'

function UserProfilePage() {
  const { userId } = useParams()
  const dispatch = useAppDispatch()
  const { showBoundary } = useErrorBoundary()

  const loadingStatus = useAppSelector(selectUserProfileStatus)
  const deleteStatus = useAppSelector(selectDeleteUserStatus)
  const restoreStatus = useAppSelector(selectRestoreUserStatus)
  const data = useAppSelector(selectUserProfile)
  const status = useAppSelector(selectUserProfileStatus)

  const isLoadingUserProfile = status === 'loading'

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [openLinkedCollections, setOpenLinkedCollections] = useState(false)

  const open = Boolean(anchorElement)

  useEffect(() => {
    setCurrentSelectedStatus(find(possibleUserStatuses, { status: data.main.status }) as IUserStatus)
  }, [data])
  const handleStatusButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleStatusMenuClose = () => {
    setAnchorElement(null)
  }

  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false)
  const [cancelDeleteUserModal, setCancelDeleteUserModal] = useState<boolean>(false)
  const [restoreUserModal, setRestoreUserModal] = useState<boolean>(false)
  const [deletingError, setDeletingError] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [dirty, setDirty] = useState<boolean>(false)
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<IUserStatus | null>(null)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false)
  const [isDeleteResultReceived, setIsDeleteResultReceived] = useState(false)
  const [isRestoreResultReceived, setIsRestoreResultReceived] = useState(false)

  const deleteUser = useCallback(async () => {
    setIsDeleteResultReceived(false)

    const deleteTimer = setTimeout(() => {
      if (!isDeleteResultReceived) {
        enqueueSnackbar(`User #${userId} deleting in progress`, {
          variant: 'info',
        })
      }
    }, 10000)

    try {
      const result = await dispatch(fetchDeleteUser({ comment: comment, profileId: userId! }))

      setIsDeleteResultReceived(true)
      clearTimeout(deleteTimer)

      if (result.meta.requestStatus === 'fulfilled') {
        setDeletingError(false)
        setDeleteUserModal(false)
        setComment('')
        setDirty(false)
        enqueueSnackbar(`User #${userId} has been deleted`, { variant: 'success' })
        dispatch(fetchUserProfile(userId!))
      } else {
        setDeletingError(true)
        setDeleteUserModal(false)
        enqueueSnackbar('Deleting error, try again later', { variant: 'error' })
      }
    } catch {
      setIsDeleteResultReceived(true)
      clearTimeout(deleteTimer)

      setDeletingError(true)
      enqueueSnackbar('Deleting error, try again later', { variant: 'error' })
    }
  }, [isDeleteResultReceived, userId, dispatch, comment])

  const restoreUser = useCallback(async () => {
    setIsRestoreResultReceived(false)

    const restoreTimer = setTimeout(() => {
      if (!isRestoreResultReceived) {
        enqueueSnackbar(`User #${userId} restoring in progress`, {
          variant: 'info',
        })
      }
    }, 10000)

    try {
      const result = await dispatch(fetchRestoreUser({ profileId: userId! }))

      setIsRestoreResultReceived(true)
      clearTimeout(restoreTimer)

      if (result.meta.requestStatus === 'fulfilled') {
        setRestoreUserModal(false)
        enqueueSnackbar(`User #${userId} has been restored`, { variant: 'success' })
        dispatch(fetchUserProfile(userId!))
      } else {
        setRestoreUserModal(false)
        enqueueSnackbar(`Restoring error, try again later`, { variant: 'error' })
      }
    } catch {
      setIsRestoreResultReceived(true)
      clearTimeout(restoreTimer)

      setRestoreUserModal(false)
      enqueueSnackbar(`Restoring error, try again later`, { variant: 'error' })
    }
  }, [isRestoreResultReceived, userId, dispatch])

  const closeDeleteModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setDeleteUserModal(false)
  }, [])

  const closeRestoreModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setRestoreUserModal(false)
  }, [])

  const closeCancelDeleteModal = useCallback((_event?: {}, reason?: string) => {
    if (reason && reason === 'backdropClick') return
    setCancelDeleteUserModal(false)
    setDeleteUserModal(false)
    setComment('')
    setDirty(false)
  }, [])

  const handleChangeStatusClick = async (selectedStatus: IUserStatus) => {
    if (selectedStatus.status.toLowerCase() === data.main.status?.toLowerCase()) {
      handleStatusMenuClose()
    } else {
      setCurrentSelectedStatus(selectedStatus)
      setShowChangeStatusModal(true)
      handleStatusMenuClose()
    }
  }

  useEffect(() => {
    initialFetchProfile()
  }, [])

  const initialFetchProfile = useCallback(async () => {
    try {
      if (userId) {
        await dispatch(fetchUserProfile(userId)).unwrap()
      }
    } catch (error) {
      showBoundary(error)
    }
  }, [userId])

  return (
    <div className="userProfile--container">
      {isLoadingUserProfile && <CircularProgress sx={{ m: 'auto' }} />}
      <div className="userProfile--header" style={{ display: loadingStatus === 'loading' ? 'none' : 'flex' }}>
        <div className="userProfile--header__breadcrumbs">
          <Link color="default" to={`/ui/users/${((data.main.status || '') as string).toLowerCase()}`}>
            <IconButton color="default" size="small">
              <ArrowBack />
            </IconButton>
          </Link>
          <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
            <LinkMUI color="text.secondary" component={Link} to="/ui/users" underline="hover" variant="h6">
              YZZY users
            </LinkMUI>
            <LinkMUI
              color="text.secondary"
              component={Link}
              to={`/ui/users/${((data.main.status || '') as string).toLowerCase()}`}
              underline="hover"
              variant="h6"
            >
              {loadingStatus !== 'loading' &&
                data.main.status &&
                `${stringCapitalize(data.main.status === 'ACTIVE' ? 'Actual' : data.main.status)} users`}
            </LinkMUI>
            <Typography color="text.primary" variant="h6">
              {`YZZY user #${userId}`}
            </Typography>
          </Breadcrumbs>
        </div>
        <Stack direction="row" gap={2} height={36}>
          {/*<Link href="/ui/users" underline="none" sx={{ marginRight: '16px' }}>*/}
          <Button
            // disabled={data.main.status !== 'ACTIVE'}
            onClick={() => setOpenLinkedCollections(true)}
            startIcon={<WorldSendIcon />}
            variant="outlined"
            disabled
          >
            Top Search
          </Button>
          {/*</Link>*/}
          <Button
            color={find(possibleUserStatuses, { status: data.main.status } as IUserStatus)?.color || 'primary'}
            disabled={find(possibleUserStatuses, { status: data.main.status } as IUserStatus) == undefined}
            endIcon={anchorElement ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            onClick={handleStatusButtonClick}
            sx={{ minWidth: 106 }}
            variant="contained"
          >
            {find(possibleUserStatuses, { status: data.main.status } as IUserStatus) == undefined ? 'Active' : data.main.status}
          </Button>
          <Menu
            id="user-status-menu"
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            anchorEl={anchorElement}
            onClose={handleStatusMenuClose}
            open={open}
            PaperProps={{ sx: { width: anchorElement?.offsetWidth || 106 } }}
          >
            {possibleUserStatuses.map((item) => (
              <MenuItem
                key={item.title}
                onClick={() => handleChangeStatusClick(item)}
                selected={item.status?.toLowerCase() === data.main.status?.toLowerCase()}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </div>
      <div className="userProfile--content">
        <div style={{ display: loadingStatus === 'loading' ? 'none' : 'block' }}>
          <UserProfile />
        </div>
        <div className="userProfile--deleteUser" style={{ visibility: loadingStatus === 'loading' ? 'hidden' : 'visible' }}>
          {data.main.status && data.main.status !== 'DELETED' && (
            <Button color="error" onClick={() => setDeleteUserModal(true)} startIcon={<DeleteIcon />}>
              Delete user
            </Button>
          )}
          {data.main.status && data.main.status === 'DELETED' && (
            <Button className="userProfile--restoreUserButton" onClick={() => setRestoreUserModal(true)} startIcon={<RestoreIcon />}>
              Restore user
            </Button>
          )}
        </div>
      </div>

      {userId && openLinkedCollections && (
        <ManageLinkedCollectionsModal
          entityId={userId}
          entityType="HERO"
          onClose={() => setOpenLinkedCollections(false)}
          open={openLinkedCollections}
        />
      )}
      {deleteUserModal && (
        <Modal customstyle={{ width: 600 }} onClose={closeDeleteModal} open={deleteUserModal}>
          <div className="defaultModal--content deleteUserModal">
            <div className="defaultModal--header">You are going to delete user #{data.main.userId}</div>
            <div className="deleteUserModal--textContainer">
              <Typography color="text.secondary" variant="body1">
                Be sure if you checked following conditions:
              </Typography>
              <div className="deleteUserModal--conditionText">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                User has no active paid content
              </div>
              <div className="deleteUserModal--conditionText">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                User either had no wallet or the wallet has been blocked
                <Tooltip title="You can block it on the Stripe panel if user fits following:" placement="top-end" followCursor>
                  <IconButton className="deleteUserModal--infoButton" disableRipple>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                All user's balances are zero
                <Tooltip
                  title={
                    <div>
                      All balances must be zero, including:
                      <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                        <li>Balance</li>
                        <li>Available to withdrawal</li>
                        <li>Pending (Withdrawn)</li>
                        <li>Pending (Earned)</li>
                      </ul>
                    </div>
                  }
                  placement="top-end"
                  followCursor
                >
                  <IconButton className="deleteUserModal--infoButton" disableRipple>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                Yearly user's payouts sum is less than $599.99
                <Tooltip
                  title={
                    <>
                      <div>
                        If yearly payouts sum is more or equal $599.99 we still can delete user but should keep their data for tax authorities.
                      </div>
                      <div>You can check it on the Stripe panel</div>
                    </>
                  }
                  componentsProps={{
                    tooltip: {
                      sx: { maxWidth: 346 },
                    },
                  }}
                  placement="top-end"
                  followCursor
                >
                  <IconButton className="deleteUserModal--infoButton" disableRipple>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                There are more than 7 days passed after last user's transaction
                <Tooltip
                  title={
                    <>
                      <div>It’s obligatory to wait 7 days due to refund policy.</div>
                      <div>You can check it on the Payments module</div>
                    </>
                  }
                  placement="top-end"
                  followCursor
                >
                  <IconButton className="deleteUserModal--infoButton" disableRipple>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                label="Comment (optional)"
                onChange={(event) => {
                  setDirty(true)
                  setComment(event.target.value)
                }}
                className="deleteUserModal--commentField"
                inputProps={{ maxLength: 640 }}
                maxRows={5}
                minRows={5}
                value={comment}
                multiline
              />
            </div>
            {deletingError ? (
              <Alert severity="error">Deleting error, try again later</Alert>
            ) : (
              <Alert severity="info">
                <div>User will be moved to Deleted users tab for 45 days and can be restored in that term if it will be necessary.</div>
                <div>After 45 days user will be deleted permanently.</div>
              </Alert>
            )}
            <div className="defaultModal--actions">
              <Button
                onClick={() => {
                  if (dirty) {
                    setCancelDeleteUserModal(true)
                  } else {
                    closeCancelDeleteModal()
                  }
                }}
                className="deleteUserModal--cancelButton"
              >
                Cancel
              </Button>
              <LoadingButton
                className="deleteUserModal--deleteButton defaultModal--errorButton"
                loading={deleteStatus === 'loading'}
                onClick={() => deleteUser()}
                variant="contained"
              >
                Delete user
              </LoadingButton>
            </div>
          </div>
        </Modal>
      )}
      {cancelDeleteUserModal && (
        <Modal customstyle={{ minHeight: 160 }} onClose={closeCancelDeleteModal} open={cancelDeleteUserModal}>
          <div className="defaultModal--content">
            <div className="defaultModal--header">Cancel deleting user?</div>
            <div className="defaultModal--text">Changes won't be applied</div>
            <div className="defaultModal--actions">
              <Button onClick={() => setCancelDeleteUserModal(false)} style={{ marginRight: '16px' }}>
                Don't cancel
              </Button>
              <Button onClick={() => closeCancelDeleteModal()} type="submit" variant="contained">
                Cancel deleting
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {restoreUserModal && (
        <Modal customstyle={{ maxWidth: 600, minHeight: 100 }} onClose={closeRestoreModal} open={restoreUserModal}>
          <div className="defaultModal--content">
            <div className="defaultModal--header">You are going to restore user #{data.main.userId}</div>
            <div className="defaultModal--text">User will be moved to Actual users tab</div>
            <div className="defaultModal--actions">
              <Button onClick={() => closeRestoreModal()} style={{ marginRight: '16px' }}>
                Cancel
              </Button>
              <LoadingButton loading={restoreStatus === 'loading'} onClick={() => restoreUser()} type="submit" variant="contained">
                Restore user
              </LoadingButton>
            </div>
          </div>
        </Modal>
      )}

      <ChangeUserStatusModal
        currentSelectedStatus={currentSelectedStatus}
        onClose={() => setShowChangeStatusModal(false)}
        onDataRefresh={() => dispatch(fetchUserProfile(userId!))}
        open={showChangeStatusModal}
        profileId={userId as string}
      />
    </div>
  )
}

export default UserProfilePage
