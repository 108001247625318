import { useState } from 'react'

import EditIcon from '@admin/assets/img/EditIcon'
import ImageIcon from '@admin/assets/img/ImageIcon'
import RefreshIcon from '@admin/assets/img/RefreshIcon'
import UserBroken from '@admin/assets/img/UserBroken.png'
import '@admin/components/Profile/MemberProfile/MemberAvatar/index.scss'
import Button from '@admin/components/shared/Button/Button'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import { TAvatarAction } from '@admin/containers/Profile/Profile'
import { IMedia } from '@admin/types/commonTypes'

interface IProps {
  avatarPreview: IMedia
  setShowSetAvatar(action: TAvatarAction): void
}

const MemberAvatar = ({ avatarPreview, setShowSetAvatar }: IProps) => {
  const [isError, setIsError] = useState(false)

  return (
    <div className="memberAvatar">
      {avatarPreview.originalLink && avatarPreview.originalLink !== '' ? (
        <div className="memberAvatar-imageContainer">
          <div className="memberAvatar-imageContainer-buttons">
            <Tooltip title="Edit photo" placement="top-start">
              <span className={isError ? 'memberAvatar-imageContainer-buttons_disabled' : ''}>
                <IconButton disabled={isError} onClick={() => setShowSetAvatar('edit')}>
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Replace photo" placement="top-start">
              <span>
                <IconButton onClick={() => setShowSetAvatar('replace')}>
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <img
            onError={(event) => {
              event.currentTarget.src = UserBroken
              setIsError(true)
            }}
            alt="avatar"
            src={avatarPreview.originalLink}
          />
        </div>
      ) : (
        <div className="memberAvatar-imageContainer memberAvatar-imageContainer_noImage">
          <Button onClick={() => setShowSetAvatar('add')} startIcon={<ImageIcon />} variant="outlined">
            Add Photo
          </Button>
        </div>
      )}
    </div>
  )
}

export default MemberAvatar
