const UsernameIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M11.9597 3.87505C7.54605 3.87505 4.59116 7.08647 4.09077 10.0688C3.82145 11.674 3.85162 12.8807 4.09206 13.9194C4.33277 14.9593 4.79723 15.8881 5.47969 16.9281C6.93267 19.1421 9.72166 20.125 11.9597 20.125C13.6734 20.125 15.7028 19.1981 16.5045 18.7194C16.9075 18.4787 17.4294 18.6103 17.6701 19.0134C17.9108 19.4164 17.7792 19.9382 17.3761 20.1789C16.4744 20.7174 14.1269 21.8251 11.9597 21.8251C9.34676 21.8251 5.92062 20.6984 4.05841 17.8608C3.31514 16.7282 2.73843 15.6099 2.43585 14.3028C2.13299 12.9945 2.11775 11.5544 2.4142 9.78753C3.04534 6.02594 6.67614 2.17505 11.9597 2.17505C17.151 2.17505 21.7611 5.86097 21.7995 11.0841C21.7998 11.0922 21.7999 11.1004 21.7999 11.1086V13.4217C21.7999 15.2851 20.2893 16.7956 18.4259 16.7956C17.2048 16.7956 16.1352 16.1469 15.5429 15.1752C14.6596 16.1693 13.3714 16.7956 11.9369 16.7956C9.27354 16.7956 7.11446 14.6365 7.11446 11.9732C7.11446 9.30982 9.27354 7.15074 11.9369 7.15074C13.1244 7.15074 14.2117 7.57997 15.052 8.29174V7.9878C15.052 7.51836 15.4326 7.1378 15.902 7.1378C16.3714 7.1378 16.752 7.51836 16.752 7.9878V11.705C16.7569 11.7938 16.7593 11.8832 16.7593 11.9732C16.7593 12.0632 16.7569 12.1526 16.752 12.2414V13.4217C16.752 14.3462 17.5014 15.0956 18.4259 15.0956C19.3504 15.0956 20.0999 14.3462 20.0999 13.4217V11.1634C20.0998 11.1589 20.0998 11.1544 20.0998 11.1499C20.0998 7.02801 16.444 3.87505 11.9597 3.87505ZM15.052 11.7575C14.9412 10.1336 13.5889 8.85074 11.9369 8.85074C10.2124 8.85074 8.81446 10.2487 8.81446 11.9732C8.81446 13.6977 10.2124 15.0956 11.9369 15.0956C13.5889 15.0956 14.9412 13.8127 15.052 12.1889V11.7575Z"
      fill="#BFC6E2"
      fillRule="evenodd"
    />
  </svg>
)

export default UsernameIcon
