// todo: fixed yup schema
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import './index.scss'

import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar, VariantType } from 'notistack'

import RulesModalCancel from '@admin/components/Moderation/RulesModalContent/RulesModalCancel'
import getValidationSchema from '@admin/components/Moderation/RulesModalContent/utils/getValidationSchema'
import { Rules } from '@admin/components/Moderation/types/ModerationTabs'
import Alert from '@admin/components/shared/Alert/Alert'
import Button from '@admin/components/shared/Button/Button'
import Divider from '@admin/components/shared/Divider/Divider'
import FormHelperText from '@admin/components/shared/FormHelperText/FormHelperText'
import Modal from '@admin/components/shared/Modal/Modal'
import Select, { ISelectOptions } from '@admin/components/shared/Select/Select'
import TextField from '@admin/components/shared/TextField/TextField'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchAddRule, fetchDeleteRule, fetchUpdateRule, selectRulesStatus } from '@admin/store/rulesSlice'

import type { TAny } from '@yzzy/types'

interface IProps {
  contentType: Rules
  item: TAny
  onClose(): void
}

const RulesModalContent = ({ contentType, item, onClose }: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isShowError, setIsShowError] = useState(false)
  const dispatch = useAppDispatch()

  const status = useAppSelector(selectRulesStatus)
  const schema = useMemo(() => getValidationSchema(contentType, item.action), [contentType, item.action])

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm({
    defaultValues:
      item.action === 'delete'
        ? { id: item.rule.id, metric: item.rule.metric }
        : {
            id: item.rule.id,
            countForPeriod: item.rule.countForPeriod,
            decision: item.rule.decision,
            decisionMinScore: item.rule.decisionMinScore,
            decisionPeriodCount: item.rule.decisionPeriodCount,
            decisionPeriodUnit: item.rule.decisionPeriodUnit,
            metric: item.rule.metric,
            periodCount: item.rule.periodCount,
            periodUnit: item.rule.periodUnit,
            profileDecision:
              item.rule.profileImplicationDto?.profileDecision || item.rule.profileDecision || contentType === Rules.PROFILE || item.action === 'add'
                ? item.rule.profileImplicationDto?.profileDecision || item.rule.profileDecision
                : 'NO_IMPLICATIONS',
            restrictions: item.rule.restrictions,
            reviewMinScore: item.rule.reviewMinScore,
            term: item.rule.term,
            type: item.rule.type || 'VISUAL',
          },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: TAny) => {
    trigger()

    if (item.action === 'delete') {
      try {
        const result = await dispatch(fetchDeleteRule({ data, type: contentType }))

        if (result.meta.requestStatus === 'fulfilled') {
          onClose()
          enqueueSnackbar(`${data.metric} rule deleted`, { variant: 'success' as VariantType })
        }

        if (result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Deleting error, try again later', { variant: 'error' as VariantType })
        }
      } catch {
        enqueueSnackbar('Deleting error, try again later', { variant: 'error' as VariantType })
      }

      return
    }

    let newData

    if (contentType === Rules.PROFILE) {
      newData = {
        ...data,
        profileDecision: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.profileDecision,
      }
    } else {
      newData = {
        id: data.id,
        decision: data.decision,
        decisionMinScore: data.decisionMinScore,
        metric: data.metric,
        profileImplicationDto:
          data.profileDecision === 'NO_IMPLICATIONS'
            ? null
            : {
                countForPeriod: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.countForPeriod,
                decisionPeriodCount: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.decisionPeriodCount,
                decisionPeriodUnit: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.decisionPeriodUnit,
                periodCount: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.periodCount,
                periodUnit: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.periodUnit,
                profileDecision: data.profileDecision === 'NO_IMPLICATIONS' ? undefined : data.profileDecision,
                term: data.profileDecision === 'NO_IMPLICATIONS' ? null : data.term,
              },
        restrictions: data.decision !== 'LIMIT' ? null : data.restrictions,
        reviewMinScore: data.reviewMinScore,
        type: data.type,
      }
    }
    if (item.action === 'add') {
      try {
        const result = await dispatch(fetchAddRule({ data: newData, type: contentType }))

        if (result.meta.requestStatus === 'fulfilled') {
          onClose()
          enqueueSnackbar(`${newData.metric} rule has been added`, { variant: 'success' as VariantType })
        }

        if (result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Creation error, try again later', { variant: 'error' as VariantType })
        }

        setIsShowError(status === 'failed')
      } catch {
        setIsShowError(true)
        enqueueSnackbar('Creation error, try again later', { variant: 'error' as VariantType })
      }

      return
    }

    try {
      const result = await dispatch(fetchUpdateRule({ data: newData, type: contentType }))

      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
        enqueueSnackbar(`Changes of ${newData.metric} rule has been saved`, { variant: 'success' as VariantType })
      }

      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
      }

      setIsShowError(status === 'failed')
    } catch {
      setIsShowError(true)
      enqueueSnackbar('Saving error, try again later', { variant: 'error' as VariantType })
    }
  }

  return (
    <form className={item.action === 'delete' ? 'rulesModal rulesModal_delete' : 'rulesModal'} onSubmit={handleSubmit(onSubmit)}>
      <div className="rulesModal-header">
        {item.action === 'add' && `New ${contentType} rule`}
        {item.action === 'edit' && `Edit ${item.rule.metric} rule`}
        {item.action === 'delete' && `You are going to delete ${item.rule.metric} rule`}
      </div>
      <div className="rulesModal-content">
        {item.action === 'delete' && <span className="rulesModal-content-value_delete">It’s irreversible action</span>}
        {item.action === 'add' && (
          <>
            <div className="rulesModal-content-name">Category</div>
            <Select
              {...register('metric')}
              name="metric"
              options={item.availableMetrics.map(
                (metric: string) =>
                  ({
                    text: metric,
                    value: metric,
                  }) as ISelectOptions,
              )}
              className="rulesModal-content-value"
              defaultValue={item.availableMetrics[0] || ''}
              error={!!errors.metric}
              size="small"
              sx={{ width: '330px' }}
              variant="outlined"
            />
            <Divider orientation="horizontal" />
          </>
        )}
        {(item.action === 'add' || item.action === 'edit') && (
          <>
            <div className="rulesModal-content-group rulesModal-content-group_sb">
              {contentType === Rules.HIVE && (
                <div>
                  <div className="rulesModal-content-name">Review score</div>
                  <TextField
                    {...register('reviewMinScore')}
                    label="From"
                    name="reviewMinScore"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.reviewMinScore || 0.6}
                    error={!!errors.reviewMinScore}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                </div>
              )}
              {contentType === Rules.HIVE && (
                <div>
                  <div className="rulesModal-content-name">Confirmation score</div>
                  <TextField
                    {...register('decisionMinScore')}
                    label="From"
                    name="decisionMinScore"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.decisionMinScore || 0.9}
                    error={!!errors.decisionMinScore}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                </div>
              )}
              {contentType !== Rules.PROFILE && (
                <div>
                  <div className="rulesModal-content-name">Decision</div>
                  <Select
                    {...register('decision')}
                    name="decision"
                    onChange={(event) => {
                      setValue('decision', event.target.value)
                      void trigger()
                    }}
                    options={[
                      { text: 'Delete entity', value: 'DELETE' },
                      { text: 'Limit entity', value: 'LIMIT' },
                    ]}
                    className="rulesModal-content-value"
                    defaultValue={item.rule.decision || 'DELETE'}
                    error={!!errors.decision}
                    size="small"
                    sx={{ width: '176px' }}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
            {errors.reviewMinScore && <FormHelperText error>{errors.reviewMinScore?.message as string}</FormHelperText>}
            {errors.decisionMinScore && <FormHelperText error>{errors.decisionMinScore?.message as string}</FormHelperText>}
            {contentType !== Rules.PROFILE && <Divider orientation="horizontal" />}

            {getValues('decision') === 'LIMIT' && (
              <>
                <div className="rulesModal-content-group rulesModal-content-group_fs">
                  <div>
                    <div className="rulesModal-content-name">Restrictions</div>
                    <Select
                      className="rulesModal-content-value"
                      options={[{ text: 'Age restrict', value: 'age' }]}
                      size="small"
                      sx={{ width: '200px' }}
                      value={'age'}
                      variant="outlined"
                    />
                  </div>
                  <Select
                    className="rulesModal-content-value"
                    disabled={true}
                    options={[{ text: '18', value: '18' }]}
                    placeholder="Minimal age"
                    size="small"
                    sx={{ width: '120px' }}
                    value={'18'}
                    variant="outlined"
                  />
                </div>
                <Divider orientation="horizontal" />
              </>
            )}
            <div className="rulesModal-content-group rulesModal-content-group_fs">
              <div>
                <div className="rulesModal-content-name">Profile implications</div>
                <Controller
                  name="profileDecision"
                  render={({ field: { value } }) => (
                    <Select
                      {...register('profileDecision')}
                      onChange={(event) => {
                        setValue('profileDecision', event.target.value)
                        void trigger()
                      }}
                      options={
                        contentType !== Rules.PROFILE
                          ? [
                              { text: 'Prohibit content creation', value: 'BLOCK_PUBLICATION' },
                              { text: 'Ban profile', value: 'BAN_PROFILE' },
                              { text: 'No implications', value: 'NO_IMPLICATIONS' },
                            ]
                          : [
                              { text: 'Prohibit content creation', value: 'BLOCK_PUBLICATION' },
                              { text: 'Ban profile', value: 'BAN_PROFILE' },
                            ]
                      }
                      className=""
                      error={!!errors?.profileDecision}
                      placeholder="Implication"
                      size="small"
                      sx={{ width: '240px' }}
                      value={value}
                      variant="outlined"
                    />
                  )}
                  control={control}
                  defaultValue={item.rule.profileImplicationDto?.profileDecision || item.rule.profileDecision || 'BLOCK_PUBLICATION'}
                />
              </div>
              {getValues('profileDecision') !== 'NO_IMPLICATIONS' && (
                <>
                  <Select
                    {...register('term')}
                    name="term"
                    onChange={(event) => {
                      setValue('term', event.target.value)
                      void trigger()
                    }}
                    options={[
                      { text: 'for period', value: 'FOR_PERIOD' },
                      { text: 'permanently', value: 'PERMANENTLY' },
                    ]}
                    className=""
                    defaultValue={item.rule.profileImplicationDto?.term || item.rule.term || 'FOR_PERIOD'}
                    error={!!errors.term}
                    placeholder="Term"
                    size="small"
                    sx={{ width: '152px' }}
                    variant="outlined"
                  />
                  {getValues('term') !== 'PERMANENTLY' && (
                    <>
                      <span className="rulesModal-content-value_text">of</span>
                      <TextField
                        {...register('decisionPeriodCount')}
                        label="Days"
                        name="decisionPeriodCount"
                        className=""
                        defaultValue={item.rule.profileImplicationDto?.decisionPeriodCount || item.rule.decisionPeriodCount || 14}
                        error={!!errors.decisionPeriodCount}
                        size="small"
                        sx={{ width: '64px' }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            {getValues('profileDecision') !== 'NO_IMPLICATIONS' && errors.decisionPeriodCount && (
              <FormHelperText error>{errors.decisionPeriodCount.message as string}</FormHelperText>
            )}
            {getValues('profileDecision') !== 'NO_IMPLICATIONS' && (
              <>
                <div className="rulesModal-content-name rulesModal-content-name_grey">when number of violations</div>
                <div className="rulesModal-content-group rulesModal-content-group_fs">
                  <TextField
                    {...register('countForPeriod')}
                    name="countForPeriod"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.profileImplicationDto?.countForPeriod || item.rule.countForPeriod || 2}
                    error={!!errors.countForPeriod}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                  <span className="rulesModal-content-value rulesModal-content-value_text">within</span>
                  <TextField
                    {...register('periodCount')}
                    name="periodCount"
                    className="rulesModal-content-value"
                    defaultValue={item.rule.profileImplicationDto?.periodCount || item.rule.periodCount || 1}
                    error={!!errors.periodCount}
                    size="small"
                    sx={{ width: '64px' }}
                  />
                  <Select
                    {...register('periodUnit')}
                    name="periodUnit"
                    onChange={(event) => {
                      setValue('periodUnit', event.target.value)
                      void trigger()
                    }}
                    options={[
                      { text: 'days', value: 'DAYS' },
                      { text: 'month', value: 'MONTH' },
                    ]}
                    className="rulesModal-content-value"
                    defaultValue={item.rule.profileImplicationDto?.periodUnit || item.rule.periodUnit || 'DAYS'}
                    error={!!errors.periodUnit}
                    placeholder="Period"
                    size="small"
                    sx={{ width: '112px' }}
                    variant="outlined"
                  />
                </div>
                {errors.countForPeriod && <FormHelperText error>{errors.countForPeriod.message as string}</FormHelperText>}
                {errors.periodCount && <FormHelperText error>{errors.periodCount.message as string}</FormHelperText>}
              </>
            )}
          </>
        )}
      </div>
      <div className="rulesModal-footer">
        {isShowError && (item.action === 'add' || item.action === 'edit') && (
          <Alert severity="error">{item.action === 'add' ? 'Creation' : 'Saving'} error, try again later</Alert>
        )}
        <div className="rulesModal-footer-buttons">
          <Button
            onClick={() => {
              if (item.action === 'delete') {
                onClose()

                return
              }
              setIsOpenModal(true)
            }}
          >
            Cancel
          </Button>
          <Button color={item.action === 'delete' ? 'error' : 'primary'} type="submit" variant="contained">
            {item.action === 'edit' ? 'Save' : item.action}
          </Button>
        </div>
      </div>
      <Modal customstyle={{ minHeight: 188 }} open={isOpenModal}>
        <RulesModalCancel action={item.action} closeModal={() => setIsOpenModal(false)} onClose={onClose} />
      </Modal>
    </form>
  )
}

export default RulesModalContent
