import { GridRenderCellParams } from '@mui/x-data-grid'

import Box from '@admin/components/shared/Box/Box'
import Divider from '@admin/components/shared/Divider/Divider'
import Typography from '@admin/components/shared/Typography/Typography'
import colors from '@admin/theme/constants/colors'

/**
 * Props for the RenderCategoryCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 */
interface IRenderCategoryCellProps {
  props: GridRenderCellParams
}

/**
 * Renders a list of event categories in a data grid.
 */
export const RenderCategoryCell = ({ props }: IRenderCategoryCellProps) => {
  const { row } = props

  if (!row.eventCategories?.length) {
    return <span>—</span>
  }

  if (row.eventCategories) {
    const categories: {
      id: string
      title: string
    }[] = row.eventCategories

    return (
      <Box width={1} alignItems="flex-start" display="flex" flexDirection="column">
        {categories.map((category, index) => (
          <Box key={index} width={1}>
            <Typography variant="body2">{category.title}</Typography>
            {index < categories?.length - 1 && (
              <Divider orientation="horizontal" flexItem>
                <Typography color={colors.outlinedBorder} variant="tooltip">
                  OR
                </Typography>
              </Divider>
            )}
          </Box>
        ))}
      </Box>
    )
  } else {
    return null
  }
}
