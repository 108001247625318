import './index.scss'
// import { useErrorBoundary } from 'react-error-boundary'

import { useCallback, useEffect, useState } from 'react'

import { useDocumentTitle } from '@uidotdev/usehooks'
import { enqueueSnackbar, VariantType } from 'notistack'

// import CopyIcon from '@admin/assets/img/CopyIcon'
import InfoIcon from '@admin/assets/img/InfoIcon'
import MemberProfile from '@admin/components/Profile/MemberProfile/MemberProfile'
import ChangePassword from '@admin/components/Profile/ProfileModal/ChangePassword'
import GlobalLogout from '@admin/components/Profile/ProfileModal/GlobalLogout'
import SetAvatar from '@admin/components/Profile/ProfileModal/SetAvatar'
// import IconButton from '@admin/components/shared/IconButton/IconButton'
import Modal from '@admin/components/shared/Modal/Modal'
import { action } from '@admin/containers/SnackbarContainer'
import { logout, selectSnackbarKeys, updateSnackbarKeys } from '@admin/store/authSlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import { fetchAvatar, fetchGlobalSignOut, selectAvatar, selectAvatarPreview, selectProfile } from '@admin/store/profileSlice'

export type TAvatarAction = 'add' | 'edit' | 'replace'

function Profile() {
  useDocumentTitle('Profile')

  const [showGlobalLogoutModal, setShowGlobalLogoutModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showSetAvatar, setShowSetAvatar] = useState<{ action: TAvatarAction; show: boolean }>({
    action: 'add',
    show: false,
  })

  const memberAvatar = useAppSelector(selectAvatar)
  const memberAvatarPreview = useAppSelector(selectAvatarPreview)
  const memberProfile = useAppSelector(selectProfile)
  const snackbarKeys = useAppSelector(selectSnackbarKeys)

  const dispatch = useAppDispatch()
  // const { showBoundary } = useErrorBoundary()
  //
  // const copyEmail = async () => {
  //   if (navigator.clipboard) {
  //     enqueueSnackbar('Email address has been copied to clipboard', { variant: 'success' as VariantType })
  //
  //     return navigator.clipboard.writeText(adminEmail)
  //   }
  // }

  const handleGlobalLogout = useCallback(async () => {
    try {
      const result = await dispatch(fetchGlobalSignOut())

      if (result.meta.requestStatus === 'fulfilled') {
        dispatch(logout({ message: '' }))
        setShowGlobalLogoutModal(false)
        const key = enqueueSnackbar('Session has been ended, you have to relogin', {
          autoHideDuration: null,
          action,
          variant: 'warning' as VariantType,
        })

        dispatch(updateSnackbarKeys([...snackbarKeys, key]))
      }
      if (result.meta.requestStatus === 'rejected') {
        const key = enqueueSnackbar('Logging out error, try again later', { variant: 'error' as VariantType })

        dispatch(updateSnackbarKeys([...snackbarKeys, key]))
      }
    } catch {
      const key = enqueueSnackbar('Logging out error, try again later', { variant: 'error' as VariantType })

      dispatch(updateSnackbarKeys([...snackbarKeys, key]))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchAvatar())
  }, [])

  return (
    <div className="profile-container">
      <div className="profile-header">
        Profile
        <div className="profile-header-info">
          <InfoIcon />
          If your credentials were changed, please contact your manager
          {/*<span>*/}
          {/*  {adminEmail}*/}
          {/*  <IconButton onClick={copyEmail}>*/}
          {/*    <CopyIcon />*/}
          {/*  </IconButton>*/}
          {/*</span>*/}
        </div>
      </div>
      <MemberProfile
        avatarPreview={memberAvatarPreview}
        memberProfile={memberProfile}
        setShowChangePasswordModal={() => setShowChangePasswordModal(true)}
        setShowGlobalLogoutModal={() => setShowGlobalLogoutModal(true)}
        setShowSetAvatar={(action: TAvatarAction) => setShowSetAvatar({ action, show: true })}
      />
      <Modal customstyle={{ minHeight: 212 }} open={showGlobalLogoutModal}>
        <GlobalLogout logout={() => handleGlobalLogout()} onClose={() => setShowGlobalLogoutModal(false)} />
      </Modal>
      <Modal open={showChangePasswordModal}>
        <ChangePassword onClose={() => setShowChangePasswordModal(false)} />
      </Modal>
      <Modal open={showSetAvatar.show}>
        <SetAvatar
          avatar={showSetAvatar.action === 'edit' ? memberAvatar : null}
          avatarAction={showSetAvatar.action}
          onClose={() => setShowSetAvatar({ action: 'add', show: false })}
        />
      </Modal>
    </div>
  )
}

export default Profile
