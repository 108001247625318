import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import ContentDisplayTabs from '@admin/components/ContentDisplay/types/ContentDisplayTabs'
import { CONTENT_DISPLAY_API } from '@admin/shared/api/constants'
import { TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

import { TRootState } from './store'

export interface IUsersState {
  currentTabFetching: string
  data: TAny[]
  eventsStatus: TStatus
  lifestylesStatus: TStatus
  offersStatus: TStatus
  refreshStatus: TStatus
  status: TStatus
  totalElements: number
  wishesStatus: TStatus
}

const initialState: IUsersState = {
  currentTabFetching: '',
  data: [],
  eventsStatus: 'idle',
  lifestylesStatus: 'idle',
  offersStatus: 'idle',
  refreshStatus: 'idle',
  status: 'idle',
  totalElements: 0,
  wishesStatus: 'idle',
}

export const fetchContent = createAsyncThunk(
  'contentDisplay/fetchContent',
  async ({ pageSize, searchValue, tab }: { pageSize: number; searchValue: string; tab: string }, thunkAPI) => {
    try {
      const rootState: TRootState = (await thunkAPI.getState()) as TRootState
      const filters = Object.keys(rootState.filters.data) ? Object.values(rootState.filters.data).filter((item: TAny) => item !== null) : []
      const sortingList =
        Object.keys(rootState.sorting.data).length > 0
          ? Object.values(rootState.sorting.data).filter((item: TAny) => item !== null && item.columnId !== null && item.sortingType !== null)
          : [
              {
                columnId: 'published_at',
                sortingType: 'DESC',
              },
            ]
      const response = await axios.post(
        CONTENT_DISPLAY_API + `content/screen/${tab}`,
        {
          filters: filters,
          pageInfo: {
            page: 1,
            size: pageSize,
          },
          searchValue,
          sortingList,
        },
        { signal: thunkAPI.signal },
      )

      return { data: response.data, entityType: tab }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          message: error.message || 'An unexpected error occurred',
          status: error.response?.status,
        })
      } else {
        return thunkAPI.rejectWithValue({
          message: 'An unexpected error occurred',
        })
      }
    }
  },
)

export const fetchUpdateContentStatus = createAsyncThunk(
  'contentDisplay/fetchUpdateContentStatus',
  async ({ entityId, entityType, status }: { entityId: string; entityType: string; status: null | string }) => {
    const response = await axios.post(CONTENT_DISPLAY_API + 'content/recommender-status', {
      entityId: entityId,
      entityType: entityType,
      status: status,
    })

    return { data: response.data, entityId: entityId, status: status }
  },
)

export const contentDisplaySlice = createSlice({
  name: 'contentDisplay',
  extraReducers: (builder) => {
    builder
      .addCase(fetchContent.pending, (state, action) => {
        state.status = 'loading'
        state.currentTabFetching = action.meta.arg.tab
        switch (action.meta.arg.tab) {
          case ContentDisplayTabs.EVENTS:
            state.eventsStatus = 'loading'
            break
          case ContentDisplayTabs.LIFESTYLES:
            state.lifestylesStatus = 'loading'
            break
          case ContentDisplayTabs.OFFERS:
            state.offersStatus = 'loading'
            break
          case ContentDisplayTabs.WISHES:
            state.wishesStatus = 'loading'
            break
        }
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = 'idle'
        state.refreshStatus = 'idle'
        if (state.currentTabFetching === action.payload.entityType) {
          state.data = action.payload.data.content
          state.totalElements = action.payload.data.totalElements
        }
        switch (action.payload.entityType) {
          case ContentDisplayTabs.EVENTS:
            state.eventsStatus = 'idle'
            break
          case ContentDisplayTabs.LIFESTYLES:
            state.lifestylesStatus = 'idle'
            break
          case ContentDisplayTabs.OFFERS:
            state.offersStatus = 'idle'
            break
          case ContentDisplayTabs.WISHES:
            state.wishesStatus = 'idle'
            break
        }
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.status = 'failed'
        state.refreshStatus = 'idle'
        state.data = []
        switch (action.meta.arg.tab) {
          case ContentDisplayTabs.EVENTS:
            state.eventsStatus = 'failed'
            break
          case ContentDisplayTabs.LIFESTYLES:
            state.lifestylesStatus = 'failed'
            break
          case ContentDisplayTabs.OFFERS:
            state.offersStatus = 'failed'
            break
          case ContentDisplayTabs.WISHES:
            state.wishesStatus = 'failed'
            break
        }
      })
      .addCase(fetchUpdateContentStatus.fulfilled, (state, action) => {
        const elementIndex = state.data.findIndex((element: TAny) => element.entityId === action.payload.entityId)

        state.data[elementIndex].recommenderStatus =
          state.data[elementIndex].recommenderStatus === action.payload.status ? null : action.payload.status
      })
  },
  initialState,
  reducers: {
    clearContentDisplayData: (state) => {
      state.data = []
    },
    setEventsStatus: (state, action: PayloadAction<TStatus>) => {
      state.eventsStatus = action.payload
    },
    setLifestyleStatus: (state, action: PayloadAction<TStatus>) => {
      state.lifestylesStatus = action.payload
    },
    setOffersStatus: (state, action: PayloadAction<TStatus>) => {
      state.offersStatus = action.payload
    },
    setRefreshContentStatus: (state, action) => {
      state.refreshStatus = action.payload
    },
    setWishesStatus: (state, action: PayloadAction<TStatus>) => {
      state.wishesStatus = action.payload
    },
  },
})

export const selectContentDisplayTotalElements = (state: TRootState): number => state.contentDisplay.totalElements
export const selectContentDisplayStatus = (state: TRootState): TStatus => state.contentDisplay.status
export const selectEventsStatus = (state: TRootState): TStatus => state.contentDisplay.eventsStatus
export const selectWishesStatus = (state: TRootState): TStatus => state.contentDisplay.wishesStatus
export const selectOffersStatus = (state: TRootState): TStatus => state.contentDisplay.offersStatus
export const selectLifestylesStatus = (state: TRootState): TStatus => state.contentDisplay.lifestylesStatus
export const selectContentDisplayRefreshStatus = (state: TRootState): TStatus => state.contentDisplay.refreshStatus
export const selectContentDisplayData = (state: TRootState): TAny[] => state.contentDisplay.data

export const { clearContentDisplayData, setRefreshContentStatus } = contentDisplaySlice.actions

export default contentDisplaySlice.reducer
