import { MouseEvent } from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import Typography from '@admin/components/shared/Typography/Typography'
import { selectUserPermissions } from '@admin/store/authSlice'
import { useAppSelector } from '@admin/store/hooks'
import { ITaxCode, TaxesStatuses } from '@admin/store/slices/Settings/interface'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import { possibleTaxStatuses } from '../constants'

/**
 * Props for the RenderStatusSelect component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {string[] } disablePermission - If `true`, it's not allowed to change status.
 * @property {boolean} isMenuOpen - If `true`, the status menu is shown.
 * @property {ITaxCode | null} [selectedCellData] - Row data for selected cell.
 * @property {(e: MouseEvent<HTMLDivElement>, data: any) => void} onClick - Click event handler.
 */
interface IRenderStatusSelectProps {
  disablePermission: string[]
  isMenuOpen: boolean
  onClick: (event: MouseEvent<HTMLDivElement>, data: TAny) => void
  props: GridRenderCellParams
  selectedCellData?: ITaxCode | null
}

/**
 * Renders a status select cell in a data grid.
 */
export const RenderStatusSelect = ({ disablePermission, isMenuOpen, onClick, props, selectedCellData }: IRenderStatusSelectProps) => {
  const { row } = props
  const userPermissions = useAppSelector(selectUserPermissions)

  if (!row.id && !row.salesTaxRuleId) {
    return null
  }

  const hasAccess = checkPermissions(userPermissions, disablePermission)
  const currentStatus = possibleTaxStatuses.find((element) => row.status?.toLowerCase() === element.status.toLowerCase())
  const color = currentStatus?.color

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    onClick(event, row)
  }

  const isDefaultStatus = row.status === TaxesStatuses.DEFAULT
  const isDisabledChange = hasAccess && !isDefaultStatus

  return (
    <div
      className={isDisabledChange ? 'taxes-table__status-select' : ''}
      onClick={(event) => isDisabledChange && handleClick(event)}
      style={{ color }}
    >
      <Typography color={color} variant="chip">
        {row.status?.replace(row.status, currentStatus?.title)}
      </Typography>
      {isDisabledChange && (selectedCellData?.id === row.id && isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </div>
  )
}
