const WalletIcon = () => (
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2472 12C19.2472 12.6904 18.6876 13.25 17.9972 13.25C17.3069 13.25 16.7472 12.6904 16.7472 12C16.7472 11.3096 17.3069 10.75 17.9972 10.75C18.6876 10.75 19.2472 11.3096 19.2472 12Z" />
    <path
      clipRule="evenodd"
      d="M20.4941 7.58545C20.0496 4.95886 17.7637 2.9585 15.0105 2.9585H9.25689C4.92146 2.9585 1.40689 6.47306 1.40689 10.8085V13.1916C1.40689 17.527 4.92146 21.0416 9.25689 21.0416H15.1636C17.8854 21.0416 20.1377 19.0312 20.5163 16.4142H21.1135C22.4829 16.4142 23.593 15.3042 23.593 13.9348V10.0649C23.593 8.69553 22.4829 7.58545 21.1135 7.58545H20.4941ZM9.25689 4.6585C5.86034 4.6585 3.10689 7.41194 3.10689 10.8085V13.1916C3.10689 16.5882 5.86034 19.3416 9.25689 19.3416H15.1636C16.9439 19.3416 18.4311 18.0873 18.7899 16.4142H18.1602C15.7221 16.4142 13.7458 14.4379 13.7458 11.9998C13.7458 9.56184 15.7222 7.58545 18.1602 7.58545H18.7584C18.3405 5.90432 16.821 4.6585 15.0105 4.6585H9.25689ZM15.4458 11.9998C15.4458 10.5007 16.661 9.28545 18.1602 9.28545H21.1135C21.544 9.28545 21.893 9.63442 21.893 10.0649V13.9348C21.893 14.3653 21.544 14.7142 21.1135 14.7142H18.1602C16.661 14.7142 15.4458 13.499 15.4458 11.9998Z"
      fillRule="evenodd"
    />
  </svg>
)

export default WalletIcon
