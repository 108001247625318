import Button from '@admin/components/shared/Button/Button'
import Dialog from '@admin/components/shared/Dialog/Dialog'
import DialogActions from '@admin/components/shared/Dialog/DialogActions/DialogActions'
import DialogContent from '@admin/components/shared/Dialog/DialogContent/DialogContent'
import DialogContentText from '@admin/components/shared/Dialog/DialogContentText/DialogContentText'
import DialogTitle from '@admin/components/shared/Dialog/DialogTitle/DialogTitle'

interface ICancelAddingMemberModalProps {
  closeModal: () => void
  contentText: string
  onCancel: () => void
  open: boolean
  title: string
}

export const CancelAddingMemberModal = ({ title, closeModal, contentText, onCancel, open }: ICancelAddingMemberModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="text.primary" variant="body1">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeModal} variant="text">
          Don't cancel
        </Button>
        <Button color="primary" onClick={onCancel} variant="contained">
          Cancel adding
        </Button>
      </DialogActions>
    </Dialog>
  )
}
