const ShieldIcon = () => {
  return (
    <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5612 9.78112C15.8931 9.44917 15.8931 8.91098 15.5612 8.57904C15.2292 8.24709 14.691 8.24709 14.3591 8.57904L10.6601 12.278L9.65118 11.269C9.31923 10.9371 8.78104 10.9371 8.44909 11.269C8.11715 11.601 8.11715 12.1392 8.44909 12.4711L10.0591 14.0811C10.391 14.4131 10.9292 14.4131 11.2612 14.0811L15.5612 9.78112Z" />
      <path
        clipRule="evenodd"
        d="M13.8212 1.43141C13.276 1.23257 12.6185 1.14746 12.0025 1.14746C11.3875 1.14746 10.7318 1.23229 10.1926 1.43369L10.1903 1.43454L5.2023 3.3138L5.20123 3.3142C4.43188 3.60217 3.78062 4.17759 3.32523 4.83438C2.86974 5.49132 2.56 6.30206 2.56 7.11996V14.55C2.56 15.3088 2.80475 16.1231 3.1594 16.8282C3.5142 17.5335 4.0228 18.2162 4.63152 18.6711L8.92923 21.8794L8.93066 21.8805C9.80871 22.54 10.9293 22.845 12.01 22.845C13.0907 22.845 14.2113 22.54 15.0893 21.8805L15.0908 21.8794L19.3888 18.6708C19.9976 18.2159 20.5058 17.5335 20.8606 16.8282C21.2152 16.1231 21.46 15.3088 21.46 14.55V7.11996C21.46 6.29832 21.1511 5.48534 20.6953 4.82638C20.2396 4.16769 19.5874 3.59176 18.8177 3.3038L13.8283 1.43398L13.8212 1.43141ZM10.7874 3.02623L10.7884 3.02586C11.0792 2.91747 11.5181 2.84746 12.0025 2.84746C12.4848 2.84746 12.9309 2.91686 13.2356 3.02735L18.2223 4.89612C18.6024 5.03823 18.9955 5.3574 19.2972 5.79354C19.5989 6.22958 19.76 6.7116 19.76 7.11996V14.55C19.76 14.9712 19.6147 15.5218 19.3419 16.0643C19.0692 16.6064 18.7124 17.054 18.3711 17.3091L14.0715 20.5188L14.0692 20.5205C13.5375 20.9203 12.7937 21.145 12.01 21.145C11.2263 21.145 10.4825 20.9203 9.95077 20.5205L5.64885 17.3091C5.30757 17.054 4.95079 16.6064 4.6781 16.0643C4.40525 15.5218 4.26 14.9712 4.26 14.55V7.11996C4.26 6.71787 4.42026 6.2386 4.72227 5.80304C5.02416 5.36763 5.41751 5.04828 5.79769 4.90613L10.7874 3.02623Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ShieldIcon
