import { TDataGridRowItem } from '@admin/store/slices/Settings/interface'

const getTransformedFormData = (inputObject: TDataGridRowItem) => {
  const resultObject = {
    certainty: inputObject.certainty,
    entityType: inputObject.entityType,
    eventCategories: inputObject.eventCategories
      ? inputObject.eventCategories.map((element) => {
          return { titles: [element.title], value: element.id }
        })
      : [],
    eventOnlineFormatType: inputObject.eventOnlineFormatType,
    format: inputObject.format,
    passions: inputObject.passions
      ? inputObject.passions.map((passionGroup) =>
          passionGroup.map((passion) => {
            return { titles: [passion.title], value: passion.id }
          }),
        )
      : [[]],
    salesTaxCode: {
      category: inputObject.salesTaxCode.category,
      code: inputObject.salesTaxCode.code,
      salesTaxCodeId: inputObject.salesTaxCode.id,
    },
  }

  return resultObject
}

export default getTransformedFormData
