import { ElementType } from 'react'

import { Typography as TypographyBase, type TypographyProps } from '@mui/material'

interface ITypographyProps extends TypographyProps {
  component?: ElementType
}

const Typography = (props: ITypographyProps) => {
  return <TypographyBase {...props}>{props.children}</TypographyBase>
}

export default Typography
