import Carousel from 'react-material-ui-carousel'

import { Badge } from '@yzzy/ui'

import ZoomInIcon from '@admin/assets/img/ZoomInIcon'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import colors from '@admin/theme/constants/colors'

import type { TAny } from '@yzzy/types'

interface IOfferInfoProps {
  entityStatus?: 'EXPIRED'
  format: 'OFFLINE' | 'ONLINE'
  title: string
  userInfo: {
    avatarUrl: string
    firstName: string
    lastName: string
  }
}

const OfferInfo = ({ title, entityStatus, format, userInfo }: IOfferInfoProps) => {
  const isExpired = entityStatus === 'EXPIRED'
  const userName = userInfo.firstName && userInfo.lastName ? `${userInfo.firstName || ''} ${userInfo.lastName || ''}` : 'Unknown user'

  return (
    <div className="offerCard--data">
      <div>
        <div>
          <Badge disabled={isExpired} type={format} />
        </div>
      </div>
      <div>
        {isExpired && (
          <Badge
            sx={{
              marginBottom: '4px',
            }}
            customText="timed out"
            type="EXPIRED"
          />
        )}
        <div className="offerCard--title">{title}</div>
        <div className="offerCard--avatar">
          <AvatarItem primaryText={userName} sizes="small" src={userInfo.avatarUrl} subText="Creator" textColor="white" />
        </div>
      </div>
    </div>
  )
}

const OfferCard = (props: TAny) => {
  const userInfo = props.data.userInfoDto ?? {}
  const widthEntity = 200
  const heightEntity = 300

  return props.data.orderedMediaList.length > 1 ? (
    <Carousel
      activeIndicatorIconButtonProps={{ style: { color: colors.primary } }}
      animation="slide"
      autoPlay={false}
      className="offerCard--Carousel"
      height={300}
      indicatorContainerProps={{ style: { gap: '12px' } }}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: colors.actionDisabled } }}
      navButtonsAlwaysInvisible={true}
      swipe={false}
    >
      {props.data.orderedMediaList.map((element: TAny, index: number) => (
        <div key={index} className="carouselEntity">
          <div
            onClick={() => {
              props.showMedia(
                element.mediaUrl ? element.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                element,
                element.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              )
            }}
            className="overlay overlay--shifted"
            style={{ width: widthEntity, height: heightEntity }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <div className="offerCard--container">
            {index === 0 && (
              <OfferInfo title={props.data.title} entityStatus={props.data.entityStatus} format={props.data.format} userInfo={userInfo} />
            )}
            {index === 0 && (
              <div className="offerCard--price">
                <div className="offerCard--priceText">Price</div>
                <div className="offerCard--priceValue">{props.priceOption.price === '$0' ? 'Free' : props.priceOption.cardPrice}</div>
              </div>
            )}
            <div className="offerCard--background">
              <img
                src={
                  element.mediaPreviewUrl ||
                  'https://images.rawpixel.com/image_png_1000/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8xMF9jbG9zZXVwX2ZveF9mYWNlX21pbmltYWxfc2ltcGxlX2NsZWFuX3dhdGVyY19mMWQ5YzRmMy1jMTJkLTRlYTYtOWJhMC1jMTc0ZGM5MmM4MTMucG5n.png'
                }
                alt="Offer media"
              />
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaType: 'Image',
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
          }

          props.showMedia(mediaItem.mediaUrl, mediaItem, mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image', props.data.orderedMediaList)
        }}
        className="overlay"
        style={{ width: widthEntity, height: heightEntity }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <div className="offerCard--container">
        <OfferInfo title={props.data.title} entityStatus={props.data.entityStatus} format={props.data.format} userInfo={userInfo} />
        <div className="offerCard--price">
          <div className="offerCard--priceText">Price</div>
          <div className="offerCard--priceValue">{props.priceOption.price === '$0' ? 'Free' : props.priceOption.cardPrice}</div>
        </div>
        <img
          src={
            props.data.orderedMediaList[0]?.mediaPreviewUrl ||
            'https://images.rawpixel.com/image_png_1000/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8xMF9jbG9zZXVwX2ZveF9mYWNlX21pbmltYWxfc2ltcGxlX2NsZWFuX3dhdGVyY19mMWQ5YzRmMy1jMTJkLTRlYTYtOWJhMC1jMTc0ZGM5MmM4MTMucG5n.png'
          }
          alt="Offer media"
          className="offerCard--background"
        />
      </div>
    </div>
  )
}

export default OfferCard
