import { CardMedia } from '@mui/material'

import UserDefault from '@admin/assets/img/UserDefault.png'
import Card from '@admin/components/shared/Card/Card'
import CardActionArea from '@admin/components/shared/Card/CardActionArea'
import CardContent from '@admin/components/shared/Card/CardContent'
import { IOrderedHero } from '@admin/store/collectionDetailsSlice'

interface IProps {
  entity: IOrderedHero
  height: number
  width: number
}

const HeroCard = ({ width, entity, height }: IProps) => {
  return (
    <Card className="heroCard">
      <CardActionArea>
        <CardMedia width={width} alt="Hero avatar" component="img" height={height} image={entity.avatar?.originalLink || UserDefault} />
        <CardContent>
          <span className="hero-name">
            {entity.firstName} {entity.lastName}
          </span>
          {entity.occupation && <span>{entity.occupation}</span>}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default HeroCard
