import './index.scss'

import { useCallback, useMemo } from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { format } from 'date-fns'
import startCase from 'lodash/startCase'
import { enqueueSnackbar, VariantType } from 'notistack'

import ChevronDownIcon from '@admin/assets/img/ChevronDownIcon'
import ChevronUpIcon from '@admin/assets/img/ChevronUpIcon'
import CloseIcon from '@admin/assets/img/CloseIcon'
import CommentIcon from '@admin/assets/img/CommentIcon'
import EyeClosedFilledIcon from '@admin/assets/img/EyeClosedFilledIcon'
import EyeClosedIcon from '@admin/assets/img/EyeClosedIcon'
import FlashIcon from '@admin/assets/img/FlashIcon'
import LikeIcon from '@admin/assets/img/LikeIcon'
import LocationIcon from '@admin/assets/img/LocationIcon'
import MoneyIcon from '@admin/assets/img/MoneyIcon'
import StarFilledIcon from '@admin/assets/img/StarFilledIcon'
import StarIcon from '@admin/assets/img/StarIcon'
import ContentDisplayInfoBlock from '@admin/components/ContentDisplay/ContentDisplayInfoBlock/ContentDisplayInfoBlock'
import AvatarItem from '@admin/components/shared/AvatarItem/AvatarItem'
import Chip from '@admin/components/shared/Chip/Chip'
import IconButton from '@admin/components/shared/IconButton/IconButton'
import Tooltip from '@admin/components/shared/Tooltip/Tooltip'
import Typography from '@admin/components/shared/Typography/Typography'
import useExpandableDescription from '@admin/hooks/useExpandableDescription'
import { selectUserPermissions } from '@admin/store/authSlice'
import { fetchUpdateContentStatus } from '@admin/store/contentDisplaySlice'
import { useAppDispatch, useAppSelector } from '@admin/store/hooks'
import colors from '@admin/theme/constants/colors'
import { EntityStatus, IMediaItem, Permissions } from '@admin/types/commonTypes'
import { currencyFormatter } from '@admin/utils//currencyFormatter'
import { checkPermissions } from '@admin/utils/checkPermissions'

import type { TAny } from '@yzzy/types'

import LifestyleCard from './LifestyleCard'

const Lifestyle = (props: TAny) => {
  const data = props.data
  const publishDate = data?.publishedAt ? new Date(data.publishedAt) : null
  const userInfo = data.userInfoDto ?? {}

  const dispatch = useAppDispatch()
  const { isExpanded, renderedDescription, toggleExpansion } = useExpandableDescription(data.description)

  const userPermissions = useAppSelector(selectUserPermissions)
  const showMarkHideButtonGroup = checkPermissions(userPermissions, [Permissions.CONTENT_DISPLAY_MARK_HIDE_PUBLICATIONS])

  const isPaidLifeStyle = data.price && data.priceOption !== 'FREE'
  const isEntityStatusActive = data.entityStatus === EntityStatus.ACTIVE

  const setContentStatus = useCallback(async (entityId: string, recommenderStatus: null | string, status: string) => {
    const result = await dispatch(
      fetchUpdateContentStatus({
        entityId: entityId,
        entityType: 'LIFESTYLE',
        status: recommenderStatus !== status ? status : null,
      }),
    )

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Lifestyle #${data.entityId} has been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}`, {
        variant: 'success' as VariantType,
      })
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar(`Lifestyle #${data.entityId} hasn't been ${recommenderStatus === status ? 'un' : ''}${status.toLowerCase()}, try again later`, {
        variant: 'error' as VariantType,
      })
    }
  }, [])

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`

    return 'Location'
  }, [data.locationInfo])

  const locationText = useMemo(() => {
    const { locationCity, locationCountry } = data.locationInfo
    const parts = [locationCity, locationCountry].filter(Boolean)

    return parts.length > 0 ? parts.join(', ') : 'Not specified'
  }, [data.locationInfo])

  return (
    <div className={'lifestyleCard--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="lifestyleCard--containerHeader">
        <div className="lifestyleCard--eventName">
          <div className="lifestyleCard--eventId">Lifestyle #{data.entityId}</div>
          {publishDate && (
            <div className="lifestyleCard--eventPublishDate">
              Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
            </div>
          )}
        </div>
        {props.modalClose ? (
          <div className="lifestyleCard--eventActions">
            <IconButton onClick={() => props.modalClose()} size="small" sx={{ margin: '-4px -12px 0 0' }}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className="lifestyleCard--eventActions">
            {showMarkHideButtonGroup && (
              <Tooltip title={!isEntityStatusActive ? 'Unavailable for entities in this status' : ''} placement="top-end" followCursor>
                <ToggleButtonGroup
                  className="lifestyleCard--actionButtonsGroup"
                  disabled={!isEntityStatusActive}
                  value={data.recommenderStatus}
                  exclusive
                >
                  <ToggleButton
                    className="lifestyleCard--actionButton starred"
                    onClick={(_event, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                    size="small"
                    value="STARRED"
                  >
                    {data.recommenderStatus === 'STARRED' ? <StarFilledIcon /> : <StarIcon />}
                  </ToggleButton>
                  <ToggleButton
                    className="lifestyleCard--actionButton hidden"
                    onClick={(_event, value) => setContentStatus(data.entityId, data.recommenderStatus, value)}
                    size="small"
                    value="HIDDEN"
                  >
                    {data.recommenderStatus === 'HIDDEN' ? <EyeClosedFilledIcon /> : <EyeClosedIcon />}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="lifestyleCard--content">
        <div className="lifestyleCardContent--card">
          <LifestyleCard
            showMedia={(mediaUrl: string, mainEntity: IMediaItem, mediaType?: string, orderedMediaList?: IMediaItem[]) => {
              if (data.orderedMediaList) {
                props.showMedia(data.entityId, data.entityType, mediaUrl, mainEntity, orderedMediaList, mediaType)
              } else {
                props.showMedia(data.entityId, data.entityType, mediaUrl)
              }
            }}
            data={data}
          />
        </div>
        <div className="lifestyleCardContent--descriptionContainer">
          <AvatarItem
            onClick={() => props.openUser(userInfo.userId || '1')}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            src={userInfo.avatarUrl}
            subText={`@${userInfo.username}`}
          />
          <div className="lifestyleCardContent--description">
            <Typography className="text" color="text.secondary" component="span" variant="body2">
              {renderedDescription}{' '}
            </Typography>
            {data.description && data.description.length > 250 && (
              <Typography
                onClick={(event) => {
                  event.stopPropagation()
                  toggleExpansion()
                }}
                className="trigger"
                component="span"
              >
                <Typography component="span" variant="body2">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Typography>
                {isExpanded ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
              </Typography>
            )}
          </div>
          <div className="lifestyleCardContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
            <div>
              <CommentIcon /> {data.commentsCounter}
            </div>
          </div>
        </div>
        <div className="lifestyleCardContent--passions">
          <div className="lifestyleCardContent--passionsTitle">Passions</div>
          <div className="lifestyleCardContent--passionsChips">
            {data.passions.length > 0 ? (
              data.passions.map((element: TAny) => <Chip key={element} label={element} size="small" variant="filled" />)
            ) : (
              <Typography color={colors.outlinedBorder} variant="body2">
                Not specified
              </Typography>
            )}
          </div>
        </div>
        <div className="lifestyleCardContent--terms">
          {data.entityStatus && (
            <ContentDisplayInfoBlock icon={<FlashIcon />} primary={startCase(data.entityStatus.toLowerCase())} secondary="Status" />
          )}
          {isPaidLifeStyle && (
            <ContentDisplayInfoBlock
              icon={<MoneyIcon />}
              primary={data.priceOption === 'FREE' ? 'Free' : `${currencyFormatter(data.price, 'en-US', 'USD')}`}
              secondary="Price"
            />
          )}
          <ContentDisplayInfoBlock icon={<LocationIcon />} primary={locationText} secondary={locationHeader} withTooltip />
        </div>
      </div>
    </div>
  )
}

export default Lifestyle
