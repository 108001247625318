import './index.scss'

import { Box, Modal as ModalBase } from '@mui/material'
import { ModalProps } from '@mui/material/Modal/Modal'

import type { TAny } from '@yzzy/types'

const style = {
  minWidth: 444,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  minHeight: 220,
  outline: 'none',
  padding: '16px 24px 24px',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
}

interface IModalProps extends ModalProps {
  children: TAny
  customstyle?: TAny
  label?: string
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  open: boolean
  primary?: boolean
}

const Modal = (props: IModalProps) => {
  return (
    <ModalBase {...props} className="modal">
      <Box sx={{ ...style, ...props.customstyle }}>{props.children || <div />}</Box>
    </ModalBase>
  )
}

export default Modal
