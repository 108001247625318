import './index.scss'

import { useRef, useState } from 'react'

import { Box, InputLabel, useTheme } from '@mui/material'
import { TMUIRichTextEditorRef } from 'mui-rte'

import TextEditor, { HeaderBlockStyle, HeaderToolbarButton, ITextEditorProps } from '@admin/components/shared/TextEditor/TextEditor'

interface IProps extends Omit<ITextEditorProps, 'onChange' | 'onSave' | 'toolbar'> {
  onChange(value: string): void
  onSave(value: string): void
  placeholder?: string
}

const TextEditorField = (props: IProps) => {
  const [active, setActive] = useState(false)
  const reference = useRef<TMUIRichTextEditorRef>(null)

  const theme = useTheme()

  Object.assign(theme, {
    overrides: {
      MUIRichTextEditor: {
        '.DraftEditor-root': {
          height: '100%',
        },
        root: {
          height: '100%',
        },
        container: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        },
        editor: {
          color: props.readOnly ? '#BCC0CD' : '#70778f',
          height: '100%',
          order: -1,
        },
        editorContainer: {
          width: '100%',
          height: '100%',
        },
        placeHolder: {
          color: '#BCC0CD',
          order: -1,
          paddingTop: '5px',
        },
        toolbar: {
          '& .MuiToggleButton-root': {
            padding: '4px 6px',
          },
          '& .MuiToggleButton-root:hover': {
            backgroundColor: '#d9e3fd',
            border: '1px solid #DADCE3',
          },
          '& .TextEditor-button': {
            color: '#70778f',
          },
          '& .TextEditor-button_active': {
            backgroundColor: '#d9e3fd',
            border: '1px solid #88a8f9',
            color: '#4E79E7',
          },
          marginTop: '24px',
        },
      },
    },
  })

  return (
    <Box
      id="box-editor"
      className={active ? 'TextEditor-box TextEditor-box_active' : props.readOnly ? 'TextEditor-box TextEditor-box_disabled' : 'TextEditor-box '}
    >
      <InputLabel
        className={
          active ? 'TextEditor-label TextEditor-label_active' : props.readOnly ? 'TextEditor-label TextEditor-label_disabled' : 'TextEditor-label'
        }
        htmlFor="box-editor"
      >
        {props.label}
      </InputLabel>
      <TextEditor
        {...props}
        label={props.placeholder}
        customControls={[
          {
            id: 'header',
            name: 'header',
            blockWrapper: <HeaderBlockStyle readOnly={props.readOnly} />,
            component: HeaderToolbarButton,
            type: 'block',
          },
        ]}
        draftEditorProps={{
          stripPastedStyles: true,
        }}
        onChange={(editorState) => {
          if (!props.readOnly) {
            props.onChange(editorState.getCurrentContent().getPlainText())
            reference.current?.save()
          }
        }}
        controls={['header']}
        defaultValue={props.defaultValue}
        maxLength={props.maxLength}
        onBlur={() => !props.readOnly && setActive(false)}
        onFocus={() => !props.readOnly && setActive(true)}
        onSave={(data) => !props.readOnly && props.onSave(data)}
        ref={reference}
        toolbar={active || props.readOnly}
      />
    </Box>
  )
}

export default TextEditorField
