import { createSvgIcon } from '@mui/material'

const MessageIcon = createSvgIcon(
  <svg width="24" fill="currentColor" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M2.1499 12.002C2.1499 15.5002 2.62732 17.8864 4.33665 19.2849C5.17988 19.9748 6.2449 20.3572 7.49536 20.5739C8.7424 20.7901 10.2402 20.852 11.9999 20.852C13.7569 20.852 15.2533 20.7972 16.4984 20.5906C17.7465 20.3835 18.8134 20.0132 19.6591 19.3298C21.3746 17.9436 21.8499 15.5502 21.8499 12.002C21.8499 8.45383 21.3746 6.06044 19.6591 4.67425C18.8134 3.99085 17.7465 3.6206 16.4984 3.4135C15.2533 3.20691 13.7569 3.15204 11.9999 3.15204C10.2402 3.15204 8.7424 3.214 7.49536 3.43015C6.2449 3.6469 5.17988 4.02926 4.33665 4.71918C2.62732 6.11772 2.1499 8.50389 2.1499 12.002ZM11.9999 19.152C10.2596 19.152 8.88241 19.089 7.7857 18.8989C6.6924 18.7094 5.94493 18.4043 5.41315 17.9692C4.37248 17.1177 3.8499 15.5039 3.8499 12.002C3.8499 10.7886 3.91265 9.80184 4.03796 8.99496C7.75302 11.057 9.84403 11.85 12.0001 11.85C14.1571 11.85 16.2491 11.0562 19.9674 8.99205C20.0889 9.79916 20.1499 10.7868 20.1499 12.002C20.1499 15.5649 19.6252 17.1716 18.5907 18.0076C18.0614 18.4353 17.3158 18.7317 16.2201 18.9135C15.1215 19.0958 13.7429 19.152 11.9999 19.152ZM19.5251 7.29195C15.5677 9.51422 13.7491 10.15 12.0001 10.15C10.2535 10.15 8.43753 9.51601 4.49176 7.30134C4.73178 6.74575 5.03898 6.34105 5.41315 6.0349C5.94493 5.59981 6.6924 5.29468 7.7857 5.10518C8.88241 4.91508 10.2596 4.85204 11.9999 4.85204C13.7429 4.85204 15.1215 4.90827 16.2201 5.09057C17.3158 5.27236 18.0614 5.56878 18.5907 5.9965C18.9715 6.30421 19.2832 6.71632 19.5251 7.29195Z"
      fillRule="evenodd"
    />
  </svg>,
  'Message',
)

export default MessageIcon
