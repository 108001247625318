import lowerCase from 'lodash/lowerCase'
import upperFirst from 'lodash/upperFirst'

/**
 * Converts a string to "sentence case" format.
 * @param {string} str - The input string to convert.
 * @returns {string} The string in "sentence case" format.
 */
export const sentenceCase = (string_: string): string => {
  return upperFirst(lowerCase(string_))
}
