import * as yup from 'yup'

const getValidationSchema = () => {
  return yup.object().shape({
    appleConsumptionRequestData: yup.object().shape({
      accountTenure: yup.string().required('Required field'),
      consumptionStatus: yup.string().required('Required field'),
      deliveryStatus: yup.string().required('Required field'),
      lifetimeDollarsPurchased: yup.string().required('Required field'),
      lifetimeDollarsRefunded: yup.string().required('Required field'),
      playTime: yup.string().required('Required field'),
      userStatus: yup.string().required('Required field'),
    }),
    comment: yup.string().trim().max(5000, 'Max limit of 5000 characters is exceeded'),
    consumptionRequestType: yup.string().required('Required field'),
    reason: yup.string().required('Required field'),
  })
}

export default getValidationSchema
