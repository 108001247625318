import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { WORLD_MANAGEMENT_API } from '@admin/shared/api/constants'
import { TRootState } from '@admin/store/store'
import { IMedia, TStatus } from '@admin/types/commonTypes'

import type { TAny } from '@yzzy/types'

export interface IBanner {
  article: null | {
    editorString: string
    textString: string
  }
  cover: IMedia
  creatorLastName: string
  creatorName: string
  expiredDate: Date | null
  id: string
  name: string
  order?: number
  status: TBannerStatus
  subtitle: string
  title: string
  updatedAt: Date
}

export type TBannerStatus = 'ARCHIVED' | 'EXPIRED' | 'INCOMPLETE' | 'LIVE' | 'READY'

interface IBannerDetailsState {
  banner: IBanner | null
  savingStatus: TStatus
  status: TStatus
}

const initialState: IBannerDetailsState = {
  banner: null,
  savingStatus: 'idle',
  status: 'idle',
}

export const fetchBanner = createAsyncThunk('bannerDetails/bannerDetails', async (bannerId: string) => {
  const response = await axios.get(WORLD_MANAGEMENT_API + `timworld/banners/${bannerId}`)

  return response.data
})

export const fetchEditBanner = createAsyncThunk('bannerDetails/editBanner', async ({ bannerId, data }: { bannerId: string; data: TAny }) => {
  const response = await axios.patch(WORLD_MANAGEMENT_API + `timworld/banners/${bannerId}`, data)

  return response.data
})

export const fetchUpdateCover = createAsyncThunk(
  'bannerDetails/updateCover',
  async ({ bannerId, cover, coverPreview }: { bannerId: string; cover?: Blob; coverPreview: Blob }) => {
    const body = new FormData()

    body.append('cover', cover ?? '')
    body.append('coverPreview', coverPreview ?? '')
    const response = await axios.post(WORLD_MANAGEMENT_API + `timworld/banners/${bannerId}/cover`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  },
)

export const bannerDetailsSlice = createSlice({
  name: 'bannerDetails',
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanner.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchBanner.fulfilled, (state, { payload }) => {
        state.status = 'idle'
        state.banner = payload
      })
      .addCase(fetchBanner.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(fetchEditBanner.pending, (state) => {
        state.savingStatus = 'loading'
      })
      .addCase(fetchEditBanner.fulfilled, (state, { payload }) => {
        state.savingStatus = 'idle'
      })
      .addCase(fetchEditBanner.rejected, (state) => {
        state.savingStatus = 'failed'
      })
  },
  initialState,
  reducers: {},
})

export const selectBanner = (state: TRootState): IBanner | null => state.bannerDetails.banner
export const selectBannerStatus = (state: TRootState): TStatus => state.bannerDetails.status
export const savingBannerStatus = (state: TRootState): TStatus => state.bannerDetails.savingStatus

export default bannerDetailsSlice.reducer
