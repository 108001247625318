import * as yup from 'yup'

import { TIER_MAX_LIMIT, TIER_MIN_LIMIT } from '@admin/yzzy-constants'

export const getValidationSchema = () => {
  return yup.object().shape({
    name: yup.string().required('Required field').trim().max(50, 'Max limit of 50 characters is exceeded'),
    providerId: yup.string().required('Required field'),
    country: yup.string().required('Required field'),
    currency: yup.string().required('Required field'),
    linkId: yup.string().when('radioValue', {
      is: (value: string) => value === 'list',
      then: (schema) => schema.required('Required field'),
    }),
    operationType: yup.string().required('Required field'),
    price: yup
      .string()
      .required('Required field')
      .test('validateMinLimit', `Enter an amount greater than the minimum (${TIER_MIN_LIMIT})`, (value) =>
        value ? Number(value) >= TIER_MIN_LIMIT : false,
      )
      .test('validateMaxLimit', `Enter an amount less than the maximum (${TIER_MAX_LIMIT})`, (value) =>
        value ? Number(value) <= TIER_MAX_LIMIT : false,
      ),
    productId: yup.string().required('Required field').trim().max(50, 'Max limit of 50 characters is exceeded'),
  })
}
